// Created By ND
import MycircleLoginLogo from "../../Assets/Icons/MycircleLoginLogo.svg";
import MyCircleLogo from "../../Assets/Icons/MyCircleLogo.svg";
import AutoResponders from "../../Assets/Icons/AutoResponders.svg";
import AutoRespondersFill from "../../Assets/Icons/AutoRespondersFill.svg";
import BlastCampaign from "../../Assets/Icons/BlastCampaign.svg";
import BlastCampaignFill from "../../Assets/Icons/BlastCampaignFill.svg";
import TestSMS from "../../Assets/Icons/TestSMS.svg";
import SendMsg from "../../Assets/Icons/SendMsg.svg";
import PendingStatus from "../../Assets/Icons/PendingStatus.svg";
import MarkAsCompleted from "../../Assets/Icons/MarkAsCompleted.svg";
import ReservationMblRgtArt from "../../Assets/Icons/ReservationMblRgtArt.svg";
import ReservationMblLftArt from "../../Assets/Icons/ReservationMblLftArt.svg";

// No data img
import NoDataImg from "../../Assets/Icons/NoDataImg.svg";

// Error Page
import Page302 from "../../Assets/Icons/Page302.svg";
import Page404 from "../../Assets/Icons/Page404.svg";
import Page500 from "../../Assets/Icons/Page500.svg";

import Close from "../../Assets/Icons/Close.svg";
import Client from "../../Assets/Icons/Client.svg";
import SubClient from "../../Assets/Icons/SubClient.svg";
import SubClientFill from "../../Assets/Icons/SubClientFill.svg";
import ClientFill from "../../Assets/Icons/ClientFill.svg";
import Dashboard from "../../Assets/Icons/Dashboard.svg";
import DashboardFill from "../../Assets/Icons/DashboardFill.svg";
import Dot from "../../Assets/Icons/Dot.svg";
import Tools from "../../Assets/Icons/Tools.svg";
import ToolsFill from "../../Assets/Icons/ToolsFill.svg";
import Search from "../../Assets/Icons/Search.svg";
import Chat from "../../Assets/Icons/Chat.svg";
import Notification from "../../Assets/Icons/Notification.svg";
import Switch from "../../Assets/Icons/Switch.svg";
import User from "../../Assets/Icons/User.svg";
import Settings from "../../Assets/Icons/Settings.svg";
import BusinessSettings from "../../Assets/Icons/BusinessSettings.svg";
import HelpCenter from "../../Assets/Icons/HelpCenter.svg";
import Logout from "../../Assets/Icons/Logout.svg";
import Envelope from "../../Assets/Icons/Envelope.svg";
import Comment from "../../Assets/Icons/Comment.svg";
import Whatsapp from "../../Assets/Icons/Whatsapp.svg";
import CloseRound from "../../Assets/Icons/CloseRound.svg";
import RequiredStar from "../../Assets/Icons/RequiredStar.svg";
import CFCheck from "../../Assets/Icons/CF-Check.svg";
import CFCopy from "../../Assets/Icons/CF-Copy.svg";
import CopyGrey from "../../Assets/Icons/CopyGrey.svg";
import CFCross from "../../Assets/Icons/CF-Cross.svg";
import CFDown from "../../Assets/Icons/CF-Down.svg";
import CFUp from "../../Assets/Icons/CF-Up.svg";
import CustomForms from "../../Assets/Icons/CustomForms.svg";
import CustomFormsFill from "../../Assets/Icons/CustomFormsFill.svg";
import Groups from "../../Assets/Icons/Groups.svg";
import GroupsFill from "../../Assets/Icons/GroupsFill.svg";
import CreditAmount from "../../Assets/Icons/CreditAmount.svg";
import DebitAmount from "../../Assets/Icons/DebitAmount.svg";
import PayLocations from "../../Assets/Icons/PayLocations.svg";
import Drop from "../../Assets/Icons/Mask.svg";
import MenuonlineIcon from "../../Assets/Icons/Icon-Menuonline.svg";
import DigitalSignageIcon from "../../Assets/Icons/Icon-DigitalSignage.svg";
import ScheduleDowncon from "../../Assets/Icons/Icon-ScheduleDown.svg";
import SmartWifiIcon from "../../Assets/Icons/Icon-Smart-Wifi.svg";
import MarketingIcon from "../../Assets/Icons/Icon-Marketing.svg";
import LoyaltyProgramIcon from "../../Assets/Icons/Icon-LoyaltyProram.svg";
import ReputationIcon from "../../Assets/Icons/Icon-Reputation.svg";
import Edit from "../../Assets/Icons/Edit.svg";
import Delete from "../../Assets/Icons/Delete.svg";
import More from "../../Assets/Icons/More.svg";
import Arrow from "../../Assets/Icons/Arrow.svg";
import Rocket from "../../Assets/Icons/Rocket.svg";
import IIcon from "../../Assets/Icons/IIcon.svg";
import IIconBlack from "../../Assets/Icons/IIconBlack.svg";
import Emoji from "../../Assets/Icons/Emoji.svg";
import Image from "../../Assets/Icons/Image.svg";
import Attachment from "../../Assets/Icons/Attachment.svg";
import Template from "../../Assets/Icons/Template.svg";
import CustomField from "../../Assets/Icons/CustomField.svg";
import Link from "../../Assets/Icons/Link.svg";
import LinkActive from "../../Assets/Icons/LinkActive.svg";
import Refresh from "../../Assets/Icons/Refresh.svg";
import TimeFast from "../../Assets/Icons/TimeFast.svg";
import TimeQuarterpast from "../../Assets/Icons/TimeQuarterpast.svg";
import CardMask from "../../Assets/Icons/CardMask.svg";
import CCVisaIcon from "../../Assets/Icons/CC-visa.svg";
import CCMasterIcon from "../../Assets/Icons/CC-mastercard.svg";
import CCAmericaExpIcon from "../../Assets/Icons/CC-american-express.svg";
import CCDiscoverIcon from "../../Assets/Icons/CC-discover.svg";
import CCJcbIcon from "../../Assets/Icons/CC-jcb.svg";
import CCOtherIcon from "../../Assets/Icons/CC-other.svg";
import Plus from "../../Assets/Icons/Plus.svg";
import GreenCheck from "../../Assets/Icons/GreenCheck.svg";
import CustPlanBadge from "../../Assets/Icons/CustPlansBadge.svg";
import Gift from "../../Assets/Icons/Gift.svg";
import GiftWhite from "../../Assets/Icons/GiftWhite.svg";
import UserBlack from "../../Assets/Icons/UserBlack.svg";
import Earnings from "../../Assets/Icons/Earnings.svg";
import BirthdayCake from "../../Assets/gifs/BirthdayCake.gif";
import WelNClient from "../../Assets/gifs/WelNClient.gif";
import WinBClient from "../../Assets/gifs/WinBClient.gif";
import RewardReg from "../../Assets/gifs/RewardReg.gif";
import Ratings from "../../Assets/gifs/Ratings.gif";
import ThankUVist from "../../Assets/gifs/ThankUVist.gif";
import PlusBlue from "../../Assets/Icons/PlusBlue.svg";
import UnsubscribeIcon from "../../Assets/Icons/UnsubscribeIcon.svg";
import SubscribeIcon from "../../Assets/Icons/SubscribeIcon.svg";
import CongratulationGIF from "../../Assets/gifs/CongratulationGIF.gif";
import CongoGIF from "../../Assets/gifs/CongoGIF.gif";
import CongoGIF1 from "../../Assets/gifs/CongoGiF1.gif";
import DeliveryScooter from "../../Assets/gifs/DeliveryScooter.gif";
import Bulb from "../../Assets/Icons/Bulb.svg";
import CloseRed from "../../Assets/Icons/CloseRed.svg";
import DummyLocation from "../../Assets/Icons/DummyLocation.svg";
import Lock from "../../Assets/Icons/Lock.svg";
import Compliance from "../../Assets/Icons/Compliance.svg";
import Ellipse from "../../Assets/Icons/Ellipse.svg";
import LeftArrow from "../../Assets/Icons/LeftArrow.svg";
import QRIcon from "../../Assets/Icons/QRIcon.svg";
import List from "../../Assets/Icons/List.svg";
import DrageIcon from "../../Assets/Icons/DrageIcon.svg";
import timeQuarterPastWhite from "../../Assets/Icons/timeQuarterPastWhite.svg";
import timeFastWhite from "../../Assets/Icons/timeFastWhite.svg";
import notificationWhite from "../../Assets/Icons/notificationWhite.svg";
import CompailanceWh from "../../Assets/Icons/CompailanceWh.svg";
import settingsWhite from "../../Assets/Icons/settingsWhite.svg";
import CheckMarkOr from "../../Assets/Icons/CheckMarkOr.svg";
import CrossCheck from "../../Assets/Icons/crossCheck.svg";
import AngleDown from "../../Assets/Icons/AngleDown.svg";
import AngleUp from "../../Assets/Icons/AngleUp.svg";
import Dominos from "../../Assets/images/Dominos.png";
import NoProfile from "../../Assets/images/NoProfile.jpg";
import OutOfStock from "../../Assets/images/OutOfStock.png";
import Hide from "../../Assets/images/Hide.png";
import Scan from "../../Assets/Icons/Scan.svg";
import LeftRoundArrow from "../../Assets/Icons/LeftRoundArrow.svg";
import Congratulation from "../../Assets/Icons/Congratulation.svg";
import GiftLoyalty from "../../Assets/Icons/GiftLoyalty.svg";
import GiftGrey from "../../Assets/Icons/GiftGrey.svg";
import GiftLoyaltyWhite from "../../Assets/Icons/GiftLoyaltyWhite.svg";
import DownloadWhite from "../../Assets/Icons/DownloadWhite.svg";
import FetchIconBlack from "../../Assets/Icons/FetchIconBlack.svg";
import LocationYellow from "../../Assets/Icons/LocationYellow.svg";
import iIconBordered from "../../Assets/Icons/iIconBordered.svg";
import Del from "../../Assets/Icons/Del.svg";
import MenuQr from "../../Assets/Icons/menuQr.svg";
import Preview from "../../Assets/Icons/preview.svg";
import Show from "../../Assets/Icons/Show.svg";
import DigitalSignage from "../../Assets/Icons/Digital Signage.svg";
import DigitalDevice from "../../Assets/Icons/Digital Device.svg";
import Resmenu from "../../Assets/Icons/res menu.svg";
import MenuOnline from "../../Assets/Icons/menu online.svg";
import ResMarketing from "../../Assets/Icons/res marketing.svg";
import ResWifi from "../../Assets/Icons/res wifi.svg";
import RouterHardware from "../../Assets/Icons/router hardware.svg";
import LotalityProgram from "../../Assets/Icons/loyality program.svg";
import Diamond from "../../Assets/Icons/Diamond.svg";
import Setting from "../../Assets/Icons/Setting.svg";
import Users from "../../Assets/Icons/Users.svg";
import Forever from "../../Assets/Icons/Forever.svg";
import DigitalSigange from "../../Assets/Icons/DigitalSigange.svg";
import OnlineOrder from "../../Assets/Icons/OnlineOrder.svg";
import CommingSoon from "../../Assets/Icons/CommingSoon.svg";
import New from "../../Assets/Icons/New.svg";
import POS from "../../Assets/Icons/POS.svg";
import Reservations from "../../Assets/Icons/Reservations.svg";
import SmartWifi from "../../Assets/Icons/SmartWifi.svg";
import Loyalty from "../../Assets/Icons/Loyalty.svg";
import MenuOnlineModuleIcon from "../../Assets/Icons/MenuOnline.svg";
import GetMoreReviews from "../../Assets/Icons/GetMoreReviews.svg";
import Marketing from "../../Assets/Icons/Marketing.svg";
import Crown from "../../Assets/Icons/Crown.svg";
import LockIcon from "../../Assets/Icons/LockIcon.svg";
import BackHomeBlack from "../../Assets/Icons/BackHomeBlack.svg";
import InstantMenuMSImg from "../../Assets/images/InstantMenuMSImg.png";
import LoyaltyMSImg from "../../Assets/images/LoyaltyMSImg.png";
import ReputationMSImg from "../../Assets/images/ReputationMSImg.png";
import WifiMSImg from "../../Assets/images/WifiMSImg.png";
import ReservationMSImg from "../../Assets/images/ReservationMSImg.png";
import DigitalSignageMSImg from "../../Assets/images/DigitalSignageMSImg.png";
import OnlineOrderMSImg from "../../Assets/images/OnlineOrderMSImg.png";
import POSMSImg from "../../Assets/images/POSMSImg.png";
import MainScreenBG from "../../Assets/images/MainScreenBG.png";
import editOutline from "../../Assets/Icons/editOutline.svg";
import PreviewIcon from "../../Assets/Icons/PreviewIcon.svg";

//side menu
import BackMenuArrow from "../../Assets/Icons/BackMenuArrow.svg";
import WebWidgets from "../../Assets/Icons/WebWidgets.svg";
import WebWidgetsFill from "../../Assets/Icons/WebWidgetsFill.svg";
import QrWidgets from "../../Assets/Icons/QrWidgets.svg";
import QrWidgetsFill from "../../Assets/Icons/QrWidgetsFill.svg";
import ImageWidgets from "../../Assets/Icons/ImageWidgets.svg";
import ImageWidgetsFill from "../../Assets/Icons/ImageWidgetsFill.svg";
import Account from "../../Assets/Icons/Account.svg";
import AccountFill from "../../Assets/Icons/AccountFill.svg";
import Payment from "../../Assets/Icons/Payment.svg";
import PaymentFill from "../../Assets/Icons/PaymentFill.svg";
import Location from "../../Assets/Icons/Location.svg";
import LocationFill from "../../Assets/Icons/LocationFill.svg";
import SubUser from "../../Assets/Icons/SubUser.svg";
import SubUserFill from "../../Assets/Icons/SubUserFill.svg";
import ChangePass from "../../Assets/Icons/ChangePass.svg";
import ChangePassFill from "../../Assets/Icons/ChangePassFill.svg";
import ChangePassFillBlack from "../../Assets/Icons/ChangePassFillBlack.svg";
import SettingBlack from "../../Assets/Icons/SettingBlack.svg";
import SwitchBlack from "../../Assets/Icons/SwitchBlack.svg";
import SwitchWhite from "../../Assets/Icons/SwitchWhite.svg";
import UserSwitchBlack from "../../Assets/Icons/UserSwitchBlack.svg";
import UserSwitchWhite from "../../Assets/Icons/UserSwitchWhite.svg";
import MenuBlack from "../../Assets/Icons/MenuBlack.svg";
import ArrowDownWhite from "../../Assets/Icons/ArrowDownWhite.svg";
import DragIconWhite from "../../Assets/Icons/DragIconWhite.svg";
import DeleteWhite from "../../Assets/Icons/DeleteWhite.svg";
import EditWhite from "../../Assets/Icons/EditWhite.svg";
import TickCircle from "../../Assets/Icons/TickCircle.svg";
import CloseCircle from "../../Assets/Icons/CloseCircle.svg";
import EditGrey from "../../Assets/Icons/EditGrey.svg";
import MoreGrey from "../../Assets/Icons/MoreGrey.svg";
import DeleteGrey from "../../Assets/Icons/DeleteGrey.svg";
import Eye from "../../Assets/Icons/eye.svg";
import EyeCrossed from "../../Assets/Icons/eye-crossed.svg";
import License from "../../Assets/Icons/license.svg";
import LicenseBlack from "../../Assets/Icons/licenseBlack.svg";
import policyWhite from "../../Assets/Icons/policyWhite.svg";
import policyBlack from "../../Assets/Icons/policyBlack.svg";
import policy from "../../Assets/Icons/policy.svg";

// Marketing Module
import Coupon from "../../Assets/gifs/Coupon.gif";
import SMS from "../../Assets/gifs/SMS.gif";
import WhatsAppAni from "../../Assets/gifs/WhatsAppAni.gif";
import CustomFormBlack from "../../Assets/gifs/CustomFormBlack.gif";
import WebWidgetsBlack from "../../Assets/gifs/WebWidgetsBlack.gif";
import QrWidgetsBlack from "../../Assets/gifs/QrWidgetsBlack.gif";
import ImageWidgetsBlack from "../../Assets/gifs/ImageWidgetsBlack.gif";
import CouponCampaign from "../../Assets/images/CouponCampaign.png";
import WhatsappCampaign from "../../Assets/images/WhatsappCampaign.png";
import BirthdaySpacial from "../../Assets/images/BirthdaySpacial.png";
import WelcomeNewClient from "../../Assets/images/WelcomeNewClient.png";
import WinBackClient from "../../Assets/images/WinBackClient.png";
import RewardRegular from "../../Assets/images/RewardRegular.png";
import GetMoreRatings from "../../Assets/images/GetMoreRatings.png";
import ThankYouForVisiting from "../../Assets/images/ThankYouForVisiting.png";
import ESignUp from "../../Assets/images/ESignUp.png";
import CustomForm from "../../Assets/images/CustomForm.png";
import SmartQR from "../../Assets/images/SmartQR.png";
import OptInNumber from "../../Assets/images/OptInNumber.png";
import DeliveryReditrection from "../../Assets/images/DeliveryReditrection.png";
import SmartQR1 from "../../Assets/images/SmartQR1.png";
import SmartQR2 from "../../Assets/images/SmartQR2.png";
import SmartQR3 from "../../Assets/images/SmartQR3.png";
import SmartQR4 from "../../Assets/images/SmartQR4.png";
import SmartQR5 from "../../Assets/images/SmartQR5.png";
import SmartQR6 from "../../Assets/images/SmartQR6.png";
import SmartQR7 from "../../Assets/images/SmartQR7.png";
import SmartQR8 from "../../Assets/images/SmartQR8.png";
import SmartQR9 from "../../Assets/images/SmartQR9.png";
import SmartQR10 from "../../Assets/images/SmartQR10.png";
import SmartQR11 from "../../Assets/images/SmartQR11.png";
import SmartQR12 from "../../Assets/images/SmartQR12.png";
import SmsCampaign from "../../Assets/images/SmsCampaign.png";
import blastSMSArrow from "../../Assets/Icons/blastSMSArrow.svg";
import ArrowRightOrange from "../../Assets/Icons/ArrowRightOrange.svg";
import ArrowRightWhite from "../../Assets/Icons/ArrowRightWhite.svg";
import WhiteMenu from "../../Assets/Icons/WhiteMenu.svg";
import AutoResponderBannerGif from "../../Assets/gifs/AutoResponderBannerGif.gif";
import AutoResponderGifSM from "../../Assets/gifs/AutoResponderGifSM.gif";
import InstantMenuGif from "../../Assets/gifs/InstantMenuGif.gif";
import AutoResponderMSGif from "../../Assets/gifs/AutoResponderMSGif.gif";
import Line from "../../Assets/Icons/Line.svg";

// clients icons
import CustForms from "../../Assets/Icons/CustForms.svg";
import WIFI from "../../Assets/Icons/WIFI.svg";
import QRGreyIcon from "../../Assets/Icons/QRGreyIcon.svg";
import WebSignUp from "../../Assets/Icons/WebSignUp.svg";
import DownloadIcon from "../../Assets/Icons/DownloadIcon.svg";
import UserRemove from "../../Assets/Icons/UserRemove.svg";
import editNewWhite from "../../Assets/Icons/editNewWhite.svg";

// sub user login
import Num0 from "../../Assets/Icons/Num0.svg";
import Num1 from "../../Assets/Icons/Num1.svg";
import Num2 from "../../Assets/Icons/Num2.svg";
import Num3 from "../../Assets/Icons/Num3.svg";
import Num4 from "../../Assets/Icons/Num4.svg";
import Num5 from "../../Assets/Icons/Num5.svg";
import Num6 from "../../Assets/Icons/Num6.svg";
import Num7 from "../../Assets/Icons/Num7.svg";
import Num8 from "../../Assets/Icons/Num8.svg";
import Num9 from "../../Assets/Icons/Num9.svg";
import NumBack from "../../Assets/Icons/NumBack.svg";
import NumCancel from "../../Assets/Icons/NumCancel.svg";
import Pin from "../../Assets/Icons/Pin.svg";

// two - way chat
import LikeIcon from "../../Assets/Icons/LikeIcon.svg";
import RefreshIcon from "../../Assets/Icons/RefreshIcon.svg";
import FavouriteRed from "../../Assets/Icons/FavouriteRed.svg";
import FavouriteWhite from "../../Assets/Icons/FavouriteWhite.svg";
import DeleteUser from "../../Assets/Icons/DeleteUser.svg";
import Trash from "../../Assets/Icons/Trash.svg";
import UploadFile from "../../Assets/Icons/UploadFile.svg";
import FileDownload from "../../Assets/Icons/FileDownload.svg";

// Wifi module
import Hotspot from "../../Assets/Icons/Hotspot.svg";
import HotspotFill from "../../Assets/Icons/HotspotFill.svg";
import SplashPages from "../../Assets/Icons/SplashPages.svg";
import SplashPagesFill from "../../Assets/Icons/SplashPagesFill.svg";

// hotspots
import Info from "../../Assets/Icons/Info.svg";

//wifi
import PhoneFrame from "../../Assets/Icons/PhoneFrame.svg";
import Wifi_Icon from "../../Assets/Icons/Wifi_Icon.svg";
import WifiLoginPicture from "../../Assets/Icons/WifiLoginPicture.svg";
import GoogleIcon from "../../Assets/Icons/GoogleIcon.svg";
import FbIcon from "../../Assets/Icons/FbIcon.svg";
import MaleIcon from "../../Assets/Icons/MaleIcon.svg";
import FemaleIcon from "../../Assets/Icons/FemaleIcon.svg";
import OtherGen from "../../Assets/Icons/OtherGen.svg";
import MaleIconActive from "../../Assets/Icons/MaleIconActive.svg";
import FemaleIconActive from "../../Assets/Icons/FemaleIconActive.svg";
import OtherGenActive from "../../Assets/Icons/OtherGenActive.svg";
import Hashtag from "../../Assets/Icons/Hashtag.svg";
import MailIcon from "../../Assets/Icons/MailIcon.svg";
import UserIcon from "../../Assets/Icons/UserIcon.svg";
import RefreshWhite from "../../Assets/Icons/RefreshWhite.svg";
import AddUserBlack from "../../Assets/Icons/AddUserBlack.svg";
import TimeLineIcon from "../../Assets/Icons/TimeLineIcon.svg";
import RightSideArrow from "../../Assets/Icons/RightSideArrow.svg";
import WifiGif from "../../Assets/gifs/WifiNotActive.gif";
import MinusWhiteIcon from "../../Assets/Icons/MinusIconWhite.svg";
import PlusWhiteIcon from "../../Assets/Icons/PlusIconWhite.svg";
import PlusBlackIcon from "../../Assets/Icons/PlusIconBlack.svg";
import MailLargeIcon from "../../Assets/Icons/MailLargeIcon.svg";
import PhoneLargeIcon from "../../Assets/Icons/PhoneLargeIcon.svg";
import LocationLargeIcon from "../../Assets/Icons/LocationLargeIcon.svg";
import BiteMe from "../../Assets/Icons/BiteMeLogo.svg";
import Bars from "../../Assets/Icons/Bars.svg";
import FooterLogo from "../../Assets/Icons/FooterLogo.svg";
import SliderBg from "../../Assets/Icons/SliderBg.svg";
import RatingStar from "../../Assets/Icons/RatingStar.svg";
import SliderArrowLeft from "../../Assets/Icons/SliderArrowLeft.svg";
import SliderArrowRight from "../../Assets/Icons/SliderArrowRight.svg";
import Share from "../../Assets/Icons/Share.svg";
import IframeLayer from "../../Assets/Icons/IframeLayer.svg";

// menu online side bar
import Reservation from "../../Assets/Icons/Reservation.svg";
import ReservationBlack from "../../Assets/Icons/ReservationBlack.svg";
import ReservationSideSquare from "../../Assets/Icons/ReservationSideSquare.svg";
import ReservationSideSquareR from "../../Assets/Icons/ReservationSideSquareR.svg";
import ReservationBtnArrowR from "../../Assets/Icons/ReservationBtnArrowR.svg";
import ReservationBtnArrowL from "../../Assets/Icons/ReservationBtnArrowL.svg";
import ReservationDownArrow from "../../Assets/Icons/ReservationDownArrow.svg";
import ReservationDoneArrow from "../../Assets/Icons/ReservationDoneArrow.svg";
import Table from "../../Assets/Icons/Table.svg";
import Menu from "../../Assets/Icons/Menu.svg";
import DownExpandArrow from "../../Assets/Icons/DownExpandArrow.svg";
import RightLongArrow from "../../Assets/Icons/RightLongArrow.svg";
import LeftLongArrow from "../../Assets/Icons/LeftLongArrow.svg";
import DownFillArrow from "../../Assets/Icons/DownFillArrow.svg";
import RestaurentIcon from "../../Assets/Icons/RestaurentIcon.svg";
import MenuDownIcon from "../../Assets/Icons/MenuDownIcon.svg";
import newMenu from "../../Assets/images/newMenu.svg";
import newRec from "../../Assets/images/newrec.png";
import QrBorder from "../../Assets/images/QrBorder.png";
import GiftCard from "../../Assets/images/giftcard.png";
import Congratulations from "../../Assets/images/congratulations.svg";

//reputation
import ThumbDown from "../../Assets/Icons/ThumbDown.svg";
import ThumbUp from "../../Assets/Icons/ThumbUp.svg";
import ThumbDownRed from "../../Assets/Icons/ThumbDownRed.svg";
import ThumbUpGreen from "../../Assets/Icons/ThumbUpGreen.svg";
import ThumbUpOrange from "../../Assets/Icons/ThumbUpOrange.svg";
import ThumbDownBlack from "../../Assets/Icons/ThumbDownBlack.svg";
import ArrowRight from "../../Assets/Icons/ArrowRight.svg";
import chargingStation from "../../Assets/Icons/chargingStation.svg";
import Signal from "../../Assets/Icons/Signal.svg";
import CurveArrow1 from "../../Assets/Icons/CurveArrow1.svg";
import CurveArrow2 from "../../Assets/Icons/CurveArrow2.svg";
import BgArrowLeft from "../../Assets/Icons/BgArrowLeft.svg";
import BgArrowRight from "../../Assets/Icons/BgArrowRight.svg";
import smileIcon from "../../Assets/Icons/smileIcon.svg";
import editNew from "../../Assets/Icons/editNew.svg";
import qrCodeNew from "../../Assets/Icons/qrCodeNew.svg";
import deleteNew from "../../Assets/Icons/deleteNew.svg";
import userNew from "../../Assets/Icons/userNew.svg";
import editNewGrey from "../../Assets/Icons/editNewGrey.svg";
import deleteNewGrey from "../../Assets/Icons/deleteNewGrey.svg";
import userNewGrey from "../../Assets/Icons/userNewGrey.svg";
import ReviewImg from "../../Assets/images/ReviewImg.png";

//location
import Hitchhiker from "../../Assets/gifs/Hitchhiker.gif";
import GroupCongo from "../../Assets/gifs/GroupCongo.gif";
import Box from "../../Assets/Icons/Box.svg";
import LocationIcon from "../../Assets/Icons/LocationIcon.svg";
import FetchIcon from "../../Assets/Icons/FetchIcon.svg";
import DummyQR from "../../Assets/Icons/DummyQR.svg";

//Loyaly Program
import ScanMe from "../../Assets/Icons/ScanMe.svg";
import ScanemeImage from "../../Assets/Icons/ScanemeImage.svg";
import ScannMeYellow from "../../Assets/Icons/ScanMeYellow.svg";
import LoyaltyUser from "../../Assets/Icons/LoyaltyUser.svg";

// filterImages
import Veg from "../../Assets/images/FilterImages/Veg.jpg";
import NonVeg from "../../Assets/images/FilterImages/NonVeg.png";
import Best from "../../Assets/images/FilterImages/Best.jpg";

// office Supplies
import officeSupplies1 from "../../Assets/images/officeSupplies1.jpg";
import officeSupplies2 from "../../Assets/images/officeSupplies2.jpg";
import officeSupplies3 from "../../Assets/images/officeSupplies3.jpg";
// Icon
import OfficeSuppliesIcon from "../../Assets/Icons/officeSupplies.svg";
import OfficeSuppliesIconFill from "../../Assets/Icons/officeSuppliesWhite.svg";
import records from "../../Assets/Icons/records.svg";

// Reseller
import Sell from "../../Assets/Icons/sell.svg";
import Device from "../../Assets/Icons/device.svg";
import BackHome from "../../Assets/Icons/BackHome.svg";

// ScheduleDown
import ScheduleDown_logo from "../../Assets/images/ScheduleDown_logo.svg";

// Banner
import Mic from "../../Assets/Icons/Mic.svg";
import BlastSMSBannerImage from "../../Assets/images/BlastSMSBannerImage.png";
import BannerBg from "../../Assets/images/BannerBg.png";
import MDashBoardBImg from "../../Assets/images/MDashBoardBImg.png";
import MarketingBannerImg from "../../Assets/images/MarketingBannerImg.png";
import AutoResponderBannerImg from "../../Assets/images/AutoResponderBannerImg.png";
import CustFormBannerImg from "../../Assets/images/CustFormBannerImg.png";
import ReservationBannerImg from "../../Assets/images/ReservationBannerImg.png";
import WifiBannerImg from "../../Assets/images/WifiBannerImg.png";
import LoyaltyBannerImg from "../../Assets/images/LoyaltyBannerImg.png";
import InstantMenuBannerImg from "../../Assets/images/InstantMenuBannerImg.png";
import ReputationBannerImg from "../../Assets/images/ReputationBannerImg.png";
import MarketingBannerImgSM from "../../Assets/images/MarketingBannerImgSM.png";
import BannerBgSM from "../../Assets/images/BannerBgSM.png";
import BlastSMSBannerImageSM from "../../Assets/images/BlastSMSBannerImageSM.png";
import AutoResponderBannerImgSM from "../../Assets/images/AutoResponderBannerImgSM.png";
import CustFormBannerImgSM from "../../Assets/images/CustFormBannerImgSM.png";
import ReservationBannerImgSM from "../../Assets/images/ReservationBannerImgSM.png";
import WifiBannerImgSM from "../../Assets/images/WifiBannerImgSM.png";
import LoyaltyBannerImgSM from "../../Assets/images/LoyaltyBannerImgSM.png";
import InstantMenuBannerImgSM from "../../Assets/images/InstantMenuBannerImgSM.png";
import ReputationBannerImgSM from "../../Assets/images/ReputationBannerImgSM.png";
import WifiDashBannerImg from "../../Assets/images/WifiDashBannerImg.png";
import GetNewCustomer from '../../Assets/gifs/GetNewCustomer.gif'

// Landing Page
import LandingHerSectionBg from "../../Assets/images/LandingHerSectionBg.png";
import DisplayPromo from "../../Assets/images/DisplayPromo.png";
import DigitalMenu from "../../Assets/images/DigitalMenu.png";
import CommunicateCustomers from "../../Assets/images/CommunicateCustomers.png";
import DigitalMenuZeroCost from "../../Assets/images/DigitalMenuZeroCost.png";
import InstantMenuNotification from "../../Assets/images/InstantMenuNotification.png";
import Marketplace from "../../Assets/images/Marketplace.png";
import GetFreeMenu from "../../Assets/images/GetFreeMenu.png";
import AboutUsLanding from "../../Assets/images/AboutUsLanding.png";
import QuickSignup from "../../Assets/images/QuickSignup.png";
import CustomizeMenu from "../../Assets/images/CustomizeMenu.png";
import ShareAndGrow from "../../Assets/images/ShareAndGrow.png";
import Rating from "../../Assets/Icons/Rating.svg";
import checkbox from "../../Assets/Icons/checkbox.svg";
import sliderButton from "../../Assets/Icons/sliderButton.svg";
import OrangeArrow from "../../Assets/Icons/OrangeArrow.svg";
import FreeMenuFormSubmit from "../../Assets/images/FreeMenuFormSubmit.png";
import Promotions from "../../Assets/gifs/Promotions.gif";
import TestimonialThumb3 from "../../Assets/images/TestimonialThumb3.png";
import TestimonialImg3 from "../../Assets/images/TestimonialImg3.png";
import TestimonialThumb2 from "../../Assets/images/TestimonialThumb2.png";
import TestimonialImg2 from "../../Assets/images/TestimonialImg2.png";
import TestimonialThumb1 from "../../Assets/images/TestimonialThumb1.png";
import TestimonialImg1 from "../../Assets/images/TestimonialImg1.png";
import GetQueUser from "../../Assets/images/GetQueUser.png";
import Avatar1 from "../../Assets/images/Avatar1.png";
import Avatar2 from "../../Assets/images/Avatar2.png";
import Avatar3 from "../../Assets/images/Avatar3.png";
import Avatar4 from "../../Assets/images/Avatar4.png";
import GetFreeMenuDialogImg from "../../Assets/images/GetFreeMenuDialogImg.png";
import User1 from "../../Assets/images/User1.png";
import GetFreeDlgBg from "../../Assets/images/GetFreeDlgBg.png";
import TDMenus from "../../Assets/gifs/3DMenus.gif";
import FormSubmit from "../../Assets/gifs/FormSubmit.gif";
import GetNewCustomerIcon from '../../Assets/Icons/GetNewCustomer.svg'
import GetNewCustomerWhite from '../../Assets/Icons/GetNewCustomerWhite.svg'
import LanguageGrey from '../../Assets/Icons/LanguageGrey.svg'
import LanguageBlack from '../../Assets/Icons/LanguageBlack.svg'

const staticIcons = {
  MycircleLoginLogo,
  MyCircleLogo,
  AutoResponders,
  AutoRespondersFill,
  BlastCampaign,
  BlastCampaignFill,
  Client,
  ClientFill,
  Dashboard,
  DashboardFill,
  Dot,
  ReservationMblRgtArt,
  ReservationMblLftArt,
  LocationLargeIcon,
  Tools,
  ToolsFill,
  TimeLineIcon,
  Search,
  Chat,
  Notification,
  Switch,
  User,
  MailLargeIcon,
  PhoneLargeIcon,
  Settings,
  BusinessSettings,
  HelpCenter,
  Logout,
  Envelope,
  CloseRound,
  RequiredStar,
  CFCheck,
  CFCopy,
  CopyGrey,
  CFCross,
  CFDown,
  CFUp,
  Comment,
  Whatsapp,
  Drop,
  MenuonlineIcon,
  DigitalSignageIcon,
  ScheduleDowncon,
  SmartWifiIcon,
  MarketingIcon,
  LoyaltyProgramIcon,
  ReputationIcon,
  Link,
  LinkActive,
  Close,
  IIcon,
  IIconBlack,
  Emoji,
  DrageIcon,
  Image,
  Attachment,
  Template,
  CustomField,
  CustomForms,
  RightSideArrow,
  CustomFormsFill,
  CustomFormBlack,
  Groups,
  GroupsFill,
  SubClient,
  SubClientFill,
  CreditAmount,
  DebitAmount,
  PayLocations,
  Edit,
  Delete,
  More,
  Refresh,
  TimeFast,
  TimeQuarterpast,
  CardMask,
  CCVisaIcon,
  CCMasterIcon,
  CCAmericaExpIcon,
  CCDiscoverIcon,
  CCJcbIcon,
  CCOtherIcon,
  Plus,
  GreenCheck,
  CustPlanBadge,
  Gift,
  GiftWhite,
  UserBlack,
  BirthdayCake,
  WelNClient,
  Earnings,
  WinBClient,
  RewardReg,
  Ratings,
  ThankUVist,
  PlusBlue,
  CongratulationGIF,
  CongoGIF,
  CongoGIF1,
  DeliveryScooter,
  Bulb,
  CloseRed,
  DummyLocation,
  Lock,
  Compliance,
  UnsubscribeIcon,
  SubscribeIcon,
  LeftArrow,
  Ellipse,
  TestSMS,
  SendMsg,
  QRIcon,
  List,
  timeQuarterPastWhite,
  timeFastWhite,
  notificationWhite,
  CompailanceWh,
  settingsWhite,
  Arrow,
  PendingStatus,
  MarkAsCompleted,
  Rocket,
  CheckMarkOr,
  CrossCheck,
  AngleUp,
  AngleDown,
  Dominos,
  NoProfile,
  OutOfStock,
  Hide,
  Scan,
  LeftRoundArrow,
  Congratulation,
  GiftLoyalty,
  GiftGrey,
  GiftLoyaltyWhite,
  DownloadWhite,
  FetchIconBlack,
  LocationYellow,
  iIconBordered,

  // No data img
  NoDataImg,

  //Error Page
  Page302,
  Page404,
  Page500,

  // side menu
  BackMenuArrow,
  WebWidgets,
  WebWidgetsFill,
  WebWidgetsBlack,
  QrWidgets,
  QrWidgetsFill,
  QrWidgetsBlack,
  ImageWidgets,
  ImageWidgetsFill,
  ImageWidgetsBlack,
  Account,
  AccountFill,
  Payment,
  PaymentFill,
  Location,
  LocationFill,
  SubUser,
  SubUserFill,
  ChangePass,
  ChangePassFill,
  ChangePassFillBlack,
  ReservationSideSquare,
  ReservationSideSquareR,
  ReservationBtnArrowR,
  ReservationBtnArrowL,
  ReservationDownArrow,
  ReservationDoneArrow,
  SettingBlack,
  SwitchBlack,
  SwitchWhite,
  UserSwitchBlack,
  UserSwitchWhite,
  MenuBlack,
  ArrowDownWhite,
  DragIconWhite,
  EditWhite,
  DeleteWhite,
  CloseCircle,
  TickCircle,
  DeleteGrey,
  EditGrey,
  MoreGrey,
  records,
  Eye,
  EyeCrossed,
  Del,
  Show,
  MenuQr,
  Preview,
  License,
  LicenseBlack,
  policyWhite,
  policyBlack,
  policy,

  // Marketing Module
  Coupon,
  SMS,
  WhatsAppAni,
  DigitalSignage,
  DigitalDevice,
  Resmenu,
  MenuOnline,
  ResMarketing,
  ResWifi,
  RouterHardware,
  LotalityProgram,
  Diamond,
  Setting,
  Users,
  Forever,
  DigitalSigange,
  OnlineOrder,
  CommingSoon,
  New,
  POS,
  Reservations,
  SmartWifi,
  Loyalty,
  GetMoreReviews,
  MenuOnlineModuleIcon,
  Marketing,
  Crown,
  LockIcon,
  BackHomeBlack,
  InstantMenuMSImg,
  LoyaltyMSImg,
  ReputationMSImg,
  WifiMSImg,
  ReservationMSImg,
  DigitalSignageMSImg,
  OnlineOrderMSImg,
  POSMSImg,
  MainScreenBG,
  PreviewIcon,
  editOutline,
  CouponCampaign,
  blastSMSArrow,
  WhatsappCampaign,
  BirthdaySpacial,
  WelcomeNewClient,
  WinBackClient,
  RewardRegular,
  GetMoreRatings,
  ThankYouForVisiting,
  ESignUp,
  CustomForm,
  SmartQR,
  OptInNumber,
  DeliveryReditrection,
  SmartQR1,
  SmartQR2,
  SmartQR3,
  SmartQR4,
  SmartQR5,
  SmartQR6,
  SmartQR7,
  SmartQR8,
  SmartQR9,
  SmartQR10,
  SmartQR11,
  SmartQR12,
  SmsCampaign,
  ArrowRightOrange,
  ArrowRightWhite,
  WhiteMenu,
  AutoResponderBannerGif,
  AutoResponderGifSM,
  InstantMenuGif,
  AutoResponderMSGif,
  Line,

  //client icons
  CustForms,
  WIFI,
  QRGreyIcon,
  WebSignUp,
  DownloadIcon,
  UserRemove,
  editNewWhite,

  // sub user login
  Num0,
  Num1,
  Num2,
  Num3,
  Num4,
  Num5,
  Num6,
  Num7,
  Num8,
  Num9,
  NumBack,
  NumCancel,
  Pin,

  // two - way chat
  RefreshIcon,
  LikeIcon,
  FavouriteRed,
  FavouriteWhite,
  DeleteUser,
  Trash,
  UploadFile,
  FileDownload,

  // Wifi module
  Hotspot,
  HotspotFill,
  SplashPages,
  SplashPagesFill,

  // hotspots
  Info,

  // wifi
  PhoneFrame,
  Wifi_Icon,
  WifiGif,
  WifiLoginPicture,
  GoogleIcon,
  FbIcon,
  MaleIcon,
  FemaleIcon,
  OtherGen,
  MaleIconActive,
  FemaleIconActive,
  OtherGenActive,
  Hashtag,
  MailIcon,
  UserIcon,
  RefreshWhite,
  AddUserBlack,

  // Menuonline
  Reservation,
  ReservationBlack,
  Table,
  Menu,
  DownExpandArrow,
  RightLongArrow,
  LeftLongArrow,

  MinusWhiteIcon,
  PlusWhiteIcon,

  // Menu Items
  PlusBlackIcon,

  // Home
  BiteMe,
  Bars,
  SliderBg,
  RatingStar,
  SliderArrowLeft,
  SliderArrowRight,
  // footer
  FooterLogo,
  Share,
  IframeLayer,

  // Menu
  DownFillArrow,
  RestaurentIcon,
  MenuDownIcon,
  newMenu,
  newRec,
  QrBorder,
  GiftCard,
  Congratulations,

  //reputation
  ThumbDownRed,
  ThumbUp,
  ThumbUpGreen,
  ThumbDown,
  ThumbDownBlack,
  ThumbUpOrange,
  ArrowRight,
  chargingStation,
  Signal,
  CurveArrow1,
  CurveArrow2,
  ReviewImg,
  BgArrowLeft,
  BgArrowRight,
  smileIcon,
  editNew,
  deleteNew,
  userNew,
  editNewGrey,
  deleteNewGrey,
  userNewGrey,
  qrCodeNew,

  //location
  Hitchhiker,
  Box,
  GroupCongo,
  LocationIcon,
  FetchIcon,
  DummyQR,

  //Loyaly Program
  ScanMe,
  ScanemeImage,
  ScannMeYellow,
  LoyaltyUser,

  //Filter icons
  Veg,
  NonVeg,
  Best,

  // office Supplies
  officeSupplies1,
  officeSupplies2,
  officeSupplies3,
  OfficeSuppliesIcon,
  OfficeSuppliesIconFill,

  // Reseller
  Sell,
  Device,
  BackHome,

  // ScheduleDown
  ScheduleDown_logo,
  //Banner
  Mic,
  BlastSMSBannerImage,
  BannerBg,
  MDashBoardBImg,
  MarketingBannerImg,
  AutoResponderBannerImg,
  CustFormBannerImg,
  ReservationBannerImg,
  WifiBannerImg,
  LoyaltyBannerImg,
  InstantMenuBannerImg,
  ReputationBannerImg,
  MarketingBannerImgSM,
  BannerBgSM,
  BlastSMSBannerImageSM,
  AutoResponderBannerImgSM,
  CustFormBannerImgSM,
  ReservationBannerImgSM,
  WifiBannerImgSM,
  LoyaltyBannerImgSM,
  InstantMenuBannerImgSM,
  ReputationBannerImgSM,
  WifiDashBannerImg,
  GetNewCustomer,
  GetNewCustomerIcon,
  GetNewCustomerWhite,

  // Landing Page
  LandingHerSectionBg,
  Rating,
  checkbox,
  Promotions,
  DisplayPromo,
  DigitalMenu,
  CommunicateCustomers,
  DigitalMenuZeroCost,
  InstantMenuNotification,
  Marketplace,
  GetFreeMenu,
  AboutUsLanding,
  QuickSignup,
  CustomizeMenu,
  ShareAndGrow,
  TestimonialThumb3,
  TestimonialImg3,
  TestimonialThumb2,
  TestimonialImg2,
  TestimonialThumb1,
  TestimonialImg1,
  GetQueUser,
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  sliderButton,
  OrangeArrow,
  TDMenus,
  FormSubmit,
  GetFreeMenuDialogImg,
  User1,
  GetFreeDlgBg,
  FreeMenuFormSubmit,
  LanguageBlack,
  LanguageGrey,
};

export default staticIcons;
