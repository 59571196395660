import { useEffect, useState } from "react";
import Localize from "../locales/Localize";
import { PublicVariables } from "../Helper/Language/languageConstant";
import { subscribeToVariableChanges } from "./variableManager";

const useLanguageChange = () => {
    const [currentLanguageLocalization, setCurrentLanguageLocalization] = useState(Localize[PublicVariables?.currentLanguageObj?.languageCode]);

    useEffect(() => {
        const handleChange = () => {
            // Update the localized language object whenever PublicVariables.currentLanguageObj changes
            setCurrentLanguageLocalization(Localize[PublicVariables?.currentLanguageObj?.languageCode]);
        };

        // Subscribe to variable changes
        const unsubscribe = subscribeToVariableChanges(handleChange);

        // Clean up function
        return () => {
            // Unsubscribe from variable changes
            unsubscribe();
        };
    }, []); // This effect runs only once when the component mounts

    return currentLanguageLocalization;
};

export default useLanguageChange;
