const es = {
    "rated_google_reviews": "Calificado con 5,0 en las reseñas de Google",
    "get_your_digital_menu": "Obtenga su menú digital",
    "instant_popup_menu": "Menú emergente instantáneo sin escaneo de código QR",
    "free_forever": "Libre para siempre",
    "the_future_is_here": "¡El futuro está aquí!",
    "upgrade_your_restaurant": "Actualice su restaurante con un menú digital espectacular sin costo alguno. Atraiga más clientes desde hoy.",
    "get_my_free_menu_now": "Obtenga mi menú gratis ahora",
    "no_credit_card_required": "*No se requiere tarjeta de crédito",
    "join_largest_marketplace": "¡Únete al mercado de restaurantes más grande!",
    "what_will_you_get": "¿Qué obtendrás?",
    "free": "GRATIS",
    "free_digital_menu": "Menú digital gratuito 2D o 3D",
    "free_digital_menu_desc": "Diga adiós a los menús en papel y a las costosas reimpresiones",
    "quick_and_easy_signup": "Registro rápido y sencillo",
    "communicate_with_customers_title": "¡Comunícate con tus clientes GRATIS!",
    "features1": [
        "Ahorre en costos de impresión y disfrute de actualizaciones instantáneas del menú cuando las necesite.",
        "Accede a tu menú digital desde cualquier dispositivo",
        "Ecológico y sin complicaciones."
    ],
    "features2": [
        "Permita que sus clientes exploren virtualmente sus platos con impresionantes imágenes en 3D.",
        "Mejore la participación ofreciendo a los comensales una vista previa realista de su comida.",
        "Destaque con una experiencia de menú que sea tan tentadora como el sabor de su comida."
    ],
    "features3": [
        "Salude instantáneamente a sus invitados con su menú cuando ingresen al restaurante.",
        "Envíe automáticamente actualizaciones sobre ofertas especiales y promociones a sus teléfonos.",
        "Mejore la experiencia gastronómica con acceso rápido y sin contacto a su menú."
    ],
    "feature4": [
        "Resalte ofertas de temporada, especiales diarios y promociones exclusivas.",
        "Llame la atención de los clientes hacia sus artículos más populares.",
        "Cree urgencia y entusiasmo con imágenes y descripciones llamativas."
    ],
    "feature5": [
        "Sea parte de una comunidad en línea con miles de visitantes diarios.",
        "Aumente la visibilidad y llegue a nuevos clientes que buscan activamente opciones gastronómicas.",
        "Reserve un lugar destacado con anticipación para maximizar su exposición y atraer a más comensales."
    ],
    "section3_heading": "¿Por qué pasarse a lo digital? ¡Porque es gratis y fácil!",
    "section3_description": "Desbloquee funciones potentes diseñadas para aumentar el atractivo de su restaurante.",
    "instant_menu": "Menú instantáneo",
    "instant_menu_notification": "Ventanas emergentes y notificaciones",
    "instant_menu_description": "Reciba a sus invitados con acceso instantáneo al menú tan pronto como ingresen. Envíe automáticamente el menú más reciente y las ofertas especiales directamente a sus teléfonos móviles",
    "digital_menu": "Menús digitales",
    "at_zero_cost": "A coste cero",
    "digital_menu_description1": "Diga adiós a los menús en papel y a las costosas reimpresiones. ¡Cambie a un menú digital que siempre está actualizado y es gratis para siempre!",
    "digital_menu_description2": "Con nuestro menú digital, sus ofertas siempre estarán actualizadas y accesibles desde cualquier dispositivo. Ahorre dinero y simplifique las actualizaciones del menú con una solución sencilla y sin costo.",
    "try_me_button": "Pruébame",
    "try_3d_menu_title": "Menús 3D",
    "try_3d_menu_title2": "¡Gratis! ¡GUAU!",
    "try_3d_menu_description1": "Haz que tus platos destaquen en la pantalla. Sube imágenes en 3D y permite que los clientes prueben virtualmente tu comida antes de entrar.",
    "try_3d_menu_description2": "Las imágenes 3D de alta calidad crean una vista previa apetitosa, mejorando el atractivo y fomentando los pedidos.",
    "try_now": "Probar ahora",
    "showcase_title": "Muestra tu",
    "showcase_specials": "Especiales",
    "showcase_specials_description1": "¿Tiene una oferta deliciosa o una promoción inmejorable? Resáltela en su menú digital y atraiga a más clientes hambrientos.",
    "showcase_specials_description2": "Haga que las promociones sean imposibles de pasar por alto, llamando la atención con imágenes llamativas y descripciones atractivas.",
    "marketplace": "Mercado",
    "marketplace_description": "Estamos creando un mercado con más de 2000 visitantes diarios. Súbete ahora y serás el primero en la fila cuando lo lancemos. Puedes llegar a nuevos clientes que buscan activamente opciones para comer. ¡Reserva tu lugar con anticipación para maximizar la exposición!",
    "see_other_products": "Ver otros productos",
    "about_us": "Sobre nosotros",
    "why_serving_free": "¿Por qué ofrecemos esto?",
    "serving_free_highlight": "Gratis",
    "about_desc": "Somos gente local aquí en Dallas, comprometidos a ayudar a que restaurantes como el suyo prosperen.",
    "about_tool_free": "Ofrecemos esta poderosa herramienta totalmente gratis porque creemos en apoyar a nuestra comunidad.",
    "no_catch": "No es broma, no es trampa. Sabemos que cuando tienes éxito, nuestra comunidad se fortalece. 😊",
    "understanding_challenges": "Entendemos los desafíos de administrar un negocio, por eso nos dedicamos a brindar herramientas que faciliten sus operaciones y amplíen su alcance.",
    "community_growth": "A medida que creces, nuestra comunidad crece contigo, lo que genera un efecto dominó de éxito y apoyo. Juntos, mejoremos la experiencia gastronómica para todos.",
    "how_it_works": "Cómo funciona",
    "three_simple_steps": "Tres sencillos pasos para",
    "get_started_highlight": "Empezar",
    "no_tech_skills_needed": "No se necesitan conocimientos técnicos. Si puedes darle la vuelta a una hamburguesa, ¡puedes hacer esto!",
    "step1_title": "¡Muestra tu promoción GRATIS!",
    "step1_desc": "¡Empiece más rápido de lo que tarda en decir \"Haga su pedido\"! Simplemente complete un formulario simple.",
    "step2_title": "Personaliza tu menú",
    "step2_desc": "Añade tus platos, sube fotos incluso en 3D y resalta tus especialidades.",
    "step3_title": "Compartir y crecer",
    "step3_desc": "Comparte tu menú digital a través de QR, redes sociales o tu sitio web.",
    "testimonials": "Testimonios",
    "testimonials_section_title": "No se fíe sólo de nuestras palabras",
    "testimonials_section_desc": "Vea lo que dicen otros propietarios de restaurantes de Dallas.",
    "faq_section_title": "¿Tienes preguntas? Tenemos respuestas",
    "faq_section_desc": "Todo lo que necesitas saber antes de empezar.",
    "faqs": "Preguntas frecuentes",
    "faq_answers": [
        {
            "que": "¿Es realmente gratis para siempre?",
            "ans": "¡Por supuesto! Sin cargos ocultos, sin tarjetas de crédito, sin condiciones."
        },
        {
            "que": "¿Necesito algún conocimiento técnico?",
            "ans": "Si puedes enviar un correo electrónico, puedes hacerlo. Es así de sencillo."
        },
        {
            "que": "¿Por qué ofreces esto gratis?",
            "ans": "Creemos en dar primero. En el futuro, es posible que tengamos otros servicios que le gusten, pero eso depende exclusivamente de usted."
        },
        {
            "que": "¿Qué pasa con el mercado?",
            "ans": "¡Estamos trabajando en ello! Una vez que tengamos suficientes restaurantes a bordo, lanzaremos su mercado para que tenga aún más exposición."
        }
    ],
    "testimonials_content1": "¡Cambiar a un menú digital fue la mejor decisión que tomamos! ¡A nuestros clientes les encanta la comodidad y el diseño actualizado!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "El restaurante de la mansión",
    "testimonials_content2": "¡Nuestro nuevo menú digital hace que la navegación sea más sencilla para los huéspedes y realmente aumenta la participación!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Comidas urbanas",
    "testimonials_content3": "Los invitados quedan sorprendidos por las imágenes en 3D: ¡nos han ayudado a destacar y a mejorar su experiencia gastronómica!",
    "testimonials_name3": "Davis verde",
    "testimonials_restaurant_name3": "Hogar de la ciudad",
    "ready_to_boost_your_restaurant": "¿Listo para impulsar su restaurante?",
    "ready_to_boost_desc": "¡No te quedes atrás! ¡Únete hoy a la revolución digital!",
    "yes_i_want_my_free_digital_menu": "Sí, quiero mi menú digital gratis",
    "480_plus": "480+",
    "people_already_joined": "La gente ya se unió",
    "menu_online_home": "Menú Inicio Online",
    "welcome": "Bienvenido",
    "description_other": "¡Mejoremos la satisfacción de sus clientes!",
    "benefits": "Beneficios",
    "greeting_text": "Bienvenidos 👋",
    "all_in_one_features": "🚀 Funciones todo en uno para mejorar tu restaurante",
    "menu_online_menu_name": "Menú en línea",
    "menu_online_description": "Digitalice su menú para que los clientes puedan acceder fácilmente a él en línea.",
    "menu_online_benefits": "Mejore la comodidad del cliente y aumente la participación permitiéndoles ver su menú en cualquier momento y en cualquier lugar.",
    "ds_name": "Señalización digital",
    "ds_description": "Muestre ofertas y promociones en pantallas dinámicas en la tienda.",
    "ds_benefits": "Capte la atención de los clientes con imágenes atractivas, promoviendo ventas adicionales y ofertas en tiempo real sin esfuerzo.",
    "loyalty_punch_name": "Tarjeta perforada de fidelidad",
    "loyalty_punch_description": "Recompense a los clientes habituales con un programa de fidelización digital.",
    "loyalty_punch_benefits": "Impulse la retención de clientes y aumente las ventas incentivando la lealtad con recompensas canjeables por visitas frecuentes.",
    "marketing_menu_name": "Marketing",
    "marketing_description": "Llegue a más clientes con campañas de marketing digital específicas.",
    "marketing_benefits": "Amplíe el alcance de su restaurante e impulse el tráfico peatonal con promociones personalizadas diseñadas para atraer clientes nuevos y habituales.",
    "get_more_reviews_menu_name": "Obtenga más reseñas",
    "get_more_reviews_description": "Incentive a los clientes satisfechos a dejar reseñas positivas en línea.",
    "get_more_reviews_benefits": "Aumente la credibilidad de su restaurante y atraiga nuevos comensales mejorando su reputación en línea con más reseñas positivas.",
    "guest_smart_wifi_menu_name": "Wifi inteligente para invitados",
    "guest_smart_wifi_description": "Ofrecer Wi-Fi gratuito para capturar datos de los huéspedes para marketing.",
    "guest_smart_wifi_benefits": "Aumente la satisfacción del cliente mientras recopila datos valiosos sobre él para personalizar promociones futuras.",
    "reservation_menu_name": "Reserva",
    "reservation_description": "Permita que los clientes reserven mesas en línea con facilidad.",
    "reservation_benefits": "Mejore la experiencia gastronómica minimizando los tiempos de espera y agilizando las reservas para gestionar mejor los asientos.",
    "pos_integration_menu_name": "Integración de POS",
    "pos_integration_description": "Integre nuestro sistema con su sistema POS.",
    "pos_integration_benefits": "Simplifique el procesamiento de pedidos y reduzca los errores manteniendo sincronizados sus sistemas en línea y en la tienda.",
    "online_ordering_menu_name": "Pedidos en línea",
    "online_ordering_description": "Permita que los clientes realicen pedidos directamente desde su sitio web o aplicación.",
    "online_ordering_benefits": "Aumente las ventas y reduzca la dependencia de plataformas de terceros ofreciendo una opción de pedido directo que ahorre tarifas.",
    "pro_label": "Pro",
    "coming_soon": "Muy pronto",
    "locked_label": "Bloqueado",
    "forever_label": "Para siempre",
    "new_label": "Nuevo",
    "other_products_page_title": "Otros Productos | Menú Online",
    "other_products_description": "Política de privacidad en línea del menú",
    "other_products_header_title": "Otros productos",
    "privacy_policy": {
        "title": "política de privacidad",
        "lastUpdated": "Última actualización: 1 de julio de 2024",
        "introduction": {
            "header": "Política de privacidad para clientes nuevos y existentes",
            "paragraph1": "LOS SIGUIENTES PUNTOS CLAVE (INCLUIDA LA REFERENCIA A LAS SECCIONES) SE OFRECEN ÚNICAMENTE PARA SU COMODIDAD Y NO SUSTITUYEN LOS TÉRMINOS Y CONDICIONES COMPLETOS. ES SU RESPONSABILIDAD LEER ESOS TÉRMINOS Y CONDICIONES ANTES DE UTILIZAR EL SERVICIO DE MENUONLINE INC.",
            "paragraph2": "Al utilizar Menuonline, usted acepta los Términos y condiciones y declara y garantiza que está autorizado por su organización o empresa para hacerlo. (Consulte la sección 3 “Cómo funciona”)",
            "paragraph3": "Menuonline Inc es un servicio de marketing basado en permisos. El envío deliberado de “spam” o la violación de los Términos y condiciones darán lugar al cierre de su cuenta. (Véase la sección 16 “Uso aceptable del servicio”)",
            "paragraph4": "Los datos que usted aloja en Menuonline Inc, como registros de contacto, contenido de correo electrónico e información que recopila a través de Menuonline Inc, le pertenecen a usted. (Véase la sección 14 “¿Qué es de su propiedad?”).",
            "paragraph5": "El software de Menuonline Inc y el contenido de nuestro sitio web nos pertenecen. (Véase la sección 13 “¿Qué nos pertenece?”) Aparte de sus propios usuarios, los datos o el contenido no publicado que usted aloja con Menuonline Inc solo pueden ser vistos por los empleados y socios de Menuonline Inc que hayan firmado acuerdos de confidencialidad. (Véase la sección 14 “¿Qué le pertenece?”)",
            "paragraph6": "Toda la información de identificación personal (PII) se almacena únicamente en servidores ubicados en Canadá. (Consulte la sección 14 “¿Qué posee?”).",
            "paragraph7": "El servicio de Menuonline Inc. está sujeto a las leyes de Canadá y de la provincia de Ontario. (Véase el artículo 24 “Ley aplicable”)",
            "paragraph8": "Cualquier solicitud de sus datos o contenido por parte de personas, la policía u otras agencias reguladoras de Canadá o del exterior siempre se le remitirá a usted. La única excepción sería si esto nos pusiera en violación de la ley canadiense o de Ontario; sin embargo, en todos los casos nos opondremos enérgicamente a cualquier divulgación de sus datos por parte de cualquier persona que no sea usted. (Vea la sección 14 “¿Qué es lo que posee?”).",
            "paragraph9": "La seguridad de Menuonline Inc es tan buena como la gestión que haga de sus nombres de usuario y contraseñas. No cobramos por las cuentas de usuario de Menuonline Inc, así que cree una cuenta independiente para cada usuario y mantenga las contraseñas en secreto. Usted es responsable de su cuenta. (Vea la sección 3 “Cómo funciona”)",
            "paragraph10": "Puede cancelar su cuenta en cualquier momento. No ofrecemos reembolsos por el crédito no utilizado si cancela. (Consulte la sección 3 “Cómo funciona”)"
        },
        "termsAndConditions": {
            "title": "TÉRMINOS Y CONDICIONES DE MENUONLINE INC",
            "lastUpdate": "ÚLTIMA ACTUALIZACIÓN: 14 de enero de 2020",
            "paragraph1": "Lea atentamente estos términos y condiciones de servicio (los “Términos”) antes de utilizar este sitio web (el “Sitio”). Estos Términos eximen a Menuonline Inc. (“Menuonline Inc”, “nosotros” o “nos”) y a otros de toda responsabilidad y\/o limitan nuestra y su responsabilidad y contienen otras disposiciones importantes que se aplican al uso que usted haga de este Sitio. Al visitar o utilizar este Sitio o el Servicio, usted acepta en su propio nombre y en nombre de cualquier organización en cuyo nombre pueda actuar (en conjunto, denominados en este documento como “usted”) aceptar y cumplir con estos Términos para cada uso y cada visita a este Sitio."
        },
        "services": {
            "title": "Nuestros Servicios.",
            "paragraph": "Los servicios que ofrecemos a través del Sitio son un servicio que le permite crear, enviar y administrar mensajes de correo electrónico (“Correos electrónicos”) a los destinatarios que usted elija (el “Servicio”)."
        },
        "license": {
            "title": "Licencia.",
            "paragraph": "Sujeto a estos Términos, Menuonline Inc le otorga por la presente una licencia no exclusiva, limitada e intransferible durante el Plazo para ver el Sitio y utilizar el Servicio según lo permitan las características del Servicio. Nos reservamos todos los derechos no expresamente otorgados en este documento en relación con el Servicio y el Contenido de Menuonline Inc (según se define a continuación)."
        },
        "emailingContent": {
            "title": "Envío de contenido por correo electrónico.",
            "paragraph": "Al interactuar con Menuonline Inc a través de este Sitio o del Servicio, usted acepta proporcionar información verdadera y precisa. Además, usted comprende que proporcionar información confidencial por correo electrónico es una práctica insegura. Usted comprende que es el único responsable de todo lo que envíe a cualquier persona que utilice nuestro Servicio."
        },
        "support": {
            "title": "Apoyo.",
            "paragraph": "Le brindamos asistencia para que pueda ser autosuficiente con el Servicio. Le brindamos asistencia en forma de documentación y videos en el Sitio, el sistema de tickets de asistencia de Menuonline Inc. y por teléfono. Puede comunicarse con nosotros haciendo clic en la pestaña “Asistencia” en la parte superior del Sitio. No hay ningún cargo por la asistencia técnica del producto; sin embargo, nos reservamos el derecho de discontinuar la asistencia técnica para cuentas de clientes específicas a nuestra discreción."
        }
    },
    "terms_condition": {
        "title": "Condiciones de servicio | Menú en línea",
        "header": "Términos y condiciones para clientes nuevos y existentes"
    },
    "privacy_policy_title": "POLÍTICA DE PRIVACIDAD",
    "privacy_policy_effective_date": "Vigente a partir del 16 de enero de 2020",
    "privacy_policy_general_title": "POLÍTICA GENERAL DE PRIVACIDAD",
    "privacy_policy_intro": "menuonline.com (Menuonline y “nosotros”) comprende la importancia de la protección de la privacidad de la información personal de las personas y la importancia de la seguridad de la información confidencial de todo tipo. Hemos creado esta Política de privacidad (la “Política”) para demostrar nuestro compromiso con la protección de su “privacidad”. Esta Política de privacidad explica:",
    "privacy_policy_points": [
        "Qué información personal recopilamos y por qué la recopilamos;",
        "Cómo utilizamos su información personal;",
        "Con quién compartimos su información personal; y",
        "Las opciones que tiene para acceder, actualizar y eliminar su información personal del servicio."
    ],
    "privacy_policy_pipeda": "Menuonline gestiona la recopilación de toda la información de acuerdo con la Ley de Protección de Información Personal y Documentos Electrónicos de Canadá (PIPEDA).",
    "privacy_policy_update_notice": "Tenga en cuenta que Menuonline se reserva el derecho de actualizar o cambiar esta Política de privacidad en cualquier momento. Le notificaremos sobre cualquier cambio mediante la publicación de una Política actualizada en este sitio web o cuando esté disponible a través de los servicios. La versión más actual de esta Política regirá el uso que haga mycircle de la información personal de los usuarios. Si continúa accediendo al sitio web de mycircle o si continúa utilizando sus servicios después de que se realicen cambios a esta Política de privacidad, usted acepta estar sujeto a los términos revisados.",
    "privacy_policy_consent_title": "CONSENTIMIENTO PARA INFORMACIÓN PERSONAL",
    "privacy_policy_consent_text": "Al proporcionar a Menuonline su información personal a través de nuestro sitio web o de cualquiera de nuestros formularios, usted da su consentimiento para la recopilación, el uso y la divulgación de dicha información de conformidad con esta Política o para los fines que se le indicaron al momento de proporcionar la información personal. Cualquier uso secundario de la información personal solo se procesará con su consentimiento expreso, o se le brindará la oportunidad de optar por no hacerlo.",
    "privacy_policy_opt_out_text": "Tiene la oportunidad de retirar su consentimiento o de optar por no recibirlo en cualquier momento. Si desea retirar su consentimiento o optar por no recibirlo, puede hacer clic en el enlace de preferencias de comunicaciones que se encuentra en la parte inferior de cualquier correo electrónico que reciba de nosotros.",
    "privacy_policy_info_collection_title": "INFORMACIÓN QUE RECOPILAMOS",
    "privacy_policy_info_collection_text": "Menuonline recopila y procesa información personal de acuerdo con esta Política. No venderemos, compartiremos ni alquilaremos esta información a terceros, excepto según lo estipulado en esta Política. Menuonline recopila información personal con el fin de brindarle nuestros servicios o productos.",
    "privacy_policy_info_types_text": "Los tipos de información que recopila Menuonline incluyen:",
    "privacy_policy_account_holders_title": "Titulares de cuenta de Menuonline",
    "privacy_policy_registration_info": "Información de registro: Si se registra para utilizar los servicios, necesita una cuenta de Menuonline para convertirse en titular de la cuenta (\"Titular de la cuenta\"). Cuando se registre para obtener una cuenta, se le solicitará que proporcione cierta información básica, como su nombre, dirección de correo electrónico, nombre de usuario, contraseña, nombre de la empresa, ocupación, ubicación y número de teléfono. Las contraseñas están encriptadas; el personal de Menuonline no puede verlas ni recuperarlas.",
    "privacy_policy_billing_info": "Información de facturación: Si se suscribe a nuestros servicios, deberá proporcionarnos información de pago y facturación.",
    "privacy_policy_cookies_info": "Cookies: Utilizamos cookies para rastrear datos de los visitantes, como la fuente de las visitas al sitio web y la cantidad de visitas al sitio web.",
    "privacy_policy_ip_info": "Direcciones IP: Las direcciones IP se recopilan sobre usted como parte de su uso de los servicios (por ejemplo, con el fin de registrar la ubicación a nivel de país y las direcciones IP utilizadas al utilizar nuestros servicios).",
    "privacy_policy_your_contacts_title": "Sus contactos",
    "privacy_policy_distribution_list": "Lista de distribución: Como parte de su uso de nuestros servicios, usted puede proporcionarnos, o podemos recopilar como parte de los servicios, información personal sobre sus contactos (\"Contactos\"). Esta información incluye direcciones de correo electrónico y puede incluir otra información identificable como nombre, nombre de la empresa u otra información que proporcione a Menuonline mientras utiliza los servicios. Debe asegurarse de obtener el consentimiento correspondiente de sus Contactos para permitir que procesemos su información.",
    "privacy_policy_billing_info_contacts": "Información de facturación: Si se suscribe a nuestros servicios, deberá proporcionarnos información de pago y facturación.",
    "privacy_policy_cookies_contacts": "Cookies: Menuonline solo utilizará cookies y tecnologías de seguimiento similares para recopilar y utilizar información personal sobre sus Contactos si ha añadido el código de seguimiento de Menuonline a su sitio web. Todas las páginas de destino alojadas por Menuonline tendrán automáticamente un código de seguimiento.",
    "privacy_policy_ip_contacts": "Direcciones IP: Las direcciones IP se recopilan acerca de sus Contactos como parte de los servicios (por ejemplo, con el fin de registrar la ubicación a nivel de país y las direcciones IP utilizadas al enviar un formulario y proporcionar el consentimiento).",
    "privacy_policy_info_about_contacts_title": "INFORMACIÓN QUE RECOPILAMOS SOBRE SUS CONTACTOS",
    "privacy_policy_info_about_contacts_notice": "*Esta sección solo se aplica a la información recopilada, utilizada o divulgada por Menuonline sobre los contactos de un titular de cuenta con el fin de proporcionar los servicios*",
    "privacy_policy_info_about_contacts_desc": "Menuonline solo recopilará, usará o divulgará información personal sobre los Contactos si el Titular de la Cuenta se ha registrado para ciertos servicios en los que nos proporciona una lista de distribución. Los Titulares de la Cuenta son los únicos responsables de garantizar que han cumplido con todas las leyes aplicables al proporcionar información personal sobre sus Contactos a Menuonline a través de los servicios.",
    "privacy_policy_info_auto_collect_title": "Información que Menuonline recopila automáticamente",
    "privacy_policy_info_auto_collect_desc1": "Cuando los contactos del titular de la cuenta interactúan con una campaña de correo electrónico recibida de un titular de la cuenta o interactúan con un enlace al sitio web conectado de un titular de la cuenta, Menuonline puede recopilar información sobre el dispositivo de dicho contacto y su interacción con el correo electrónico mediante el uso de cookies. Esta información solo se recopila para proporcionar los servicios y brindar información al titular de la cuenta.",
    "privacy_policy_info_auto_collect_desc2": "La información que recopilamos sobre el dispositivo de un Contacto y las aplicaciones que utiliza el Contacto para acceder a los correos electrónicos enviados desde el Titular de la Cuenta, a través de nuestros servicios, puede incluir la dirección IP del Contacto, el sistema operativo, la ID del navegador y otra información relacionada con el sistema y la conexión del Contacto.",
    "privacy_policy_info_auto_collect_desc3": "La información que recopilamos sobre la interacción de Contact con los correos electrónicos enviados a través de los servicios puede incluir fechas y horas en que Contact accede al correo electrónico y a las actividades de navegación, e información sobre el desempeño de los servicios, como la capacidad de entrega de correos electrónicos y otras comunicaciones enviadas a Contact por un Titular de Cuenta a través de los servicios.",
    "privacy_policy_info_auto_collect_desc4": "Tenga en cuenta que Menuonline no se comunicará con ninguno de sus contactos, a menos que lo requiera la ley aplicable.",
    "privacy_policy_why_we_collect_title": "¿POR QUÉ RECOPILAMOS SU INFORMACIÓN?",
    "privacy_policy_why_we_collect_desc": "Menuonline recopila información para los siguientes fines:",
    "privacy_policy_why_collect_1": "para proporcionar a los Titulares de Cuentas de Menuonline los servicios a los que se suscriben, incluido el uso de la información del Titular de Cuenta para los servicios;",
    "privacy_policy_why_collect_2": "para la administración y gestión de cuentas, así como el acceso al sitio web de mycircle;",
    "privacy_policy_why_collect_3": "si ha dado su consentimiento para recibir materiales de marketing y promoción, para informarle sobre otros productos y servicios ofrecidos por Menuonline; y",
    "privacy_policy_why_collect_4": "Para responder a las comunicaciones del Titular de la Cuenta o para enviar la información que usted solicite. Esto puede incluir, entre otras cosas:",
    "privacy_policy_why_collect_5": "ofertas especiales y actualizaciones;",
    "privacy_policy_why_collect_6": "boletín informativo; y",
    "privacy_policy_why_collect_7": "anuncios de servicio.",
    "privacy_policy_opt_out_desc": "Usted puede, en cualquier momento, optar por no recibir comunicaciones haciendo clic en el botón para cancelar la suscripción en la parte inferior de cada correo electrónico que le enviamos desde Menuonline.",
    "privacy_policy_use_disclosure_title": "USO Y DIVULGACIÓN DE INFORMACIÓN PERSONAL",
    "privacy_policy_use_disclosure_desc1": "La información personal enviada a Menuonline, incluso a través del sitio web de Menuonline o de un formulario prescrito, se mantiene estrictamente confidencial a menos que se divulgue de conformidad con el consentimiento expreso del Titular de la Cuenta o con requisitos legales. En cualquier caso, cualquier información personal proporcionada en cualquier plataforma de \"Menuonline\" por los Titulares de la Cuenta es voluntaria: cualquier información personal proporcionada por un Titular de la Cuenta se envía a discreción del Titular de la Cuenta. Tenga en cuenta que revocar su consentimiento puede resultar en la imposibilidad de acceder o utilizar ciertos servicios de Menuonline.",
    "privacy_policy_use_disclosure_desc2": "Menuonline utiliza terceros y proveedores de servicios externos con respecto a su sitio web y sus servicios. La información personal del Titular de la Cuenta puede ser compartida con dichos terceros y proveedores. También podemos proporcionar estadísticas agregadas sobre los Titulares de la Cuenta, las ventas, los patrones de tráfico y la información relacionada a terceros de confianza. Menuonline exige que cualquier proveedor externo que tenga acceso a información personal esté sujeto a obligaciones consistentes con esta Política de Privacidad y que dichos proveedores solo recopilen, utilicen o divulguen la información personal del Titular de la Cuenta con el fin de proporcionar los servicios de Menuonline.",
    "privacy_policy_use_disclosure_desc3": "La información personal proporcionada por los Titulares de Cuentas no se venderá, compartirá ni alquilará a organizaciones externas a menos que se dé su consentimiento implícito o expreso. Menuonline se reserva el derecho de comunicarse con cualquier Titular de Cuentas para enviarle información, responder preguntas o notificarle cualquier cambio en nuestro programa o nuestras políticas. Tenga en cuenta que Menuonline no se comunicará con ninguno de sus Contactos, a menos que lo exija la ley aplicable.",
    "privacy_policy_use_disclosure_desc4": "Menuonline divulgará información personal solo si el individuo en cuestión da su consentimiento o:",
    "privacy_policy_disclosure_list_1": "cuando lo permita o lo requiera la ley, como en respuesta a una citación u otro proceso legal;",
    "privacy_policy_disclosure_list_2": "a un cesionario de todo o parte de Menuonline;",
    "privacy_policy_disclosure_list_3": "a los proveedores de servicios de Menuonline de conformidad con esta Política; o",
    "privacy_policy_disclosure_list_4": "para cumplir con obligaciones legales, incluidas, entre otras, las obligaciones de presentación de informes reglamentarios.",
    "privacy_policy_service_providers_title": "Menuonline utiliza los siguientes terceros y proveedores de servicios (la información personal de sus Contactos no se divulga a \"dichos\" terceros):",
    "privacy_policy_service_provider_1": "Utilizamos ManyChat para proporcionar servicios de chat en el sitio web para ventas y soporte, así como correos electrónicos de seguimiento sobre estas conversaciones.",
    "privacy_policy_service_provider_2": "Utilizamos AskNicely para encuestar a nuestros clientes con el fin de preguntarles sobre la calidad del servicio y el soporte que reciben.",
    "privacy_policy_service_provider_3": "Utilizamos join.me para programar reuniones con clientes actuales y potenciales, llamadas de soporte y demostraciones de software.",
    "privacy_policy_service_provider_4": "Utilizamos Google Analytics para rastrear la actividad anónima del sitio web y medir los patrones de uso en nuestro sitio web.",
    "privacy_policy_breach_notification_title": "NOTIFICACIÓN DE INCUMPLIMIENTO",
    "privacy_policy_breach_notification_desc": "Menuonline deberá cumplir y brindar a los Titulares de Cuentas asistencia razonable en el cumplimiento de las leyes aplicables con respecto a cualquier uso, acceso o divulgación no autorizados de información personal.",
    "privacy_policy_rights_access_title": "SUS DERECHOS Y ACCESO",
    "privacy_policy_rights_access_desc": "Tiene derecho a acceder a la información personal que Menuonline procesa sobre usted. Puede solicitarnos información sobre:",
    "privacy_policy_rights_access_list_1": "La información personal que tenemos sobre usted",
    "privacy_policy_rights_access_list_2": "Las categorías de datos personales en cuestión",
    "privacy_policy_rights_access_list_3": "Finalidades del tratamiento",
    "privacy_policy_rights_access_list_4": "Detalles a quién se divulgará\/divulgará su información personal",
    "privacy_policy_rights_access_list_5": "Durante cuánto tiempo conservamos su información personal",
    "privacy_policy_rights_access_list_6": "Si no recopilamos la información directamente de usted, información sobre la fuente",
    "privacy_policy_rights_access_list_7": "Cómo presentar una reclamación ante la autoridad de control competente",
    "privacy_policy_rights_access_list_8": "También puede solicitarnos lo siguiente:",
    "privacy_policy_rights_access_list_9": "Que actualicemos cualquier información incompleta o inexacta sobre usted",
    "privacy_policy_rights_access_list_10": "Solicitar que eliminemos su información personal de acuerdo con las leyes aplicables",
    "privacy_policy_rights_access_contact": "Puede solicitar que ejerzamos sus derechos comunicándose con nosotros a 2851 Dufferin Street, Toronto, ON, Canadá, o enviándonos un correo electrónico a {link}.",
    "privacy_policy_accountability_title": "RESPONSABILIDAD",
    "privacy_policy_accountability_desc": "Menuonline se compromete a hacerse responsable de la información personal y confidencial que nos proporcione. Para obtener información de contacto, consulte el final de esta Política.",
    "privacy_policy_links_other_sites_title": "ENLACES A OTROS SITIOS",
    "privacy_policy_links_other_sites_desc1": "El sitio web de Menuonline puede contener enlaces a otros sitios. Tenga en cuenta que Menuonline no es responsable de las prácticas de privacidad de otros sitios. Recomendamos a nuestros titulares de cuentas que tengan en cuenta que, cuando abandonen nuestro sitio, deben leer detenidamente las declaraciones de política de privacidad de todos y cada uno de los sitios web que recopilan información de identificación personal.",
    "privacy_policy_links_other_sites_desc2": "Esta Política de Privacidad se aplica únicamente a la información recopilada por nosotros.",
    "privacy_policy_links_other_sites_desc3": "Si utiliza un enlace para ir desde el sitio de Menuonline para comprar productos o servicios, está completando una transacción de compra de acuerdo con las reglas y regulaciones de la empresa vendedora, no de Menuonline.",
    "privacy_policy_cookies_title": "POLÍTICA DE PRIVACIDAD COOKIES",
    "privacy_policy_cookies_desc1": "El sitio web y los servicios de Menuonline utilizan cookies para recopilar y almacenar cierta información de acuerdo con esta política. Una \"cookie\" es un pequeño archivo de texto enviado desde un sitio web y almacenado en la computadora del usuario por el navegador web del usuario. Al visitar un sitio que utiliza cookies, se descarga una cookie en su computadora o dispositivo móvil. La próxima vez que visite ese sitio, su dispositivo recordará información útil como preferencias, páginas visitadas u opciones de inicio de sesión.",
    "privacy_policy_cookies_desc2": "Las cookies se utilizan ampliamente para agregar funcionalidad a los sitios web o para garantizar que funcionen de manera más eficiente. Nuestro sitio depende de las cookies para optimizar la experiencia del usuario y garantizar que los servicios del sitio funcionen correctamente.",
    "privacy_policy_cookies_desc3": "La mayoría de los navegadores web permiten cierto control para restringir o bloquear las cookies a través de la configuración del navegador; sin embargo, si deshabilita las cookies, es posible que esto afecte su capacidad para usar ciertas partes de nuestro sitio web o servicios.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Fines analíticos: <\/strong> Utilizamos cookies para analizar la actividad del Titular de la cuenta con el fin de mejorar nuestro sitio web. Por ejemplo, podemos utilizar cookies para observar patrones agregados, como las funciones que utilizan los Titulares de la cuenta. Podemos utilizar dicho análisis para obtener información sobre cómo mejorar la funcionalidad y la experiencia del usuario de nuestro sitio web.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Sus preferencias y experiencia de usuario: <\/strong> Utilizamos cookies para recopilar cierta información sobre los visitantes y los titulares de cuentas, como el tipo de navegador, el servidor, la preferencia de idioma y la configuración del país, con el fin de almacenar las preferencias del titular de la cuenta en nuestro sitio web para que la experiencia del titular de la cuenta sea más consistente y cómoda. Utilizamos cookies para mantener su estado de sesión iniciada cuando visita nuestro sitio web repetidamente.",
    "privacy_policy_cookies_targeted_ads": "<strong> Ofrecemos servicios de medición y publicidad y marketing mejor orientados: <\/strong> utilizamos cookies, balizas web y otras tecnologías de almacenamiento de socios externos como Google y Facebook para servicios de medición, publicidad mejor orientada y con fines de marketing. Esto ocurre cuando usted visita nuestro sitio web. Estas cookies, balizas web y otras tecnologías de almacenamiento nos permiten mostrarle material promocional de Menuonline en otros sitios que visite en Internet.",
    "privacy_policy_security_title": "SEGURIDAD",
    "privacy_policy_security_desc1": "Menuonline se esfuerza por proteger la información confidencial de todo tipo, incluida la información personal, con medidas de seguridad organizativas, físicas, mecánicas y electrónicas adecuadas a la sensibilidad de la información. Nuestros servidores están ubicados en Canadá y cumplen con los estándares de seguridad de la industria.",
    "privacy_policy_security_desc2": "Sin embargo, tenga en cuenta que no podemos garantizar que las medidas que mantenemos garantizarán la seguridad de la información.",
    "privacy_policy_retention_title": "RETENCIÓN",
    "privacy_policy_retention_desc": "Conservamos información personal sobre los Titulares de Cuentas y Contactos durante el tiempo que sea necesario para los fines establecidos en esta Política de Privacidad y de acuerdo con las disposiciones de retención de los Términos de Uso.",
    "privacy_policy_access_questions_title": "ACCESO, PREGUNTAS E INQUIETUDES",
    "privacy_policy_access_questions_desc": "Una persona que haya enviado información personal a Menuonline puede enviar una solicitud por escrito para acceder o corregir su información personal retenida por Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "TÉRMINOS Y CONDICIONES",
        "terms_conditions_subtitle": "Términos y condiciones para clientes nuevos y existentes",
        "terms_conditions_desc1": "LOS SIGUIENTES PUNTOS CLAVE (INCLUIDA LA REFERENCIA A LAS SECCIONES) SE OFRECEN ÚNICAMENTE PARA SU COMODIDAD Y NO SUSTITUYEN LOS TÉRMINOS Y CONDICIONES COMPLETOS. ES SU RESPONSABILIDAD LEER ESOS TÉRMINOS Y CONDICIONES ANTES DE UTILIZAR EL SERVICIO DE Menuonline.",
        "terms_conditions_desc2": "Al utilizar Menuonline, usted acepta los Términos y condiciones y declara y garantiza que está autorizado por su organización o empresa para hacerlo. (Consulte la sección 3 “Cómo funciona”)",
        "terms_conditions_desc3": "Menuonline es un servicio de marketing basado en permisos. El envío deliberado de “spam” o la violación de los Términos y Condiciones darán lugar al cierre de su cuenta. (Véase la sección 16 “Uso aceptable del servicio”)",
        "terms_conditions_desc4": "Los datos que aloja en Menuonline, como registros de contacto, contenido de correo electrónico e información que recopila a través de Menuonline, le pertenecen a usted. (Véase la sección 14 “¿Qué es de su propiedad?”).",
        "terms_conditions_desc5": "El software de Menuonline y el contenido de nuestro sitio web nos pertenecen. (Véase el apartado 13 “¿Qué nos pertenece?”)",
        "terms_conditions_desc6": "Aparte de sus propios usuarios, los datos o el contenido no publicado que usted aloja en Menuonline solo pueden ser vistos por los empleados y socios de Menuonline que hayan firmado acuerdos de confidencialidad. (Véase el apartado 14 “¿Qué es de su propiedad?”).",
        "terms_conditions_desc7": "Toda la información de identificación personal (PII) se almacena únicamente en servidores ubicados en Canadá. (Consulte la sección 14 “¿Qué posee?”).",
        "terms_conditions_desc8": "El servicio Menuonline está sujeto a las leyes de Canadá y de la provincia de Ontario. (Véase el artículo 24 “Ley aplicable”)",
        "terms_conditions_desc9": "Cualquier solicitud de sus datos o contenido por parte de personas, la policía u otras agencias reguladoras de Canadá o del exterior siempre se le remitirá a usted. La única excepción sería si esto nos pusiera en violación de la ley canadiense o de Ontario; sin embargo, en todos los casos nos opondremos enérgicamente a cualquier divulgación de sus datos por parte de cualquier persona que no sea usted. (Vea la sección 14 “¿Qué es lo que posee?”).",
        "terms_conditions_desc10": "La seguridad de Menuonline depende de la gestión que haga de sus nombres de usuario y contraseñas. No cobramos por las cuentas de usuario de Menuonline, así que cree una cuenta independiente para cada usuario y mantenga las contraseñas en secreto. Usted es responsable de su cuenta. (Vea la sección 3 “Cómo funciona”)",
        "terms_conditions_desc11": "Puede cancelar su cuenta en cualquier momento. No ofrecemos reembolsos por el crédito no utilizado si cancela. (Consulte la sección 3 “Cómo funciona”)",
        "terms_conditions_desc12": "Podemos cancelar su cuenta en cualquier momento. Le reembolsaremos los créditos de correo electrónico y los servicios no utilizados si cancelamos su cuenta. (Vea la sección 3 “Cómo funciona”)",
        "terms_conditions_desc13": "Sus datos se eliminarán después de la cancelación y también podrán eliminarse si un usuario de la cuenta no ha iniciado sesión durante 12 meses o más. Mantendremos datos agregados de su cuenta que no incluyan información de identificación personal para fines estadísticos. (Véase la sección 3 “Cómo funciona”)",
        "terms_conditions_desc14": "Los créditos de correo electrónico se compran mediante un sistema de pago por uso y nunca caducan. La única excepción es si no ha iniciado sesión en su cuenta durante 12 meses. En este caso, nos reservamos el derecho de eliminar su cuenta, sus datos y los créditos asociados. (Consulte la sección 10 “¿Cuánto pago por el Servicio?”)",
        "terms_conditions_desc15": "Trabajamos arduamente para garantizar que Menuonline esté en funcionamiento en todo momento y para avisar con anticipación si necesitamos suspender temporalmente el servicio por mantenimiento. Sin embargo, no garantizamos que el servicio esté en funcionamiento en todo momento y no somos responsables de ninguna pérdida que sufra por usar Menuonline, incluidas las pérdidas de su parte si el sistema no está en funcionamiento en el momento en que lo necesita. (Consulte la sección 17 “Descargos de responsabilidad”).",
        "terms_conditions_desc16": "No monitoreamos su contenido ni a quién envía mensajes. Sin embargo, monitoreamos las quejas y otros problemas creados por sus actividades. Demasiadas quejas o problemas pueden resultar en la cancelación de su servicio a nuestra discreción. (Ver s.7 “Monitoreo”)",
        "terms_conditions_desc17": "Nos comprometemos a brindar un excelente soporte de productos para ayudarlo a ser autosuficiente con Menuonline. Brindamos soporte en forma de documentación y videos en nuestro sitio web, el sistema de tickets de soporte de Menuonline y por teléfono. No hay ningún cargo por el soporte de productos; sin embargo, nos reservamos el derecho de discontinuar el soporte para cuentas de clientes específicas a nuestra discreción. (Consulte la sección 5 “Soporte”)",
        "terms_conditions_desc18": "Los servicios de productos están pensados para aquellas ocasiones en las que desea que hagamos algún trabajo relacionado con Menuonline en lugar de hacerlo usted mismo. Nuestra tarifa por los servicios de productos es de $135 por hora. También ofrecemos servicios de consultoría a tarifas más altas. Nos reservamos el derecho de cambiar nuestras tarifas en cualquier momento. (Vea la sección 6 “Servicios de consultoría y productos”)",
        "terms_conditions_desc19": "Le informaremos si creemos que se ha producido una vulneración de sus datos y haremos todo lo posible por proporcionarle información y detalles relacionados (consulte la sección 14 “¿Qué es lo que posee?”).",
        "terms_conditions_desc20": "Nos reservamos el derecho de realizar cambios a este acuerdo en cualquier momento. Intentaremos informarle sobre los cambios a los términos y condiciones, pero el uso del servicio implica que acepta los cambios en los Términos. (Ver sección 9 “Cambios”).",
        "terms_conditions_desc21": "Los Términos y Condiciones y nuestra Política de Privacidad han sido redactados únicamente en inglés, y usted acepta que la versión en inglés de los Términos y Condiciones y la Política de Privacidad tendrá prioridad en caso de cualquier conflicto con cualquier versión traducida.",
        "terms_conditions_mycircle": "TÉRMINOS Y CONDICIONES DE MYCIRCLE",
        "terms_conditions_last_update": "ÚLTIMA ACTUALIZACIÓN: 14 de enero de 2020",
        "terms_conditions_usage_agreement": "Lea atentamente estos términos y condiciones de servicio (los \"Términos\") antes de utilizar este sitio web (el \"Sitio\"). Estos Términos eximen a Menuonline (\"Menuonline\", \"nosotros\" o \"nos\") y a otros de toda responsabilidad y\/o limitan nuestra y su responsabilidad y contienen otras disposiciones importantes que se aplican a su uso de este Sitio.",
        "terms_conditions_acceptance": "Al visitar o utilizar este Sitio o el Servicio, usted acepta en su propio nombre y en nombre de cualquier organización en cuyo nombre pueda actuar (en conjunto, denominados en este documento como \"usted\") aceptar y cumplir estos Términos para cada uso y cada visita a este Sitio.",
        "terms_conditions_services": "1. Nuestros Servicios.",
        "terms_conditions_services_desc": "Los servicios que ofrecemos a través del Sitio son un servicio que le permite crear, enviar y administrar mensajes de correo electrónico ('Correos electrónicos') a los destinatarios que elija (el 'Servicio').",
        "terms_conditions_license": "2. Licencia.",
        "terms_conditions_license_desc": "Sujeto a estos Términos, Menuonline le otorga por la presente una licencia no exclusiva, limitada e intransferible durante el Plazo para ver el Sitio y utilizar el Servicio según lo permitan las características del Servicio. Nos reservamos todos los derechos no expresamente otorgados en este documento en relación con el Servicio y el Contenido de Menuonline (según se define a continuación).",
        "terms_conditions_account": "3. Cómo funciona.",
        "terms_conditions_account_desc": "Para utilizar el Servicio, debe registrarse para obtener una cuenta. Su cuenta es gratuita y le otorga acceso al Servicio y a las \"funciones\" que podamos establecer y mantener de vez en cuando. Para ser elegible para obtener una cuenta, debe:",
        "terms_conditions_account_requirements": [
            "tener al menos dieciocho (18) años de edad y ser capaz de celebrar contratos;",
            "completar el proceso de registro que hemos establecido;",
            "aceptar estos Términos y Condiciones;",
            "Proporcionar información de contacto y de facturación\/pago verdadera, completa y actualizada."
        ],
        "responsibility": "Responsabilidad.",
        "responsibilityText": "Usted es el único responsable de la actividad que se produce en su cuenta y debe mantener la contraseña de su cuenta de forma confidencial y segura. Las cuentas son gratuitas, por lo que le recomendamos que configure una cuenta diferente para cada usuario que tenga. También es responsable de cualquier cuenta a la que tenga acceso, independientemente de si autorizó o no el uso de esa cuenta. Usted acepta que es el único responsable de sus interacciones con aquellas personas a las que envía correos electrónicos y solo enviará correos electrónicos a aquellas personas de las que tenga el consentimiento. Debe notificarnos de inmediato cualquier uso no autorizado de sus cuentas.",
        "compliance_with_CASL": "Cumplimiento de la legislación canadiense antispam (CASL).",
        "compliance_with_CASL_text": "La función de Menuonline es facilitar la gestión de la configuración de consentimiento, no hacer cumplir la legislación. Es su responsabilidad cumplir con la CASL. Le ayudamos configurando la funcionalidad predeterminada y facilitando la obtención y actualización del consentimiento expreso documentado y administrando las fechas de vencimiento rotativas para el consentimiento implícito. Usted tiene el control total de la gestión del consentimiento para su base de datos; puede actualizar el estado de consentimiento de todos sus contactos de forma masiva en cualquier momento, anular la configuración predeterminada y deshabilitar el vencimiento automático del estado de consentimiento implícito.",
        "closing_account": "Cerrar su cuenta.",
        "closing_account_text": "Tanto usted como Menuonline pueden rescindir este Acuerdo en cualquier momento y por cualquier motivo mediante notificación a la otra parte. Si proporciona información falsa, inexacta, no actualizada o incompleta, o utiliza el Servicio de una manera que viole estos Términos y Condiciones, tenemos el derecho de suspender inmediatamente su cuenta y cancelar su acceso al Servicio, además de cualquier otro recurso disponible para nosotros. Si no utiliza su cuenta durante doce (12) meses, podemos cancelarla. Si cancelamos su cuenta por cualquier motivo, le reembolsaremos los Créditos que le queden. Una vez cancelada, eliminaremos permanentemente su cuenta y todos los datos asociados a ella, incluidos los correos electrónicos que haya enviado. Podemos mantener datos agregados de su cuenta que no incluyan información de identificación personal para fines estadísticos.",
        "emailing_content": "Envío de contenido por correo electrónico.",
        "emailing_content_text": "Al interactuar con Menuonline a través de este Sitio o el Servicio, usted acepta proporcionar información verdadera y precisa. Además, usted comprende que proporcionar información confidencial por correo electrónico es una práctica insegura. Usted comprende que es el único responsable de todo lo que envíe a cualquier persona que utilice nuestro Servicio.",
        "support": "Apoyo.",
        "support_text": "Le brindamos asistencia para que pueda ser autosuficiente con el Servicio. Le brindamos asistencia en forma de documentación y videos en el Sitio, el sistema de tickets de asistencia de Menuonline y por teléfono. Puede comunicarse con nosotros haciendo clic en la pestaña “Asistencia” en la parte superior del Sitio. No hay ningún cargo por la asistencia del producto; sin embargo, nos reservamos el derecho de discontinuar la asistencia para cuentas de clientes específicas a nuestra discreción.",
        "product_and_consulting_services": "Servicios de productos y consultoría.",
        "product_and_consulting_services_text": "Los servicios de productos son para aquellos casos en los que desea que hagamos algún trabajo relacionado con Menuonline en lugar de hacerlo usted mismo. Nuestra tarifa por los servicios de productos es de $125 por hora. También ofrecemos servicios de consultoría a tarifas más altas. Nos reservamos el derecho de cambiar nuestras tarifas en cualquier momento.",
        "monitoring": "Escucha.",
        "monitoring_text": "No supervisaremos ni juzgaremos el contenido de la información transmitida a través del Servicio, pero investigaremos si recibimos denuncias de posible uso inapropiado. Menuonline puede, en cualquier momento y a su exclusivo criterio, determinar si determinados usos son o no apropiados, con o sin previo aviso, de acuerdo con las siguientes pautas.",
        "term": "Término.",
        "term_text": "El plazo comienza cuando usted se registra para obtener una cuenta con nosotros y continúa mientras utilice el Servicio. Usted declara y garantiza que tiene la autoridad y la capacidad para registrarse para obtener una cuenta con nosotros (incluso si se registra en nombre de una organización).",
        "changes": "Cambios.",
        "changes_text": "El plazo comienza cuando usted se registra para obtener una cuenta con nosotros y continúa mientras utilice el Servicio. Usted declara y garantiza que tiene la autoridad y la capacidad para registrarse para obtener una cuenta con nosotros (incluso si se registra en nombre de una organización).",
        "payment": "PAGO",
        "what_do_i_pay_for_service": "¿Cuánto pago por el Servicio?",
        "what_do_i_pay_for_service_text": "Puede comprar créditos prepagos para enviar correos electrónicos (“Créditos”) a través de nuestro Servicio, como describimos en detalle en la página de “precios” de nuestro Sitio. Los Créditos nunca vencen y no hay cargos recurrentes por estos Créditos. La única excepción es que si no utiliza su cuenta durante doce (12) meses, podemos cancelar su cuenta y cualquier Crédito no utilizado. Podemos cambiar nuestros precios en cualquier momento actualizando las páginas de precios de nuestro Sitio, que se aplicarán a su próxima compra de Créditos.",
        "how_do_i_pay_for_service": "¿Cómo pago el Servicio?",
        "how_do_i_pay_for_service_text": "Puedes pagar los créditos con cualquier tarjeta de crédito importante. Podemos cambiar los métodos de pago que aceptamos en cualquier momento.",
        "can_i_get_refund": "¿Puedo obtener un reembolso?",
        "can_i_get_refund_text": "Los importes que haya pagado por utilizar el Servicio no son reembolsables si cancela su cuenta. Si cancelamos su cuenta, le reembolsaremos los Créditos que le queden.",
        "rights": "DERECHOS",
        "what_do_we_own": "¿Qué poseemos?",
        "what_do_we_own_text": "A excepción del contenido que usted nos proporciona (incluidos, entre otros, registros de contacto, contenido de correo electrónico e información que recopila a través del Servicio) (\"Su Contenido\"), todos los materiales incluidos en nuestro Sitio, el Servicio y todos los materiales incluidos en el mismo o transferidos por medio de ellos, incluidos, entre otros, software, imágenes, texto, gráficos, ilustraciones, logotipos, patentes, marcas comerciales, marcas de servicio, fotografías, audio, videos, música y contenido perteneciente a otros Usuarios (el \"Contenido de Menuonline\") (y todos los derechos de propiedad intelectual relacionados con el mismo) son de nuestra propiedad o de nuestros licenciantes.",
        "what_do_you_own": "¿Que posees?",
        "what_do_you_own_text": "No reclamamos ningún derecho de propiedad sobre su Contenido. Su Contenido es suyo y usted conserva la propiedad del Contenido que carga al Servicio. Al compartir su Contenido a través del Servicio, usted acepta permitir que otros vean, editen y\/o compartan su Contenido de acuerdo con su configuración y estos Términos y Condiciones. Usted declara y garantiza que es el propietario o tiene permiso para usar todo el material que usa en sus Correos Electrónicos. Podemos usar o divulgar sus materiales solo como lo describimos en estos Términos y nuestra Política de Privacidad.",
        "privacy_statement": "Menuonline respeta la privacidad de sus usuarios. Toda información personal que Menuonline recopile a través de este Sitio o del Servicio está sujeta a la Declaración de Privacidad de Menuonline, que se incorpora por referencia a estos Términos. Podemos usar y divulgar su información de acuerdo con nuestra Declaración de Privacidad.",
        "access_to_your_content": "Menuonline no proporcionará acceso a Su Contenido a nadie excepto a aquellos empleados y contratistas de Menuonline que hayan celebrado acuerdos de confidencialidad apropiados con nosotros o tengamos su consentimiento.",
        "governmentRequest": "Si recibimos una solicitud de un gobierno o autoridad regulatoria para tener acceso a su Contenido, le notificaremos de la solicitud y solicitaremos su consentimiento antes de publicar cualquier parte de su Contenido. Nos opondremos a cualquier solicitud de acceso a su Contenido y no publicaremos su Contenido en respuesta a este tipo de solicitud sin su permiso o si así lo exige un tribunal de jurisdicción competente o el organismo regulatorio correspondiente.",
        "breachNotification": "En caso de que nos demos cuenta de que su contenido ha sido objeto de acceso, uso, copia, robo, pérdida o uso o divulgación no autorizados en violación de estos Términos (un \"Incumplimiento\"), se lo notificaremos de inmediato y le informaremos de lo ocurrido. Usted acepta que los incumplimientos se manejarán de acuerdo con la ley aplicable y, si necesitamos que informe a los miembros de sus listas de distribución de correo electrónico, se compromete a hacerlo de inmediato y según nuestras instrucciones.",
        "contentRetention": "Conservaremos, utilizaremos y transmitiremos su Contenido en Canadá, salvo que cuente con su aprobación o indicación previa por escrito (por ejemplo, si decide enviar su Contenido a personas fuera de Canadá). Los servidores utilizados para proporcionar el Servicio se encuentran en Canadá.",
        "accessTrouble": "Si tiene problemas para acceder a su contenido, Menuonline lo ayudará a recuperar su contenido en función del tiempo y los materiales.",
        "reportingAbuse": "Si cree que alguien está violando cualquiera de estos Términos, notifíquenoslo de inmediato. Si recibió correo no deseado que cree que proviene de otro usuario del Servicio, si cree que otro usuario del Servicio ha publicado material que viola algún derecho de autor o si cree que otro usuario del Servicio está violando de alguna otra manera estos Términos, comuníquese con nosotros a Support@menuonline.com.",
        "acceptableUse": "Usted declara y garantiza que su uso del Servicio cumplirá con todas las leyes y regulaciones aplicables. Usted acepta que es responsable de determinar si el Servicio cumplirá con las leyes y regulaciones aplicables y si es adecuado para su uso en su jurisdicción. Para mayor certeza, usted acepta que deberá (y acepta garantizar que sus empleados, agentes y usuarios autorizados deberán) ...",
        "exportLaws": "No garantizamos que el Sitio o el Servicio sean apropiados o estén disponibles para su uso fuera de Canadá, y está prohibido el acceso a ellos desde territorios donde su contenido sea ilegal. No puede usar, exportar ni reexportar el Contenido de Menuonline ni otros materiales de este Sitio ni ninguna copia o adaptación que infrinja las leyes o regulaciones aplicables, incluidas, entre otras, las leyes y regulaciones de exportación canadienses y estadounidenses.",
        "disclaimers": "El Servicio y este Sitio se proporcionan \"tal cual\", sin representaciones, garantías ni condiciones de ningún tipo, y Menuonline renuncia expresamente, en la máxima medida permitida por las leyes aplicables, a todas las representaciones, garantías y condiciones, expresas o implícitas, por operación de la ley o de otro modo, incluidas, entre otras, las garantías y condiciones implícitas de comerciabilidad o idoneidad para cualquier propósito o uso particular, o no infracción de los derechos de terceros.",
        "limitLiability": "La responsabilidad total de Menuonline bajo este Acuerdo, ya sea por contrato o agravio (incluida la negligencia), como resultado del incumplimiento de la garantía, responsabilidad estricta o bajo cualquier otra teoría de responsabilidad, se limitará a los daños directos en un monto que no exceda las tarifas totales pagadas a Menuonline bajo estos Términos y Condiciones en los doce (12) meses inmediatamente anteriores al mes en el que ocurrió el evento más reciente que dio lugar a la responsabilidad.",
        "liabilityExclusions": "Ninguno de Menuonline, sus afiliados o sus respectivos directores, funcionarios, empleados, agentes u otros representantes (colectivamente 'Representantes\" de Menuonline') o los operadores de cualquier red adjunta tendrán responsabilidad u obligación alguna en relación con este Sitio o el Servicio por: (i) cualquier daño indirecto, consecuente, incidental, ejemplar, punitivo o especial; o (ii) por cualquier daño, ya sea directo, indirecto, consecuente, incidental, ejemplar, punitivo o especial, caracterizado como pérdida de ingresos, pérdida de ahorros o ingresos o pérdida de ganancias...",
        "indemnity": "Usted indemnizará y eximirá de responsabilidad a Menuonline y\/o a los Representantes de Menuonline (las 'partes indemnizadas de Menuonline') por cualquier reclamo presentado por terceros y cualquier multa o penalización impuesta contra nosotros, que surja de su uso de la información a la que accedió desde este Sitio o su uso del Servicio, y cualquier incumplimiento de estos Términos...",
        "ipInfringement": "Menuonline lo defenderá, indemnizará y lo mantendrá indemne de y contra todos y cada uno de los reclamos, demandas, acciones, juicios o procedimientos realizados o interpuestos contra usted por un tercero que alegue que su acceso o uso del Servicio de conformidad con este Acuerdo infringe los derechos de propiedad intelectual bajo la ley canadiense de un tercero (un 'Reclamo por infracción de propiedad intelectual')...",
        "equitableRelief": "Si usted viola estos Términos, podremos solicitar una medida cautelar (es decir, podremos solicitar una orden judicial para impedirle que lo haga) u otra medida equitativa. Usted acepta que esto se sumará a, y sin perjuicio de, cualquier otro derecho que podamos tener por ley o equidad.",
        "linksToOtherSites": "Si proporcionamos enlaces a sitios web de terceros, se proporcionan únicamente para su comodidad. Si utiliza estos enlaces, abandonará nuestro Sitio. No controlamos estos sitios de terceros ni los respaldamos. Usted acepta que no somos responsables de ninguno de estos sitios de terceros ni de su contenido...",
        "thirdPartyRequests": "Si tenemos que proporcionar información en respuesta a una solicitud legal o gubernamental relacionada con su cuenta o su uso del Servicio, usted acepta que podemos cobrarle nuestros costos. Estos honorarios pueden incluir nuestros honorarios legales y los costos del tiempo de nuestros empleados dedicados a preparar la respuesta a la solicitud.",
        "assignment": "Este Acuerdo, y todos los derechos y licencias otorgados a través del mismo, no pueden ser transferidos ni cedidos por usted, pero sí pueden ser cedidos por Menuonline sin restricción, mediante notificación anticipada razonable.",
        "governingLaw": "Estos términos se regirán e interpretarán de conformidad con todas las leyes federales aplicables y las leyes de Ontario, sin tener en cuenta las disposiciones sobre conflicto de leyes. Usted acepta someterse a la jurisdicción exclusiva de los tribunales de Ontario para la determinación de cualquier disputa relacionada con estos Términos.",
        "severability": "Si por cualquier motivo un tribunal de jurisdicción competente determina que alguna disposición de estos Términos o parte de ella es ilegal o por cualquier motivo inaplicable, dicha disposición se aplicará en la máxima medida permitida para dar efecto a la intención de estos Términos, y el resto de estos Términos continuarán en pleno vigor y efecto y seguirán siendo aplicables.",
        "waiver": "Toda renuncia o consentimiento para apartarse de los requisitos de estos Términos será efectivo únicamente si se realiza por escrito y está firmado por nosotros, y únicamente en el caso específico y para el propósito específico para el que se ha otorgado. El hecho de que no ejerzamos o nos demoremos en ejercer cualquier derecho en virtud de estos Términos no se considerará una renuncia a nuestros derechos.",
        "furtherAssurances": "Cada parte acepta firmar y proporcionar todos los documentos y tomar cualquier acción (o hacer que se realicen esas acciones) necesarias para cumplir con sus obligaciones bajo estos Términos y condiciones.",
        "notices": "Todas las notificaciones dirigidas a Menuonline deben realizarse por escrito y enviarse por correo electrónico. Las notificaciones dirigidas a nosotros deben enviarse a la atención de nuestros representantes de servicio al cliente a la dirección support@menuonline.com. Las notificaciones dirigidas a usted pueden enviarse a la dirección de correo electrónico o la dirección de correo electrónico que usted proporcionó como parte de su registro. Además, podemos transmitir avisos o mensajes a través de este Sitio para informarle sobre cambios en este Sitio u otros asuntos de importancia, y estas transmisiones constituirán un aviso para usted en el momento en que las publiquemos.",
        "entireAgreement": "Estos Términos y nuestra Declaración de Privacidad constituyen el acuerdo completo entre usted y nosotros con respecto a su uso del Sitio y del Servicio, y reemplazan todos los acuerdos, representaciones y entendimientos previos entre Menuonline y usted con respecto a este tema. En caso de conflicto entre la Declaración de Privacidad y estos Términos, regirán estos Términos y condiciones.",
        "language": "Las partes han solicitado y requerido expresamente que estos Términos y condiciones, nuestra Política de privacidad y cualquier documento relacionado se redacten en inglés. Las partes convienen y exigen expresamente que este Contrato y todos los documentos que se relatan estén redactados en inglés. Usted acepta que la versión en inglés de estos Términos y Política de privacidad tendrá prioridad en caso de conflicto con cualquier versión traducida.",
        "survival": "Todas las disposiciones de estos Términos que por su naturaleza o importancia están destinadas a sobrevivir a la expiración o terminación de estos Términos, sobrevivirán, incluidas, sin limitación, las Secciones 17 a 19."
    },
    "collect_additional_info": "¿Quieres recopilar información adicional?",
    "additionalInfoChoice": "Elija cualquier información adicional que desee recopilar.",
    "additional_info_yes": "Sí",
    "additional_info_noSkip": "No, salta",
    "additional_info_label": "Información adicional",
    "confirm_business_location": "Primero confirmemos la ubicación de su negocio.",
    "custom_get_more_reviews_form": "Formulario personalizado para obtener más reseñas",
    "set_up_custom_form": "Configura tu formulario personalizado para obtener más reseñas por grupo",
    "reputation_name_label": "Obtenga más reseñas Nombre",
    "reputation_name_placeholder": "Introduzca el nombre de la reputación",
    "group_label": "Grupo",
    "group_desc_title": "Título de la descripción del grupo",
    "group_desc": "Descripción del grupo",
    "select_group_placeholder": "Seleccionar grupo",
    "required_group_name": "El nombre del grupo es obligatorio",
    "before_asking_review": "Antes de pedirle a los clientes su opinión, hagámosles entre 1 y 4 preguntas.",
    "feel_free_edit": "Siéntete libre de editar según sea necesario.",
    "incentive_settings": "Configuración de incentivos",
    "incentivize_customer": "¿Quieres incentivar a tus clientes para que dejen una reseña?",
    "choose_incentive": "Elige el incentivo que deseas recomendar.",
    "scan_qr_feedback": "Escanee el código QR y comparta con nosotros sus valiosos comentarios sobre su experiencia.",
    "qr_code": "Código QR",
    "scan_me": "¡ESCANEAME!",
    "or_title": "O",
    "send_otp_title": "Enviar OTP",
    "sign_in_title": "Iniciar sesión",
    "email_addresss_title": "Dirección de correo electrónico",
    "reset_password_title": "Restablecer contraseña",
    "remember_password_title": "¿Recordaste tu contraseña?",
    "digit_code_title": "Por favor ingrese el código de 4 dígitos que le enviamos ",
    "not_received_title": "¡No recibí un código de verificación!",
    "resend_title": "Por favor reenviar",
    "sign_up_form": "Crea tu cuenta",
    "sign_up_sub_title": "Inicie sesión ahora para acceder a las últimas novedades",
    "already_registered_title": "¿Ya tienes una cuenta de Menuonline?",
    "reserved_title": "Derechos de autor ©2023 Menuonline. Todos los derechos reservados.",
    "terms_title": "Condiciones de servicio",
    "policy_title": "política de privacidad",
    "sign_up_title": "Inscribirse",
    "terms_conditions_title": "Términos y condiciones",
    "business_type_title": "Tipo de negocio",
    "business_type_sub_title": "¡Por favor seleccione uno de los tipos de negocio que describa mejor su negocio!",
    "salon_title": "Salón",
    "restaurant_title": "Restaurante",
    "pizza_store_title": "Tienda de pizza",
    "food_truck_title": "Camión de comida",
    "check_domain_title": "Comprobar nombre de dominio",
    "start_exploring_title": "Empieza a explorar",
    "sign_in_form": "Inicie sesión en su cuenta",
    "sign_in_sub_title": "Inicie sesión ahora para acceder a las últimas novedades",
    "remember_title": "Acuérdate de mí",
    "otp_title": "OTP",
    "password_title": "Contraseña",
    "forgot_title": "¿Has olvidado tu contraseña?",
    "not_registered_title": "¿No tienes una cuenta?",
    "create_account_title": "Regístrate ahora",
    "create_form_title": "Crear formulario",
    "first_name_title": "Nombre de pila",
    "last_name_title": "Apellido",
    "email_title": "Correo electrónico",
    "mobile_number_title": "Número de teléfono móvil",
    "birthday_title": "Cumpleaños",
    "gender_title": "Género",
    "address_title": "DIRECCIÓN",
    "short_answer_title": "Respuesta corta",
    "long_answer_title": "Respuesta larga",
    "single_answer_title": "Respuesta única",
    "single_checkbox_title": "Casilla de verificación única",
    "multiple_choice_title": "Opción múltiple",
    "drop_down_title": "Desplegable",
    "yes_no_title": "Sí o no",
    "description_text_title": "Texto de descripción",
    "file_upload": "Subir archivo",
    "reset_password_form": "Restablecer su contraseña",
    "reset_password_sub_title": "Restablezca rápidamente su contraseña y obtenga acceso a su cuenta.",
    "required_full_name_title": "Se requiere nombre completo",
    "required_first_name_title": "El nombre es obligatorio",
    "valid_first_name_title": "Introduzca un nombre válido",
    "valid_full_name_title": "Introduzca un nombre completo válido",
    "required_block_time": "Se requiere tiempo de bloque",
    "required_last_name_title": "El apellido es obligatorio",
    "valid_last_name_title": "Introduzca un apellido válido",
    "valid_mobile_number_title": "Introduzca un número de móvil válido",
    "required_mobile_number_title": "Se requiere número de móvil",
    "required_already_mobile_number_title": "Ya se requiere el número de móvil",
    "valid_otp_title": "Ingrese un otp válido",
    "valid_email_title": "Introduzca un correo electrónico válido",
    "required_email_title": "Se requiere correo electrónico",
    "required_description_title": "Se requiere descripción",
    "required_description_500_characters": "Por favor, introduzca al menos 500 caracteres",
    "required_description_600_characters": "Por favor, introduzca un máximo de 600 caracteres",
    "domain_title": "Nombre de dominio",
    "required_password_title": "Se requiere contraseña",
    "required_password_regex_title": "Debe contener un mínimo de 8 caracteres, al menos un carácter en mayúscula, un carácter en minúscula, un número y un carácter en mayúscula especial.",
    "required_terms_title": "Por favor, acepte los términos y condiciones",
    "required_note_title": "Nota obligatoria",
    "required_birthday_title": "Se requiere fecha de nacimiento",
    "no_access_title": "No tienes acceso en este momento. Ponte en contacto con tu administrador.",
    "menu_online_open_txt": "Abierto",
    "loyalty_program_description": "😊 ¡Encuentra tu programa de fidelización ideal y personalízalo para que coincida con tu marca!",
    "lp_list_title": "Lista de tarjetas perforadas de fidelidad",
    "lp_n_title": "Nombre de la tarjeta perforada de fidelidad",
    "enter_details_title": "Introducir detalles",
    "stamp_allowed_title": "Sello Permitido",
    "stamp_items_title": "Artículos de sellos",
    "lpn_req_title": "Se requiere el nombre de la tarjeta perforada de fidelidad",
    "valid_lpn_title": "Ingrese un nombre válido para la tarjeta perforada de fidelidad",
    "brand_color_error": "El color de la marca debe tener exactamente 7 caracteres.",
    "select_dark_brand_color_title": "Por favor seleccione un color de marca válido",
    "your_brand_color_title": "El color de tu marca",
    "upload_logo_title": "Subir logotipo",
    "locations_title": "Ubicaciones",
    "add_lp_title": "Crear programa",
    "location_error": "Por favor seleccione ubicaciones",
    "pvs_title": "¿Cuántos sellos puede ganar un cliente por visita?",
    "max_apd_title": "¿Cuál es el número máximo de sellos que un cliente puede ganar en un día?",
    "logo_error": "Se requiere logotipo",
    "expiry_title": "Establecer fecha de vencimiento para las recompensas",
    "add_more_rewards_title": "+ Añadir más recompensas",
    "prev_step_1_title": "Muestra QR para ganar puntos y disfrutar de tus recompensas.",
    "delete_lp_title": "Esta tarjeta perforada de fidelidad se eliminará de forma permanente.",
    "delete_user_record_title": "Este registro de usuario se eliminará de forma permanente.",
    "redeem_title": "Canjear",
    "lp_button_title": "Únase a la tarjeta perforada de fidelidad",
    "lp_download_button_title": "Descargar tarjeta",
    "step_0_title": "Únete ahora, deliciosas recompensas esperan a los amantes de la comida leales como tú.",
    "step_1_title": "Te has unido",
    "congrats_step_title": "Ahora eres elegible para pizza gratis",
    "reward_title": "Obtenga recompensas en cada visita",
    "visit_title": "Visita y desbloquea",
    "rewards_title": "Recompensas",
    "dummy_address": "3730 Frankfort Ave, Louisville, Kentucky, Estados Unidos",
    "loyalty_qr_default_text": "Escanee el código QR, únase a nuestra tarjeta de fidelidad y disfrute de recompensas.",
    "lp_earn_stamp_txt": "Los clientes ganan sellos en cada visita.",
    "lp_get_rewards_txt": "Obtenga recompensas cada vez que visite",
    "lp_1_time_visit_txt": "1 visita que recibirás",
    "lp_uppercase_stamp_text": "Estampilla",
    "lp_lowercase_stamp_text": "estampilla",
    "lp_txt": "Lealtad",
    "lp_per_visit_stamp_validation_msg": "El número de sellos por visita debe ser menor que el máximo permitido por día.",
    "lp_per_max_stamp_validation_msg": "El número máximo de sellos permitidos por día debe ser mayor que el número de sellos por visita.",
    "lp_valid_value_msg": "Introduzca un valor válido",
    "lp_required_field_msg": "Este campo es obligatorio",
    "lp_stamp_required_msg": "Se requiere sello",
    "lp_valid_stamp_msg": "Introduzca un valor de sello válido",
    "lp_reward_name_required_msg": "El nombre de la recompensa es obligatorio",
    "lp_unique_stamp_msg": "El valor del sello debe ser único.",
    "lp_benefits": "Un programa de fidelización aumenta las visitas repetidas y los ingresos al tiempo que proporciona información sobre los clientes para realizar marketing personalizado.",
    "lp_how_customer_txt": "Así es como lo hacen tus clientes",
    "lp_punch_card_txt": "Tarjeta perforada de fidelidad",
    "lp_look_txt": "¡lo miraré! 😍",
    "lp_desc": "Los clientes ganarán sellos por cada visita y, una vez que recolecten suficientes, podrán canjearlos por recompensas especiales.",
    "lp_edit": "Editar",
    "lp_looks_good_txt": "La apariencia es buena",
    "lp_customer_view_instruction": "Esto es lo que verá tu cliente. Sube tu logotipo y establece el tema de colores.",
    "lp_customer_earn_stamps": "Permita que los clientes ganen sellos en cada visita y administren sus recompensas",
    "lp_stamp_rewards_setup": "Establecer recompensas por la obtención de sellos, permitir que los clientes obtengan recompensas por cada sello",
    "lp_loyalty_program_setup": "Dale un nombre a tu programa de fidelización y configura tu ubicación para tener este programa",
    "lp_prev_step1": "Muestra QR para ganar puntos y disfrutar de tus recompensas.",
    "lp_visit_1_time_txt": "Visita 1 vez y obtén",
    "lp_get_free_txt": "Obtenga gratis",
    "lp_visit_allow_txt": "Visita y desbloquea",
    "lp_rewards": "Recompensas",
    "repsT": "Obtenga más reseñas",
    "offSup": "Material de oficina",
    "repsNxtV": "En tu próxima visita",
    "addRepT": "Añadir Obtener más opiniones",
    "repEntrCpnTxt": "Ingresar texto del cupón",
    "repEntrCpnTxtBtn": "Introducir texto del botón de cupón",
    "repMngNxtV": "¿en tu próxima visita?",
    "repMngWldL": "¿Te gustaría obtener?",
    "discount": "Descuento",
    "repDisTyp": "Tipo de descuento",
    "repNameT": "Nombra tu reputación",
    "crtRepT": "Crear reputación",
    "feedBackT": "Comentario",
    "confLocT": "Confirmar ubicación",
    "businLocT": "Ubicación de la empresa",
    "addInfoT": "información adicional",
    "repNReqT": "Obtener más reseñas El nombre es obligatorio",
    "validRepNT": "Introduzca un nombre de reputación válido",
    "groupT": "Grupo",
    "groupLDescTitleT": "Insertar contactos automáticamente en un grupo",
    "groupLDescT": "Todos los clientes que completen el formulario se agregarán al grupo seleccionado.",
    "que2": "¿Estuvo buena tu comida?",
    "que3": "¿Fue amable nuestro servicio?",
    "que4": "¿Nuestro servicio fue rápido?",
    "addAdditionalT": "¿Te gustaría añadir alguna información adicional?",
    "phNoMandT": "¡El número de teléfono es obligatorio!",
    "qrGenT": "QR generado",
    "repFDT": "¡Su aporte ayudará a que nuestro equipo pueda brindarle un mejor servicio!",
    "letdiT": "Vamos a hacerlo",
    "tak15secT": "Toma 15 segundos",
    "defQue": "¿Quieres recibir grandes ofertas y promociones de vez en cuando?",
    "defNQue": "¿Quieres que alguien te responda?",
    "rateOnGoogleDesc": "¿Sería tan amable de calificarnos en Google?",
    "mayBeNT": "Tal vez la próxima vez",
    "yesSureT": "Sí, claro",
    "valid_name": "Introduzca un nombre válido",
    "nameReq": "El nombre es obligatorio",
    "backToMainT": "Volver a la página principal",
    "attachCopT": "¿Quieres incentivar a tu cliente?",
    "thankYouT": "Gracias",
    "lftyT": "¡Esperamos su próxima visita!",
    "repPreT": "Vista previa de reputación",
    "offer_exclusive_discounts": "Ofrezca descuentos y promociones exclusivas para atraer a sus clientes.",
    "couponT": "Cupón",
    "locStepDesc": "Confirme la ubicación de su negocio",
    "createRepStepDesc": "Crear un formulario de reputación personalizado por grupos",
    "feedbackStepDesc": "Conjunto de preguntas de retroalimentación del cliente",
    "addInfoStepDesc": "Campos de clientes y configuraciones de incentivos",
    "FedbkkWDT": "Retroalimentación con datos",
    "repAnlytT": "Análisis de reputación",
    "todayT": "Hoy",
    "rep_last_7_days_t": "Últimos 7 días",
    "last30DaysT": "Últimos 30 días",
    "lastMT": "Mes pasado",
    "custRangeT": "Gama personalizada",
    "queWiseAnltT": "Análisis basado en preguntas",
    "atL1QueErrT": "Por favor seleccione al menos una pregunta",
    "deleteRepTitle": "Esta reputación será eliminada permanentemente.",
    "incvCustT": "Sí, incentivar a mi cliente",
    "collAddInfoT": "Sí, me gustaría recopilar información adicional",
    "totRewT": "Reseña total",
    "totPosRewT": "Reseña totalmente positiva",
    "negFeedT": "Retroalimentación negativa",
    "posFeedT": "Retroalimentación positiva",
    "fineT": "Letra pequeña",
    "enterFineT": "Introduzca el texto de la letra pequeña",
    "setThemeT": "Establecer el color del tema",
    "que1T": "Pregunta 1",
    "que2T": "Pregunta 2",
    "que3T": "Pregunta 3",
    "que4T": "Pregunta 4",
    "entMailT": "Ingresa tu correo electrónico",
    "reputationQRDefTxt": "Escanee el código QR y comparta con nosotros sus valiosos comentarios sobre su experiencia.",
    "selUser": "Seleccionar usuario",
    "userT": "Usuario",
    "subUReq": "Por favor seleccione usuarios",
    "updateLoc": "Actualizar ubicación",
    "leadGenT": "Generación de leads",
    "displayT": "Mostrar",
    "ratingT": "Clasificación",
    "rep_dashboard_negative_feedback": "Comentarios negativos 0%",
    "rep_dashboard_position_feedback": "Comentarios positivos 0%",
    "rep_dashboard_total_Feedback_txt": "Comentarios totales",
    "rep_dashboard_anony_Feedback": "Comentarios anónimos",
    "rep_dashboard_Feeedback_Data_txt": "Retroalimentación con datos",
    "rep_dashboard_review_txt": "Obtenga más reseñas",
    "rep_dashboard_total_Review_txt": "Reseña total",
    "rep_dashboard_total_positive_Review_txt": "Reseña Totalmente Positiva",
    "rep_dashboard_negative_feedback_txt": "Retroalimentación negativa",
    "rep_dashboard_connection_txt": "Conexión",
    "rep_dashboard_question_wise_analytics_txt": "Análisis basado en preguntas",
    "rep_dashboard_date_label": "Seleccione fecha",
    "rep_dashboard_custom_range_txt": "Gama personalizada",
    "rep_tlt_min": "REPS",
    "emojis": "Emojis",
    "media_text": "Agregar medios",
    "custom_field_text": "Insertar campos personalizados",
    "shortlink_text": "Agregar enlace corto",
    "use_template_text": "Usar plantilla",
    "banner_must_have": "Debe tener",
    "res_seamless": "Sin costura",
    "res_table_reservation": "Reserva de mesa",
    "res_system": "Sistema",
    "res_text": "Reservaciones",
    "md_easily": "Fácilmente",
    "md_manage_customers": "Gestionar clientes",
    "md_visits_interactions": "Visitas e interacciones",
    "md_desc": "Facilite la experiencia de sus clientes con reservas de mesa en tiempo real. Gestione las reservas sin esfuerzo, reduzca los tiempos de espera y garantice una experiencia fluida tanto para el personal como para los clientes. Mantenga su restaurante completamente reservado y al mismo tiempo mantenga un servicio y una satisfacción de primer nivel.",
    "engage_convert": "SMS atractivos y de conversión",
    "coupon_campaign": "y Campaña de cupones",
    "sms_read_rate": "Los mensajes SMS se leen el 98% de las veces",
    "real_time_promo": "Entregar promociones en tiempo real para una acción inmediata",
    "affordable_reach": "Una forma asequible de llegar a los clientes con un sólido retorno de la inversión",
    "stay_in_touch": "Manténgase en contacto con su cliente en todo momento",
    "bc_engage_customers": "Involucrar a los clientes Convertir SMS",
    "bc_coupon_campaign": "y Campaña de cupones",
    "bc_sms_read_rate": "Los mensajes SMS se leen el 98% de las veces",
    "bc_real_time_promo": "Entregar promociones en tiempo real para una acción inmediata",
    "bc_affordable_reach": "Una forma asequible de llegar a los clientes con un sólido retorno de la inversión",
    "bc_stay_in_touch": "Manténgase en contacto con su cliente en todo momento",
    "ar_automate_engagement": "Automatice la interacción con sus clientes",
    "ar_with": "con",
    "ar_with_auto_responders": "Autorespondedores",
    "ar_personalized_messages": "Envíe automáticamente mensajes personalizados para cumpleaños, dé la bienvenida a nuevos clientes y vuelva a conectarse con clientes anteriores. Ahorre tiempo y mantenga cada interacción significativa y oportuna.",
    "sq_custom_forms_tools": "Formularios y herramientas personalizados: simplificación",
    "sq_custom_forms_ci": "Interacciones con el cliente",
    "sq_smart_qr_desc": "Cree formularios personalizados, recopile firmas electrónicas y aproveche los códigos QR inteligentes para optimizar sus procesos comerciales. Adapte cada herramienta para satisfacer las necesidades de sus clientes y mejorar la experiencia del usuario.",
    "gnc_get_new_cus": "Conseguir nuevos clientes",
    "gnc_get_customers": "Desde las redes sociales: ¡rápido y fácil!",
    "gnc_social_media_power": "Utilice el poder de las plataformas sociales para atraer nuevos clientes e impulsar las ventas directamente a su negocio. Redireccione los pedidos de entrega de terceros directamente a su tienda o plataforma de pedidos en línea para obtener el máximo control y mayores ingresos.",
    "wifi_connect_guests": "Conectar a los invitados con",
    "wifi_smart": "Wi-Fi inteligente",
    "wifi_connect_grow": "Y haga crecer sus conocimientos",
    "wifi_smart_wifi_desc": "Proporcione una conexión Wi-Fi sin complicaciones a sus clientes a través de una pantalla de presentación de marca mientras recopila datos valiosos de los clientes para mejorar sus esfuerzos de marketing y participación.",
    "wifi_create_splash_page": "Crear página de bienvenida de Wifi para invitados",
    "instant_menu_create": "Crea tu",
    "instant_menu_text": "Menú instantáneo",
    "instant_menu_wm": "En cuestión de minutos",
    "instant_menu_desc": "Mejora las experiencias de los clientes al permitir actualizaciones en tiempo real, fácil personalización y una mayor participación, lo que hace que sea más fácil mostrar sus ofertas y mantener su contenido actualizado.",
    "instant_menu_create_digital_menu": "Crear un menú digital",
    "loyalty_program_rewards": "Ganar recompensas",
    "loyalty_program_on_visits": "en cada visita",
    "loyalty_program_create": "Crear un programa de fidelización",
    "reputation_create": "Crear reputación",
    "reputation_boost": "Impulsa tu",
    "reputation_text": "Reputación",
    "reputation_reviews": "¡Con más reseñas!",
    "reputation_desc": "Recopile comentarios valiosos de sus clientes para mejorar su servicio y reputación. Comparta su experiencia y ayúdenos a crecer: ¡su opinión es importante!",
    "dashboard": "Panel",
    "blast_campaign": "Campaña Blast",
    "blast_campaigns": "Campañas explosivas",
    "blast_campaign_sub_title": "Lanza campañas explosivas para llegar a todos tus clientes con unos pocos clics",
    "auto_responders": "Contestadores automáticos",
    "auto_responder": "Contestador automático",
    "auto_responder_sub_title": "Promociones basadas en eventos fáciles de usar para sus clientes",
    "smart_qr_form": "QR inteligente \/ Formulario",
    "get_new_customer": "Conseguir un nuevo cliente",
    "marketing": "Marketing",
    "clients": "Clientela",
    "group_data": "Datos de grupos",
    "clients_data": "Datos de clientes",
    "account": "Cuenta",
    "user_acc_info": "Información de la cuenta de usuario",
    "current_plan": "Plan actual",
    "payments": "Pagos",
    "location": "Ubicación",
    "location_list": "Lista de ubicaciones",
    "users": "Usuarios",
    "users_sub_title": "Lista de todos los usuarios y sus detalles",
    "online_profile": "Perfil en línea",
    "change_password": "Cambiar la contraseña",
    "menu": "Menú",
    "reseller_dashboard": "Panel de control del revendedor",
    "companies": "Empresas",
    "plans_bought": "Planes comprados",
    "agreement_t_c": "Términos y condiciones del acuerdo",
    "wifi_dashboard": "Panel de control de Wifi",
    "hot_spot": "Punto de acceso",
    "splash_pages": "Páginas de bienvenida",
    "get_more_reviews": "Obtenga más reseñas",
    "office_supplies": "Material de oficina",
    "reservation": "Reserva",
    "floor_table": "Suelo y mesa",
    "guest_smart_wifi": "WiFi inteligente para invitados",
    "digital_signage": "Señalización digital",
    "schedule_down": "Programación descontinuada",
    "business_settings": "Configuraciones de negocios",
    "sub_user_login": "Inicio de sesión de subusuario",
    "help_center": "Centro de ayuda",
    "switch_to_hub": "Cambiar al Hub",
    "meeting_records": "Actas de reuniones",
    "logout": "Cerrar sesión",
    "no_access_text": "No tienes acceso en este momento. Por favor, contacta a tu administrador.",
    "menu_item": "ELEMENTO DEL MENÚ",
    "menu_capital": "MENÚ",
    "good_day": "Buen día",
    "no_permission_text": "¡¡No tienes permiso para realizar esta acción!!",
    "enter_valid_pin": "Introduzca un PIN válido",
    "add_reservation": "Añadir reserva",
    "edit_reservation": "Editar reserva",
    "per_req": "Se requiere el número de personas",
    "req_block_time": "Se requiere tiempo de bloque",
    "pending_status": "Pendiente",
    "booked_status": "Reservado",
    "completed_status": "Terminado",
    "cancelled_status": "Cancelado",
    "reservation_slot": "Franja de reserva",
    "block_time": "Tiempo en bloque",
    "slot_time": "Horario de reserva:  ",
    "template_name_req": "El nombre de la plantilla es obligatorio",
    "template_type_req": "El tipo de plantilla es obligatorio",
    "sms_template_req": "Se requiere plantilla de SMS",
    "group_req": "Se requiere grupo",
    "valid_date_time": "Introduzca fecha y hora válidas",
    "valid_group_name": "Introduzca un nombre de grupo válido",
    "reservation_mark_completed": "Esta reserva se marcará como completada.",
    "customer_name": "Nombre del cliente",
    "date_time": "Fecha y hora",
    "table_no": "Tabla N°",
    "floor": "Piso",
    "customer_name_placeholder": "nombre_del_cliente",
    "date_time_placeholder": "fecha_hora",
    "table_no_placeholder": "número de tabla",
    "floor_placeholder": "piso",
    "select_floor": "Seleccionar piso",
    "select_table": "Seleccionar tabla",
    "customer_name_macro": "%% nombre_cliente %%",
    "date_time_macro": "%% fecha_hora %%",
    "table_no_macro": "%% n° de tabla %%",
    "floor_macro": "%% piso %%",
    "template_name": "Nombre de la plantilla",
    "login_with_4_digit_pin": "Inicie sesión con su PIN de 4 dígitos",
    "login_now_to_access_latest_insights": "Inicie sesión ahora para acceder a las últimas noticias",
    "mkt_BS_Title": "SMS explosivos",
    "step_1": "Personalizar mensaje",
    "step_2": "Elija la audiencia",
    "step_3": "Mensaje de programación",
    "step_4": "Enviar un mensaje",
    "step_5": "Administrar mensaje",
    "bs_tab_title": "Resumen de la campaña Blast",
    "default_message": "Hola %% firstname %%, ¡Descuento especial en brunch! ¡Cena con nosotros hoy y obtén un descuento exclusivo del 10 % en tu factura!",
    "bs_Title": "Elija un tipo de campaña",
    "sms_title": "Mensajes de texto y mensajes multimedia",
    "reach_out_to_customer": "Comuníquese con los clientes a través de mensajes de texto y multimedia.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Conecta con tus clientes usando WhatsApp 💬",
    "send_discount_offers": "Envía ofertas de descuentos por SMS 📲",
    "send_coupons_via_sms": "Envía cupones vía SMS a tus clientes 🎟️",
    "env_Cap": "Ofrezca descuentos y promociones exclusivas para atraer a sus clientes.",
    "cmt_Caption": "Comuníquese con los clientes a través de mensajes de texto y multimedia",
    "wp_caption": "Conéctese con los usuarios mediante WhatsApp para una comunicación fluida y directa",
    "msg_Prev_Title": "Vista previa del mensaje",
    "cou_Prev_Title": "Vista previa del cupón",
    "blast_SMS": "Campaña SMS\/MMS",
    "bs_P_Msg": "En cola",
    "bs_S_Msg_C": "Fallido",
    "bs_F_Msg_Count": "Entregado",
    "previous": "Anterior",
    "next_ST": "Próximo",
    "next_S": "Próximo paso",
    "req_Temp_Name_Title": "El nombre de la plantilla es obligatorio",
    "req_Template_Tx_Title": "El texto de la plantilla es obligatorio",
    "add_Temp_Title": "Agregar plantilla",
    "link_Desc": "Agrega un enlace de tu elección para que los clientes puedan reservar contigo a través de Facebook, Google o tu propio sitio web.",
    "audience_Title": "Audiencia",
    "client_G1_Title": "Nuevos clientes",
    "client_G2_Title": "Clientes recientes",
    "client_G3_Title": "Clientes leales",
    "client_G4_Title": "Clientes inactivos",
    "client_G5_Title": "Clientes por fecha de conexión",
    "on_Which_Day": "¿En qué día?",
    "on_Day": "En el día",
    "immediate_Desc": "Su mensaje será enviado inmediatamente, haga clic en Siguiente para comenzar.",
    "never_Desc": "Esta campaña está programada para ejecutarse indefinidamente hasta que la finalice manualmente.",
    "end_Desc": "La campaña finalizará en la fecha que elijas aquí.",
    "success_Message": "¡Campaña guardada exitosamente!",
    "send_Success_Message": "¡Campaña enviada exitosamente!",
    "msg_Name_Field_Title": "Título de la campaña",
    "valid_campaign_title": "Introduzca un título de campaña válido",
    "msg_Type_Title": "Tipo de mensaje",
    "sms_Desc": "1 crédito por SMS",
    "mms_Desc": "3 créditos por MMS",
    "client_GTO_1": "Día",
    "client_GTO_2": "Horas",
    "minutes": "Minutos",
    "client_GTO_4": "Semana",
    "time_Option_1": "Día",
    "time_Option_2": "Horas",
    "c_Grp_Day_1": "90 días",
    "c_Grp_Day_2": "60 días",
    "c_Grp_Day_3": "30 días",
    "first": "Primero",
    "second_T": "Segundo",
    "third_T": "Tercero",
    "fourth_T": "Cuatro",
    "fifth_T": "Quinto",
    "delete_BS_T": "Esta campaña será eliminada permanentemente.",
    "delete_MBS_T": "Estas campañas se eliminarán de forma permanente.",
    "cFT_1": "Teléfono",
    "cFT_2": "Nombre de contactos",
    "cFT_3": "Apellido del contactos",
    "cFT_4": "Correo electrónico de contacto",
    "cFT_5": "Nota",
    "cFT_6": "Cumpleaños",
    "cFT_7": "nombre de empresa",
    "cF_Meta_Tag_1": "%% teléfono %%",
    "cF_Meta_Tag_2": "%% nombre de pila %%",
    "cF_Meta_Tag_3": "%% apellido %%",
    "cF_Meta_Tag_4": "%% correo electrónico %%",
    "cF_Meta_Tag_5": "%% nota %%",
    "cF_Meta_Tag_6": "%% cumpleaños %%",
    "cF_Meta_Tag_7": "%% nombre de empresa %%",
    "cF_Name_1": "teléfono",
    "cF_Name_2": "nombre de pila",
    "cF_Name_3": "apellido",
    "cF_Name_4": "correo electrónico",
    "cF_Name_5": "nota",
    "cF_Name_6": "cumpleaños",
    "cF_Name_7": "nombre de empresa",
    "all_clients": "Todos los clientes",
    "aud_Type_2": "Grupos de clientes",
    "aud_Type_3": "Grupos de clientes",
    "aud_Type_T1": "Todos los clientes",
    "aud_Type_T2": "Grupos de clientes",
    "aud_Type_T3": "Grupos de clientes",
    "new_clients": "Nuevos clientes",
    "client_G2": "Clientes recientes",
    "client_G3": "Clientes leales",
    "client_G4": "Clientes inactivos",
    "client_G5": "Clientes por fecha de conexión",
    "never_text": "Nunca",
    "ending_on": "Terminando en",
    "send_T2": "Programado",
    "send_Type_3": "Periódico",
    "daily": "A diario",
    "weekly_T": "Semanalmente",
    "monthly_T": "Mensualmente",
    "yearly_T": "Anualmente",
    "each": "Cada",
    "on_the": "En el",
    "monday": "Lunes",
    "exclusive_offer": "Oferta exclusiva para ti",
    "redeem_now": "Canjear ahora",
    "redeem_with_cashier": "Por favor canjear con cajero",
    "lg_Day_2": "Martes",
    "lg_Day_3": "Miércoles",
    "lg_Day_4": "Jueves",
    "lg_Day_5": "Viernes",
    "lg_Day_6": "Sábado",
    "lg_Day_7": "Domingo",
    "msg_Pre_T": "Dominós",
    "cA_Edit_T1": "Todo",
    "cA_Edit_T2": "Incluido",
    "cA_Edit_T3": "Excluidos",
    "SMS": "Mensaje de texto",
    "MMS": "MMS",
    "usd_T": "Dólar estadounidense",
    "cad_T": "CANALLA",
    "msg": "Mensaje",
    "which_Day": "¿Qué días?",
    "end_Date_Tx": "Fecha de finalización",
    "sDate_Err": "La fecha de inicio debe ser mayor o igual a hoy",
    "eDate_Err": "La fecha de finalización debe ser posterior a la fecha de inicio",
    "start_Date_Req": "La fecha de inicio es obligatoria",
    "end_Date_Req": "La fecha de finalización es obligatoria",
    "time_req": "Se requiere tiempo",
    "client_GT1": "Clientes suscritos en los últimos",
    "client_GT2": "Clientes con conexión en el últimos",
    "client_GT3": "Clientes con al menos",
    "client_GT4": "con el ultimo",
    "client_GT5": "que no volvió en el últimos",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Prueba",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Se requiere medio o URL",
    "time_Title": "Tiempos",
    "start_date": "Fecha de inicio",
    "end_date": "Fecha de finalización",
    "auto_Send_T": "Enviar automáticamente a los clientes",
    "add_Media": "Agregar medios",
    "in_Title": "En",
    "c_Temp_T": "Plantilla de campaña",
    "temp_NT": "Nombre de la plantilla",
    "type_T": "Tipo",
    "select_template_type": "Seleccionar el tipo de plantilla",
    "sel_Temp_T": "Seleccionar el tipo de plantilla",
    "sms_temp_t": "Plantilla de SMS",
    "temp_T": "Texto de plantilla",
    "default_Msg": "Hola %% firstname %%, ¡Descuento especial en brunch! ¡Cena con nosotros hoy y obtén un descuento exclusivo del 10 % en tu factura!",
    "refill_T": "Rellenar",
    "avl_Credit": "Créditos disponibles",
    "req_Credit": "Total de créditos requeridos",
    "rem_Credit": "Crédito restante",
    "refill_Credit": "Créditos a recargar",
    "clients_Req": "Seleccione al menos un cliente",
    "subclient_Req": "Los clientes seleccionados no son suscriptores.",
    "refill_Desc": "Por favor rellene los créditos requeridos",
    "url_Regex": "^((http|https):\/\/)....+$",
    "sd_Invalid": "La fecha de inicio no es válida",
    "ed_Invalid": "La fecha de finalización no es válida",
    "img_Url_Invalid": "URL de imagen no válida",
    "time_Invalid": "El tiempo no es válido",
    "time_And_Date_Invalid": "Introduzca una fecha y hora válidas",
    "time_Invalid_Bfr": "Seleccione una hora al menos 5 minutos después de la hora actual",
    "sod_Req": "Se requiere enviar el día",
    "add_Card": "Agregar una nueva tarjeta",
    "sm_D_Rep_Title": "Informe de entrega de SMS\/MMS",
    "send_Now_T": "Enviar ahora",
    "schd_Now_T": "Programar SMS",
    "test_SMS_T": "Enviar SMS de prueba",
    "save_AD_T": "Guardar como borrador",
    "back_TE_T": "Volver a editar",
    "reset_tex": "Reiniciar",
    "update_tex": "Actualizar",
    "dum_msg1": "Para mostrar el nombre del cliente, utilice %% customer_name %% en la plantilla",
    "dum_msg2": "Para mostrar la hora de reserva, utilice %% date_time %% en la plantilla",
    "dum_msg3": "Para mostrar el número de tabla, utilice %% table_no %% en la plantilla",
    "embedded_link": "Enlace incrustado",
    "img_title": "Imagen",
    "img_input_text": "Texto:",
    "img_join_text": " para unirse a nuestra lista",
    "img_copy_text": "Copiar",
    "img_not_found": "¡Imagen generada no encontrada!",
    "or_text": "O",
    "web_signup_image_content": "Al enviar este formulario y registrarse por mensaje de texto, usted acepta recibir mensajes de texto de marketing (como códigos promocionales y recordatorios). Pueden aplicarse tarifas por mensajes y datos. La frecuencia de los mensajes varía. Puede cancelar su suscripción en cualquier momento respondiendo STOP",
    "img_download_success": "Imagen descargada exitosamente",
    "embedded_link_copied": "Enlace incrustado copiado exitosamente",
    "media_url_required": "Se requiere medio o URL",
    "invalid_image_url": "URL de imagen no válida",
    "invalid_file": "El archivo no es válido",
    "image_error_1mb": "Por favor, inserte una imagen que pese menos de 1 MB",
    "image_error_2_5mb": "Por favor, inserte una imagen que pese menos de 2,5 MB",
    "image_error_3mb": "Por favor, inserte una imagen que pese menos de 3 MB",
    "change_title": "Cambiar",
    "drop_file_text": "Suelte el archivo aquí o haga clic para cargarlo",
    "apply_text": "Aplicar",
    "search_here": "Buscar aquí",
    "update_status": "Estado de actualización",
    "reservation_deleted_permanently": "Esta reserva se eliminará de forma permanente.",
    "table_text": "Tablas",
    "add_table_button": "Agregar nueva tabla",
    "add_table_text": "Agregar tabla",
    "edit_table_text": "Editar tabla",
    "table_delete_text": "Esta tabla se eliminará de forma permanente.",
    "multiple_table_delete_text": "Estas tablas se eliminarán de forma permanente.",
    "table_error_name": "Por favor, introduzca el nombre de la tabla",
    "table_error_invalid_name": "Por favor ingrese un nombre de tabla válido",
    "table_error_floor": "Por favor seleccione el piso",
    "table_error_select": "Por favor seleccione la tabla",
    "table_capacity_text": "Capacidad de la mesa",
    "capacity_text": "Capacidad",
    "table_occasion_text": "Encuentra tu mesa para cualquier ocasión",
    "table_name_text": "Nombre de la tabla",
    "table_capacity_error": "Se requiere capacidad de mesa",
    "cancel_text": "Cancelar",
    "submit_text": "Entregar",
    "select_valid_date": "Seleccione una fecha válida",
    "select_valid_time": "Seleccione una hora válida",
    "valid_contact_number": "Introduzca un número de contacto válido",
    "date_req": "La fecha es obligatoria",
    "date_error_later_than_today": "La fecha debe ser posterior o igual a la fecha de hoy.",
    "time_error_later_than_5_minutes": "Seleccione una hora al menos 5 minutos posterior a la hora actual",
    "number_person_req": "Se requiere número de personas",
    "contact_no_req": "El número de contacto es obligatorio",
    "contact_no": "Número de contactos",
    "select_time": "Seleccionar hora",
    "for_how_many_person": "¿Para cuantas personas?",
    "f_name": "Nombre de pila",
    "l_name": "Apellido",
    "business_name": "Nombre de la empresa",
    "business_no": "Número de empresa",
    "no_for_sign_in": "Este número se utiliza para iniciar sesión.",
    "business_email": "Correo electrónico comercial",
    "notes_tex": "Notas",
    "floor_deleted_permanently": "Este piso será eliminado de forma permanente.",
    "add_floor": "Agregar piso",
    "edit_floor": "Editar piso",
    "name_text": "Nombre",
    "mobile_no": "Número móvil",
    "person_text": "Persona",
    "date_and_time": "Fecha y hora",
    "actions_text": "Comportamiento",
    "extra_text": "Extra",
    "floor_name_req": "El nombre del piso es obligatorio",
    "floor_name": "Nombre del piso",
    "status_text": "Estado",
    "category_status_req": "El estado de la categoría es obligatorio",
    "table_deleted_permanently": "Esta tabla se eliminará de forma permanente.",
    "tables_deleted_permanently": "Estas tablas se eliminarán permanentemente.",
    "back_to_home": "Volver a la página de inicio",
    "link_copied_text": "Enlace copiado al portapapeles",
    "cust_dash_head_to_title": "a",
    "cust_dash_head_location_title": "Ubicación",
    "select_location_title": "Seleccione la ubicación",
    "all_locations_label": "todas las ubicaciones",
    "rep_customer_feedback_analytics": "😊 Realice un seguimiento de los comentarios de sus clientes con análisis en tiempo realice",
    "rep_customer_txt": "Clientes",
    "rep_delete_title": "Esta reputación será eliminada permanentemente.",
    "rep_qr_def_txt": "Escanee el código QR y comparta con nosotros sus valiosos comentarios sobre su experiencia.",
    "delete_title": "Borrar",
    "user_list_title": "Lista de clientes",
    "os_nfc_txt": "Tarjetas NFC",
    "os_sign_holder_txt": "Soporte para letreros",
    "os_store_Sign_holder_txt": "Soportes para carteles de tiendas",
    "StaT": "Estadísticas",
    "AllgT": "Todos los grupos",
    "FavT": "Favoritos",
    "MostActT": "Más activos",
    "grT": "Nombre del grupo",
    "keywT": "Palabras clave",
    "exSubT": "Mensaje para suscriptores existentes",
    "ArchT": "Archivados",
    "gNotiSms": "Notificarme de cada nuevo contacto a través de SMS",
    "gNotiEmail": "Notificarme sobre cada nuevo contacto por correo electrónico",
    "reqGName": "El nombre del grupo es obligatorio",
    "validGN": "Introduzca un nombre de grupo válido",
    "valid_phone_no": "Introduzca un número de teléfono válido",
    "phone_no_req": "Se requiere el número de teléfono",
    "required_message_text": "El mensaje es obligatorio",
    "required_discount_text": "Se requiere descuento",
    "less_than_discount": "El monto del descuento debe ser menor a 5000",
    "discount_percentage_invalid": "El porcentaje de descuento debe ser menor o igual a 100",
    "discount_type_req": "Se requiere el tipo de descuento",
    "discount_text_req": "El texto del cupón es obligatorio",
    "reqContactNo": "El número de contacto es obligatorio",
    "reqMsgNT": "El título de la campaña es obligatorio",
    "reqLinkT": "Se requiere enlace",
    "delMGT": "Estos grupos serán eliminados permanentemente.",
    "defMemMsg": "Escribe tu mensaje aquí",
    "add_cust_to_grp_title": "Agregar cliente al grupo",
    "group_title": "Grupos",
    "group_create_title": "Crear grupo",
    "group_name_txt": "Nombre del grupo",
    "group_table_keyword_title": "Palabra clave",
    "actions_title": "Comportamiento",
    "clients_title": "Clientela",
    "send_title": "Enviar",
    "qr_title": "Código QR",
    "delete_group_txt": "Este grupo será eliminado permanentemente.",
    "delete_groups_txt": "Estos grupos serán eliminados permanentemente.",
    "delete_client_title": "Este cliente será eliminado permanentemente.",
    "delete_clients_title": "Estos clientes serán eliminados permanentemente.",
    "delete_multiple_title": "Eliminación múltiple",
    "wel_sms_mms": "¿Enviar SMS\/MMS de bienvenida?",
    "key_words_title": "Palabras clave",
    "srch_plch_txt": "Buscar aquí",
    "req_location_id_title": "La ubicación es obligatoria",
    "create_text": "Crear",
    "view_text": "Vista",
    "immediately": "Inmediatamente",
    "business_name_req": "El nombre de la empresa es obligatorio",
    "business_no_req": "Se requiere el número de empresa",
    "valid_business_name": "Introduzca un nombre comercial válido",
    "valid_business_no": "Introduzca un número de empresa válido",
    "address_req": "La dirección es obligatoria",
    "valid_address": "Por favor seleccione una dirección válida",
    "time_zone_req": "Se requiere zona horaria",
    "image_req": "Inserte una imagen que pese menos de 2,5 MB.",
    "valid_file": "El archivo no es válido.",
    "logo": "Logotipo",
    "time_zone": "Huso horario",
    "save": "Ahorrar",
    "account_type_req": "El tipo de cuenta es obligatorio",
    "gst_no_req": "Se requiere el número de GST",
    "gst_no_valid": "Introduzca un número de GST válido",
    "set_up_payments": "Configurar pagos",
    "billing_details": "Introduzca sus datos de facturación",
    "billing_details_desc": "Sus detalles de facturación se mostrarán en su factura mensual de menuonline.",
    "account_type": "Tipo de cuenta",
    "select_account_type": "Seleccione el tipo de cuenta",
    "gst_no": "Número de GST",
    "transaction_details": "Detalles de la transacción",
    "payment_method": "Método de pago",
    "billing_period": "Periodo de facturación",
    "paid_by": "Pagado por",
    "download": "Descargar",
    "pay_now": "Pagar ahora",
    "pull_down_refresh": "Desplácese hacia abajo para actualizar",
    "release_refresh": "Soltar para actualizar",
    "billing_details_text": "Detalles de facturación",
    "payment_methods": "Métodos de pago",
    "invoice": "Factura",
    "invoice_to": "Factura a:",
    "issue_date": "Fecha de asunto",
    "due_date": "Fecha de vencimiento",
    "amount_due": "Monto adeudado",
    "charges": "Cargos",
    "sub_total_capitalize": "Subtotal",
    "hst": "HST",
    "grand_total": "Gran total",
    "invoice_generated_on": "Factura generada el",
    "contact_us": "Contáctenos",
    "invoice_issue": "Si ha revisado su factura y aún tiene preguntas",
    "call": "Llamar",
    "send_sms": "Enviar SMS",
    "payment_success": "El pago se ha realizado correctamente",
    "edit_credit_card": "Editar tarjeta de crédito",
    "add_credit_card": "Agregar tarjeta de crédito",
    "modify_card_info": "Modificar la información de tu tarjeta",
    "enter_card_info": "Ingrese los datos de su tarjeta",
    "account_no_req": "Se requiere número de cuenta",
    "card_name_req": "Se requiere el nombre en la tarjeta",
    "expiry_date_req": "Se requiere fecha de caducidad",
    "valid_expiry_date": "Introduzca una fecha de caducidad válida",
    "valid_cvv": "Introduzca un CVV válido",
    "cvv_req": "Se requiere CVV",
    "card_no": "Número de tarjeta",
    "name_of_card": "Nombre en la tarjeta",
    "expiry": "Expiración",
    "mm_text": "MM\/AA",
    "cvv": "CVV",
    "set_as_default": "Establecer como predeterminado",
    "add_new_card": "Agregar nueva tarjeta",
    "all_credit_card": "Todas tus tarjetas de crédito",
    "fastest_checkout": "Pago más rápido con una plataforma segura y confiable, en la que confían un gran grupo de empresas",
    "coupon_button_req": "El texto del botón de cupón es obligatorio",
    "max19_characters_allowed": "Se permiten un máximo de 19 caracteres.",
    "fine_print_text_req": "Se requiere texto en letra pequeña",
    "clients_req": "Seleccione al menos un cliente",
    "client_groups": "Grupos de clientes",
    "day_text": "Día",
    "valid_days": "El valor del día es demasiado grande, ingrese días válidos",
    "hours_text": "Horas",
    "enter_valid_hours": "El valor de las horas es demasiado grande. Ingrese horas válidas.",
    "enter_valid_min": "El valor de minutos es demasiado grande, ingrese minutos válidos",
    "clients_with_connection_required": "Se requiere clientes con conexión en el último",
    "connection_required": "Se requiere conexión",
    "connection_value_too_large": "El valor de las conexiones es demasiado grande. Ingrese una conexión válida.",
    "minutes_required": "Minutos requeridos",
    "clients_with_at_least_connection_required": "Se requieren clientes con al menos conexión",
    "last_minutes_req": "Con los últimos minutos se requiere",
    "clients_not_returned_required": "Quien no volvió en los últimos minutos es requerido",
    "not_return_in_last_minutes_invalid": "No regresar en últimos minutos debe ser menor a los últimos minutos.",
    "customerGroups": "Grupos de clientes",
    "select_customer_group": "Por favor seleccione el grupo de clientes",
    "location_required": "La ubicación es obligatoria",
    "start_date_required": "La fecha de inicio es obligatoria",
    "start_date_invalid": "La fecha de inicio no es válida",
    "start_date_invalid_error": "La fecha de inicio debe ser mayor o igual a hoy",
    "recurring": "Periódico",
    "end_date_required": "La fecha de finalización es obligatoria",
    "end_date_invalid": "La fecha de finalización no es válida",
    "end_date_invalid_error": "La fecha de finalización debe ser posterior a la fecha de inicio",
    "time_invalid": "El tiempo no es válido",
    "monthly_text": "Mensual",
    "send_day_req": "Se requiere enviar el día",
    "loyal_clients": "Clientes leales",
    "recent_clients": "Clientes recientes",
    "lapsed_clients": "Clientes inactivos",
    "clients_connection_date": "Clientes por fecha de conexión",
    "scheduled_text": "Programado",
    "weekly": "Semanalmente",
    "selected_clients_not_subscriber": "Los clientes seleccionados no son suscriptores.",
    "message_preview_title": "Vista previa del mensaje",
    "coupon_preview_title": "Vista previa del cupón",
    "form_text": "Forma",
    "preview_text": "Avance",
    "next_text": "Próximo",
    "send_test_SMS": "Enviar SMS de prueba",
    "save_draft": "Guardar como borrador",
    "back_to_edit": "Volver a editar",
    "select_payment_method": "Por favor seleccione el método de pago",
    "schedule_SMS": "Programar SMS",
    "send_now": "Envía ahora",
    "get_more_ratings": "Obtenga más calificaciones",
    "overview": "Descripción general",
    "reset_campaign": "Campaña de reinicio",
    "permissions": "Permisos",
    "location_name": "Nombre de la ubicación",
    "phone_no": "Número de teléfono",
    "location_email_address": "Ubicación Dirección de correo electrónico",
    "located_business": "¿Donde está ubicada su empresa?",
    "business_logo": "Logotipo de empresa",
    "congratulations": "Felicidades",
    "almost_done": "Ya casi estás listo",
    "publish": "Publicar",
    "about_your_business": "Acerca de su negocio",
    "add_your_location": "Añade tu ubicación",
    "publish_location": "Ubicación de publicación",
    "location_name_req": "El nombre de la ubicación es obligatorio",
    "valid_location_name": "Introduzca un nombre de ubicación válido",
    "business_logo_req": "Se requiere el logotipo de la empresa",
    "please_accept_terms": "Por favor acepte los términos y condiciones",
    "add_new_location": "Agregar nueva ubicación",
    "edit_location": "Editar ubicación",
    "add_location": "Agregar ubicación",
    "existing_msg_subscriber_txt": "Mensaje para suscriptores existentes",
    "msg_capitalize_txt": "Mensaje",
    "group_noti_sms": "Notificarme de cada nuevo contacto a través de SMS",
    "group_noti_email": "Notificarme sobre cada nuevo contacto por correo electrónico",
    "group_member_msg": "¡Parece que ya formas parte de nuestro grupo!",
    "group_mend_msg": "DETÉNGASE para finalizar. AYUDA para obtener ayuda. Pueden aplicarse tarifas por SMS y datos.",
    "Disclaimer_title": "Texto de exención de responsabilidad: ",
    "group_def_msg": "¡Estamos en línea ahora! Consulta nuestro menú de servicios y reserva tu próxima cita hoy mismo.",
    "required_msg_txt": "El mensaje es obligatorio",
    "required_Key_txt": "Se requieren palabras clave",
    "required_mem_msg": "Se requiere mensaje de suscriptor",
    "client_list_title": "Lista de clientes",
    "add_contact_txt": "Agregar contacto",
    "delete_all_clients_txt": "Eliminar todos los clientes",
    "delete_all_clients_msg": "¿Está seguro de que desea eliminar todos los clientes? No se pueden recuperar.",
    "delete_all_txt": "Eliminar todo",
    "timeline_title": "Línea de tiempo",
    "unsubscribe_title": "Darse de baja",
    "subscribe_title": "Suscribir",
    "unsubscribe_confirm_msg": "¿Quieres marcar a este cliente como dado de baja?",
    "subscribe_confirm_msg": "¿Quieres marcar a este cliente como suscriptor?",
    "no_title": "No",
    "yes_title": "Sí",
    "client_name_title": "Nombre del cliente",
    "source_title": "Fuente",
    "contact_file_Req": "Se requiere archivo de contactos",
    "req_title": "Requerido",
    "opt_in_req": "Los contactos importados deben ser 100% opt-in",
    "image_invalid_error": "El archivo no es válido.",
    "import_contacts_msg": "Importar contactos",
    "csv_upload_title": "Archivo de contactos CSV para cargar *",
    "csv_file_desc": "Cargue únicamente archivos csv. Columnas del archivo csv: nombre, apellido, dirección de correo electrónico, número de móvil, sexo, fecha de nacimiento (MM\/DD\/AAAA). Sin espacios, guiones ni paréntesis en el número de móvil.",
    "to_download_title": "Para descargar el archivo de demostración haga clic aquí",
    "contains_header_title": "Sí, este archivo contiene encabezados",
    "opt_in_100_txt": "Sí, estos contactos importados son 100% opt-in",
    "DisT": "Texto de exención de responsabilidad: ",
    "gMendMsg": "DETÉNGASE para finalizar. AYUDA para obtener ayuda. Pueden aplicarse tarifas por SMS y datos.",
    "reqKeyT": "Se requieren palabras clave",
    "reqMemMsg": "Se requiere mensaje de suscriptor",
    "reqMsgT": "El mensaje es obligatorio",
    "gMemberMsg": "¡Parece que ya formas parte de nuestro grupo!",
    "gdefMsg": "¡Estamos en línea ahora! Consulta nuestro menú de servicios y reserva tu próxima cita hoy mismo.",
    "next_title": "Próximo",
    "male_title": "Masculino",
    "male_val": "masculino",
    "female_title": "Femenino",
    "female_val": "femenino",
    "others_txt": "Otros",
    "others_val": "otros",
    "validBirthdate": "Introduzca una fecha de nacimiento válida",
    "valid_phone_no_title": "Introduzca un número de teléfono válido",
    "required_phone_no_title": "Se requiere el número de teléfono",
    "add_new_client_txt": "Agregar un nuevo cliente",
    "update_client_txt": "Actualizar cliente",
    "phone_num_text": "Número de teléfono",
    "dob_title": "Fecha de nacimiento",
    "select_gender_title": "Seleccione género",
    "timelineTitle": "Línea de tiempo",
    "confirm_location": "Confirmar ubicación",
    "feedback_title": "Comentario",
    "rep_question_1": "¿Estaba limpio el negocio?",
    "rep_que_2": "¿Estuvo buena tu comida?",
    "rep_que_3": "¿Fue amable nuestro servicio?",
    "rep_que_4": "¿Nuestro servicio fue rápido?",
    "business_location_req_title": "Se requiere la ubicación del negocio",
    "valid_location_err_txt": "Por favor seleccione una dirección válida",
    "rep_management_title": "Obtenga más gestión de reseñas",
    "rep_about_desc": "La reputación le ayuda a aumentar la calificación de Google al pedirle comentarios a sus clientes.",
    "preview_title": "Avance",
    "rep_preview_txt": "Vista previa de reputación",
    "back_title": "Atrás",
    "fine_print_txt": "Por favor canjear con cajero",
    "redeem_me_title": "Redimirme",
    "rep_nxt_visit_txt": "En tu próxima visita",
    "device_type": "Tipo de dispositivo utilizado",
    "connection_method": "Método de conexión",
    "peak_days": "Días pico",
    "peak_hours": "Horas pico",
    "guest_by_day": "Invitado por día",
    "guest_visit": "Visita de invitado",
    "connection": "Conexión",
    "connection_duration": "Duración de la conexión",
    "guest_visit_1": "1 vez",
    "guest_visit_2": "2 veces",
    "guest_visit_3_5": "3-5 veces",
    "guest_visit_6_10": "6-10 veces",
    "guest_visit_11_25": "11-25 veces",
    "guest_visit_26_100": "26-100 veces",
    "guest_visit_100_plus": "100+ veces",
    "device_android_total": "Android total",
    "device_android": "Androide",
    "device_desktop": "De oficina",
    "device_ios": "iOS",
    "device_ios_total": "iOS total",
    "device_desktop_total": "Escritorio total",
    "connection_duration_10": "<=10 minutos",
    "connection_duration_20": "11-20 minutos",
    "connection_duration_30": "21-30 minutos",
    "connection_duration_40": "31-45 minutos",
    "connection_duration_60": "46-60 minutos",
    "connection_method_email": "Correo electrónico",
    "connection_method_sms": "Mensaje de texto",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Todos los invitados",
    "new_Guest_txt": "Nuevo invitado",
    "connections_txt": "Conexiones",
    "hotspot": "Punto de acceso",
    "hotspot_list": "Lista de puntos de acceso",
    "add_new_hotspot": "Agregar nuevo punto de acceso",
    "hotspot_information": "Información sobre puntos de acceso",
    "edit_details_button": "Editar detalles",
    "wifi_info_message": "Conéctate y disfruta de WiFi gratis",
    "connection_message": "Genial, te han conectado a ",
    "connection_message_suffix": " Wi-Fi",
    "wifi": "Wi-Fi",
    "login_to_access": "Inicie sesión para obtener acceso",
    "verification_code": "Código de verificación",
    "verification_code_message": "Por favor, escriba el código de verificación enviado a ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Por cierto, ¿cómo te llamas?",
    "your_birthdate": "¿Nos puedes decir cuál es tu fecha de nacimiento?",
    "request_guest_wifi_name": "Por favor, introduzca el nombre de la red wifi para invitados",
    "request_device_key": "Por favor, introduzca la clave del dispositivo",
    "request_maximum_internet_access_length": "Seleccione la longitud máxima de acceso a Internet",
    "mac_address": "Dirección MAC",
    "device_port": "Puerto del dispositivo",
    "hardware": "Hardware",
    "current_uptime": "Tiempo de actividad actual",
    "nearby_devices": "Dispositivos cercanos",
    "firmware": "Firmware",
    "who_are_you": "¿Quién eres?",
    "where_to_contact_you": "¿Dónde podemos ponernos en contacto con usted?",
    "your_area_code": "¿Cual es tu código de área?",
    "connected": "Conectado",
    "delete_hotspot_message": "Este punto de acceso se eliminará de forma permanente.",
    "delete_multiple_hotspots_message": "Estos puntos de acceso se eliminarán de forma permanente.",
    "speed_error": "La velocidad debe ser al menos 0,01",
    "speed_max_error": "Ingrese un valor de hasta 1024 para una velocidad ilimitada o seleccione un valor inferior dentro del rango admitido",
    "device_ssid": "SSID del dispositivo",
    "device_ssid_two": "Dispositivo SSID Dos",
    "device_ssid_two_wpa": "Dispositivo SSID Dos WPA",
    "device_key": "Clave del dispositivo",
    "select_location": "Seleccione la ubicación",
    "select_maximum_internet_access_length": "Seleccione la longitud máxima de acceso a Internet",
    "download_speed": "Velocidad de descarga",
    "upload_speed": "Velocidad de subida",
    "network_length_1": "15 minutos",
    "network_length_2": "30 minutos",
    "network_length_3": "45 minutos",
    "network_length_4": "1 hora",
    "network_length_5": "2 horas",
    "network_length_6": "4 horas",
    "network_length_7": "6 horas",
    "network_length_8": "8 horas",
    "network_length_9": "10 horas",
    "network_length_10": "12 horas",
    "employee_wifi_name": "Nombre del empleado de Wifi",
    "employee_wifi_password": "Contraseña de Wifi para empleados",
    "guest_wifi_name": "Nombre del Wifi para invitados",
    "menu_other_products_txt": "Otros Productos | Menú Online",
    "menu_home_text": "Inicio | Menú Online",
    "whatsapp_title": "WhatsApp",
    "select_campaign_type": "Elija un tipo de campaña",
    "select_readymade_templates": "Elige entre plantillas ya preparadas o crea la tuya propia",
    "campaign_title_required": "El título de la campaña es obligatorio",
    "type_here": "Escribe aquí...",
    "location_permission_req": "Se requiere permiso de ubicación",
    "platform_permission_req": "Se requiere permiso de la plataforma",
    "profile_picture": "Foto de perfil",
    "click_to_upload": "Haga clic para cargar",
    "location_permission": "Permiso de ubicación",
    "pin": "Alfiler",
    "platform_permission": "Permiso de plataforma",
    "set_pin": "Establecer PIN",
    "list_of_users": "Lista de usuarios",
    "create_user": "Crear usuario",
    "terms_and_condition_req": "Los términos y condiciones son obligatorios",
    "terms_and_conditions": "Términos y condiciones",
    "recommended": "RECOMENDADO",
    "mo": "\/mes",
    "unsubscribe": "Darse de baja",
    "cancel_subscription": "¿Está seguro de que desea cancelar la suscripción?",
    "upgrade": "Mejora",
    "plan_upgraded_successfully": "¡El plan se actualizó exitosamente!",
    "menu_online": "Menú en línea",
    "support": "Apoyo",
    "wifiC": "Wi-Fi",
    "billing": "Facturación",
    "please_upgrade": "Actualice para disfrutar de todas las funciones",
    "you_have_subscribed": "Te has suscrito al ",
    "plan": "plan",
    "of_text": "de",
    "days": "Días",
    "remaining_days_until_plan": " Días restantes hasta que tu plan requiera actualización",
    "manage_your_screens": "Administra tus pantallas",
    "screens_you_save": "Pantallas que tienes",
    "add_more_screens": "Agregar más pantallas",
    "addition_screen": "Pantalla de adición",
    "per_screen": "$ por pantalla",
    "per_box": "$ por caja",
    "shipping_changes": "Gastos de envío",
    "upto4_boxes": "hasta 4 cajas ",
    "charge_now": "Recibirás el cargo ahora",
    "updated_reoccuring": "Su actualización recurrente será",
    "current_reoccuring": "Corriente recurrente: ",
    "no_text": "No",
    "android_box": "Caja de Android",
    "old_password_req": "Se requiere contraseña antigua",
    "new_password_req": "Se requiere nueva contraseña",
    "confirm_password_req": "Se requiere confirmar contraseña",
    "password_do_not_match": "Las contraseñas no coinciden",
    "old_password": "Contraseña anterior",
    "new_password": "Nueva contraseña",
    "confirm_password": "confirmar Contraseña",
    "copy_working_hours": "¿Copiar este horario de trabajo a todos los días?",
    "yes_copy": "Sí, copia",
    "closed": "Cerrado",
    "opening_time": "Horario de apertura",
    "closing_time": "Hora de cierre",
    "description": "Descripción",
    "file_exist": "El archivo ya existe, seleccione otro archivo",
    "bussiness_images": "Imágenes de negocios",
    "display_info_on_market_place": "¿Le gustaría mostrar la siguiente información en su Marketplace?",
    "profile_ready": "¡Tu perfil está listo!",
    "client_book_with_online": "Los clientes ahora pueden reservar con usted en línea. Comparta el enlace a continuación para comenzar.",
    "copy_link": "Copiar enlace",
    "see_your_profile": "Ver tu perfil",
    "ok_got_it": "Ok, lo entiendo",
    "preview_profile": "Obtenga una vista previa de su perfil y vea cómo se vería.",
    "opening_hours": "Horario de apertura",
    "display_data": "Mostrar datos",
    "manage_profile": "Administrar perfil",
    "phone_req": "Se requiere teléfono",
    "market_place_img_req": "Se requieren imágenes del mercado.",
    "add_new_market_place": "Agregar nuevo MarketPlace",
    "edit_market_place": "Editar MarketPlace",
    "no_reviews_yet": "No hay reseñas todavía",
    "good": "Bien",
    "average": "Promedio",
    "add_marketPlace": "Agregar MarketPlace",
    "all_title": "Todo",
    "included_title": "Incluido",
    "excluded_title": "Excluido",
    "clients_subscribed": "Clientes suscritos en los últimos",
    "clients_groups": "Grupos de clientes",
    "customer_groups": "Grupos de clientes",
    "audience_title": "Audiencia",
    "client_gt5": "que no volvió en el último",
    "select_all": "Seleccionar todo",
    "modify": "Modificar",
    "campaign_title": "Título de la campaña",
    "msg_type": "Tipo de mensaje",
    "enter_discount": "Introducir descuento",
    "discount_type": "Tipo de descuento",
    "coupon_text": "Texto del cupón",
    "enter_coupon_text": "Ingresar texto del cupón",
    "coupon_button_text": "Texto del botón de cupón",
    "enter_coupon_button_text": "Introducir texto del botón de cupón",
    "fine_prin": "Letra pequeña",
    "enter_fine_prin": "Introduzca el texto de la letra pequeña",
    "campaign_dec": "Puedes modificar la campaña 30 minutos antes de su hora de inicio. Los cargos por campañas de SMS se realizarán 30 minutos antes de la hora de inicio de la campaña.",
    "blast_text_message_dec": "Tu mensaje de texto masivo está listo. Selecciona tu método de pago y envía tus mensajes.",
    "payment_completed": " El pago se completará en ",
    "total_cost": "Costo total",
    "close_title": "Cerca",
    "friday": "Viernes",
    "saturday": "Sábado",
    "sunday": "Domingo",
    "thursday": "Jueves",
    "tuesday": "Martes",
    "wednesday": "Miércoles",
    "port_txt": "Puerto",
    "today_title": "Hoy",
    "yesterday_title": "Ayer",
    "last_30_days_txt": "Últimos 30 días",
    "this_month_txt": "Este mes",
    "last_month_txt": "Mes pasado",
    "valid_date_title": "Seleccione una fecha válida",
    "valid_business_name_txt": "Introduzca un nombre comercial válido",
    "req_bus_add_txt": "Se requiere dirección comercial",
    "req_domain_name_txt": "Se requiere el nombre de dominio",
    "basic_info_txt": "Información básica de su negocio",
    "loyalty_qr_def_txt": "Escanee el código QR, únase a nuestra tarjeta de fidelidad y disfrute de recompensas.",
    "last_stamp_txt": "Último sello",
    "collected_on_txt": "Recogido el",
    "stamp_details_txt": "Detalles del sello",
    "add_stamp_txt": "Añadir sello",
    "choose_brand_color_txt": "Elige el color y el idioma de tu marca 🎨",
    "change_anytime_txt": "Puedes cambiar esto en cualquier momento.",
    "like_to_get_txt": "¿Te gustaría obtener?",
    "your_next_visit_txt": "¿en tu próxima visita?",
    "time_to_time_get_offers": "¿Quieres recibir grandes ofertas y promociones de vez en cuando?",
    "respond_back_to_you": "¿Quieres que alguien te responda?",
    "input_serve_better": "¡Su aporte ayudará a que nuestro equipo pueda brindarle un mejor servicio!",
    "do_it_txt": "Vamos a hacerlo",
    "take_15_s": "Toma 15 segundos",
    "rate_on_google_desc": "¿Sería tan amable de calificarnos en Google?",
    "may_be_next_time": "Tal vez la próxima vez",
    "thank_you_txt": "Gracias",
    "look_next_txt": "¡Esperamos su próxima visita!",
    "deleteUserTitle": "Este usuario será eliminado permanentemente.",
    "deleteMUserTitle": "Estos usuarios serán eliminados permanentemente.",
    "change_pin": "Cambiar PIN",
    "area_code": "¿Cual es tu código de área? ",
    "add_menu": "Agregar menú",
    "menu_name": "Nombre del menú",
    "add_menu_placeholder": "Pizza, hamburguesas, bebidas, etc.",
    "enable_data_Collection": "Habilitar la recopilación de datos",
    "add_new_menu": "Agregar nuevo menú",
    "rename_menu": "Cambiar nombre del menú",
    "preview": "Avance",
    "generate_qr": "QR generado",
    "edit_menu": "Menú Editar",
    "remove_menu": "Eliminar menú",
    "menu_delete_msg": "Este menú se eliminará permanentemente.",
    "menus_delete_msg": "Estos menús se eliminarán permanentemente.",
    "view_menu_dialoage_msg": "¿Te gustaría recibir un regalo en tu cumpleaños?",
    "skip": "Saltar",
    "cliam_your_gift": "Reclama tu regalo",
    "collect_form": "Formulario de recolección",
    "enter_first_name": "Introduzca el nombre",
    "enter_last_name": "Introduzca el apellido",
    "enter_email": "Introducir correo electrónico",
    "enter_dob": "Introduzca la fecha de nacimiento",
    "enter_number": "Introducir número",
    "select_gender": "Seleccione género",
    "congratulations_desc": "🎉 ¡Felicitaciones! Tu solicitud de regalo se ha enviado correctamente. Nuestro equipo se pondrá en contacto contigo en breve. 🎉",
    "male_heading": "Masculino",
    "male_text": "masculino",
    "female_heading": "Femenino",
    "female_text": "femenino",
    "others_heading": "Otros",
    "other_text": "otros",
    "BirthD": "Fecha de nacimiento",
    "GenderT": "Género",
    "EmailT": "Correo electrónico",
    "dobT": "Fecha de nacimiento",
    "capitalize_menu": "Menú",
    "select_menu": "Seleccionar menú",
    "manage_variant": "Gestionar variante",
    "add_products": "Agregar productos",
    "add_category": "Agregar categoría",
    "category_delete": "Esta categoría será eliminada permanentemente",
    "variation_delete": "Esta variación se eliminará del Producto.",
    "product_delete": "Este producto será eliminado permanentemente.",
    "categories_delete": "Estas categorías serán eliminadas permanentemente.",
    "products_delete": "Estos productos serán eliminados permanentemente.",
    "category": "CATEGORÍA",
    "price": "PRECIO",
    "food_product_placeholder": "Pizza, hamburguesas, bebidas, etc.",
    "active_title": "Activo",
    "inActive_title": "Inactivo",
    "status_capital": "ESTADO",
    "cat_status_require": "El estado de la categoría es obligatorio",
    "cat_name_require": "El nombre de la categoría es obligatorio",
    "category_name": "Nombre de la categoría",
    "status": "Estado",
    "lgDay1": "Lunes",
    "lgDay2": "Martes",
    "lgDay3": "Miércoles",
    "lgDay4": "Jueves",
    "lgDay5": "Viernes",
    "lgDay6": "Sábado",
    "lgDay7": "Domingo",
    "is_closed_title": "Cerrado",
    "book_table_title": "Reservar una mesa",
    "emailErrSub": "El asunto del correo electrónico es obligatorio",
    "email_subject": "Asunto del correo electrónico",
    "contactUsfrmTitleEmail": "Responder al correo electrónico",
    "companyInfo": "Lorem Ipsum es lorem hrig, lorem ipsum es fraets.Lorem Ipsum es lorem hrig, lorem ipsum es fraets.Lorem Ipsum es lorem hrig, lorem ipsum es fraets.",
    "footerSTitle1": "Acerca de Menuonline",
    "footerSTitle1Lnk2": "Características",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Legal",
    "footerSTitle2Lnk1": "Términos y condiciones",
    "footerSTitle2Lnk2": "política de privacidad",
    "footerSTitle3": "Para empresas",
    "footerSTitle3Lnk1": "Para socios",
    "footerSTitle3Lnk2": "Precios",
    "footerSTitle3Lnk3": "Apoyo a los socios",
    "footerCopyRithgTxt": "  Menuonline o sus afiliados",
    "homeTitle": "Hogar",
    "contactTitle": "Contáctenos",
    "aboutTitle": "Sobre nosotros",
    "homeMenuTitle": "Menú",
    "bookTableTitle": "Reservar una mesa",
    "vDateT": "Seleccione una fecha válida",
    "reqDateT": "La fecha es obligatoria",
    "dateGtErr": "La fecha debe ser posterior o igual a la fecha de hoy.",
    "timeInvalid": "El tiempo no es válido",
    "reqTimeT": "Se requiere tiempo",
    "timeInvalidBfr": "Seleccione una hora al menos 5 minutos posterior a la hora actual ",
    "PerReq": "Se requiere número de personas",
    "validcNoT": "Introduzca un número de contacto válido",
    "reqCrT": "Se requiere moneda",
    "reqPrT": "El precio es obligatorio",
    "reCateT": "La categoría es obligatoria",
    "reqVrT": "Se requiere variante",
    "reqVrVldT": "Introduzca una variante válida ",
    "validLNameT": "Introduzca un apellido válido",
    "sDateInValid": "La fecha de inicio no es válida",
    "minmReq": "Se requiere un mínimo",
    "resLblDate": "Seleccione fecha",
    "resLblTime": "Seleccionar hora",
    "perT": "¿Para cuantas personas?",
    "resLblEmail": "¿Por favor proporcione su correo electrónico?",
    "resLblNote": "Añadir una nota de reserva",
    "imageError1MB": "Por favor inserte una imagen que sea menor a 1mb",
    "imageError": "Inserte una imagen que pese menos de 2,5 MB.",
    "imageError3Mb": "Por favor inserte una imagen que sea menor a 3mb",
    "imageInVldError": "El archivo no es válido.",
    "addProMT": "Menú de productos",
    "proT": "Nombre del productos",
    "reqProT": "El nombre del producto es obligatorio",
    "proPT": "Precio del productos",
    "reservationSuccTitle": "Genial, tu reserva está hecha",
    "book": "Libro",
    "custzName": "Nombre de personalización",
    "always": "Siempre",
    "proImgReq": "Se requiere imagen del producto",
    "selCustmT": "Seleccione Personalización o cree uno nuevo",
    "visStR": "Se requiere estado de visibilidad",
    "avlblScR": "Por favor seleccione horario de disponibilidad",
    "addonPR": "Por favor seleccione productos complementarios",
    "upsellPR": "Por favor seleccione productos de venta adicional",
    "markItemR": "Por favor seleccione marcar artículo productos",
    "caloriesR": "Se requieren calorías",
    "allergensR": "Por favor seleccione productos alergénicos",
    "prepInstR": "Se requieren instrucciones de preparación.",
    "staffNR": "Se requieren notas del personal",
    "discountR": "Se requiere descuento",
    "validDiscE": "Introduzca un descuento válido",
    "disday": "Por el días",
    "plSelDayT": "Por favor seleccione el día",
    "sTimeReq": "Se requiere hora de inicio",
    "sTimeInValid": "La hora de inicio no es válida",
    "eTimeReq": "Se requiere la hora de finalización",
    "eTimeInValid": "La hora de finalización no es válida",
    "sDateReq": "La fecha de inicio es obligatoria",
    "eDateReq": "Se requiere hasta la fecha",
    "eDateInValid": "Hasta la fecha no es válido",
    "disdate": "A partir de la fecha",
    "disdate1": "Hasta la fecha",
    "disdate2": "Desde Fecha",
    "currT": "Divisa",
    "iconR": "Por favor seleccione el icono de marcar elemento",
    "minT": "Mínimo",
    "maxT": "Máximo",
    "itemNT": "Nombre del artículo",
    "itemPT": "Precio del artículo",
    "descProT": "Describe tu productos",
    "cateT": "Categoría",
    "selProCateT": "Seleccione la categoría de su productos",
    "reqMT": "El nombre del menú es obligatorio",
    "ViewMenu": "Ver menú",
    "CopyMenu": "Copiar menú",
    "EditMenu": "Editar menú",
    "RemoveMenu": "Eliminar menú",
    "welcomeMnuTitle": "Bienvenido a",
    "reviewT": "Reseñas",
    "userErrorMsg": "Lo sentimos, ocurrió un error.",
    "reqCateT": "El nombre de la categoría es obligatorio",
    "mngCustT": "Administrar personalización",
    "custNReq": "Se requiere el nombre de personalización",
    "incReq": "Se incluye obligatorio",
    "minmValid": "Introduzca un valor mínimo válido",
    "maxmReq": "Se requiere el máximo",
    "cust": "Personalización",
    "crCust": "Crear nueva personalización",
    "custList": "Lista de personalización",
    "delCustmzT": "Esta personalización se eliminará de forma permanente.",
    "mkNameR": "El nombre del elemento de la marca es obligatorio",
    "mkDelT": "Este elemento de marca se eliminará de forma permanente.",
    "hideText": "Esconder",
    "showText": "Espectáculo",
    "device_mac_txt": "Dispositivo Mac",
    "delete_hotspot_txt": "Este punto de acceso se eliminará de forma permanente.",
    "delete_hotspots_txt": "Estos puntos de acceso se eliminarán de forma permanente.",
    "emp_wifi_name": "Nombre del empleado de Wifi",
    "max_limit_txt": "Introduzca \"1024\" para indicar que la velocidad es ILIMITADA. Este valor elimina los límites y permite un rendimiento máximo.",
    "device_port_txt": "Puerto del dispositivo",
    "firmware_txt": "Firmware",
    "hotspot_info_txt": "Información sobre puntos de acceso",
    "editDBtn": "Editar detalles",
    "birth_date": "Fecha de nacimiento",
    "di_theme_clr": "Color del tema exactamente 7 dígitos",
    "color_Invalid_txt": "Seleccione un código de color válido",
    "Req_theme_clr": "El color del tema es obligatorio",
    "select_valid_color_txt": "Seleccione un color de tema válido",
    "req_redir_link": "Se requiere un enlace de redireccionamiento",
    "val_redir_link": "Introduzca un enlace de redireccionamiento válido",
    "req_business_name_txt": "El nombre de la empresa es obligatorio",
    "splash_preview": "Vista previa de bienvenida",
    "create_new_splash": "Crear nueva presentación",
    "splash_page": "Página de bienvenida",
    "splash_page_builder": "Generador de páginas de bienvenida",
    "redirect_link": "Enlace de redireccionamiento",
    "theme_color": "Color del tema",
    "enable_social_login": "Habilitar opciones de redes sociales para el inicio de sesión de los usuarios",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "¿Es obligatorio?",
    "field": "Campo",
    "name": "Nombre",
    "first_name": "Nombre de pila",
    "last_name": "Apellido",
    "birthdate": "Fecha de nacimiento",
    "gender": "Género",
    "email": "Correo electrónico",
    "dob": "Fecha de nacimiento",
    "zip_code": "Código postal",
    "required_redirect_link": "Se requiere un enlace de redireccionamiento",
    "valid_redirect_link": "Introduzca un enlace de redireccionamiento válido",
    "required_theme_color": "El color del tema es obligatorio",
    "theme_color_length": "Color del tema exactamente 7 dígitos",
    "required_name": "El nombre es obligatorio",
    "delete_splash_message": "¿Quieres eliminar esta salpicadura?",
    "delete_multiple_splashes_message": "¿Quieres eliminar estas salpicaduras?",
    "user_login_required": "Es necesario iniciar sesión de usuario",
    "set_theme_color": "Establecer el color del tema",
    "splash_colllect_additional_info_txt": "Recopilar información adicional",
    "verify_mobile_otp_message": "¿Quieres enviar OTP para verificar el número de móvil?",
    "add_company": "Agregar empresa",
    "edit_company": "Editar empresa",
    "ds_device": "Dispositivo de señalización digital",
    "ds_device_activation_fee": "Tarifa de activación de dispositivo de señalización digital",
    "wifi_hardware": "Hardware de Wifi",
    "menu_design": "Diseño de menús",
    "ds_hardware": "Hardware de señalización digital",
    "company_name_req": "El nombre de la empresa es obligatorio",
    "known_name_req": "Se requiere nombre conocido",
    "no_location_req": "Se requiere el número de ubicación",
    "minimum_location": "Por favor ingrese al menos 1 ubicación",
    "terms_req": "Se requieren términos",
    "notes_req": "Se requieren notas",
    "sms_charge_req": "Se requiere cargo por SMS",
    "menuonline_quantity_req": "Menú Online Cantidad requerida",
    "menuonline_cost_req": "Menú Online Costo requerido",
    "ds_quantity_req": "Señalización digital Se requiere cantidad",
    "ds_monthly_cost_req": "Se requiere el costo mensual de señalización digital",
    "reputation_quantity_req": "Se requiere cantidad de reputación",
    "reputation_cost_req": "Se requiere el costo de reputación",
    "marketing_quantity_req": "Se requiere cantidad de marketing",
    "marketing_cost_req": "Se requiere el costo de marketing",
    "tax_percentage_req": "Se requiere el porcentaje de impuestos",
    "router_hardware_quantity_req": "Se requiere cantidad de hardware del enrutador",
    "wifi_hardware_cost_req": "Se requiere el costo del hardware WIFI",
    "activation_cost_req": "Se requiere un costo de activación",
    "wifi_quantity_req": "Se requiere cantidad de WIFI",
    "wifi_router_req": "Se requiere enrutador wifi",
    "menu_design_quantity_req": "Diseño de Menú Cantidad requerida",
    "menu_design_cost_req": "Se requiere el costo del diseño del menú",
    "quantity_req": "Se requiere cantidad",
    "cost_req": "Se requiere el costo",
    "sheduledown_quantity_req": "Horario de cierre La cantidad es requerida",
    "sheduledown_cost_req": "Se requiere el costo del cronograma inicial",
    "loyalty_program_quantity_req": "Programa de fidelización Se requiere cantidad",
    "loyalty_program_cost_req": "Se requiere el costo del programa de lealtad",
    "loyalty_program_sms_quantity_req": "Se requiere cantidad de SMS del programa de fidelización",
    "loyalty_program_sms_cost_req": "Se requiere el costo del SMS del programa de fidelización",
    "comapny_created": "Empresa creada con éxito",
    "comapny_updated": "Empresa actualizada con éxito",
    "billing_info": "INFORMACIÓN DE FACTURACIÓN",
    "business_name_acc_holder": "Nombre de la empresa (titular de la cuenta)",
    "client_first_name": "Nombre del cliente",
    "client_last_name": "Apellido del cliente",
    "dba_known_as": "DBA conocido como",
    "business_address": "Dirección comercial",
    "no_of_locations": "Número de ubicaciones",
    "package_details": "DETALLES DEL PAQUETE",
    "terms": "Términos",
    "one_month": "1 mes",
    "per_sms_charge": "Por cargo por SMS",
    "plan_test": "PLAN : PRUEBA",
    "desc_capital": "DESCRIPCIÓN",
    "qty_capital": "CANTIDAD",
    "cost_capital": "COSTO",
    "total_price_capital": "PRECIO TOTAL",
    "quantity": "Cantidad",
    "can_not_access": "No puedes seleccionar más de",
    "licenses": "licencias",
    "monthly_cost": "Costo mensual",
    "plan_cost": "Costo del plan",
    "tax": "Impuesto",
    "tax_percentage": "Porcentaje de impuestos",
    "monthly_cost_after_tax": "Costo mensual después de impuestos",
    "activation_one_time_charge": "Activación y cargo único",
    "sub_total": "SUB TOTAL",
    "action": "Acción",
    "cost": "Costo",
    "shipping_charge": "Gastos de envío",
    "other": "Otros",
    "additional_cost": "Coste adicional (Otros cargos)",
    "other_tax": "Otros impuestos",
    "total": "Totales",
    "license_statistics": "Estadísticas de licencias",
    "total_licenses": "Licencias totales",
    "available_licenses": "Licencias disponibles",
    "stock": "Existencias",
    "has_permission": "Tiene permiso",
    "avg_stock_price": "Precio de las acciones de AVG",
    "average_price": "Precio medio",
    "allocated": "Asignado",
    "reward_regulars": "Recompensar a los clientes habituales",
    "not_add_coupon_url": "No se puede agregar la URL del cupón. La longitud máxima del mensaje es 160",
    "like_attach_coupon": "¿Quieres adjuntar un cupón?",
    "advance_scheduling": "Programación anticipada",
    "choose_day": "Seleccione día(s) para enviar el mensaje",
    "select_essage_window": "Seleccionar ventana de mensajes",
    "subscription_outside_delivery_window": "Si un usuario se suscribe fuera del período de entrega, recibirá su respuesta automática en el próximo horario disponible.",
    "remaining": "Restante",
    "processing_request": "Se produjo un error al procesar su solicitud.",
    "list_companies": "Lista de empresas",
    "are_you_sure": "Está seguro..?",
    "signin_agreement": "Al confirmar esto, la empresa se creará sin firmar ningún acuerdo.",
    "status_updated": "Actualización de estado exitosamente",
    "status_failed": "No se pudo actualizar el estado",
    "new_editor_status_updated": "El estado de nuevo editor de DS se ha actualizado correctamente.",
    "user_name": "Nombre de usuario",
    "known_as": "conocido como",
    "agreement_signed": "Acuerdo firmado",
    "deactivate": "Desactivar",
    "activate": "Activar",
    "login_to_user_acc": "Iniciar sesión en la cuenta de usuario",
    "disable": "Desactivar",
    "enable": "Permitir",
    "ds_new_editor": "Nuevo editor de DS",
    "sign_agreement": "Firmar acuerdo",
    "agreement_sign": "Acuerdo sin firma",
    "view_agreement": "Ver Acuerdo",
    "download_agreement": "Descargar Acuerdo",
    "add_deleted_user": "Agregar usuario eliminado",
    "deleted_user": "Eliminar usuario",
    "favourite": "Favoritos",
    "refresh": "Refrescar",
    "delete_chat": "Eliminar chat",
    "responsive_drawer": "Cajón responsivo",
    "delete_contact": "Eliminar contactos",
    "clear_chat_history": "Borrar historial de chat",
    "clear_chat_history_dec": "¿Estás seguro de que deseas eliminar este chat?",
    "clear_contact_dec": "¿Estás seguro de que deseas eliminar este contacto?",
    "select_contact": "Seleccionar contacto",
    "new_conversation": "Iniciar nueva conversación",
    "type_msg": "Escribe tu mensaje aquí",
    "select_delete_client": "Por favor seleccione el cliente que desea eliminar",
    "select_delete_client_chat": "Seleccione el cliente que desea eliminar el chat",
    "select_delete_client_chat_err": "No tienes chat con este cliente",
    "acquire_customers_business": "Adquirir nuevos clientes de otras empresas.",
    "customers_from_events": "¡Consigue clientes de los eventos y tráelos a ti!",
    "customers_from_ads": "Obtenga clientes a través de anuncios en periódicos.",
    "smart_pamphlets": "Cree folletos inteligentes para recopilar datos de los clientes.",
    "smart_qr_group": "Un QR inteligente para unirse a un grupo de clientes.",
    "opt_in_number": "Permitir que los clientes opten por el número.",
    "qr_menu": "Crea un código QR para mostrar tu menú.",
    "collect_birthdays": "Recoge el cumpleaños y aniversario del cliente.",
    "join_loyalty_program": "Consiga que nuevos clientes se unan a mi programa de fidelización.",
    "create_feedback_forms": "Crear formularios para recopilar comentarios de los clientes.",
    "exclusive_discounts_promotions": "Ofrece descuentos y promociones exclusivas para promociones cruzadas.",
    "group_smart_qr_opt_in": "Después de crear un grupo, puede generar y seleccionar un código QR inteligente, lo que permite a los clientes registrarse instantáneamente en un grupo.",
    "group_smart_qr_opt_in_dec": "Después de crear un grupo, el widget de imagen permite a los clientes optar por usar texto y recibir promociones, por ejemplo, enviar un mensaje de texto con la palabra \"pizza\" al 555-555-5555",
    "in_store_discount_next_visit": "Obtenga un descuento en la tienda durante su próxima visita a nuestra ubicación",
    "delivery_redirection": "Crear redirección de entrega",
    "additional_info": "información adicional",
    "add_url": "Agregar URL",
    "custom_fields": "Campos personalizados",
    "meta_tag": "Etiqueta metaetiqueta",
    "max_chars": "Máximo de caracteres",
    "add": "Agregar",
    "update_campaign": "Campaña de actualización",
    "last_week_required": "La semana pasada es obligatoria",
    "large_week_value": "El valor de la semana es demasiado grande. Ingrese una semana válida.",
    "less_than_last_week": "No regresar en la última semana debe ser menor que la semana pasada.",
    "last_week_req": "No se requiere devolución en la última semana",
    "birthday_special": "Especial de cumpleaños",
    "birthday_message": "Mensaje automático enviado en sus cumpleaños con saludos personalizados y ofertas tentadoras.",
    "birthday_sms": "Crea felicitaciones de cumpleaños personalizadas para SMS\/MMS",
    "target_audience": "Elija el público objetivo",
    "client_by_birthday": "Cliente por cumpleaños",
    "clients_once_year": "Esta campaña se envía automáticamente a los clientes una vez al año. ",
    "auto_responder_default_msg_1": "¡Eres uno de nuestros mejores clientes a quien nos encanta ofrecer descuentos! Que siempre tengas salud y riqueza. ¡Feliz cumpleaños! %% firstname %%",
    "campaign_send_to_client_birthday": "Esta campaña automática se envía a clientes que cumplen años, puedes personalizar la segmentación a continuación.",
    "get_more_engagement": "Consigue más engagement enviando SMS de cumpleaños 🎂",
    "welcome_new_clients": "Bienvenidos nuevos clientes",
    "make_lasting_impression": "Deje una impresión duradera saludando y dando una cálida bienvenida a quienes comen por primera vez.",
    "personalized_greetings": "Crea saludos personalizados para los nuevos clientes.",
    "greetings_to_new_customers": "Esta campaña enviará saludos de bienvenida a los nuevos clientes el día siguiente de su primera interacción con su empresa.",
    "audience_predefined": "La audiencia de este autorespondedor está predefinida, ¡lo tenemos bajo control!",
    "send_clients_subscribed": "Este autorespondedor está configurado para enviar a los clientes suscritos en los últimos ",
    "first_interaction": "  después de su primera interacción.",
    "default_msg_2": "Esperamos que hayas disfrutado de tu primera visita. Nos encantaría volver a verte pronto, por eso te ofrecemos un descuento solo para ti. Haz clic en el enlace para reservar tu próxima cita con nosotros y canjea tu oferta especial en línea ahora.",
    "new_clients_update": "Texto de actualización de campaña de bienvenida a nuevos clientes",
    "new_clients_warm_greeting": "Damos la bienvenida a los nuevos clientes con un cálido saludo 💌",
    "win_back_clients": "Recuperar clientes",
    "re_engage_past_customers": "Vuelva a involucrar a los clientes anteriores con ofertas irresistibles para alentarlos a regresar.",
    "target_disengaged_clients": "Intente que los clientes desconectados regresen con una oferta especial, una táctica comprobada para atraerlos nuevamente.",
    "campaign_sends_to_clients": "Esta campaña se envía a los clientes que no regresaron después de cierto período de tiempo.",
    "customize_targeting": "Personalice la segmentación a continuación para enviar SMS\/MMS a clientes inactivos.",
    "clients_with_at_least": "Clientes con al menos ",
    "connection_last": " conexión en el último ",
    "return_in_last_week": " semana, pero no regresó en la última ",
    "arr_menu_44": " semana",
    "df_msg_4": "¡Queremos que vuelvas TANTO que te daremos un 10 % de descuento solo por devolverlo! ¿Quieres volver a probar hoy?",
    "update_campaign_text": "Texto de actualización de la campaña para recuperar clientes",
    "re_invite_customers": "Volver a invitar a los clientes que no han regresado durante un tiempo",
    "loyal_patrons": "Muestre su agradecimiento a los clientes leales con descuentos especiales y recompensas de fidelidad.",
    "surprize_top_spenders": "Sorprenda a los mayores gastadores con ofertas personalizadas y especiales.",
    "campaign_automatically_send": "Esta campaña envía automáticamente recompensas para aumentar la afluencia de clientes a su negocio.",
    "sms_loyal_clients": "Esta campaña envía SMS\/MMS a clientes leales, puedes cambiar la orientación a continuación.",
    "or_more_connection": " o más conexiones, dentro de los últimos ",
    "week_period": " período de semana.",
    "default_msg_5": "¡Ya es cliente nuestro desde hace mucho tiempo! ¡Vaya, cómo pasa el tiempo! Le agradecemos que esté con nosotros y le agradecemos que esté con nosotros.",
    "reward_regulars_update": "Texto de actualización de la campaña de recompensas para los habituales",
    "special_discounts": "Aprecia y regala descuentos especiales💎",
    "leave_positive_online": "Incentive a los clientes a dejar reseñas positivas en línea para mejorar la reputación del restaurante.",
    "clients_service_online": "La campaña anima a sus clientes a calificar su servicio en línea.",
    "increase_your_company": "Este autorespondedor se envía para aumentar la calificación en línea de su empresa.",
    "send_message_after": "Enviar mensaje después ",
    "min_connection": " min de la conexión",
    "default_msg_6": "Esperamos que hayas disfrutado de tu primera visita, ¡nos encantaría volver a verte pronto! [URL] Haz clic en el enlace para calificarnos en Google.",
    "ratings_update": "Obtenga más texto de campaña de actualización de calificaciones",
    "friendly_nudge": "Obtén más reseñas con un empujoncito amistoso ⭐",
    "thanks_for_visiting": "Gracias por visitarnos",
    "express_gratitude": "Exprese su gratitud a los clientes por elegir su negocio con sinceros mensajes de agradecimiento.",
    "thank_you_message": "Crea un mensaje de agradecimiento para tus clientes.",
    "clients_for_visiting": "Envíe un cálido agradecimiento a sus clientes por visitar su local.",
    "campaign_audience": "¡Esta audiencia de campaña automática está predefinida, lo tenemos cubierto!",
    "campaign_automatically_sends": "Esta campaña automática se envía automáticamente después de ",
    "minutes_customer_visit": " minutos de visita del cliente",
    "default_msg_7": "Gracias por ser nuestro valioso cliente. Estamos muy agradecidos por el placer de servirle y esperamos haber cumplido con sus expectativas.",
    "visiting_update_campaign": "Gracias por visitar el texto de la campaña de actualización",
    "guests_thank_you": "Agradece a tus invitados por su visita 😊",
    "download_title": "Descargar",
    "qr_gen_title": "QR generado",
    "qr_download_s_title": "Código QR descargado exitosamente",
    "sel_dark_brand_clr_msg": "Por favor seleccione un color de granero válido",
    "manage_customer_stamp_rewards": "Administrar las recompensas de sellos de clientes 🎁",
    "sel_loc_menu_title": "Seleccione ubicaciones para su menú",
    "ans_req": "La respuesta es obligatoria",
    "valid_reputation_name": "Introduzca un nombre de reputación válido",
    "reviews_name_req": "Obtener más reseñas El nombre es obligatorio",
    "birthdate_required": "La fecha de nacimiento es obligatoria",
    "gender_required": "Se requiere género",
    "valid_birthdate_required": "Introduzca una fecha de nacimiento válida",
    "custom_delivery_redirection": "Crear un formulario de redirección de entrega personalizado por grupos",
    "customer_fields_incentive_settings": "Campos de clientes y configuraciones de incentivos",
    "delivery_redirection_text": "Redirección de entrega",
    "delivery_redirection_success": "¡Felicitaciones! ¡Tu widget de redirección de entrega se creó! 🎉",
    "swipe_to_preview_redirection": "Desliza para ver cómo se verá tu formulario de redirección de entregas",
    "enter_item_name": "Introduzca el nombre del artículo",
    "home_page_text": "Texto de la página de inicio",
    "settings": "Ajustes",
    "anniversary_required": "Se requiere fecha de aniversario",
    "valid_anniversary": "Introduzca una fecha de aniversario válida",
    "form_submited": "formulario enviado exitosamente",
    "notifications": "Notificaciones",
    "discount_message": "¡Reclama tu 20% de descuento en Pizza ahora!",
    "is_required": " se requiere",
    "section_title_required": "El título de la sección es obligatorio",
    "section_dec_required": "La descripción de la sección es obligatoria",
    "client_details_required": "Los campos de detalles del cliente son obligatorios",
    "compliance": "Cumplimiento",
    "SMS_campaign1": "Campaña SMS 1",
    "mobile_number_mandatory": "El número de móvil es obligatorio",
    "new_answer": "Nueva respuesta",
    "new_question": "Nueva pregunta",
    "add_new_question": "Añadir nueva respuesta",
    "select": "Seleccionar",
    "group_customers_question": "¿Dónde quieres agrupar a tus clientes?",
    "contacts_added_to_group": "Todos los contactos que envíen el formulario se agregarán al grupo seleccionado.",
    "edit_client_details_section": "Editar la sección de detalles del cliente",
    "client_details_fields": "Campos de detalles del cliente",
    "enter_client_details_section_title_desc": "Ingrese el título y la descripción de la sección de detalles del cliente",
    "choose_fields_displayed_client_side": "Seleccione los campos que se mostrarán en el lado del cliente",
    "section_title": "Título de la sección",
    "add_title": "Añadir título",
    "section_description": "Descripción de la sección",
    "add_description": "Añadir descripción",
    "enter_program_name_location": "Ingresa el nombre del programa y la ubicación 📍",
    "brand_theme_clr_txt": "Color del tema de la marca",
    "set_loyalty_punch_card_theme": "Establezcamos un tema para tu tarjeta perforada de fidelidad 🎨",
    "upload_logo_txt": "Sube tu logotipo",
    "recommended_image_specs": "Recomendamos utilizar una imagen de 512 x 512 píxeles para una mejor visualización. JPG, SVG o PNG. Tamaño máximo de 10 MB.",
    "valid_rep_name_txt": "Introduzca un nombre de reputación válido",
    "rep_name_req_txt": "Obtener más reseñas El nombre es obligatorio",
    "que_req": "La pregunta es obligatoria",
    "day_of_birthday": "En el día del cumpleaños",
    "day_before_birthday": "3 días antes del cumpleaños",
    "week_before_birthday": "Una semana antes del cumpleaños",
    "two_week_before_birthday": "Dos semanas antes del cumpleaños",
    "de_active": "Desactivar",
    "campaign_details": "Detalles de la campaña",
    "link_clicked": "Enlace hecho clic",
    "history": "Historia",
    "auto_responder_summary": "Resumen del contestador automático",
    "vsop_1": "15 minutos",
    "vsop_2": "30 minutos",
    "vsop_3": "45 minutos",
    "vsop_4": "60 minutos",
    "vsop_5": "90 minutos",
    "vsop_6": "120 minutos",
    "in_the_last": " En el ultimo ",
    "return_in_last": " pero no regresó en el último ",
    "of_the_connection": "de la conexión",
    "your_campaign": "Tu campaña",
    "you_have_successfully": "Lo has logrado con éxito",
    "published_successfully": "publicado con éxito",
    "updated_smart_campaign": "Se actualizó la campaña inteligente",
    "almost_done_text": "¡Casi terminado!",
    "update_campaign_desc": "Su autorespondedor está completo. Una vez activado, enviará mensajes a los clientes de forma continua. Esta campaña se puede modificar o pausar fácilmente en cualquier momento.",
    "update_and_publish": "Actualizar y publicar",
    "reset_campaign_title": "Eliminará de forma permanente el texto personalizado, la imagen, las ofertas de descuento y el público objetivo de su campaña.",
    "space_wifi": " Wi-Fi",
    "custom_forms": "Formularios personalizados",
    "web_e_signup": "Registro electrónico web",
    "import": "Importar",
    "permissions_req": "Se requieren permisos",
    "redeemed": "Redimido",
    "coupon_already_redeemed": "Cupón ya canjeado en",
    "autoresponder_campaigns": "Campañas de respuesta automática",
    "autoresponder_campaign_desc": "Habilite el autorespondedor y lance la campaña personalizada",
    "mon": "LUN",
    "tue": "MARtes",
    "wed": "CASARSE",
    "thu": "JUE",
    "fri": "VIE",
    "sat": "SE SENTÓ",
    "sun": "SOL",
    "duplicate": "Duplicado",
    "visibility": "Visibilidad",
    "availability": "Disponibilidad",
    "out_stok": "Fuera de stock",
    "edit_product": "Editar productos",
    "create_product": "Crear un productos",
    "basic_info": "Información básica",
    "basic_sub_info": "Lorem Ipsum es simplemente texto de relleno de la impresión...",
    "enter_product_price": "Introduzca el precio del producto",
    "upload_image": "Subir imagen",
    "allowed_file_formats": "Solo se permiten archivos JPG y PNG con un tamaño máximo de archivo de 3 MB.",
    "pre_select": "Preseleccionar",
    "promotions": "Promociones",
    "discount_availability": "Disponibilidad de descuentos",
    "start_time": "Hora de inicio",
    "end_time": "Fin del tiempos",
    "select_day": "Seleccione el día",
    "menu_required": "El menú es obligatorio",
    "generated": "Generado",
    "link": "Enlace",
    "variations": "Variaciones",
    "select_variant_to_add": "Seleccione la variante que desea agregar",
    "price_title": "Precio",
    "choose_image": "Elija una imagen",
    "select_visiblity": "Seleccionar visibilidad",
    "availability_schedule": "Horario de disponibilidad",
    "add_on_upsell": "Complemento \/ Venta adicional",
    "add_on_select_product": "Añadir a Seleccionar Producto",
    "upsell_product": "Venta adicional de productos seleccionados",
    "variant_deletion_warning": "Esta variante se eliminará permanentemente.",
    "search_variations": "Buscar variaciones",
    "add_variation": "Añadir variación",
    "variation_text": "Variante",
    "select_customization": "Seleccionar personalización",
    "add_new": "Agregar nuevo",
    "delete_customization_warning": "¿Quieres eliminar esta personalización del producto?",
    "nutritional_allergen_info": "Información nutricional y sobre alérgenos",
    "mark_item": "Marcar artículo",
    "calories_text": "Calorias",
    "allergens": "Alérgenos",
    "select_allergens": "Seleccionar alérgenos",
    "special_instructions": "Instrucciones especiales",
    "preparation_instructions": "Instrucciones de preparación",
    "staff_notes": "Notas del personal",
    "checkbox1": "Una casilla de verificación para permitir o no permitir instrucciones especiales de los clientes para este elemento del menú.",
    "menu_c": "MENÚ",
    "past_design": "Diseño pasado",
    "file_name": "Nombre del archivo",
    "variation_name": "Nombre de la variación",
    "grid_items_title1": "Carpa de mesa",
    "grid_items_title2": "Menú digital",
    "grid_items_title3": "Menú para llevar",
    "grid_items_title4": "Menú de media página",
    "grid_items_title5": "Menú digital 5",
    "grid_items_title6": "Menú digital 6",
    "grid_items_title7": "Menú digital 7",
    "grid_items_title8": "Menú digital 8",
    "grid_items_title9": "Menú digital 9",
    "enter_valid_form_name": "Introduzca un nombre de formulario válido",
    "form_name_required": "El nombre del formulario es obligatorio",
    "enter_valid_section": "Introduzca un título de sección válido",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Nombre del formulario",
    "contact_list": "Lista de contactos",
    "label_notify_email": "¿Quieres ser notificado por correo electrónico?",
    "add_client_details_section": "Agregar sección de detalles del cliente",
    "demo_purpose_only": "Es solo para fines de demostración.",
    "form_updated_success": "formulario actualizado exitosamente",
    "form_creat_success": "formulario creado exitosamente",
    "add_custom_section": "Agregar una sección personalizada",
    "edit_custom_section": "Editar una sección personalizada",
    "forms_list": "Lista de formularios",
    "filled_custom_form_details": "Detalles del formulario personalizado completado",
    "custom_form_deletion_warning": "Este formulario personalizado se eliminará permanentemente."
};

export default es