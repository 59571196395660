export const LOCALISE_LANGUAGES = [
    {
        languageCode: "en",
        languageName: "English",
        checked: false,
    },
    // {
    //     languageCode: "de",
    //     languageName: "German",
    //     checked: false,
    // },
    {
        languageCode: "fr",
        languageName: "French",
        checked: false,
    },
    {
        languageCode: "es",
        languageName: "Spanish",
        checked: false,
    },
    {
        languageCode: "ja",
        languageName: "Japanese",
        checked: false,
    },
    // {
    //     languageCode: "it",
    //     languageName: "Italian",
    //     checked: false,
    // },
    {
        languageCode: "nl",
        languageName: "Dutch",
        checked: false,
    },
    // {
    //     languageCode: "sv",
    //     languageName: "Swedish",
    //     checked: false,
    // },
    // {
    //     languageCode: "no",
    //     languageName: "Norwegian",
    //     checked: false,
    // },
    {
        languageCode: "da",
        languageName: "Danish",
        checked: false,
    },
    {
        languageCode: "fi",
        languageName: "Finnish",
        checked: false,
    },
    // {
    //     languageCode: "ko",
    //     languageName: "Korean",
    //     checked: false,
    // },
    // {
    //     languageCode: "he",
    //     languageName: "Hebrew",
    //     checked: false,
    // },
    // {
    //     languageCode: "el",
    //     languageName: "Greek",
    //     checked: false,
    // },
    // {
    //     languageCode: "pt",
    //     languageName: "Portuguese",
    //     checked: false,
    // },
    {
        languageCode: "cs",
        languageName: "Czech",
        checked: false,
    },
    // {
    //     languageCode: "sk",
    //     languageName: "Slovak",
    //     checked: false,
    // },
    // {
    //     languageCode: "hu",
    //     languageName: "Hungarian",
    //     checked: false,
    // },
    // {
    //     languageCode: "pl",
    //     languageName: "Polish",
    //     checked: false,
    // },
    {
        languageCode: "hi",
        languageName: "Hindi",
        checked: false,
    },
];

export const DefaultLanguage: string = "en";

export const PublicVariables:any = {
    currentLanguageObj : LOCALISE_LANGUAGES.find((lang) => lang.languageCode === DefaultLanguage) || DefaultLanguage
}