import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import AppUtils from '../../../../Utils/AppUtils'
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Custom style
import opStyle from './OtherProducts.style'
import '../../index.css'

// Third Party Imports
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Components Imports
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import HomeContextProvider, { HomeContext } from "../../Contexts";
import CustomDialog from '../../Components/CustomDialog';
import OtherProducts from './OtherProducts';
import useLanguageChange from '../../../../Utils/useLanguageChange';

const OtherProductsMain = (props: any) => {
    const localizedVariable = useLanguageChange()

    return (
        <HelmetProvider>
            <HomeContextProvider>
                <Box sx={{ overflow: 'auto', height: '100vh', pt: { xs: "4.5rem", md: "4rem" }, }}>
                    <Header />
                    <Helmet>
                        <title>{localizedVariable['menu_other_products_txt']}</title>
                        <meta name="description" content="Menu Online Privacy Policy" />
                    </Helmet>
                    <OtherProducts {...props} />
                </Box>
                <CustomDialog />
            </HomeContextProvider>
        </HelmetProvider>
    )
}

export default OtherProductsMain