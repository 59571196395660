import en from './en';
import es from './es';
import fr from './fr';
import hi from './hi';
import cs from './cs';
import da from './da';
import fi from './fi';
import ja from './ja';
import nl from './nl';

const Localize: any = {
    en: { ...en },
    fr: { ...fr },
    es: { ...es },
    hi: { ...hi },
    cs: { ...cs },
    da: { ...da },
    fi: { ...fi },
    ja: { ...ja },
    nl: { ...nl },
};

export default Localize;
