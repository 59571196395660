const fi = {
    "rated_google_reviews": "Google-arvosteluissa arvosana 5.0",
    "get_your_digital_menu": "Hanki digitaalinen menu -",
    "instant_popup_menu": "Välitön ponnahdusvalikko ilman QR-skannausta",
    "free_forever": "Ilmainen ikuisesti",
    "the_future_is_here": "Tulevaisuus on täällä!",
    "upgrade_your_restaurant": "Päivitä ravintolaasi upealla digitaalisella menulla ilman kustannuksia. Houkuttele lisää asiakkaita tästä päivästä alkaen.",
    "get_my_free_menu_now": "Hanki ilmainen menuni nyt",
    "no_credit_card_required": "* Luottokorttia ei vaadita",
    "join_largest_marketplace": "Liity suurimmalle ravintolatorille!",
    "what_will_you_get": "Mitä saat",
    "free": "ILMAISEKSI",
    "free_digital_menu": "Ilmainen digitaalinen valikko 2D tai 3Digitaalinen",
    "free_digital_menu_desc": "Sano hyvästit paperisille valikoille ja kalliille uusintapainoksille",
    "quick_and_easy_signup": "Nopea ja helppo rekisteröityminen",
    "communicate_with_customers_title": "Kommunikoi asiakkaiden kanssa ILMAISEKSI!",
    "features1": [
        "Säästä tulostuskustannuksissa ja nauti välittömistä valikkopäivityksistä aina kun tarvitset niitä.",
        "Käytä digitaalista valikkoasi millä tahansa laitteella",
        "Ympäristöystävällinen ja vaivaton"
    ],
    "features2": [
        "Anna asiakkaiden tutkia ruokiasi virtuaalisesti upeilla 3D-visuaaleilla.",
        "Tehosta sitoutumista antamalla ruokailijoille realistinen esikuva ateriastaan.",
        "Erotu joukosta menuelämyksellä, joka tuntuu yhtä houkuttelevalta kuin ruokasi maistuu."
    ],
    "features3": [
        "Tervehdi vieraita välittömästi ruokalistallasi heidän saapuessaan ravintolaan.",
        "Lähetä automaattisesti päivityksiä erikoistarjouksista ja kampanjoista heidän puhelimiinsa.",
        "Paranna ruokailukokemusta nopealla, kontaktittomalla pääsyllä ruokalistaan."
    ],
    "feature4": [
        "Korosta sesongin tarjouksia, päivittäisiä erikoistarjouksia ja eksklusiivisia tarjouksia.",
        "Kiinnitä asiakkaiden huomio suosituimpiin tuotteisiisi.",
        "Luo kiirettä ja jännitystä katseenvangitsijalla visuaalisilla kuvilla ja kuvauksilla."
    ],
    "feature5": [
        "Ole osa verkkoyhteisöä, jossa on tuhansia päivittäisiä vierailijoita.",
        "Lisää näkyvyyttä ja tavoita uusia asiakkaita, jotka etsivät aktiivisesti ruokailuvaihtoehtoja.",
        "Varmista huippupaikka hyvissä ajoin maksimoidaksesi altistumisesi ja houkutellaksesi lisää ruokailijoita."
    ],
    "section3_heading": "Miksi mennä digiksi? Koska se on ilmaista ja helppoa!",
    "section3_description": "Avaa tehokkaita ominaisuuksia, jotka on suunniteltu parantamaan ravintolasi houkuttelevuutta.",
    "instant_menu": "Pikavalikko",
    "instant_menu_notification": "Ponnahdusikkuna ja ilmoitus",
    "instant_menu_description": "Tervetuloa vieraille, joilla on välitön pääsy menuun heti saapuessaan. Lähetä uusin ruokalista ja erikoistarjoukset automaattisesti suoraan heidän matkapuhelimiinsa",
    "digital_menu": "Digitaaliset valikot",
    "at_zero_cost": "nollakustannuksilla",
    "digital_menu_description1": "Sano hyvästit paperisille valikoille ja kalliille uusintapainoksille. Vaihda digitaaliseen valikkoon, joka on aina ajan tasalla – ja se on ikuisesti ilmainen!",
    "digital_menu_description2": "Digitaalisen valikkomme ansiosta tarjontasi ovat aina ajan tasalla ja käytettävissä kaikilla laitteilla. Säästä rahaa ja yksinkertaista valikkopäivityksiä saumattomalla, kustannustehokkaalla ratkaisulla.",
    "try_me_button": "Kokeile Minua",
    "try_3d_menu_title": "3D-valikot",
    "try_3d_menu_title2": "Ilmaiseksi - WOW!",
    "try_3d_menu_description1": "Saa astiat ponnahtamaan näytöltä. Lataa 3D-kuvia ja anna asiakkaiden maistaa ruokaasi virtuaalisesti ennen kuin he astuvat sisään.",
    "try_3d_menu_description2": "Laadukkaat 3D-kuvat luovat suussa sulavan esikatselun, lisäävät houkuttelevuutta ja rohkaisevat tilauksia.",
    "try_now": "Kokeile nyt",
    "showcase_title": "Esittele omasi",
    "showcase_specials": "Erikoistarjoukset",
    "showcase_specials_description1": "Saitko suussa sulavan tarjouksen tai lyömättömän tarjouksen? Korosta se digitaalisessa valikossasi ja houkuttele lisää nälkäisiä ihmisiä.",
    "showcase_specials_description2": "Tee kampanjoista mahdotonta ohittaa kiinnittämällä huomiota rohkeilla visuaaleilla ja houkuttelevilla kuvauksilla.",
    "marketplace": "MarketPlace",
    "marketplace_description": "Rakennamme markkinapaikkaa, jossa on yli 2 000 päivittäistä kävijää. Hyppää kyytiin nyt, niin olet ensimmäinen jonossa, kun lanseeraamme. Voit tavoittaa uusia asiakkaita, jotka etsivät aktiivisesti ruokailumahdollisuuksia. Varmista paikkasi ajoissa maksimoidaksesi näkyvyyden!",
    "see_other_products": "Katso Muut tuotteet",
    "about_us": "Tietoja meistä",
    "why_serving_free": "Miksi palvelemme tätä",
    "serving_free_highlight": "Ilmainen",
    "about_desc": "Olemme paikallisia ihmisiä täällä Dallasissa, ja olemme sitoutuneet auttamaan kaltaisiasi ravintoloita menestymään.",
    "about_tool_free": "Tarjoamme tämän tehokkaan työkalun täysin ilmaiseksi, koska uskomme yhteisömme tukemiseen.",
    "no_catch": "Ei saalis, ei vitsi. Tiedämme, että kun onnistut, yhteisöstämme tulee vahvempi. 😊",
    "understanding_challenges": "Ymmärrämme yrityksen johtamisen haasteet, minkä vuoksi olemme omistautuneet tarjoamaan työkaluja, jotka tekevät toiminnastasi sujuvampaa ja ulottuvuudestasi laajemman.",
    "community_growth": "Kun kasvat, yhteisömme kasvaa kanssasi luoden menestystä ja tukea. Tehdään yhdessä ruokailukokemus kaikille.",
    "how_it_works": "Miten se toimii",
    "three_simple_steps": "Kolme yksinkertaista askelta",
    "get_started_highlight": "Aloita",
    "no_tech_skills_needed": "Teknisiä taitoja ei tarvita. Jos pystyt kääntämään hampurilaisen, voit tehdä tämän!",
    "step1_title": "Näytä kampanjasi ILMAISEKSI!",
    "step1_desc": "Aloita nopeammin kuin osaat sanoa \"Tilaa!\" Täytä vain yksinkertainen lomake.",
    "step2_title": "Mukauta valikkoasi",
    "step2_desc": "Lisää ruokiasi, lähetä valokuvia jopa 3D-muodossa ja korosta erikoisuuksiasi.",
    "step3_title": "Jaa ja kasvata",
    "step3_desc": "Jaa digitaalinen ruokalistasi QR-koodien, sosiaalisen median tai verkkosivustosi kautta.",
    "testimonials": "Mielipiteitä",
    "testimonials_section_title": "Älä vain ota sanaamme",
    "testimonials_section_desc": "Katso, mitä muut Dallas-ravintoloiden omistajat sanovat.",
    "faq_section_title": "Onko sinulla kysymyksiä? Meillä on vastauksia",
    "faq_section_desc": "Kaikki mitä sinun tulee tietää ennen aloittamista.",
    "faqs": "UKK",
    "faq_answers": [
        {
            "que": "Onko se todella ilmainen ikuisesti?",
            "ans": "Täysin! Ei piilomaksuja, ei luottokortteja, ei sidottuja ehtoja."
        },
        {
            "que": "Tarvitsenko teknistä osaamista?",
            "ans": "Jos voit lähettää sähköpostia, voit tehdä tämän. Se on niin yksinkertaista."
        },
        {
            "que": "Miksi tarjoat tätä ilmaiseksi?",
            "ans": "Uskomme antamaan ensin. Meillä saattaa olla muita palveluita, joista pidät, mutta se on täysin sinun päätettävissäsi."
        },
        {
            "que": "Mikä on tilanne torilla?",
            "ans": "Me keitämme sen! Kun meillä on tarpeeksi ravintoloita, lanseeraamme markkinapaikkasi, jotta saat entistä enemmän näkyvyyttä."
        }
    ],
    "testimonials_content1": "Digitaaliseen valikkoon siirtyminen oli paras tekomme! Asiakkaamme rakastavat käyttömukavuutta ja uudistettua ulkoasua!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "Ravintola Mansion",
    "testimonials_content2": "Uusi digitaalinen ruokalistamme helpottaa vieraiden selaamista, ja se lisää todella sitoutumista!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Urbaani syö",
    "testimonials_content3": "Vieraat hämmästyttävät 3D-visuaalista – se on auttanut meitä erottumaan joukosta ja parantamaan heidän ruokailukokemustaan!",
    "testimonials_name3": "Davis Green",
    "testimonials_restaurant_name3": "Town Heath",
    "ready_to_boost_your_restaurant": "Oletko valmis parantamaan ravintolaasi?",
    "ready_to_boost_desc": "Älä jää jälkeen. Liity digitaaliseen vallankumoukseen jo tänään!",
    "yes_i_want_my_free_digital_menu": "Kyllä, haluan ilmaisen digitaalisen valikoni",
    "480_plus": "480+",
    "people_already_joined": "ihmisiä on jo liittynyt",
    "menu_online_home": "Menu Online Home",
    "welcome": "Tervetuloa",
    "description_other": "Tehdään lisää asiakastyytyväisyyttäsi!",
    "benefits": "Edut",
    "greeting_text": "Tervetuloa 👋",
    "all_in_one_features": "🚀 All-in-One-ominaisuudet ravintolasi kohottamiseksi",
    "menu_online_menu_name": "Menu verkossa",
    "menu_online_description": "Digitalisoi ruokalistasi, jotta asiakkaat voivat käyttää sitä helposti verkossa.",
    "menu_online_benefits": "Paranna asiakkaiden mukavuutta ja lisää sitoutumista antamalla heille mahdollisuus tarkastella ruokalistaasi milloin ja missä tahansa.",
    "ds_name": "Digital Signage",
    "ds_description": "Esittele tarjouksia ja kampanjoita dynaamisilla myymälän näytöillä.",
    "ds_benefits": "Vangitse asiakkaiden huomio houkuttelevilla visuaaleilla, mainostamalla lisämyyntiä ja reaaliaikaisia ​​tarjouksia vaivattomasti.",
    "loyalty_punch_name": "Kanta-asiakaskortti",
    "loyalty_punch_description": "Palkitse toistuvia asiakkaita digitaalisella kanta-asiakasohjelmalla.",
    "loyalty_punch_benefits": "Edistä asiakkaiden säilyttämistä ja lisää myyntiä kannustamalla uskollisuutta lunastettavilla palkkioilla toistuvista vierailuista.",
    "marketing_menu_name": "Markkinointi",
    "marketing_description": "Tavoita enemmän asiakkaita kohdistetuilla digitaalisilla markkinointikampanjoilla.",
    "marketing_benefits": "Laajenna ravintolasi kattavuutta ja lisää kävijöitä räätälöityillä kampanjoilla, jotka on räätälöity houkuttelemaan uusia ja toistuvia asiakkaita.",
    "get_more_reviews_menu_name": "Hanki lisää arvosteluja",
    "get_more_reviews_description": "Kannusta tyytyväisiä asiakkaita jättämään positiivisia online-arvosteluja.",
    "get_more_reviews_benefits": "Kasvata ravintolasi uskottavuutta ja houkuttele uusia ruokailijoita parantamalla online-mainettasi positiivisemmilla arvosteluilla.",
    "guest_smart_wifi_menu_name": "Vieras Smart Wifi",
    "guest_smart_wifi_description": "Tarjoa ilmainen Wi-Fi vierastietojen keräämiseen markkinointia varten.",
    "guest_smart_wifi_benefits": "Lisää asiakastyytyväisyyttä ja kerää samalla arvokasta asiakasdataa tulevien kampanjoiden personoimiseksi.",
    "reservation_menu_name": "Varaus",
    "reservation_description": "Asiakkaiden on mahdollista varata pöytiä verkossa helposti.",
    "reservation_benefits": "Paranna ruokailukokemusta minimoimalla odotusajat ja virtaviivaista varauksia istumapaikkojen hallinnan parantamiseksi.",
    "pos_integration_menu_name": "POS-integrointi",
    "pos_integration_description": "Integroi järjestelmämme POS-järjestelmääsi.",
    "pos_integration_benefits": "Yksinkertaista tilausten käsittelyä ja vähennä virheitä pitämällä verkko- ja myymäläjärjestelmäsi synkronoituna.",
    "online_ordering_menu_name": "Online-tilaus",
    "online_ordering_description": "Anna asiakkaille mahdollisuuden tehdä tilauksia suoraan verkkosivustoltasi tai sovelluksestasi.",
    "online_ordering_benefits": "Lisää myyntiä ja vähennä riippuvuutta kolmansien osapuolien alustoista tarjoamalla suora tilausvaihtoehto, joka säästää maksuja.",
    "pro_label": "Pro",
    "coming_soon": "Tulossa pian",
    "locked_label": "Lukittu",
    "forever_label": "Ikuisesti",
    "new_label": "Uusi",
    "other_products_page_title": "Muut tuotteet | Menu verkossa",
    "other_products_description": "Menu Online-tietosuojakäytäntö",
    "other_products_header_title": "Muut tuotteet",
    "privacy_policy": {
        "title": "Tietosuojakäytäntö",
        "lastUpdated": "Viimeksi päivitetty: 1. heinäkuuta 2024",
        "introduction": {
            "header": "Tietosuojakäytäntö uusille ja nykyisille asiakkaille",
            "paragraph1": "SEURAAVAT AVAINKOHDAT (MUKAAN LUKIEN OSIJOIDEN VIITTEET) TOIMITETAAN VAIN KÄYTTÖKÄYTTÖÄSI EIVÄT VIRTA TÄYSIÄ EHTOJA. ON SINULLA VASTUULLISUUS LUE NÄMÄ KÄYTTÖEHDOT ENNEN MENUONLINE INC -PALVELUN KÄYTTÖÄ.",
            "paragraph2": "Menuonlinen käyttö tarkoittaa, että hyväksyt käyttöehdot ja edustat ja takaat, että organisaatiosi tai yrityksesi on valtuuttanut sinut tekemään niin. (Katso s.3 \"Kuinka se toimii\")",
            "paragraph3": "Menuonline Inc on lupapohjainen markkinointipalvelu. Tahallinen \"roskapostin\" lähettäminen tai käyttöehtojen rikkominen johtaa tilisi sulkemiseen. (Katso s.16 \"Palvelun hyväksyttävä käyttö\")",
            "paragraph4": "Menuonline Inc:ssä isännöidyt tiedot, kuten yhteystiedot, sähköpostin sisältö ja Menuonline Inc:n kautta keräämäsi tiedot, kuuluvat sinulle. (Katso s.14 \"Mitä omistat?\").",
            "paragraph5": "Menuonline Inc -ohjelmisto ja verkkosivustomme sisältö kuuluvat meille. (Katso kohta 13 \"Mitä me omistamme?\") Omien käyttäjienne lisäksi Menuonline Inc:n kautta isännöimääsi dataa tai julkaisematonta sisältöä voivat tarkastella vain Menuonline Inc:n työntekijät ja kumppanit, jotka ovat allekirjoittaneet salassapitosopimuksen. (Katso s.14 \"Mitä omistat?\")",
            "paragraph6": "Kaikkia henkilökohtaisia ​​tunnistetietoja (PII) säilytetään vain Kanadassa sijaitsevilla palvelimilla. (Katso s.14 \"Mitä omistat?\").",
            "paragraph7": "Menuonline Inc -palvelu on Kanadan ja Ontarion provinssin lakien alainen. (Katso s.24 \"Sovellettava laki\")",
            "paragraph8": "Kaikki Kanadan sisällä tai Kanadan ulkopuolelta tulevien henkilöiden, poliisin tai muiden sääntelyvirastojen esittämät tietosi tai sisältöäsi koskevat pyynnöt ohjataan aina sinulle. Ainoa poikkeus olisi, jos tämä saa meidät rikkomaan Kanadan tai Ontarion lakia; Vastustamme kuitenkin kaikissa tapauksissa tiukasti sitä, että kukaan muu kuin sinä luovuttaa tietojasi. (Katso s.14 \"Mitä omistat?\").",
            "paragraph9": "Menuonline Inc:n tietoturva on yhtä hyvä kuin käyttäjätunnusten ja salasanojen hallinta. Emme veloita Menuonline Inc:n käyttäjätilistä, joten luo jokaiselle käyttäjälle erillinen tili ja pidä salasanat salassa. Olet vastuussa tilistäsi. (Katso s.3 \"Kuinka se toimii\")",
            "paragraph10": "Voit peruuttaa tilisi milloin tahansa. Emme hyvitä käyttämättömiä luottoja, jos peruutat. (Katso s.3 \"Kuinka se toimii\")"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC. KÄYTTÖEHDOT",
            "lastUpdate": "VIIMEINEN PÄIVITYS: 14.1.2020",
            "paragraph1": "Lue nämä palveluehdot (\"Ehdot\") huolellisesti ennen tämän verkkosivuston (\"Sivusto\") käyttöä. Nämä ehdot vapauttavat Menuonline Inc:n (\"Menuonline Inc\" \"me\" tai \"me\") ja muut vastuusta ja\/tai rajoittavat meidän ja heidän vastuutaan ja sisältävät muita tärkeitä ehtoja, jotka koskevat tämän Sivuston käyttöä. Vierailemalla tai käyttämällä tätä Sivustoa tai Palvelua sitoudut omasta puolestasi ja minkä tahansa organisaation puolesta, jonka puolesta saatat toimia (jota kutsutaan tässä yhteisesti \"sinä\"), hyväksymään ja noudattamaan näitä ehtoja jokaisen tämän Sivuston käytön ja jokaisen vierailun yhteydessä."
        },
        "services": {
            "title": "Palvelumme.",
            "paragraph": "Palvelut, joita tarjoamme Sivuston kautta palvelun, jonka avulla voit luoda, lähettää ja hallita sähköpostiviestejä (\"Sähköpostit\") valitsemillesi vastaanottajille (\"Palvelu\")."
        },
        "license": {
            "title": "Lisenssi.",
            "paragraph": "Näiden ehtojen mukaisesti Menuonline Inc myöntää täten sinulle ei-yksinomaisen, rajoitetun, ei-siirrettävän lisenssin Kauden aikana tarkastella Sivustoa ja käyttää Palvelua palvelun ominaisuuksien sallimalla tavalla. Pidätämme kaikki oikeudet, joita ei tässä nimenomaisesti myönnetä Palveluun ja Menuonline Inc:n sisältöön (kuten on määritelty alla)."
        },
        "emailingContent": {
            "title": "Sisällön lähettäminen sähköpostitse.",
            "paragraph": "Kun olet vuorovaikutuksessa Menuonline Inc:n kanssa tämän sivuston tai palvelun kautta, sitoudut antamaan oikeita ja tarkkoja tietoja. Lisäksi ymmärrät, että luottamuksellisten tietojen antaminen sähköpostitse ei ole turvallinen käytäntö. Ymmärrät, että olet yksin vastuussa kaikesta, mitä lähetät Palvelumme käyttäjille."
        },
        "support": {
            "title": "Tukea.",
            "paragraph": "Tarjoamme sinulle tukea auttaaksemme sinua tulemaan omavaraisiksi palvelun kanssa. Tarjoamme sinulle tukea asiakirjojen ja videoiden muodossa Sivustolla, Menuonline Inc:n tukilippujärjestelmässä ja puhelimitse. Voit ottaa meihin yhteyttä napsauttamalla \"Tuki\" -välilehteä sivuston yläosassa. Tuotetuesta ei veloiteta; Pidätämme kuitenkin oikeuden lopettaa tiettyjen asiakastilien tuen harkintamme mukaan."
        }
    },
    "terms_condition": {
        "title": "Palveluehdot | Menu verkossa",
        "header": "Ehdot uusille ja nykyisille asiakkaille"
    },
    "privacy_policy_title": "YKSITYISPOLITIIKKA",
    "privacy_policy_effective_date": "Voimassa 16.1.2020 alkaen",
    "privacy_policy_general_title": "YLEINEN YKSITYISKOHTA",
    "privacy_policy_intro": "menuonline.com (Menuonline ja \"me\") ymmärtää yksilöiden henkilötietojen yksityisyyden suojan tärkeyden ja kaikenlaisten luottamuksellisten tietojen turvallisuuden tärkeyden. Olemme luoneet tämän tietosuojakäytännön (\"käytäntö\") osoittaaksemme sitoutumisemme \"yksityisyytesi\" suojaamiseen. Tämä yksityinen käytäntö selittää:",
    "privacy_policy_points": [
        "Mitä henkilötietoja keräämme ja miksi keräämme niitä;",
        "Kuinka käytämme henkilötietojasi;",
        "Kenen kanssa jaamme henkilötietosi; ja",
        "Valinnat, jotka sinulla on pääsyyn, päivittämiseen ja henkilötietojesi poistamiseen palvelusta."
    ],
    "privacy_policy_pipeda": "Menuonline hallinnoi kaikkien tietojen keräämistä Kanadan henkilötietojen suojasta ja sähköisistä asiakirjoista annetun lain (PIPEDA) mukaisesti.",
    "privacy_policy_update_notice": "Huomaa, että Menuonline pidättää oikeuden päivittää tai muuttaa tätä Yksityisyyskäytäntöä milloin tahansa. Ilmoitamme sinulle kaikista muutoksista julkaisemalla päivitetyn käytännön tällä verkkosivustolla tai palvelujen kautta. Tämän käytännön viimeisin versio koskee mycirclen käyttäjien henkilötietojen käyttöä. Jatkamalla pääsyä mycirclen verkkosivustolle tai jatkamalla sen palvelujen käyttöä sen jälkeen, kun tähän yksityiseen käytäntöön on tehty muutoksia, hyväksyt tarkistetut ehdot.",
    "privacy_policy_consent_title": "SUOSTUMUS HENKILÖTIEDOT",
    "privacy_policy_consent_text": "Antamalla Menuonlinelle henkilötietosi verkkosivustomme tai minkä tahansa lomakkeemme kautta, hyväksyt tällaisten tietojen keräämisen, käytön ja paljastamisen tämän käytännön mukaisesti tai tarkoituksiin, jotka tunnistettiin sinulle, kun annoit henkilötietoja. Henkilötietojen toissijaista käyttöä käsitellään vain sinun nimenomaisella suostumuksellasi tai sinulle tarjotaan mahdollisuus kieltäytyä.",
    "privacy_policy_opt_out_text": "Sinulla on mahdollisuus peruuttaa suostumuksesi tai kieltäytyä siitä milloin tahansa. Jos haluat peruuttaa suostumuksesi tai kieltäytyä siitä, voit napsauttaa viestintäasetukset-linkkiä minkä tahansa meiltä saamasi sähköpostin alaosassa.",
    "privacy_policy_info_collection_title": "KERÄÄMME TIEDOT",
    "privacy_policy_info_collection_text": "Menuonline kerää ja käsittelee henkilötietoja tämän käytännön mukaisesti. Emme myy, jaa tai vuokraa näitä tietoja muille, ellei tässä käytännössä mainita. Menuonline kerää henkilötietoja palveluidemme tai tuotteidemme tarjoamiseksi sinulle.",
    "privacy_policy_info_types_text": "Menuonlinen keräämiä tietoja ovat mm.",
    "privacy_policy_account_holders_title": "Menuonline-tilinomistajat",
    "privacy_policy_registration_info": "Rekisteröintitiedot: Jos kirjaudut käyttämään palveluita, tarvitset Menuonline-tilin tullaksesi tilinhaltijaksi (\"Tilinhaltija\"). Kun rekisteröit tilin, sinua pyydetään antamaan tiettyjä perustietoja, kuten nimesi, sähköpostiosoitteesi, käyttäjätunnuksesi, salasanasi, yrityksen nimi, ammatti, sijainti ja puhelinnumero. Salasanat ovat salattuja – Menuonlinen henkilökunta ei voi tarkastella tai hakea salasanoja.",
    "privacy_policy_billing_info": "Laskutustiedot: Jos tilaat palvelumme, sinun on annettava meille maksu- ja laskutustiedot.",
    "privacy_policy_cookies_info": "Evästeet: Käytämme evästeitä seurataksemme vierailijatietoja, kuten verkkosivustolla käyntien lähdettä ja verkkosivustollamme olevien vierailujen määrää.",
    "privacy_policy_ip_info": "IP-osoitteet: IP-osoitteita kerätään sinusta osana palveluiden käyttöä (esim. maatason sijainnin ja palveluidemme käytön yhteydessä käytettyjen IP-osoitteiden tallentamista varten).",
    "privacy_policy_your_contacts_title": "Yhteystietosi",
    "privacy_policy_distribution_list": "Jakeluluettelo: Osana palveluidemme käyttöä voit antaa meille tai voimme kerätä osana palveluita henkilökohtaisia ​​tietoja yhteystiedoistasi (\"Yhteystiedot\"). Nämä tiedot sisältävät sähköpostiosoitteita ja voivat sisältää muita tunnistettavia tietoja, kuten nimen, yrityksen nimen tai muita tietoja, jotka annat Menuonlinelle palveluita käyttäessäsi. Sinun on varmistettava, että hankit yhteyshenkilöiltäsi asianmukaisen suostumuksen, jotta voimme käsitellä heidän tietojaan.",
    "privacy_policy_billing_info_contacts": "Laskutustiedot: Jos tilaat palvelumme, sinun on annettava meille maksu- ja laskutustiedot.",
    "privacy_policy_cookies_contacts": "Evästeet: Menuonline käyttää evästeitä ja vastaavia seurantatekniikoita henkilökohtaisten tietojen keräämiseen ja käyttämiseen yhteystiedoistasi vain, jos olet lisännyt Menuonline-seurantakoodin verkkosivustollesi. Kaikilla Menuonlinen isännöimillä aloitussivuilla on automaattisesti seurantakoodi.",
    "privacy_policy_ip_contacts": "IP-osoitteet: Yhteyshenkilöistäsi kerätään IP-osoitteita osana palveluita (esim. maatason sijainnin ja lomakkeen ja suostumuksen antamisessa käytettyjen IP-osoitteiden tallentamista varten).",
    "privacy_policy_info_about_contacts_title": "KERÄÄMME YHTEYSTIEDOT",
    "privacy_policy_info_about_contacts_notice": "*Tämä osio koskee vain Menuonlinen keräämiä, käyttämiä tai paljastamia tietoja tilinomistajan yhteystiedoista palvelujen tarjoamista varten*",
    "privacy_policy_info_about_contacts_desc": "Menuonline kerää, käyttää tai paljastaa henkilökohtaisia ​​tietoja Yhteyshenkilöistä vain, jos Tilinomistaja on rekisteröitynyt tiettyihin palveluihin, joissa he toimittavat meille jakeluluettelon. Tilinomistajat ovat yksin vastuussa siitä, että he ovat noudattaneet kaikkia soveltuvia lakeja antaessaan yhteystietojaan Menuonlinelle palveluiden kautta.",
    "privacy_policy_info_auto_collect_title": "Tiedot Menuonline kerää automaattisesti",
    "privacy_policy_info_auto_collect_desc1": "Kun Tilinhaltijan Yhteystiedot ovat tekemisissä tilinomistajalta saatuun sähköpostikampanjaan tai ovat vuorovaikutuksessa tilinhaltijan yhdistettyyn verkkosivustoon johtavan linkin kanssa, Menuonline voi kerätä tietoja kyseisen yhteyshenkilön laitteesta ja vuorovaikutuksesta sähköpostin kanssa evästeiden avulla. Näitä tietoja kerätään vain palvelujen tarjoamiseksi ja näkemyksen tarjoamiseksi Tilinhaltijalle.",
    "privacy_policy_info_auto_collect_desc2": "Tiedot, joita keräämme Yhteyshenkilön laitteesta ja sovelluksista, joita Yhteyshenkilö käyttää tilinhaltijalta palveluidemme kautta lähetettyihin sähköposteihin, voivat sisältää Yhteyshenkilön IP-osoitteen, käyttöjärjestelmän, selaintunnuksen ja muita asiaan liittyviä tietoja Yhteyshenkilön järjestelmästä ja yhteydestä.",
    "privacy_policy_info_auto_collect_desc3": "Tietoja, joita keräämme Yhteyshenkilön vuorovaikutuksesta palveluiden kautta lähetettyjen sähköpostien kanssa, voivat sisältää päivämäärät ja kellonajat, jolloin Yhteyshenkilö käyttää sähköpostia ja selaustoimintoja, sekä tietoja palvelujen toimivuudesta, kuten sähköpostien ja muiden tilinomistajan yhteyshenkilölle palvelujen kautta lähettämien viestien toimittamisesta.",
    "privacy_policy_info_auto_collect_desc4": "Huomaa, että Menuonline ei ota yhteyttä Yhteyshenkilöihisi, ellei sovellettava laki sitä edellytä.",
    "privacy_policy_why_we_collect_title": "MIKSI KERÄÄMME TIETOSI",
    "privacy_policy_why_we_collect_desc": "Menuonline kerää tietoja seuraaviin tarkoituksiin:",
    "privacy_policy_why_collect_1": "tarjota Menuonline-tilinhaltijoille heidän tilaamiaan palveluita, mukaan lukien Tilinomistajan tietojen käyttö palveluissa;",
    "privacy_policy_why_collect_2": "hallintaan ja tilinhallintaan sekä pääsyyn mycirclen verkkosivustolle;",
    "privacy_policy_why_collect_3": "jos olet antanut suostumuksesi markkinointi- ja promootiomateriaalien vastaanottamiseen, ilmoittaaksesi sinulle muista Menuonlinen tarjoamista tuotteista ja palveluista; ja",
    "privacy_policy_why_collect_4": "vastataksesi tilinomistajan viestiin tai lähettääksesi pyytämiäsi tietoja. Tämä voi sisältää, mutta ei rajoitu niihin:",
    "privacy_policy_why_collect_5": "erikoistarjoukset ja päivitykset;",
    "privacy_policy_why_collect_6": "uutiskirje; ja",
    "privacy_policy_why_collect_7": "palveluilmoituksia.",
    "privacy_policy_opt_out_desc": "Voit milloin tahansa kieltäytyä vastaanottamasta viestit napsauttamalla tilauksen peruutuspainiketta jokaisen Menuonlinesta sinulle lähetetyn sähköpostin alaosassa.",
    "privacy_policy_use_disclosure_title": "HENKILÖTIETOJEN KÄYTTÖ JA ILMOITTAMINEN",
    "privacy_policy_use_disclosure_desc1": "Menuonlinelle lähetetyt henkilötiedot, mukaan lukien Menuonlinen verkkosivuston tai määrätyn lomakkeen kautta, säilytetään ehdottoman luottamuksellisina, ellei niitä luovuteta Tilinhaltijan nimenomaisen suostumuksen tai lakisääteisten vaatimusten mukaisesti. Joka tapauksessa tilinomistajien millä tahansa \"Menuonline\"-alustalla antamat henkilötiedot ovat vapaaehtoisia: kaikki tilinomistajan antamat henkilötiedot toimitetaan tilinomistajan harkinnan mukaan. Huomaa, että suostumuksesi peruuttaminen saattaa johtaa siihen, että et voi käyttää tai käyttää tiettyjä Menuonline-palveluita.",
    "privacy_policy_use_disclosure_desc2": "Menuonline käyttää verkkosivuillaan ja palveluillaan kolmansia osapuolia ja ulkopuolisia palveluntarjoajia. Tilinhaltijan henkilötietoja voidaan jakaa tällaisten kolmansien osapuolten ja palveluntarjoajien kanssa. Voimme myös tarjota koottuja tilastoja tilinhaltijoista, myynnistä, liikennemalleista ja niihin liittyvistä tiedoista hyvämaineisille kolmansille osapuolille. Menuonline edellyttää, että kaikki kolmannen osapuolen palveluntarjoajat, joilla on pääsy henkilötietoihin, ovat tämän Yksityisyyskäytännön mukaisia ​​velvoitteita sitovia ja että tällaiset palveluntarjoajat keräävät, käyttävät tai paljastavat tilinomistajan henkilötietoja vain Menuonline-palvelujen tarjoamista varten.",
    "privacy_policy_use_disclosure_desc3": "Tilinhaltijoiden toimittamia henkilötietoja ei myydä, jaeta tai vuokrata ulkopuolisille organisaatioille, ellei implisiittisen tai nimenomaisen suostumuksesi mukaisesti. Menuonline pidättää oikeuden ottaa yhteyttä mihin tahansa tilinomistajaan tietojen välittämiseksi, kysymyksiin vastaamiseksi tai ilmoitusten ilmoittamiseksi ohjelmamme tai käytäntöjemme muutoksista. Huomaa, että Menuonline ei ota yhteyttä yhteystietoihisi, ellei sovellettava laki sitä vaadi.",
    "privacy_policy_use_disclosure_desc4": "Menuonline luovuttaa henkilötietoja vain, jos asianomainen henkilö on antanut siihen suostumuksen tai:",
    "privacy_policy_disclosure_list_1": "kun laki sallii tai vaatii, kuten vastauksena haasteeseen tai muuhun oikeudelliseen prosessiin;",
    "privacy_policy_disclosure_list_2": "koko Menuonlinen tai sen osan siirronsaajalle;",
    "privacy_policy_disclosure_list_3": "Menuonlinen palveluntarjoajille tämän käytännön mukaisesti; tai",
    "privacy_policy_disclosure_list_4": "täyttääkseen lakisääteiset velvoitteet, mukaan lukien, mutta ei rajoittuen, lakisääteiset raportointivelvoitteet.",
    "privacy_policy_service_providers_title": "Menuonline käyttää seuraavia kolmansia osapuolia ja palveluntarjoajia (Yhteystietojen henkilökohtaisia ​​tietoja ei luovuteta \"tällaisille\" kolmansille osapuolille):",
    "privacy_policy_service_provider_1": "Käytämme ManyChatia tarjotaksemme verkkosivuston chat-palveluita myyntiä ja tukea varten sekä näiden keskustelujen seurantasähköpostiviestejä.",
    "privacy_policy_service_provider_2": "Käytämme AskNicelyä asiakkaidemme kyselyyn kysyäksemme heidän saamansa palvelun ja tuen laadusta.",
    "privacy_policy_service_provider_3": "Käytämme join.me-palvelua asiakkaiden ja mahdollisten asiakkaiden tapaamisten, tukipuhelujen ja ohjelmisto-esittelyjen ajoittamiseen.",
    "privacy_policy_service_provider_4": "Käytämme Google Analyticsia seurataksemme anonyymiä verkkosivuston toimintaa ja mittaamme verkkosivustomme käyttötapoja.",
    "privacy_policy_breach_notification_title": "RIKKOMUSILMOITUS",
    "privacy_policy_breach_notification_desc": "Menuonline noudattaa ja tarjoaa Tilinhaltijoille kohtuullista apua sovellettavien lakien mukaisesti koskien henkilötietojen luvatonta käyttöä, pääsyä tai luovuttamista.",
    "privacy_policy_rights_access_title": "OIKEUTESI JA KÄYTTÖÖNOTTOSI",
    "privacy_policy_rights_access_desc": "Sinulla on oikeus päästä käsiksi henkilötietoihin, joita Menuonline käsittelee sinusta. Voit pyytää meiltä tietoja seuraavista:",
    "privacy_policy_rights_access_list_1": "Henkilötiedot, joita meillä on sinusta",
    "privacy_policy_rights_access_list_2": "Asianomaisten henkilötietojen luokat",
    "privacy_policy_rights_access_list_3": "Käsittelyn tarkoitukset",
    "privacy_policy_rights_access_list_4": "Tiedot kenelle henkilötietosi on\/joille luovutetaan",
    "privacy_policy_rights_access_list_5": "Kuinka kauan säilytämme henkilötietojasi",
    "privacy_policy_rights_access_list_6": "Jos emme keränneet tietoja suoraan sinulta, tiedot lähteestä",
    "privacy_policy_rights_access_list_7": "Kuinka tehdä valitus oikealle valvontaviranomaiselle",
    "privacy_policy_rights_access_list_8": "Voit myös pyytää meiltä seuraavia:",
    "privacy_policy_rights_access_list_9": "Päivitämme sinua koskevat puutteelliset tai epätarkat tiedot",
    "privacy_policy_rights_access_list_10": "Pyydä, että poistamme henkilötietosi sovellettavien lakien mukaisesti",
    "privacy_policy_rights_access_contact": "Voit pyytää meitä käsittelemään oikeuksiasi ottamalla meihin yhteyttä osoitteeseen 2851 Dufferin Street, Toronto, ON, Kanada tai lähettämällä meille sähköpostia osoitteeseen {link}.",
    "privacy_policy_accountability_title": "VASTUULLISUUS",
    "privacy_policy_accountability_desc": "Menuonline on sitoutunut olemaan vastuussa henkilökohtaisista ja luottamuksellisista tiedoista, joita annat meille. Katso yhteystiedot tämän käytännön lopusta.",
    "privacy_policy_links_other_sites_title": "LINKIT MUILLE SIVUILLE",
    "privacy_policy_links_other_sites_desc1": "Menuonlinen verkkosivuilla voi olla linkkejä muille sivustoille. Huomaa, että Menuonline ei ole vastuussa muiden sivustojen tietosuojakäytännöistä. Kehotamme Tilinhaltijoitamme olemaan tietoisia siitä, että heidän tulee sivustoltamme poistuessaan lukea huolellisesti jokaisen henkilökohtaisesti tunnistettavia tietoja keräävän verkkosivuston yksityiset käytännöt.",
    "privacy_policy_links_other_sites_desc2": "Tämä tietosuojakäytäntö koskee vain keräämiämme tietoja.",
    "privacy_policy_links_other_sites_desc3": "Jos käytät linkkiä Menuonline-sivustolta ostamaan tuotteita tai palveluita, teet ostotapahtuman toimittajayrityksen, et Menuonlinen, sääntöjen ja määräysten mukaisesti.",
    "privacy_policy_cookies_title": "YKSITYISKOHTAISET Evästeet",
    "privacy_policy_cookies_desc1": "Menuonline-verkkosivusto ja -palvelut käyttävät evästeitä tiettyjen tietojen keräämiseen ja tallentamiseen tämän käytännön mukaisesti. \"Eväste\" on pieni tekstitiedosto, joka lähetetään verkkosivustolta ja jonka käyttäjän verkkoselain tallentaa käyttäjän tietokoneelle. Kun vierailet evästeitä käyttävällä sivustolla, tietokoneellesi tai mobiililaitteellesi ladataan eväste. Kun seuraavan kerran vierailet kyseisellä sivustolla, laitteesi muistaa hyödyllisiä tietoja, kuten asetukset, vieraillut sivut tai kirjautumisvaihtoehdot.",
    "privacy_policy_cookies_desc2": "Evästeitä käytetään laajalti toiminnallisuuden lisäämiseen verkkosivustoille tai niiden tehokkaamman toiminnan varmistamiseksi. Sivustomme luottaa evästeisiin optimoidakseen käyttökokemuksen ja varmistaakseen, että sivuston palvelut toimivat oikein.",
    "privacy_policy_cookies_desc3": "Useimmat verkkoselaimet sallivat evästeiden rajoittamisen tai estämisen selaimen asetuksista, mutta jos poistat evästeet käytöstä, saatat huomata, että tämä vaikuttaa kykyysi käyttää tiettyjä verkkosivustomme tai palveluidemme osia.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Analyyttiset tarkoitukset: <\/strong> Käytämme evästeitä analysoidaksemme tilinomistajan toimintaa parantaaksemme verkkosivustoamme. Voimme esimerkiksi käyttää evästeitä tarkastellaksemme aggregoituja malleja, kuten tilinomistajien käyttämiä ominaisuuksia. Voimme käyttää tällaista analyysiä saadaksemme näkemyksiä siitä, miten voimme parantaa verkkosivustomme toimivuutta ja käyttökokemusta.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Asetuksesi ja käyttäjäkokemuksesi: <\/strong> Käytämme evästeitä kerätäksemme tiettyjä tietoja vierailijoista ja tilinhaltijoista, kuten selaintyypin, palvelimen, kielivalinnan ja maa-asetuksen, tallentaaksemme tilinhaltijan asetukset verkkosivustollemme tehdäksemme tilinomistajakokemuksesta johdonmukaisemman ja mukavamman. Käytämme evästeitä ylläpitääksemme kirjautuneena, kun vierailet verkkosivustollamme toistuvasti.",
    "privacy_policy_cookies_targeted_ads": "<strong> Tarjoa mittauspalveluita ja paremmin kohdistettuja mainoksia ja markkinointia: <\/strong> Käytämme evästeitä, verkkojäljitteitä ja muita tallennustekniikoita kolmansien osapuolien kumppaneilta, kuten Googlelta ja Facebookilta, mittauspalveluihin, parempien mainosten kohdistamiseen ja markkinointitarkoituksiin. Tämä tapahtuu, kun vierailet verkkosivuillamme. Näiden evästeiden, verkkojäljitteiden ja muiden tallennustekniikoiden avulla voimme näyttää sinulle Menuonline-mainosmateriaalia muilla Internet-sivustoilla, joissa käyt.",
    "privacy_policy_security_title": "TURVALLISUUS",
    "privacy_policy_security_desc1": "Menuonline pyrkii suojaamaan kaiken tyyppisiä luottamuksellisia tietoja, mukaan lukien henkilökohtaiset tiedot, organisatorisilla, fyysisillä, mekaanisilla ja elektronisilla suojatoimilla, jotka vastaavat tietojen arkaluonteisuutta. Palvelimemme sijaitsevat Kanadassa ja noudattavat alan turvallisuusstandardeja.",
    "privacy_policy_security_desc2": "Huomaa kuitenkin, että emme voi taata, että ylläpitämämme toimenpiteet takaavat tietojen turvallisuuden.",
    "privacy_policy_retention_title": "SÄILYTTÄMINEN",
    "privacy_policy_retention_desc": "Säilytämme henkilötietoja tilinhaltijoista ja yhteyshenkilöistä niin kauan kuin on tarpeen tässä Yksityisyyskäytännössä määriteltyihin tarkoituksiin ja käyttöehdoissa olevien säilytysehtojen mukaisesti.",
    "privacy_policy_access_questions_title": "PÄÄSY, KYSYMYKSET JA HUOLELLE",
    "privacy_policy_access_questions_desc": "Henkilö, joka on toimittanut Menuonlinelle henkilökohtaisia ​​tietojaan, voi lähettää kirjallisen pyynnön saada Menuonlinen säilyttämiin henkilötietoihinsa pääsy tai korjata niitä.",
    "terms_obj": {
        "terms_conditions_title": "KÄYTTÖEHDOT",
        "terms_conditions_subtitle": "Ehdot uusille ja nykyisille asiakkaille",
        "terms_conditions_desc1": "SEURAAVAT AVAINKOHDAT (MUKAAN LUKIEN OSIJOIDEN VIITTEET) TOIMITETAAN VAIN KÄYTTÖKÄYTTÖÄSI EIVÄT VIRTA TÄYSIÄ EHTOJA. ON SINULLA VASTUULLASI LUE NÄMÄ EHDOT ENNEN Menuonline-PALVELUN KÄYTTÖÄ.",
        "terms_conditions_desc2": "Menuonlinen käyttö tarkoittaa, että hyväksyt käyttöehdot ja edustat ja takaat, että organisaatiosi tai yrityksesi on valtuuttanut sinut tekemään niin. (Katso s.3 \"Kuinka se toimii\")",
        "terms_conditions_desc3": "Menuonline on lupapohjainen markkinointipalvelu. Tahallinen \"roskapostin\" lähettäminen tai käyttöehtojen rikkominen johtaa tilisi sulkemiseen. (Katso s.16 \"Palvelun hyväksyttävä käyttö\")",
        "terms_conditions_desc4": "Menuonlinessa isännöimäsi tiedot, kuten yhteystiedot, sähköpostin sisältö ja Menuonlinen kautta keräämäsi tiedot, kuuluvat sinulle. (Katso s.14 \"Mitä omistat?\").",
        "terms_conditions_desc5": "Menuonline-ohjelmisto ja verkkosivustomme sisältö kuuluvat meille. (Katso s.13 \"Mitä omistamme?\")",
        "terms_conditions_desc6": "Omia käyttäjiäsi lukuun ottamatta Menuonlinen kautta isännöimääsi dataa tai julkaisematonta sisältöä voivat tarkastella vain Menuonlinen työntekijät ja yhteistyökumppanit, jotka ovat allekirjoittaneet salassapitosopimuksen. (Katso s.14 \"Mitä omistat?\").",
        "terms_conditions_desc7": "Kaikkia henkilökohtaisia ​​tunnistetietoja (PII) säilytetään vain Kanadassa sijaitsevilla palvelimilla. (Katso s.14 \"Mitä omistat?\").",
        "terms_conditions_desc8": "Menuonline-palvelu on Kanadan ja Ontarion maakunnan lakien alainen. (Katso s.24 \"Sovellettava laki\")",
        "terms_conditions_desc9": "Kaikki Kanadan sisällä tai Kanadan ulkopuolelta tulevien henkilöiden, poliisin tai muiden sääntelyvirastojen esittämät tietosi tai sisältöäsi koskevat pyynnöt ohjataan aina sinulle. Ainoa poikkeus olisi, jos tämä saa meidät rikkomaan Kanadan tai Ontarion lakia; Vastustamme kuitenkin kaikissa tapauksissa tiukasti sitä, että kukaan muu kuin sinä luovuttaa tietojasi. (Katso s.14 \"Mitä omistat?\").",
        "terms_conditions_desc10": "Menuonline-turvallisuus on yhtä hyvä kuin käyttäjätunnusten ja salasanojen hallinta. Emme veloita Menuonline-käyttäjätileistä, joten luo jokaiselle käyttäjälle erillinen tili ja pidä salasanat salassa. Olet vastuussa tilistäsi. (Katso s.3 \"Kuinka se toimii\")",
        "terms_conditions_desc11": "Voit peruuttaa tilisi milloin tahansa. Emme hyvitä käyttämättömiä luottoja, jos peruutat. (Katso s.3 \"Kuinka se toimii\")",
        "terms_conditions_desc12": "Voimme peruuttaa tilisi milloin tahansa. Hyvitämme sähköpostihyvityksiä ja käyttämättömiä palveluita, jos peruutamme tilisi. (Katso s.3 \"Kuinka se toimii\")",
        "terms_conditions_desc13": "Tietosi poistetaan peruutuksen jälkeen ja ne voidaan poistaa myös, jos tilin käyttäjä ei ole kirjautunut sisään 12 kuukauteen tai pidempään. Säilytämme tililtäsi koottuja tietoja, jotka eivät sisällä henkilökohtaisia ​​tunnistetietoja tilastollisiin tarkoituksiin. (Katso s.3 \"Kuinka se toimii\")",
        "terms_conditions_desc14": "Sähköpostihyvityksiä ostetaan jakoperusteisesti, eivätkä ne koskaan vanhene. Ainoa poikkeus on, jos et ole kirjautunut tilillesi 12 kuukauteen. Tässä tapauksessa pidätämme oikeuden poistaa tilisi, tietosi ja siihen liittyvät hyvitykset. (Katso s.10 \"Mitä maksan palvelusta?\")",
        "terms_conditions_desc15": "Teemme kovasti töitä varmistaaksemme, että Menuonline on aina toiminnassa ja ilmoittaa etukäteen, jos joudumme keskeyttämään palvelun tilapäisesti huoltotöiden vuoksi. Emme kuitenkaan takaa, että palvelu on aina toiminnassa, emmekä ole vastuussa tappioista, joita kärsit Menuonlinen käytöstä, mukaan lukien tappiot, jotka aiheutuvat, jos järjestelmä ei ole käynnissä silloin, kun sitä tarvitset. (Katso s.17 \"Vastuuvapauslausekkeet\").",
        "terms_conditions_desc16": "Emme valvo sisältöäsi tai kenelle lähetät viestejä. Seuraamme kuitenkin valituksia ja muita toimintasi aiheuttamia ongelmia. Liian monet valitukset tai ongelmat voivat johtaa palvelun peruuttamiseen harkintamme mukaan. (Katso s.7 \"Seuranta\")",
        "terms_conditions_desc17": "Olemme sitoutuneet tarjoamaan erinomaista tuotetukea auttaaksemme sinua tulemaan omavaraisiksi Menuonlinen avulla. Tarjoamme tukea dokumentaation ja videon muodossa verkkosivuillamme, Menuonline-tukilippujärjestelmässä ja puhelimitse. Tuotetuesta ei veloiteta; Pidätämme kuitenkin oikeuden lopettaa tiettyjen asiakastilien tuen harkintamme mukaan. (Katso s.5 \"Tuki\")",
        "terms_conditions_desc18": "Tuotepalvelut on tarkoitettu niihin aikoihin, jolloin haluat meidän tekevän Menuonlineen liittyviä töitä sen sijaan, että teemme sen itse. Hintamme tuotepalveluista on 135 dollaria per tunti. Tarjoamme myös konsultointipalveluita korkeammalla hinnalla. Pidätämme oikeuden muuttaa hintojamme milloin tahansa. (Katso s.6 \"Tuote- ja konsultointipalvelut\")",
        "terms_conditions_desc19": "Ilmoitamme sinulle, jos uskomme, että tietojasi on loukattu, ja pyrimme toimittamaan asiaan liittyviä yksityiskohtia ja tietoja. (Katso s.14 \"Mitä omistat?\")",
        "terms_conditions_desc20": "Pidätämme oikeuden tehdä muutoksia tähän sopimukseen milloin tahansa. Pyrimme ilmoittamaan sinulle ehtojen muutoksista, mutta palvelun käyttö tarkoittaa, että hyväksyt Ehtojen muutokset. (Katso s.9 \"Muutokset\").",
        "terms_conditions_desc21": "Käyttöehdot ja tietosuojakäytäntömme on laadittu vain englanniksi, ja hyväksyt, että käyttöehtojen ja tietosuojakäytännön englanninkielinen versio on ensisijainen, jos käännetty versio on ristiriidassa.",
        "terms_conditions_mycircle": "MYCIRCLE-KÄYTTÖEHDOT",
        "terms_conditions_last_update": "VIIMEINEN PÄIVITYS: 14.1.2020",
        "terms_conditions_usage_agreement": "Lue nämä käyttöehdot (\"Ehdot\") huolellisesti ennen tämän verkkosivuston (\"Sivusto\") käyttöä. Nämä ehdot vapauttavat Menuonlinen (\"Menuonline\", \"me\" tai \"me\") ja muut vastuusta ja\/tai rajoittavat meidän ja heidän vastuutaan ja sisältävät muita tärkeitä ehtoja, jotka koskevat tämän Sivuston käyttöä.",
        "terms_conditions_acceptance": "Vierailemalla tai käyttämällä tätä Sivustoa tai Palvelua sitoudut omasta puolestasi ja minkä tahansa organisaation puolesta, jonka puolesta saatat toimia (jota kutsutaan tässä yhteisesti \"sinä\"), hyväksymään ja noudattamaan näitä ehtoja jokaisen tämän Sivuston käytön ja jokaisen vierailun yhteydessä.",
        "terms_conditions_services": "1. Palvelumme.",
        "terms_conditions_services_desc": "Sivuston kautta tarjoamamme palvelut ovat palveluja, joiden avulla voit luoda, lähettää ja hallita sähköpostiviestejä (\"Sähköpostit\") valitsemillesi vastaanottajille (\"Palvelu\").",
        "terms_conditions_license": "2. Lisenssi.",
        "terms_conditions_license_desc": "Näiden ehtojen mukaisesti Menuonline myöntää täten sinulle ei-yksinomaisen, rajoitetun, ei-siirrettävän lisenssin Kauden aikana tarkastella Sivustoa ja käyttää Palvelua palvelun ominaisuuksien sallimalla tavalla. Pidätämme kaikki oikeudet, joita ei tässä nimenomaisesti myönnetä Palveluun ja Menuonline-sisältöön (kuten alla on määritelty).",
        "terms_conditions_account": "3. Kuinka se toimii.",
        "terms_conditions_account_desc": "Palvelun käyttäminen edellyttää tilin rekisteröintiä. Tilisi on ilmainen. Tilisi antaa sinulle pääsyn Palveluun ja \"toimintoihin\", joita saatamme luoda ja ylläpitää ajoittain. Jotta voit saada tilin, sinun on:",
        "terms_conditions_account_requirements": [
            "oltava vähintään kahdeksantoista (18) vuoden ikäinen ja kykenevä tekemään sopimuksia;",
            "loppuun määrittämämme rekisteröintiprosessi;",
            "hyväksyä nämä ehdot;",
            "antaa oikeat, täydelliset ja ajantasaiset yhteys- ja laskutus-\/maksutiedot."
        ],
        "responsibility": "Vastuullisuus.",
        "responsibilityText": "Olet yksin vastuussa tililläsi tapahtuvasta toiminnasta, ja sinun on pidettävä tilisi salasana luottamuksellisena ja turvassa. Tilit ovat ilmaisia, joten suosittelemme luomaan eri tilin jokaiselle käyttäjälle. Olet myös vastuussa kaikista tileistä, joihin sinulla on käyttöoikeus, riippumatta siitä, oletko antanut luvan kyseisen tilin käyttöön vai et. Hyväksyt, että olet yksin vastuussa vuorovaikutuksestasi niiden kanssa, joille lähetät sähköposteja, ja lähetät sähköposteja vain niille ihmisille, joille olet antanut suostumuksesi. Sinun tulee välittömästi ilmoittaa meille kaikista tiliesi luvattomista käytöstä.",
        "compliance_with_CASL": "Kanadan roskapostintorjuntalain (CASL) noudattaminen.",
        "compliance_with_CASL_text": "Menuonline-rooli on helpottaa suostumusasetusten hallintaa, ei valvoa lainsäädäntöä. Sinun vastuullasi on noudattaa CASL-standardia. Autamme sinua määrittämällä oletustoiminnot ja helpottamalla dokumentoidun nimenomaisen suostumuksen hankkimista ja päivittämistä sekä hallitsemalla implisiittisen suostumuksen muuttuvia vanhenemispäiviä. Hallitset täysin tietokantasi suostumuksen hallintaa; voit päivittää kaikkien yhteystietojesi suostumuksen tilan kerralla kerralla, ohittaa oletusasetukset ja poistaa käytöstä oletetun suostumuksen tilan automaattisen vanhenemisen.",
        "closing_account": "Tilin sulkeminen.",
        "closing_account_text": "Joko sinä tai Menuonline voit irtisanoa tämän Sopimuksen milloin tahansa ja mistä tahansa syystä ilmoittamalla siitä toiselle osapuolelle. Jos annat virheellisiä, epätarkkoja, epätäydellisiä tai epätäydellisiä tietoja tai käytät Palvelua tavalla, joka rikkoo näitä ehtoja, meillä on oikeus välittömästi jäädyttää tilisi ja lopettaa pääsysi Palveluun kaikkien muiden käytettävissämme olevien korjauskeinojen lisäksi. Jos et käytä tiliäsi kahteentoista (12) kuukauteen, voimme peruuttaa tilisi. Jos lopetamme tilisi jostain syystä, hyvitämme sinulle jäljellä olevat krediitit. Kun lopetamme, poistamme pysyvästi tilisi ja kaikki siihen liittyvät tiedot, mukaan lukien lähettämäsi sähköpostit. Saatamme säilyttää tililtäsi koottuja tietoja, jotka eivät sisällä henkilökohtaisia ​​tunnistetietoja tilastollisiin tarkoituksiin.",
        "emailing_content": "Sisällön lähettäminen sähköpostitse.",
        "emailing_content_text": "Kun olet vuorovaikutuksessa Menuonlinen kanssa tämän sivuston tai palvelun kautta, sitoudut antamaan oikeita ja tarkkoja tietoja. Lisäksi ymmärrät, että luottamuksellisten tietojen antaminen sähköpostitse ei ole turvallinen käytäntö. Ymmärrät, että olet yksin vastuussa kaikesta, mitä lähetät Palvelumme käyttäjille. tarkoituksiin.",
        "support": "Tukea.",
        "support_text": "Tarjoamme sinulle tukea auttaaksemme sinua tulemaan omavaraisiksi palvelun kanssa. Tarjoamme sinulle tukea dokumenttien ja videoiden muodossa Sivustolla, Menuonline-tukilippujärjestelmässä ja puhelimitse. Voit ottaa meihin yhteyttä napsauttamalla \"Tuki\" -välilehteä sivuston yläosassa. Tuotetuesta ei veloiteta; Pidätämme kuitenkin oikeuden lopettaa tiettyjen asiakastilien tuen harkintamme mukaan.",
        "product_and_consulting_services": "Tuote- ja konsultointipalvelut.",
        "product_and_consulting_services_text": "Tuotepalvelut on tarkoitettu niihin aikoihin, jolloin haluat meidän tekevän Menuonlineen liittyviä töitä sen sijaan, että teemme sen itse. Hintamme tuotepalveluista on 125 dollaria per tunti. Tarjoamme myös konsultointipalveluita korkeammalla hinnalla. Pidätämme oikeuden muuttaa hintojamme milloin tahansa.",
        "monitoring": "Valvonta.",
        "monitoring_text": "Emme valvo tai arvioi Palvelun kautta välitettyjen tietojen sisältöä, mutta tutkimme, jos saamme valituksia mahdollisesta sopimattomasta käytöstä. Menuonline voi milloin tahansa ja oman harkintansa mukaan päättää, ovatko tietyt käyttötarkoitukset tarkoituksenmukaisia ​​ilmoittamalla sinulle tai ilmoittamatta siitä seuraavien ohjeiden mukaisesti.",
        "term": "Termi.",
        "term_text": "Voimassaoloaika alkaa, kun rekisteröidyt meille, ja jatkuu niin kauan kuin käytät Palvelua. Vakuutat ja takaat, että sinulla on valtuudet ja kyky rekisteröidä tili meille (mukaan lukien jos rekisteröidyt organisaation puolesta).",
        "changes": "Muutokset.",
        "changes_text": "Voimassaoloaika alkaa, kun rekisteröidyt meille, ja jatkuu niin kauan kuin käytät Palvelua. Vakuutat ja takaat, että sinulla on valtuudet ja kyky rekisteröidä tili meille (mukaan lukien jos rekisteröidyt organisaation puolesta).",
        "payment": "MAKSU",
        "what_do_i_pay_for_service": "Mitä maksan Palvelusta?.",
        "what_do_i_pay_for_service_text": "Voit ostaa ennakkoon maksettuja hyvityksiä sähköpostin lähettämiseen (\"hyvitykset\") Palvelumme kautta, kuten kuvataan yksityiskohtaisesti Sivustomme \"hinnoittelu\"-sivulla. Krediitit eivät koskaan vanhene, eikä näistä krediiteistä peritä toistuvia maksuja. Ainoa poikkeus on, että jos et käytä tiliäsi kahteentoista (12) kuukauteen, voimme peruuttaa tilisi ja kaikki käyttämättömät hyvitykset. Voimme muuttaa hinnoitteluamme milloin tahansa päivittämällä sivustomme hinnoittelusivut, joita sovelletaan seuraavaan krediittien ostoon.",
        "how_do_i_pay_for_service": "Kuinka maksan Palvelusta?.",
        "how_do_i_pay_for_service_text": "Voit maksaa luottoja millä tahansa yleisimmällä luottokortilla. Voimme muuttaa hyväksymiämme maksutapoja milloin tahansa.",
        "can_i_get_refund": "Voinko saada hyvityksen?.",
        "can_i_get_refund_text": "Summia, jotka olet maksanut palvelun käytöstä, ei palauteta, jos peruutat tilisi. Jos peruutamme tilisi, hyvitämme sinulle jäljellä olevat krediitit.",
        "rights": "OIKEUDET",
        "what_do_we_own": "Mitä me omistamme?.",
        "what_do_we_own_text": "Lukuun ottamatta sisältöä, jonka annat meille (mukaan lukien rajoituksetta yhteystiedot, sähköpostin sisältö ja Palvelun kautta keräämäsi tiedot) (\"Sinun sisältösi\"), kaikki sivustollamme oleva materiaali, Palvelu ja kaikki siellä oleva tai sen kautta siirretty materiaali, mukaan lukien rajoituksetta ohjelmistot, kuvat, tekstit, grafiikat, piirrokset, logot, patentit, tavaramerkit, palvelumerkit, valokuvat, musiikki, ääni ja sisältö (muulle käyttäjälle, Sisältö\") (ja kaikki siihen liittyvät immateriaalioikeudet) ovat meidän tai lisenssinantajamme omistamia.",
        "what_do_you_own": "Mitä omistat?.",
        "what_do_you_own_text": "Emme vaadi omistusoikeuksia sisältöösi. Sisältösi on sinun ja säilytät Palveluun lataamasi Sisällön omistusoikeuden. Jakamalla Sisältöäsi Palvelun kautta hyväksyt sen, että muut voivat tarkastella, muokata ja\/tai jakaa sisältöäsi asetustesi ja näiden käyttöehtojesi mukaisesti. Vakuutat ja takaat, että joko omistat kaiken materiaalin, jota käytät sähköpostiviesteissäsi, tai sinulla on siihen lupa käyttää. Saatamme käyttää tai paljastaa materiaaliasi vain näissä ehdoissa ja tietosuojakäytännössämme kuvatulla tavalla.",
        "privacy_statement": "Menuonline kunnioittaa käyttäjiensä yksityisyyttä. Kaikki Menuonlinen tämän sivuston tai palvelun kautta keräämät henkilötiedot ovat Menuonlinen tietosuojalausunnon alaisia, jotka on sisällytetty näihin ehtoihin viittauksella. Saatamme käyttää ja paljastaa tietojasi tietosuojaselosteemme mukaisesti.",
        "access_to_your_content": "Menuonline ei anna pääsyä sisältöösi kenellekään paitsi niille Menuonlinen työntekijöille ja urakoitsijoille, jotka ovat tehneet kanssamme asianmukaiset luottamuksellisuussopimukset tai meillä on suostumuksesi.",
        "governmentRequest": "Jos saamme hallitukselta tai sääntelyviranomaiselta pyynnön päästä sisältöösi, ilmoitamme sinulle pyynnöstä ja pyydämme suostumuksesi ennen kuin julkaisemme mitään sisältöäsi. Vastustamme kaikkia Sisältöäsi koskevia pyyntöjä, emmekä julkaise Sisältöäsi vastauksena tämäntyyppisiin pyyntöihin ilman lupaasi tai toimivaltaisen lainkäyttöalueen tuomioistuimen tai sovellettavan sääntelyelimen vaatimilta.",
        "breachNotification": "Jos saamme tietoomme, että sisältöösi on joutunut luvaton pääsy, käyttö, kopiointi, varastettu, kadonnut tai käytetty tai paljastettu näiden ehtojen vastaisesti (\"rikkomus\"), ilmoitamme sinulle viipymättä ja tiedotamme tapahtuneesta. Hyväksyt, että rikkomukset käsitellään sovellettavan lain mukaisesti, ja jos tarvitsemme sinun ilmoittavan sähköpostisi jakelulistojesi jäsenille, suostut tekemään sen viipymättä ja ohjeiden mukaan.",
        "contentRetention": "Säilytämme ja käytämme ja välitämme sisältösi Kanadassa, paitsi jos sinulla on etukäteen kirjallinen lupasi tai ohjeistus (esimerkiksi jos päätät lähettää sisältösi Kanadan ulkopuolella oleville henkilöille). Palvelun tarjoamiseen käytetyt palvelimet sijaitsevat Kanadassa.",
        "accessTrouble": "Jos sinulla on vaikeuksia päästä sisältöösi, Menuonline auttaa sinua hakemaan sisältösi aika- ja materiaaliperusteisesti.",
        "reportingAbuse": "Jos uskot jonkun rikkovan jotakin näistä ehdoista, ilmoita siitä meille välittömästi. Jos olet saanut roskapostia, jonka uskot tulevan toiselta Palvelun käyttäjältä, jos uskot, että toinen Palvelun käyttäjä on lähettänyt materiaalia, joka loukkaa tekijänoikeuksia, tai jos uskot, että toinen Palvelun käyttäjä muuten rikkoo näitä ehtoja, ota meihin yhteyttä osoitteeseen Support@menuonline.com.",
        "acceptableUse": "Vakuutat ja takaat, että käytät Palvelua kaikkien sovellettavien lakien ja määräysten mukaisesti. Hyväksyt, että olet vastuussa sen määrittämisestä, onko Palvelu soveltuva käytettäväksi omalla lainkäyttöalueellasi. Varmuuden vuoksi hyväksyt, että sinun tulee (ja varmistaa, että työntekijäsi, edustajasi ja valtuutetut käyttäjäsi) ...",
        "exportLaws": "Emme esitä, että Sivusto tai Palvelu on sopiva tai saatavilla käytettäväksi Kanadan ulkopuolella, ja pääsy niille alueilta, joilla niiden sisältö on laitonta, on kielletty. Et saa käyttää tai viedä tai jälleenviedä tämän sivuston Menuonline-sisältöä tai muuta materiaalia tai mitään kopiota tai muunnelmaa sovellettavien lakien tai määräysten vastaisesti, mukaan lukien rajoituksetta Kanadan ja Yhdysvaltojen vientilait ja -määräykset.",
        "disclaimers": "Palvelu ja tämä Sivusto tarjotaan \"sellaisenaan\" ilman minkäänlaisia ​​esityksiä, takuita tai ehtoja, ja Menuonline nimenomaisesti kiistää sovellettavien lakien sallimissa rajoissa kaikki esitykset, takuut ja ehdot, nimenomaisesti tai epäsuorasti, lain tai muutoin, mukaan lukien rajoituksetta kaikki oletetut takuut ja ehdot, jotka koskevat käyttöä tai kolmansien osapuolien sopivuutta mihin tahansa tiettyyn tarkoitukseen tai sopimattomuuteen. oikeudet...",
        "limitLiability": "Tämän Sopimuksen mukainen Menuonlinen kokonaisvastuu, joko sopimuksesta tai vahingonkorvausvelvollisuudesta (mukaan lukien huolimattomuus), joka johtuu takuun rikkomisesta, ankarasta vastuusta tai minkä tahansa muun vastuuteorian perusteella, rajoittuu suoriin vahingonkorvauksiin, joiden määrä ei ylitä Menuonlinelle näiden ehtojen mukaisesti maksettujen maksujen kokonaismäärää viimeisimmän vastuun syntymiskuukautta edeltävänä 12 kuukauden aikana.",
        "liabilityExclusions": "Kenelläkään Menuonlinesta, sen tytäryhtiöistä tai niiden vastaavista johtajista, toimihenkilöistä, työntekijöistä, edustajista tai muista edustajista (yhteisesti \"Menuonlinen \"edustajat\") tai minkään liitetyn verkon operaattoreista ei ole mitään vastuuta tai vastuuta tämän Sivuston tai Palvelun yhteydessä: (i) epäsuorista, välillisistä, satunnaisista, rangaistavista tai esimerkillisistä erityisvahingoista; tai (ii) kaikista vahingoista, olivatpa ne suoria, epäsuoria, seurauksellisia, satunnaisia, esimerkillisiä, rankaisevia tai erityisiä ja jotka ovat menetettyjä tuloja, menetettyjä säästöjä tai tuloja tai menetettyjä voittoja...",
        "indemnity": "Vapautat Menuonlinen ja\/tai Menuonline-edustajat (\"Menuonlinen korvatut osapuolet\") ja pidät niitä vapaina kolmansien osapuolten esittämistä vaatimuksista ja kaikista meille määrätyistä sakoista tai seuraamuksista, jotka johtuvat tältä Sivustolta saatujen tietojen käyttämisestä tai Palvelun käytöstäsi, ja kaikista...",
        "ipInfringement": "Menuonline puolustaa, hyvittää ja pitää sinut vaarattomana kaikilta vaatimuksilta, vaatimuksilta, toimilta, kanteilta tai oikeudenkäynneiltä, ​​joita kolmas osapuoli on esittänyt tai nostanut sinua vastaan ​​ja väittää, että pääsysi Palveluun tai sen käyttö tämän Sopimuksen mukaisesti loukkaa kolmannen osapuolen immateriaalioikeuksia Kanadan lain mukaisesti (\"IP-loukkausvaatimus\")...",
        "equitableRelief": "Jos rikot näitä ehtoja, voimme hakea kieltomääräystä (eli voimme pyytää oikeuden määräystä pysäyttämään sinut) tai muita oikeudenmukaisia ​​korvauksia. Hyväksyt, että tämä täydentää muita oikeuksia, joita meillä saattaa olla lain tai pääoman suhteen, eikä rajoita niitä.",
        "linksToOtherSites": "Jos tarjoamme linkkejä kolmansien osapuolien verkkosivustoille, ne tarjotaan vain käyttömukavuuden vuoksi. Jos käytät näitä linkkejä, poistut sivustoltamme. Emme hallitse näitä kolmansien osapuolien sivustoja tai tue niitä. Hyväksyt, että emme ole vastuussa mistään näistä kolmansien osapuolien sivustoista tai niiden sisällöstä...",
        "thirdPartyRequests": "Jos meidän on annettava tietoja vastauksena tiliäsi tai Palvelun käyttöäsi koskevaan oikeudelliseen tai valtion pyyntöön, hyväksyt, että voimme veloittaa sinulta kulumme. Näihin palkkioihin voivat sisältyä lakiasiainpalkkiot ja kustannukset, jotka aiheutuvat työntekijämme ajasta pyyntöön vastauksen valmisteluun.",
        "assignment": "Et voi siirtää tai luovuttaa tätä sopimusta ja sen nojalla myönnettyjä oikeuksia ja lisenssejä, mutta Menuonline voi luovuttaa ne rajoituksetta ilmoittamalla siitä sinulle kohtuullisessa ajassa etukäteen.",
        "governingLaw": "Näitä ehtoja säätelevät ja tulkitaan kaikkien sovellettavien liittovaltion lakien ja Ontarion lakien mukaisesti ottamatta huomioon sen lainvalintaa koskevia määräyksiä. Sitoudut alistumaan Ontarion tuomioistuinten yksinomaiseen toimivaltaan näiden ehtoja koskevien riitojen ratkaisemiseksi.",
        "severability": "Jos toimivaltainen tuomioistuin jostain syystä toteaa jonkin näiden Ehtojen säännöksen tai sen osan lainvastaiseksi tai jostain syystä täytäntöönpanokelvottomaksi, kyseistä ehtoa pannaan täytäntöön suurimmassa sallitussa laajuudessa näiden Ehtojen tarkoituksen toteuttamiseksi, ja loput näistä ehdoista ovat edelleen täysin voimassa ja täytäntöönpanokelpoisia.",
        "waiver": "Kaikki näiden Ehtojen vaatimuksista luopuminen tai suostumus poikkeamiseen tulee voimaan vain, jos se on kirjallinen ja olemme allekirjoittaneet, ja vain siinä erityisessä tapauksessa ja siihen erityiseen tarkoitukseen, jota varten se on annettu. Epäonnistuksemme käyttämisessämme tai viivästymisessämme, mikään näiden ehtojen mukainen oikeutemme ei tarkoita luopumista oikeuksistamme.",
        "furtherAssurances": "Kumpikin osapuoli sitoutuu allekirjoittamaan ja toimittamaan kaikki asiakirjat ja ryhtymään kaikkiin tarvittaviin toimenpiteisiin (tai aiheuttamaan näiden toimien suorittamisen), jotka ovat tarpeen näiden Ehtojen mukaisten velvoitteidensa täyttämiseksi.",
        "notices": "Kaikki ilmoitukset Menuonlinelle tulee tehdä kirjallisesti ja lähettää meille sähköpostitse. Meille osoitetut ilmoitukset tulee lähettää asiakaspalveluedustajillemme osoitteeseen support@menuonline.com. Ilmoitukset sinulle voidaan lähettää osoitteeseen tai sähköpostiosoitteeseen, jonka olet antanut osana rekisteröitymistäsi. Lisäksi voimme lähettää tämän sivuston kautta ilmoituksia tai viestejä ilmoittaaksemme sinulle tämän sivuston muutoksista tai muista tärkeistä asioista, ja nämä lähetykset muodostavat ilmoituksen sinulle, kun julkaisemme ne.",
        "entireAgreement": "Nämä ehdot ja tietosuojaselosteemme muodostavat koko sopimuksen sinun ja meidän välillämme liittyen sivuston ja palvelun käyttöön, ja ne korvaavat kaikki aiemmat sopimukset, esitykset ja yhteisymmärrykset Menuonlinen ja sinun välillä tästä aiheesta. Jos tietosuojaselosteen ja näiden ehtojen välillä on ristiriita, nämä ehdot ovat voimassa.",
        "language": "Osapuolet ovat nimenomaisesti pyytäneet ja vaatineet, että nämä ehdot, tietosuojakäytäntömme ja kaikki asiaan liittyvät asiakirjat laaditaan englanniksi. Les partys conviennent et exigent expressément que ce Contrat et tous les documents qui s'y rapportent soient rédigés en anglais. Hyväksyt, että näiden ehtojen ja tietosuojakäytännön englanninkielinen versio on ensisijainen, jos jokin käännetty versio on ristiriidassa.",
        "survival": "Kaikki näiden Ehtojen määräykset, jotka niiden luonteen tai tuonnin vuoksi on tarkoitettu kestämään näiden Ehtojen voimassaolon päättymisen tai päättymisen, pysyvät voimassa, mukaan lukien rajoituksetta kohdat 17-19."
    },
    "collect_additional_info": "Haluatko kerätä lisätietoja?",
    "additionalInfoChoice": "Valitse lisätiedot, joita haluat kerätä.",
    "additional_info_yes": "Kyllä",
    "additional_info_noSkip": "Ei, Skip",
    "additional_info_label": "Lisätietoja",
    "confirm_business_location": "Vahvistataan ensin yrityksesi sijainti",
    "custom_get_more_reviews_form": "Mukautettu Hanki lisää arvosteluja -lomake",
    "set_up_custom_form": "Luo mukautettu Hanki lisää arvosteluja ryhmäkohtaisesti",
    "reputation_name_label": "Hanki lisää arvosteluja Nimi",
    "reputation_name_placeholder": "Anna maineen nimi",
    "group_label": "ryhmä",
    "group_desc_title": "Ryhmän kuvauksen otsikko",
    "group_desc": "Ryhmän kuvaus",
    "select_group_placeholder": "Valitse Ryhmä",
    "required_group_name": "Ryhmän nimi vaaditaan",
    "before_asking_review": "Ennen kuin pyydämme asiakkaita arvioimaan, kysytään heiltä 1–4 kysymystä.",
    "feel_free_edit": "Muokkaa vapaasti tarpeen mukaan.",
    "incentive_settings": "Kannustinasetukset",
    "incentivize_customer": "Haluatko innostaa asiakastasi antamaan arvostelun?",
    "choose_incentive": "Valitse kannustin, johon haluat viitata.",
    "scan_qr_feedback": "Skannaa QR-koodi ja anna meille arvokasta palautetta kokemuksistasi.",
    "qr_code": "QR-koodi",
    "scan_me": "SKANNAA MINUA!",
    "or_title": "TAI",
    "send_otp_title": "Lähetä OTP",
    "sign_in_title": "Kirjaudu sisään",
    "email_addresss_title": "Sähköpostiosoite",
    "reset_password_title": "Palauta salasana",
    "remember_password_title": "Muistatko salasanan?",
    "digit_code_title": "Syötä 4-numeroinen koodi, joka lähetettiin ",
    "not_received_title": "En saanut vahvistuskoodia!",
    "resend_title": "Lähetä uudelleen",
    "sign_up_form": "Luo tilisi",
    "sign_up_sub_title": "Kirjaudu sisään nyt saadaksesi viimeisimmät tiedot",
    "already_registered_title": "Onko sinulla jo Menuonline-tili?",
    "reserved_title": "Tekijänoikeudet ©2023 Menuonline. Kaikki oikeudet pidätetään.",
    "terms_title": "Palveluehdot",
    "policy_title": "Tietosuojakäytäntö",
    "sign_up_title": "Rekisteröidy",
    "terms_conditions_title": "Ehdot",
    "business_type_title": "Liiketoiminnan tyyppi",
    "business_type_sub_title": "Ole hyvä ja valitse yksi yritystyypeistä, joka kuvaa yritystäsi paremmin!",
    "salon_title": "Salonki",
    "restaurant_title": "Ravintola",
    "pizza_store_title": "Pizzakauppa",
    "food_truck_title": "Ruoka-auto",
    "check_domain_title": "Tarkista Domain Name",
    "start_exploring_title": "Aloita tutkiminen",
    "sign_in_form": "Kirjaudu tilillesi",
    "sign_in_sub_title": "Kirjaudu sisään nyt saadaksesi viimeisimmät tiedot",
    "remember_title": "Muista minut",
    "otp_title": "OTP",
    "password_title": "Salasana",
    "forgot_title": "Unohtuiko salasana?",
    "not_registered_title": "Eikö sinulla ole tiliä?",
    "create_account_title": "Rekisteröidy nyt",
    "create_form_title": "Luo lomake",
    "first_name_title": "Etunimi",
    "last_name_title": "Sukunimi",
    "email_title": "Sähköposti",
    "mobile_number_title": "Matkapuhelinnumero",
    "birthday_title": "Syntymäpäivä",
    "gender_title": "Sukupuoli",
    "address_title": "Osoite",
    "short_answer_title": "Lyhyt vastaus",
    "long_answer_title": "Pitkä vastaus",
    "single_answer_title": "Yksi vastaus",
    "single_checkbox_title": "Yksi valintaruutu",
    "multiple_choice_title": "Monivalinta",
    "drop_down_title": "Pudotusvalikko",
    "yes_no_title": "Kyllä tai ei",
    "description_text_title": "Kuvausteksti",
    "file_upload": "Tiedoston lataus",
    "reset_password_form": "Palauta salasanasi",
    "reset_password_sub_title": "Nollaa salasanasi nopeasti ja pääset käyttämään tiliäsi.",
    "required_full_name_title": "Koko nimi vaaditaan",
    "required_first_name_title": "Etunimi vaaditaan",
    "valid_first_name_title": "Anna kelvollinen etunimi",
    "valid_full_name_title": "Anna kelvollinen koko nimi",
    "required_block_time": "Block Time vaaditaan",
    "required_last_name_title": "Sukunimi vaaditaan",
    "valid_last_name_title": "Anna kelvollinen sukunimi",
    "valid_mobile_number_title": "Anna kelvollinen matkapuhelinnumero",
    "required_mobile_number_title": "Matkapuhelinnumero vaaditaan",
    "required_already_mobile_number_title": "Matkapuhelinnumero vaaditaan jo",
    "valid_otp_title": "Anna kelvollinen otp",
    "valid_email_title": "Anna kelvollinen sähköpostiosoite",
    "required_email_title": "Sähköposti vaaditaan",
    "required_description_title": "Kuvaus vaaditaan",
    "required_description_500_characters": "Kirjoita vähintään 500 merkkiä",
    "required_description_600_characters": "Anna enintään 600 merkkiä",
    "domain_title": "Verkkotunnuksen nimi",
    "required_password_title": "Salasana vaaditaan",
    "required_password_regex_title": "Sisältää vähintään 8 merkkiä, vähintään yhden ison kirjaimen, yhden pienen kirjaimen, yhden numeron ja yhden erikoismerkin",
    "required_terms_title": "Hyväksy ehdot",
    "required_note_title": "Huomautus vaaditaan",
    "required_birthday_title": "Syntymäpäivä vaaditaan",
    "no_access_title": "Sinulla ei ole käyttöoikeutta juuri nyt. Ota yhteyttä järjestelmänvalvojaasi",
    "menu_online_open_txt": "Avata",
    "loyalty_program_description": "😊 Löydä ihanteellinen kanta-asiakasohjelmasi ja räätälöi se brändiäsi vastaavaksi!",
    "lp_list_title": "Luettelo kanta-asiakaskorteista",
    "lp_n_title": "Kanta-asiakaskortin nimi",
    "enter_details_title": "Anna tiedot",
    "stamp_allowed_title": "Leima sallittu",
    "stamp_items_title": "Leimakohteet",
    "lpn_req_title": "Kanta-asiakaskortin nimi vaaditaan",
    "valid_lpn_title": "Anna kelvollisen kanta-asiakaskortin nimi",
    "brand_color_error": "Brändin värin on oltava täsmälleen 7 merkkiä pitkä",
    "select_dark_brand_color_title": "Valitse kelvollinen merkkiväri",
    "your_brand_color_title": "Brändisi väri",
    "upload_logo_title": "Lataa logo",
    "locations_title": "Sijainnit",
    "add_lp_title": "Luo ohjelma",
    "location_error": "Valitse sijainnit",
    "pvs_title": "Kuinka monta postimerkkiä asiakas voi ansaita vierailua kohden?",
    "max_apd_title": "Kuinka monta postimerkkiä asiakas voi ansaita enintään päivässä?",
    "logo_error": "Logo vaaditaan",
    "expiry_title": "Aseta palkintojen vanheneminen",
    "add_more_rewards_title": "+ Lisää palkintoja",
    "prev_step_1_title": "Näytä QR ansaitaksesi pisteitä ja nauttiaksesi palkinnoistasi.",
    "delete_lp_title": "Tämä kanta-asiakaskortti poistetaan pysyvästi.",
    "delete_user_record_title": "Tämä käyttäjätietue poistetaan pysyvästi.",
    "redeem_title": "Lunastaa",
    "lp_button_title": "Liity Loyalty Punch Cardiin",
    "lp_download_button_title": "Lataa kortti",
    "step_0_title": "Liity nyt, herkulliset palkinnot odottavat kaltaisiasi uskollisia ruokailijoita.",
    "step_1_title": "Olet liittynyt",
    "congrats_step_title": "Nyt olet oikeutettu ilmaiseen pizzaan",
    "reward_title": "Saat palkintoja jokaisesta vierailusta",
    "visit_title": "Vieraile ja avaa lukitus",
    "rewards_title": "Palkinnot",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, Yhdysvallat",
    "loyalty_qr_default_text": "Skannaa QR-koodi, liity kanta-asiakaskorttiimme ja nauti palkinnoista",
    "lp_earn_stamp_txt": "Asiakkaat ansaitsevat postimerkkejä jokaisesta vierailusta.",
    "lp_get_rewards_txt": "Saat palkintoja joka kerta, kun vierailet",
    "lp_1_time_visit_txt": "Saat yhden käynnin",
    "lp_uppercase_stamp_text": "Leima",
    "lp_lowercase_stamp_text": "leima",
    "lp_txt": "Uskollisuus",
    "lp_per_visit_stamp_validation_msg": "Vierailukohtaisen leiman tulee olla pienempi kuin suurin sallittu leima päivässä",
    "lp_per_max_stamp_validation_msg": "Suurin sallittu leima päivässä on suurempi kuin käyntileima",
    "lp_valid_value_msg": "Anna kelvollinen arvo",
    "lp_required_field_msg": "Tämä kenttä on pakollinen",
    "lp_stamp_required_msg": "Leima vaaditaan",
    "lp_valid_stamp_msg": "Anna kelvollinen leiman arvo",
    "lp_reward_name_required_msg": "Palkinnon nimi vaaditaan",
    "lp_unique_stamp_msg": "Leiman arvon tulee olla yksilöllinen",
    "lp_benefits": "Kanta-asiakasohjelma lisää toistuvia käyntejä ja tuottoa samalla, kun se tarjoaa asiakkaille oivalluksia henkilökohtaiseen markkinointiin",
    "lp_how_customer_txt": "Näin asiakkaasi toimivat",
    "lp_punch_card_txt": "kanta-asiakas reikäkortti",
    "lp_look_txt": "tulee katsomaan! 😍",
    "lp_desc": "Asiakkaat ansaitsevat postimerkkejä jokaisesta vierailusta, ja kun he ovat keränneet tarpeeksi, he voivat lunastaa erikoispalkintoja.",
    "lp_edit": "Muokata",
    "lp_looks_good_txt": "Hyvältä näyttää",
    "lp_customer_view_instruction": "Tämän asiakkaasi näkee. Lataa logosi ja aseta väriteema",
    "lp_customer_earn_stamps": "Anna asiakkaiden ansaita postimerkkejä jokaisella vierailulla ja hallita palkintojaan",
    "lp_stamp_rewards_setup": "Aseta postimerkkien ansaitsemispalkkiot, anna asiakkaille mahdollisuus saada palkintoja postimerkeillä",
    "lp_loyalty_program_setup": "Anna kanta-asiakasohjelmasi nimi ja määritä sijaintisi tämän ohjelman käyttöä varten",
    "lp_prev_step1": "Näytä QR ansaitaksesi pisteitä ja nauttiaksesi palkinnoistasi.",
    "lp_visit_1_time_txt": "Vieraile 1 kerran ja hanki",
    "lp_get_free_txt": "Get Free",
    "lp_visit_allow_txt": "Visit & Unlock",
    "lp_rewards": "Palkinnot",
    "repsT": "Hanki lisää arvosteluja",
    "offSup": "Toimistotarvikkeet",
    "repsNxtV": "Seuraavalla vierailullasi",
    "addRepT": "Lisää Hanki lisää arvosteluja",
    "repEntrCpnTxt": "Syötä kuponkiteksti",
    "repEntrCpnTxtBtn": "Syötä kuponkipainikkeen teksti",
    "repMngNxtV": "seuraavalla vierailullasi?",
    "repMngWldL": "Haluaisitko saada",
    "discount": "Alennus",
    "repDisTyp": "Alennustyyppi",
    "repNameT": "Nimeä maineesi",
    "crtRepT": "Luo maine",
    "feedBackT": "Palaute",
    "confLocT": "Vahvista sijainti",
    "businLocT": "Liiketoiminnan sijainti",
    "addInfoT": "Lisätietoja",
    "repNReqT": "Hanki lisää arvosteluja nimi vaaditaan",
    "validRepNT": "Anna kelvollinen maineen nimi",
    "groupT": "ryhmä",
    "groupLDescTitleT": "Työnnä yhteystiedot automaattisesti ryhmään",
    "groupLDescT": "Kaikki lomakkeen täyttäneet asiakkaat lisätään valittuun ryhmään.",
    "que2": "Oliko ruokasi hyvää?",
    "que3": "Oliko palvelumme ystävällistä?",
    "que4": "Oliko palvelumme nopeaa?",
    "addAdditionalT": "Haluatko lisätä lisätietoja?",
    "phNoMandT": "Puhelinnumero on pakollinen!",
    "qrGenT": "QR Luotu",
    "repFDT": "Palautteesi auttaa tiimiämme palvelemaan sinua paremmin!",
    "letdiT": "Tehdään se",
    "tak15secT": "Kestää 15 sekuntia",
    "defQue": "Haluatko saada aika ajoin mahtavia tarjouksia ja kampanjoita?",
    "defNQue": "Haluaisitko jonkun vastaavan sinulle?",
    "rateOnGoogleDesc": "Voisitko ystävällisesti arvioida meidät Googlessa?",
    "mayBeNT": "Ehkä ensi kerralla",
    "yesSureT": "Kyllä, varmasti",
    "valid_name": "Anna kelvollinen nimi",
    "nameReq": "Nimi vaaditaan",
    "backToMainT": "Takaisin pääsivulle",
    "attachCopT": "Haluatko innostaa asiakastasi?",
    "thankYouT": "Kiitos",
    "lftyT": "Odotamme innolla seuraavaa vierailuasi!",
    "repPreT": "Maineen esikatselu",
    "offer_exclusive_discounts": "Tarjoa ainutlaatuisia alennuksia ja tarjouksia houkutellaksesi asiakkaitasi.",
    "couponT": "Kuponki",
    "locStepDesc": "Vahvista yrityksesi sijainti",
    "createRepStepDesc": "Luo mukautettu mainelomake ryhmittäin",
    "feedbackStepDesc": "Asiakaspalautteen kysymyssarja",
    "addInfoStepDesc": "Asiakaskentät ja kannustinasetukset",
    "FedbkkWDT": "Palaute tietojen kanssa",
    "repAnlytT": "Maineanalyysi",
    "todayT": "Tänään",
    "rep_last_7_days_t": "Viimeiset 7 päivää",
    "last30DaysT": "Viimeiset 30 päivää",
    "lastMT": "Viimeinen kuukausi",
    "custRangeT": "Mukautettu valikoima",
    "queWiseAnltT": "Kysy viisasta analytiikkaa",
    "atL1QueErrT": "Valitse vähintään yksi kysymys",
    "deleteRepTitle": "Tämä maine poistetaan pysyvästi.",
    "incvCustT": "Kyllä, kannusta asiakastani",
    "collAddInfoT": "Kyllä, haluaisin kerätä lisätietoja",
    "totRewT": "Kokonaisarvostelu",
    "totPosRewT": "Täysin positiivinen arvostelu",
    "negFeedT": "Negatiivinen palaute",
    "posFeedT": "Positiivista palautetta",
    "fineT": "Hieno printti",
    "enterFineT": "Kirjoita hieno teksti",
    "setThemeT": "Aseta teeman väri",
    "que1T": "Kysymys 1",
    "que2T": "Kysymys 2",
    "que3T": "Kysymys 3",
    "que4T": "Kysymys 4",
    "entMailT": "Kirjoita sähköpostiosoitteesi",
    "reputationQRDefTxt": "Skannaa QR-koodi ja anna meille arvokasta palautetta kokemuksistasi.",
    "selUser": "Valitse Käyttäjä",
    "userT": "Käyttäjä",
    "subUReq": "Valitse käyttäjät",
    "updateLoc": "Päivitä sijainti",
    "leadGenT": "Lead Generation",
    "displayT": "Näyttö",
    "ratingT": "Luokitus",
    "rep_dashboard_negative_feedback": "Negatiivinen palaute 0 %",
    "rep_dashboard_position_feedback": "Positiivista palautetta 0 %",
    "rep_dashboard_total_Feedback_txt": "Yhteensä palautetta",
    "rep_dashboard_anony_Feedback": "Anonyymi palaute",
    "rep_dashboard_Feeedback_Data_txt": "Palaute tietojen kanssa",
    "rep_dashboard_review_txt": "Hanki lisää arvosteluja",
    "rep_dashboard_total_Review_txt": "Kokonaisarvostelu",
    "rep_dashboard_total_positive_Review_txt": "Täysin positiivinen arvostelu",
    "rep_dashboard_negative_feedback_txt": "Negatiivinen palaute",
    "rep_dashboard_connection_txt": "Yhteys",
    "rep_dashboard_question_wise_analytics_txt": "Kysy viisasta analytiikkaa",
    "rep_dashboard_date_label": "Valitse Päivämäärä",
    "rep_dashboard_custom_range_txt": "Mukautettu valikoima",
    "rep_tlt_min": "TASAVALTA",
    "emojis": "Emojit",
    "media_text": "Lisää media",
    "custom_field_text": "Lisää mukautettuja kenttiä",
    "shortlink_text": "Lisää lyhytlinkki",
    "use_template_text": "Käytä mallia",
    "banner_must_have": "Pakko saada",
    "res_seamless": "Saumaton",
    "res_table_reservation": "Pöytävaraus",
    "res_system": "Järjestelmä",
    "res_text": "Varaukset",
    "md_easily": "Helposti",
    "md_manage_customers": "Hallitse asiakkaita",
    "md_visits_interactions": "vierailut ja vuorovaikutukset",
    "md_desc": "Tee ruokailusta asiakkaillesi helpompaa reaaliaikaisilla pöytävarauksilla. Hallitse varauksia vaivattomasti, lyhennä odotusaikoja ja varmista sujuva käyttökokemus sekä henkilökunnalle että vieraille. Pidä ravintolasi täynnä ja säilytä huippuluokan palvelu ja tyytyväisyys.",
    "engage_convert": "Ota käyttöön ja muunna tekstiviestit",
    "coupon_campaign": "ja kuponkikampanja",
    "sms_read_rate": "Tekstiviestit luetaan 98 % ajasta",
    "real_time_promo": "Toimita tarjouksia reaaliajassa välitöntä toimintaa varten",
    "affordable_reach": "Edullinen tapa tavoittaa asiakkaat vakaalla ROI:lla",
    "stay_in_touch": "Pysy aina yhteydessä asiakkaasi",
    "bc_engage_customers": "Aktivoi asiakkaat Muunna tekstiviestit",
    "bc_coupon_campaign": "ja kuponkikampanja",
    "bc_sms_read_rate": "Tekstiviestit luetaan 98 % ajasta",
    "bc_real_time_promo": "Toimita tarjouksia reaaliajassa välitöntä toimintaa varten",
    "bc_affordable_reach": "Edullinen tapa tavoittaa asiakkaat vakaalla ROI:lla",
    "bc_stay_in_touch": "Pysy aina yhteydessä asiakkaasi",
    "ar_automate_engagement": "Automatisoi asiakkaan sitoutuminen",
    "ar_with": "kanssa",
    "ar_with_auto_responders": "Auto-vastaajat",
    "ar_personalized_messages": "Lähetä automaattisesti henkilökohtaisia ​​viestejä syntymäpäivinä, toivota uudet asiakkaat tervetulleiksi ja ota yhteyttä entisiin asiakkaisiin. Säästä aikaa ja pidä jokainen vuorovaikutus mielekkäänä ja oikea-aikaisena.",
    "sq_custom_forms_tools": "Mukautetut lomakkeet ja työkalut - Yksinkertaista",
    "sq_custom_forms_ci": "Asiakasvuorovaikutukset",
    "sq_smart_qr_desc": "Luo mukautettuja lomakkeita, kerää sähköisiä allekirjoituksia ja hyödynnä älykkäitä QR-koodeja tehostaaksesi liiketoimintaprosessejasi. Räätälöi jokainen työkalu vastaamaan asiakkaidesi tarpeita ja parantamaan käyttökokemusta.",
    "gnc_get_new_cus": "Hanki uusia asiakkaita",
    "gnc_get_customers": "sosiaalisesta mediasta – nopeaa ja helppoa!",
    "gnc_social_media_power": "Käytä sosiaalisten alustojen voimaa houkutellaksesi uusia asiakkaita ja ohjataksesi myyntiä suoraan yritykseesi. Ohjaa kolmannen osapuolen toimitustilaukset suoraan kauppaasi tai verkkotilausalustaan ​​maksimaalisen hallinnan ja tuoton saavuttamiseksi.",
    "wifi_connect_guests": "Yhdistä vieraita kanssa",
    "wifi_smart": "Älykäs Wi-Fi",
    "wifi_connect_grow": "& Kasvata näkemyksiäsi",
    "wifi_smart_wifi_desc": "Tarjoa asiakkaillesi vaivaton Wi-Fi-yhteys brändätyn aloitusnäytön kautta ja kerää samalla arvokasta asiakasdataa markkinointisi ja sitoutumisesi parantamiseksi.",
    "wifi_create_splash_page": "Luo vieras-Wi-Fi-kuvaussivu",
    "instant_menu_create": "Luo omasi",
    "instant_menu_text": "Pikavalikko",
    "instant_menu_wm": "minuuteissa",
    "instant_menu_desc": "Parantaa asiakaskokemuksia mahdollistamalla reaaliaikaiset päivitykset, helpon mukauttamisen ja parannetun sitoutumisen, mikä helpottaa tarjousten esittelemistä ja sisällön tuoreen pitämistä.",
    "instant_menu_create_digital_menu": "Luo digitaalinen valikko",
    "loyalty_program_rewards": "Ansaitse palkintoja",
    "loyalty_program_on_visits": "jokaisella vierailulla",
    "loyalty_program_create": "Luo kanta-asiakasohjelma",
    "reputation_create": "Luo maine",
    "reputation_boost": "Tehosta omaa",
    "reputation_text": "Maine",
    "reputation_reviews": "Lisää arvosteluja!",
    "reputation_desc": "Kerää arvokasta palautetta asiakkailtasi parantaaksesi palveluasi ja mainettasi. Jaa kokemuksesi ja auta meitä kasvamaan – arvostelullasi on merkitystä!",
    "dashboard": "Kojelauta",
    "blast_campaign": "Räjähdyskampanja",
    "blast_campaigns": "Räjähdyskampanjat",
    "blast_campaign_sub_title": "Aloita räjähdyskampanjoita tavoittaaksesi kaikki asiakkaasi muutamalla klikkauksella",
    "auto_responders": "Automaattiset vastaajat",
    "auto_responder": "Auto Responder",
    "auto_responder_sub_title": "Helppokäyttöiset tapahtumapohjaiset tarjoukset asiakkaillesi",
    "smart_qr_form": "Älykäs QR \/ lomake",
    "get_new_customer": "Hanki uusi asiakas",
    "marketing": "Markkinointi",
    "clients": "Asiakkaat",
    "group_data": "Ryhmien tiedot",
    "clients_data": "Asiakastiedot",
    "account": "Tili",
    "user_acc_info": "Käyttäjätilin tiedot",
    "current_plan": "Nykyinen suunnitelma",
    "payments": "Maksut",
    "location": "Sijainti",
    "location_list": "Sijaintiluettelo",
    "users": "Käyttäjät",
    "users_sub_title": "Luettelo kaikista käyttäjistä ja heidän tiedoistaan",
    "online_profile": "Online-profiili",
    "change_password": "Vaihda salasana",
    "menu": "Valikko",
    "reseller_dashboard": "Jälleenmyyjän kojelauta",
    "companies": "Yritykset",
    "plans_bought": "Suunnitelmat ostettu",
    "agreement_t_c": "Sopimuksen T&C",
    "wifi_dashboard": "Wifi-kojelauta",
    "hot_spot": "Hotspot",
    "splash_pages": "Splash Pages",
    "get_more_reviews": "Hanki lisää arvosteluja",
    "office_supplies": "Toimistotarvikkeet",
    "reservation": "Varaus",
    "floor_table": "Lattia & Pöytä",
    "guest_smart_wifi": "Vieras Smart WiFi",
    "digital_signage": "Digital Signage",
    "schedule_down": "Aikataulu",
    "business_settings": "Yritysasetukset",
    "sub_user_login": "Alikäyttäjän kirjautuminen",
    "help_center": "Ohjekeskus",
    "switch_to_hub": "Vaihda Hubiin",
    "meeting_records": "Kokouspöytäkirja",
    "logout": "Kirjaudu ulos",
    "no_access_text": "Sinulla ei ole käyttöoikeutta juuri nyt. ota yhteyttä järjestelmänvalvojaan",
    "menu_item": "VALIKKOKOHDAT",
    "menu_capital": "VALIKKO",
    "good_day": "Hyvää päivää",
    "no_permission_text": "Sinulla ei ole lupaa suorittaa tätä toimintoa!!",
    "enter_valid_pin": "Anna kelvollinen PIN-koodi",
    "add_reservation": "Lisää varaus",
    "edit_reservation": "Muokkaa varausta",
    "per_req": "Henkilömäärä vaaditaan",
    "req_block_time": "Block Time vaaditaan",
    "pending_status": "Odottaa",
    "booked_status": "Varattu",
    "completed_status": "Valmis",
    "cancelled_status": "Peruutettu",
    "reservation_slot": "Varauspaikka",
    "block_time": "Estä aika",
    "slot_time": "Varausaika:  ",
    "template_name_req": "Mallin nimi vaaditaan",
    "template_type_req": "Mallin tyyppi vaaditaan",
    "sms_template_req": "SMS-malli vaaditaan",
    "group_req": "Ryhmä vaaditaan",
    "valid_date_time": "Anna kelvollinen päivämäärä ja aika",
    "valid_group_name": "Anna kelvollinen ryhmän nimi",
    "reservation_mark_completed": "Tämä varaus merkitään suoritetuksi.",
    "customer_name": "Asiakkaan nimi",
    "date_time": "Päivämäärä Aika",
    "table_no": "Taulukko nro",
    "floor": "Kerros",
    "customer_name_placeholder": "asiakkaan_nimi",
    "date_time_placeholder": "päivämäärä_aika",
    "table_no_placeholder": "taulukon_nro",
    "floor_placeholder": "kerros",
    "select_floor": "Valitse Lattia",
    "select_table": "Valitse Taulukko",
    "customer_name_macro": "%% asiakkaan_nimi %%",
    "date_time_macro": "%% päivämäärä_aika %%",
    "table_no_macro": "%% table_no %%",
    "floor_macro": "%% kerros %%",
    "template_name": "Mallin nimi",
    "login_with_4_digit_pin": "Kirjaudu 4-numeroisella PIN-koodillasi",
    "login_now_to_access_latest_insights": "Kirjaudu nyt, niin pääset uusimpiin merkintöihin",
    "mkt_BS_Title": "Blast SMS",
    "step_1": "Mukauta viestiä",
    "step_2": "Valitse Yleisö",
    "step_3": "Aikataulu Viesti",
    "step_4": "Lähetä viesti",
    "step_5": "Hallitse viestiä",
    "bs_tab_title": "Räjähdyskampanjan yhteenveto",
    "default_message": "Hei %% etunimi %%, Erikoisalennus brunssista! Illasta kanssamme tänään ja saat eksklusiivisen 10 % alennuksen laskustasi!",
    "bs_Title": "Valitse kampanjatyyppi",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Tavoita asiakkaita teksti- ja multimediaviesteillä.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Ota yhteyttä asiakkaisiisi WhatsAppilla 💬",
    "send_discount_offers": "Lähetä Alennustarjoukset tekstiviestillä 📲",
    "send_coupons_via_sms": "Lähetä kuponkeja tekstiviestillä asiakkaillesi 🎟️",
    "env_Cap": "Tarjoa ainutlaatuisia alennuksia ja tarjouksia houkutellaksesi asiakkaitasi",
    "cmt_Caption": "Tavoita asiakkaita teksti- ja multimediaviesteillä",
    "wp_caption": "Ota yhteyttä asiakkaisiin WhatsAppilla, jotta viestintä on saumatonta ja suoraa",
    "msg_Prev_Title": "Viestin esikatselu",
    "cou_Prev_Title": "Kupongin esikatselu",
    "blast_SMS": "SMS\/MMS-kampanja",
    "bs_P_Msg": "Jonossa",
    "bs_S_Msg_C": "Epäonnistui",
    "bs_F_Msg_Count": "Toimitettu",
    "previous": "Edellinen",
    "next_ST": "Seuraavaksi",
    "next_S": "Seuraava vaihe",
    "req_Temp_Name_Title": "Mallin nimi vaaditaan",
    "req_Template_Tx_Title": "Mallin teksti on pakollinen",
    "add_Temp_Title": "Lisää malli",
    "link_Desc": "Lisää valitsemasi linkki, jotta asiakkaat voivat tehdä varauksen Facebookin, Googlen tai oman verkkosivustosi kautta.",
    "audience_Title": "Yleisö",
    "client_G1_Title": "Uusia asiakkaita",
    "client_G2_Title": "Viimeaikaiset asiakkaat",
    "client_G3_Title": "Uskolliset asiakkaat",
    "client_G4_Title": "Vanhentuneet asiakkaat",
    "client_G5_Title": "Asiakkaat liittymispäivän mukaan",
    "on_Which_Day": "Minä päivänä",
    "on_Day": "Päivänä",
    "immediate_Desc": "Viestisi lähetetään välittömästi, aloita napsauttamalla Seuraava.",
    "never_Desc": "Tämä kampanja on ajoitettu jatkuvaksi ikuisesti, kunnes lopetat sen manuaalisesti.",
    "end_Desc": "Kampanja päättyy valitsemanasi päivänä.",
    "success_Message": "Kampanja tallennettu onnistuneesti!",
    "send_Success_Message": "Kampanja lähetetty onnistuneesti!",
    "msg_Name_Field_Title": "Kampanjan otsikko",
    "valid_campaign_title": "Anna kelvollinen kampanjan otsikko",
    "msg_Type_Title": "Viestin tyyppi",
    "sms_Desc": "1 luotto per tekstiviesti",
    "mms_Desc": "3 krediittiä per MMS",
    "client_GTO_1": "Päivä",
    "client_GTO_2": "Tuntia",
    "minutes": "Minuutit",
    "client_GTO_4": "Viikko",
    "time_Option_1": "Päivä",
    "time_Option_2": "Tuntia",
    "c_Grp_Day_1": "90 päivää",
    "c_Grp_Day_2": "60 päivää",
    "c_Grp_Day_3": "30 päivää",
    "first": "Ensimmäinen",
    "second_T": "Toinen",
    "third_T": "Kolmas",
    "fourth_T": "Neljäs",
    "fifth_T": "Viides",
    "delete_BS_T": "Tämä kampanja poistetaan pysyvästi.",
    "delete_MBS_T": "Nämä kampanjat poistetaan pysyvästi.",
    "cFT_1": "Puhelin",
    "cFT_2": "Ota yhteyttä etunimi",
    "cFT_3": "Ota yhteyttä sukunimi",
    "cFT_4": "Yhteydenotto Sähköposti",
    "cFT_5": "Huom",
    "cFT_6": "Syntymäpäivä",
    "cFT_7": "Yrityksen nimi",
    "cF_Meta_Tag_1": "%% puhelin %%",
    "cF_Meta_Tag_2": "%% etunimi %%",
    "cF_Meta_Tag_3": "%% sukunimi %%",
    "cF_Meta_Tag_4": "%% sähköposti %%",
    "cF_Meta_Tag_5": "%% muistiinpano %%",
    "cF_Meta_Tag_6": "%% syntymäpäivä %%",
    "cF_Meta_Tag_7": "%% yrityksen_nimi %%",
    "cF_Name_1": "puhelin",
    "cF_Name_2": "etunimi",
    "cF_Name_3": "sukunimi",
    "cF_Name_4": "sähköposti",
    "cF_Name_5": "huomautus",
    "cF_Name_6": "syntymäpäivä",
    "cF_Name_7": "yrityksen_nimi",
    "all_clients": "Kaikki Asiakkaat",
    "aud_Type_2": "Asiakasryhmät",
    "aud_Type_3": "Asiakasryhmät",
    "aud_Type_T1": "Kaikki asiakkaat",
    "aud_Type_T2": "Asiakasryhmät",
    "aud_Type_T3": "Asiakasryhmät",
    "new_clients": "Uusia asiakkaita",
    "client_G2": "Viimeaikaiset asiakkaat",
    "client_G3": "Uskolliset asiakkaat",
    "client_G4": "Vanhentuneet asiakkaat",
    "client_G5": "Asiakkaat liittymispäivän mukaan",
    "never_text": "Ei koskaan",
    "ending_on": "Päättyy",
    "send_T2": "Aikataulutettu",
    "send_Type_3": "Toistuva",
    "daily": "Päivittäin",
    "weekly_T": "Viikoittain",
    "monthly_T": "Kuukausittainen",
    "yearly_T": "Vuosittain",
    "each": "Jokainen",
    "on_the": "On The",
    "monday": "maanantai",
    "exclusive_offer": "Ainutlaatuinen tarjous sinulle",
    "redeem_now": "Lunasta nyt",
    "redeem_with_cashier": "Lunasta kassalla",
    "lg_Day_2": "tiistai",
    "lg_Day_3": "keskiviikko",
    "lg_Day_4": "torstai",
    "lg_Day_5": "perjantai",
    "lg_Day_6": "lauantai",
    "lg_Day_7": "sunnuntai",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Kaikki",
    "cA_Edit_T2": "Mukana",
    "cA_Edit_T3": "Poissuljettu",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Viesti",
    "which_Day": "Mitkä päivät",
    "end_Date_Tx": "Päättymispäivämäärä",
    "sDate_Err": "Aloituspäivän on oltava suurempi tai yhtä suuri kuin tämä päivä",
    "eDate_Err": "Päättymispäivän on oltava aloituspäivää myöhäisempi",
    "start_Date_Req": "Aloituspäivä on pakollinen",
    "end_Date_Req": "Päättymispäivä on pakollinen",
    "time_req": "Aikaa tarvitaan",
    "client_GT1": "Asiakkaat tilasivat viimeksi",
    "client_GT2": "Asiakkaat, joilla on viimeinen yhteys",
    "client_GT3": "Asiakkaat, joilla on vähintään",
    "client_GT4": "viimeisen kanssa",
    "client_GT5": "joka ei palannut viimeksi",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Testata",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Media tai URL vaaditaan",
    "time_Title": "Aika",
    "start_date": "Aloituspäivämäärä",
    "end_date": "Päättymispäivämäärä",
    "auto_Send_T": "Lähetä automaattisesti asiakkaille",
    "add_Media": "Lisää media",
    "in_Title": "sisään",
    "c_Temp_T": "Kampanjamalli",
    "temp_NT": "Mallin nimi",
    "type_T": "Tyyppi",
    "select_template_type": "Valitse mallin tyyppi",
    "sel_Temp_T": "Valitse Mallin tyyppi",
    "sms_temp_t": "SMS malli",
    "temp_T": "Mallin teksti",
    "default_Msg": "Hei %% etunimi %%, Erikoisalennus brunssista! Illasta kanssamme tänään ja saat eksklusiivisen 10 % alennuksen laskustasi!",
    "refill_T": "Täytä uudelleen",
    "avl_Credit": "Saatavilla olevat krediitit",
    "req_Credit": "Vaaditut krediitit yhteensä",
    "rem_Credit": "Jäljellä oleva luotto",
    "refill_Credit": "Krediitit täytettävä",
    "clients_Req": "Valitse vähintään yksi asiakas",
    "subclient_Req": "Valitut asiakkaat eivät ole tilaajia.",
    "refill_Desc": "Ole hyvä ja täytä tarvittavat krediitit",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Aloituspäivämäärä on virheellinen",
    "ed_Invalid": "Päättymispäivä on virheellinen",
    "img_Url_Invalid": "Virheellinen kuvan URL-osoite",
    "time_Invalid": "Aika on virheellinen",
    "time_And_Date_Invalid": "Anna kelvollinen päivämäärä ja aika",
    "time_Invalid_Bfr": "Valitse aika, joka on vähintään 5 minuuttia myöhempi nykyisestä ajasta",
    "sod_Req": "Pakollinen lähetys päivän aikana",
    "add_Card": "Lisää uusi kortti",
    "sm_D_Rep_Title": "SMS\/MMS-toimitusraportti",
    "send_Now_T": "Lähetä nyt",
    "schd_Now_T": "Ajoita tekstiviestit",
    "test_SMS_T": "Lähetä testitekstiviesti",
    "save_AD_T": "Tallenna luonnoksena",
    "back_TE_T": "Takaisin Muokkaa",
    "reset_tex": "Nollaa",
    "update_tex": "Päivittää",
    "dum_msg1": "Näytä asiakkaan nimi käyttämällä mallissa %% asiakkaan_nimi %%",
    "dum_msg2": "Näytä varausaika käyttämällä mallissa %% date_time %%",
    "dum_msg3": "Jos haluat näyttää taulukon numeron, käytä %% table_no %% mallissa",
    "embedded_link": "Upotettu linkki",
    "img_title": "Kuva",
    "img_input_text": "Teksti:",
    "img_join_text": " liittyäksesi listallemme",
    "img_copy_text": "Kopioida",
    "img_not_found": "Luotua kuvaa ei löydy!!",
    "or_text": "TAI",
    "web_signup_image_content": "Lähettämällä tämän lomakkeen ja rekisteröitymällä tekstiviestillä hyväksyt markkinointitekstiviestien (kuten tarjouskoodeja ja muistutuksia) vastaanottamisen. Viesti- ja tiedonsiirtomaksuja saatetaan periä. Viestitiheys vaihtelee. Voit peruuttaa tilauksen milloin tahansa vastaamalla STOP",
    "img_download_success": "Kuvan lataus onnistui",
    "embedded_link_copied": "Upotetun linkin kopiointi onnistui",
    "media_url_required": "Media tai URL vaaditaan",
    "invalid_image_url": "Virheellinen kuvan URL-osoite",
    "invalid_file": "Tiedosto on virheellinen",
    "image_error_1mb": "Lisää kuva, joka on pienempi kuin 1 Mt",
    "image_error_2_5mb": "Lisää kuva, joka on pienempi kuin 2,5 Mt",
    "image_error_3mb": "Lisää kuva, joka on alle 3 Mt",
    "change_title": "Muuttaa",
    "drop_file_text": "Pudota tiedosto tähän tai lataa tiedosto napsauttamalla",
    "apply_text": "Käytä",
    "search_here": "Hae tästä",
    "update_status": "Päivitä tila",
    "reservation_deleted_permanently": "Tämä varaus poistetaan pysyvästi.",
    "table_text": "Taulukot",
    "add_table_button": "Lisää uusi taulukko",
    "add_table_text": "Lisää taulukko",
    "edit_table_text": "Muokkaa taulukkoa",
    "table_delete_text": "Tämä taulukko poistetaan pysyvästi.",
    "multiple_table_delete_text": "Nämä taulukot poistetaan pysyvästi.",
    "table_error_name": "Anna taulukon nimi",
    "table_error_invalid_name": "Anna kelvollinen taulukon nimi",
    "table_error_floor": "Ole hyvä ja valitse lattia",
    "table_error_select": "Ole hyvä ja valitse pöytä",
    "table_capacity_text": "Pöydän kapasiteetti",
    "capacity_text": "Kapasiteetti",
    "table_occasion_text": "Löydä pöytäsi kaikkiin tilaisuuksiin",
    "table_name_text": "Taulukon nimi",
    "table_capacity_error": "Pöytäkapasiteetti vaaditaan",
    "cancel_text": "Peruuttaa",
    "submit_text": "Lähetä",
    "select_valid_date": "Valitse kelvollinen päivämäärä",
    "select_valid_time": "Valitse kelvollinen aika",
    "valid_contact_number": "Anna kelvollinen yhteysnumero",
    "date_req": "Päivämäärä vaaditaan",
    "date_error_later_than_today": "Päivämäärän on oltava tämän päivän päivämäärää myöhempi tai yhtä suuri",
    "time_error_later_than_5_minutes": "Valitse aika, joka on vähintään 5 minuuttia myöhempi nykyisestä ajasta",
    "number_person_req": "Henkilömäärä vaaditaan",
    "contact_no_req": "Yhteysnumero vaaditaan",
    "contact_no": "Yhteysnumero",
    "select_time": "Valitse Aika",
    "for_how_many_person": "Kuinka monelle henkilölle?",
    "f_name": "Etunimi",
    "l_name": "Sukunimi",
    "business_name": "Yrityksen nimi",
    "business_no": "Yritysnumero",
    "no_for_sign_in": "Tätä numeroa käytetään kirjautumiseen",
    "business_email": "Yrityksen sähköposti",
    "notes_tex": "Huomautuksia",
    "floor_deleted_permanently": "Tämä kerros poistetaan pysyvästi.",
    "add_floor": "Lisää kerros",
    "edit_floor": "Muokkaa kerrosta",
    "name_text": "Nimi",
    "mobile_no": "Matkapuhelin nro",
    "person_text": "Henkilö",
    "date_and_time": "Päivämäärä ja aika",
    "actions_text": "Toiminnot",
    "extra_text": "ylimääräistä",
    "floor_name_req": "Kerroksen nimi vaaditaan",
    "floor_name": "Kerroksen nimi",
    "status_text": "Status",
    "category_status_req": "Luokan tila vaaditaan",
    "table_deleted_permanently": "Tämä taulukko poistetaan pysyvästi.",
    "tables_deleted_permanently": "Nämä taulukot poistetaan pysyvästi.",
    "back_to_home": "Takaisin Kotiin",
    "link_copied_text": "Linkki kopioitu leikepöydälle",
    "cust_dash_head_to_title": "to",
    "cust_dash_head_location_title": "Sijainti",
    "select_location_title": "Valitse Sijainti",
    "all_locations_label": "kaikki_paikat",
    "rep_customer_feedback_analytics": "😊 Seuraa asiakaspalautettasi reaaliaikaisen analytiikan avulla",
    "rep_customer_txt": "Asiakkaat",
    "rep_delete_title": "Tämä maine poistetaan pysyvästi.",
    "rep_qr_def_txt": "Skannaa QR-koodi ja anna meille arvokasta palautetta kokemuksistasi.",
    "delete_title": "Poistaa",
    "user_list_title": "Luettelo asiakkaista",
    "os_nfc_txt": "NFC-kortit",
    "os_sign_holder_txt": "Kyltinpitimen teline",
    "os_store_Sign_holder_txt": "Myymäläkylttitelineet",
    "StaT": "Tilastot",
    "AllgT": "Kaikki ryhmät",
    "FavT": "Suosikki",
    "MostActT": "Aktiivisin",
    "grT": "Ryhmän nimi",
    "keywT": "Avainsanat",
    "exSubT": "Viesti nykyiselle tilaajalle",
    "ArchT": "Arkistoitu",
    "gNotiSms": "Ilmoita minulle jokaisesta uudesta yhteydenotosta tekstiviestillä",
    "gNotiEmail": "Ilmoita minulle jokaisesta uudesta yhteydenotosta sähköpostitse",
    "reqGName": "Ryhmän nimi vaaditaan",
    "validGN": "Anna kelvollinen ryhmän nimi",
    "valid_phone_no": "Anna kelvollinen puhelinnumero",
    "phone_no_req": "Puhelinnumero vaaditaan",
    "required_message_text": "Viesti vaaditaan",
    "required_discount_text": "Alennus vaaditaan",
    "less_than_discount": "Alennussumman tulee olla alle 5000",
    "discount_percentage_invalid": "Alennusprosentin on oltava pienempi tai yhtä suuri kuin 100",
    "discount_type_req": "Alennustyyppi vaaditaan",
    "discount_text_req": "Kupongin teksti on pakollinen",
    "reqContactNo": "Yhteysnumero vaaditaan",
    "reqMsgNT": "Kampanjan nimi on pakollinen",
    "reqLinkT": "Linkki vaaditaan",
    "delMGT": "Nämä ryhmät poistetaan pysyvästi.",
    "defMemMsg": "Kirjoita viestisi tähän",
    "add_cust_to_grp_title": "Lisää asiakas ryhmään",
    "group_title": "ryhmät",
    "group_create_title": "Luo ryhmä",
    "group_name_txt": "Ryhmän nimi",
    "group_table_keyword_title": "avainsana",
    "actions_title": "Toiminnot",
    "clients_title": "Asiakkaat",
    "send_title": "Lähetä",
    "qr_title": "QR",
    "delete_group_txt": "Tämä ryhmä poistetaan pysyvästi.",
    "delete_groups_txt": "Nämä ryhmät poistetaan pysyvästi.",
    "delete_client_title": "Tämä asiakasohjelma poistetaan pysyvästi.",
    "delete_clients_title": "Nämä asiakkaat poistetaan pysyvästi.",
    "delete_multiple_title": "Useita Poista",
    "wel_sms_mms": "Lähetetäänkö tervetuloa SMS\/MMS?",
    "key_words_title": "Avainsanat",
    "srch_plch_txt": "Hae tästä",
    "req_location_id_title": "Sijainti on pakollinen",
    "create_text": "Luoda",
    "view_text": "Näytä",
    "immediately": "Heti",
    "business_name_req": "Yrityksen nimi vaaditaan",
    "business_no_req": "Yritysnumero vaaditaan",
    "valid_business_name": "Anna kelvollinen yrityksen nimi",
    "valid_business_no": "Anna kelvollinen yritysnumero",
    "address_req": "Osoite vaaditaan",
    "valid_address": "Valitse kelvollinen osoite",
    "time_zone_req": "Aikavyöhyke vaaditaan",
    "image_req": "Lisää kuva, joka on alle 2,5 megatavua",
    "valid_file": "Tiedosto on virheellinen.",
    "logo": "Logo",
    "time_zone": "Aikavyöhyke",
    "save": "Tallentaa",
    "account_type_req": "Tilityyppi vaaditaan",
    "gst_no_req": "GST-numero vaaditaan",
    "gst_no_valid": "Anna kelvollinen GST-numero",
    "set_up_payments": "Määritä maksut",
    "billing_details": "Anna laskutustietosi",
    "billing_details_desc": "Laskutustietosi näkyvät menuonlinen kuukausilaskussasi.",
    "account_type": "Tilin tyyppi",
    "select_account_type": "Valitse tilin tyyppi",
    "gst_no": "GST-numero",
    "transaction_details": "Tapahtuman tiedot",
    "payment_method": "Maksutapa",
    "billing_period": "Laskutuskausi",
    "paid_by": "Maksoi",
    "download": "Lataa",
    "pay_now": "Maksa nyt",
    "pull_down_refresh": "Vedä alas päivittääksesi",
    "release_refresh": "Vapauta päivittääksesi",
    "billing_details_text": "Laskutustiedot",
    "payment_methods": "Maksutavat",
    "invoice": "Laskuttaa",
    "invoice_to": "Lasku vastaanottaja:",
    "issue_date": "Julkaisupäivämäärä",
    "due_date": "Eräpäivä",
    "amount_due": "Erääntyvä summa",
    "charges": "Maksut",
    "sub_total_capitalize": "Välisumma",
    "hst": "HST",
    "grand_total": "Yhteensä",
    "invoice_generated_on": "Lasku luotu",
    "contact_us": "Ota yhteyttä",
    "invoice_issue": "Jos olet käynyt laskusi läpi ja sinulla on vielä kysyttävää",
    "call": "Soittaa",
    "send_sms": "Lähetä SMS",
    "payment_success": "Maksu on onnistunut",
    "edit_credit_card": "Muokkaa luottokorttia",
    "add_credit_card": "Lisää luottokortti",
    "modify_card_info": "Muokkaa korttisi tietoja",
    "enter_card_info": "Syötä korttisi tiedot",
    "account_no_req": "Tilinumero vaaditaan",
    "card_name_req": "Kortilla oleva nimi vaaditaan",
    "expiry_date_req": "Viimeinen voimassaolopäivä vaaditaan",
    "valid_expiry_date": "Anna kelvollinen viimeinen voimassaolopäivä",
    "valid_cvv": "Anna kelvollinen cvv",
    "cvv_req": "CVV vaaditaan",
    "card_no": "Kortin numero",
    "name_of_card": "Nimi kortissa",
    "expiry": "Vanhentuminen",
    "mm_text": "KK\/VV",
    "cvv": "CVV",
    "set_as_default": "Aseta oletukseksi",
    "add_new_card": "Lisää uusi kortti",
    "all_credit_card": "Kaikki luottokorttisi",
    "fastest_checkout": "Nopein uloskirjautuminen turvallisella ja luotettavalla alustalla, suuren yritysryhmän luottama",
    "coupon_button_req": "Kuponkipainikkeen teksti on pakollinen",
    "max19_characters_allowed": "Enintään 19 merkkiä sallitaan",
    "fine_print_text_req": "Pieni teksti vaaditaan",
    "clients_req": "Valitse vähintään yksi asiakas",
    "client_groups": "Asiakasryhmät",
    "day_text": "Päivä",
    "valid_days": "Päivän arvo on liian suuri, syötä kelvolliset päivät",
    "hours_text": "Tuntia",
    "enter_valid_hours": "Tuntien arvo on liian suuri, anna kelvolliset tunnit",
    "enter_valid_min": "Minuuttiarvo on liian suuri syötä kelvolliset minuutit",
    "clients_with_connection_required": "Asiakkaita, joilla on viimeinen yhteys, tarvitaan",
    "connection_required": "Yhteys vaaditaan",
    "connection_value_too_large": "Yhteyksien arvo on liian suuri syötä kelvollinen yhteys",
    "minutes_required": "Minuutit vaaditaan",
    "clients_with_at_least_connection_required": "Asiakkaita, joilla on vähintään yhteys, vaaditaan",
    "last_minutes_req": "Viimeiset minuutit vaaditaan",
    "clients_not_returned_required": "Se, joka ei palannut viime minuuteilla, vaaditaan",
    "not_return_in_last_minutes_invalid": "Ei palata viime minuuteilla saa olla vähemmän kuin viimeiset minuutit.",
    "customerGroups": "Asiakasryhmät",
    "select_customer_group": "Valitse asiakasryhmä",
    "location_required": "Sijainti vaaditaan",
    "start_date_required": "Aloituspäivä on pakollinen",
    "start_date_invalid": "Aloituspäivämäärä on virheellinen",
    "start_date_invalid_error": "Aloituspäivän on oltava suurempi tai yhtä suuri kuin tämä päivä",
    "recurring": "Toistuva",
    "end_date_required": "Päättymispäivä on pakollinen",
    "end_date_invalid": "Päättymispäivä on virheellinen",
    "end_date_invalid_error": "Päättymispäivän on oltava aloituspäivää myöhäisempi",
    "time_invalid": "Aika on virheellinen",
    "monthly_text": "Kuukausittain",
    "send_day_req": "Pakollinen lähetys päivän aikana",
    "loyal_clients": "Uskolliset asiakkaat",
    "recent_clients": "Viimeaikaiset asiakkaat",
    "lapsed_clients": "Vanhentuneet asiakkaat",
    "clients_connection_date": "Asiakkaat liittymispäivän mukaan",
    "scheduled_text": "Aikataulutettu",
    "weekly": "Viikoittain",
    "selected_clients_not_subscriber": "Valitut asiakkaat eivät ole tilaajia.",
    "message_preview_title": "Viestin esikatselu",
    "coupon_preview_title": "Kupongin esikatselu",
    "form_text": "Lomake",
    "preview_text": "Esikatselu",
    "next_text": "Seuraavaksi",
    "send_test_SMS": "Lähetä testitekstiviesti",
    "save_draft": "Tallenna luonnoksena",
    "back_to_edit": "Takaisin Muokkaa",
    "select_payment_method": "Valitse maksutapa",
    "schedule_SMS": "Ajoita tekstiviestit",
    "send_now": "Lähetä nyt",
    "get_more_ratings": "Hanki lisää arvioita",
    "overview": "Yleiskatsaus",
    "reset_campaign": "Nollaa kampanja",
    "permissions": "Käyttöoikeudet",
    "location_name": "Sijainnin nimi",
    "phone_no": "Puhelinnumero",
    "location_email_address": "Sijainti Sähköpostiosoite",
    "located_business": "Missä yrityksesi sijaitsee?",
    "business_logo": "Yrityksen logo",
    "congratulations": "Onnittelut",
    "almost_done": "Olet melkein valmis",
    "publish": "Julkaista",
    "about_your_business": "Tietoja yrityksestäsi",
    "add_your_location": "Lisää sijaintisi",
    "publish_location": "Julkaise sijainti",
    "location_name_req": "Sijainnin nimi on pakollinen",
    "valid_location_name": "Anna kelvollinen sijainnin nimi",
    "business_logo_req": "Yrityksen logo vaaditaan",
    "please_accept_terms": "Hyväksy ehdot",
    "add_new_location": "Lisää uusi sijainti",
    "edit_location": "Muokkaa sijaintia",
    "add_location": "Lisää sijainti",
    "existing_msg_subscriber_txt": "Viesti nykyiselle tilaajalle",
    "msg_capitalize_txt": "Viesti",
    "group_noti_sms": "Ilmoita minulle jokaisesta uudesta yhteydenotosta tekstiviestillä",
    "group_noti_email": "Ilmoita minulle jokaisesta uudesta yhteydenotosta sähköpostitse",
    "group_member_msg": "Vaikuttaa siltä, ​​että olet jo osa ryhmäämme!!",
    "group_mend_msg": "STOP lopettaaksesi. APUA apua. Tekstiviesti- ja tiedonsiirtomaksuja saatetaan periä",
    "Disclaimer_title": "Vastuuvapauslausekkeen teksti: ",
    "group_def_msg": "Olemme nyt verkossa! Tutustu palveluvalikoimaamme ja varaa seuraava aikasi jo tänään.",
    "required_msg_txt": "Viesti vaaditaan",
    "required_Key_txt": "Avainsanat vaaditaan",
    "required_mem_msg": "Tilaajaviesti vaaditaan",
    "client_list_title": "Asiakasluettelo",
    "add_contact_txt": "Lisää yhteystieto",
    "delete_all_clients_txt": "Poista kaikki asiakkaat",
    "delete_all_clients_msg": "Haluatko varmasti poistaa kaikki asiakkaat? Niitä ei voi palauttaa.",
    "delete_all_txt": "Poista kaikki",
    "timeline_title": "Aikajana",
    "unsubscribe_title": "Peruuta tilaus",
    "subscribe_title": "Tilaa",
    "unsubscribe_confirm_msg": "Haluatko merkitä tämän asiakkaan tilauksen peruuttajaksi?",
    "subscribe_confirm_msg": "Haluatko merkitä tämän asiakkaan tilaajaksi?",
    "no_title": "Ei",
    "yes_title": "Kyllä",
    "client_name_title": "Asiakkaan nimi",
    "source_title": "Lähde",
    "contact_file_Req": "Yhteystiedot-tiedosto vaaditaan",
    "req_title": "Pakollinen",
    "opt_in_req": "Tuotujen yhteystietojen on oltava 100-prosenttisesti hyväksyttyjä",
    "image_invalid_error": "Tiedosto on virheellinen.",
    "import_contacts_msg": "Tuo yhteystiedot",
    "csv_upload_title": "Ladattava CSV-yhteystietotiedosto *",
    "csv_file_desc": "Lataa vain csv-tiedosto - csv-tiedoston sarakkeet - Etunimi, Sukunimi, Sähköpostitunnus, Matkapuhelinnumero, Sukupuoli, DOB(KK\/PP\/VVVV) - Ei välilyöntejä, viivoja tai sulkeita matkapuhelinnumerossa",
    "to_download_title": "Lataa demotiedosto napsauttamalla tätä",
    "contains_header_title": "Kyllä, tämä tiedosto sisältää otsikoita",
    "opt_in_100_txt": "Kyllä, nämä tuodut yhteystiedot ovat 100-prosenttisesti mukana",
    "DisT": "Vastuuvapauslausekkeen teksti: ",
    "gMendMsg": "STOP lopettaaksesi. APUA apua. Tekstiviesti- ja tiedonsiirtomaksuja saatetaan periä",
    "reqKeyT": "Avainsanat vaaditaan",
    "reqMemMsg": "Tilaajaviesti vaaditaan",
    "reqMsgT": "Viesti vaaditaan",
    "gMemberMsg": "Vaikuttaa siltä, ​​että olet jo osa ryhmäämme!!",
    "gdefMsg": "Olemme nyt verkossa! Tutustu palveluvalikoimaamme ja varaa seuraava aikasi jo tänään.",
    "next_title": "Seuraavaksi",
    "male_title": "Uros",
    "male_val": "uros",
    "female_title": "Naaras",
    "female_val": "naaras",
    "others_txt": "muut",
    "others_val": "muut",
    "validBirthdate": "Anna kelvollinen syntymäaika",
    "valid_phone_no_title": "Anna kelvollinen puhelinnumero",
    "required_phone_no_title": "Puhelinnumero vaaditaan",
    "add_new_client_txt": "Lisää uusi asiakas",
    "update_client_txt": "Päivitä asiakas",
    "phone_num_text": "Puhelinnumero",
    "dob_title": "DOB",
    "select_gender_title": "Valitse Sukupuoli",
    "timelineTitle": "Aikajana",
    "confirm_location": "Vahvista sijainti",
    "feedback_title": "Palaute",
    "rep_question_1": "Oliko liike siisti?",
    "rep_que_2": "Oliko ruokasi hyvää?",
    "rep_que_3": "Oliko palvelumme ystävällistä?",
    "rep_que_4": "Oliko palvelumme nopeaa?",
    "business_location_req_title": "Yrityksen sijainti vaaditaan",
    "valid_location_err_txt": "Valitse kelvollinen osoite",
    "rep_management_title": "Hanki lisää arvostelujen hallinta",
    "rep_about_desc": "Maine auttaa sinua parantamaan Google-luokitusta pyytämällä asiakkailtasi palautetta",
    "preview_title": "Esikatselu",
    "rep_preview_txt": "Maineen esikatselu",
    "back_title": "Takaisin",
    "fine_print_txt": "Lunasta kassalla",
    "redeem_me_title": "Lunasta minut",
    "rep_nxt_visit_txt": "Seuraavalla vierailullasi",
    "device_type": "Käytetyn laitteen tyyppi",
    "connection_method": "Yhteysmenetelmä",
    "peak_days": "Huippupäivät",
    "peak_hours": "Huipputunnit",
    "guest_by_day": "Vieras päivältä",
    "guest_visit": "Vierailu",
    "connection": "Yhteys",
    "connection_duration": "Yhteyden kesto",
    "guest_visit_1": "1 kerta",
    "guest_visit_2": "2 kertaa",
    "guest_visit_3_5": "3-5 kertaa",
    "guest_visit_6_10": "6-10 kertaa",
    "guest_visit_11_25": "11-25 kertaa",
    "guest_visit_26_100": "26-100 kertaa",
    "guest_visit_100_plus": "100+ kertaa",
    "device_android_total": "Android yhteensä",
    "device_android": "Android",
    "device_desktop": "Työpöytä",
    "device_ios": "Ios",
    "device_ios_total": "Yhteensä Ios",
    "device_desktop_total": "Koko työpöytä",
    "connection_duration_10": "<=10 min",
    "connection_duration_20": "11-20 Min",
    "connection_duration_30": "21-30 min",
    "connection_duration_40": "31-45 min",
    "connection_duration_60": "46-60 min",
    "connection_method_email": "Sähköposti",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Kaikki Vieraat",
    "new_Guest_txt": "Uusi Vieras",
    "connections_txt": "Liitännät",
    "hotspot": "Hotspot",
    "hotspot_list": "Hotspot-luettelo",
    "add_new_hotspot": "Lisää uusi hotspot",
    "hotspot_information": "Hotspot-tiedot",
    "edit_details_button": "Muokkaa tietoja",
    "wifi_info_message": "Yhdistä ja nauti ilmaisesta WiFi-yhteydestä",
    "connection_message": "Hienoa, olet ollut yhteydessä ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "Kirjaudu sisään saadaksesi pääsyn",
    "verification_code": "Vahvistuskoodi",
    "verification_code_message": "Kirjoita osoitteeseen lähetetty vahvistuskoodi ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Mikä sinun nimesi muuten on?",
    "your_birthdate": "Voitko kertoa meille, mikä on syntymäpäiväsi?",
    "request_guest_wifi_name": "Anna vieras Wi-Fi-nimi",
    "request_device_key": "Anna laiteavain",
    "request_maximum_internet_access_length": "Valitse Internet-yhteyden enimmäispituus",
    "mac_address": "MAC-osoite",
    "device_port": "Laitteen portti",
    "hardware": "Laitteisto",
    "current_uptime": "Nykyinen käyttöaika",
    "nearby_devices": "Läheiset laitteet",
    "firmware": "Laiteohjelmisto",
    "who_are_you": "Kuka sinä olet?",
    "where_to_contact_you": "Mistä otamme sinuun yhteyttä?",
    "your_area_code": "Mikä on suuntanumerosi?",
    "connected": "Yhdistetty",
    "delete_hotspot_message": "Tämä hotspot poistetaan pysyvästi.",
    "delete_multiple_hotspots_message": "Nämä hotspotit poistetaan pysyvästi.",
    "speed_error": "Nopeuden tulee olla vähintään 0,01",
    "speed_max_error": "Anna arvo 1024 asti, jos haluat rajoittamattoman nopeuden, tai valitse pienempi arvo tuetulla alueella",
    "device_ssid": "Laitteen SSID",
    "device_ssid_two": "Laitteen SSID kaksi",
    "device_ssid_two_wpa": "Laitteen SSID Kaksi WPA",
    "device_key": "Laitteen avain",
    "select_location": "Valitse Sijainti",
    "select_maximum_internet_access_length": "Valitse Internet-yhteyden enimmäispituus",
    "download_speed": "Latausnopeus",
    "upload_speed": "Latausnopeus",
    "network_length_1": "15 minuuttia",
    "network_length_2": "30 minuuttia",
    "network_length_3": "45 minuuttia",
    "network_length_4": "1 tunti",
    "network_length_5": "2 tuntia",
    "network_length_6": "4 tuntia",
    "network_length_7": "6 tuntia",
    "network_length_8": "8 tuntia",
    "network_length_9": "10 tuntia",
    "network_length_10": "12 tuntia",
    "employee_wifi_name": "Työntekijän wifi-nimi",
    "employee_wifi_password": "Työntekijän WiFi-salasana",
    "guest_wifi_name": "Vieras Wi-Fi-nimi",
    "menu_other_products_txt": "Muut tuotteet | Menu verkossa",
    "menu_home_text": "Etusivu | Menu verkossa",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Valitse kampanjatyyppi",
    "select_readymade_templates": "Valitse valmiista malleista tai tee omasi",
    "campaign_title_required": "Kampanjan nimi on pakollinen",
    "type_here": "Kirjoita tähän...",
    "location_permission_req": "Sijaintilupa vaaditaan",
    "platform_permission_req": "Alustan lupa vaaditaan",
    "profile_picture": "Profiilikuva",
    "click_to_upload": "Lataa napsauttamalla",
    "location_permission": "Sijainnin lupa",
    "pin": "Pin",
    "platform_permission": "Alustan lupa",
    "set_pin": "Aseta PIN",
    "list_of_users": "Luettelo käyttäjistä",
    "create_user": "Luo käyttäjä",
    "terms_and_condition_req": "Ehdot vaaditaan",
    "terms_and_conditions": "Ehdot",
    "recommended": "SUOSITELTU",
    "mo": "\/kk",
    "unsubscribe": "Peruuta tilaus",
    "cancel_subscription": "Haluatko varmasti peruuttaa tilauksen?",
    "upgrade": "Päivitä",
    "plan_upgraded_successfully": "Suunnitelman päivitys onnistui!",
    "menu_online": "Menuonline",
    "support": "Tukea",
    "wifiC": "WIFI",
    "billing": "Laskutus",
    "please_upgrade": "Päivitä, jotta voit nauttia kaikista ominaisuuksista",
    "you_have_subscribed": "Olet tilannut ",
    "plan": "suunnitelma",
    "of_text": "\/",
    "days": "päivää",
    "remaining_days_until_plan": " Päiviä jäljellä, kunnes suunnitelmasi on päivitettävä",
    "manage_your_screens": "Hallitse näyttöjäsi",
    "screens_you_save": "Sinulla olevat näytöt",
    "add_more_screens": "Lisää näyttöjä",
    "addition_screen": "Lisäysnäyttö",
    "per_screen": "$ per näyttö",
    "per_box": "$ per laatikko",
    "shipping_changes": "Toimituskulut",
    "upto4_boxes": "jopa 4 laatikkoa ",
    "charge_now": "Saat maksun nyt",
    "updated_reoccuring": "Päivitetty uudelleentoistosi tulee olemaan",
    "current_reoccuring": "Nykyinen toistuva: ",
    "no_text": "Ei",
    "android_box": "Android Box",
    "old_password_req": "Vanha salasana vaaditaan",
    "new_password_req": "Uusi salasana vaaditaan",
    "confirm_password_req": "Vahvista salasana vaaditaan",
    "password_do_not_match": "Salasanat eivät täsmää",
    "old_password": "Vanha salasana",
    "new_password": "Uusi salasana",
    "confirm_password": "Vahvista salasana",
    "copy_working_hours": "Kopioidaanko nämä työajat kaikkiin päiviin?",
    "yes_copy": "Kyllä, kopioi",
    "closed": "Suljettu",
    "opening_time": "Aukioloaika",
    "closing_time": "Sulkemisaika",
    "description": "Kuvaus",
    "file_exist": "tiedosto on jo olemassa, valitse toinen tiedosto",
    "bussiness_images": "Yrityskuvat",
    "display_info_on_market_place": "Haluatko näyttää alla olevat tiedot kauppapaikastasi?",
    "profile_ready": "Profiilisi on valmis!",
    "client_book_with_online": "Asiakkaat voivat nyt varata kanssasi verkossa. Aloita jakamalla alla oleva linkki.",
    "copy_link": "Kopioi linkki",
    "see_your_profile": "Katso profiilisi",
    "ok_got_it": "Ok, tajusin",
    "preview_profile": "Esikatsele profiiliasi ja katso, miltä se näyttäisi.",
    "opening_hours": "Aukioloajat",
    "display_data": "Näytä tiedot",
    "manage_profile": "Hallinnoi profiilia",
    "phone_req": "Puhelin vaaditaan",
    "market_place_img_req": "Kauppapaikan kuvat vaaditaan",
    "add_new_market_place": "Lisää uusi markkinapaikka",
    "edit_market_place": "Muokkaa MarketPlacia",
    "no_reviews_yet": "Ei vielä arvosteluja",
    "good": "Hyvä",
    "average": "Keskimäärin",
    "add_marketPlace": "Lisää MarketPlace",
    "all_title": "Kaikki",
    "included_title": "Mukana",
    "excluded_title": "Poissuljettu",
    "clients_subscribed": "Asiakkaat tilasivat viimeksi",
    "clients_groups": "Asiakasryhmät",
    "customer_groups": "Asiakasryhmät",
    "audience_title": "Yleisö",
    "client_gt5": "joka ei palannut viimeksi",
    "select_all": "Valitse Kaikki",
    "modify": "Muuttaa",
    "campaign_title": "Kampanjan otsikko",
    "msg_type": "Viestin tyyppi",
    "enter_discount": "Anna alennus",
    "discount_type": "Alennustyyppi",
    "coupon_text": "Kupongin teksti",
    "enter_coupon_text": "Syötä kuponkiteksti",
    "coupon_button_text": "Kuponkipainikkeen teksti",
    "enter_coupon_button_text": "Syötä kuponkipainikkeen teksti",
    "fine_prin": "Hieno printti",
    "enter_fine_prin": "Kirjoita hieno teksti",
    "campaign_dec": "Voit muokata kampanjaa 30 minuuttia ennen sen alkamisaikaa. Tekstiviestikampanjan veloitukset alkavat 30 minuuttia ennen kampanjan alkamisaikaa.",
    "blast_text_message_dec": "Pikatekstiviestisi on valmis. Valitse maksutapasi ja lähetä viestisi.",
    "payment_completed": " Maksu suoritetaan klo ",
    "total_cost": "Kokonaiskustannukset",
    "close_title": "Lähellä",
    "friday": "perjantai",
    "saturday": "lauantai",
    "sunday": "sunnuntai",
    "thursday": "torstai",
    "tuesday": "tiistai",
    "wednesday": "keskiviikko",
    "port_txt": "Portti",
    "today_title": "Tänään",
    "yesterday_title": "Eilen",
    "last_30_days_txt": "Viimeiset 30 päivää",
    "this_month_txt": "Tässä kuussa",
    "last_month_txt": "Viimeinen kuukausi",
    "valid_date_title": "Valitse kelvollinen päivämäärä",
    "valid_business_name_txt": "Anna kelvollinen yrityksen nimi",
    "req_bus_add_txt": "Yrityksen osoite vaaditaan",
    "req_domain_name_txt": "Verkkotunnus vaaditaan",
    "basic_info_txt": "Yrityksesi perustiedot",
    "loyalty_qr_def_txt": "Skannaa QR-koodi, liity kanta-asiakaskorttiimme ja nauti palkinnoista.",
    "last_stamp_txt": "Viimeinen leima",
    "collected_on_txt": "Kerätty",
    "stamp_details_txt": "Leiman tiedot",
    "add_stamp_txt": "Lisää leima",
    "choose_brand_color_txt": "Valitse brändisi väri ja kieli 🎨",
    "change_anytime_txt": "Voit muuttaa tätä milloin tahansa",
    "like_to_get_txt": "Haluaisitko saada",
    "your_next_visit_txt": "seuraavalla vierailullasi?",
    "time_to_time_get_offers": "Haluatko saada aika ajoin mahtavia tarjouksia ja kampanjoita?",
    "respond_back_to_you": "Haluaisitko jonkun vastaavan sinulle?",
    "input_serve_better": "Palautteesi auttaa tiimiämme palvelemaan sinua paremmin!",
    "do_it_txt": "Tehdään se",
    "take_15_s": "Kestää 15 sekuntia",
    "rate_on_google_desc": "Voisitko ystävällisesti arvioida meidät Googlessa?",
    "may_be_next_time": "Ehkä ensi kerralla",
    "thank_you_txt": "Kiitos",
    "look_next_txt": "Odotamme innolla seuraavaa vierailuasi!",
    "deleteUserTitle": "Tämä käyttäjä poistetaan pysyvästi.",
    "deleteMUserTitle": "Nämä käyttäjät poistetaan pysyvästi.",
    "change_pin": "Vaihda Pin",
    "area_code": "Mikä on suuntanumerosi? ",
    "add_menu": "Lisää valikko",
    "menu_name": "Valikon nimi",
    "add_menu_placeholder": "Pizza, Burger, Baverages jne",
    "enable_data_Collection": "Ota tiedonkeruu käyttöön",
    "add_new_menu": "Lisää uusi valikko",
    "rename_menu": "Nimeä valikko uudelleen",
    "preview": "Esikatselu",
    "generate_qr": "Luotu QR",
    "edit_menu": "Muokkaa valikkoa",
    "remove_menu": "Poista valikko",
    "menu_delete_msg": "Tämä valikko poistetaan pysyvästi",
    "menus_delete_msg": "Nämä valikot poistetaan pysyvästi",
    "view_menu_dialoage_msg": "Haluatko saada lahjan syntymäpäivänäsi?",
    "skip": "Ohita",
    "cliam_your_gift": "Lunasta lahjasi",
    "collect_form": "Kerää lomake",
    "enter_first_name": "Anna etunimi",
    "enter_last_name": "Anna sukunimi",
    "enter_email": "Anna sähköpostiosoite",
    "enter_dob": "Anna syntymäpäivä",
    "enter_number": "Syötä numero",
    "select_gender": "Valitse Sukupuoli",
    "congratulations_desc": "🎉 Onnittelut! Lahjahakemuspyyntösi on lähetetty onnistuneesti. Tiimimme ottaa sinuun yhteyttä pian. 🎉",
    "male_heading": "Uros",
    "male_text": "uros",
    "female_heading": "Naaras",
    "female_text": "naaras",
    "others_heading": "muut",
    "other_text": "muut",
    "BirthD": "Syntymäpäivä",
    "GenderT": "Sukupuoli",
    "EmailT": "Sähköposti",
    "dobT": "DOB",
    "capitalize_menu": "Valikko",
    "select_menu": "Valitse valikko",
    "manage_variant": "Hallitse versiota",
    "add_products": "Lisää tuotteita",
    "add_category": "Lisää luokka",
    "category_delete": "Tämä luokka poistetaan pysyvästi",
    "variation_delete": "Tämä versio poistetaan tuotteesta.",
    "product_delete": "Tämä tuote poistetaan pysyvästi.",
    "categories_delete": "Nämä luokat poistetaan pysyvästi.",
    "products_delete": "Nämä tuotteet poistetaan pysyvästi.",
    "category": "KATEGORIA",
    "price": "HINTA",
    "food_product_placeholder": "Pizza, Burger, Baverages jne",
    "active_title": "Aktiivinen",
    "inActive_title": "Ei-aktiivinen",
    "status_capital": "STATUS",
    "cat_status_require": "Luokan tila vaaditaan",
    "cat_name_require": "Luokan nimi vaaditaan",
    "category_name": "Luokan nimi",
    "status": "Status",
    "lgDay1": "maanantai",
    "lgDay2": "tiistai",
    "lgDay3": "keskiviikko",
    "lgDay4": "torstai",
    "lgDay5": "perjantai",
    "lgDay6": "lauantai",
    "lgDay7": "sunnuntai",
    "is_closed_title": "Suljettu",
    "book_table_title": "Varaa pöytä",
    "emailErrSub": "Sähköpostin aihe vaaditaan",
    "email_subject": "Sähköpostin aihe",
    "contactUsfrmTitleEmail": "Vastaa sähköpostiin",
    "companyInfo": "Lorem Ipsum on lorem hrig, lorem ipsum on fraets. Lorem Ipsum on lorem hrig, lorem ipsum on fraets. Lorem Ipsum on lorem hrig, lorem ipsum on fraets.",
    "footerSTitle1": "Tietoja Menuonlinesta",
    "footerSTitle1Lnk2": "Ominaisuudet",
    "footerSTitle1Lnk3": "Blogi",
    "footerSTitle2": "Laillinen",
    "footerSTitle2Lnk1": "Ehdot",
    "footerSTitle2Lnk2": "Tietosuojakäytäntö",
    "footerSTitle3": "Yrityksille",
    "footerSTitle3Lnk1": "Kumppaneille",
    "footerSTitle3Lnk2": "Hinnoittelu",
    "footerSTitle3Lnk3": "Tuki kumppaneille",
    "footerCopyRithgTxt": "  Menuonline tai sen tytäryhtiöt",
    "homeTitle": "Kotiin",
    "contactTitle": "Ota yhteyttä",
    "aboutTitle": "Tietoja meistä",
    "homeMenuTitle": "Valikko",
    "bookTableTitle": "Varaa pöytä",
    "vDateT": "Valitse kelvollinen päivämäärä",
    "reqDateT": "Päivämäärä vaaditaan",
    "dateGtErr": "Päivämäärän on oltava tämän päivän päivämäärää myöhempi tai yhtä suuri",
    "timeInvalid": "Aika on virheellinen",
    "reqTimeT": "Aikaa tarvitaan",
    "timeInvalidBfr": "Valitse aika, joka on vähintään 5 minuuttia myöhempi nykyisestä ajasta ",
    "PerReq": "Henkilömäärä vaaditaan",
    "validcNoT": "Anna kelvollinen yhteysnumero",
    "reqCrT": "Valuutta vaaditaan",
    "reqPrT": "Hinta vaaditaan",
    "reCateT": "Luokka vaaditaan",
    "reqVrT": "Variantti vaaditaan",
    "reqVrVldT": "Anna kelvollinen versio ",
    "validLNameT": "Anna kelvollinen sukunimi",
    "sDateInValid": "Alkamispäivämäärä on virheellinen",
    "minmReq": "Vähintään vaaditaan",
    "resLblDate": "Valitse Päivämäärä",
    "resLblTime": "Valitse Aika",
    "perT": "Kuinka monelle henkilölle?",
    "resLblEmail": "Anna sähköpostiosoitteesi?",
    "resLblNote": "Lisää varausilmoitus",
    "imageError1MB": "Lisää kuva, joka on alle 1 Mt",
    "imageError": "Lisää kuva, joka on alle 2,5 megatavua",
    "imageError3Mb": "Lisää kuva, joka on alle 3 megatavua",
    "imageInVldError": "Tiedosto on virheellinen.",
    "addProMT": "Tuotevalikko",
    "proT": "Tuotteen nimi",
    "reqProT": "Tuotteen nimi vaaditaan",
    "proPT": "Tuotteen hinta",
    "reservationSuccTitle": "Hienoa, varauksesi on tehty",
    "book": "Kirja",
    "custzName": "Mukautuksen nimi",
    "always": "Aina",
    "proImgReq": "Tuotekuva vaaditaan",
    "selCustmT": "Valitse mukauttaminen tai luo uusi",
    "visStR": "Näkyvyystila vaaditaan",
    "avlblScR": "Valitse saatavuusaikataulu",
    "addonPR": "Valitse lisätuotteet",
    "upsellPR": "Valitse lisämyyntituotteet",
    "markItemR": "Valitse tuotemerkit",
    "caloriesR": "Kaloreita tarvitaan",
    "allergensR": "Valitse allergeenituotteet",
    "prepInstR": "Valmistusohjeet vaaditaan",
    "staffNR": "Henkilökunnan muistiinpanot vaaditaan",
    "discountR": "Alennus vaaditaan",
    "validDiscE": "Anna voimassa oleva alennus",
    "disday": "Päivän mukaan",
    "plSelDayT": "Valitse päivä",
    "sTimeReq": "Aloitusaika vaaditaan",
    "sTimeInValid": "Aloitusaika on virheellinen",
    "eTimeReq": "Päättymisaika vaaditaan",
    "eTimeInValid": "Päättymisaika on virheellinen",
    "sDateReq": "Päivämäärä vaaditaan",
    "eDateReq": "Tähän päivään mennessä vaaditaan",
    "eDateInValid": "Päivämäärä on virheellinen",
    "disdate": "Päivämäärästä alkaen",
    "disdate1": "Päivämäärään",
    "disdate2": "Päivämäärästä alkaen",
    "currT": "Valuutta",
    "iconR": "Valitse merkki kohteen kuvake",
    "minT": "Minimi",
    "maxT": "Maksimi",
    "itemNT": "Tuotteen nimi",
    "itemPT": "Tuotteen hinta",
    "descProT": "Kuvaile tuotettasi",
    "cateT": "Luokka",
    "selProCateT": "Valitse tuoteluokkasi",
    "reqMT": "Valikon nimi on pakollinen",
    "ViewMenu": "Näytä valikko",
    "CopyMenu": "Kopioi valikko",
    "EditMenu": "Muokkaa valikkoa",
    "RemoveMenu": "Poista valikko",
    "welcomeMnuTitle": "Tervetuloa",
    "reviewT": "Arvostelut",
    "userErrorMsg": "Anteeksi, tapahtui virhe!!!",
    "reqCateT": "Luokan nimi vaaditaan",
    "mngCustT": "Hallitse mukauttamista",
    "custNReq": "Mukautettava nimi vaaditaan",
    "incReq": "Mukana vaaditaan",
    "minmValid": "Anna kelvollinen vähimmäisarvo",
    "maxmReq": "Enimmäismäärä vaaditaan",
    "cust": "Räätälöinti",
    "crCust": "Luo uusi räätälöinti",
    "custList": "Mukauttaminen luettelo",
    "delCustmzT": "Tämä muokkaus poistetaan pysyvästi.",
    "mkNameR": "Merkitse kohteen nimi on pakollinen",
    "mkDelT": "Tämä merkkikohde poistetaan pysyvästi.",
    "hideText": "Piilottaa",
    "showText": "Show",
    "device_mac_txt": "Laite Mac",
    "delete_hotspot_txt": "Tämä hotspot poistetaan pysyvästi.",
    "delete_hotspots_txt": "Nämä hotspotit poistetaan pysyvästi.",
    "emp_wifi_name": "Työntekijän wifi-nimi",
    "max_limit_txt": "Syötä '1024', jos haluat määrittää nopeuden RAJOITTAMATTOmaksi. Tämä arvo poistaa kaikki rajoitukset, mikä mahdollistaa suurimman suorituskyvyn",
    "device_port_txt": "Laitteen portti",
    "firmware_txt": "Laiteohjelmisto",
    "hotspot_info_txt": "Hotspot-tiedot",
    "editDBtn": "Muokkaa tietoja",
    "birth_date": "Syntymäpäivä",
    "di_theme_clr": "Teeman väri täsmälleen 7 numeroa",
    "color_Invalid_txt": "Valitse kelvollinen värikoodi",
    "Req_theme_clr": "Teeman väri on pakollinen",
    "select_valid_color_txt": "Valitse kelvollinen teeman väri",
    "req_redir_link": "Uudelleenohjauslinkki vaaditaan",
    "val_redir_link": "Anna kelvollinen uudelleenohjauslinkki",
    "req_business_name_txt": "Yrityksen nimi vaaditaan",
    "splash_preview": "Splash esikatselu",
    "create_new_splash": "Luo uusi Splash",
    "splash_page": "Splash sivu",
    "splash_page_builder": "Splash Page Builder",
    "redirect_link": "Uudelleenohjauslinkki",
    "theme_color": "Teeman väri",
    "enable_social_login": "Ota käyttöön sosiaalisen median asetukset käyttäjien kirjautumiseen",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Onko se pakollinen?",
    "field": "Ala",
    "name": "Nimi",
    "first_name": "Etunimi",
    "last_name": "Sukunimi",
    "birthdate": "Syntymäpäivä",
    "gender": "Sukupuoli",
    "email": "Sähköposti",
    "dob": "DOB",
    "zip_code": "Postinumero",
    "required_redirect_link": "Uudelleenohjauslinkki vaaditaan",
    "valid_redirect_link": "Anna kelvollinen uudelleenohjauslinkki",
    "required_theme_color": "Teeman väri on pakollinen",
    "theme_color_length": "Teeman väri täsmälleen 7 numeroa",
    "required_name": "Nimi vaaditaan",
    "delete_splash_message": "Haluatko poistaa tämän splashin?",
    "delete_multiple_splashes_message": "Haluatko poistaa nämä roiskeet?",
    "user_login_required": "Käyttäjän sisäänkirjautuminen vaaditaan",
    "set_theme_color": "Aseta teeman väri",
    "splash_colllect_additional_info_txt": "Kerää lisätietoja",
    "verify_mobile_otp_message": "Haluatko lähettää OTP:n matkapuhelinnumeron vahvistamiseksi?",
    "add_company": "Lisää yritys",
    "edit_company": "Muokkaa yritystä",
    "ds_device": "Digital Signage Device",
    "ds_device_activation_fee": "Digital Signage -laitteen aktivointimaksu",
    "wifi_hardware": "Wifi-laitteisto",
    "menu_design": "Menun suunnittelu",
    "ds_hardware": "Digital Signage -laitteisto",
    "company_name_req": "Yrityksen nimi vaaditaan",
    "known_name_req": "Tunnettu nimi vaaditaan",
    "no_location_req": "Paikan numero vaaditaan",
    "minimum_location": "Anna vähintään 1 sijainti",
    "terms_req": "Ehdot vaaditaan",
    "notes_req": "Muistiinpanot vaaditaan",
    "sms_charge_req": "SMS-maksu vaaditaan",
    "menuonline_quantity_req": "Menu Online Määrä vaaditaan",
    "menuonline_cost_req": "Menu Online-maksu vaaditaan",
    "ds_quantity_req": "Digitaalisten opasteiden määrä vaaditaan",
    "ds_monthly_cost_req": "Digital Signage kuukausimaksu vaaditaan",
    "reputation_quantity_req": "Maine Määrä vaaditaan",
    "reputation_cost_req": "Mainekustannus vaaditaan",
    "marketing_quantity_req": "Markkinointimäärä vaaditaan",
    "marketing_cost_req": "Markkinointikulut vaaditaan",
    "tax_percentage_req": "Veroprosentti vaaditaan",
    "router_hardware_quantity_req": "Reitittimen laitteiston määrä vaaditaan",
    "wifi_hardware_cost_req": "WIFI-laitteiston hinta vaaditaan",
    "activation_cost_req": "Aktivointikulut vaaditaan",
    "wifi_quantity_req": "WIFI-määrä vaaditaan",
    "wifi_router_req": "Wifi-reititin vaaditaan",
    "menu_design_quantity_req": "Menu suunnittelu Määrä vaaditaan",
    "menu_design_cost_req": "Menusuunnittelun hinta vaaditaan",
    "quantity_req": "Määrä vaaditaan",
    "cost_req": "Kustannus vaaditaan",
    "sheduledown_quantity_req": "Alasmäärä on pakollinen",
    "sheduledown_cost_req": "Alennetut kustannukset vaaditaan",
    "loyalty_program_quantity_req": "Kanta-asiakasohjelman määrä vaaditaan",
    "loyalty_program_cost_req": "Kanta-asiakasohjelman hinta vaaditaan",
    "loyalty_program_sms_quantity_req": "Kanta-asiakasohjelman SMS-määrä vaaditaan",
    "loyalty_program_sms_cost_req": "Kanta-asiakasohjelman tekstiviestimaksu vaaditaan",
    "comapny_created": "Yritys perustettu onnistuneesti",
    "comapny_updated": "Yritys päivitetty onnistuneesti",
    "billing_info": "LASKUTUSTIEDOT",
    "business_name_acc_holder": "Yrityksen nimi (tilinhaltija)",
    "client_first_name": "Asiakkaan etunimi",
    "client_last_name": "Asiakkaan sukunimi",
    "dba_known_as": "DBA tunnetaan nimellä",
    "business_address": "Yrityksen osoite",
    "no_of_locations": "Sijaintien lukumäärä",
    "package_details": "PAKKAUKSEN TIEDOT",
    "terms": "Ehdot",
    "one_month": "1 kuukausi",
    "per_sms_charge": "per tekstiviestimaksu",
    "plan_test": "SUUNNITELMA: TEST",
    "desc_capital": "KUVAUS",
    "qty_capital": "Kpl",
    "cost_capital": "MAKSAA",
    "total_price_capital": "KOKONAISHINTA",
    "quantity": "Määrä",
    "can_not_access": "Et voi valita enempää kuin",
    "licenses": "lisenssit",
    "monthly_cost": "Kuukausikulut",
    "plan_cost": "Suunnitelman kustannukset",
    "tax": "Verottaa",
    "tax_percentage": "Veroprosentti",
    "monthly_cost_after_tax": "Kuukausikulut verojen jälkeen",
    "activation_one_time_charge": "Aktivointi ja kertamaksu",
    "sub_total": "SUB TOTAL",
    "action": "Toiminta",
    "cost": "Maksaa",
    "shipping_charge": "Toimitusmaksu",
    "other": "Muut",
    "additional_cost": "Lisäkulut (muut maksut)",
    "other_tax": "Muu vero",
    "total": "Kokonais",
    "license_statistics": "Lisenssitilastot",
    "total_licenses": "Lisenssit yhteensä",
    "available_licenses": "Käytettävissä olevat lisenssit",
    "stock": "Stock",
    "has_permission": "On lupa",
    "avg_stock_price": "AVG osakekurssi",
    "average_price": "Keskihinta",
    "allocated": "Jaettu",
    "reward_regulars": "Palkitse vakituiset asiakkaat",
    "not_add_coupon_url": "Kupongin URL-osoitetta ei voi lisätä. Viestin enimmäispituus on 160",
    "like_attach_coupon": "Haluatko liittää kupongin?",
    "advance_scheduling": "Ennakkoaikataulu",
    "choose_day": "Valitse päivä(t) lähettääksesi viestin",
    "select_essage_window": "Valitse Viesti-ikkuna",
    "subscription_outside_delivery_window": "Jos käyttäjä tekee tilauksen toimitusajan ulkopuolella, hän saa automaattivastaajasi seuraavana mahdollisena ajankohtana.",
    "remaining": "Jäljellä",
    "processing_request": "Pyyntöäsi käsiteltäessä tapahtui virhe.",
    "list_companies": "Luettelo yrityksistä",
    "are_you_sure": "Oletko varma..?",
    "signin_agreement": "Vahvistamalla tämän yritys luo ilman sopimusta.",
    "status_updated": "Tilan päivitys onnistui",
    "status_failed": "Tilan päivittäminen epäonnistui",
    "new_editor_status_updated": "DS New Editor -tilan päivitys onnistui.",
    "user_name": "Käyttäjänimi",
    "known_as": "tunnetaan nimellä",
    "agreement_signed": "Sopimus allekirjoitettu",
    "deactivate": "Poista käytöstä",
    "activate": "Aktivoida",
    "login_to_user_acc": "Kirjaudu sisään käyttäjätilille",
    "disable": "Poista käytöstä",
    "enable": "Ota käyttöön",
    "ds_new_editor": "DS:n uusi editori",
    "sign_agreement": "Allekirjoita sopimus",
    "agreement_sign": "Sopimus ilman allekirjoitusta",
    "view_agreement": "Katso sopimus",
    "download_agreement": "Lataa sopimus",
    "add_deleted_user": "Lisää poistettu käyttäjä",
    "deleted_user": "Poista käyttäjä",
    "favourite": "Suosikki",
    "refresh": "Päivitä",
    "delete_chat": "Poista Chat",
    "responsive_drawer": "Responsiivinen laatikko",
    "delete_contact": "Poista yhteystieto",
    "clear_chat_history": "Tyhjennä keskusteluhistoria",
    "clear_chat_history_dec": "Haluatko varmasti poistaa tämän keskustelun?",
    "clear_contact_dec": "Haluatko varmasti poistaa tämän yhteystiedon?",
    "select_contact": "Valitse Yhteystiedot",
    "new_conversation": "Aloita uusi keskustelu",
    "type_msg": "Kirjoita viestisi tähän",
    "select_delete_client": "Valitse poistettava asiakas",
    "select_delete_client_chat": "Valitse asiakas poistaaksesi keskustelun",
    "select_delete_client_chat_err": "Sinulla ei ole keskustelua tämän asiakkaan kanssa",
    "acquire_customers_business": "Hanki uusia asiakkaita muilta yrityksiltä.",
    "customers_from_events": "Hanki asiakkaita tapahtumista ja tuo se sinulle!",
    "customers_from_ads": "Hanki asiakkaita sanomalehtimainoksista.",
    "smart_pamphlets": "Luo älykkäitä esitteitä asiakastietojen keräämiseen.",
    "smart_qr_group": "Älykäs QR asiakkaiden ryhmään liittymiseen.",
    "opt_in_number": "Anna asiakkaille mahdollisuuden valita numero.",
    "qr_menu": "Luo QR-koodi valikosi näyttämistä varten.",
    "collect_birthdays": "Kerää asiakkaan syntymäpäivä ja vuosipäivä.",
    "join_loyalty_program": "Hanki uusia asiakkaita liittymään kanta-asiakasohjelmaani.",
    "create_feedback_forms": "Luo lomakkeita asiakkaiden palautteen keräämiseksi.",
    "exclusive_discounts_promotions": "Tarjoa eksklusiivisia alennuksia ja kampanjoita ristikkäisiin kampanjoihin.",
    "group_smart_qr_opt_in": "Ryhmän luomisen jälkeen voit luoda ja valita älykkään QR-koodin, jonka avulla asiakkaat voivat liittyä ryhmään välittömästi.",
    "group_smart_qr_opt_in_dec": "Ryhmän luomisen jälkeen Kuva-widget, jonka avulla asiakkaat voivat valita tekstin käytön ja saada tarjouksia, esimerkiksi tekstin \"pizza\" numeroon 555-555-5555",
    "in_store_discount_next_visit": "Saat alennuksen myymälästä seuraavan vierailusi aikana toimipisteessämme",
    "delivery_redirection": "Luo toimituksen uudelleenohjaus",
    "additional_info": "Lisätietoja",
    "add_url": "Lisää URL-osoite",
    "custom_fields": "Mukautetut kentät",
    "meta_tag": "Sisällönkuvauskenttä",
    "max_chars": "Max merkkiä",
    "add": "Lisätä",
    "update_campaign": "Päivitä kampanja",
    "last_week_required": "viimeinen viikko vaaditaan",
    "large_week_value": "Viikon arvo on liian suuri syötä kelvollinen viikko",
    "less_than_last_week": "Viime viikon palautusoikeuden on oltava pienempi kuin viime viikolla.",
    "last_week_req": "Ei palauteta viime viikolla",
    "birthday_special": "Syntymäpäivä erikoinen",
    "birthday_message": "Syntymäpäivinä lähetetty automaattinen viesti henkilökohtaisilla tervehdyksillä ja houkuttelevilla tarjouksilla",
    "birthday_sms": "Luo henkilökohtaisia ​​syntymäpäivätervehdyksiä SMS-\/MMS-viestejä varten",
    "target_audience": "Valitse kohdeyleisö",
    "client_by_birthday": "Asiakas syntymäpäivän mukaan",
    "clients_once_year": "Tämä kampanja lähetetään automaattisesti asiakkaille kerran vuodessa ",
    "auto_responder_default_msg_1": "Olet yksi parhaista asiakkaistamme, jolle annamme mielellämme alennuksia! Olkoon aina rikas ja terve. Hyvää syntymäpäivää! %% etunimi %%",
    "campaign_send_to_client_birthday": "Tämä automaattinen kampanja lähettää asiakkaille, joilla on syntymäpäivä. Voit mukauttaa kohdistusta alla.",
    "get_more_engagement": "Hanki lisää sitoutumista lähettämällä b'day SMS 🎂",
    "welcome_new_clients": "Tervetuloa uudet asiakkaat",
    "make_lasting_impression": "Tee pysyvä vaikutelma tervehtimällä lämpimästi ja toivottamalla ensikertalaisia",
    "personalized_greetings": "Luo henkilökohtaisia ​​terveisiä uusille asiakkaille",
    "greetings_to_new_customers": "Tämä kampanja lähettää tervetulotoivotukset uusille asiakkaille seuraavana päivänä, kun he ovat ensimmäisen kerran yhteydessä yritykseesi",
    "audience_predefined": "Tämän automaattivastaajan yleisö on ennalta määritetty, meillä on tämä!",
    "send_clients_subscribed": "Tämä automaattivastaaja on asetettu lähettämään viimeksi tilatut asiakkaat ",
    "first_interaction": "  ensimmäisen vuorovaikutuksensa jälkeen.",
    "default_msg_2": "Toivomme, että pidit ensimmäisestä vierailustasi, haluamme nähdä sinut pian uudelleen, joten tarjoamme alennuksen juuri sinulle! Napsauta linkkiä varataksesi seuraavan tapaamisesi kanssamme ja lunastaaksesi erikoistarjouksesi nyt.",
    "new_clients_update": "Tervetuloa uudet asiakkaat päivittämään kampanjatekstiä",
    "new_clients_warm_greeting": "Tervetuloa uudet asiakkaat lämpimästi tervehtimään 💌",
    "win_back_clients": "Voita takaisin asiakkaita",
    "re_engage_past_customers": "Sitouta entiset asiakkaat vastustamattomilla tarjouksilla kannustaaksesi heitä palaamaan",
    "target_disengaged_clients": "Kohdista irrottautuneita asiakkaita palaamaan erikoistarjouksella, joka on todistettu taktiikka asiakkaiden houkuttelemiseksi takaisin.",
    "campaign_sends_to_clients": "Tämä kampanja lähettää asiakkaille, jotka eivät palanneet tietyn ajan kuluttua.",
    "customize_targeting": "Muokkaa kohdistusta alla lähettääksesi SMS-\/MMS-viestejä vanhentuneille asiakkaille.",
    "clients_with_at_least": "Asiakkaat, joilla on vähintään ",
    "connection_last": " yhteys viimeisessä ",
    "return_in_last_week": " viikolla, mutta ei palannut viimeksi ",
    "arr_menu_44": " viikko",
    "df_msg_4": "Haluamme sinut takaisin NIIN PALJON, että annamme sinulle 10% alennuksen pelkästä palautuksesta! Haluatko kokeilla meitä uudelleen tänään?",
    "update_campaign_text": "Voita takaisin asiakkaat päivittää kampanjatekstiä",
    "re_invite_customers": "Kutsu uudelleen asiakkaita, jotka eivät ole palanneet vähään aikaan",
    "loyal_patrons": "Osoita arvostusta uskollisille asiakkaille erikoisalennuksilla ja uskollisuuspalkkioilla",
    "surprize_top_spenders": "Yllätä eniten kuluttajat henkilökohtaisilla ja erikoistarjouksilla.",
    "campaign_automatically_send": "Tämä kampanja Lähetä automaattisesti palkintoja yrityksesi jalkapudotuksen tehostamiseksi.",
    "sms_loyal_clients": "Tämä kampanja lähettää SMS\/MMS-viestejä uskollisille asiakkaille. Voit muuttaa kohdistusta alla.",
    "or_more_connection": " tai useamman yhteyden viimeisen sisällä ",
    "week_period": " viikon ajanjakso.",
    "default_msg_5": "Olet ollut asiakas jo pitkään! Vau, aika lentää! Arvostamme sinua ja haluamme kiittää sinua siitä, että olet kanssamme.",
    "reward_regulars_update": "Rewards vakituiset päivittää kampanjateksti",
    "special_discounts": "Arvosta ja anna erikoisalennuksia💎",
    "leave_positive_online": "Kannusta asiakkaita jättämään positiivisia online-arvosteluja ravintolan maineen kohentamiseksi",
    "clients_service_online": "Kampanja rohkaisee asiakkaitasi arvioimaan palveluasi verkossa.",
    "increase_your_company": "Tämä automaattivastaaja lähetetään parantamaan yrityksesi online-luokitusta.",
    "send_message_after": "Lähetä viesti jälkeen ",
    "min_connection": " min yhteyden",
    "default_msg_6": "Toivomme, että pidit ensimmäisestä vierailustasi, haluamme nähdä sinut pian uudelleen! [URL] Napsauta linkkiä arvioidaksesi meidät Googlessa.",
    "ratings_update": "Hanki lisää Arvioiden päivityskampanjan tekstiä",
    "friendly_nudge": "Hanki lisää arvosteluja ystävällisellä nyökkäyksellä ⭐",
    "thanks_for_visiting": "Kiitos vierailustasi",
    "express_gratitude": "Kiitä asiakkaita yrityksesi valinnasta sydämellisillä kiitosviesteillä",
    "thank_you_message": "Luo kiitosviesti asiakkaillesi.",
    "clients_for_visiting": "Lähetä lämmin kiitos asiakkaillesi vierailusta luonasi.",
    "campaign_audience": "Tämä automaattisen kampanjan yleisö on ennalta määritetty, meillä on tämä!",
    "campaign_automatically_sends": "Tämä automaattinen kampanja lähettää automaattisesti sen jälkeen ",
    "minutes_customer_visit": " minuutin asiakaskäynnin",
    "default_msg_7": "Kiitos, että olet arvostettu asiakkaamme. Olemme erittäin kiitollisia ilosta palvella teitä ja toivomme, että olemme täyttäneet odotuksesi.",
    "visiting_update_campaign": "Kiitos käynnistäsi päivittää kampanjateksti",
    "guests_thank_you": "Kiitos vieraillenne vierailusta 😊",
    "download_title": "Lataa",
    "qr_gen_title": "QR Luotu",
    "qr_download_s_title": "QR-koodin lataus onnistui",
    "sel_dark_brand_clr_msg": "Valitse kelvollinen barnd-väri",
    "manage_customer_stamp_rewards": "Hallinnoi asiakasleimapalkintoja 🎁",
    "sel_loc_menu_title": "Valitse paikat ruokalistallesi",
    "ans_req": "Vastaus vaaditaan",
    "valid_reputation_name": "Anna kelvollinen maineen nimi",
    "reviews_name_req": "Hanki lisää arvosteluja nimi vaaditaan",
    "birthdate_required": "Syntymäaika vaaditaan",
    "gender_required": "Sukupuoli on pakollinen",
    "valid_birthdate_required": "Anna kelvollinen syntymäaika",
    "custom_delivery_redirection": "Luo mukautettu toimituksen uudelleenohjauslomake ryhmittäin",
    "customer_fields_incentive_settings": "Asiakaskentät ja kannustinasetukset",
    "delivery_redirection_text": "Toimituksen uudelleenohjaus",
    "delivery_redirection_success": "Onnittelut! Toimituksen uudelleenohjauswidgettisi luotu! 🎉",
    "swipe_to_preview_redirection": "Pyyhkäise nähdäksesi, miltä toimituksen uudelleenohjauslomake näyttää",
    "enter_item_name": "Anna tuotteen nimi",
    "home_page_text": "Kotisivun teksti",
    "settings": "Asetukset",
    "anniversary_required": "Vuosipäivä vaaditaan",
    "valid_anniversary": "Anna kelvollinen vuosipäivä",
    "form_submited": "lomake lähetetty onnistuneesti",
    "notifications": "Ilmoitukset",
    "discount_message": "Lunasta 20% alennuksesi Pizzasta nyt!",
    "is_required": " vaaditaan",
    "section_title_required": "Osion otsikko vaaditaan",
    "section_dec_required": "Osion kuvaus vaaditaan",
    "client_details_required": "Asiakastietokentät ovat pakollisia",
    "compliance": "Vaatimustenmukaisuus",
    "SMS_campaign1": "SMS-kampanja 1",
    "mobile_number_mandatory": "Matkapuhelinnumero on pakollinen",
    "new_answer": "Uusi vastaus",
    "new_question": "Uusi kysymys",
    "add_new_question": "Lisää uusi vastaus",
    "select": "Valitse",
    "group_customers_question": "Mihin haluat ryhmitellä asiakkaasi?",
    "contacts_added_to_group": "Kaikki lomakkeen lähettäneet yhteystiedot lisätään valittuun ryhmään",
    "edit_client_details_section": "Muokkaa asiakkaan tiedot -osiota",
    "client_details_fields": "Asiakkaan tietokentät",
    "enter_client_details_section_title_desc": "Anna asiakkaan tiedot -osion otsikko ja kuvaus",
    "choose_fields_displayed_client_side": "Valitse asiakaspuolella näytettävät kentät",
    "section_title": "Osion otsikko",
    "add_title": "Lisää otsikko",
    "section_description": "Osion kuvaus",
    "add_description": "Lisää kuvaus",
    "enter_program_name_location": "Anna ohjelman nimi ja sijainti 📍",
    "brand_theme_clr_txt": "Tuotemerkin teeman väri",
    "set_loyalty_punch_card_theme": "Laitetaan teema kanta-asiakaskortille 🎨",
    "upload_logo_txt": "Lataa logosi",
    "recommended_image_specs": "Suosittelemme käyttämään kuvaa, jonka koko on 512 x 512 pikseliä parhaan näytön saamiseksi. JPG, SVG tai PNG. Enimmäiskoko 10 MB.",
    "valid_rep_name_txt": "Anna kelvollinen maineen nimi",
    "rep_name_req_txt": "Hanki lisää arvosteluja nimi vaaditaan",
    "que_req": "Kysymys on pakollinen",
    "day_of_birthday": "syntymäpäivänä",
    "day_before_birthday": "3 päivää ennen syntymäpäivää",
    "week_before_birthday": "viikkoa ennen syntymäpäivää",
    "two_week_before_birthday": "kaksi viikkoa ennen syntymäpäivää",
    "de_active": "Ei aktiivinen",
    "campaign_details": "Kampanjan tiedot",
    "link_clicked": "Linkki napsautettiin",
    "history": "Historia",
    "auto_responder_summary": "Automaattivastaajien yhteenveto",
    "vsop_1": "15 min",
    "vsop_2": "30 min",
    "vsop_3": "45 min",
    "vsop_4": "60 min",
    "vsop_5": "90 min",
    "vsop_6": "120 min",
    "in_the_last": " viimeisessä ",
    "return_in_last": " mutta ei palannut viimeksi ",
    "of_the_connection": "yhteydestä",
    "your_campaign": "Sinun kampanjasi",
    "you_have_successfully": "Olet onnistunut",
    "published_successfully": "julkaistu onnistuneesti",
    "updated_smart_campaign": "päivitti älykkään kampanjan",
    "almost_done_text": "Melkein valmis!",
    "update_campaign_desc": "Automaattivastaajasi on valmis, kun se on otettu käyttöön, se lähettää viestejä asiakkaille jatkuvasti. Tätä kampanjaa voidaan helposti muokata tai keskeyttää milloin tahansa.",
    "update_and_publish": "Päivitä ja julkaise",
    "reset_campaign_title": "Se poistaa pysyvästi kampanjasi mukautetun tekstin, kuvan, alennustarjoukset ja kohdeyleisön!",
    "space_wifi": " WiFi",
    "custom_forms": "Mukautetut lomakkeet",
    "web_e_signup": "Web E-kirjautuminen",
    "import": "Tuoda",
    "permissions_req": "Luvat vaaditaan",
    "redeemed": "Lunastettu",
    "coupon_already_redeemed": "Kuponki on jo lunastettu",
    "autoresponder_campaigns": "Automaattivastaajakampanjat",
    "autoresponder_campaign_desc": "Ota automaattivastaaja käyttöön ja käynnistä henkilökohtainen kampanja",
    "mon": "MA",
    "tue": "TI",
    "wed": "KE",
    "thu": "TO",
    "fri": "PE",
    "sat": "SAT",
    "sun": "AURINKO",
    "duplicate": "Kopioi",
    "visibility": "Näkyvyys",
    "availability": "Saatavuus",
    "out_stok": "Loppu",
    "edit_product": "Muokkaa tuotetta",
    "create_product": "Luo tuote",
    "basic_info": "Perustiedot",
    "basic_sub_info": "Lorem Ipsum on yksinkertaisesti painatuksen valeteksti...",
    "enter_product_price": "Syötä tuotteen hinta",
    "upload_image": "Lataa kuva",
    "allowed_file_formats": "Vain JPG- ja PNG-tiedostot, joiden enimmäiskoko on 3 Mt, ovat sallittuja.",
    "pre_select": "Esivalinta",
    "promotions": "Kampanjat",
    "discount_availability": "Alennus saatavuus",
    "start_time": "Aloitusaika",
    "end_time": "Päättymisaika",
    "select_day": "Valitse Päivä",
    "menu_required": "Menu vaaditaan",
    "generated": "Luotu",
    "link": "Linkki",
    "variations": "Muunnelmia",
    "select_variant_to_add": "Valitse Variantti, jonka haluat lisätä",
    "price_title": "Hinta",
    "choose_image": "Valitse Kuva",
    "select_visiblity": "Valitse Näkyvyys",
    "availability_schedule": "Saatavuusaikataulu",
    "add_on_upsell": "Lisäys \/ lisämyynti",
    "add_on_select_product": "Lisää Valitse tuote",
    "upsell_product": "Lisämyynti Valitse tuote",
    "variant_deletion_warning": "Tämä versio poistetaan pysyvästi.",
    "search_variations": "Hakumuunnelmia",
    "add_variation": "Lisää variaatio",
    "variation_text": "Variantti",
    "select_customization": "Valitse mukauttaminen",
    "add_new": "Lisää Uusi",
    "delete_customization_warning": "Haluatko poistaa tämän mukautuksen tuotteesta?",
    "nutritional_allergen_info": "Ravitsemus- ja allergeenitiedot",
    "mark_item": "Merkitse kohde",
    "calories_text": "Kalorit",
    "allergens": "Allergeenit",
    "select_allergens": "Valitse allergeenit",
    "special_instructions": "Erityiset ohjeet",
    "preparation_instructions": "Valmistusohjeet",
    "staff_notes": "Henkilökunnan huomautukset",
    "checkbox1": "Valintaruutu, jolla voit sallia tai estää asiakkailta tämän valikkokohdan erityisohjeet.",
    "menu_c": "VALIKKO",
    "past_design": "Mennyt suunnittelu",
    "file_name": "Tiedoston nimi",
    "variation_name": "Muunnelman nimi",
    "grid_items_title1": "Pöytä teltta",
    "grid_items_title2": "Digitaalinen valikko",
    "grid_items_title3": "Takeout-valikko",
    "grid_items_title4": "Puolisivuinen valikko",
    "grid_items_title5": "Digitaalinen valikko 5",
    "grid_items_title6": "Digitaalinen valikko 6",
    "grid_items_title7": "Digitaalinen valikko 7",
    "grid_items_title8": "Digitaalinen valikko 8",
    "grid_items_title9": "Digitaalinen valikko 9",
    "enter_valid_form_name": "Anna kelvollinen lomakkeen nimi",
    "form_name_required": "Lomakkeen nimi vaaditaan",
    "enter_valid_section": "Anna kelvollinen osion otsikko",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Lomakkeen nimi",
    "contact_list": "Yhteystiedot",
    "label_notify_email": "Haluatko saada ilmoituksen sähköpostitse?",
    "add_client_details_section": "Lisää Asiakkaan tiedot -osiota",
    "demo_purpose_only": "Se on tarkoitettu vain demokäyttöön",
    "form_updated_success": "lomake päivitetty onnistuneesti",
    "form_creat_success": "lomake luotu onnistuneesti",
    "add_custom_section": "Lisää mukautettu osiota",
    "edit_custom_section": "Muokkaa mukautettua osiota",
    "forms_list": "Lomakkeiden luettelo",
    "filled_custom_form_details": "Täytetty mukautetun lomakkeen tiedot",
    "custom_form_deletion_warning": "Tämä mukautettu lomake poistetaan pysyvästi"
};

export default fi