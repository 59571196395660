// Initial value of the variable
let variableValue = "";

// Array to hold callback functions subscribed to variable changes
const subscribers:any = [];

// Function to update the variable value
export const updateVariableValue = (newValue:any) => {
    variableValue = newValue;
    // Notify all subscribers about the change
    subscribers.forEach((callback:any) => callback(variableValue));
};

// Function to get the current value of the variable
export const getCurrentVariableValue = () => {
    return variableValue;
};

// Function to subscribe to variable changes
export const subscribeToVariableChanges = (callback:any) => {
    // Add the callback to the list of subscribers
    subscribers.push(callback);
    // Return a function to unsubscribe
    return () => {
        const index = subscribers.indexOf(callback);
        if (index !== -1) {
            subscribers.splice(index, 1);
        }
    };
};
