const ja = {
    "rated_google_reviews": "Google レビューで 5.0 の評価",
    "get_your_digital_menu": "デジタルメニューを入手しましょう—",
    "instant_popup_menu": "QRスキャンなしですぐにポップアップメニューを表示",
    "free_forever": "永遠に無料",
    "the_future_is_here": "未来はここにあります!",
    "upgrade_your_restaurant": "魅力的なデジタル メニューを無料で提供して、レストランをアップグレードしましょう。今日からより多くの顧客を引き付けましょう。",
    "get_my_free_menu_now": "今すぐ無料メニューを入手",
    "no_credit_card_required": "*クレジットカードは不要です",
    "join_largest_marketplace": "最大のレストランマーケットプレイスに参加しましょう！",
    "what_will_you_get": "何が得られますか",
    "free": "無料",
    "free_digital_menu": "無料のデジタルメニュー 2D または 3Dまたは",
    "free_digital_menu_desc": "紙のメニューと高価な再印刷に別れを告げましょう",
    "quick_and_easy_signup": "素早く簡単なサインアップ",
    "communicate_with_customers_title": "顧客と無料でコミュニケーション！",
    "features1": [
        "印刷コストを節約し、必要なときにいつでも即座にメニューを更新できます。",
        "あらゆるデバイスからデジタルメニューにアクセス",
        "環境に優しく、手間がかからない"
    ],
    "features2": [
        "素晴らしい 3D ビジュアルで、顧客に仮想的に料理を体験してもらいましょう。",
        "食事をする人に実際の食事のプレビューを提供することでエンゲージメントを高めます。",
        "食べ物の味と同じくらい魅力的なメニュー体験で、他と差をつけましょう。"
    ],
    "features3": [
        "お客様がレストランに入るとすぐにメニューでお迎えします。",
        "特別セールやプロモーションの最新情報を携帯電話に自動的に送信します。",
        "メニューに素早く非接触でアクセスして、食事の体験を向上させます。"
    ],
    "feature4": [
        "季節限定のオファー、日替わりスペシャル、限定プロモーションを強調します。",
        "最も人気のある商品に顧客の注目を集めましょう。",
        "目を引くビジュアルと説明で緊急性と興奮を生み出します。"
    ],
    "feature5": [
        "毎日何千人もの訪問者がいるオンライン コミュニティに参加しましょう。",
        "認知度を高め、積極的に食事の選択肢を探している新規顧客にリーチします。",
        "露出を最大限に高め、より多くの顧客を引き付けるために、早めにトップスポットを確保しましょう。"
    ],
    "section3_heading": "なぜデジタル化するのか？それは無料で簡単だからです。",
    "section3_description": "レストランの魅力を高めるために設計された強力な機能を活用できます。",
    "instant_menu": "インスタントメニュー",
    "instant_menu_notification": "ポップアップと通知",
    "instant_menu_description": "入店するとすぐにメニューにアクセスしてゲストをお迎えします。最新のメニューと特別メニューをゲストの携帯電話に自動的に直接送信します。",
    "digital_menu": "デジタルメニュー",
    "at_zero_cost": "ゼロコストで",
    "digital_menu_description1": "紙のメニューや高価な再印刷はもう必要ありません。常に最新のデジタル メニューに切り替えましょう。しかも永久に無料です。",
    "digital_menu_description2": "当社のデジタル メニューを利用すれば、提供するメニューは常に最新の状態になり、どのデバイスからでもアクセスできます。シームレスでコストゼロのソリューションでコストを節約し、メニューの更新を簡素化できます。",
    "try_me_button": "試してみて",
    "try_3d_menu_title": "3D メニュー",
    "try_3d_menu_title2": "無料で—すごい！",
    "try_3d_menu_description1": "料理が画面から飛び出すような演出を。3D 画像をアップロードして、お客様が店に入る前に仮想的に料理を味わってもらうことができます。",
    "try_3d_menu_description2": "高品質の 3D 画像により、魅力的なプレビューが作成され、魅力が高まり、注文が促進されます。",
    "try_now": "今すぐ試す",
    "showcase_title": "あなたの",
    "showcase_specials": "スペシャル",
    "showcase_specials_description1": "魅力的なお買い得品や、他にはないプロモーションがありますか? デジタル メニューで強調表示して、お腹を空かせた人々をもっと引き付けましょう。",
    "showcase_specials_description2": "大胆なビジュアルと魅力的な説明で注目を集め、見逃せないプロモーションを実現します。",
    "marketplace": "市場",
    "marketplace_description": "私たちは、毎日 2,000 人以上の訪問者がいるマーケットプレイスを立ち上げています。今すぐ参加すれば、開始時に真っ先に並ぶことができます。積極的に食事の選択肢を探している新しい顧客にリーチできます。露出を最大限に高めるために、早めに場所を確保してください。",
    "see_other_products": "他の製品を見る",
    "about_us": "私たちについて",
    "why_serving_free": "なぜこれを提供するのか",
    "serving_free_highlight": "無料",
    "about_desc": "私たちはここダラスの地元民であり、あなたのようなレストランが繁栄できるよう支援することに尽力しています。",
    "about_tool_free": "私たちはコミュニティをサポートすることに全力を尽くしているため、この強力なツールを完全に無料で提供しています。",
    "no_catch": "嘘も冗談もありません。あなたが成功すれば、私たちのコミュニティは強くなることを私たちは知っています。😊",
    "understanding_challenges": "当社は、ビジネスを運営する上での課題を理解しています。そのため、業務を円滑にし、リーチを拡大するツールの提供に注力しています。",
    "community_growth": "あなたの成長に合わせて、私たちのコミュニティも成長し、成功とサポートの波及効果を生み出します。一緒に、みんなの食事体験を向上させましょう。",
    "how_it_works": "仕組み",
    "three_simple_steps": "3つの簡単なステップ",
    "get_started_highlight": "始める",
    "no_tech_skills_needed": "技術的なスキルは必要ありません。ハンバーガーをひっくり返すことができれば、これもできます!",
    "step1_title": "プロモーションを無料で表示しましょう!",
    "step1_desc": "「注文してください！」と言うよりも早く始められます。簡単なフォームに記入するだけです。",
    "step2_title": "メニューをカスタマイズする",
    "step2_desc": "あなたの料理を追加し、3D でも写真をアップロードして、あなたのスペシャル料理をハイライトしましょう。",
    "step3_title": "シェアして成長する",
    "step3_desc": "QR、ソーシャル メディア、または Web サイト経由でデジタル メニューを共有します。",
    "testimonials": "お客様の声",
    "testimonials_section_title": "私たちの言うことを鵜呑みにしないでください",
    "testimonials_section_desc": "ダラスの他のレストランオーナーの意見をご覧ください。",
    "faq_section_title": "ご質問がありますか？答えがあります",
    "faq_section_desc": "始める前に知っておくべきことすべて。",
    "faqs": "よくある質問",
    "faq_answers": [
        {
            "que": "本当に永久に無料ですか？",
            "ans": "もちろんです！隠れた手数料やクレジットカード、条件は一切ありません。"
        },
        {
            "que": "技術的な知識は必要ですか?",
            "ans": "電子メールを送信できる場合は、これを行うことができます。とても簡単です。"
        },
        {
            "que": "なぜこれを無料で提供しているのですか?",
            "ans": "私たちはまず与えることを信条としています。将来的には、お客様に喜んでいただける他のサービスも提供できるかもしれませんが、それは完全にお客様次第です。"
        },
        {
            "que": "マーケットプレイスとは何ですか?",
            "ans": "準備中です! 十分な数のレストランが参加したら、マーケットプレイスを立ち上げて、さらなる露出を目指します。"
        }
    ],
    "testimonials_content1": "デジタル メニューへの切り替えは、私たちにとって最高の決断でした。お客様はその利便性と最新のデザインを気に入ってくださっています。",
    "testimonials_name1": "モリス・ジョージ",
    "testimonials_restaurant_name1": "ザ・マンションレストラン",
    "testimonials_content2": "新しいデジタル メニューにより、ゲストの閲覧が簡単になり、エンゲージメントが大幅に向上しました。",
    "testimonials_name2": "アメリア・ジョンソン",
    "testimonials_restaurant_name2": "アーバンイーツ",
    "testimonials_content3": "3D ビジュアルにゲストは驚いています。3D ビジュアルは私たちの存在を際立たせ、ゲストの食事体験を向上させるのに役立っています。",
    "testimonials_name3": "デイビス・グリーン",
    "testimonials_restaurant_name3": "タウンハース",
    "ready_to_boost_your_restaurant": "レストランを盛り上げる準備はできていますか?",
    "ready_to_boost_desc": "取り残されないでください。今すぐデジタル革命に参加しましょう!",
    "yes_i_want_my_free_digital_menu": "はい、無料のデジタルメニューを希望します",
    "480_plus": "480+",
    "people_already_joined": "すでに参加している人",
    "menu_online_home": "メニュー オンラインホーム",
    "welcome": "いらっしゃいませ",
    "description_other": "顧客満足度を高めましょう！",
    "benefits": "利点",
    "greeting_text": "ようこそ👋",
    "all_in_one_features": "🚀 レストランのレベルを上げるオールインワン機能",
    "menu_online_menu_name": "メニューオンライン",
    "menu_online_description": "メニューをデジタル化して、顧客がオンラインで簡単にアクセスできるようにします。",
    "menu_online_benefits": "いつでもどこでもメニューを閲覧できるようにすることで、顧客の利便性を高め、エンゲージメントを高めます。",
    "ds_name": "デジタルサイネージ",
    "ds_description": "店内のダイナミックなスクリーンでオファーやプロモーションを紹介します。",
    "ds_benefits": "魅力的なビジュアルで顧客の注目を集め、アップセルやリアルタイムのオファーを簡単に促進します。",
    "loyalty_punch_name": "ロイヤルティ パンチカード",
    "loyalty_punch_description": "デジタル ロイヤルティ プログラムでリピーター顧客に報酬を与えます。",
    "loyalty_punch_benefits": "頻繁な来店に対して交換可能な特典を提供して顧客ロイヤルティを奨励することで、顧客維持を促進し、売上を増加させます。",
    "marketing_menu_name": "マーケティング",
    "marketing_description": "ターゲットを絞ったデジタル マーケティング キャンペーンで、より多くの顧客にリーチします。",
    "marketing_benefits": "新規顧客やリピーターを引き付けるためにカスタマイズされたプロモーションで、レストランのリーチを拡大し、来店者数を増やします。",
    "get_more_reviews_menu_name": "レビューをもっと見る",
    "get_more_reviews_description": "満足した顧客に肯定的なオンラインレビューを残すように促します。",
    "get_more_reviews_benefits": "より多くの肯定的なレビューでオンラインの評判を高め、レストランの信頼性を高めて新しい顧客を引き付けましょう。",
    "guest_smart_wifi_menu_name": "ゲストスマートWifi",
    "guest_smart_wifi_description": "マーケティング用にゲストデータを収集するために無料 Wi-Fi を提供します。",
    "guest_smart_wifi_benefits": "貴重な顧客データを収集しながら顧客満足度を高め、将来のプロモーションをパーソナライズします。",
    "reservation_menu_name": "予約",
    "reservation_description": "顧客がオンラインで簡単にテーブルを予約できるようにします。",
    "reservation_benefits": "待ち時間を最小限に抑えて食事の体験を向上させ、予約を合理化して座席をより適切に管理します。",
    "pos_integration_menu_name": "POS統合",
    "pos_integration_description": "当社のシステムをお客様の POS システムに統合します。",
    "pos_integration_benefits": "オンライン システムと店舗システムを同期させることで、注文処理を簡素化し、エラーを削減します。",
    "online_ordering_menu_name": "オンライン注文",
    "online_ordering_description": "顧客がウェブサイトまたはアプリから直接注文できるようにします。",
    "online_ordering_benefits": "手数料を節約できる直接注文オプションを提供することで、売上を増やし、サードパーティのプラットフォームへの依存を減らします。",
    "pro_label": "プロ",
    "coming_soon": "近日公開",
    "locked_label": "ロックされています",
    "forever_label": "永遠に",
    "new_label": "新しい",
    "other_products_page_title": "その他の商品 | オンラインメニュー",
    "other_products_description": "メニュー オンラインプライバシーポリシー",
    "other_products_header_title": "その他の製品",
    "privacy_policy": {
        "title": "プライバシーポリシー",
        "lastUpdated": "最終更新日: 2024年7月1日",
        "introduction": {
            "header": "新規および既存の顧客向けプライバシーポリシー",
            "paragraph1": "以下の要点（セクション参照を含む）は、お客様の便宜のためにのみ提供されており、完全な利用規約に代わるものではありません。MENUONLINE INC サービスをご利用になる前に、これらの利用規約をお読みいただくのはお客様の責任となります。",
            "paragraph2": "Menuonline を使用するということは、利用規約に同意し、組織または会社から許可されていることを表明し保証することを意味します。(第 3 項「仕組み」を参照)",
            "paragraph3": "Menuonline Inc は許可ベースのマーケティング サービスです。故意に「スパム」を送信したり、利用規約に違反したりすると、アカウントが閉鎖されます。(第 16 項「サービスの許容される使用」を参照)",
            "paragraph4": "連絡先記録、電子メールの内容、Menuonline Inc を通じて収集した情報など、Menuonline Inc でホストするデータは、お客様に帰属します。(第 14 項「お客様の所有物は何ですか?」を参照)。",
            "paragraph5": "Menuonline Inc のソフトウェアおよび当社ウェブサイト上のコンテンツは当社に帰属します。(第 13 条「当社が所有するもの」を参照) お客様自身のユーザーを除き、Menuonline Inc でホストするデータまたは未公開コンテンツは、機密保持契約に署名した Menuonline Inc の従業員およびパートナーのみが閲覧できます。(第 14 条「お客様が所有するもの」を参照)",
            "paragraph6": "すべての個人識別情報 (PII) は、カナダにあるサーバーにのみ保存されます。(第 14 項「何を所有しますか?」を参照)。",
            "paragraph7": "Menuonline Inc のサービスは、カナダおよびオンタリオ州の法律に準拠します。（第 24 条「準拠法」を参照）",
            "paragraph8": "カナダ国内またはカナダ国外の個人、警察、その他の規制当局によるお客様のデータまたはコンテンツに関する要求は、常にお客様に照会されます。唯一の例外は、これがカナダまたはオンタリオ州の法律に違反することになる場合です。ただし、いかなる場合でも、お客様以外の者によるお客様のデータの公開には断固として抵抗します。（第 14 条「お客様の所有物は何ですか?」を参照）。",
            "paragraph9": "Menuonline Inc のセキュリティは、ユーザー名とパスワードの管理によってのみ向上します。Menuonline Inc のユーザー アカウントは無料ですので、ユーザーごとにアカウントを作成し、パスワードを秘密にしてください。アカウントの責任はユーザーにあります。(「仕組み」のセクション 3 を参照)",
            "paragraph10": "アカウントはいつでもキャンセルできます。キャンセルした場合、未使用のクレジットの払い戻しは行われません。（第3項「仕組み」を参照）"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC 利用規約",
            "lastUpdate": "最終更新日: 2020年1月14日",
            "paragraph1": "このウェブサイト（以下「サイト」）を使用する前に、これらの利用規約（以下「規約」）をよくお読みください。これらの規約は、Menuonline Inc.（以下「Menuonline Inc」または「当社」）およびその他の者の責任を免除し、または当社およびそれらの者の責任を制限するものであり、お客様によるこのサイトの使用に適用されるその他の重要な規定が含まれています。このサイトまたはサービスを訪問または使用することにより、お客様は、お客様自身、およびお客様が代理を務める組織（以下総称して「お客様」）に代わって、このサイトの使用および訪問ごとにこれらの規約を受け入れ、遵守することに同意するものとします。"
        },
        "services": {
            "title": "当社のサービス。",
            "paragraph": "当サイトを通じて提供するサービスは、お客様が選択した受信者への電子メール メッセージ (以下「電子メール」) を作成、送信、管理できるサービスです (以下「サービス」)。"
        },
        "license": {
            "title": "ライセンス。",
            "paragraph": "本規約に従い、Menuonline Inc は、本規約期間中、本サイトの閲覧および本サービスの機能により許可される範囲でのサービスの利用について、非独占的、限定的、譲渡不可のライセンスをお客様に付与します。当社は、本サービスおよび Menuonline Inc コンテンツ (以下に定義) に関して、本規約で明示的に付与されていないすべての権利を留保します。"
        },
        "emailingContent": {
            "title": "コンテンツを電子メールで送信します。",
            "paragraph": "このサイトまたはサービスを通じて Menuonline Inc とやり取りする場合、お客様は真実かつ正確な情報を提供することに同意するものとします。また、電子メールで機密情報を提供することは安全ではないことを理解するものとします。お客様は、当社のサービスを利用して送信するあらゆる情報に対して、単独で責任を負うことを理解するものとします。"
        },
        "support": {
            "title": "サポート。",
            "paragraph": "当社は、お客様が本サービスを自力で利用できるようサポートを提供します。当社は、本サイト上の文書やビデオ、Menuonline Inc サポート チケット システム、および電話によるサポートを提供します。お客様は、本サイトの上部にある「サポート」タブをクリックして当社に連絡できます。製品サポートは無料ですが、当社は独自の判断で特定のクライアント アカウントのサポートを中止する権利を留保します。"
        }
    },
    "terms_condition": {
        "title": "利用規約 | メニューオンライン",
        "header": "新規および既存のお客様向けの利用規約"
    },
    "privacy_policy_title": "プライバシーポリシー",
    "privacy_policy_effective_date": "2020年1月16日より有効",
    "privacy_policy_general_title": "一般的なプライバシーポリシー",
    "privacy_policy_intro": "menuonline.com (Menuonline および「当社」) は、個人の個人情報のプライバシー保護とあらゆる種類の機密情報のセキュリティの重要性を理解しています。当社は、お客様の「プライバシー」を保護するという当社の取り組みを示すために、このプライベート ポリシー (「ポリシー」) を作成しました。このプライベート ポリシーでは、次の内容について説明します。",
    "privacy_policy_points": [
        "当社が収集する個人情報の内容とその理由。",
        "当社がお客様の個人情報をどのように使用するか",
        "個人情報を共有する相手",
        "サービスから個人情報にアクセスし、更新し、削除するための選択肢。"
    ],
    "privacy_policy_pipeda": "Menuonline は、カナダの個人情報保護および電子文書法 (PIPEDA) に従ってすべての情報の収集を管理します。",
    "privacy_policy_update_notice": "Menuonline は、このプライベート ポリシーをいつでも更新または変更する権利を留保します。変更があった場合は、更新されたポリシーをこの Web サイトに掲載するか、サービスを通じて公開することでお知らせします。このポリシーの最新バージョンが、mycircle によるユーザーの個人情報の使用に適用されます。このプライベート ポリシーの変更後も、mycircle の Web サイトにアクセスしたり、そのサービスを使用し続けることで、改訂された条件に同意することになります。",
    "privacy_policy_consent_title": "個人情報に関する同意",
    "privacy_policy_consent_text": "当社のウェブサイトまたはいずれかのフォームを通じてMenuonlineに個人情報を提供することにより、お客様は、本ポリシーに従って、または個人情報を提供した時点でお客様に明示された目的のために、かかる情報の収集、使用、開示に同意するものとします。個人情報の二次使用は、お客様の明示的な同意がある場合にのみ処理され、同意しない場合は、オプトアウトの機会が提供されます。",
    "privacy_policy_opt_out_text": "同意を撤回したり、オプトアウトしたりする機会はいつでもあります。同意を撤回したり、オプトアウトしたりしたい場合は、当社から受信した電子メールの下部にある通信設定リンクをクリックしてください。",
    "privacy_policy_info_collection_title": "収集する情報",
    "privacy_policy_info_collection_text": "Menuonline は、本ポリシーに従って個人情報を収集し、処理します。本ポリシーで説明されている場合を除き、当社はこの情報を他者に販売、共有、または貸与することはありません。Menuonline は、お客様に当社のサービスまたは製品を提供する目的で個人情報を収集します。",
    "privacy_policy_info_types_text": "Menuonline が収集する情報の種類は次のとおりです。",
    "privacy_policy_account_holders_title": "Menuonlineアカウント保有者",
    "privacy_policy_registration_info": "登録情報: サービスの利用にサインアップする場合、アカウント所有者 (「アカウント所有者」) になるために Menuonline アカウントが必要です。アカウントを登録する際、名前、電子メール アドレス、ユーザー名、パスワード、会社名、職業、所在地、電話番号などの特定の基本情報を入力するよう求められます。パスワードは暗号化されており、Menuonline のスタッフはパスワードを表示または取得できません。",
    "privacy_policy_billing_info": "請求情報: 当社のサービスをご利用になる場合は、お支払い情報と請求情報を当社に提供していただく必要があります。",
    "privacy_policy_cookies_info": "クッキー: 当社は、ウェブサイト訪問元や当社ウェブサイトへの訪問回数などの訪問者データを追跡するためにクッキーを使用します。",
    "privacy_policy_ip_info": "IP アドレス: IP アドレスは、サービスの使用の一環として収集されます (例: 当社のサービスの使用時に使用された国レベルの場所と IP アドレスを記録するため)。",
    "privacy_policy_your_contacts_title": "あなたの連絡先",
    "privacy_policy_distribution_list": "配信リスト: お客様が当社のサービスをご利用になる際、お客様は当社に連絡先 (以下「連絡先」) に関する個人情報を提供したり、当社がサービスの一環として収集したりすることがあります。この情報には電子メール アドレスが含まれ、名前、会社名、またはお客様がサービス使用中に Menuonline に提供するその他の情報など、その他の識別可能な情報が含まれる場合があります。連絡先の情報を当社が処理できるように、連絡先から適切な同意を得る必要があります。",
    "privacy_policy_billing_info_contacts": "請求情報: 当社のサービスをご利用になる場合は、お支払い情報と請求情報を当社に提供していただく必要があります。",
    "privacy_policy_cookies_contacts": "クッキー: Menuonline は、ウェブサイトに Menuonline トラッキング コードを追加した場合にのみ、クッキーや同様のトラッキング テクノロジーを使用して連絡先に関する個人情報を収集および使用します。Menuonline がホストするランディング ページには、自動的にトラッキング コードが設定されます。",
    "privacy_policy_ip_contacts": "IP アドレス: サービスの一環として、連絡先の IP アドレスが収集されます (例: 国レベルの場所や、フォームの送信時や同意の提供時に使用された IP アドレスを記録するため)。",
    "privacy_policy_info_about_contacts_title": "お客様の連絡先について収集する情報",
    "privacy_policy_info_about_contacts_notice": "*このセクションは、Menuonlineがサービスを提供する目的でアカウント所有者の連絡先について収集、使用、または開示する情報にのみ適用されます*",
    "privacy_policy_info_about_contacts_desc": "Menuonline は、アカウント所有者が特定のサービスに登録し、配信リストを提供している場合にのみ、連絡先に関する個人情報を収集、使用、または開示します。アカウント所有者は、サービスを通じて連絡先に関する個人情報を Menuonline に提供する場合、適用されるすべての法律に準拠していることを確認する責任を単独で負います。",
    "privacy_policy_info_auto_collect_title": "Menuonlineが自動的に収集する情報",
    "privacy_policy_info_auto_collect_desc1": "アカウント所有者の連絡先がアカウント所有者から受信した電子メール キャンペーンに参加したり、アカウント所有者の接続 Web サイトへのリンクを操作したりすると、Menuonline は Cookie を使用して、その連絡先のデバイスと電子メールの操作に関する情報を収集する場合があります。この情報は、サービスを提供し、アカウント所有者に洞察を提供するためにのみ収集されます。",
    "privacy_policy_info_auto_collect_desc2": "連絡先のデバイスおよび連絡先が当社のサービスを通じてアカウント所有者から送信された電子メールにアクセスするために使用するアプリケーションについて当社が収集する情報には、連絡先の IP アドレス、オペレーティング システム、ブラウザ ID、および連絡先のシステムと接続に関するその他の関連情報が含まれる場合があります。",
    "privacy_policy_info_auto_collect_desc3": "サービスを通じて送信された電子メールに対する連絡先のやり取りに関して当社が収集する情報には、連絡先が電子メールにアクセスした日時や閲覧アクティビティ、およびサービスを通じてアカウント所有者から連絡先に送信された電子メールやその他の通信の配信可能性などのサービスのパフォーマンスに関する情報が含まれる場合があります。",
    "privacy_policy_info_auto_collect_desc4": "適用法で義務付けられている場合を除き、Menuonline はお客様の連絡先に連絡しませんのでご了承ください。",
    "privacy_policy_why_we_collect_title": "当社がお客様の情報を収集する理由",
    "privacy_policy_why_we_collect_desc": "Menuonline は以下の目的で情報を収集します。",
    "privacy_policy_why_collect_1": "Menuonline アカウント所有者に、サービスのためにアカウント所有者情報を使用することを含め、加入しているサービスを提供するため。",
    "privacy_policy_why_collect_2": "管理およびアカウント管理、ならびに mycircle の Web サイトへのアクセスのため。",
    "privacy_policy_why_collect_3": "マーケティングおよびプロモーション資料の受信に同意した場合、Menuonlineが提供するその他の製品やサービスについてお知らせするため。",
    "privacy_policy_why_collect_4": "アカウント所有者の連絡に応答するため、またはお客様から要求された情報を送信するため。これには以下が含まれますが、これらに限定されません。",
    "privacy_policy_why_collect_5": "特別オファーとアップデート。",
    "privacy_policy_why_collect_6": "ニュースレター",
    "privacy_policy_why_collect_7": "サービスに関するお知らせ。",
    "privacy_policy_opt_out_desc": "Menuonline から送信される各電子メールの下部にある配信停止ボタンをクリックすることで、いつでも通信の受信を停止することができます。",
    "privacy_policy_use_disclosure_title": "個人情報の利用と開示",
    "privacy_policy_use_disclosure_desc1": "Menuonline の Web サイトまたは所定のフォームを通じて提供された個人情報は、アカウント所有者の明示的な同意または法的要件に従って開示されない限り、厳重に機密扱いされます。いずれの場合も、アカウント所有者が「Menuonline」プラットフォームで提供する個人情報は任意であり、アカウント所有者が提供する個人情報はアカウント所有者の裁量で送信されます。同意を取り消すと、特定の Menuonline サービスにアクセスしたり、利用したりできなくなる可能性があることにご注意ください。",
    "privacy_policy_use_disclosure_desc2": "Menuonline は、ウェブサイトおよびサービスに関して、第三者および外部サービス プロバイダーを使用します。アカウント所有者の個人情報は、このような第三者およびプロバイダーと共有されることがあります。また、アカウント所有者、販売、トラフィック パターン、および関連情報についての集計統計を信頼できる第三者に提供することもあります。Menuonline では、個人情報にアクセスできるすべての第三者プロバイダーがこのプライバシー ポリシーに準拠する義務を負うこと、およびこのようなプロバイダーがアカウント所有者の個人情報を収集、使用、または開示するのは Menuonline サービスを提供する目的のみであることを要求しています。",
    "privacy_policy_use_disclosure_desc3": "アカウント所有者から提供された個人情報は、お客様の明示的または黙示的な同意がない限り、外部の組織に販売、共有、または貸し出されることはありません。Menuonline は、情報を転送したり、質問に回答したり、プログラムやポリシーの変更を通知したりするために、アカウント所有者に連絡する権利を留保します。適用法で義務付けられている場合を除き、Menuonline はお客様の連絡先に連絡することはありませんのでご了承ください。",
    "privacy_policy_use_disclosure_desc4": "Menuonline は、関係する個人から同意を得た場合、または以下の場合にのみ個人情報を開示します。",
    "privacy_policy_disclosure_list_1": "召喚状やその他の法的手続きへの対応など、法律で許可または要求されている場合。",
    "privacy_policy_disclosure_list_2": "Menuonline の全部または一部の譲受人へ;",
    "privacy_policy_disclosure_list_3": "このポリシーに従ってMenuonlineのサービスプロバイダーに提供するか、",
    "privacy_policy_disclosure_list_4": "規制報告義務を含むがこれに限定されない法的義務を遵守するため。",
    "privacy_policy_service_providers_title": "Menuonline は以下の第三者およびサービス プロバイダーを使用します (連絡先の個人情報は「このような」第三者に開示されません)。",
    "privacy_policy_service_provider_1": "当社では、ManyChat を使用して、販売およびサポート用の Web サイト チャット サービスや、これらの会話に関するフォローアップ メールを送信しています。",
    "privacy_policy_service_provider_2": "当社では、お客様が受けているサービスとサポートの品質について質問する目的で AskNicely を使用してアンケートを実施しています。",
    "privacy_policy_service_provider_3": "当社では、顧客および見込み顧客との会議、サポートコール、ソフトウェアのデモをスケジュールするために join.me を使用しています。",
    "privacy_policy_service_provider_4": "当社は、Google Analytics を使用して、匿名のウェブサイトアクティビティを追跡し、ウェブサイトの使用パターンを測定します。",
    "privacy_policy_breach_notification_title": "違反通知",
    "privacy_policy_breach_notification_desc": "Menuonline は、個人情報の不正使用、アクセス、開示に関して適用される法律を遵守し、アカウント保有者に合理的な支援を提供します。",
    "privacy_policy_rights_access_title": "あなたの権利とアクセス",
    "privacy_policy_rights_access_desc": "お客様は、Menuonline がお客様に関して処理する個人情報にアクセスする権利を有します。お客様は、当社に対して以下の情報を要求できます。",
    "privacy_policy_rights_access_list_1": "当社が保有するお客様の個人情報",
    "privacy_policy_rights_access_list_2": "対象となる個人情報の種類",
    "privacy_policy_rights_access_list_3": "処理の目的",
    "privacy_policy_rights_access_list_4": "あなたの個人情報が開示される相手の詳細",
    "privacy_policy_rights_access_list_5": "個人情報の保管期間",
    "privacy_policy_rights_access_list_6": "当社がお客様から直接情報を収集しなかった場合、情報源に関する情報",
    "privacy_policy_rights_access_list_7": "適切な監督機関に苦情を申し立てる方法",
    "privacy_policy_rights_access_list_8": "以下の事項を弊社にリクエストすることもできます:",
    "privacy_policy_rights_access_list_9": "お客様に関する不完全または不正確な情報を更新すること",
    "privacy_policy_rights_access_list_10": "適用法に従って個人情報を削除するよう要求する",
    "privacy_policy_rights_access_contact": "2851 Dufferin Street, Toronto, ON, Canada までご連絡いただくか、 {link}までメールを送信していただくことで、お客様の権利の行使を要請することができます。",
    "privacy_policy_accountability_title": "説明責任",
    "privacy_policy_accountability_desc": "Menuonline は、お客様から提供された個人情報および機密情報に対して責任を負うことに尽力しています。連絡先情報については、このポリシーの最後をご覧ください。",
    "privacy_policy_links_other_sites_title": "他のサイトへのリンク",
    "privacy_policy_links_other_sites_desc1": "Menuonline の Web サイトには、他のサイトへのリンクが含まれている場合があります。Menuonline は他のサイトのプライバシー保護について責任を負わないことにご注意ください。アカウント所有者は、当社のサイトを離れる際には、個人情報を収集するすべての Web サイトのプライバシー ポリシー ステートメントを注意深く読む必要があることに留意してください。",
    "privacy_policy_links_other_sites_desc2": "このプライバシーポリシーは、当社が収集した情報にのみ適用されます。",
    "privacy_policy_links_other_sites_desc3": "Menuonline サイトからリンクを使用して製品またはサービスを購入する場合、Menuonline ではなく、ベンダー会社の規則と規制に従って購入取引を完了することになります。",
    "privacy_policy_cookies_title": "プライバシーポリシー クッキー",
    "privacy_policy_cookies_desc1": "Menuonline の Web サイトおよびサービスでは、このポリシーに従って特定の情報を収集および保存するために Cookie を使用します。「Cookie」とは、Web サイトから送信され、ユーザーの Web ブラウザーによってユーザーのコンピューターに保存される小さなテキスト ファイルです。Cookie を使用するサイトにアクセスすると、Cookie がコンピューターまたはモバイル デバイスにダウンロードされます。次回そのサイトにアクセスすると、設定、アクセスしたページ、ログイン オプションなどの役立つ情報がデバイスに記憶されます。",
    "privacy_policy_cookies_desc2": "クッキーは、ウェブサイトに機能を追加したり、ウェブサイトの効率を高めるために広く使用されています。当サイトは、ユーザーエクスペリエンスを最適化し、サイトサービスが適切に機能するようにクッキーを使用しています。",
    "privacy_policy_cookies_desc3": "ほとんどのウェブブラウザでは、ブラウザ設定を通じてクッキーを制限またはブロックする制御が可能です。ただし、クッキーを無効にすると、当社のウェブサイトまたはサービスの特定の部分を使用できなくなる場合があります。",
    "privacy_policy_cookies_analytical_purpose": "<strong>分析目的: <\/strong>当社は、ウェブサイトを改善するために、アカウント所有者のアクティビティを分析するために Cookie を使用します。たとえば、アカウント所有者が使用する機能などの集計パターンを確認するために Cookie を使用できます。このような分析を使用して、ウェブサイトの機能とユーザー エクスペリエンスを改善する方法についての洞察を得ることができます。",
    "privacy_policy_cookies_preferences_user_experience": "<strong>お客様の設定とユーザー エクスペリエンス: <\/strong>当社は、アカウント所有者のエクスペリエンスの一貫性と利便性を高めるために、当社の Web サイトでアカウント所有者の設定を保存するために、ブラウザーの種類、サーバー、言語設定、国設定など、訪問者とアカウント所有者に関する特定の情報を収集するために Cookie を使用します。当社は、お客様が当社の Web サイトに繰り返しアクセスしたときに、ログイン状態を維持するために Cookie を使用します。",
    "privacy_policy_cookies_targeted_ads": "<strong>測定サービスとよりターゲットを絞った広告およびマーケティングの提供: <\/strong>当社は、測定サービス、よりターゲットを絞った広告、およびマーケティングの目的で、Google や Facebook などのサードパーティ パートナーの Cookie、Web ビーコン、およびその他のストレージ技術を使用します。これは、お客様が当社の Web サイトにアクセスしたときに行われます。これらの Cookie、Web ビーコン、およびその他のストレージ技術により、インターネット上でお客様がアクセスする他のサイトで Menuonline のプロモーション資料を表示できます。",
    "privacy_policy_security_title": "安全",
    "privacy_policy_security_desc1": "Menuonline は、情報の機密性に応じた組織的、物理的、機械的、電子的保護手段を講じて、個人情報を含むあらゆる種類の機密情報を保護するよう努めています。当社のサーバーはカナダにあり、セキュリティに関する業界標準に従っています。",
    "privacy_policy_security_desc2": "ただし、当社が維持する対策が情報のセキュリティを保証するものではないことをご了承ください。",
    "privacy_policy_retention_title": "保持",
    "privacy_policy_retention_desc": "当社は、本プライバシーポリシーに定める目的のために必要な期間、および利用規約の保持規定に従って、アカウント所有者および連絡先に関する個人情報を保持します。",
    "privacy_policy_access_questions_title": "アクセス、質問、懸念事項",
    "privacy_policy_access_questions_desc": "Menuonline に個人情報を提出した個人は、Menuonline が保持する個人情報へのアクセスまたは修正を希望する場合は、書面によるリクエストを提出することができます。",
    "terms_obj": {
        "terms_conditions_title": "利用規約",
        "terms_conditions_subtitle": "新規および既存のお客様向けの利用規約",
        "terms_conditions_desc1": "以下の要点（セクション参照を含む）は、お客様の便宜のためにのみ提供されており、完全な利用規約に代わるものではありません。Menuonline サービスをご利用になる前に、これらの利用規約をお読みいただくのはお客様の責任となります。",
        "terms_conditions_desc2": "Menuonline を使用するということは、利用規約に同意し、組織または会社から許可されていることを表明し保証することを意味します。(第 3 項「仕組み」を参照)",
        "terms_conditions_desc3": "Menuonline は許可ベースのマーケティング サービスです。故意に「スパム」を送信したり、利用規約に違反したりすると、アカウントが閉鎖されます。(第 16 項「サービスの許容される使用」を参照)",
        "terms_conditions_desc4": "連絡先記録、電子メールの内容、Menuonline を通じて収集した情報など、Menuonline でホストするデータは、お客様の所有物となります。(第 14 項「お客様の所有物は何ですか?」を参照)。",
        "terms_conditions_desc5": "Menuonline ソフトウェアおよび当社ウェブサイト上のコンテンツは当社に帰属します。（第 13 項「当社が所有するものは何ですか?」を参照）",
        "terms_conditions_desc6": "お客様自身のユーザーを除き、Menuonline でホストするデータまたは未公開コンテンツは、機密保持契約に署名した Menuonline の従業員およびパートナーのみが閲覧できます。(第 14 項「お客様の所有物は何ですか?」を参照)。",
        "terms_conditions_desc7": "すべての個人識別情報 (PII) は、カナダにあるサーバーにのみ保存されます。(第 14 項「何を所有しますか?」を参照)。",
        "terms_conditions_desc8": "Menuonline サービスは、カナダおよびオンタリオ州の法律に準拠します。（第 24 条「準拠法」を参照）",
        "terms_conditions_desc9": "カナダ国内またはカナダ国外の個人、警察、その他の規制当局によるお客様のデータまたはコンテンツに関する要求は、常にお客様に照会されます。唯一の例外は、これがカナダまたはオンタリオ州の法律に違反することになる場合です。ただし、いかなる場合でも、お客様以外の者によるお客様のデータの公開には断固として抵抗します。（第 14 条「お客様の所有物は何ですか?」を参照）。",
        "terms_conditions_desc10": "Menuonline のセキュリティは、ユーザー名とパスワードの管理次第です。Menuonline のユーザー アカウントは無料ですので、ユーザーごとにアカウントを作成し、パスワードは秘密にしておいてください。アカウントの責任はユーザーにあります。(「仕組み」のセクション 3 を参照)",
        "terms_conditions_desc11": "アカウントはいつでもキャンセルできます。キャンセルした場合、未使用のクレジットの払い戻しは行われません。（第3項「仕組み」を参照）",
        "terms_conditions_desc12": "当社はいつでもお客様のアカウントをキャンセルできます。アカウントをキャンセルした場合、メールクレジットと未使用のサービスの払い戻しは行われます。（第3項「仕組み」を参照）",
        "terms_conditions_desc13": "お客様のデータはキャンセル後に削除されます。また、アカウントユーザーが12か月以上ログインしていない場合も削除されることがあります。当社は、統計目的でお客様のアカウントから個人を特定できる情報を含まない集計データを保持します。（第3項「仕組み」を参照）",
        "terms_conditions_desc14": "メール クレジットは従量課金制で購入され、有効期限はありません。唯一の例外は、アカウントに 12 か月間ログインしていない場合です。この場合、当社はお客様のアカウント、データ、および関連するクレジットを削除する権利を留保します。(第 10 項「サービスに対して支払う金額はいくらですか?」を参照)",
        "terms_conditions_desc15": "当社は、Menuonline が常に稼働していることを確認するために全力を尽くし、メンテナンスのためにサービスを一時的に停止する必要がある場合は事前に通知します。ただし、当社はサービスが常に稼働していることを保証するものではなく、必要なときにシステムが稼働していない場合にお客様が被る損失を含め、Menuonline の使用によってお客様が被る損失について責任を負いません。(第 17 条「免責事項」を参照)。",
        "terms_conditions_desc16": "当社は、お客様のコンテンツやメッセージの送信先を監視しません。ただし、お客様の活動によって生じた苦情やその他の問題は監視します。苦情や問題が多すぎる場合は、当社の判断によりサービスをキャンセルする場合があります。（第 7 条「監視」を参照）",
        "terms_conditions_desc17": "当社は、Menuonline をお客様が自立してご利用いただけるよう、優れた製品サポートを提供することに尽力しています。当社では、ウェブサイト、Menuonline サポート チケット システム、電話によるドキュメントやビデオの形式でサポートを提供しています。製品サポートは無料ですが、当社の判断により特定のクライアント アカウントのサポートを中止する権利を留保します。(第 5 項「サポート」を参照)",
        "terms_conditions_desc18": "製品サービスは、Menuonline に関連する作業をお客様自身で行うのではなく、当社に委託する場合にご利用いただけます。製品サービスの料金は 1 時間あたり 135 ドルです。当社は、より高額な料金でコンサルティング サービスも提供しています。当社は、いつでも料金を変更する権利を留保します。(第 6 項「製品およびコンサルティング サービス」を参照)",
        "terms_conditions_desc19": "お客様のデータに侵害があったと思われる場合は、お客様に通知し、関連する詳細と情報を提供するよう努めます。（第 14 項「お客様の所有物は何ですか?」を参照）",
        "terms_conditions_desc20": "当社は、本契約をいつでも変更する権利を留保します。当社は、利用規約の変更についてお客様に通知するよう努めますが、サービスを利用することは、お客様が本規約の変更に同意することを意味します。（第 9 項「変更」を参照）。",
        "terms_conditions_desc21": "利用規約およびプライバシーポリシーは英語のみで作成されており、翻訳版と矛盾がある場合は、利用規約およびプライバシーポリシーの英語版が優先されることに同意するものとします。",
        "terms_conditions_mycircle": "MYCIRCLE 利用規約",
        "terms_conditions_last_update": "最終更新日: 2020年1月14日",
        "terms_conditions_usage_agreement": "このウェブサイト（以下「サイト」）を使用する前に、これらの利用規約（以下「規約」）をよくお読みください。これらの規約は、Menuonline（以下「Menuonline」または「当社」）およびその他を免責し、当社およびその他関係者の責任を制限するものであり、また、お客様による本サイトの使用に適用されるその他の重要な規定を含んでいます。",
        "terms_conditions_acceptance": "本サイトまたは本サービスを訪問または使用することにより、お客様は、お客様自身、およびお客様が代理で行動する組織（以下、総称して「お客様」といいます）に代わって、本サイトの使用および本サイトへの訪問ごとに本規約を承諾し、遵守することに同意するものとします。",
        "terms_conditions_services": "1. 当社のサービス。",
        "terms_conditions_services_desc": "当サイトを通じて提供するサービスは、お客様が選択した受信者に電子メール メッセージ (以下「電子メール」) を作成、送信、管理できるようにするサービスです (以下「サービス」)。",
        "terms_conditions_license": "2. ライセンス。",
        "terms_conditions_license_desc": "本規約に従い、Menuonline は、本規約期間中、本サイトの閲覧および本サービスの機能により許可される本サービスの使用について、非独占的、限定的、譲渡不可のライセンスをお客様に付与します。当社は、本サービスおよび Menuonline コンテンツ (以下に定義) に関して、本規約で明示的に付与されていないすべての権利を留保します。",
        "terms_conditions_account": "3. 仕組み",
        "terms_conditions_account_desc": "サービスを利用するには、アカウントを登録する必要があります。アカウントは無料です。アカウントにより、サービスおよび当社が随時構築および維持する「機能」にアクセスできます。アカウントを取得するには、次の条件を満たす必要があります。",
        "terms_conditions_account_requirements": [
            "18歳以上であり、契約を締結できる能力があること。",
            "当社が定めた登録手続きを完了する。",
            "これらの利用規約に同意する。",
            "真実かつ完全で最新の連絡先情報と請求\/支払い情報を提供します。"
        ],
        "responsibility": "責任。",
        "responsibilityText": "お客様は、お客様のアカウントで発生するアクティビティについて全責任を負い、アカウントのパスワードを秘密かつ安全に保管する必要があります。アカウントは無料ですので、お持ちのユーザーごとに別のアカウントを設定することをお勧めします。また、お客様がそのアカウントの使用を許可したかどうかに関わらず、お客様がアクセスできるすべてのアカウントについて責任を負います。お客様は、電子メールの送信先とのやり取りについて全責任を負うことに同意し、同意を得た相手にのみ電子メールを送信します。お客様のアカウントが不正に使用された場合は、直ちに当社に通知する必要があります。",
        "compliance_with_CASL": "カナダのスパム対策法 (CASL) に準拠します。",
        "compliance_with_CASL_text": "Menuonline の役割は、同意設定の管理を容易にすることであり、法律を施行することではありません。CASL に準拠するのはお客様の責任です。当社は、デフォルトの機能を設定し、文書化された明示的な同意の取得と更新を容易にし、暗黙的な同意の有効期限を管理することでお客様を支援します。お客様は、データベースの同意の管理を完全に制御できます。いつでもすべての連絡先の同意ステータスを一括更新し、デフォルト設定を上書きし、暗黙的な同意ステータスの自動有効期限を無効にできます。",
        "closing_account": "アカウントを閉鎖します。",
        "closing_account_text": "お客様またはMenuonlineは、相手方に通知することにより、いつでもいかなる理由でも本契約を解除できます。お客様が虚偽、不正確、最新でない、または不完全な情報を提供した場合、または本利用規約に違反する方法で本サービスを使用した場合、当社は、当社が利用できるその他の救済策に加えて、お客様のアカウントを直ちに停止し、サービスへのアクセスを終了する権利を有します。お客様が12か月間アカウントを使用しない場合、当社はお客様のアカウントをキャンセルする場合があります。当社が何らかの理由でお客様のアカウントを終了する場合、残っているクレジットを返金します。終了すると、当社はお客様のアカウントと、お客様が送信したメールを含むそれに関連するすべてのデータを永久に削除します。当社は、統計目的で、個人を特定できる情報を含まないお客様のアカウントの集計データを保持する場合があります。",
        "emailing_content": "コンテンツを電子メールで送信します。",
        "emailing_content_text": "このサイトまたはサービスを通じて Menuonline とやり取りする場合、お客様は真実かつ正確な情報を提供することに同意するものとします。また、電子メールで機密情報を提供することは安全ではないことを理解するものとします。お客様は、当社のサービスを使用する誰かに送信する内容について、お客様が単独で責任を負うことを理解するものとします。",
        "support": "サポート。",
        "support_text": "当社は、お客様が本サービスを自力で利用できるようサポートを提供します。当社は、本サイト上の文書やビデオ、Menuonline サポート チケット システム、および電話によるサポートを提供します。お客様は、本サイトの上部にある「サポート」タブをクリックして当社に連絡できます。製品サポートは無料ですが、当社は独自の判断で特定のクライアント アカウントのサポートを中止する権利を留保します。",
        "product_and_consulting_services": "製品およびコンサルティング サービス。",
        "product_and_consulting_services_text": "製品サービスは、Menuonline に関連する作業をお客様自身で行うのではなく、当社に委託する場合にご利用いただけます。製品サービスの料金は 1 時間あたり 125 ドルです。より高額な料金でコンサルティング サービスも提供しています。当社は料金をいつでも変更する権利を留保します。",
        "monitoring": "監視。",
        "monitoring_text": "当社は、本サービスを通じて送信される情報の内容を監視または判断することはありませんが、不適切な使用の可能性があるという苦情を受け取った場合は調査します。Menuonline は、以下のガイドラインに従い、お客様への通知の有無にかかわらず、いつでも独自の裁量で、特定の使用が適切であるかどうかを決定することができます。",
        "term": "学期。",
        "term_text": "契約期間は、お客様が当社にアカウントを登録した時点から開始され、お客様が本サービスを利用する限り継続します。お客様は、当社にアカウントを登録する権限と能力を有していることを表明し、保証するものとします（お客様が組織を代表して登録する場合も含みます）。",
        "changes": "変更。",
        "changes_text": "契約期間は、お客様が当社にアカウントを登録した時点から開始され、お客様が本サービスを利用する限り継続します。お客様は、当社にアカウントを登録する権限と能力を有していることを表明し、保証するものとします（お客様が組織を代表して登録する場合も含みます）。",
        "payment": "支払い",
        "what_do_i_pay_for_service": "サービスにはいくら支払うのですか?",
        "what_do_i_pay_for_service_text": "当サイトの「価格」ページで詳しく説明している通り、お客様は当社のサービスを通じて電子メールを送信するためのプリペイドクレジット（「クレジット」）を購入することができます。クレジットに有効期限はなく、これらのクレジットに対して定期的な料金は発生しません。唯一の例外は、お客様がアカウントを 12 か月間使用しなかった場合、当社はお客様のアカウントおよび未使用のクレジットをキャンセルできることです。当社は、当サイトの価格ページを更新することにより、いつでも価格を変更することができます。これは、お客様の次回のクレジット購入に適用されます。",
        "how_do_i_pay_for_service": "サービス料金の支払い方法は？",
        "how_do_i_pay_for_service_text": "クレジットの支払いは、主要なクレジットカードで行うことができます。当社が受け付ける支払い方法はいつでも変更される可能性があります。",
        "can_i_get_refund": "払い戻しはできますか？",
        "can_i_get_refund_text": "お客様がアカウントをキャンセルした場合、本サービスの利用に対して支払った金額は返金されません。当社がお客様のアカウントをキャンセルした場合、残っているクレジットを返金いたします。",
        "rights": "権利",
        "what_do_we_own": "私たちは何を所有しているのでしょうか?",
        "what_do_we_own_text": "お客様が当社に提供したコンテンツ（連絡先記録、電子メールの内容、お客様が本サービスを通じて収集した情報を含むがこれらに限定されない）（以下「お客様のコンテンツ」）を除き、当社のサイト、本サービスに含まれる、または本サービスにより転送されるすべての資料（ソフトウェア、画像、テキスト、グラフィック、イラスト、ロゴ、特許、商標、サービスマーク、写真、音声、ビデオ、音楽、および他のユーザーに属するコンテンツ（以下「Menuonline コンテンツ」）を含むがこれらに限定されない）（およびそれに関連するすべての知的財産権）は、当社または当社のライセンサーが所有します。",
        "what_do_you_own": "あなたは何を所有していますか？",
        "what_do_you_own_text": "当社は、お客様のコンテンツに対する所有権を主張しません。お客様のコンテンツはお客様のものであり、お客様が本サービスにアップロードしたコンテンツの所有権はお客様が保持します。お客様は、本サービスを通じてお客様のコンテンツを共有することにより、お客様の設定および本利用規約に従って、他のユーザーがお客様のコンテンツを閲覧、編集、および\/または共有することを許可することに同意するものとします。お客様は、電子メールで使用するすべての素材を所有しているか、使用する許可を得ていることを表明し、保証するものとします。当社は、本利用規約および当社のプライバシーポリシーに記述されている方法でのみ、お客様の素材を使用または開示する場合があります。",
        "privacy_statement": "Menuonline はユーザーのプライバシーを尊重します。Menuonline が本サイトまたは本サービスを通じて収集する個人情報は、Menuonline のプライバシー ポリシーの対象となり、このポリシーは本規約に参照により組み込まれます。当社は、プライバシー ポリシーに従ってお客様の情報を使用および開示する場合があります。",
        "access_to_your_content": "Menuonline は、当社と適切な機密保持契約を締結した、またはお客様の同意を得た Menuonline の従業員および請負業者以外のいかなる者にも、お客様のコンテンツへのアクセスを許可しません。",
        "governmentRequest": "政府または規制当局からお客様のコンテンツへのアクセス要求を受けた場合、当社はお客様にその要求を通知し、お客様のコンテンツを公開する前にお客様の同意を求めます。当社はお客様のコンテンツに関するいかなる要求にも応じず、お客様の許可なく、または管轄裁判所または該当する規制機関から要求されない限り、この種の要求に応じてお客様のコンテンツを公開することはありません。",
        "breachNotification": "お客様のコンテンツが不正アクセス、使用、コピー、盗難、紛失、または本規約に違反する使用または開示（「違反」）の対象になっていることが判明した場合、当社はお客様に速やかに通知し、発生した事態についてお知らせします。お客様は、違反が適用法に従って処理されることに同意し、当社がお客様の電子メール配信リストのメンバーに通知する必要がある場合、お客様は当社の指示に従って速やかに通知することに同意するものとします。",
        "contentRetention": "お客様の事前の書面による承認または指示がない限り（たとえば、お客様がコンテンツをカナダ国外の個人に送信することを選択した場合など）、当社はお客様のコンテンツをカナダ国内で保持、使用、送信します。サービスを提供するために使用されるサーバーはカナダにあります。",
        "accessTrouble": "コンテンツにアクセスできない場合は、Menuonline が時間と材料をかけてコンテンツの取得をお手伝いします。",
        "reportingAbuse": "誰かが本規約に違反していると思われる場合は、直ちに当社にご連絡ください。本サービスの他のユーザーから送信されたと思われるスパムメールを受信した場合、本サービスの他のユーザーが著作権を侵害するコンテンツを投稿したと思われる場合、または本サービスの他のユーザーがその他の方法で本規約に違反していると思われる場合は、Support@menuonline.com までご連絡ください。",
        "acceptableUse": "お客様は、本サービスの利用がすべての適用法および規制に準拠することを表明し、保証します。お客様は、本サービスがお客様の管轄区域での使用に適合しているかどうか、またお客様にとって適切であるかどうかを判断する責任があることに同意します。より確実にするために、お客様は、次のことに同意します（また、お客様の従業員、代理人、および許可されたユーザーが次のことを確実にすることに同意します）。",
        "exportLaws": "当社は、本サイトまたは本サービスがカナダ国外での使用に適切または利用可能であることについて一切表明しません。また、コンテンツが違法である地域からのアクセスは禁止されています。お客様は、カナダおよび米国の輸出法および規制を含むがこれらに限定されない適用法または規制に違反して、Menuonline コンテンツまたは本サイトのその他の資料、またはそのコピーまたは翻案を使用、輸出、再輸出することはできません。",
        "disclaimers": "サービスおよび本サイトは、いかなる種類の表明、保証、条件もなく「現状のまま」提供され、Menuonline は、適用法で認められる最大限の範囲で、明示的または黙示的を問わず、法律の適用またはその他の方法によるすべての表明、保証、条件（商品性、特定の目的または使用への適合性、第三者の権利の非侵害に関する黙示的保証および条件を含みますが、これらに限定されません）を明示的に否認します。",
        "limitLiability": "本契約に基づく Menuonline の総責任は、契約または不法行為（過失を含む）のいずれによるかを問わず、保証違反、厳格責任またはその他のいかなる責任理論の結果として生じたものであっても、責任の原因となる最新の事象が発生した月の直前の 12 か月間に本利用規約に基づいて Menuonline に支払われた合計料金を超えない直接損害に限定されます。",
        "liabilityExclusions": "Menuonline、その関連会社、またはそれぞれの取締役、役員、従業員、代理人、その他の代表者 (総称して「Menuonline 代表者」)、または付属ネットワークの運営者は、本サイトまたは本サービスに関連して、(i) 間接的、結果的、付随的、模範的、懲罰的、または特別な損害、または (ii) 直接的、間接的、結果的、付随的、模範的、懲罰的、または特別な損害 (収益の損失、貯蓄の損失、収益の損失、利益の損失として特徴付けられる) について、一切の責任を負いません。",
        "indemnity": "お客様は、本サイトからアクセスした情報の使用または本サービスの使用、および本規約の違反に起因して第三者が提起した請求、当社に対して科せられた罰金または罰則から、Menuonline および\/または Menuonline の代表者 (以下「Menuonline の補償対象者」) を免責するものとします。",
        "ipInfringement": "Menuonline は、本契約に従ったサービスへのアクセスまたはサービスの使用がカナダの法律に基づく第三者の知的財産権を侵害していると主張する第三者によるあらゆる請求、要求、訴訟、訴え、または法的手続き (「知的財産権侵害請求」) からお客様を防御し、補償し、免責するものとします。",
        "equitableRelief": "お客様が本規約に違反した場合、当社は差止命令による救済（お客様の行為を阻止するために裁判所命令を要請することを意味します）またはその他の衡平法上の救済を求める場合があります。お客様は、これが当社が法律上または衡平法上有するその他の権利に加えて、かつそれらの権利を損なうものではないことに同意するものとします。",
        "linksToOtherSites": "当社が第三者の Web サイトへのリンクを提供する場合、それらは便宜上の目的でのみ提供されます。これらのリンクを使用すると、当社のサイトから離れることになります。当社はこれらの第三者のサイトを管理または推奨していません。お客様は、当社がこれらの第三者のサイトまたはそのコンテンツに対して一切責任を負わないことに同意するものとします...",
        "thirdPartyRequests": "お客様のアカウントまたは本サービスの使用に関連する法的または政府の要請に応じて当社が情報を提供する必要がある場合、お客様は当社が費用を請求することに同意するものとします。これらの費用には、当社の法的費用および要求への回答を準備するために費やした当社従業員の労働時間費用が含まれる場合があります。",
        "assignment": "本契約および本契約に基づいて付与されるすべての権利およびライセンスは、お客様によって移転または譲渡することはできませんが、Menuonline はお客様に合理的な事前通知を行った上で、制限なく譲渡することができます。",
        "governingLaw": "これらの条件は、抵触法の規定にかかわらず、適用されるすべての連邦法およびオンタリオ州法に準拠し、それらに従って解釈されます。お客様は、これらの条件に関する紛争の解決については、オンタリオ州裁判所の専属管轄権に従うことに同意するものとします。",
        "severability": "何らかの理由で管轄裁判所が本規約のいずれかの条項またはその一部を違法または何らかの理由で執行不能と判断した場合、その条項は本規約の意図を実行するために許容される最大範囲で執行され、本規約の残りの部分は完全に効力を維持し、執行可能のままとなります。",
        "waiver": "本規約の要件の放棄または逸脱の同意は、書面で当社により署名され、かつそれが与えられた特定の場合および特定の目的に対してのみ有効となります。当社が本規約に基づく権利を行使しなかった、または行使が遅れた場合でも、当社の権利の放棄とはみなされません。",
        "furtherAssurances": "各当事者は、本利用規約に基づく義務を履行するために必要なすべての文書に署名して提供し、あらゆる措置を講じる（またはそれらの措置を講じさせる）ことに同意します。",
        "notices": "Menuonline への通知はすべて書面で行い、電子メールで当社に送付する必要があります。当社への通知は、support@menuonline.com のカスタマー サービス担当者宛に送付する必要があります。お客様への通知は、登録時にお客様が提供した住所または電子メール アドレスに送付される場合があります。さらに、当社は、本サイトの変更やその他の重要事項についてお客様にお知らせするために、本サイトを通じて通知またはメッセージを配信する場合があります。これらの配信は、当社が投稿した時点でお客様への通知となります。",
        "entireAgreement": "本規約および当社のプライバシー ポリシーは、お客様による本サイトおよび本サービスの利用に関するお客様と当社の間の完全な合意を構成するものであり、この主題に関する Menuonline とお客様との間の以前のすべての合意、表明、および理解に優先します。プライバシー ポリシーと本規約の間に矛盾がある場合は、本利用規約が優先します。",
        "language": "両当事者は、本利用規約、当社のプライバシーポリシー、および関連文書が英語で作成されることを明示的に要求しています。両当事者は、本契約および関連するすべての文書が英語で作成されることを希望し、また要求しています。お客様は、本利用規約およびプライバシーポリシーの翻訳版と矛盾が生じた場合、英語版が優先されることに同意するものとします。",
        "survival": "本規約の満了または終了後も存続することがその性質または重要性により意図されている本規約のすべての規定は、第 17 条から第 19 条までを含み、これらに限定されない、存続するものとします。"
    },
    "collect_additional_info": "追加情報を収集しますか?",
    "additionalInfoChoice": "収集したい追加情報を選択してください。",
    "additional_info_yes": "はい",
    "additional_info_noSkip": "いいえ、スキップ",
    "additional_info_label": "追加情報",
    "confirm_business_location": "まず、あなたのビジネス・ロケーションを確認しましょう。",
    "custom_get_more_reviews_form": "カスタムレビュー取得フォーム",
    "set_up_custom_form": "グループ別にカスタムレビュー獲得フォームを設定する",
    "reputation_name_label": "レビューをもっと見る 名前",
    "reputation_name_placeholder": "評判名を入力",
    "group_label": "グループ",
    "group_desc_title": "グループの説明タイトル",
    "group_desc": "グループの説明",
    "select_group_placeholder": "グループを選択",
    "required_group_name": "グループ名は必須です",
    "before_asking_review": "顧客にレビューを依頼する前に、1〜4 個の質問をしてみましょう。",
    "feel_free_edit": "必要に応じて自由に編集してください。",
    "incentive_settings": "インセンティブ設定",
    "incentivize_customer": "顧客にレビューを書いてもらうインセンティブを与えたいですか?",
    "choose_incentive": "紹介したいインセンティブを選択してください。",
    "scan_qr_feedback": "QR コードをスキャンして、あなたの体験に関する貴重なフィードバックを私たちと共有してください。",
    "qr_code": "QRコード",
    "scan_me": "スキャンして！",
    "or_title": "または",
    "send_otp_title": "OTPを送信",
    "sign_in_title": "サインイン",
    "email_addresss_title": "電子メールアドレス",
    "reset_password_title": "パスワードをリセット",
    "remember_password_title": "パスワードを覚えていますか?",
    "digit_code_title": "送信された4桁のコードを入力してください ",
    "not_received_title": "認証コードが届きません！",
    "resend_title": "再送してください",
    "sign_up_form": "アカウントを作成する",
    "sign_up_sub_title": "最新の情報にアクセスするには今すぐログインしてください",
    "already_registered_title": "すでにMenuonlineアカウントをお持ちですか?",
    "reserved_title": "著作権 ©2023 Menuonline。無断転載を禁じます。",
    "terms_title": "利用規約",
    "policy_title": "プライバシーポリシー",
    "sign_up_title": "サインアップ",
    "terms_conditions_title": "利用規約",
    "business_type_title": "事業形態",
    "business_type_sub_title": "あなたのビジネスをよりよく表すビジネスタイプを 1 つ選択してください。",
    "salon_title": "サロン",
    "restaurant_title": "レストラン",
    "pizza_store_title": "ピザ店",
    "food_truck_title": "フードトラック",
    "check_domain_title": "ドメイン名を確認する",
    "start_exploring_title": "探索を始める",
    "sign_in_form": "アカウントにログイン",
    "sign_in_sub_title": "最新の情報にアクセスするには今すぐログインしてください",
    "remember_title": "私を覚えてますか",
    "otp_title": "ワンタイムパスワード",
    "password_title": "パスワード",
    "forgot_title": "パスワードをお忘れですか？",
    "not_registered_title": "アカウントをお持ちではありませんか?",
    "create_account_title": "今すぐ登録",
    "create_form_title": "フォームを作成",
    "first_name_title": "ファーストネーム",
    "last_name_title": "苗字",
    "email_title": "メール",
    "mobile_number_title": "携帯電話番号",
    "birthday_title": "誕生日",
    "gender_title": "性別",
    "address_title": "住所",
    "short_answer_title": "短い答え",
    "long_answer_title": "長い答え",
    "single_answer_title": "単一の回答",
    "single_checkbox_title": "チェックボックス1つ",
    "multiple_choice_title": "複数選択",
    "drop_down_title": "落ちる",
    "yes_no_title": "はい、もしくは、いいえ",
    "description_text_title": "説明テキスト",
    "file_upload": "ファイルのアップロード",
    "reset_password_form": "パスワードをリセットする",
    "reset_password_sub_title": "すぐにパスワードをリセットして、アカウントにアクセスします。",
    "required_full_name_title": "フルネームは必須です",
    "required_first_name_title": "名を入力してください",
    "valid_first_name_title": "有効な名を入力してください",
    "valid_full_name_title": "有効なフルネームを入力してください",
    "required_block_time": "ブロックタイムが必要です",
    "required_last_name_title": "姓は必須です",
    "valid_last_name_title": "有効な姓を入力してください",
    "valid_mobile_number_title": "有効な携帯電話番号を入力してください",
    "required_mobile_number_title": "携帯電話番号が必要です",
    "required_already_mobile_number_title": "携帯電話番号はすでに必須です",
    "valid_otp_title": "有効なOTPを入力してください",
    "valid_email_title": "有効なメールアドレスを入力してください",
    "required_email_title": "メールアドレスは必須です",
    "required_description_title": "説明が必要です",
    "required_description_500_characters": "少なくとも500文字を入力してください",
    "required_description_600_characters": "最大600文字を入力してください",
    "domain_title": "ドメイン名",
    "required_password_title": "パスワードが必要です",
    "required_password_regex_title": "少なくとも 8 文字、少なくとも 1 つの大文字、1 つの小文字、1 つの数字、および 1 つの特殊文字を含める必要があります。",
    "required_terms_title": "利用規約に同意してください",
    "required_note_title": "メモは必須です",
    "required_birthday_title": "誕生日は必須です",
    "no_access_title": "現在アクセス権がありません。管理者にお問い合わせください",
    "menu_online_open_txt": "開ける",
    "loyalty_program_description": "😊 理想的なロイヤルティ プログラムを見つけて、ブランドに合わせてカスタマイズしましょう。",
    "lp_list_title": "ロイヤルティ パンチ カードのリスト",
    "lp_n_title": "ロイヤルティパンチカード名",
    "enter_details_title": "詳細を入力",
    "stamp_allowed_title": "スタンプ可",
    "stamp_items_title": "スタンプアイテム",
    "lpn_req_title": "ロイヤルティ パンチカード名が必要です",
    "valid_lpn_title": "有効なポイントカード名を入力してください",
    "brand_color_error": "ブランドカラーは7文字でなければなりません",
    "select_dark_brand_color_title": "有効なブランドカラーを選択してください",
    "your_brand_color_title": "ブランドカラー",
    "upload_logo_title": "ロゴをアップロード",
    "locations_title": "場所",
    "add_lp_title": "プログラムを作成する",
    "location_error": "場所を選択してください",
    "pvs_title": "顧客は1回の訪問でいくつのスタンプを獲得できますか?",
    "max_apd_title": "顧客が 1 日に獲得できるスタンプの最大数はいくつですか?",
    "logo_error": "ロゴは必須です",
    "expiry_title": "報酬の有効期限を設定する",
    "add_more_rewards_title": "+ 報酬をさらに追加",
    "prev_step_1_title": "QR コードを表示してポイントを獲得し、特典をお楽しみください。",
    "delete_lp_title": "このロイヤルティ パンチ カードは永久に削除されます。",
    "delete_user_record_title": "このユーザーレコードは永久に削除されます。",
    "redeem_title": "償還",
    "lp_button_title": "ロイヤルティパンチカードに加入する",
    "lp_download_button_title": "ダウンロードカード",
    "step_0_title": "今すぐ参加してください。あなたのような忠実な食通のためにおいしい特典が待っています。",
    "step_1_title": "参加しました",
    "congrats_step_title": "今なら無料ピザが受けられます",
    "reward_title": "訪問ごとに特典を獲得",
    "visit_title": "訪問してロックを解除",
    "rewards_title": "報酬",
    "dummy_address": "3730 Frankfort Ave 、ルイビル、ケンタッキー州、アメリカ合衆国",
    "loyalty_qr_default_text": "QRコードをスキャンして、ロイヤルティパンチカードに加入し、特典をお楽しみください",
    "lp_earn_stamp_txt": "お客様は来店ごとにスタンプを獲得します。",
    "lp_get_rewards_txt": "訪問するたびに特典を獲得",
    "lp_1_time_visit_txt": "1回の訪問で得られるもの",
    "lp_uppercase_stamp_text": "スタンプ",
    "lp_lowercase_stamp_text": "スタンプ",
    "lp_txt": "忠誠心",
    "lp_per_visit_stamp_validation_msg": "訪問ごとのスタンプは、1日あたりの最大スタンプ数より少なくなければなりません",
    "lp_per_max_stamp_validation_msg": "1日あたりの最大スタンプ数は、訪問ごとのスタンプ数より大きくなければなりません",
    "lp_valid_value_msg": "有効な値を入力してください",
    "lp_required_field_msg": "この項目は必須です",
    "lp_stamp_required_msg": "スタンプが必要です",
    "lp_valid_stamp_msg": "有効なスタンプ値を入力してください",
    "lp_reward_name_required_msg": "報酬名は必須です",
    "lp_unique_stamp_msg": "スタンプの値は一意である必要があります",
    "lp_benefits": "ロイヤルティプログラムは、パーソナライズされたマーケティングのための顧客インサイトを提供しながら、リピート訪問と収益を増加させます。",
    "lp_how_customer_txt": "顧客はこうする",
    "lp_punch_card_txt": "ロイヤルティパンチカード",
    "lp_look_txt": "見ます！😍",
    "lp_desc": "顧客は来店ごとにスタンプを獲得し、十分な数を集めると特別な特典と引き換えることができます。",
    "lp_edit": "編集",
    "lp_looks_good_txt": "見た目は良い",
    "lp_customer_view_instruction": "これが顧客に表示されるものです。ロゴをアップロードしてカラーテーマを設定してください",
    "lp_customer_earn_stamps": "顧客が訪問するたびにスタンプを獲得し、その特典を管理できるようにします",
    "lp_stamp_rewards_setup": "スタンプ獲得報酬を設定し、顧客がスタンプごとに報酬を獲得できるようにします",
    "lp_loyalty_program_setup": "ロイヤルティプログラム名を入力し、このプログラムを実施する場所を設定します",
    "lp_prev_step1": "QR コードを表示してポイントを獲得し、特典をお楽しみください。",
    "lp_visit_1_time_txt": "1回訪問して",
    "lp_get_free_txt": "無料で入手",
    "lp_visit_allow_txt": "訪問してロックを解除",
    "lp_rewards": "報酬",
    "repsT": "レビューをもっと見る",
    "offSup": "オフィス用品",
    "repsNxtV": "次回のご来店の際には",
    "addRepT": "追加 レビューをもっと見る",
    "repEntrCpnTxt": "クーポンテキストを入力",
    "repEntrCpnTxtBtn": "クーポンボタンのテキストを入力",
    "repMngNxtV": "次回のご来店の際はいかがでしょうか？",
    "repMngWldL": "取得したいですか？",
    "discount": "割引",
    "repDisTyp": "割引タイプ",
    "repNameT": "あなたの評判に名前を付ける",
    "crtRepT": "評判を築く",
    "feedBackT": "フィードバック",
    "confLocT": "場所を確認",
    "businLocT": "事業所所在地",
    "addInfoT": "追加情報",
    "repNReqT": "レビューをもっと見るには名前が必要です",
    "validRepNT": "有効な評判名を入力してください",
    "groupT": "グループ",
    "groupLDescTitleT": "連絡先を自動的にグループにプッシュする",
    "groupLDescT": "フォームに記入したすべての顧客が選択したグループに追加されます。",
    "que2": "食事は美味しかったですか？",
    "que3": "私たちのサービスは親切でしたか?",
    "que4": "当社のサービスは迅速でしたか?",
    "addAdditionalT": "追加情報を追加しますか?",
    "phNoMandT": "電話番号は必須です！",
    "qrGenT": "QRコード生成",
    "repFDT": "あなたのご意見は、私たちのチームがより良いサービスを提供するために役立ちます。",
    "letdiT": "やりましょう",
    "tak15secT": "15秒かかります",
    "defQue": "定期的に素晴らしいオファーやプロモーションを受け取りたいですか?",
    "defNQue": "誰かに返信してもらいたいですか?",
    "rateOnGoogleDesc": "Google で評価していただけますか?",
    "mayBeNT": "また今度",
    "yesSureT": "はい、もちろんです",
    "valid_name": "有効な名前を入力してください",
    "nameReq": "名前は必須です",
    "backToMainT": "メインページに戻る",
    "attachCopT": "顧客にインセンティブを与えたいですか?",
    "thankYouT": "ありがとう",
    "lftyT": "次回のご来店をお待ちしております！",
    "repPreT": "評判プレビュー",
    "offer_exclusive_discounts": "顧客を引き付けるために、特別割引やプロモーションを提供します。",
    "couponT": "クーポン",
    "locStepDesc": "事業所の所在地を確認する",
    "createRepStepDesc": "グループ別にカスタム評判フォームを作成する",
    "feedbackStepDesc": "顧客フィードバック質問セット",
    "addInfoStepDesc": "顧客フィールドとインセンティブ設定",
    "FedbkkWDT": "データによるフィードバック",
    "repAnlytT": "評判分析",
    "todayT": "今日",
    "rep_last_7_days_t": "過去7日間",
    "last30DaysT": "過去30日間",
    "lastMT": "先月",
    "custRangeT": "カスタム範囲",
    "queWiseAnltT": "質問ごとの分析",
    "atL1QueErrT": "少なくとも1つの質問を選択してください",
    "deleteRepTitle": "この評判は永久に削除されます。",
    "incvCustT": "はい、顧客にインセンティブを与えます",
    "collAddInfoT": "はい、追加情報を収集したいです",
    "totRewT": "総レビュー",
    "totPosRewT": "総肯定的レビュー",
    "negFeedT": "否定的なフィードバック",
    "posFeedT": "肯定的なフィードバック",
    "fineT": "細則",
    "enterFineT": "細字テキストを入力",
    "setThemeT": "テーマカラーを設定する",
    "que1T": "質問1",
    "que2T": "質問2",
    "que3T": "質問3",
    "que4T": "質問4",
    "entMailT": "メールアドレスを入力してください",
    "reputationQRDefTxt": "QR コードをスキャンして、あなたの体験に関する貴重なフィードバックを私たちと共有してください。",
    "selUser": "ユーザーを選択",
    "userT": "User",
    "subUReq": "ユーザーを選択してください",
    "updateLoc": "場所を更新",
    "leadGenT": "リードジェネレーション",
    "displayT": "画面",
    "ratingT": "評価",
    "rep_dashboard_negative_feedback": "否定的なフィードバック 0%",
    "rep_dashboard_position_feedback": "肯定的なフィードバック 0%",
    "rep_dashboard_total_Feedback_txt": "総フィードバック",
    "rep_dashboard_anony_Feedback": "匿名のフィードバック",
    "rep_dashboard_Feeedback_Data_txt": "データによるフィードバック",
    "rep_dashboard_review_txt": "レビューをもっと見る",
    "rep_dashboard_total_Review_txt": "総レビュー",
    "rep_dashboard_total_positive_Review_txt": "総肯定的レビュー",
    "rep_dashboard_negative_feedback_txt": "否定的なフィードバック",
    "rep_dashboard_connection_txt": "繋がり",
    "rep_dashboard_question_wise_analytics_txt": "質問ごとの分析",
    "rep_dashboard_date_label": "日付を選択",
    "rep_dashboard_custom_range_txt": "カスタム範囲",
    "rep_tlt_min": "担当者",
    "emojis": "絵文字",
    "media_text": "メディアを追加",
    "custom_field_text": "カスタムフィールドを挿入する",
    "shortlink_text": "ショートリンクを追加",
    "use_template_text": "テンプレートを使用する",
    "banner_must_have": "必須アイテム",
    "res_seamless": "シームレス",
    "res_table_reservation": "テーブル予約",
    "res_system": "システム",
    "res_text": "予約",
    "md_easily": "簡単に",
    "md_manage_customers": "顧客管理",
    "md_visits_interactions": "訪問と交流",
    "md_desc": "リアルタイムのテーブル予約で、お客様の食事がもっと簡単になります。予約を簡単に管理し、待ち時間を減らし、スタッフとお客様の両方にとってスムーズな体験を実現します。最高のサービスと満足度を維持しながら、レストランを満席に保ちます。",
    "engage_convert": "SMSのエンゲージメントと変換",
    "coupon_campaign": "クーポンキャンペーン",
    "sms_read_rate": "SMSメッセージは98%の確率で読まれます",
    "real_time_promo": "即時のアクションのためにリアルタイムでプロモーションを配信",
    "affordable_reach": "確実なROIで顧客にリーチできる手頃な方法",
    "stay_in_touch": "常に顧客と連絡を取り合う",
    "bc_engage_customers": "顧客との関わり SMS の変換",
    "bc_coupon_campaign": "クーポンキャンペーン",
    "bc_sms_read_rate": "SMSメッセージは98%の確率で読まれます",
    "bc_real_time_promo": "即時のアクションのためにリアルタイムでプロモーションを配信",
    "bc_affordable_reach": "確実なROIで顧客にリーチできる手頃な方法",
    "bc_stay_in_touch": "常に顧客と連絡を取り合う",
    "ar_automate_engagement": "顧客エンゲージメントを自動化",
    "ar_with": "と",
    "ar_with_auto_responders": "自動応答",
    "ar_personalized_messages": "誕生日にパーソナライズされたメッセージを自動的に送信し、新規顧客を歓迎し、過去の顧客と再びつながります。すべてのやり取りを有意義かつタイムリーに保ちながら時間を節約します。",
    "sq_custom_forms_tools": "カスタムフォームとツール - シンプル化",
    "sq_custom_forms_ci": "クライアントとのやり取り",
    "sq_smart_qr_desc": "カスタム フォームを作成し、電子署名を収集し、スマート QR コードを活用してビジネス プロセスを効率化します。各ツールをカスタマイズしてクライアントのニーズを満たし、ユーザー エクスペリエンスを強化します。",
    "gnc_get_new_cus": "新規顧客を獲得する",
    "gnc_get_customers": "ソーシャル メディアから — 高速かつ簡単!",
    "gnc_social_media_power": "ソーシャル プラットフォームの力を活用して、新規顧客を引き付け、直接ビジネスに売上をもたらします。サードパーティの配送注文を店舗またはオンライン注文プラットフォームに直接リダイレクトして、最大限の管理と収益を実現します。",
    "wifi_connect_guests": "ゲストとつながる",
    "wifi_smart": "スマートWi-Fi",
    "wifi_connect_grow": "洞察力を高める",
    "wifi_smart_wifi_desc": "ブランド化されたスプラッシュ スクリーンを通じて顧客に手間のかからない Wi-Fi 接続を提供し、同時に貴重な顧客データを収集してマーケティング活動とエンゲージメントを強化します。",
    "wifi_create_splash_page": "ゲストWifiスプラッシュページを作成する",
    "instant_menu_create": "作成する",
    "instant_menu_text": "インスタントメニュー",
    "instant_menu_wm": "数分以内に",
    "instant_menu_desc": "リアルタイムの更新、簡単なカスタマイズ、エンゲージメントの向上を可能にして顧客エクスペリエンスを強化し、サービスを簡単に紹介し、コンテンツを最新の状態に保ちます。",
    "instant_menu_create_digital_menu": "デジタルメニューを作成する",
    "loyalty_program_rewards": "報酬を獲得",
    "loyalty_program_on_visits": "訪問のたびに",
    "loyalty_program_create": "ロイヤルティプログラムを作成する",
    "reputation_create": "評判を築く",
    "reputation_boost": "あなたの",
    "reputation_text": "評判",
    "reputation_reviews": "レビューがさらに増えました！",
    "reputation_desc": "顧客から貴重なフィードバックを集めて、サービスと評判を高めましょう。あなたの経験を共有して、私たちの成長にご協力ください。あなたのレビューは重要です!",
    "dashboard": "ダッシュボード",
    "blast_campaign": "ブラストキャンペーン",
    "blast_campaigns": "ブラストキャンペーン",
    "blast_campaign_sub_title": "数回のクリックですべてのクライアントにリーチする一斉キャンペーンを開始",
    "auto_responders": "自動応答",
    "auto_responder": "自動応答",
    "auto_responder_sub_title": "顧客向けのイベントベースのプロモーションを簡単に利用",
    "smart_qr_form": "スマートQR \/ フォーム",
    "get_new_customer": "新規顧客を獲得",
    "marketing": "マーケティング",
    "clients": "クライアント",
    "group_data": "グループデータ",
    "clients_data": "クライアントデータ",
    "account": "アカウント",
    "user_acc_info": "ユーザーアカウント情報",
    "current_plan": "現在の計画",
    "payments": "支払い",
    "location": "位置",
    "location_list": "場所リスト",
    "users": "ユーザー",
    "users_sub_title": "すべてのユーザーとその詳細のリスト",
    "online_profile": "オンラインプロフィール",
    "change_password": "パスワードを変更する",
    "menu": "メニュー",
    "reseller_dashboard": "リセラーダッシュボード",
    "companies": "企業",
    "plans_bought": "購入したプラン",
    "agreement_t_c": "契約条件",
    "wifi_dashboard": "Wifiダッシュボード",
    "hot_spot": "ホットスポット",
    "splash_pages": "スプラッシュページ",
    "get_more_reviews": "レビューをもっと見る",
    "office_supplies": "オフィス用品",
    "reservation": "予約",
    "floor_table": "フロア＆テーブル",
    "guest_smart_wifi": "ゲストスマートWiFi",
    "digital_signage": "デジタルサイネージハードウェア",
    "schedule_down": "スケジュールダウン",
    "business_settings": "ビジネス設定",
    "sub_user_login": "サブユーザーログイン",
    "help_center": "ヘルプセンター",
    "switch_to_hub": "ハブに切り替える",
    "meeting_records": "会議記録",
    "logout": "ログアウト",
    "no_access_text": "現在、アクセス権がありません。管理者に問い合わせてください。",
    "menu_item": "メニュー項目",
    "menu_capital": "メニュー",
    "good_day": "良い一日",
    "no_permission_text": "この操作を実行する権限がありません!!",
    "enter_valid_pin": "有効なピンを入力してください",
    "add_reservation": "予約を追加",
    "edit_reservation": "予約を編集",
    "per_req": "人数は必須です",
    "req_block_time": "ブロックタイムが必要です",
    "pending_status": "保留中",
    "booked_status": "予約済み",
    "completed_status": "完了",
    "cancelled_status": "キャンセル",
    "reservation_slot": "予約枠",
    "block_time": "ブロックタイム",
    "slot_time": "予約枠時間:  ",
    "template_name_req": "テンプレート名は必須です",
    "template_type_req": "テンプレートタイプは必須です",
    "sms_template_req": "SMSテンプレートが必要です",
    "group_req": "グループは必須です",
    "valid_date_time": "有効な日付と時刻を入力してください",
    "valid_group_name": "有効なグループ名を入力してください",
    "reservation_mark_completed": "この予約は完了としてマークされます。",
    "customer_name": "顧客名",
    "date_time": "日付時刻",
    "table_no": "テーブル番号",
    "floor": "床",
    "customer_name_placeholder": "顧客名",
    "date_time_placeholder": "日付_時刻",
    "table_no_placeholder": "テーブル番号",
    "floor_placeholder": "床",
    "select_floor": "フロアを選択",
    "select_table": "テーブルを選択",
    "customer_name_macro": "%%顧客名%%",
    "date_time_macro": "%%日付_時刻%%",
    "table_no_macro": "%%テーブル番号%%",
    "floor_macro": "%%床%%",
    "template_name": "テンプレート名",
    "login_with_4_digit_pin": "4桁のPINでログイン",
    "login_now_to_access_latest_insights": "最新のインサインにアクセスするには今すぐログインしてください",
    "mkt_BS_Title": "ブラストSMS",
    "step_1": "メッセージをカスタマイズ",
    "step_2": "対象者を選択",
    "step_3": "スケジュールメッセージ",
    "step_4": "メッセージを送信",
    "step_5": "メッセージの管理",
    "bs_tab_title": "ブラストキャンペーン概要",
    "default_message": "こんにちは%% firstname %%さん、ブランチが特別割引です! 本日当店でお食事いただくと、お会計から 10% の特別割引が受けられます!",
    "bs_Title": "キャンペーンの種類を選択",
    "sms_title": "ショートメッセージ",
    "reach_out_to_customer": "テキストおよびマルチメディア メッセージを通じて顧客にアプローチします。",
    "wp_text": "ワッツアップ",
    "connect_with_wp": "WhatsApp を使ってクライアントとつながりましょう 💬",
    "send_discount_offers": "割引オファーをSMSで送信📲",
    "send_coupons_via_sms": "顧客にSMSでクーポンを送信します🎟️",
    "env_Cap": "顧客を引き付けるために特別割引やプロモーションを提供する",
    "cmt_Caption": "テキストメッセージやマルチメディアメッセージを通じて顧客にアプローチする",
    "wp_caption": "WhatsAppを使用して顧客とシームレスかつ直接的なコミュニケーションを実現",
    "msg_Prev_Title": "メッセージのプレビュー",
    "cou_Prev_Title": "クーポンプレビュー",
    "blast_SMS": "SMS\/MMSキャンペーン",
    "bs_P_Msg": "キュー内",
    "bs_S_Msg_C": "失敗した",
    "bs_F_Msg_Count": "配達済み",
    "previous": "前の",
    "next_ST": "次",
    "next_S": "次のステップ",
    "req_Temp_Name_Title": "テンプレート名は必須です",
    "req_Template_Tx_Title": "テンプレートテキストは必須です",
    "add_Temp_Title": "テンプレートを追加",
    "link_Desc": "選択したリンクを追加すると、クライアントは Facebook、Google、または独自の Web サイトから予約できるようになります。",
    "audience_Title": "観客",
    "client_G1_Title": "新規クライアント",
    "client_G2_Title": "最近のクライアント",
    "client_G3_Title": "忠実な顧客",
    "client_G4_Title": "休眠顧客",
    "client_G5_Title": "接続日別のクライアント",
    "on_Which_Day": "どの日に",
    "on_Day": "当日",
    "immediate_Desc": "メッセージはすぐに送信されます。開始するには「次へ」をクリックしてください。",
    "never_Desc": "このキャンペーンは、手動で終了するまで永久に実行されるようにスケジュールされています。",
    "end_Desc": "キャンペーンはここで選択した日付で終了します。",
    "success_Message": "キャンペーンが正常に保存されました。",
    "send_Success_Message": "キャンペーンが正常に送信されました。",
    "msg_Name_Field_Title": "キャンペーンタイトル",
    "valid_campaign_title": "有効なキャンペーンタイトルを入力してください",
    "msg_Type_Title": "メッセージタイプ",
    "sms_Desc": "SMS 1件につき1クレジット",
    "mms_Desc": "MMS 1 件につき 3 クレジット",
    "client_GTO_1": "日",
    "client_GTO_2": "営業時間",
    "minutes": "分",
    "client_GTO_4": "週",
    "time_Option_1": "日",
    "time_Option_2": "営業時間",
    "c_Grp_Day_1": "90日間",
    "c_Grp_Day_2": "60日間",
    "c_Grp_Day_3": "30日間",
    "first": "初め",
    "second_T": "2番",
    "third_T": "三番目",
    "fourth_T": "4番目",
    "fifth_T": "5番目",
    "delete_BS_T": "このキャンペーンは永久に削除されます。",
    "delete_MBS_T": "これらのキャンペーンは永久に削除されます。",
    "cFT_1": "電話",
    "cFT_2": "連絡先名",
    "cFT_3": "連絡先姓",
    "cFT_4": "連絡先メールアドレス",
    "cFT_5": "注記",
    "cFT_6": "誕生日",
    "cFT_7": "会社名",
    "cF_Meta_Tag_1": "%%電話%%",
    "cF_Meta_Tag_2": "%%ファーストネーム%%",
    "cF_Meta_Tag_3": "%%苗字%%",
    "cF_Meta_Tag_4": "%%メール%%",
    "cF_Meta_Tag_5": "%%注記%%",
    "cF_Meta_Tag_6": "%%誕生日%%",
    "cF_Meta_Tag_7": "%%会社名%%",
    "cF_Name_1": "電話",
    "cF_Name_2": "ファーストネーム",
    "cF_Name_3": "苗字",
    "cF_Name_4": "メール",
    "cF_Name_5": "注記",
    "cF_Name_6": "誕生日",
    "cF_Name_7": "会社名",
    "all_clients": "すべてのクライアント",
    "aud_Type_2": "クライアントグループ",
    "aud_Type_3": "顧客グループ",
    "aud_Type_T1": "すべてのクライアント",
    "aud_Type_T2": "クライアントグループ",
    "aud_Type_T3": "顧客グループ",
    "new_clients": "新規クライアント",
    "client_G2": "最近のクライアント",
    "client_G3": "忠実な顧客",
    "client_G4": "休眠顧客",
    "client_G5": "接続日別のクライアント",
    "never_text": "一度もない",
    "ending_on": "終了日",
    "send_T2": "予定",
    "send_Type_3": "定期的",
    "daily": "毎日",
    "weekly_T": "毎週",
    "monthly_T": "月次",
    "yearly_T": "年間",
    "each": "それぞれ",
    "on_the": "オンザ",
    "monday": "月曜日",
    "exclusive_offer": "あなただけの特別オファー",
    "redeem_now": "今すぐ引き換える",
    "redeem_with_cashier": "レジで引き換えてください",
    "lg_Day_2": "火曜日",
    "lg_Day_3": "水曜日",
    "lg_Day_4": "木曜日",
    "lg_Day_5": "金曜日",
    "lg_Day_6": "土曜日",
    "lg_Day_7": "日曜日",
    "msg_Pre_T": "ドミノ",
    "cA_Edit_T1": "全て",
    "cA_Edit_T2": "含まれるもの",
    "cA_Edit_T3": "除外",
    "SMS": "SMS",
    "MMS": "ＭＭＳ",
    "usd_T": "米ドル",
    "cad_T": "CAD",
    "msg": "メッセージ",
    "which_Day": "どの日",
    "end_Date_Tx": "終了日",
    "sDate_Err": "開始日は今日以降である必要があります",
    "eDate_Err": "終了日は開始日より後でなければなりません",
    "start_Date_Req": "開始日は必須です",
    "end_Date_Req": "終了日は必須です",
    "time_req": "時間が必要です",
    "client_GT1": "過去に登録したクライアント",
    "client_GT2": "過去に接続したクライアント",
    "client_GT3": "少なくとも",
    "client_GT4": "最後に",
    "client_GT5": "前回戻ってこなかった",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "テスト",
    "dummy_Birth_Date": "2001年1月1日",
    "image_Req": "メディアまたはURLが必要です",
    "time_Title": "時間",
    "start_date": "開始日",
    "end_date": "終了日",
    "auto_Send_T": "クライアントに自動送信",
    "add_Media": "メディアを追加",
    "in_Title": "で",
    "c_Temp_T": "キャンペーンテンプレート",
    "temp_NT": "テンプレート名",
    "type_T": "タイプ",
    "select_template_type": "テンプレートの種類を選択",
    "sel_Temp_T": "テンプレートの種類を選択",
    "sms_temp_t": "SMS テンプレート",
    "temp_T": "テンプレートテキスト",
    "default_Msg": "こんにちは%% firstname %%さん、ブランチが特別割引です! 本日当店でお食事いただくと、お会計から 10% の特別割引が受けられます!",
    "refill_T": "詰め替え",
    "avl_Credit": "利用可能なクレジット",
    "req_Credit": "必要な総単位数",
    "rem_Credit": "残りのクレジット",
    "refill_Credit": "補充すべきクレジット",
    "clients_Req": "少なくとも1人のクライアントを選択してください",
    "subclient_Req": "選択されたクライアントはサブスクライバーではありません。",
    "refill_Desc": "必要なクレジットを補充してください",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "開始日が無効です",
    "ed_Invalid": "終了日が無効です",
    "img_Url_Invalid": "画像のURLが無効です",
    "time_Invalid": "時間が無効です",
    "time_And_Date_Invalid": "有効な日付と時刻を入力してください",
    "time_Invalid_Bfr": "現在の時刻から少なくとも5分後の時刻を選択してください",
    "sod_Req": "当日発送が必要です",
    "add_Card": "新しいカードを追加",
    "sm_D_Rep_Title": "SMS\/MMS配信レポート",
    "send_Now_T": "今すぐ送信",
    "schd_Now_T": "SMSをスケジュールする",
    "test_SMS_T": "テストSMSを送信",
    "save_AD_T": "下書きとして保存",
    "back_TE_T": "編集に戻る",
    "reset_tex": "リセット",
    "update_tex": "アップデート",
    "dum_msg1": "顧客名を表示するには、テンプレートで%% customer_name %%を使用します。",
    "dum_msg2": "予約時間を表示するには、テンプレートで%% date_time %%を使用します。",
    "dum_msg3": "テーブル番号を表示するには、テンプレートで%% table_no %%を使用します。",
    "embedded_link": "埋め込みリンク",
    "img_title": "画像",
    "img_input_text": "文章：",
    "img_join_text": " リストに参加する",
    "img_copy_text": "コピー",
    "img_not_found": "生成された画像が見つかりません!!",
    "or_text": "または",
    "web_signup_image_content": "このフォームを送信し、テキストでサインアップすると、マーケティングテキストメッセージ（プロモーションコードやリマインダーなど）を受信することに同意したことになります。メッセージとデータ料金が適用される場合があります。メッセージの頻度は異なります。STOPと返信することでいつでも登録を解除できます。",
    "img_download_success": "画像が正常にダウンロードされました",
    "embedded_link_copied": "埋め込みリンクが正常にコピーされました",
    "media_url_required": "メディアまたはURLが必要です",
    "invalid_image_url": "画像のURLが無効です",
    "invalid_file": "ファイルが無効です",
    "image_error_1mb": "1MB未満の画像を挿入してください",
    "image_error_2_5mb": "2.5MB未満の画像を挿入してください",
    "image_error_3mb": "3MB未満の画像を挿入してください",
    "change_title": "変化",
    "drop_file_text": "ここにファイルをドロップするかクリックしてアップロードしてください",
    "apply_text": "適用する",
    "search_here": "ここで検索",
    "update_status": "ステータスの更新",
    "reservation_deleted_permanently": "この予約は完全に削除されます。",
    "table_text": "テーブル",
    "add_table_button": "新しいテーブルを追加",
    "add_table_text": "テーブルを追加",
    "edit_table_text": "テーブルを編集",
    "table_delete_text": "このテーブルは永久に削除されます。",
    "multiple_table_delete_text": "これらのテーブルは永久に削除されます。",
    "table_error_name": "テーブル名を入力してください",
    "table_error_invalid_name": "有効なテーブル名を入力してください",
    "table_error_floor": "フロアを選択してください",
    "table_error_select": "テーブルを選択してください",
    "table_capacity_text": "テーブル容量",
    "capacity_text": "容量",
    "table_occasion_text": "あらゆる機会にふさわしいテーブルを見つける",
    "table_name_text": "テーブル名",
    "table_capacity_error": "テーブル容量が必要です",
    "cancel_text": "キャンセル",
    "submit_text": "提出する",
    "select_valid_date": "有効な日付を選択してください",
    "select_valid_time": "有効な時間を選択してください",
    "valid_contact_number": "有効な連絡先番号を入力してください",
    "date_req": "日付は必須です",
    "date_error_later_than_today": "日付は今日の日付以降である必要があります",
    "time_error_later_than_5_minutes": "現在の時刻から少なくとも5分後の時刻を選択してください",
    "number_person_req": "人数は必須です",
    "contact_no_req": "連絡先番号は必須です",
    "contact_no": "連絡先",
    "select_time": "時間を選択",
    "for_how_many_person": "何人ですか？",
    "f_name": "ファーストネーム",
    "l_name": "苗字",
    "business_name": "会社名",
    "business_no": "事業者番号",
    "no_for_sign_in": "この番号はサインインに使用されます",
    "business_email": "ビジネスメール",
    "notes_tex": "注記",
    "floor_deleted_permanently": "このフロアは永久に削除されます。",
    "add_floor": "フロアを追加",
    "edit_floor": "フロアを編集",
    "name_text": "名前",
    "mobile_no": "携帯番号",
    "person_text": "人",
    "date_and_time": "日付と時刻",
    "actions_text": "アクション",
    "extra_text": "余分な",
    "floor_name_req": "フロア名は必須です",
    "floor_name": "フロア名",
    "status_text": "状態",
    "category_status_req": "カテゴリステータスは必須です",
    "table_deleted_permanently": "このテーブルは永久に削除されます。",
    "tables_deleted_permanently": "このテーブルは永久に削除されます。",
    "back_to_home": "ホームに戻る",
    "link_copied_text": "リンクをクリップボードにコピーしました",
    "cust_dash_head_to_title": "に",
    "cust_dash_head_location_title": "位置",
    "select_location_title": "場所を選択",
    "all_locations_label": "すべての場所",
    "rep_customer_feedback_analytics": "😊 リアルタイム分析で顧客からのフィードバックを追跡",
    "rep_customer_txt": "顧客",
    "rep_delete_title": "この評判は永久に削除されます。",
    "rep_qr_def_txt": "QR コードをスキャンして、あなたの体験に関する貴重なフィードバックを私たちと共有してください。",
    "delete_title": "消去",
    "user_list_title": "顧客リスト",
    "os_nfc_txt": "NFC カード",
    "os_sign_holder_txt": "サインホルダースタンド",
    "os_store_Sign_holder_txt": "店舗看板ホルダー",
    "StaT": "統計",
    "AllgT": "すべてのグループ",
    "FavT": "お気に入り",
    "MostActT": "最もアクティブ",
    "grT": "グループ名",
    "keywT": "キーワード",
    "exSubT": "既存の加入者へのメッセージ",
    "ArchT": "アーカイブ済み",
    "gNotiSms": "新しい連絡先ごとにSMSで通知する",
    "gNotiEmail": "新しい連絡先ごとにメールで通知する",
    "reqGName": "グループ名は必須です",
    "validGN": "有効なグループ名を入力してください",
    "valid_phone_no": "有効な電話番号を入力してください",
    "phone_no_req": "電話番号は必須です",
    "required_message_text": "メッセージは必須です",
    "required_discount_text": "割引が必要です",
    "less_than_discount": "割引額は5000未満でなければなりません",
    "discount_percentage_invalid": "割引率は 100 以下にする必要があります",
    "discount_type_req": "割引の種類は必須です",
    "discount_text_req": "クーポンテキストは必須です",
    "reqContactNo": "連絡先番号は必須です",
    "reqMsgNT": "キャンペーンタイトルは必須です",
    "reqLinkT": "リンクが必要です",
    "delMGT": "このグループは永久に削除されます。",
    "defMemMsg": "ここにメッセージを入力してください",
    "add_cust_to_grp_title": "クライアントをグループに追加",
    "group_title": "グループ",
    "group_create_title": "グループを作成",
    "group_name_txt": "グループ名",
    "group_table_keyword_title": "キーワード",
    "actions_title": "アクション",
    "clients_title": "クライアント",
    "send_title": "送信",
    "qr_title": "質問",
    "delete_group_txt": "このグループは永久に削除されます。",
    "delete_groups_txt": "このグループは永久に削除されます。",
    "delete_client_title": "このクライアントは永久に削除されます。",
    "delete_clients_title": "このクライアントは永久に削除されます。",
    "delete_multiple_title": "複数削除",
    "wel_sms_mms": "ようこそSMS\/MMSを送信しますか?",
    "key_words_title": "キーワード",
    "srch_plch_txt": "ここで検索",
    "req_location_id_title": "場所は必須です",
    "create_text": "作成する",
    "view_text": "ビュー",
    "immediately": "すぐに",
    "business_name_req": "会社名は必須です",
    "business_no_req": "事業者番号が必要です",
    "valid_business_name": "有効な会社名を入力してください",
    "valid_business_no": "有効な事業番号を入力してください",
    "address_req": "住所は必須です",
    "valid_address": "有効な住所を選択してください",
    "time_zone_req": "タイムゾーンは必須です",
    "image_req": "2.5MB未満の画像を挿入してください",
    "valid_file": "ファイルが無効です。",
    "logo": "ロゴ",
    "time_zone": "タイムゾーン",
    "save": "保存",
    "account_type_req": "アカウントの種類は必須です",
    "gst_no_req": "GST番号が必要です",
    "gst_no_valid": "有効なGST番号を入力してください",
    "set_up_payments": "支払いを設定する",
    "billing_details": "請求の詳細を入力してください",
    "billing_details_desc": "請求の詳細は、menuonline からの毎月の請求書に表示されます。",
    "account_type": "アカウントタイプ",
    "select_account_type": "アカウントの種類を選択",
    "gst_no": "GST番号",
    "transaction_details": "取引の詳細",
    "payment_method": "支払方法",
    "billing_period": "請求期間",
    "paid_by": "支払者",
    "download": "ダウンロード",
    "pay_now": "今すぐ支払う",
    "pull_down_refresh": "下に引いて更新",
    "release_refresh": "リフレッシュするにはリリースしてください",
    "billing_details_text": "請求の詳細",
    "payment_methods": "お支払い方法",
    "invoice": "請求書",
    "invoice_to": "請求先:",
    "issue_date": "発行日",
    "due_date": "期日",
    "amount_due": "支払金額",
    "charges": "料金",
    "sub_total_capitalize": "小計",
    "hst": "ハッブル宇宙望遠鏡",
    "grand_total": "総計",
    "invoice_generated_on": "請求書生成日",
    "contact_us": "お問い合わせ",
    "invoice_issue": "請求書を読んでもまだ疑問がある場合は",
    "call": "電話",
    "send_sms": "SMSを送信",
    "payment_success": "支払いが完了しました",
    "edit_credit_card": "クレジットカードの編集",
    "add_credit_card": "クレジットカードを追加",
    "modify_card_info": "カード情報を変更する",
    "enter_card_info": "カード情報を入力してください",
    "account_no_req": "口座番号が必要です",
    "card_name_req": "カードの名前は必須です",
    "expiry_date_req": "有効期限が必要です",
    "valid_expiry_date": "有効な有効期限を入力してください",
    "valid_cvv": "有効なCVVを入力してください",
    "cvv_req": "CVVが必要です",
    "card_no": "カード番号",
    "name_of_card": "カード名",
    "expiry": "有効期限",
    "mm_text": "年\/月",
    "cvv": "CVV",
    "set_as_default": "デフォルトとして設定",
    "add_new_card": "新しいカードを追加",
    "all_credit_card": "すべてのクレジットカード",
    "fastest_checkout": "安全で信頼性の高いプラットフォームで最速のチェックアウト。大規模なビジネスグループから信頼されています。",
    "coupon_button_req": "クーポンボタンのテキストは必須です",
    "max19_characters_allowed": "最大19文字まで入力できます",
    "fine_print_text_req": "細字のテキストが必要です",
    "clients_req": "少なくとも1人のクライアントを選択してください",
    "client_groups": "クライアントグループ",
    "day_text": "日",
    "valid_days": "日の値が大きすぎます。有効な日を入力してください",
    "hours_text": "営業時間",
    "enter_valid_hours": "時間の値が大きすぎます。有効な時間を入力してください",
    "enter_valid_min": "分値が大きすぎます。有効な分を入力してください",
    "clients_with_connection_required": "最後に接続したクライアントが必要です",
    "connection_required": "接続が必要です",
    "connection_value_too_large": "接続値が大きすぎます。有効な接続を入力してください",
    "minutes_required": "所要時間",
    "clients_with_at_least_connection_required": "少なくとも接続のあるクライアントが必要です",
    "last_minutes_req": "最後の数分が必要です",
    "clients_not_returned_required": "最後の数分以内に返還されなかった人は",
    "not_return_in_last_minutes_invalid": "最後の数分以内に返却しない場合は、最後の数分以内に返却する必要があります。",
    "customerGroups": "顧客グループ",
    "select_customer_group": "顧客グループを選択してください",
    "location_required": "場所が必要です",
    "start_date_required": "開始日は必須です",
    "start_date_invalid": "開始日が無効です",
    "start_date_invalid_error": "開始日は今日以降である必要があります",
    "recurring": "定期的",
    "end_date_required": "終了日は必須です",
    "end_date_invalid": "終了日が無効です",
    "end_date_invalid_error": "終了日は開始日より後でなければなりません",
    "time_invalid": "時間が無効です",
    "monthly_text": "月次",
    "send_day_req": "当日発送が必要です",
    "loyal_clients": "忠実な顧客",
    "recent_clients": "最近のクライアント",
    "lapsed_clients": "休眠顧客",
    "clients_connection_date": "接続日別のクライアント",
    "scheduled_text": "予定",
    "weekly": "毎週",
    "selected_clients_not_subscriber": "選択されたクライアントはサブスクライバーではありません。",
    "message_preview_title": "メッセージのプレビュー",
    "coupon_preview_title": "クーポンプレビュー",
    "form_text": "形状",
    "preview_text": "プレビュー",
    "next_text": "次",
    "send_test_SMS": "テストSMSを送信",
    "save_draft": "下書きとして保存",
    "back_to_edit": "編集に戻る",
    "select_payment_method": "お支払い方法を選択してください",
    "schedule_SMS": "SMSのスケジュール",
    "send_now": "今すぐ送信",
    "get_more_ratings": "もっと評価を得る",
    "overview": "概要",
    "reset_campaign": "リセットキャンペーン",
    "permissions": "権限",
    "location_name": "場所名",
    "phone_no": "電話番号",
    "location_email_address": "所在地 メールアドレス",
    "located_business": "あなたの会社はどこにありますか?",
    "business_logo": "ビジネスロゴ",
    "congratulations": "おめでとう",
    "almost_done": "もうすぐ終わりです",
    "publish": "公開",
    "about_your_business": "あなたのビジネスについて",
    "add_your_location": "場所を追加",
    "publish_location": "公開場所",
    "location_name_req": "場所名は必須です",
    "valid_location_name": "有効な場所名を入力してください",
    "business_logo_req": "ビジネスロゴが必要です",
    "please_accept_terms": "利用規約に同意してください",
    "add_new_location": "新しい場所を追加",
    "edit_location": "場所を編集",
    "add_location": "場所を追加",
    "existing_msg_subscriber_txt": "既存の加入者へのメッセージ",
    "msg_capitalize_txt": "メッセージ",
    "group_noti_sms": "新しい連絡先ごとにSMSで通知する",
    "group_noti_email": "新しい連絡先があったらメールで通知する",
    "group_member_msg": "あなたはすでに私たちのグループの一員のようです!!",
    "group_mend_msg": "終了するにはSTOPを押してください。ヘルプが必要な場合はHELPを押してください。SMSとデータ料金が適用される場合があります",
    "Disclaimer_title": "免責事項テキスト: ",
    "group_def_msg": "現在オンラインです! サービス メニューを確認し、今すぐ次の予約をしてください。",
    "required_msg_txt": "メッセージは必須です",
    "required_Key_txt": "キーワードは必須です",
    "required_mem_msg": "購読者メッセージは必須です",
    "client_list_title": "クライアントリスト",
    "add_contact_txt": "連絡先を追加",
    "delete_all_clients_txt": "すべてのクライアントを削除",
    "delete_all_clients_msg": "すべてのクライアントを削除してもよろしいですか? 取得できません。",
    "delete_all_txt": "すべて削除",
    "timeline_title": "タイムライン",
    "unsubscribe_title": "登録解除",
    "subscribe_title": "購読する",
    "unsubscribe_confirm_msg": "この顧客を購読解除者としてマークしますか?",
    "subscribe_confirm_msg": "この顧客を購読者としてマークしますか?",
    "no_title": "いいえ",
    "yes_title": "はい",
    "client_name_title": "クライアント名",
    "source_title": "ソース",
    "contact_file_Req": "連絡先ファイルは必須です",
    "req_title": "必須",
    "opt_in_req": "インポートされた連絡先は100%オプトインである必要があります",
    "image_invalid_error": "ファイルが無効です。",
    "import_contacts_msg": "連絡先をインポート",
    "csv_upload_title": "アップロードするCSV連絡先ファイル *",
    "csv_file_desc": "csv ファイルのみをアップロードしてください - csv ファイルの列 - 名、姓、メール ID、携帯電話番号、性別、生年月日 (MM\/DD\/YYYY) - 携帯電話番号にはスペース、ダッシュ、括弧を使用しないでください",
    "to_download_title": "デモファイルをダウンロードするにはここをクリックしてください",
    "contains_header_title": "はい、このファイルにはヘッダーが含まれています",
    "opt_in_100_txt": "はい、インポートされた連絡先は100%オプトインです",
    "DisT": "免責事項テキスト: ",
    "gMendMsg": "終了するにはSTOPを押してください。ヘルプが必要な場合はHELPを押してください。SMSとデータ料金が適用される場合があります",
    "reqKeyT": "キーワードは必須です",
    "reqMemMsg": "購読者メッセージは必須です",
    "reqMsgT": "メッセージは必須です",
    "gMemberMsg": "あなたはすでに私たちのグループの一員のようです!!",
    "gdefMsg": "現在オンラインです! 当社のサービス メニューを確認し、今すぐ次の予約を行ってください。",
    "next_title": "次",
    "male_title": "男",
    "male_val": "男",
    "female_title": "女性",
    "female_val": "女性",
    "others_txt": "その他",
    "others_val": "その他",
    "validBirthdate": "有効な生年月日を入力してください",
    "valid_phone_no_title": "有効な電話番号を入力してください",
    "required_phone_no_title": "電話番号は必須です",
    "add_new_client_txt": "新しいクライアントを追加する",
    "update_client_txt": "クライアントの更新",
    "phone_num_text": "電話番号",
    "dob_title": "生年月日",
    "select_gender_title": "性別を選択",
    "timelineTitle": "タイムライン",
    "confirm_location": "場所を確認",
    "feedback_title": "フィードバック",
    "rep_question_1": "ビジネスはクリーンでしたか?",
    "rep_que_2": "食事は美味しかったですか？",
    "rep_que_3": "私たちのサービスは親切でしたか?",
    "rep_que_4": "当社のサービスは迅速でしたか?",
    "business_location_req_title": "事業所の所在地は必須です",
    "valid_location_err_txt": "有効な住所を選択してください",
    "rep_management_title": "レビュー管理を増やす",
    "rep_about_desc": "評判は顧客にフィードバックを求めることでGoogleの評価を高めるのに役立ちます",
    "preview_title": "プレビュー",
    "rep_preview_txt": "評判プレビュー",
    "back_title": "戻る",
    "fine_print_txt": "レジで引き換えてください",
    "redeem_me_title": "償還する",
    "rep_nxt_visit_txt": "次回のご来店の際には",
    "device_type": "使用するデバイスの種類",
    "connection_method": "接続方法",
    "peak_days": "ピーク日",
    "peak_hours": "ピーク時間",
    "guest_by_day": "日替わりゲスト",
    "guest_visit": "ゲスト訪問",
    "connection": "繋がり",
    "connection_duration": "接続時間",
    "guest_visit_1": "1回",
    "guest_visit_2": "2回",
    "guest_visit_3_5": "3〜5回",
    "guest_visit_6_10": "6～10回",
    "guest_visit_11_25": "11～25回",
    "guest_visit_26_100": "26～100回",
    "guest_visit_100_plus": "100回以上",
    "device_android_total": "トータルアンドロイド",
    "device_android": "アンドロイド",
    "device_desktop": "デスクトップ",
    "device_ios": "イオス",
    "device_ios_total": "合計Ios",
    "device_desktop_total": "トータルデスクトップ",
    "connection_duration_10": "<=10 分",
    "connection_duration_20": "11-20分",
    "connection_duration_30": "21-30分",
    "connection_duration_40": "31-45分",
    "connection_duration_60": "46-60分",
    "connection_method_email": "メール",
    "connection_method_sms": "SMS",
    "connection_method_google": "グーグル",
    "connection_method_facebook": "フェイスブック",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "すべてのゲスト",
    "new_Guest_txt": "新しいゲスト",
    "connections_txt": "接続",
    "hotspot": "ホットスポット",
    "hotspot_list": "ホットスポットリスト",
    "add_new_hotspot": "新しいホットスポットを追加",
    "hotspot_information": "ホットスポット情報",
    "edit_details_button": "詳細を編集",
    "wifi_info_message": "接続して無料WiFiをお楽しみください",
    "connection_message": "素晴らしい、接続されました ",
    "connection_message_suffix": " Wi-Fi",
    "wifi": "Wi-Fi",
    "login_to_access": "アクセスするにはログインしてください",
    "verification_code": "検証コード",
    "verification_code_message": "送信された確認コードを入力してください ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wi-Fi",
    "your_name": "ところであなたのお名前は何ですか？",
    "your_birthdate": "あなたの生年月日を教えていただけますか？",
    "request_guest_wifi_name": "ゲストWi-Fi名を入力してください",
    "request_device_key": "デバイスキーを入力してください",
    "request_maximum_internet_access_length": "最大インターネットアクセス時間を選択してください",
    "mac_address": "MACアドレス",
    "device_port": "デバイスポート",
    "hardware": "ハードウェア",
    "current_uptime": "現在の稼働時間",
    "nearby_devices": "近くのデバイス",
    "firmware": "ファームウェア",
    "who_are_you": "あなたは誰ですか？",
    "where_to_contact_you": "どこで連絡を取ればよいでしょうか?",
    "your_area_code": "市外局番は何ですか?",
    "connected": "接続",
    "delete_hotspot_message": "このホットスポットは永久に削除されます。",
    "delete_multiple_hotspots_message": "これらのホットスポットは永久に削除されます。",
    "speed_error": "速度は少なくとも0.01である必要があります",
    "speed_max_error": "無制限の速度を得るには 1024 までの値を入力するか、サポートされている範囲内でより低い値を選択してください",
    "device_ssid": "デバイスのSSID",
    "device_ssid_two": "デバイスSSID 2",
    "device_ssid_two_wpa": "デバイスSSID 2 WPA",
    "device_key": "デバイスキー",
    "select_location": "場所を選択",
    "select_maximum_internet_access_length": "最大インターネットアクセス時間を選択",
    "download_speed": "ダウンロード速度",
    "upload_speed": "アップロード速度",
    "network_length_1": "15分",
    "network_length_2": "30分",
    "network_length_3": "45分",
    "network_length_4": "1時間",
    "network_length_5": "2時間",
    "network_length_6": "4時間",
    "network_length_7": "6時間",
    "network_length_8": "8時間",
    "network_length_9": "10時間",
    "network_length_10": "12時間",
    "employee_wifi_name": "従業員Wifi名",
    "employee_wifi_password": "従業員Wifiパスワード",
    "guest_wifi_name": "ゲストWi-Fi名",
    "menu_other_products_txt": "その他の商品 | オンラインメニュー",
    "menu_home_text": "ホーム | オンラインメニュー",
    "whatsapp_title": "ワッツアップ",
    "select_campaign_type": "キャンペーンの種類を選択",
    "select_readymade_templates": "既成のテンプレートから選択するか、独自のテンプレートを作成してください",
    "campaign_title_required": "キャンペーンタイトルは必須です",
    "type_here": "ここに入力してください...",
    "location_permission_req": "位置情報の許可が必要です",
    "platform_permission_req": "プラットフォームの許可が必要です",
    "profile_picture": "プロフィール写真",
    "click_to_upload": "クリックしてアップロード",
    "location_permission": "位置情報の許可",
    "pin": "ピン",
    "platform_permission": "プラットフォームの許可",
    "set_pin": "PINを設定する",
    "list_of_users": "ユーザーリスト",
    "create_user": "ユーザーを作成",
    "terms_and_condition_req": "利用規約は必須です",
    "terms_and_conditions": "利用規約",
    "recommended": "推奨",
    "mo": "\/月",
    "unsubscribe": "登録解除",
    "cancel_subscription": "本当にサブスクリプションをキャンセルしますか?",
    "upgrade": "アップグレード",
    "plan_upgraded_successfully": "プランのアップグレードに成功しました！",
    "menu_online": "メニューオンライン",
    "support": "サポート",
    "wifiC": "WI-FI",
    "billing": "請求する",
    "please_upgrade": "すべての機能を楽しむにはアップグレードしてください",
    "you_have_subscribed": "あなたは購読しました ",
    "plan": "プラン",
    "of_text": "の",
    "days": "日数",
    "remaining_days_until_plan": " プランの更新が必要になるまでの残り日数",
    "manage_your_screens": "画面を管理する",
    "screens_you_save": "画面",
    "add_more_screens": "スクリーンを追加する",
    "addition_screen": "追加画面",
    "per_screen": "1画面あたり$",
    "per_box": "1箱あたり$",
    "shipping_changes": "配送料",
    "upto4_boxes": "最大4箱 ",
    "charge_now": "今すぐ料金をお支払いください",
    "updated_reoccuring": "更新された定期購読は",
    "current_reoccuring": "現在再発中: ",
    "no_text": "いいえ",
    "android_box": "アンドロイドボックス",
    "old_password_req": "古いパスワードが必要です",
    "new_password_req": "新しいパスワードが必要です",
    "confirm_password_req": "パスワードの確認が必要です",
    "password_do_not_match": "パスワードが一致しません",
    "old_password": "以前のパスワード",
    "new_password": "新しいパスワード",
    "confirm_password": "パスワードを認証する",
    "copy_working_hours": "これらの勤務時間をすべての日にコピーしますか?",
    "yes_copy": "はい、コピーします",
    "closed": "閉鎖",
    "opening_time": "営業時間",
    "closing_time": "閉店時間",
    "description": "説明",
    "file_exist": "ファイルは既に存在します。他のファイルを選択してください",
    "bussiness_images": "ビジネスイメージ",
    "display_info_on_market_place": "以下の情報をマーケットプレイスに表示しますか?",
    "profile_ready": "プロフィールが準備できました!",
    "client_book_with_online": "クライアントはオンラインで予約できるようになりました。開始するには、以下のリンクを共有してください。",
    "copy_link": "リンクをコピー",
    "see_your_profile": "プロフィールを見る",
    "ok_got_it": "分かりました",
    "preview_profile": "プロフィールをプレビューして、どのように表示されるかを確認します。",
    "opening_hours": "営業時間",
    "display_data": "データを表示",
    "manage_profile": "プロフィールの管理",
    "phone_req": "電話番号は必須です",
    "market_place_img_req": "マーケットプレイスの画像が必要です",
    "add_new_market_place": "新しいマーケットプレイスを追加",
    "edit_market_place": "マーケットプレイスを編集",
    "no_reviews_yet": "レビューはまだありません",
    "good": "良い",
    "average": "平均",
    "add_marketPlace": "マーケットプレイスを追加",
    "all_title": "全て",
    "included_title": "含まれるもの",
    "excluded_title": "除外",
    "clients_subscribed": "過去に登録したクライアント",
    "clients_groups": "クライアントグループ",
    "customer_groups": "顧客グループ",
    "audience_title": "観客",
    "client_gt5": "前回戻ってこなかった",
    "select_all": "すべて選択",
    "modify": "修正する",
    "campaign_title": "キャンペーンタイトル",
    "msg_type": "メッセージタイプ",
    "enter_discount": "割引を入力",
    "discount_type": "割引タイプ",
    "coupon_text": "クーポンテキスト",
    "enter_coupon_text": "クーポンテキストを入力",
    "coupon_button_text": "クーポンボタンのテキスト",
    "enter_coupon_button_text": "クーポンボタンのテキストを入力",
    "fine_prin": "細則",
    "enter_fine_prin": "細字テキストを入力",
    "campaign_dec": "キャンペーン開始時間の30分前までキャンペーン内容を変更することができます。SMSキャンペーンの料金はキャンペーン開始時間の30分前より発生します。",
    "blast_text_message_dec": "一斉テキストメッセージの準備ができました。お支払い方法を選択して、メッセージを送信してください。",
    "payment_completed": " お支払いは ",
    "total_cost": "総費用",
    "close_title": "近い",
    "friday": "金曜日",
    "saturday": "土曜日",
    "sunday": "日曜日",
    "thursday": "木曜日",
    "tuesday": "火曜日",
    "wednesday": "水曜日",
    "port_txt": "ポート",
    "today_title": "今日",
    "yesterday_title": "昨日",
    "last_30_days_txt": "過去30日間",
    "this_month_txt": "今月",
    "last_month_txt": "先月",
    "valid_date_title": "有効な日付を選択してください",
    "valid_business_name_txt": "有効な会社名を入力してください",
    "req_bus_add_txt": "会社住所は必須です",
    "req_domain_name_txt": "ドメイン名は必須です",
    "basic_info_txt": "あなたのビジネスの基本情報",
    "loyalty_qr_def_txt": "QR コードをスキャンし、ロイヤルティ パンチ カードに加入して特典を獲得しましょう。",
    "last_stamp_txt": "最後のスタンプ",
    "collected_on_txt": "収集日",
    "stamp_details_txt": "スタンプの詳細",
    "add_stamp_txt": "スタンプを追加",
    "choose_brand_color_txt": "ブランドカラーと言語を選択してください🎨",
    "change_anytime_txt": "いつでも変更できます",
    "like_to_get_txt": "取得したいですか？",
    "your_next_visit_txt": "次回のご来店の際はいかがでしょうか？",
    "time_to_time_get_offers": "定期的に素晴らしいオファーやプロモーションを受け取りたいですか?",
    "respond_back_to_you": "誰かに返信してもらいたいですか?",
    "input_serve_better": "あなたのご意見は、私たちのチームがより良いサービスを提供するために役立ちます。",
    "do_it_txt": "やりましょう",
    "take_15_s": "15秒かかります",
    "rate_on_google_desc": "Google で評価していただけますか?",
    "may_be_next_time": "また今度",
    "thank_you_txt": "ありがとう",
    "look_next_txt": "次回のご来店をお待ちしております！",
    "deleteUserTitle": "このユーザーは永久に削除されます。",
    "deleteMUserTitle": "このユーザーは永久に削除されます。",
    "change_pin": "ピンの変更",
    "area_code": "市外局番は何ですか? ",
    "add_menu": "メニューを追加",
    "menu_name": "メニュー名",
    "add_menu_placeholder": "ピザ、ハンバーガー、ベーグルなど",
    "enable_data_Collection": "データ収集を有効にする",
    "add_new_menu": "新しいメニューを追加",
    "rename_menu": "メニュー名の変更",
    "preview": "プレビュー",
    "generate_qr": "生成されたQR",
    "edit_menu": "編集メニュー",
    "remove_menu": "メニューを削除",
    "menu_delete_msg": "このメニューは永久に削除されます",
    "menus_delete_msg": "このメニューは永久に削除されます",
    "view_menu_dialoage_msg": "誕生日にプレゼントをもらいたいですか？",
    "skip": "スキップ",
    "cliam_your_gift": "ギフトを受け取る",
    "collect_form": "フォームを収集",
    "enter_first_name": "名を入力してください",
    "enter_last_name": "姓を入力してください",
    "enter_email": "メールアドレスを入力",
    "enter_dob": "生年月日を入力してください",
    "enter_number": "番号を入力",
    "select_gender": "性別を選択",
    "congratulations_desc": "🎉 おめでとうございます！ギフトの請求リクエストが正常に送信されました。弊社のチームからすぐにご連絡いたします。🎉",
    "male_heading": "男",
    "male_text": "男",
    "female_heading": "女性",
    "female_text": "女性",
    "others_heading": "その他",
    "other_text": "その他",
    "BirthD": "生年月日",
    "GenderT": "性別",
    "EmailT": "メール",
    "dobT": "生年月日",
    "capitalize_menu": "メニュー",
    "select_menu": "メニューを選択",
    "manage_variant": "バリアントの管理",
    "add_products": "製品を追加",
    "add_category": "カテゴリーを追加",
    "category_delete": "このカテゴリは永久に削除されます",
    "variation_delete": "このバリエーションは製品から削除されます。",
    "product_delete": "この製品は永久に削除されます。",
    "categories_delete": "このカテゴリは永久に削除されます。",
    "products_delete": "この製品は永久に削除されます。",
    "category": "カテゴリ",
    "price": "価格",
    "food_product_placeholder": "ピザ、ハンバーガー、ベーグルなど",
    "active_title": "アクティブ",
    "inActive_title": "非アクティブ",
    "status_capital": "状態",
    "cat_status_require": "カテゴリステータスは必須です",
    "cat_name_require": "カテゴリ名は必須です",
    "category_name": "カテゴリ名",
    "status": "状態",
    "lgDay1": "月曜日",
    "lgDay2": "火曜日",
    "lgDay3": "水曜日",
    "lgDay4": "木曜日",
    "lgDay5": "金曜日",
    "lgDay6": "土曜日",
    "lgDay7": "日曜日",
    "is_closed_title": "閉鎖",
    "book_table_title": "テーブルを予約する",
    "emailErrSub": "メールの件名は必須です",
    "email_subject": "メールの件名",
    "contactUsfrmTitleEmail": "メールに返信",
    "companyInfo": "ローレム・イプサムはローレム・ヒリッグ、ローレム・イプサムはフラエッツです。ローレム・イプサムはローレム・ヒリグ、ローレム・イプサムはフラエッツです。ローレム・イプサムはローレム・ヒリッグ、ローレム・イプサムはフラエッツです。",
    "footerSTitle1": "メニューオンラインについて",
    "footerSTitle1Lnk2": "特徴",
    "footerSTitle1Lnk3": "ブログ",
    "footerSTitle2": "法律上の",
    "footerSTitle2Lnk1": "利用規約",
    "footerSTitle2Lnk2": "プライバシーポリシー",
    "footerSTitle3": "ビジネス向け",
    "footerSTitle3Lnk1": "パートナー向け",
    "footerSTitle3Lnk2": "価格",
    "footerSTitle3Lnk3": "パートナーへのサポート",
    "footerCopyRithgTxt": "  Menuonlineまたはその関連会社",
    "homeTitle": "家",
    "contactTitle": "お問い合わせ",
    "aboutTitle": "私たちについて",
    "homeMenuTitle": "メニュー",
    "bookTableTitle": "テーブルを予約する",
    "vDateT": "有効な日付を選択してください",
    "reqDateT": "日付は必須です",
    "dateGtErr": "日付は今日の日付以降である必要があります",
    "timeInvalid": "時間が無効です",
    "reqTimeT": "時間が必要です",
    "timeInvalidBfr": "現在の時刻から少なくとも5分後の時刻を選択してください ",
    "PerReq": "人数は必須です",
    "validcNoT": "有効な連絡先番号を入力してください",
    "reqCrT": "通貨が必要です",
    "reqPrT": "価格が必要です",
    "reCateT": "カテゴリーは必須です",
    "reqVrT": "バリアントが必要です",
    "reqVrVldT": "有効なバリアントを入力してください ",
    "validLNameT": "有効な姓を入力してください",
    "sDateInValid": "開始日が無効です",
    "minmReq": "最低限必要な",
    "resLblDate": "日付を選択",
    "resLblTime": "時間を選択",
    "perT": "何人用ですか？",
    "resLblEmail": "メールアドレスを入力してください。",
    "resLblNote": "予約メモを追加する",
    "imageError1MB": "1MB未満の画像を挿入してください",
    "imageError": "2.5MB未満の画像を挿入してください",
    "imageError3Mb": "3MB未満の画像を挿入してください",
    "imageInVldError": "ファイルが無効です。",
    "addProMT": "製品メニュー",
    "proT": "製品名",
    "reqProT": "製品名は必須です",
    "proPT": "製品価格",
    "reservationSuccTitle": "ご予約が完了しました",
    "book": "本",
    "custzName": "カスタマイズ名",
    "always": "いつも",
    "proImgReq": "製品画像は必須です",
    "selCustmT": "カスタマイズを選択するか、新規作成してください",
    "visStR": "表示ステータスは必須です",
    "avlblScR": "空き状況スケジュールを選択してください",
    "addonPR": "アドオン製品を選択してください",
    "upsellPR": "アップセル製品を選択してください",
    "markItemR": "マークアイテム製品を選択してください",
    "caloriesR": "カロリーは必須です",
    "allergensR": "アレルゲン製品を選択してください",
    "prepInstR": "準備手順が必要です",
    "staffNR": "スタッフメモは必須です",
    "discountR": "割引が必要です",
    "validDiscE": "有効な割引を入力してください",
    "disday": "日によって",
    "plSelDayT": "日を選択してください",
    "sTimeReq": "開始時間は必須です",
    "sTimeInValid": "開始時間が無効です",
    "eTimeReq": "終了時間は必須です",
    "eTimeInValid": "終了時間が無効です",
    "sDateReq": "開始日は必須です",
    "eDateReq": "日付は必須です",
    "eDateInValid": "日付が無効です",
    "disdate": "日付から",
    "disdate1": "現在まで",
    "disdate2": "開始日",
    "currT": "通貨",
    "iconR": "マークアイテムアイコンを選択してください",
    "minT": "最小",
    "maxT": "最大",
    "itemNT": "アイテム名",
    "itemPT": "商品価格",
    "descProT": "製品の説明",
    "cateT": "カテゴリ",
    "selProCateT": "製品カテゴリーを選択してください",
    "reqMT": "メニュー名は必須です",
    "ViewMenu": "表示メニュー",
    "CopyMenu": "メニューをコピー",
    "EditMenu": "編集メニュー",
    "RemoveMenu": "メニューを削除",
    "welcomeMnuTitle": "ようこそ",
    "reviewT": "レビュー",
    "userErrorMsg": "申し訳ございません。エラーが発生しました。",
    "reqCateT": "カテゴリ名は必須です",
    "mngCustT": "カスタマイズの管理",
    "custNReq": "カスタマイズ名は必須です",
    "incReq": "含まれていることが必須です",
    "minmValid": "有効な最小値を入力してください",
    "maxmReq": "最大値が必要です",
    "cust": "カスタマイズ",
    "crCust": "新しいカスタマイズを作成する",
    "custList": "カスタマイズリスト",
    "delCustmzT": "このカスタマイズは完全に削除されます。",
    "mkNameR": "マーク項目名は必須です",
    "mkDelT": "このマーク項目は永久に削除されます。",
    "hideText": "隠れる",
    "showText": "見せる",
    "device_mac_txt": "デバイス Mac",
    "delete_hotspot_txt": "このホットスポットは永久に削除されます。",
    "delete_hotspots_txt": "このホットスポットは永久に削除されます。",
    "emp_wifi_name": "従業員Wifi名",
    "max_limit_txt": "速度を無制限にするには、「1024」と入力します。この値により上限がなくなり、スループットが最大になります。",
    "device_port_txt": "デバイスポート",
    "firmware_txt": "ファームウェア",
    "hotspot_info_txt": "ホットスポット情報",
    "editDBtn": "詳細を編集",
    "birth_date": "生年月日",
    "di_theme_clr": "テーマカラーは7桁",
    "color_Invalid_txt": "有効なカラーコードを選択してください",
    "Req_theme_clr": "テーマカラーは必須です",
    "select_valid_color_txt": "有効なテーマカラーを選択してください",
    "req_redir_link": "リダイレクトリンクが必要です",
    "val_redir_link": "有効なリダイレクトリンクを入力してください",
    "req_business_name_txt": "会社名は必須です",
    "splash_preview": "スプラッシュプレビュー",
    "create_new_splash": "新しいスプラッシュを作成",
    "splash_page": "スプラッシュページ",
    "splash_page_builder": "スプラッシュページビルダー",
    "redirect_link": "リダイレクトリンク",
    "theme_color": "テーマカラー",
    "enable_social_login": "ユーザーのログインにソーシャルメディアオプションを有効にする",
    "google": "グーグル",
    "facebook": "フェイスブック",
    "is_mandatory": "それは必須ですか？",
    "field": "分野",
    "name": "名前",
    "first_name": "ファーストネーム",
    "last_name": "苗字",
    "birthdate": "生年月日",
    "gender": "性別",
    "email": "メール",
    "dob": "生年月日",
    "zip_code": "郵便番号",
    "required_redirect_link": "リダイレクトリンクが必要です",
    "valid_redirect_link": "有効なリダイレクトリンクを入力してください",
    "required_theme_color": "テーマカラーは必須です",
    "theme_color_length": "テーマカラーは7桁",
    "required_name": "名前は必須です",
    "delete_splash_message": "このスプラッシュを削除しますか?",
    "delete_multiple_splashes_message": "これらのスプラッシュを削除しますか?",
    "user_login_required": "ユーザーログインが必要です",
    "set_theme_color": "テーマカラーを設定する",
    "splash_colllect_additional_info_txt": "追加情報を収集する",
    "verify_mobile_otp_message": "携帯電話番号を確認するために OTP を送信しますか?",
    "add_company": "会社を追加",
    "edit_company": "会社を編集",
    "ds_device": "デジタルサイネージデバイス",
    "ds_device_activation_fee": "デジタルサイネージ機器アクティベーション料金",
    "wifi_hardware": "Wi-Fiハードウェア",
    "menu_design": "メニューデザイン",
    "ds_hardware": "デジタルサイネージハードウェア",
    "company_name_req": "会社名は必須です",
    "known_name_req": "既知の名前が必要です",
    "no_location_req": "場所番号は必須です",
    "minimum_location": "少なくとも1つの場所を入力してください",
    "terms_req": "利用規約は必須です",
    "notes_req": "メモは必須です",
    "sms_charge_req": "SMS料金が必要です",
    "menuonline_quantity_req": "メニュー オンライン数量が必要です",
    "menuonline_cost_req": "メニュー オンライン料金が必要です",
    "ds_quantity_req": "デジタルサイネージ数量が必要です",
    "ds_monthly_cost_req": "デジタルサイネージ月額費用が必要です",
    "reputation_quantity_req": "評判の量は必須です",
    "reputation_cost_req": "評判コストが必要です",
    "marketing_quantity_req": "マーケティング数量は必須です",
    "marketing_cost_req": "マーケティングコストが必要",
    "tax_percentage_req": "税率は必須です",
    "router_hardware_quantity_req": "ルーターハードウェアの数量が必要です",
    "wifi_hardware_cost_req": "WIFIハードウェアコストが必要",
    "activation_cost_req": "アクティベーションコストが必要",
    "wifi_quantity_req": "WIFI数量は必須です",
    "wifi_router_req": "Wi-Fiルーターが必要です",
    "menu_design_quantity_req": "メニューデザイン数量が必要です",
    "menu_design_cost_req": "メニューデザイン費用が必要です",
    "quantity_req": "数量が必要です",
    "cost_req": "費用が必要",
    "sheduledown_quantity_req": "スケジュール数量は必須です",
    "sheduledown_cost_req": "スケジュールダウンコストが必要です",
    "loyalty_program_quantity_req": "ロイヤルティプログラムの数量が必要です",
    "loyalty_program_cost_req": "ロイヤルティプログラム費用が必要です",
    "loyalty_program_sms_quantity_req": "ロイヤルティプログラムSMS数量が必要です",
    "loyalty_program_sms_cost_req": "ロイヤルティプログラムSMS費用が必要です",
    "comapny_created": "会社設立に成功",
    "comapny_updated": "会社情報が正常に更新されました",
    "billing_info": "請求情報",
    "business_name_acc_holder": "会社名（口座名義人）",
    "client_first_name": "クライアント名",
    "client_last_name": "クライアントの姓",
    "dba_known_as": "DBA として知られている",
    "business_address": "事業所住所",
    "no_of_locations": "拠点数",
    "package_details": "パッケージの詳細",
    "terms": "条項",
    "one_month": "1ヶ月",
    "per_sms_charge": "SMS料金",
    "plan_test": "計画: テスト",
    "desc_capital": "説明",
    "qty_capital": "数量",
    "cost_capital": "料金",
    "total_price_capital": "合計金額",
    "quantity": "量",
    "can_not_access": "選択できるのは",
    "licenses": "ライセンス",
    "monthly_cost": "月額費用",
    "plan_cost": "プラン費用",
    "tax": "税",
    "tax_percentage": "税率",
    "monthly_cost_after_tax": "税引き後の月額費用",
    "activation_one_time_charge": "アクティベーションと1回限りの課金",
    "sub_total": "小計",
    "action": "アクション",
    "cost": "料金",
    "shipping_charge": "配送料",
    "other": "他の",
    "additional_cost": "追加費用（その他料金）",
    "other_tax": "その他の税金",
    "total": "合計",
    "license_statistics": "ライセンス統計",
    "total_licenses": "ライセンス総数",
    "available_licenses": "利用可能なライセンス",
    "stock": "ストック",
    "has_permission": "許可あり",
    "avg_stock_price": "AVG株価",
    "average_price": "平均価格",
    "allocated": "割り当て済み",
    "reward_regulars": "常連客への報酬",
    "not_add_coupon_url": "クーポン URL を追加できません。メッセージの最大長は 160 です。",
    "like_attach_coupon": "クーポンを添付しますか？",
    "advance_scheduling": "事前スケジュール",
    "choose_day": "メッセージを送信する日を選択してください",
    "select_essage_window": "メッセージウィンドウを選択",
    "subscription_outside_delivery_window": "ユーザーが配信期間外に登録した場合、次の利用可能な時間に自動応答が送信されます。",
    "remaining": "残り",
    "processing_request": "リクエストの処理中にエラーが発生しました。",
    "list_companies": "企業一覧",
    "are_you_sure": "本気ですか..？",
    "signin_agreement": "これを確認することで、当社は契約書に署名することなく作成します。",
    "status_updated": "ステータスの更新に成功しました",
    "status_failed": "ステータスの更新に失敗",
    "new_editor_status_updated": "DS 新エディターのステータスが正常に更新されました。",
    "user_name": "ユーザー名",
    "known_as": "として知られている",
    "agreement_signed": "契約書に署名しました",
    "deactivate": "無効化",
    "activate": "活性化",
    "login_to_user_acc": "ユーザーアカウントにログイン",
    "disable": "無効にする",
    "enable": "有効にする",
    "ds_new_editor": "DS 新エディター",
    "sign_agreement": "同意書に署名する",
    "agreement_sign": "署名なしの合意",
    "view_agreement": "契約書を見る",
    "download_agreement": "ダウンロード契約",
    "add_deleted_user": "削除されたユーザーを追加",
    "deleted_user": "ユーザーを削除",
    "favourite": "お気に入り",
    "refresh": "リフレッシュ",
    "delete_chat": "チャットを削除",
    "responsive_drawer": "レスポンシブドロワー",
    "delete_contact": "連絡先を削除",
    "clear_chat_history": "チャット履歴を消去",
    "clear_chat_history_dec": "このチャットを削除してもよろしいですか?",
    "clear_contact_dec": "この連絡先を削除してもよろしいですか?",
    "select_contact": "連絡先を選択",
    "new_conversation": "新しい会話を始める",
    "type_msg": "ここにメッセージを入力してください",
    "select_delete_client": "削除するクライアントを選択してください",
    "select_delete_client_chat": "チャットを削除するクライアントを選択してください",
    "select_delete_client_chat_err": "このクライアントとチャットできません",
    "acquire_customers_business": "他の企業から新規顧客を獲得する。",
    "customers_from_events": "イベントから顧客を獲得し、あなたのところへ連れて来ましょう！",
    "customers_from_ads": "新聞広告から顧客を獲得します。",
    "smart_pamphlets": "顧客データを収集するためのスマートなパンフレットを作成します。",
    "smart_qr_group": "顧客がグループに参加するためのスマート QR。",
    "opt_in_number": "顧客が番号をオプトインできるようにします。",
    "qr_menu": "メニューを表示するための QR コードを作成します。",
    "collect_birthdays": "顧客の誕生日と記念日を収集します。",
    "join_loyalty_program": "新規顧客にロイヤルティ プログラムに参加してもらいます。",
    "create_feedback_forms": "クライアントのフィードバックを収集するためのフォームを作成します。",
    "exclusive_discounts_promotions": "クロスプロモーションのための特別割引やプロモーションを提供します。",
    "group_smart_qr_opt_in": "グループを作成したら、スマートQRコードを生成して選択し、顧客が即座にグループに参加できるようにします。",
    "group_smart_qr_opt_in_dec": "グループを作成した後、画像ウィジェットにより、顧客はテキストを使用してオプトインし、プロモーションを受け取ることができます。たとえば、「ピザ」というテキストを 555-555-5555 に送信します。",
    "in_store_discount_next_visit": "次回当店にご来店の際には店内割引をご利用ください",
    "delivery_redirection": "配信リダイレクトを作成する",
    "additional_info": "追加情報",
    "add_url": "URLを追加",
    "custom_fields": "カスタムフィールド",
    "meta_tag": "メタタグ",
    "max_chars": "最大文字数",
    "add": "追加",
    "update_campaign": "アップデートキャンペーン",
    "last_week_required": "先週は必須",
    "large_week_value": "週の値が大きすぎます。有効な週を入力してください",
    "less_than_last_week": "先週の返品は先週より少なくなければなりません。",
    "last_week_req": "先週は返却不要",
    "birthday_special": "誕生日スペシャル",
    "birthday_message": "誕生日にパーソナライズされた挨拶と魅力的なオファーを自動送信する",
    "birthday_sms": "SMS\/MMS用のパーソナライズされた誕生日の挨拶を作成します",
    "target_audience": "ターゲットオーディエンスを選択する",
    "client_by_birthday": "誕生日別クライアント",
    "clients_once_year": "このキャンペーンは年に1回クライアントに自動的に送信されます ",
    "auto_responder_default_msg_1": "あなたは私たちが割引を喜んで提供する最高の顧客の一人です！ いつも裕福で健康でありますように。 お誕生日おめでとうございます！ %% firstname %%",
    "campaign_send_to_client_birthday": "この自動キャンペーンは誕生日のあるクライアントに送信され、以下のターゲティングをカスタマイズできます。",
    "get_more_engagement": "誕生日の SMS を送信してエンゲージメントを高めましょう 🎂を送信してエンゲージメントを高める",
    "welcome_new_clients": "新規クライアントを歓迎",
    "make_lasting_impression": "初めて来店したお客様を温かく迎え入れることで、印象に残るひとときをお過ごしください。",
    "personalized_greetings": "新規顧客向けにパーソナライズされた挨拶文を作成する",
    "greetings_to_new_customers": "このキャンペーンでは、新規顧客があなたのビジネスと初めてやり取りした翌日に歓迎の挨拶を送信します。",
    "audience_predefined": "この自動応答の対象者は事前に定義されています。これで完了です!",
    "send_clients_subscribed": "この自動返信は、前回登録したクライアントに送信するように設定されています ",
    "first_interaction": "  最初のやり取りの後。",
    "default_msg_2": "初めてのご来店をお楽しみいただけたなら幸いです。またのご来店を心よりお待ちしていますので、あなただけに割引をご提供いたします。リンクをクリックして次回のご予約をいただき、今すぐオンライン特別オファーをご利用ください。",
    "new_clients_update": "新規クライアント歓迎キャンペーンテキストを更新",
    "new_clients_warm_greeting": "温かい挨拶で新しいクライアントを歓迎します💌",
    "win_back_clients": "顧客を取り戻すためのキャンペーンテキストの更新",
    "re_engage_past_customers": "魅力的なオファーで過去の顧客を再び引き付け、再来店を促す",
    "target_disengaged_clients": "関心を失った顧客をターゲットにして、特別オファーで呼び戻すことは、顧客を呼び戻すための実証済みの戦術です。",
    "campaign_sends_to_clients": "このキャンペーンは、一定期間後に再来店しなかったクライアントに送信されます。",
    "customize_targeting": "以下のターゲティングをカスタマイズして、休眠クライアントに SMS\/MMS を送信します。",
    "clients_with_at_least": "少なくとも ",
    "connection_last": " 最後の接続 ",
    "return_in_last_week": " 先週は戻ってこなかったが、 ",
    "arr_menu_44": " 週",
    "df_msg_4": "ぜひまたお越しいただきたいので、戻ってきてくださった方には 10% 割引させていただきます! 今日もう一度試してみませんか?",
    "update_campaign_text": "顧客を取り戻すためのキャンペーンテキストの更新",
    "re_invite_customers": "しばらく戻ってこなかった顧客を再度招待する",
    "loyal_patrons": "特別割引やロイヤルティ特典で常連客に感謝の気持ちを伝えましょう",
    "surprize_top_spenders": "パーソナライズされた特別オファーで高額購入者を驚かせましょう。",
    "campaign_automatically_send": "このキャンペーンでは、ビジネスへの来客数を増やすために特典を自動的に送信します。",
    "sms_loyal_clients": "このキャンペーンでは、忠実な顧客に SMS\/MMS を送信します。以下のターゲティングを変更できます。",
    "or_more_connection": " またはそれ以上の接続、最後の ",
    "week_period": " 週期間。",
    "default_msg_5": "長い間、お客様としてお付き合いいただいていますね。時間が経つのは早いですね。これまで弊社をご利用いただき、誠にありがとうございます。",
    "reward_regulars_update": "常連客への報酬キャンペーンテキスト更新",
    "special_discounts": "感謝して特別割引をさせていただきます💎",
    "leave_positive_online": "レストランの評判を高めるために、顧客に肯定的なオンラインレビューを残すよう促します。",
    "clients_service_online": "このキャンペーンでは、クライアントにオンラインでサービスを評価するよう促します。",
    "increase_your_company": "この自動応答は、会社のオンライン評価を高めるために送信されます。",
    "send_message_after": "メッセージを送信 ",
    "min_connection": " 接続の最小値",
    "default_msg_6": "初めてのご訪問をお楽しみいただけたなら幸いです。またのお越しをお待ちしています。[URL] リンクをクリックして、Google で評価してください。",
    "ratings_update": "評価更新キャンペーンのテキストをもっと入手",
    "friendly_nudge": "フレンドリーなナッジでレビューをもっと獲得しましょう ⭐",
    "thanks_for_visiting": "ご来訪ありがとうございます",
    "express_gratitude": "心のこもった感謝のメッセージで、あなたのビジネスを選んでくれた顧客に感謝の気持ちを伝えましょう",
    "thank_you_message": "顧客への感謝のメッセージを作成します。",
    "clients_for_visiting": "あなたの場所を訪問してくれた顧客に温かい感謝の気持ちを伝えましょう。",
    "campaign_audience": "この自動キャンペーンの対象ユーザーは事前に定義されています。",
    "campaign_automatically_sends": "この自動キャンペーンは、 ",
    "minutes_customer_visit": " 顧客訪問時間（分）",
    "default_msg_7": "弊社の大切なお客様になっていただきありがとうございます。お客様にサービスを提供できたことを大変嬉しく思っており、お客様の期待に応えられたことを願っております。",
    "visiting_update_campaign": "更新キャンペーンテキストをご覧いただきありがとうございます。",
    "guests_thank_you": "ゲストの訪問に感謝しましょう😊",
    "download_title": "ダウンロード",
    "qr_gen_title": "QRコード生成",
    "qr_download_s_title": "QRコードが正常にダウンロードされました",
    "sel_dark_brand_clr_msg": "有効なバーンドカラーを選択してください。",
    "manage_customer_stamp_rewards": "顧客スタンプ特典を管理する🎁",
    "sel_loc_menu_title": "メニューの場所を選択する",
    "ans_req": "回答が必要です",
    "valid_reputation_name": "有効な評判名を入力してください",
    "reviews_name_req": "レビューをもっと見るには名前が必要です",
    "birthdate_required": "生年月日は必須です",
    "gender_required": "性別は必須です",
    "valid_birthdate_required": "有効な生年月日を入力してください。",
    "custom_delivery_redirection": "グループ別にカスタム配信リダイレクトフォームを作成する",
    "customer_fields_incentive_settings": "顧客フィールドとインセンティブ設定",
    "delivery_redirection_text": "配信リダイレクト",
    "delivery_redirection_success": "おめでとうございます！配信リダイレクトウィジェットが作成されました！🎉",
    "swipe_to_preview_redirection": "スワイプして配送リダイレクトフォームがどのように表示されるかを確認してください",
    "enter_item_name": "アイテム名を入力してください",
    "home_page_text": "ホームページのテキスト",
    "settings": "設定",
    "anniversary_required": "記念日は必須です",
    "valid_anniversary": "有効な記念日を入力してください。",
    "form_submited": "フォームが正常に送信されました",
    "notifications": "通知",
    "discount_message": "今すぐピザを 20% オフでご購入ください!",
    "is_required": " が必要です。",
    "section_title_required": "セクションタイトルは必須です",
    "section_dec_required": "セクションの説明が必要です",
    "client_details_required": "クライアントの詳細フィールドは必須です",
    "compliance": "コンプライアンス",
    "SMS_campaign1": "SMSキャンペーン1",
    "mobile_number_mandatory": "携帯電話番号は必須です",
    "new_answer": "新しい答え",
    "new_question": "新しい質問",
    "add_new_question": "新しい回答を追加",
    "select": "選択",
    "group_customers_question": "顧客をどこにグループ化しますか?",
    "contacts_added_to_group": "フォームを送信したすべての連絡先は、選択したグループに追加されます",
    "edit_client_details_section": "クライアント詳細セクションの編集",
    "client_details_fields": "クライアント詳細フィールド",
    "enter_client_details_section_title_desc": "クライアントの詳細セクションのタイトルと説明を入力します",
    "choose_fields_displayed_client_side": "クライアント側に表示されるフィールドを選択します",
    "section_title": "セクションタイトル",
    "add_title": "タイトルを追加",
    "section_description": "セクションの説明",
    "add_description": "説明を追加",
    "enter_program_name_location": "プログラム名と場所を入力してください📍",
    "brand_theme_clr_txt": "ブランドテーマカラー",
    "set_loyalty_punch_card_theme": "ロイヤルティ パンチカードのテーマを設定しましょう 🎨",
    "upload_logo_txt": "ロゴをアップロードする",
    "recommended_image_specs": "最適な表示のために、512 x 512 ピクセルの画像を使用することをお勧めします。JPG、SVG、または PNG。最大サイズは 10 MB です。",
    "valid_rep_name_txt": "有効な評判名を入力してください",
    "rep_name_req_txt": "レビューをもっと見るには名前が必要です",
    "que_req": "質問は必須です",
    "day_of_birthday": "誕生日当日",
    "day_before_birthday": "誕生日の3日前",
    "week_before_birthday": "誕生日の1週間前",
    "two_week_before_birthday": "誕生日の2週間前",
    "de_active": "非アクティブ",
    "campaign_details": "キャンペーン詳細",
    "link_clicked": "リンクがクリックされました",
    "history": "歴史",
    "auto_responder_summary": "自動応答の概要",
    "vsop_1": "15分",
    "vsop_2": "30分",
    "vsop_3": "45分",
    "vsop_4": "60分",
    "vsop_5": "90分",
    "vsop_6": "120分",
    "in_the_last": " 最後に ",
    "return_in_last": " しかし、最後には戻ってこなかった ",
    "of_the_connection": "接続の",
    "your_campaign": "あなたのキャンペーン",
    "you_have_successfully": "成功しました",
    "published_successfully": "正常に公開されました",
    "updated_smart_campaign": "スマートキャンペーンを更新しました",
    "almost_done_text": "もうすぐ終わりです！",
    "update_campaign_desc": "自動応答機能は完了です。有効にすると、継続的にクライアントにメッセージが送信されます。このキャンペーンはいつでも簡単に変更したり一時停止したりできます。",
    "update_and_publish": "更新して公開",
    "reset_campaign_title": "キャンペーンのカスタム テキスト、画像、割引オファー、ターゲット ユーザーが完全に削除されます。",
    "space_wifi": " Wi-Fi",
    "custom_forms": "カスタムフォーム",
    "web_e_signup": "ウェブ電子サインアップ",
    "import": "輸入",
    "permissions_req": "権限が必要です",
    "redeemed": "償還済み",
    "coupon_already_redeemed": "クーポンはすでに利用済みです",
    "autoresponder_campaigns": "オートレスポンダーキャンペーン",
    "autoresponder_campaign_desc": "自動応答を有効にしてパーソナライズされたキャンペーンを開始する",
    "mon": "月曜",
    "tue": "火曜日",
    "wed": "水曜",
    "thu": "木曜",
    "fri": "金曜日",
    "sat": "土曜",
    "sun": "太陽",
    "duplicate": "重複",
    "visibility": "可視性",
    "availability": "可用性",
    "out_stok": "在庫切れ",
    "edit_product": "製品を編集",
    "create_product": "製品を作成する",
    "basic_info": "基本情報",
    "basic_sub_info": "Lorem Ipsum は単なる印刷のダミー テキストです...",
    "enter_product_price": "商品価格を入力してください",
    "upload_image": "画像をアップロード",
    "allowed_file_formats": "最大ファイルサイズが 3MB の JPG および PNG ファイルのみが許可されます。",
    "pre_select": "事前選択",
    "promotions": "プロモーション",
    "discount_availability": "割引の可用性",
    "start_time": "開始時間",
    "end_time": "終了時間",
    "select_day": "日を選択",
    "menu_required": "メニューは必須です",
    "generated": "生成",
    "link": "リンク",
    "variations": "バリエーション",
    "select_variant_to_add": "追加したいバリエーションを選択してください",
    "price_title": "価格",
    "choose_image": "画像を選択",
    "select_visiblity": "表示を選択",
    "availability_schedule": "利用可能スケジュール",
    "add_on_upsell": "アドオン\/アップセル",
    "add_on_select_product": "選択した製品に追加",
    "upsell_product": "アップセル製品を選択",
    "variant_deletion_warning": "このバリアントは永久に削除されます。",
    "search_variations": "検索バリエーション",
    "add_variation": "バリエーションを追加",
    "variation_text": "変異体",
    "select_customization": "カスタマイズを選択",
    "add_new": "新規追加",
    "delete_customization_warning": "製品からこのカスタマイズを削除しますか?",
    "nutritional_allergen_info": "栄養およびアレルゲン情報",
    "mark_item": "アイテムをマーク",
    "calories_text": "カロリー",
    "allergens": "アレルゲンを選択",
    "select_allergens": "アレルゲンを選択",
    "special_instructions": "特別な指示",
    "preparation_instructions": "準備手順",
    "staff_notes": "スタッフノート",
    "checkbox1": "このメニュー項目に対する顧客からの特別な指示を許可するか禁止するかを指定するチェックボックス。",
    "menu_c": "メニュー",
    "past_design": "過去のデザイン",
    "file_name": "ファイル名",
    "variation_name": "バリエーション名",
    "grid_items_title1": "テーブルテント",
    "grid_items_title2": "デジタルメニュー",
    "grid_items_title3": "テイクアウトメニュー",
    "grid_items_title4": "半ページメニュー",
    "grid_items_title5": "デジタルメニュー5",
    "grid_items_title6": "デジタルメニュー6",
    "grid_items_title7": "デジタルメニュー7",
    "grid_items_title8": "デジタルメニュー8",
    "grid_items_title9": "デジタルメニュー9",
    "enter_valid_form_name": "有効なフォーム名を入力してください",
    "form_name_required": "フォーム名は必須です",
    "enter_valid_section": "有効なセクションタイトルを入力してください",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "フォーム名",
    "contact_list": "連絡先リスト",
    "label_notify_email": "メールで通知を受け取りますか?",
    "add_client_details_section": "クライアント詳細セクションの追加",
    "demo_purpose_only": "デモ目的のみです",
    "form_updated_success": "フォームが正常に更新されました",
    "form_creat_success": "フォームが正常に作成されました",
    "add_custom_section": "カスタムセクションを追加する",
    "edit_custom_section": "カスタムセクションを編集する",
    "forms_list": "フォーム一覧",
    "filled_custom_form_details": "記入されたカスタムフォームの詳細",
    "custom_form_deletion_warning": "このカスタムフォームは永久に削除されます。"
};

export default ja