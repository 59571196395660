const nl = {
    "rated_google_reviews": "Beoordeeld met 5,0 op Google Reviews",
    "get_your_digital_menu": "Ontvang uw digitale menu—",
    "instant_popup_menu": "Direct pop-upmenu zonder QR-scannen",
    "free_forever": "Gratis voor altijd",
    "the_future_is_here": "De toekomst is hier!",
    "upgrade_your_restaurant": "Upgrade uw restaurant met een verbluffend digitaal menu zonder kosten. Trek vanaf vandaag meer klanten aan.",
    "get_my_free_menu_now": "Ontvang nu mijn gratis menu",
    "no_credit_card_required": "*Geen creditcard vereist",
    "join_largest_marketplace": "Word lid van de grootste restaurantmarktplaats!",
    "what_will_you_get": "Wat krijg je?",
    "free": "VRIJ",
    "free_digital_menu": "Gratis digitaal menu 2D of 3D",
    "free_digital_menu_desc": "Zeg vaarwel tegen papieren menu's en dure herdrukken",
    "quick_and_easy_signup": "Snel en eenvoudig aanmelden",
    "communicate_with_customers_title": "Communiceer GRATIS met klanten!",
    "features1": [
        "Bespaar op afdrukkosten en profiteer van directe menu-updates wanneer u ze nodig hebt.",
        "Toegang tot uw digitale menu op elk apparaat",
        "Milieuvriendelijk en probleemloos"
    ],
    "features2": [
        "Laat klanten uw gerechten virtueel ontdekken met verbluffende 3D-beelden.",
        "Vergroot de betrokkenheid door gasten een realistisch voorproefje van hun maaltijd te geven.",
        "Val op met een menu-ervaring die net zo verleidelijk is als de smaak van uw eten."
    ],
    "features3": [
        "Begroet uw gasten direct met uw menu zodra ze het restaurant binnenkomen.",
        "Stuur automatisch updates over speciale aanbiedingen en promoties naar hun telefoon.",
        "Verbeter de eetervaring met snelle, contactloze toegang tot uw menu."
    ],
    "feature4": [
        "Benadruk seizoensaanbiedingen, dagelijkse specials en exclusieve promoties.",
        "Vestig de aandacht van klanten op uw populairste artikelen.",
        "Creëer urgentie en spanning met opvallende beelden en beschrijvingen."
    ],
    "feature5": [
        "Maak deel uit van een online community met duizenden dagelijkse bezoekers.",
        "Vergroot de zichtbaarheid en bereik nieuwe klanten die actief op zoek zijn naar eetgelegenheden.",
        "Zorg dat u zo snel mogelijk een toplocatie bemachtigt om uw zichtbaarheid te maximaliseren en meer gasten aan te trekken."
    ],
    "section3_heading": "Waarom digitaal? Omdat het gratis en gemakkelijk is!",
    "section3_description": "Krijg toegang tot krachtige functies die de aantrekkingskracht van uw restaurant vergroten.",
    "instant_menu": "Direct menu",
    "instant_menu_notification": "Pop-up en melding",
    "instant_menu_description": "Verwelkom gasten met directe menutoegang zodra ze binnenkomen. Stuur automatisch het nieuwste menu en specials rechtstreeks naar hun mobiele telefoons",
    "digital_menu": "Digitale menu's",
    "at_zero_cost": "zonder kosten",
    "digital_menu_description1": "Zeg vaarwel tegen papieren menu's en dure herdrukken. Stap over op een digitaal menu dat altijd up-to-date is, en het is voor altijd gratis!",
    "digital_menu_description2": "Met ons digitale menu zijn uw aanbiedingen altijd actueel en toegankelijk op elk apparaat. Bespaar geld en vereenvoudig menu-updates met een naadloze, gratis oplossing.",
    "try_me_button": "Probeer mij",
    "try_3d_menu_title": "3D-menu's",
    "try_3d_menu_title2": "Gratis — WOW!",
    "try_3d_menu_description1": "Laat uw gerechten van het scherm afspatten. Upload 3D-afbeeldingen en laat klanten virtueel uw eten proeven voordat ze binnenkomen.",
    "try_3d_menu_description2": "Hoogwaardige 3D-afbeeldingen zorgen voor een smakelijke preview, wat de aantrekkingskracht vergroot en bestellingen stimuleert.",
    "try_now": "Probeer nu",
    "showcase_title": "Laat uw zien",
    "showcase_specials": "Speciale aanbiedingen",
    "showcase_specials_description1": "Heb je een overheerlijke deal of een onverslaanbare promotie? Markeer het op je digitale menu en trek meer hongerige mensen.",
    "showcase_specials_description2": "Zorg dat u promoties niet kunt missen door de aandacht te trekken met opvallende beelden en aantrekkelijke beschrijvingen.",
    "marketplace": "Marktplaats",
    "marketplace_description": "We zijn bezig met het opzetten van een marktplaats met meer dan 2000 dagelijkse bezoekers. Stap nu in en u bent de eerste in de rij als we van start gaan. U kunt nieuwe klanten bereiken die actief op zoek zijn naar eetgelegenheden. Reserveer uw plek vroeg om de exposure te maximaliseren!",
    "see_other_products": "Bekijk andere producten",
    "about_us": "Over ons",
    "why_serving_free": "Waarom we dit serveren voor",
    "serving_free_highlight": "Vrij",
    "about_desc": "Wij zijn lokale mensen hier in Dallas en zetten ons in om restaurants zoals het uwe te laten floreren.",
    "about_tool_free": "Wij bieden deze krachtige tool geheel gratis aan, omdat wij geloven in het ondersteunen van onze community.",
    "no_catch": "Geen addertje onder het gras, geen grapje. We weten dat als je slaagt, onze community sterker wordt. 😊",
    "understanding_challenges": "Wij begrijpen de uitdagingen die gepaard gaan met het runnen van een bedrijf. Daarom streven wij ernaar om u de tools te bieden die uw bedrijfsvoering soepeler maken en uw bereik vergroten.",
    "community_growth": "Naarmate u groeit, groeit onze community met u mee, wat een domino-effect van succes en support creëert. Laten we samen de eetervaring voor iedereen verbeteren.",
    "how_it_works": "Hoe het werkt",
    "three_simple_steps": "Drie eenvoudige stappen om",
    "get_started_highlight": "Aan de slag",
    "no_tech_skills_needed": "Geen technische vaardigheden nodig. Als je een burger kunt omdraaien, kun je dit ook!",
    "step1_title": "Toon uw promotie GRATIS!",
    "step1_desc": "Ga sneller aan de slag dan u kunt zeggen: \"Bestel!\" Vul gewoon een eenvoudig formulier in.",
    "step2_title": "Pas uw menu aan",
    "step2_desc": "Voeg uw gerechten toe, upload foto's, zelfs in 3D, en markeer uw specialiteiten.",
    "step3_title": "Delen en groeien",
    "step3_desc": "Deel uw digitale menu via QR-codes, sociale media of uw website.",
    "testimonials": "Getuigenissen",
    "testimonials_section_title": "Vertrouw niet alleen op ons woord",
    "testimonials_section_desc": "Lees wat andere restauranteigenaren in Dallas zeggen.",
    "faq_section_title": "Heeft u vragen? Wij hebben antwoorden",
    "faq_section_desc": "Alles wat u moet weten voordat u begint.",
    "faqs": "Veelgestelde vragen",
    "faq_answers": [
        {
            "que": "Is het echt voor altijd gratis?",
            "ans": "Absoluut! Geen verborgen kosten, geen creditcards, geen verplichtingen."
        },
        {
            "que": "Heb ik technische kennis nodig?",
            "ans": "Als je een e-mail kunt sturen, kun je dit ook. Zo simpel is het."
        },
        {
            "que": "Waarom bieden jullie dit gratis aan?",
            "ans": "Wij geloven in geven als eerste. In de toekomst hebben we misschien andere services die u leuk vindt, maar dat is helemaal aan u."
        },
        {
            "que": "Wat is er met de markt aan de hand?",
            "ans": "We zijn het aan het koken! Zodra we genoeg restaurants aan boord hebben, lanceren we uw marktplaats om u nog meer exposure te geven."
        }
    ],
    "testimonials_content1": "Overstappen op een digitaal menu was de beste zet die we hebben gedaan! Onze klanten zijn dol op het gemak en de vernieuwde looks!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "Het herenhuisrestaurant",
    "testimonials_content2": "Dankzij ons nieuwe digitale menu kunnen gasten gemakkelijker rondkijken en is de betrokkenheid enorm toegenomen!",
    "testimonials_name2": "Amelia Johnson",
    "testimonials_restaurant_name2": "Stedelijk eten",
    "testimonials_content3": "Gasten zijn onder de indruk van de 3D-beelden. Het heeft ons geholpen om op te vallen en hun eetervaring te verbeteren!",
    "testimonials_name3": "Davis Groen",
    "testimonials_restaurant_name3": "Stadshaard",
    "ready_to_boost_your_restaurant": "Klaar om uw restaurant een boost te geven?",
    "ready_to_boost_desc": "Blijf niet achter. Doe vandaag nog mee aan de digitale revolutie!",
    "yes_i_want_my_free_digital_menu": "Ja, ik wil mijn gratis digitale menu",
    "480_plus": "480+",
    "people_already_joined": "mensen zijn al lid geworden",
    "menu_online_home": "Menu Online Startpagina",
    "welcome": "Welkom",
    "description_other": "Laten we de tevredenheid van uw klanten vergroten!",
    "benefits": "Voordelen",
    "greeting_text": "Welkom 👋",
    "all_in_one_features": "🚀 Alles-in-één functies om uw restaurant naar een hoger niveau te tillen",
    "menu_online_menu_name": "Menu Online",
    "menu_online_description": "Digitaliseer uw menu, zodat klanten er eenvoudig online toegang toe hebben.",
    "menu_online_benefits": "Vergroot het gemak voor uw klanten en vergroot de betrokkenheid door ze altijd en overal uw menu te laten bekijken.",
    "ds_name": "Digitale bewegwijzering",
    "ds_description": "Toon aanbiedingen en promoties op dynamische schermen in de winkel.",
    "ds_benefits": "Trek de aandacht van klanten met aantrekkelijke beelden en promoot moeiteloos upsells en realtime aanbiedingen.",
    "loyalty_punch_name": "Loyaliteitsponskaart",
    "loyalty_punch_description": "Beloon terugkerende klanten met een digitaal loyaliteitsprogramma.",
    "loyalty_punch_benefits": "Bevorder de klantretentie en verhoog de omzet door loyaliteit te stimuleren met inwisselbare beloningen voor regelmatige bezoeken.",
    "marketing_menu_name": "Marketing",
    "marketing_description": "Bereik meer klanten met gerichte digitale marketingcampagnes.",
    "marketing_benefits": "Vergroot het bereik van uw restaurant en trek meer klanten aan met op maat gemaakte promoties die erop gericht zijn nieuwe en terugkerende klanten aan te trekken.",
    "get_more_reviews_menu_name": "Ontvang meer beoordelingen",
    "get_more_reviews_description": "Moedig tevreden klanten aan om positieve online beoordelingen achter te laten.",
    "get_more_reviews_benefits": "Vergroot de geloofwaardigheid van uw restaurant en trek nieuwe gasten aan door uw online reputatie te verbeteren met meer positieve beoordelingen.",
    "guest_smart_wifi_menu_name": "Gast Smart Wifi",
    "guest_smart_wifi_description": "Bied gratis wifi aan om gastgegevens te verzamelen voor marketingdoeleinden.",
    "guest_smart_wifi_benefits": "Verhoog de klanttevredenheid door waardevolle klantgegevens te verzamelen om toekomstige promoties te personaliseren.",
    "reservation_menu_name": "Reservering",
    "reservation_description": "Geef klanten de mogelijkheid om eenvoudig online een tafel te reserveren.",
    "reservation_benefits": "Verbeter de eetervaring door de wachttijden te minimaliseren en stroomlijn reserveringen om het zitcomfort te verbeteren.",
    "pos_integration_menu_name": "POS-integratie",
    "pos_integration_description": "Integreer ons systeem met uw POS-systeem.",
    "pos_integration_benefits": "Vereenvoudig de orderverwerking en verminder fouten door uw online- en fysieke systemen op elkaar af te stemmen.",
    "online_ordering_menu_name": "Online bestellen",
    "online_ordering_description": "Geef klanten de mogelijkheid om rechtstreeks via uw website of app bestellingen te plaatsen.",
    "online_ordering_benefits": "Verhoog uw omzet en verminder de afhankelijkheid van externe platforms door een optie voor direct bestellen aan te bieden waarmee u kosten bespaart.",
    "pro_label": "Pro",
    "coming_soon": "Binnenkort beschikbaar",
    "locked_label": "Vergrendeld",
    "forever_label": "Voor altijd",
    "new_label": "Nieuw",
    "other_products_page_title": "Overige producten | Menu Online",
    "other_products_description": "Menu Online Privacybeleid",
    "other_products_header_title": "Andere producten",
    "privacy_policy": {
        "title": "Privacybeleid",
        "lastUpdated": "Laatst bijgewerkt: 1 juli 2024",
        "introduction": {
            "header": "Privacybeleid voor nieuwe en bestaande klanten",
            "paragraph1": "DE VOLGENDE BELANGRIJKE PUNTEN (INCLUSIEF SECTIEVERWIJZINGEN) WORDEN UITSLUITEND VOOR UW GEMAK VERSTREKT EN VERVANGEN NIET DE VOLLEDIGE ALGEMENE VOORWAARDEN. HET IS UW VERANTWOORDELIJKHEID OM DEZE ALGEMENE VOORWAARDEN TE LEZEN VOORDAT U DE SERVICE VAN MENUONLINE INC GEBRUIKT.",
            "paragraph2": "Door Menuonline te gebruiken, gaat u akkoord met de Algemene Voorwaarden en verklaart en garandeert u dat u door uw organisatie of bedrijf bent gemachtigd om dit te doen. (Zie s.3 “Hoe het werkt”)",
            "paragraph3": "Menuonline Inc is een marketingdienst op basis van toestemming. Opzettelijk verzenden van \"spam\" of het schenden van de Algemene Voorwaarden zal resulteren in het sluiten van uw account. (Zie s.16 \"Aanvaardbaar gebruik van de dienst\")",
            "paragraph4": "Gegevens die u host in Menuonline Inc, zoals contactgegevens, e-mailinhoud en informatie die u verzamelt via Menuonline Inc, zijn uw eigendom. (Zie s.14 “Wat bent u eigenaar?”).",
            "paragraph5": "De software van Menuonline Inc en de inhoud op onze website zijn ons eigendom. (Zie s.13 “Wat bezitten wij?”) Afgezien van uw eigen gebruikers, kunnen de gegevens of ongepubliceerde inhoud die u host bij Menuonline Inc alleen worden bekeken door werknemers en partners van Menuonline Inc die een geheimhoudingsverklaring hebben ondertekend. (Zie s.14 “Wat bezit u?”)",
            "paragraph6": "Alle persoonlijk identificeerbare informatie (PII) wordt uitsluitend bewaard op servers in Canada. (Zie s.14 “Wat bezit u?”).",
            "paragraph7": "De service van Menuonline Inc is onderworpen aan de wetten van Canada en de provincie Ontario. (Zie s.24 “Toepasselijk recht”)",
            "paragraph8": "Alle verzoeken om uw gegevens of inhoud door personen, politie of andere regelgevende instanties van binnen of buiten Canada worden altijd naar u doorverwezen. De enige uitzondering zou zijn als dit ons in overtreding brengt van de Canadese of Ontario-wetgeving; echter, in alle gevallen zullen we ons krachtig verzetten tegen elke vrijgave van uw gegevens door iemand anders dan u. (Zie s.14 \"Wat bent u eigenaar?\").",
            "paragraph9": "De beveiliging van Menuonline Inc is alleen zo goed als uw beheer van gebruikersnamen en wachtwoorden. We brengen geen kosten in rekening voor Menuonline Inc gebruikersaccounts, dus maak een aparte account voor elke gebruiker en houd wachtwoorden geheim. U bent verantwoordelijk voor uw account. (Zie s.3 “Hoe het werkt”)",
            "paragraph10": "U kunt uw account op elk gewenst moment annuleren. Wij verstrekken geen restituties op ongebruikt tegoed als u annuleert. (Zie s.3 “Hoe het werkt”)"
        },
        "termsAndConditions": {
            "title": "MENUONLINE INC ALGEMENE VOORWAARDEN",
            "lastUpdate": "LAATSTE UPDATE: 14 januari 2020",
            "paragraph1": "Lees deze servicevoorwaarden (de \"Voorwaarden\") zorgvuldig door voordat u deze website (de \"Site\") gebruikt. Deze Voorwaarden ontheffen Menuonline Inc. (\"Menuonline Inc\", \"wij\" of \"ons\") en anderen van aansprakelijkheid en\/of beperken onze en hun aansprakelijkheid en bevatten andere belangrijke bepalingen die van toepassing zijn op uw gebruik van deze Site. Door deze Site of de Service te bezoeken of te gebruiken, stemt u ermee in namens uzelf en namens elke organisatie namens wie u handelt (hierna gezamenlijk aangeduid als \"u\") deze Voorwaarden te accepteren en na te leven voor elk gebruik van en elk bezoek aan deze Site."
        },
        "services": {
            "title": "Onze diensten.",
            "paragraph": "De diensten die wij via de Site aanbieden, zijn een dienst waarmee u e-mailberichten (“E-mails”) kunt maken, verzenden en beheren naar ontvangers van uw keuze (de “Dienst”)."
        },
        "license": {
            "title": "Licentie.",
            "paragraph": "Onder voorbehoud van deze Voorwaarden verleent Menuonline Inc u hierbij een niet-exclusieve, beperkte, niet-overdraagbare licentie gedurende de Termijn om de Site te bekijken en de Service te gebruiken zoals toegestaan door de functies van de Service. Wij behouden ons alle rechten voor die hierin niet uitdrukkelijk worden verleend in de Service en de Menuonline Inc Content (zoals hieronder gedefinieerd)."
        },
        "emailingContent": {
            "title": "E-mailinhoud.",
            "paragraph": "Wanneer u via deze Site of de Service met Menuonline Inc communiceert, stemt u ermee in om waarheidsgetrouwe en nauwkeurige informatie te verstrekken. Verder begrijpt u dat het verstrekken van vertrouwelijke informatie via e-mail een onveilige praktijk is. U begrijpt dat u als enige verantwoordelijk bent voor alles wat u naar iemand stuurt die onze Service gebruikt."
        },
        "support": {
            "title": "Steun.",
            "paragraph": "Wij bieden u ondersteuning om u te helpen zelfvoorzienend te worden met de Service. Wij bieden u ondersteuning in de vorm van documentatie en video's op de Site, het Menuonline Inc support ticket systeem en per telefoon. U kunt ons bereiken door te klikken op het tabblad \"Support\" bovenaan de Site. Er zijn geen kosten verbonden aan productondersteuning; wij behouden ons echter het recht voor om de ondersteuning voor specifieke cliëntaccounts naar eigen goeddunken te beëindigen."
        }
    },
    "terms_condition": {
        "title": "Servicevoorwaarden | Menu Online",
        "header": "Algemene voorwaarden voor nieuwe en bestaande klanten"
    },
    "privacy_policy_title": "PRIVÉBELEID",
    "privacy_policy_effective_date": "Ingangsdatum 16 januari 2020",
    "privacy_policy_general_title": "ALGEMEEN PRIVÉBELEID",
    "privacy_policy_intro": "menuonline.com (Menuonline en “ons”) begrijpt het belang van de bescherming van de privacy van de persoonlijke informatie van individuen en het belang van de beveiliging van vertrouwelijke informatie van alle soorten. We hebben dit Privacybeleid (het “Beleid”) opgesteld om onze toewijding aan de bescherming van uw “privacy” te demonstreren. Dit Privacybeleid legt uit:",
    "privacy_policy_points": [
        "Welke persoonlijke informatie wij verzamelen en waarom wij deze verzamelen;",
        "Hoe wij uw persoonsgegevens gebruiken;",
        "Met wie wij uw persoonlijke gegevens delen; en",
        "De keuzes die u hebt om uw persoonlijke gegevens via de service te openen, bij te werken en te verwijderen."
    ],
    "privacy_policy_pipeda": "Menuonline beheert de verzameling van alle informatie in overeenstemming met de Canadese Personal Information Protection and Electronic Documents Act (PIPEDA).",
    "privacy_policy_update_notice": "Houd er rekening mee dat Menuonline zich het recht voorbehoudt om dit Privacybeleid op elk gewenst moment bij te werken of te wijzigen. Wij zullen u op de hoogte stellen van eventuele wijzigingen door een bijgewerkt Beleid op deze website te plaatsen of zoals beschikbaar gesteld via de services. De meest recente versie van dit Beleid zal het gebruik van persoonlijke informatie van gebruikers door mycircle regelen. Door de website van mycircle te blijven bezoeken of door de services te blijven gebruiken nadat er wijzigingen zijn aangebracht in dit Privacybeleid, stemt u ermee in gebonden te zijn aan de herziene voorwaarden.",
    "privacy_policy_consent_title": "TOESTEMMING VOOR PERSOONLIJKE INFORMATIE",
    "privacy_policy_consent_text": "Door Menuonline uw persoonlijke informatie te verstrekken via onze website of een van onze formulieren, stemt u in met het verzamelen, gebruiken en openbaar maken van dergelijke informatie in overeenstemming met dit beleid of voor de doeleinden die aan u zijn geïdentificeerd op het moment dat u de persoonlijke informatie verstrekte. Elk secundair gebruik van de persoonlijke informatie zal alleen worden verwerkt met uw uitdrukkelijke toestemming, of u krijgt de mogelijkheid om u af te melden.",
    "privacy_policy_opt_out_text": "U hebt de mogelijkheid om uw toestemming in te trekken of u op elk gewenst moment af te melden. Als u uw toestemming wilt intrekken of u wilt afmelden, kunt u op de link voor communicatievoorkeuren klikken onderaan elke e-mail die u van ons ontvangt.",
    "privacy_policy_info_collection_title": "INFORMATIE DIE WIJ VERZAMELEN",
    "privacy_policy_info_collection_text": "Menuonline verzamelt en verwerkt persoonlijke informatie in overeenstemming met dit beleid. Wij zullen deze informatie niet verkopen, delen of verhuren aan anderen, behalve zoals verantwoord in dit beleid. Menuonline verzamelt persoonlijke informatie om onze diensten of producten aan u te leveren.",
    "privacy_policy_info_types_text": "De soorten informatie die Menuonline verzamelt, omvatten:",
    "privacy_policy_account_holders_title": "Menuonline Rekeninghouders",
    "privacy_policy_registration_info": "Registratiegegevens: Als u zich aanmeldt om diensten te gebruiken, hebt u een Menuonline-account nodig om een accounthouder te worden (\"Accounthouder\"). Wanneer u zich registreert voor een account, wordt u gevraagd om bepaalde basisgegevens te verstrekken, zoals uw naam, e-mailadres, gebruikersnaam, wachtwoord, bedrijfsnaam, beroep, locatie en telefoonnummer. Wachtwoorden zijn gecodeerd - Menuonline-personeel kan wachtwoorden niet bekijken of ophalen.",
    "privacy_policy_billing_info": "Factureringsgegevens: Als u zich abonneert op onze diensten, dient u ons betalings- en factureringsgegevens te verstrekken.",
    "privacy_policy_cookies_info": "Cookies: Wij gebruiken cookies om bezoekersgegevens bij te houden, zoals de bron van websitebezoeken en het aantal websitebezoeken op onze website.",
    "privacy_policy_ip_info": "IP-adressen: IP-adressen worden over u verzameld als onderdeel van uw gebruik van de diensten (bijvoorbeeld om uw locatie per land en de IP-adressen vast te leggen die u gebruikt bij het gebruik van onze diensten).",
    "privacy_policy_your_contacts_title": "Uw contacten",
    "privacy_policy_distribution_list": "Distributielijst: Als onderdeel van uw gebruik van onze diensten, kunt u ons persoonlijke informatie verstrekken, of kunnen wij persoonlijke informatie verzamelen als onderdeel van de diensten, over uw contacten (\"Contacten\"). Deze informatie omvat e-mailadressen en kan andere identificeerbare informatie bevatten, zoals naam, bedrijfsnaam of andere informatie die u aan Menuonline verstrekt tijdens het gebruik van de diensten. U moet ervoor zorgen dat u de juiste toestemming van uw Contacten verkrijgt om hun informatie door ons te laten verwerken.",
    "privacy_policy_billing_info_contacts": "Factureringsgegevens: Als u zich abonneert op onze diensten, dient u ons betalings- en factureringsgegevens te verstrekken.",
    "privacy_policy_cookies_contacts": "Cookies: Menuonline gebruikt alleen cookies en vergelijkbare trackingtechnologieën om persoonlijke informatie over uw contacten te verzamelen en te gebruiken als u de Menuonline-trackingcode aan uw website hebt toegevoegd. Alle landingspagina's die door Menuonline worden gehost, hebben automatisch een trackingcode.",
    "privacy_policy_ip_contacts": "IP-adressen: IP-adressen van uw contactpersonen worden verzameld als onderdeel van de diensten (bijvoorbeeld om uw locatie op landniveau en de IP-adressen vast te leggen die worden gebruikt bij het indienen van een formulier en het verlenen van toestemming).",
    "privacy_policy_info_about_contacts_title": "INFORMATIE DIE WIJ OVER UW CONTACTEN VERZAMELEN",
    "privacy_policy_info_about_contacts_notice": "*Dit gedeelte is alleen van toepassing op informatie die door Menuonline wordt verzameld, gebruikt of bekendgemaakt over de contactpersonen van een accounthouder ten behoeve van het verlenen van de diensten*",
    "privacy_policy_info_about_contacts_desc": "Menuonline zal alleen persoonlijke informatie over Contacten verzamelen, gebruiken of bekendmaken als de Accounthouder zich heeft geregistreerd voor bepaalde services waarbij zij ons een distributielijst verstrekken. Accounthouders zijn als enige verantwoordelijk voor het naleven van alle toepasselijke wetten bij het verstrekken van persoonlijke informatie over hun Contacten aan Menuonline via de services.",
    "privacy_policy_info_auto_collect_title": "Informatie Menuonline verzamelt automatisch",
    "privacy_policy_info_auto_collect_desc1": "Wanneer de Contacten van de Accounthouder deelnemen aan een e-mailcampagne die is ontvangen van een Accounthouder, of interacteren met een link naar de verbonden website van een Accounthouder, kan Menuonline informatie verzamelen over het apparaat van een dergelijke Contactpersoon en de interactie met de e-mail door middel van cookies. Deze informatie wordt alleen verzameld om de diensten te leveren en om inzicht te verschaffen aan de Accounthouder.",
    "privacy_policy_info_auto_collect_desc2": "Informatie die wij verzamelen over het apparaat van een Contactpersoon en de applicaties die Contactpersoon gebruikt om toegang te krijgen tot e-mails die door de Accounthouder via onze diensten zijn verzonden, kan het IP-adres van Contactpersoon, het besturingssysteem, de browser-ID en andere gerelateerde informatie over het systeem en de verbinding van Contactpersoon omvatten.",
    "privacy_policy_info_auto_collect_desc3": "Informatie die wij verzamelen over de interactie van Contact met e-mails die via de services worden verzonden, kan bestaan uit data en tijden waarop Contact e-mail en browse-activiteiten raadpleegt, en informatie over de prestaties van de services, zoals de afleverbaarheid van e-mails en andere communicatie die door een Accounthouder via de services naar Contact wordt verzonden.",
    "privacy_policy_info_auto_collect_desc4": "Houd er rekening mee dat Menuonline geen contact zal opnemen met uw contactpersonen, tenzij dit wettelijk verplicht is.",
    "privacy_policy_why_we_collect_title": "WAAROM WIJ UW INFORMATIE VERZAMELEN",
    "privacy_policy_why_we_collect_desc": "Menuonline verzamelt informatie voor de volgende doeleinden:",
    "privacy_policy_why_collect_1": "om Menuonline-accounthouders de diensten te leveren waarvoor zij zich hebben geabonneerd, met inbegrip van het gebruik van accounthouderinformatie voor de diensten;",
    "privacy_policy_why_collect_2": "voor administratie en accountbeheer, alsmede voor toegang tot de website van mycircle;",
    "privacy_policy_why_collect_3": "als u toestemming hebt gegeven voor het ontvangen van marketing- en promotiemateriaal, om u te informeren over andere producten en diensten die door Menuonline worden aangeboden; en",
    "privacy_policy_why_collect_4": "om te reageren op communicatie van Accounthouders of om door u gevraagde informatie te sturen. Dit kan onder andere het volgende omvatten:",
    "privacy_policy_why_collect_5": "speciale aanbiedingen en updates;",
    "privacy_policy_why_collect_6": "nieuwsbrief; en",
    "privacy_policy_why_collect_7": "dienstmededelingen.",
    "privacy_policy_opt_out_desc": "U kunt er op elk moment voor kiezen om geen berichten meer te ontvangen door op de knop 'uitschrijven' te klikken onderaan elke e-mail die u van Menuonline ontvangt.",
    "privacy_policy_use_disclosure_title": "GEBRUIK EN OPENBAARMAKING VAN PERSOONLIJKE INFORMATIE",
    "privacy_policy_use_disclosure_desc1": "Persoonlijke informatie die aan Menuonline wordt verstrekt, inclusief via de website van Menuonline of een voorgeschreven formulier, wordt strikt vertrouwelijk behandeld, tenzij deze wordt bekendgemaakt in overeenstemming met de uitdrukkelijke toestemming van een Accounthouder of wettelijke vereisten. In ieder geval is alle persoonlijke informatie die op een \"Menuonline\"-platform door Accounthouders wordt verstrekt vrijwillig: alle persoonlijke informatie die door een Accounthouder wordt verstrekt, wordt naar eigen goeddunken van de Accounthouder verstrekt. Houd er rekening mee dat het intrekken van uw toestemming ertoe kan leiden dat u geen toegang meer hebt tot of geen gebruik meer kunt maken van bepaalde Menuonline-services.",
    "privacy_policy_use_disclosure_desc2": "Menuonline maakt gebruik van derden en externe dienstverleners met betrekking tot haar website en diensten. De persoonlijke informatie van Account Holder kan worden gedeeld met dergelijke derden en aanbieders. We kunnen ook geaggregeerde statistieken over Account Holders, verkopen, verkeerspatronen en gerelateerde informatie verstrekken aan gerenommeerde derden. Menuonline vereist dat elke externe aanbieder die toegang heeft tot persoonlijke informatie gebonden is aan verplichtingen die in overeenstemming zijn met dit Privacybeleid en dat dergelijke aanbieders de persoonlijke informatie van Account Holder alleen verzamelen, gebruiken of bekendmaken voor het doel van het leveren van Menuonline-diensten.",
    "privacy_policy_use_disclosure_desc3": "Persoonlijke informatie die door Accounthouders wordt verstrekt, wordt niet verkocht, gedeeld of verhuurd aan externe organisaties, tenzij in overeenstemming met uw impliciete of expliciete toestemming. Menuonline behoudt zich het recht voor om contact op te nemen met Accounthouders om informatie door te sturen, vragen te beantwoorden of kennisgeving te doen van wijzigingen in ons programma of ons beleid. Let op, Menuonline zal geen contact opnemen met uw Contactpersonen, tenzij vereist door de toepasselijke wetgeving.",
    "privacy_policy_use_disclosure_desc4": "Menuonline zal persoonlijke informatie alleen vrijgeven als de relevante persoon hiervoor toestemming heeft gegeven of:",
    "privacy_policy_disclosure_list_1": "wanneer dit wettelijk is toegestaan of vereist, bijvoorbeeld in reactie op een dagvaarding of een ander juridisch proces;",
    "privacy_policy_disclosure_list_2": "aan een verkrijger van het geheel of een deel van Menuonline;",
    "privacy_policy_disclosure_list_3": "aan de dienstverleners van Menuonline in overeenstemming met dit Beleid; of",
    "privacy_policy_disclosure_list_4": "om te voldoen aan wettelijke verplichtingen, waaronder maar niet beperkt tot wettelijke rapportageverplichtingen.",
    "privacy_policy_service_providers_title": "Menuonline maakt gebruik van de volgende derden en dienstverleners (persoonlijke gegevens van uw Contacten worden niet aan 'dergelijke' derden bekendgemaakt):",
    "privacy_policy_service_provider_1": "Wij gebruiken ManyChat om websitechatdiensten te leveren voor verkoop en ondersteuning, evenals vervolgmails over deze gesprekken.",
    "privacy_policy_service_provider_2": "Wij gebruiken AskNicely om onze klanten te ondervragen over de kwaliteit van de service en ondersteuning die zij ontvangen.",
    "privacy_policy_service_provider_3": "Wij gebruiken join.me om klant- en potentiële klantvergaderingen, ondersteuningsgesprekken en softwaredemo's te plannen.",
    "privacy_policy_service_provider_4": "Wij gebruiken Google Analytics om anonieme website-activiteit te volgen en gebruikspatronen op onze website te meten.",
    "privacy_policy_breach_notification_title": "MELDING VAN SCHENDING",
    "privacy_policy_breach_notification_desc": "Menuonline zal voldoen aan de toepasselijke wetgeving en Rekeninghouders redelijke assistentie verlenen bij de naleving hiervan met betrekking tot elk ongeoorloofd gebruik, elke ongeoorloofde toegang of openbaarmaking van persoonlijke informatie.",
    "privacy_policy_rights_access_title": "UW RECHTEN EN TOEGANG",
    "privacy_policy_rights_access_desc": "U hebt het recht om toegang te krijgen tot de persoonlijke informatie die Menuonline over u verwerkt. U kunt bij ons informatie opvragen over:",
    "privacy_policy_rights_access_list_1": "De persoonlijke informatie die wij over u bewaren",
    "privacy_policy_rights_access_list_2": "De categorieën van persoonlijke informatie die hierbij betrokken zijn",
    "privacy_policy_rights_access_list_3": "De doeleinden van de verwerking",
    "privacy_policy_rights_access_list_4": "Gegevens aan wie uw persoonlijke gegevens zijn\/zullen worden bekendgemaakt",
    "privacy_policy_rights_access_list_5": "Hoe lang we uw persoonlijke gegevens bewaren",
    "privacy_policy_rights_access_list_6": "Als we de informatie niet rechtstreeks van u hebben verzameld, informatie over de bron",
    "privacy_policy_rights_access_list_7": "Hoe u een klacht indient bij de juiste toezichthoudende autoriteit",
    "privacy_policy_rights_access_list_8": "U kunt ook het volgende van ons vragen:",
    "privacy_policy_rights_access_list_9": "Dat wij onvolledige of onjuiste informatie over u bijwerken",
    "privacy_policy_rights_access_list_10": "Verzoek dat wij uw persoonlijke gegevens verwijderen in overeenstemming met de toepasselijke wetgeving",
    "privacy_policy_rights_access_contact": "U kunt ons verzoeken uw rechten uit te oefenen door contact met ons op te nemen via 2851 Dufferin Street, Toronto, ON, Canada, of door ons een e-mail te sturen op {link}.",
    "privacy_policy_accountability_title": "VERANTWOORDELIJKHEID",
    "privacy_policy_accountability_desc": "Menuonline is toegewijd om verantwoordelijk te zijn voor de persoonlijke en vertrouwelijke informatie die u ons verstrekt. Voor contactgegevens, zie het einde van dit Beleid.",
    "privacy_policy_links_other_sites_title": "LINKS NAAR ANDERE SITES",
    "privacy_policy_links_other_sites_desc1": "De website van Menuonline kan links naar andere sites bevatten. Houd er rekening mee dat Menuonline niet verantwoordelijk is voor de privacypraktijken van andere sites. Wij moedigen onze accounthouders aan om zich ervan bewust te zijn dat wanneer zij onze site verlaten, zij de privacybeleidsverklaringen van elke website die persoonlijk identificeerbare informatie verzamelt, grondig moeten lezen.",
    "privacy_policy_links_other_sites_desc2": "Dit privacybeleid is uitsluitend van toepassing op de door ons verzamelde informatie.",
    "privacy_policy_links_other_sites_desc3": "Als u een link gebruikt om vanaf de Menuonline-site producten of diensten te kopen, voltooit u een aankooptransactie volgens de regels en voorschriften van het verkopende bedrijf, niet van Menuonline.",
    "privacy_policy_cookies_title": "PRIVÉBELEID COOKIES",
    "privacy_policy_cookies_desc1": "De website en services van Menuonline gebruiken cookies om bepaalde informatie te verzamelen en op te slaan in overeenstemming met dit beleid. Een 'cookie' is een klein tekstbestand dat door een website wordt verzonden en door de webbrowser van de gebruiker op de computer van de gebruiker wordt opgeslagen. Bij een bezoek aan een site die cookies gebruikt, wordt een cookie gedownload op uw computer of mobiele apparaat. De volgende keer dat u die site bezoekt, onthoudt uw apparaat nuttige informatie zoals voorkeuren, bezochte pagina's of inlogopties.",
    "privacy_policy_cookies_desc2": "Cookies worden veel gebruikt om functionaliteit toe te voegen aan websites, of om ervoor te zorgen dat ze efficiënter werken. Onze site is afhankelijk van cookies om de gebruikerservaring te optimaliseren en ervoor te zorgen dat de services van de site goed werken.",
    "privacy_policy_cookies_desc3": "De meeste webbrowsers bieden u de mogelijkheid om cookies te beperken of te blokkeren via de browserinstellingen. Als u cookies uitschakelt, kan dit echter gevolgen hebben voor de manier waarop u bepaalde onderdelen van onze website of diensten kunt gebruiken.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Analytische doeleinden: <\/strong> We gebruiken cookies om de activiteit van Account Holder te analyseren om onze website te verbeteren. We kunnen bijvoorbeeld cookies gebruiken om naar geaggregeerde patronen te kijken, zoals de functies die Account Holders gebruiken. We kunnen dergelijke analyses gebruiken om inzicht te krijgen in hoe we de functionaliteit en gebruikerservaring van onze website kunnen verbeteren.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Uw voorkeuren en gebruikerservaring: <\/strong> We gebruiken cookies om bepaalde informatie over bezoekers en accounthouders te verzamelen, zoals browsertype, server, taalvoorkeur en landinstelling, om accounthoudervoorkeuren op onze website op te slaan om de accounthouderervaring consistenter en gemakkelijker te maken. We gebruiken cookies om uw ingelogde status te behouden wanneer u onze website herhaaldelijk bezoekt.",
    "privacy_policy_cookies_targeted_ads": "<strong> Meetdiensten en beter gerichte advertenties en marketing bieden: <\/strong> We gebruiken cookies, web beacons en andere opslagtechnologieën van externe partners zoals Google en Facebook voor meetdiensten, beter gerichte advertenties en voor marketingdoeleinden. Dit vindt plaats wanneer u onze website bezoekt. Deze cookies, web beacons en andere opslagtechnologieën stellen ons in staat om Menuonline-promotiemateriaal aan u te tonen op andere sites die u op internet bezoekt.",
    "privacy_policy_security_title": "BEVEILIGING",
    "privacy_policy_security_desc1": "Menuonline spant zich in om vertrouwelijke informatie van alle soorten, inclusief persoonlijke informatie, te beschermen met organisatorische, fysieke, mechanische en elektronische beveiligingen die passen bij de gevoeligheid van de informatie. Onze servers bevinden zich in Canada en volgen de industrienormen met betrekking tot beveiliging.",
    "privacy_policy_security_desc2": "Wij willen u er echter op wijzen dat wij niet kunnen garanderen dat de maatregelen die wij treffen, de veiligheid van de informatie garanderen.",
    "privacy_policy_retention_title": "BEHOUD",
    "privacy_policy_retention_desc": "Wij bewaren persoonlijke gegevens van accounthouders en contactpersonen zo lang als nodig is voor de doeleinden die in dit privacybeleid zijn uiteengezet en in overeenstemming met de bewaartermijnen in de gebruiksvoorwaarden.",
    "privacy_policy_access_questions_title": "TOEGANG, VRAGEN EN ZORGEN",
    "privacy_policy_access_questions_desc": "Een persoon die persoonlijke informatie aan Menuonline heeft verstrekt, kan een schriftelijk verzoek indienen om zijn of haar persoonlijke informatie die door Menuonline wordt bewaard, in te zien of te corrigeren.",
    "terms_obj": {
        "terms_conditions_title": "ALGEMENE VOORWAARDEN",
        "terms_conditions_subtitle": "Algemene voorwaarden voor nieuwe en bestaande klanten",
        "terms_conditions_desc1": "DE VOLGENDE BELANGRIJKE PUNTEN (INCLUSIEF SECTIEVERWIJZINGEN) WORDEN UITSLUITEND VOOR UW GEMAK VERSTREKT EN VERVANGEN NIET DE VOLLEDIGE ALGEMENE VOORWAARDEN. HET IS UW VERANTWOORDELIJKHEID OM DEZE ALGEMENE VOORWAARDEN TE LEZEN VOORDAT U DE Menuonline SERVICE GEBRUIKT.",
        "terms_conditions_desc2": "Door Menuonline te gebruiken, gaat u akkoord met de Algemene Voorwaarden en verklaart en garandeert u dat u door uw organisatie of bedrijf bent gemachtigd om dit te doen. (Zie s.3 “Hoe het werkt”)",
        "terms_conditions_desc3": "Menuonline is een marketingdienst op basis van toestemming. Opzettelijk verzenden van \"spam\" of het schenden van de Algemene Voorwaarden zal resulteren in het sluiten van uw account. (Zie s.16 \"Aanvaardbaar gebruik van de dienst\")",
        "terms_conditions_desc4": "Gegevens die u host in Menuonline, zoals contactgegevens, e-mailinhoud en informatie die u verzamelt via Menuonline, zijn uw eigendom. (Zie s.14 “Wat bent u eigenaar?”).",
        "terms_conditions_desc5": "De software Menuonline en de inhoud van onze website zijn ons eigendom. (Zie s.13 “Wat bezitten wij?”)",
        "terms_conditions_desc6": "Naast uw eigen gebruikers kunnen de gegevens of ongepubliceerde inhoud die u host bij Menuonline alleen worden bekeken door Menuonline-werknemers en partners die een geheimhoudingsverklaring hebben ondertekend. (Zie s.14 “Wat bent u eigenaar?”).",
        "terms_conditions_desc7": "Alle persoonlijk identificeerbare informatie (PII) wordt uitsluitend bewaard op servers in Canada. (Zie s.14 “Wat bezit u?”).",
        "terms_conditions_desc8": "De Menuonline-service is onderworpen aan de wetten van Canada en de provincie Ontario. (Zie s.24 “Toepasselijk recht”)",
        "terms_conditions_desc9": "Alle verzoeken om uw gegevens of inhoud door personen, politie of andere regelgevende instanties van binnen of buiten Canada worden altijd naar u doorverwezen. De enige uitzondering zou zijn als dit ons in overtreding brengt van de Canadese of Ontario-wetgeving; echter, in alle gevallen zullen we ons krachtig verzetten tegen elke vrijgave van uw gegevens door iemand anders dan u. (Zie s.14 \"Wat bent u eigenaar?\").",
        "terms_conditions_desc10": "De beveiliging van Menuonline is alleen zo goed als uw beheer van gebruikersnamen en wachtwoorden. We brengen geen kosten in rekening voor Menuonline-gebruikersaccounts, dus maak voor elke gebruiker een apart account aan en houd wachtwoorden geheim. U bent verantwoordelijk voor uw account. (Zie s.3 “Hoe het werkt”)",
        "terms_conditions_desc11": "U kunt uw account op elk gewenst moment annuleren. Wij verstrekken geen restituties op ongebruikt tegoed als u annuleert. (Zie s.3 “Hoe het werkt”)",
        "terms_conditions_desc12": "We kunnen uw account op elk moment annuleren. We bieden restituties voor e-mailcredits en ongebruikte services als we uw account annuleren. (Zie s.3 “Hoe het werkt”)",
        "terms_conditions_desc13": "Uw gegevens worden na annulering verwijderd en kunnen ook worden verwijderd als een accountgebruiker 12 maanden of langer niet is ingelogd. We bewaren geaggregeerde gegevens van uw account die geen persoonlijk identificeerbare informatie bevatten voor statistische doeleinden. (Zie s.3 \"Hoe het werkt\")",
        "terms_conditions_desc14": "E-mailcredits worden gekocht op een pay-as-you-go basis en verlopen nooit. De enige uitzondering is als u 12 maanden niet bent ingelogd op uw account. In dat geval behouden wij ons het recht voor om uw account, uw gegevens en de bijbehorende credits te verwijderen. (Zie s.10 “Wat betaal ik voor de Service?”)",
        "terms_conditions_desc15": "We werken hard om ervoor te zorgen dat Menuonline altijd up and running is en om vooraf te melden als we de service tijdelijk moeten opschorten voor onderhoud. We garanderen echter niet dat de service altijd up and running is en we zijn niet aansprakelijk voor verliezen die u lijdt door het gebruik van Menuonline, inclusief verliezen van uw kant als het systeem niet draait op het moment dat u het nodig hebt. (Zie s.17 “Disclaimers”).",
        "terms_conditions_desc16": "Wij controleren uw content niet of naar wie u berichten stuurt. Wij controleren echter wel klachten en andere problemen die door uw activiteiten worden veroorzaakt. Te veel klachten of problemen kunnen leiden tot de annulering van uw service naar eigen goeddunken. (Zie s.7 “Monitoring”)",
        "terms_conditions_desc17": "Wij streven ernaar om uitstekende productondersteuning te bieden om u te helpen zelfvoorzienend te worden met Menuonline. Wij bieden ondersteuning in de vorm van documentatie en video op onze website, het Menuonline support ticket systeem en per telefoon. Er zijn geen kosten verbonden aan productondersteuning; we behouden ons echter het recht voor om de ondersteuning voor specifieke cliëntaccounts naar eigen goeddunken te beëindigen. (Zie s.5 “Ondersteuning”)",
        "terms_conditions_desc18": "Product Services is voor die momenten waarop u wilt dat wij werk doen gerelateerd aan Menuonline in plaats van het zelf te doen. Ons tarief voor Product Services is $135 per uur. Wij bieden ook adviesdiensten aan tegen hogere tarieven. Wij behouden ons het recht voor om onze tarieven op elk moment te wijzigen. (Zie s.6 “Product and Consulting Services”)",
        "terms_conditions_desc19": "Wij zullen u informeren als wij denken dat er een inbreuk op uw gegevens heeft plaatsgevonden en zullen ons best doen om gerelateerde details en informatie te verstrekken. (Zie s.14 “Wat bezit u?”)",
        "terms_conditions_desc20": "Wij behouden ons het recht voor om op elk gewenst moment wijzigingen aan te brengen in deze overeenkomst. Wij zullen proberen u te informeren over wijzigingen in de algemene voorwaarden, maar gebruik van de service betekent dat u akkoord gaat met wijzigingen in de voorwaarden. (Zie s.9 “Wijzigingen”).",
        "terms_conditions_desc21": "De Algemene Voorwaarden en ons Privacybeleid zijn uitsluitend in het Engels opgesteld. U gaat ermee akkoord dat de Engelstalige versie van de Algemene Voorwaarden en het Privacybeleid voorrang heeft in het geval van een conflict met een vertaalde versie.",
        "terms_conditions_mycircle": "MYCIRCLE ALGEMENE VOORWAARDEN",
        "terms_conditions_last_update": "LAATSTE UPDATE: 14 januari 2020",
        "terms_conditions_usage_agreement": "Lees deze servicevoorwaarden (de 'Voorwaarden') zorgvuldig door voordat u deze website (de 'Site') gebruikt. Deze Voorwaarden ontheffen Menuonline ('Menuonline', 'wij' of 'ons') en anderen van aansprakelijkheid en\/of beperken onze en hun aansprakelijkheid en bevatten andere belangrijke bepalingen die van toepassing zijn op uw gebruik van deze Site.",
        "terms_conditions_acceptance": "Door deze Site of de Service te bezoeken of te gebruiken, stemt u ermee in om namens uzelf en namens elke organisatie namens wie u handelt (hierna gezamenlijk aangeduid als 'u') deze Voorwaarden te accepteren en na te leven bij elk gebruik van en elk bezoek aan deze Site.",
        "terms_conditions_services": "1. Onze diensten.",
        "terms_conditions_services_desc": "De diensten die wij via de Site aanbieden, zijn een dienst waarmee u e-mailberichten ('E-mails') kunt maken, verzenden en beheren naar ontvangers van uw keuze (de 'Dienst').",
        "terms_conditions_license": "2. Licentie.",
        "terms_conditions_license_desc": "Onder voorbehoud van deze Voorwaarden verleent Menuonline u hierbij een niet-exclusieve, beperkte, niet-overdraagbare licentie gedurende de Termijn om de Site te bekijken en de Service te gebruiken zoals toegestaan door de functies van de Service. Wij behouden ons alle rechten voor die hierin niet uitdrukkelijk worden verleend in de Service en de Menuonline Content (zoals hieronder gedefinieerd).",
        "terms_conditions_account": "3. Hoe het werkt.",
        "terms_conditions_account_desc": "Om de Service te gebruiken, moet u zich registreren voor een account. Uw account is gratis. Uw account geeft u toegang tot de Service en \"functies\" die wij van tijd tot tijd kunnen instellen en onderhouden. Om in aanmerking te komen voor een account, moet u:",
        "terms_conditions_account_requirements": [
            "ten minste achttien (18) jaar oud zijn en contracten kunnen aangaan;",
            "het registratieproces voltooien dat wij hebben uiteengezet;",
            "akkoord gaan met deze Algemene Voorwaarden;",
            "Geef waarheidsgetrouwe, volledige en actuele contact- en factuur-\/betalingsgegevens."
        ],
        "responsibility": "Verantwoordelijkheid.",
        "responsibilityText": "U bent als enige verantwoordelijk voor de activiteit die plaatsvindt op uw account en u moet uw accountwachtwoord vertrouwelijk en veilig houden. Accounts zijn gratis, dus we raden u aan om voor elke gebruiker die u hebt een ander account in te stellen. U bent ook verantwoordelijk voor elk account waartoe u toegang hebt, ongeacht of u het gebruik van dat account hebt geautoriseerd. U stemt ermee in dat u als enige verantwoordelijk bent voor uw interacties met degenen naar wie u e-mails stuurt en u zult alleen e-mails sturen naar die mensen van wie u toestemming hebt. U moet ons onmiddellijk op de hoogte stellen van elk ongeautoriseerd gebruik van uw accounts.",
        "compliance_with_CASL": "Naleving van de Canadese anti-spamwetgeving (CASL).",
        "compliance_with_CASL_text": "De rol van Menuonline is om het beheer van toestemmingsinstellingen te vergemakkelijken, niet om wetgeving af te dwingen. Het is uw verantwoordelijkheid om te voldoen aan CASL. Wij helpen u door standaardfunctionaliteit in te stellen en door het verkrijgen en bijwerken van gedocumenteerde uitdrukkelijke toestemming te vergemakkelijken en door het beheren van doorlopende vervaldatums voor impliciete toestemming. U hebt volledige controle over het beheer van toestemming voor uw database; u kunt de toestemmingsstatus voor al uw contacten op elk gewenst moment in bulk bijwerken, standaardinstellingen overschrijven en automatische vervaldatum van impliciete toestemmingsstatus uitschakelen.",
        "closing_account": "Uw account sluiten.",
        "closing_account_text": "U of Menuonline kan deze Overeenkomst op elk moment en om welke reden dan ook beëindigen door de andere partij hiervan op de hoogte te stellen. Als u informatie verstrekt die onwaar, onjuist, niet actueel of onvolledig is of de Service op een manier gebruikt die in strijd is met deze Algemene Voorwaarden, hebben wij het recht om uw account onmiddellijk op te schorten en uw toegang tot de Service te beëindigen, naast alle andere rechtsmiddelen die voor ons beschikbaar zijn. Als u uw account twaalf (12) maanden niet gebruikt, kunnen wij uw account annuleren. Als wij uw account om welke reden dan ook beëindigen, betalen wij u alle Credits die u nog hebt terug. Na beëindiging zullen wij uw account en alle daaraan gekoppelde gegevens permanent verwijderen, inclusief de e-mails die u hebt verzonden. Wij kunnen geaggregeerde gegevens van uw account bewaren die geen persoonlijk identificeerbare informatie bevatten voor statistische doeleinden.",
        "emailing_content": "E-mailinhoud.",
        "emailing_content_text": "Wanneer u via deze Site of de Service met Menuonline communiceert, stemt u ermee in om waarheidsgetrouwe en nauwkeurige informatie te verstrekken. Verder begrijpt u dat het verstrekken van vertrouwelijke informatie via e-mail een onveilige praktijk is. U begrijpt dat u als enige verantwoordelijk bent voor alles wat u naar iemand stuurt die onze Service gebruikt. doeleinden.",
        "support": "Steun.",
        "support_text": "Wij bieden u ondersteuning om u te helpen zelfvoorzienend te worden met de Service. Wij bieden u ondersteuning in de vorm van documentatie en video's op de Site, het Menuonline support ticket systeem en per telefoon. U kunt ons bereiken door te klikken op het tabblad \"Support\" bovenaan de Site. Er zijn geen kosten verbonden aan productondersteuning; wij behouden ons echter het recht voor om de ondersteuning voor specifieke cliëntaccounts naar eigen goeddunken te beëindigen.",
        "product_and_consulting_services": "Product- en adviesdiensten.",
        "product_and_consulting_services_text": "Product Services is voor die momenten waarop u wilt dat wij werk doen gerelateerd aan Menuonline in plaats van het zelf te doen. Ons tarief voor Product Services is $125 per uur. Wij bieden ook adviesdiensten aan tegen hogere tarieven. Wij behouden ons het recht voor om onze tarieven op elk moment te wijzigen.",
        "monitoring": "Toezicht.",
        "monitoring_text": "Wij zullen de inhoud van informatie die via de Service wordt verzonden niet controleren of beoordelen, maar zullen onderzoeken of wij klachten ontvangen over mogelijk ongepast gebruik. Menuonline kan op elk moment en naar eigen goeddunken bepalen of bepaald gebruik wel of niet gepast is, met of zonder kennisgeving aan u, volgens de volgende richtlijnen.",
        "term": "Termijn.",
        "term_text": "De Termijn begint wanneer u zich aanmeldt voor een account bij ons en duurt voort zolang u de Service gebruikt. U verklaart en garandeert dat u de bevoegdheid en het vermogen hebt om u aan te melden voor een account voor ons (ook als u zich aanmeldt namens een organisatie).",
        "changes": "Veranderingen.",
        "changes_text": "De Termijn begint wanneer u zich aanmeldt voor een account bij ons en duurt voort zolang u de Service gebruikt. U verklaart en garandeert dat u de bevoegdheid en het vermogen hebt om u aan te melden voor een account voor ons (ook als u zich aanmeldt namens een organisatie).",
        "payment": "BETALING",
        "what_do_i_pay_for_service": "Hoeveel betaal ik voor de Service?",
        "what_do_i_pay_for_service_text": "U kunt vooraf betaalde credits kopen om e-mails te versturen (\"Credits\") via onze Service, zoals we gedetailleerd beschrijven op de pagina \"prijzen\" van onze Site. De Credits verlopen nooit en er zijn geen terugkerende kosten voor deze Credits. De enige uitzondering is dat als u uw account twaalf (12) maanden niet gebruikt, we uw account en ongebruikte Credits kunnen annuleren. We kunnen onze prijzen op elk moment wijzigen door de prijspagina's van onze Site bij te werken, die van toepassing zijn op uw volgende aankoop van Credits.",
        "how_do_i_pay_for_service": "Hoe betaal ik voor de Service?",
        "how_do_i_pay_for_service_text": "U kunt Credits betalen met elke gangbare creditcard. We kunnen op elk moment de betaalmethoden die we accepteren wijzigen.",
        "can_i_get_refund": "Kan ik mijn geld terugkrijgen?",
        "can_i_get_refund_text": "De bedragen die u hebt betaald voor het gebruik van de Service zijn niet restitueerbaar als u uw account annuleert. Als wij uw account annuleren, betalen wij u alle Credits die u nog hebt terug.",
        "rights": "RECHTEN",
        "what_do_we_own": "Wat bezitten wij?",
        "what_do_we_own_text": "Met uitzondering van de inhoud die u aan ons verstrekt (inclusief, maar niet beperkt tot, contactgegevens, e-mailinhoud en informatie die u verzamelt via de Service) (\"Uw inhoud\"), zijn alle materialen die op onze Site, de Service en alle materialen daarin of daardoor overgedragen zijn, inclusief maar niet beperkt tot, software, afbeeldingen, tekst, grafieken, illustraties, logo's, patenten, handelsmerken, dienstmerken, foto's, audio, video's, muziek en inhoud die toebehoort aan andere Gebruikers (de \"Menuonline-inhoud\") (en alle intellectuele eigendomsrechten die daarmee verband houden) eigendom van ons of onze licentiegevers.",
        "what_do_you_own": "Wat bezit je?",
        "what_do_you_own_text": "Wij claimen geen eigendomsrechten op Uw Content. Uw Content is van u en u behoudt het eigendom van Uw Content die u uploadt naar de Service. Door Uw Content te delen via de Service, stemt u ermee in dat anderen Uw Content kunnen bekijken, bewerken en\/of delen in overeenstemming met uw instellingen en deze Algemene Voorwaarden. U verklaart en garandeert dat u eigenaar bent van of toestemming hebt om al het materiaal dat u gebruikt in uw e-mails te gebruiken. Wij mogen uw materialen alleen gebruiken of bekendmaken zoals beschreven in deze Voorwaarden en ons Privacybeleid.",
        "privacy_statement": "Menuonline respecteert de privacy van haar gebruikers. Alle persoonlijke informatie die Menuonline verzamelt via deze Site of de Service is onderworpen aan de Menuonline Privacyverklaring die bij verwijzing is opgenomen in deze Voorwaarden. Wij kunnen uw informatie gebruiken en openbaar maken in overeenstemming met onze Privacyverklaring.",
        "access_to_your_content": "Menuonline zal niemand toegang verlenen tot Uw Inhoud, met uitzondering van de werknemers en contractanten van Menuonline die passende geheimhoudingsovereenkomsten met ons hebben ondertekend of wij uw toestemming hebben.",
        "governmentRequest": "Als we een verzoek van een overheid of regelgevende instantie ontvangen om toegang te krijgen tot Uw Content, zullen we u op de hoogte stellen van het verzoek en uw toestemming vragen voordat we Uw Content vrijgeven. We zullen alle verzoeken om Uw Content weerstaan en Uw Content niet vrijgeven in reactie op dit type verzoek zonder uw toestemming of indien vereist door een bevoegde rechtbank of de toepasselijke regelgevende instantie.",
        "breachNotification": "In het geval dat wij ons ervan bewust worden dat Uw Content onderhevig is geweest aan ongeautoriseerde toegang, gebruik, kopiëren, diefstal, verlies of gebruik of openbaarmaking in strijd met deze Voorwaarden (een 'Inbreuk'), zullen wij u onmiddellijk op de hoogte stellen en u informeren over wat er heeft plaatsgevonden. U stemt ermee in dat Inbreuken worden afgehandeld in overeenstemming met de toepasselijke wetgeving, en als wij u nodig hebben om leden van uw e-maildistributielijsten te informeren, stemt u ermee in dit onmiddellijk te doen en zoals wij u opdragen.",
        "contentRetention": "Wij bewaren, gebruiken en verzenden Uw Content in Canada, behalve met uw voorafgaande schriftelijke toestemming of instructie (bijvoorbeeld als u ervoor kiest om Uw Content naar personen buiten Canada te sturen). De servers die worden gebruikt om de Service te leveren, bevinden zich in Canada.",
        "accessTrouble": "Als u problemen ondervindt bij het openen van Uw Content, helpt Menuonline u bij het ophalen van Uw Content op basis van tijd en materiaal.",
        "reportingAbuse": "Als u denkt dat iemand een van deze Voorwaarden schendt, laat het ons dan onmiddellijk weten. Als u spams ontvangt waarvan u denkt dat deze afkomstig zijn van een andere gebruiker van de Service, als u denkt dat een andere gebruiker van de Service materiaal heeft gepost dat inbreuk maakt op auteursrechten, of als u denkt dat een andere gebruiker van de Service op een andere manier deze Voorwaarden schendt, neem dan contact met ons op via Support@menuonline.com.",
        "acceptableUse": "U verklaart en garandeert dat uw gebruik van de Service voldoet aan alle toepasselijke wetten en regelgevingen. U stemt ermee in dat u verantwoordelijk bent voor het bepalen of de Service voldoet aan en geschikt is voor u om te gebruiken in uw rechtsgebied. Voor meer zekerheid stemt u ermee in dat u (en stemt ermee in ervoor te zorgen dat uw werknemers, agenten en geautoriseerde gebruikers) ...",
        "exportLaws": "Wij geven geen enkele verklaring dat de Site of de Service geschikt of beschikbaar is voor gebruik buiten Canada, en toegang tot deze Sites vanuit gebieden waar hun inhoud illegaal is, is verboden. U mag de Menuonline Content of andere materialen op deze Site of een kopie of aanpassing ervan niet gebruiken, exporteren of opnieuw exporteren in strijd met toepasselijke wetten of regelgeving, inclusief maar niet beperkt tot Canadese en Amerikaanse exportwetten en -regelgeving.",
        "disclaimers": "De Service en deze Site worden geleverd 'zoals ze zijn', zonder enige verklaringen, garanties of voorwaarden van welke aard dan ook. Menuonline wijst uitdrukkelijk alle verklaringen, garanties en voorwaarden af, expliciet of impliciet, van rechtswege of anderszins, voor zover toegestaan door de toepasselijke wetgeving, met inbegrip van, maar niet beperkt tot, impliciete garanties en voorwaarden van verhandelbaarheid, geschiktheid voor een bepaald doel of gebruik, of niet-inbreuk op rechten van derden.",
        "limitLiability": "De totale aansprakelijkheid van Menuonline onder deze Overeenkomst, hetzij op grond van contract of onrechtmatige daad (inclusief nalatigheid), als gevolg van een schending van de garantie, strikte aansprakelijkheid of onder welke andere aansprakelijkheidstheorie dan ook, is beperkt tot directe schade tot een bedrag dat niet hoger is dan de totale vergoedingen die aan Menuonline zijn betaald onder deze Algemene Voorwaarden in de twaalf (12) maanden onmiddellijk voorafgaand aan de maand waarin de meest recente gebeurtenis die aanleiding gaf tot de aansprakelijkheid, heeft plaatsgevonden.",
        "liabilityExclusions": "Niemand van Menuonline, haar dochterondernemingen of hun respectievelijke directeuren, functionarissen, werknemers, agenten of andere vertegenwoordigers (gezamenlijk 'Menuonline \"Vertegenwoordigers\"') of de exploitanten van aangesloten netwerken is verantwoordelijk of aansprakelijk in verband met deze Site of de Service voor: (i) enige indirecte, gevolg-, incidentele, exemplarische, punitieve of speciale schade; of (ii) voor enige schade, hetzij direct, indirect, gevolg-, incidenteel, exemplarisch, punitief of speciaal, gekenmerkt als verloren inkomsten, verloren besparingen of inkomsten of verloren winst...",
        "indemnity": "U vrijwaart en stelt Menuonline en\/of de Menuonline-vertegenwoordigers (de 'vrijgestelde partijen van Menuonline') schadeloos tegen alle claims van derden en alle boetes of straffen die aan ons worden opgelegd, voortvloeiend uit uw gebruik van de informatie die u op deze site hebt verkregen of uw gebruik van de service, en elke schending van deze voorwaarden.",
        "ipInfringement": "Menuonline zal u verdedigen, schadeloos stellen en vrijwaren tegen alle claims, eisen, acties, rechtszaken of procedures die tegen u worden aangespannen door een derde partij, waarin wordt beweerd dat uw toegang tot of gebruik van de Service in overeenstemming met deze Overeenkomst inbreuk maakt op de intellectuele eigendomsrechten van een derde partij volgens het Canadese recht (een 'IP-inbreukclaim').",
        "equitableRelief": "Als u deze Voorwaarden schendt, kunnen wij een gerechtelijk bevel (wat betekent dat wij een gerechtelijk bevel kunnen aanvragen om u te stoppen) of andere billijke voorzieningen nastreven. U stemt ermee in dat dit een aanvulling is op en onverminderd alle andere rechten die wij mogelijk hebben op grond van de wet of billijkheid.",
        "linksToOtherSites": "Als wij links naar websites van derden aanbieden, worden deze alleen voor het gemak aangeboden. Als u deze links gebruikt, verlaat u onze site. Wij hebben geen controle over deze sites van derden en onderschrijven ze niet. U stemt ermee in dat wij niet verantwoordelijk zijn voor deze sites van derden of hun inhoud...",
        "thirdPartyRequests": "Als we informatie moeten verstrekken in reactie op een juridisch of overheidsverzoek met betrekking tot uw account of uw gebruik van de Service, dan gaat u ermee akkoord dat we u onze kosten in rekening kunnen brengen. Deze kosten kunnen onze juridische kosten en de kosten van onze werknemerstijd omvatten die is besteed aan het voorbereiden van het antwoord op het verzoek.",
        "assignment": "Deze Overeenkomst en alle rechten en licenties die hierin worden verleend, mogen niet door u worden overgedragen of toegewezen, maar kunnen door Menuonline zonder beperking worden toegewezen, na redelijke voorafgaande kennisgeving aan u.",
        "governingLaw": "Deze voorwaarden worden beheerst door en geïnterpreteerd in overeenstemming met alle toepasselijke federale wetten en de wetten van Ontario, ongeacht de bepalingen inzake conflicterende wetten. U stemt ermee in zich te onderwerpen aan de exclusieve jurisdictie van de rechtbanken van Ontario voor de beslechting van enig geschil met betrekking tot deze Voorwaarden.",
        "severability": "Indien een bevoegde rechtbank om welke reden dan ook een bepaling van deze Voorwaarden of een gedeelte daarvan onrechtmatig of om welke reden dan ook niet-afdwingbaar acht, dan zal die bepaling worden gehandhaafd voor zover maximaal toegestaan om uitvoering te geven aan de bedoeling van deze Voorwaarden. De overige bepalingen van deze Voorwaarden blijven volledig van kracht en uitvoerbaar.",
        "waiver": "Elke verklaring van afstand van, of toestemming om af te wijken van, de vereisten van deze Voorwaarden is alleen geldig als deze schriftelijk is en door ons is ondertekend, en alleen in het specifieke geval en voor het specifieke doel waarvoor deze is gegeven. Ons onvermogen om enig recht uit te oefenen of onze vertraging bij het uitoefenen van enig recht onder deze Voorwaarden zal niet gelden als een verklaring van afstand van onze rechten.",
        "furtherAssurances": "Elke partij stemt ermee in alle documenten te ondertekenen en te verstrekken en alle nodige maatregelen te nemen (of te laten nemen) om aan haar verplichtingen onder deze Algemene voorwaarden te voldoen.",
        "notices": "Alle mededelingen aan Menuonline moeten schriftelijk zijn en per e-mail naar ons worden verzonden. Mededelingen aan ons moeten worden verzonden ter attentie van onze klantenservicemedewerkers op support@menuonline.com. Mededelingen aan u kunnen worden verzonden naar het adres of e-mailadres dat u hebt opgegeven als onderdeel van uw registratie. Daarnaast kunnen we mededelingen of berichten via deze site uitzenden om u te informeren over wijzigingen op deze site of andere belangrijke zaken, en deze uitzendingen vormen een kennisgeving aan u op het moment dat we ze plaatsen.",
        "entireAgreement": "Deze Voorwaarden en onze Privacyverklaring vormen de volledige overeenkomst tussen u en ons met betrekking tot uw gebruik van de Site en de Service, en vervangen alle eerdere overeenkomsten, verklaringen en afspraken tussen Menuonline en u met betrekking tot dit onderwerp. In het geval van een conflict tussen de Privacyverklaring en deze Voorwaarden, zijn deze Algemene voorwaarden van toepassing.",
        "language": "De partijen hebben uitdrukkelijk verzocht en geëist dat deze Algemene voorwaarden, ons Privacybeleid en alle gerelateerde documenten in het Engels worden opgesteld. Les parties conviennent et exigent expressément que ce Contract et tous les documents qui s'y rapportent soient rédigés en anglais. U stemt ermee in dat de Engelstalige versie van deze Algemene voorwaarden en het Privacybeleid voorrang heeft in het geval van een conflict met een vertaalde versie.",
        "survival": "Alle bepalingen van deze Voorwaarden die naar hun aard of strekking bedoeld zijn om na afloop of beëindiging van deze Voorwaarden van kracht te blijven, blijven van kracht, met inbegrip van, maar niet beperkt tot, de artikelen 17-19."
    },
    "collect_additional_info": "Wilt u aanvullende informatie verzamelen?",
    "additionalInfoChoice": "Selecteer eventuele aanvullende informatie die u wilt verzamelen.",
    "additional_info_yes": "Ja",
    "additional_info_noSkip": "Nee, overslaan",
    "additional_info_label": "Aanvullende informatie",
    "confirm_business_location": "Laten we eerst de locatie van uw bedrijf bevestigen",
    "custom_get_more_reviews_form": "Aangepast formulier voor meer beoordelingen",
    "set_up_custom_form": "Stel uw aangepaste formulier 'Krijg meer beoordelingen' per groep in",
    "reputation_name_label": "Ontvang meer beoordelingen Naam",
    "reputation_name_placeholder": "Voer reputatienaam in",
    "group_label": "Groep",
    "group_desc_title": "Groepsbeschrijving titel",
    "group_desc": "Groepsbeschrijving",
    "select_group_placeholder": "Selecteer groep",
    "required_group_name": "Groepsnaam is vereist",
    "before_asking_review": "Voordat we de klanten om een beoordeling vragen, stellen we ze 1 tot 4 vragen.",
    "feel_free_edit": "U kunt deze tekst indien nodig bewerken.",
    "incentive_settings": "Stimulerende instellingen",
    "incentivize_customer": "Wilt u uw klant belonen voor het schrijven van een beoordeling?",
    "choose_incentive": "Selecteer de incentive die u wilt doorverwijzen.",
    "scan_qr_feedback": "Scan de QR-code en deel uw waardevolle feedback met ons over uw ervaring.",
    "qr_code": "QR-code",
    "scan_me": "SCAN MIJ!",
    "or_title": "OF",
    "send_otp_title": "Stuur een OTP",
    "sign_in_title": "Aanmelden",
    "email_addresss_title": "E-mailadres",
    "reset_password_title": "Wachtwoord opnieuw instellen",
    "remember_password_title": "Wachtwoord onthouden?",
    "digit_code_title": "Voer de 4-cijferige code in die u heeft ontvangen ",
    "not_received_title": "Ik heb geen verificatiecode ontvangen!",
    "resend_title": "Gelieve opnieuw te verzenden",
    "sign_up_form": "Maak uw account aan",
    "sign_up_sub_title": "Meld u nu aan om toegang te krijgen tot de nieuwste inzichten",
    "already_registered_title": "Heeft u al een Menuonline-account?",
    "reserved_title": "Auteursrechten ©2023 Menuonline. Alle rechten voorbehouden.",
    "terms_title": "Servicevoorwaarden",
    "policy_title": "Privacybeleid",
    "sign_up_title": "Aanmelden",
    "terms_conditions_title": "Algemene voorwaarden",
    "business_type_title": "Bedrijfstype",
    "business_type_sub_title": "Selecteer een van de bedrijfstypen die het beste bij uw bedrijf past!",
    "salon_title": "Salon",
    "restaurant_title": "Restaurant",
    "pizza_store_title": "Pizzawinkel",
    "food_truck_title": "Eetwagen",
    "check_domain_title": "Controleer domeinnaam",
    "start_exploring_title": "Begin met verkennen",
    "sign_in_form": "Meld u aan bij uw account",
    "sign_in_sub_title": "Meld u nu aan om toegang te krijgen tot de nieuwste inzichten",
    "remember_title": "Onthoud mij",
    "otp_title": "eenmalig",
    "password_title": "Wachtwoord",
    "forgot_title": "Wachtwoord vergeten?",
    "not_registered_title": "Heb je nog geen account?",
    "create_account_title": "Meld je nu aan",
    "create_form_title": "Formulier maken",
    "first_name_title": "Voornaam",
    "last_name_title": "Achternaam",
    "email_title": "E-mail",
    "mobile_number_title": "Mobiel nummer",
    "birthday_title": "Verjaardag",
    "gender_title": "Geslacht",
    "address_title": "Adres",
    "short_answer_title": "Kort antwoord",
    "long_answer_title": "Lang antwoord",
    "single_answer_title": "Eén antwoord",
    "single_checkbox_title": "Enkelvoudig selectievakje",
    "multiple_choice_title": "Meerkeuzevraag",
    "drop_down_title": "Uitklapmenu",
    "yes_no_title": "Ja of Nee",
    "description_text_title": "Beschrijving tekst",
    "file_upload": "Bestand uploaden",
    "reset_password_form": "Reset uw wachtwoord",
    "reset_password_sub_title": "Stel snel uw wachtwoord opnieuw in en krijg toegang tot uw account.",
    "required_full_name_title": "Volledige naam is vereist",
    "required_first_name_title": "Voornaam is verplicht",
    "valid_first_name_title": "Voer een geldige voornaam in",
    "valid_full_name_title": "Voer een geldige volledige naam in",
    "required_block_time": "Bloktijd is vereist",
    "required_last_name_title": "Achternaam is verplicht",
    "valid_last_name_title": "Voer een geldige achternaam in",
    "valid_mobile_number_title": "Voer een geldig mobiel nummer in",
    "required_mobile_number_title": "Mobiel nummer is vereist",
    "required_already_mobile_number_title": "Mobiel nummer is al vereist",
    "valid_otp_title": "Voer een geldige otp in",
    "valid_email_title": "Voer een geldig e-mailadres in",
    "required_email_title": "E-mail is vereist",
    "required_description_title": "Beschrijving is vereist",
    "required_description_500_characters": "Voer minimaal 500 tekens in",
    "required_description_600_characters": "Voer maximaal 600 tekens in",
    "domain_title": "Domeinnaam",
    "required_password_title": "Wachtwoord is vereist",
    "required_password_regex_title": "Moet minimaal 8 tekens bevatten, waarvan minstens één hoofdletter, één kleine letter, één cijfer en één speciaal hoofdletterteken",
    "required_terms_title": "Ga akkoord met de algemene voorwaarden",
    "required_note_title": "Opmerking is vereist",
    "required_birthday_title": "Geboortedatum is vereist",
    "no_access_title": "U hebt nu geen toegang. Neem contact op met uw beheerder.",
    "menu_online_open_txt": "Open",
    "loyalty_program_description": "😊 Vind uw ideale loyaliteitsprogramma en pas het aan zodat het bij uw merk past!",
    "lp_list_title": "Lijst met loyaliteitsponskaarten",
    "lp_n_title": "Naam van de loyaliteitsponskaart",
    "enter_details_title": "Gegevens invoeren",
    "stamp_allowed_title": "Zegel toegestaan",
    "stamp_items_title": "Postzegelartikelen",
    "lpn_req_title": "Naam van de loyaliteitsponskaart is vereist",
    "valid_lpn_title": "Voer een geldige naam voor de loyaliteitsstempelkaart in",
    "brand_color_error": "De merkkleur moet exact 7 tekens lang zijn",
    "select_dark_brand_color_title": "Selecteer een geldige merkkleur",
    "your_brand_color_title": "Uw merkkleur",
    "upload_logo_title": "Logo uploaden",
    "locations_title": "Locaties",
    "add_lp_title": "Programma maken",
    "location_error": "Selecteer locaties",
    "pvs_title": "Hoeveel stempels kan een klant per bezoek verdienen?",
    "max_apd_title": "Wat is het maximale aantal stempels dat een klant per dag kan verdienen?",
    "logo_error": "Logo is vereist",
    "expiry_title": "Vervaldatum voor beloningen instellen",
    "add_more_rewards_title": "+ Voeg meer beloningen toe",
    "prev_step_1_title": "Toon QR om punten te verdienen en geniet van uw beloningen.",
    "delete_lp_title": "Deze klantenkaart wordt definitief verwijderd.",
    "delete_user_record_title": "Deze gebruikersgegevens worden permanent verwijderd.",
    "redeem_title": "Inwisselen",
    "lp_button_title": "Word lid van de Loyaliteitsponskaart",
    "lp_download_button_title": "Kaart downloaden",
    "step_0_title": "Meld je nu aan, er staan heerlijke beloningen te wachten voor trouwe fijnproevers zoals jij.",
    "step_1_title": "Je bent lid geworden",
    "congrats_step_title": "Nu komt u in aanmerking voor gratis pizza",
    "reward_title": "Ontvang beloningen bij elk bezoek",
    "visit_title": "Bezoek & Ontgrendel",
    "rewards_title": "Beloningen",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, Verenigde Staten",
    "loyalty_qr_default_text": "Scan de QR-code, registreer u voor onze loyaliteitsstempelkaart en geniet van beloningen",
    "lp_earn_stamp_txt": "Bij elk bezoek verdienen klanten stempels.",
    "lp_get_rewards_txt": "Ontvang beloningen bij elk bezoek",
    "lp_1_time_visit_txt": "1 keer bezoek U krijgt",
    "lp_uppercase_stamp_text": "Stempel",
    "lp_lowercase_stamp_text": "stempel",
    "lp_txt": "Loyaliteit",
    "lp_per_visit_stamp_validation_msg": "Per bezoek mag het stempel minder zijn dan het maximaal toegestane stempel per dag.",
    "lp_per_max_stamp_validation_msg": "Het maximaal toegestane stempel per dag moet groter zijn dan het stempel per bezoek",
    "lp_valid_value_msg": "Voer een geldige waarde in",
    "lp_required_field_msg": "Dit veld is verplicht",
    "lp_stamp_required_msg": "Postzegel is vereist",
    "lp_valid_stamp_msg": "Voer een geldige postzegelwaarde in",
    "lp_reward_name_required_msg": "Beloningsnaam is vereist",
    "lp_unique_stamp_msg": "De postzegelwaarde moet uniek zijn",
    "lp_benefits": "Een loyaliteitsprogramma verhoogt herhaalbezoeken en omzet, terwijl het klanteninzichten biedt voor gepersonaliseerde marketing",
    "lp_how_customer_txt": "Zo doen uw klanten dat",
    "lp_punch_card_txt": "loyaliteitsponskaart",
    "lp_look_txt": "zal kijken! 😍",
    "lp_desc": "Bij elk bezoek krijgen klanten stempels. Zodra ze er genoeg hebben verzameld, kunnen ze speciale beloningen inwisselen.",
    "lp_edit": "Bewerking",
    "lp_looks_good_txt": "Ziet er goed uit",
    "lp_customer_view_instruction": "Dit is wat uw klant zal zien. Upload uw logo en stel het kleurenthema in",
    "lp_customer_earn_stamps": "Laat klanten bij elk bezoek stempels verdienen en hun beloningen beheren",
    "lp_stamp_rewards_setup": "Stel beloningen in voor het verdienen van stempels, zodat klanten beloningen per stempel kunnen krijgen",
    "lp_loyalty_program_setup": "Geef de naam van uw loyaliteitsprogramma op en stel uw locatie in om dit programma te hebben",
    "lp_prev_step1": "Toon QR om punten te verdienen en geniet van uw beloningen.",
    "lp_visit_1_time_txt": "Bezoek 1 keer en krijg",
    "lp_get_free_txt": "Gratis krijgen",
    "lp_visit_allow_txt": "Bezoek & Ontgrendel",
    "lp_rewards": "Beloningen",
    "repsT": "Ontvang meer beoordelingen",
    "offSup": "Kantoorartikelen",
    "repsNxtV": "Bij uw volgende bezoek",
    "addRepT": "Toevoegen Krijg meer beoordelingen",
    "repEntrCpnTxt": "Voer coupontekst in",
    "repEntrCpnTxtBtn": "Voer couponknoptekst in",
    "repMngNxtV": "bij uw volgende bezoek?",
    "repMngWldL": "Wil je krijgen",
    "discount": "Korting",
    "repDisTyp": "Kortingstype",
    "repNameT": "Geef je reputatie een naam",
    "crtRepT": "Creëer reputatie",
    "feedBackT": "Feedback",
    "confLocT": "Bevestig locatie",
    "businLocT": "Bedrijfslocatie",
    "addInfoT": "Aanvullende informatie",
    "repNReqT": "Ontvang meer beoordelingen naam is vereist",
    "validRepNT": "Voer een geldige reputatienaam in",
    "groupT": "Groep",
    "groupLDescTitleT": "Contacten automatisch in een groep plaatsen",
    "groupLDescT": "Alle klanten die het formulier invullen, worden toegevoegd aan de geselecteerde groep.",
    "que2": "Was het eten lekker?",
    "que3": "Was onze service vriendelijk?",
    "que4": "Was onze service snel?",
    "addAdditionalT": "Wilt u nog aanvullende informatie toevoegen?",
    "phNoMandT": "Telefoonnummer is verplicht!",
    "qrGenT": "QR gegenereerd",
    "repFDT": "Met uw input kunnen wij u nog beter van dienst zijn!",
    "letdiT": "Laten we het doen",
    "tak15secT": "Duurt 15 seconden",
    "defQue": "Wilt u van tijd tot tijd geweldige aanbiedingen en promoties ontvangen?",
    "defNQue": "Wilt u dat iemand op u reageert?",
    "rateOnGoogleDesc": "Zou u zo vriendelijk willen zijn om ons op Google te beoordelen?",
    "mayBeNT": "Misschien de volgende keer",
    "yesSureT": "Ja, zeker",
    "valid_name": "Voer een geldige naam in",
    "nameReq": "Naam is verplicht",
    "backToMainT": "Terug naar de hoofdpagina",
    "attachCopT": "Wilt u uw klant een beloning geven?",
    "thankYouT": "Bedankt",
    "lftyT": "Wij kijken uit naar uw volgende bezoek!",
    "repPreT": "Reputatie voorbeeld",
    "offer_exclusive_discounts": "Bied exclusieve kortingen en promoties aan om uw klanten te verleiden.",
    "couponT": "Coupon",
    "locStepDesc": "Bevestig uw bedrijfslocatie",
    "createRepStepDesc": "Maak een aangepast reputatieformulier per groep",
    "feedbackStepDesc": "Vragenset voor feedback van klanten",
    "addInfoStepDesc": "Klantvelden en incentive-instellingen",
    "FedbkkWDT": "Feedback met gegevens",
    "repAnlytT": "Reputatieanalyse",
    "todayT": "Vandaag",
    "rep_last_7_days_t": "Laatste 7 dagen",
    "last30DaysT": "Laatste 30 dagen",
    "lastMT": "Vorige maand",
    "custRangeT": "Aangepast bereik",
    "queWiseAnltT": "Vraag Wijze Analyse",
    "atL1QueErrT": "Selecteer minimaal één vraag",
    "deleteRepTitle": "Deze reputatie wordt permanent verwijderd.",
    "incvCustT": "Ja, stimuleer mijn klant",
    "collAddInfoT": "Ja, ik wil graag aanvullende informatie verzamelen",
    "totRewT": "Totale beoordeling",
    "totPosRewT": "Totaal positieve beoordeling",
    "negFeedT": "Negatieve feedback",
    "posFeedT": "Positieve feedback",
    "fineT": "Kleine lettertjes",
    "enterFineT": "Voer kleine lettertjes in",
    "setThemeT": "Themakleur instellen",
    "que1T": "Vraag 1",
    "que2T": "Vraag 2",
    "que3T": "Vraag 3",
    "que4T": "Vraag 4",
    "entMailT": "Voer uw e-mailadres in",
    "reputationQRDefTxt": "Scan de QR-code en deel uw waardevolle feedback met ons over uw ervaring.",
    "selUser": "Selecteer gebruiker",
    "userT": "Gebruiker",
    "subUReq": "Selecteer gebruikers",
    "updateLoc": "Locatie bijwerken",
    "leadGenT": "Leadgeneratie",
    "displayT": "Weergave",
    "ratingT": "Beoordeling",
    "rep_dashboard_negative_feedback": "Negatieve feedback 0%",
    "rep_dashboard_position_feedback": "Positieve feedback 0%",
    "rep_dashboard_total_Feedback_txt": "Totale feedback",
    "rep_dashboard_anony_Feedback": "Anonieme feedback",
    "rep_dashboard_Feeedback_Data_txt": "Feedback met gegevens",
    "rep_dashboard_review_txt": "Ontvang meer beoordelingen",
    "rep_dashboard_total_Review_txt": "Totale beoordeling",
    "rep_dashboard_total_positive_Review_txt": "Totaal positieve beoordeling",
    "rep_dashboard_negative_feedback_txt": "Negatieve feedback",
    "rep_dashboard_connection_txt": "Verbinding",
    "rep_dashboard_question_wise_analytics_txt": "Vraag Wijze Analyse",
    "rep_dashboard_date_label": "Selecteer datum",
    "rep_dashboard_custom_range_txt": "Aangepast bereik",
    "rep_tlt_min": "HERHALING",
    "emojis": "Emoji's",
    "media_text": "Media toevoegen",
    "custom_field_text": "Aangepaste velden invoegen",
    "shortlink_text": "Voeg een korte link toe",
    "use_template_text": "Gebruik sjabloon",
    "banner_must_have": "Moet hebben",
    "res_seamless": "Naadloos",
    "res_table_reservation": "Tafelreservering",
    "res_system": "Systeem",
    "res_text": "Reserveringen",
    "md_easily": "Gemakkelijk",
    "md_manage_customers": "Klanten beheren",
    "md_visits_interactions": "bezoeken & interacties",
    "md_desc": "Maak dineren makkelijker voor uw klanten met realtime tafelreserveringen. Beheer moeiteloos boekingen, verkort wachttijden en zorg voor een soepele ervaring voor zowel personeel als gasten. Houd uw restaurant volgeboekt terwijl u eersteklas service en tevredenheid behoudt.",
    "engage_convert": "Betrek en converteer SMS",
    "coupon_campaign": "en Couponcampagne",
    "sms_read_rate": "SMS-berichten worden in 98% van de gevallen gelezen",
    "real_time_promo": "Lever promoties in realtime voor onmiddellijke actie",
    "affordable_reach": "Betaalbare manier om klanten te bereiken met een solide ROI",
    "stay_in_touch": "Blijf te allen tijde in contact met uw klant",
    "bc_engage_customers": "Betrek klanten en converteer sms-berichten",
    "bc_coupon_campaign": "en Couponcampagne",
    "bc_sms_read_rate": "SMS-berichten worden in 98% van de gevallen gelezen",
    "bc_real_time_promo": "Lever promoties in realtime voor onmiddellijke actie",
    "bc_affordable_reach": "Betaalbare manier om klanten te bereiken met een solide ROI",
    "bc_stay_in_touch": "Blijf te allen tijde in contact met uw klant",
    "ar_automate_engagement": "Automatiseer uw klantbetrokkenheid",
    "ar_with": "met",
    "ar_with_auto_responders": "Automatische beantwoorders",
    "ar_personalized_messages": "Stuur automatisch gepersonaliseerde berichten voor verjaardagen, verwelkom nieuwe klanten en maak opnieuw contact met eerdere klanten. Bespaar tijd en zorg dat elke interactie zinvol en actueel blijft.",
    "sq_custom_forms_tools": "Aangepaste formulieren en hulpmiddelen - Vereenvoudigen",
    "sq_custom_forms_ci": "Interacties met klanten",
    "sq_smart_qr_desc": "Maak aangepaste formulieren, verzamel e-handtekeningen en maak gebruik van slimme QR-codes om uw bedrijfsprocessen te stroomlijnen. Pas elke tool aan om aan de behoeften van uw klanten te voldoen en de gebruikerservaring te verbeteren.",
    "gnc_get_new_cus": "Nieuwe klanten krijgen",
    "gnc_get_customers": "van Social Media — Snel en Gemakkelijk!",
    "gnc_social_media_power": "Gebruik de kracht van sociale platforms om nieuwe klanten aan te trekken en verkoop rechtstreeks naar uw bedrijf te sturen. Stuur bestellingen van derden rechtstreeks door naar uw winkel of online bestelplatform voor maximale controle en inkomsten.",
    "wifi_connect_guests": "Verbind gasten met",
    "wifi_smart": "Slimme wifi",
    "wifi_connect_grow": "& Vergroot uw inzichten",
    "wifi_smart_wifi_desc": "Bied uw klanten een probleemloze wifi-verbinding via een gepersonaliseerd startscherm en verzamel waardevolle klantgegevens om uw marketingactiviteiten en betrokkenheid te verbeteren.",
    "wifi_create_splash_page": "Maak een gast-wifi-splashpagina",
    "instant_menu_create": "Maak je eigen",
    "instant_menu_text": "Direct menu",
    "instant_menu_wm": "binnen enkele minuten",
    "instant_menu_desc": "Verbetert de klantervaring door realtime-updates, eenvoudige aanpassing en verbeterde betrokkenheid. Zo kunt u uw aanbod makkelijker presenteren en uw content actueel houden.",
    "instant_menu_create_digital_menu": "Maak een digitaal menu",
    "loyalty_program_rewards": "Verdien beloningen",
    "loyalty_program_on_visits": "bij elk bezoek",
    "loyalty_program_create": "Maak een loyaliteitsprogramma",
    "reputation_create": "Creëer reputatie",
    "reputation_boost": "Versterk uw",
    "reputation_text": "Reputatie",
    "reputation_reviews": "met meer beoordelingen!",
    "reputation_desc": "Verzamel waardevolle feedback van uw klanten om uw service en reputatie te verbeteren. Deel uw ervaring en help ons groeien: uw beoordeling is belangrijk!",
    "dashboard": "Dashboard",
    "blast_campaign": "Blast-campagne",
    "blast_campaigns": "Blast-campagnes",
    "blast_campaign_sub_title": "Lanceer explosieve campagnes om al uw klanten in een paar klikken te bereiken",
    "auto_responders": "Automatische beantwoorders",
    "auto_responder": "Automatische beantwoorder",
    "auto_responder_sub_title": "Gemakkelijk te gebruiken, op evenementen gebaseerde promoties voor uw klanten",
    "smart_qr_form": "Slimme QR \/ Formulier",
    "get_new_customer": "Nieuwe klant krijgen",
    "marketing": "Marketing",
    "clients": "Klanten",
    "group_data": "Groepsgegevens",
    "clients_data": "Klantengegevens",
    "account": "Rekening",
    "user_acc_info": "Gebruikersaccountinformatie",
    "current_plan": "Huidig plan",
    "payments": "Betalingen",
    "location": "Locatie",
    "location_list": "Locatielijst",
    "users": "Gebruikers",
    "users_sub_title": "Lijst met alle gebruikers en hun gegevens",
    "online_profile": "Online profiel",
    "change_password": "Wachtwoord wijzigen",
    "menu": "Menu",
    "reseller_dashboard": "Reseller-dashboard",
    "companies": "Bedrijven",
    "plans_bought": "Plannen gekocht",
    "agreement_t_c": "Overeenkomst Algemene Voorwaarden",
    "wifi_dashboard": "Wifi-dashboard",
    "hot_spot": "Hotspot",
    "splash_pages": "Splash-pagina's",
    "get_more_reviews": "Ontvang meer beoordelingen",
    "office_supplies": "Kantoorartikelen",
    "reservation": "Reservering",
    "floor_table": "Vloer & Tafel",
    "guest_smart_wifi": "Gast Smart WiFi",
    "digital_signage": "Digitale bewegwijzering",
    "schedule_down": "Schema omlaag",
    "business_settings": "Bedrijfsinstellingen",
    "sub_user_login": "Subgebruiker Login",
    "help_center": "Helpcentrum",
    "switch_to_hub": "Overschakelen naar Hub",
    "meeting_records": "Vergaderverslagen",
    "logout": "Uitloggen",
    "no_access_text": "U heeft momenteel geen toegang. Neem contact op met uw beheerder.",
    "menu_item": "MENU-ITEM",
    "menu_capital": "MENU",
    "good_day": "Goededag",
    "no_permission_text": "U hebt geen toestemming om deze actie uit te voeren!!",
    "enter_valid_pin": "Voer een geldige pincode in",
    "add_reservation": "Reservering toevoegen",
    "edit_reservation": "Reservering bewerken",
    "per_req": "Aantal personen is vereist",
    "req_block_time": "Bloktijd is vereist",
    "pending_status": "In behandeling",
    "booked_status": "Geboekt",
    "completed_status": "Voltooid",
    "cancelled_status": "Geannuleerd",
    "reservation_slot": "Reserveringsslot",
    "block_time": "Blokkeertijd",
    "slot_time": "Reserveringstijd:  ",
    "template_name_req": "Sjabloonnaam is vereist",
    "template_type_req": "Sjabloontype is vereist",
    "sms_template_req": "SMS-sjabloon is vereist",
    "group_req": "Groep is vereist",
    "valid_date_time": "Voer een geldige datum en tijd in",
    "valid_group_name": "Voer een geldige groepsnaam in",
    "reservation_mark_completed": "Deze reservering wordt als voltooid gemarkeerd.",
    "customer_name": "Klantnaam",
    "date_time": "Datum Tijd",
    "table_no": "Tabel nr.",
    "floor": "Vloer",
    "customer_name_placeholder": "klantnaam",
    "date_time_placeholder": "datum_tijd",
    "table_no_placeholder": "tafel_nr",
    "floor_placeholder": "vloer",
    "select_floor": "Selecteer vloer",
    "select_table": "Selecteer tabel",
    "customer_name_macro": "%% klantnaam %%",
    "date_time_macro": "%% datum_tijd %%",
    "table_no_macro": "%% tabel_nr %%",
    "floor_macro": "%% vloer %%",
    "template_name": "Sjabloonnaam",
    "login_with_4_digit_pin": "Inloggen met uw 4-cijferige pincode",
    "login_now_to_access_latest_insights": "Meld u nu aan om toegang te krijgen tot de nieuwste insignts",
    "mkt_BS_Title": "SMS-explosie",
    "step_1": "Bericht aanpassen",
    "step_2": "Kies publiek",
    "step_3": "Bericht plannen",
    "step_4": "Stuur een bericht",
    "step_5": "Bericht beheren",
    "bs_tab_title": "Samenvatting van de Blast-campagne",
    "default_message": "Hey %% firstname %%, Speciale korting op brunch! Dineer vandaag bij ons en ontvang exclusieve 10% korting op uw rekening!",
    "bs_Title": "Kies een campagnetype",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Bereik klanten via tekst- en multimediaberichten.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Maak contact met uw klanten via WhatsApp 💬",
    "send_discount_offers": "Stuur kortingsaanbiedingen via SMS 📲",
    "send_coupons_via_sms": "Stuur kortingsbonnen via sms naar uw klanten 🎟️",
    "env_Cap": "Bied exclusieve kortingen en promoties aan om uw klanten te verleiden",
    "cmt_Caption": "Bereik klanten via tekst- en multimediaberichten",
    "wp_caption": "Maak contact met klanten via WhatsApp voor een naadloze en directe communicatie",
    "msg_Prev_Title": "Berichtvoorbeeld",
    "cou_Prev_Title": "Couponvoorbeeld",
    "blast_SMS": "SMS\/MMS-campagne",
    "bs_P_Msg": "In de wachtrij",
    "bs_S_Msg_C": "Mislukt",
    "bs_F_Msg_Count": "Afgeleverd",
    "previous": "Vorig",
    "next_ST": "Volgende",
    "next_S": "Volgende stap",
    "req_Temp_Name_Title": "Sjabloonnaam is vereist",
    "req_Template_Tx_Title": "Sjabloontekst is vereist",
    "add_Temp_Title": "Sjabloon toevoegen",
    "link_Desc": "Voeg een link naar keuze toe, zodat klanten via Facebook, Google of uw eigen website een boeking bij u kunnen maken.",
    "audience_Title": "Publiek",
    "client_G1_Title": "Nieuwe klanten",
    "client_G2_Title": "Recente klanten",
    "client_G3_Title": "Trouwe klanten",
    "client_G4_Title": "Vervallen klanten",
    "client_G5_Title": "Klanten op verbindingsdatum",
    "on_Which_Day": "Op welke dag",
    "on_Day": "Op dag",
    "immediate_Desc": "Uw bericht wordt onmiddellijk verzonden. Klik op Volgende om te beginnen.",
    "never_Desc": "Deze campagne blijft voor altijd doorlopen, totdat u deze handmatig beëindigt.",
    "end_Desc": "De campagne eindigt op de datum die u hier kiest.",
    "success_Message": "Campagne succesvol opgeslagen!",
    "send_Success_Message": "Campagne succesvol verzonden!",
    "msg_Name_Field_Title": "Campagnetitel",
    "valid_campaign_title": "Voer een geldige campagnetitel in",
    "msg_Type_Title": "Berichttype",
    "sms_Desc": "1 tegoed per sms",
    "mms_Desc": "3 studiepunten per MMS",
    "client_GTO_1": "Dag",
    "client_GTO_2": "Uren",
    "minutes": "Notulen",
    "client_GTO_4": "Week",
    "time_Option_1": "Dag",
    "time_Option_2": "Uren",
    "c_Grp_Day_1": "90 dagen",
    "c_Grp_Day_2": "60 dagen",
    "c_Grp_Day_3": "30 dagen",
    "first": "Eerst",
    "second_T": "Seconde",
    "third_T": "Derde",
    "fourth_T": "Vierde",
    "fifth_T": "Vijfde",
    "delete_BS_T": "Deze campagne wordt permanent verwijderd.",
    "delete_MBS_T": "Deze campagnes worden permanent verwijderd.",
    "cFT_1": "Telefoon",
    "cFT_2": "Contacteer voornaam",
    "cFT_3": "Contactpersoon Achternaam",
    "cFT_4": "Contact-e-mailadres",
    "cFT_5": "Opmerking",
    "cFT_6": "Verjaardag",
    "cFT_7": "Bedrijfsnaam",
    "cF_Meta_Tag_1": "%% telefoon %%",
    "cF_Meta_Tag_2": "%% voornaam %%",
    "cF_Meta_Tag_3": "%% achternaam %%",
    "cF_Meta_Tag_4": "%% e-mail %%",
    "cF_Meta_Tag_5": "%% opmerking %%",
    "cF_Meta_Tag_6": "%% verjaardag %%",
    "cF_Meta_Tag_7": "%% bedrijfsnaam %%",
    "cF_Name_1": "telefoon",
    "cF_Name_2": "voornaam",
    "cF_Name_3": "achternaam",
    "cF_Name_4": "e-mail",
    "cF_Name_5": "opmerking",
    "cF_Name_6": "verjaardag",
    "cF_Name_7": "bedrijfsnaam",
    "all_clients": "AlleKlanten",
    "aud_Type_2": "Clientgroepen",
    "aud_Type_3": "Klantengroepen",
    "aud_Type_T1": "Alle klanten",
    "aud_Type_T2": "Klantengroepen",
    "aud_Type_T3": "Klantengroepen",
    "new_clients": "Nieuwe klanten",
    "client_G2": "Recente klanten",
    "client_G3": "Trouwe klanten",
    "client_G4": "Vervallen klanten",
    "client_G5": "Klanten op verbindingsdatum",
    "never_text": "Nooit",
    "ending_on": "Eindigend op",
    "send_T2": "Gepland",
    "send_Type_3": "Terugkerend",
    "daily": "Dagelijks",
    "weekly_T": "Wekelijks",
    "monthly_T": "Maandelijks",
    "yearly_T": "Jaarlijks",
    "each": "Elk",
    "on_the": "Op de",
    "monday": "Maandag",
    "exclusive_offer": "Exclusief aanbod voor jou",
    "redeem_now": "Nu inwisselen",
    "redeem_with_cashier": "Graag inwisselen bij de kassa",
    "lg_Day_2": "Dinsdag",
    "lg_Day_3": "Woensdag",
    "lg_Day_4": "Donderdag",
    "lg_Day_5": "Vrijdag",
    "lg_Day_6": "Zaterdag",
    "lg_Day_7": "Zondag",
    "msg_Pre_T": "Domino's",
    "cA_Edit_T1": "Alle",
    "cA_Edit_T2": "Inbegrepen",
    "cA_Edit_T3": "Uitgesloten",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "Amerikaanse dollar",
    "cad_T": "CAD",
    "msg": "Bericht",
    "which_Day": "Welke dagen",
    "end_Date_Tx": "Einddatum",
    "sDate_Err": "Startdatum moet groter zijn dan of gelijk zijn aan vandaag",
    "eDate_Err": "Einddatum moet later zijn dan startdatum",
    "start_Date_Req": "Startdatum is vereist",
    "end_Date_Req": "Einddatum is vereist",
    "time_req": "Er is tijd nodig",
    "client_GT1": "Klanten die zich de afgelopen",
    "client_GT2": "Klanten met verbinding in de laatste",
    "client_GT3": "Cliënten met minimaal",
    "client_GT4": "met de laatste",
    "client_GT5": "die de laatste keer niet terugkwam",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Test",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Media of URL is vereist",
    "time_Title": "Tijd",
    "start_date": "Startdatum",
    "end_date": "Einddatum",
    "auto_Send_T": "Automatisch naar klanten verzenden",
    "add_Media": "Media toevoegen",
    "in_Title": "In",
    "c_Temp_T": "Campagne sjabloon",
    "temp_NT": "Sjabloonnaam",
    "type_T": "Type",
    "select_template_type": "Selecteer sjabloontype",
    "sel_Temp_T": "Selecteer sjabloontype",
    "sms_temp_t": "SMS-sjabloon",
    "temp_T": "Sjabloontekst",
    "default_Msg": "Hey %% firstname %%, Speciale korting op brunch! Dineer vandaag bij ons en ontvang een exclusieve korting van 10% op uw rekening!",
    "refill_T": "Bijvullen",
    "avl_Credit": "Beschikbare credits",
    "req_Credit": "Totaal aantal vereiste studiepunten",
    "rem_Credit": "Resterend krediet",
    "refill_Credit": "Tegoeden die moeten worden aangevuld",
    "clients_Req": "Selecteer minimaal één klant",
    "subclient_Req": "Geselecteerde klanten zijn geen abonnees.",
    "refill_Desc": "Vul de vereiste credits in",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Startdatum is ongeldig",
    "ed_Invalid": "Einddatum is ongeldig",
    "img_Url_Invalid": "Ongeldige afbeelding-URL",
    "time_Invalid": "Tijd is ongeldig",
    "time_And_Date_Invalid": "Voer een geldige datum en tijd in",
    "time_Invalid_Bfr": "Selecteer een tijd die minimaal 5 minuten later is dan de huidige tijd",
    "sod_Req": "Verzenden op dag is vereist",
    "add_Card": "Voeg een nieuwe kaart toe",
    "sm_D_Rep_Title": "SMS\/MMS-bezorgingsrapport",
    "send_Now_T": "Nu verzenden",
    "schd_Now_T": "SMS plannen",
    "test_SMS_T": "Test-SMS verzenden",
    "save_AD_T": "Opslaan als concept",
    "back_TE_T": "Terug naar Bewerken",
    "reset_tex": "Opnieuw instellen",
    "update_tex": "Update",
    "dum_msg1": "Om de klantnaam weer te geven, gebruikt u %% customer_name %% in de sjabloon",
    "dum_msg2": "Om de reserveringstijd weer te geven, gebruikt u %% date_time %% in de sjabloon",
    "dum_msg3": "Om het tabelnummer weer te geven, gebruikt u %% table_no %% in de sjabloon",
    "embedded_link": "Ingesloten link",
    "img_title": "Afbeelding",
    "img_input_text": "Tekst:",
    "img_join_text": " om lid te worden van onze lijst",
    "img_copy_text": "Kopiëren",
    "img_not_found": "Gegenereerde afbeelding niet gevonden!!",
    "or_text": "OF",
    "web_signup_image_content": "Door dit formulier in te dienen en u via sms aan te melden, stemt u ermee in marketing-sms-berichten te ontvangen (zoals promotiecodes en herinneringen). Er kunnen kosten voor berichten en data van toepassing zijn. De frequentie van berichten varieert. U kunt zich op elk moment afmelden door te antwoorden met STOP",
    "img_download_success": "Afbeelding succesvol gedownload",
    "embedded_link_copied": "Ingesloten link succesvol gekopieerd",
    "media_url_required": "Media of url is vereist",
    "invalid_image_url": "Ongeldige afbeelding-url",
    "invalid_file": "Bestand is ongeldig",
    "image_error_1mb": "Voeg een afbeelding in die kleiner is dan 1 MB",
    "image_error_2_5mb": "Voeg een afbeelding in die kleiner is dan 2,5 MB",
    "image_error_3mb": "Voeg een afbeelding in die kleiner is dan 3 MB",
    "change_title": "Wijziging",
    "drop_file_text": "Sleep het bestand hierheen of klik om te uploaden",
    "apply_text": "Toepassen",
    "search_here": "Zoek hier",
    "update_status": "Status bijwerken",
    "reservation_deleted_permanently": "Deze reservering wordt definitief verwijderd.",
    "table_text": "Tabellen",
    "add_table_button": "Nieuwe tabel toevoegen",
    "add_table_text": "Tabel toevoegen",
    "edit_table_text": "Tabel bewerken",
    "table_delete_text": "Deze tabel wordt permanent verwijderd.",
    "multiple_table_delete_text": "Deze tabellen worden permanent verwijderd.",
    "table_error_name": "Voer de tabelnaam in",
    "table_error_invalid_name": "Voer een geldige tabelnaam in",
    "table_error_floor": "Selecteer de verdieping",
    "table_error_select": "Selecteer de tabel",
    "table_capacity_text": "Tafelcapaciteit",
    "capacity_text": "Capaciteit",
    "table_occasion_text": "Vind uw tafel voor elke gelegenheid",
    "table_name_text": "Tabelnaam",
    "table_capacity_error": "Tafelcapaciteit is vereist",
    "cancel_text": "Annuleren",
    "submit_text": "Indienen",
    "select_valid_date": "Selecteer een geldige datum",
    "select_valid_time": "Selecteer geldige tijd",
    "valid_contact_number": "Voer een geldig contactnummer in",
    "date_req": "Datum is vereist",
    "date_error_later_than_today": "Datum moet later zijn dan of gelijk zijn aan de datum van vandaag",
    "time_error_later_than_5_minutes": "Selecteer een tijd die minimaal 5 minuten later is dan de huidige tijd",
    "number_person_req": "Aantal personen is vereist",
    "contact_no_req": "Contactnummer is vereist",
    "contact_no": "Contactnummer",
    "select_time": "Selecteer tijd",
    "for_how_many_person": "Voor hoeveel personen?",
    "f_name": "Voornaam",
    "l_name": "Achternaam",
    "business_name": "Bedrijfsnaam",
    "business_no": "Bedrijfsnummer",
    "no_for_sign_in": "Dit nummer wordt gebruikt voor aanmelding",
    "business_email": "Zakelijke e-mail",
    "notes_tex": "Notities",
    "floor_deleted_permanently": "Deze verdieping wordt permanent verwijderd.",
    "add_floor": "Voeg vloer toe",
    "edit_floor": "Bewerken Vloer",
    "name_text": "Naam",
    "mobile_no": "Mobiel nr.",
    "person_text": "Persoon",
    "date_and_time": "Datum en tijd",
    "actions_text": "Acties",
    "extra_text": "Extra",
    "floor_name_req": "Verdiepingsnaam is vereist",
    "floor_name": "Naam verdieping",
    "status_text": "Staat",
    "category_status_req": "Categorie Status is vereist",
    "table_deleted_permanently": "Deze tabel wordt permanent verwijderd.",
    "tables_deleted_permanently": "Deze tabellen worden permanent verwijderd.",
    "back_to_home": "Terug naar Home",
    "link_copied_text": "Link gekopieerd naar klembord",
    "cust_dash_head_to_title": "naar",
    "cust_dash_head_location_title": "Locatie",
    "select_location_title": "Selecteer locatie",
    "all_locations_label": "alle_locaties",
    "rep_customer_feedback_analytics": "😊 Volg de feedback van uw klanten met realtime-analyses",
    "rep_customer_txt": "Klanten",
    "rep_delete_title": "Deze reputatie wordt permanent verwijderd.",
    "rep_qr_def_txt": "Scan de QR-code en deel uw waardevolle feedback met ons over uw ervaring.",
    "delete_title": "Verwijderen",
    "user_list_title": "Lijst met klanten",
    "os_nfc_txt": "NFC-kaarten",
    "os_sign_holder_txt": "Bordhouderstandaard",
    "os_store_Sign_holder_txt": "Winkelbordhouders",
    "StaT": "Statistieken",
    "AllgT": "Alle groepen",
    "FavT": "Favoriet",
    "MostActT": "Meest actief",
    "grT": "Groepsnaam",
    "keywT": "Trefwoorden",
    "exSubT": "Bericht voor bestaande abonnee",
    "ArchT": "Gearchiveerd",
    "gNotiSms": "Stuur mij een sms-bericht over elk nieuw contact",
    "gNotiEmail": "Stuur mij een e-mail met de melding van elk nieuw contact",
    "reqGName": "Groepsnaam is vereist",
    "validGN": "Voer een geldige groepsnaam in",
    "valid_phone_no": "Voer een geldig telefoonnummer in",
    "phone_no_req": "Telefoonnummer is verplicht",
    "required_message_text": "Bericht is vereist",
    "required_discount_text": "Korting is vereist",
    "less_than_discount": "Het kortingsbedrag moet lager zijn dan 5000",
    "discount_percentage_invalid": "Kortingspercentage moet kleiner of gelijk zijn aan 100",
    "discount_type_req": "Kortingstype is vereist",
    "discount_text_req": "Coupontekst is vereist",
    "reqContactNo": "Contactnummer is vereist",
    "reqMsgNT": "Campagnetitel is vereist",
    "reqLinkT": "Link is vereist",
    "delMGT": "Deze groepen worden permanent verwijderd.",
    "defMemMsg": "Typ hier uw bericht",
    "add_cust_to_grp_title": "Client toevoegen aan groep",
    "group_title": "Groepen",
    "group_create_title": "Groep aanmaken",
    "group_name_txt": "Groepsnaam",
    "group_table_keyword_title": "Trefwoord",
    "actions_title": "Acties",
    "clients_title": "Klanten",
    "send_title": "Versturen",
    "qr_title": "QR",
    "delete_group_txt": "Deze groep wordt definitief verwijderd.",
    "delete_groups_txt": "Deze groepen worden permanent verwijderd.",
    "delete_client_title": "Deze client wordt permanent verwijderd.",
    "delete_clients_title": "Deze klanten worden permanent verwijderd.",
    "delete_multiple_title": "Meervoudig verwijderen",
    "wel_sms_mms": "Welkomst-SMS\/MMS verzenden?",
    "key_words_title": "Trefwoorden",
    "srch_plch_txt": "Zoek hier",
    "req_location_id_title": "Locatie is vereist",
    "create_text": "Creëren",
    "view_text": "Weergave",
    "immediately": "Onmiddellijk",
    "business_name_req": "Bedrijfsnaam is vereist",
    "business_no_req": "Bedrijfsnummer is vereist",
    "valid_business_name": "Voer een geldige bedrijfsnaam in",
    "valid_business_no": "Voer een geldig bedrijfsnummer in",
    "address_req": "Adres is verplicht",
    "valid_address": "Selecteer een geldig adres",
    "time_zone_req": "Tijdzone is vereist",
    "image_req": "Voeg een afbeelding in die kleiner is dan 2,5 MB",
    "valid_file": "Bestand is ongeldig.",
    "logo": "Logo",
    "time_zone": "Tijdzone",
    "save": "Redden",
    "account_type_req": "Accounttype is vereist",
    "gst_no_req": "GST-nummer is vereist",
    "gst_no_valid": "Voer een geldig GST-nummer in",
    "set_up_payments": "Betalingen instellen",
    "billing_details": "Vul uw factuurgegevens in",
    "billing_details_desc": "Uw factuurgegevens worden weergegeven op uw maandelijkse factuur van menuonline.",
    "account_type": "Accounttype",
    "select_account_type": "Selecteer accounttype",
    "gst_no": "GST-nummer",
    "transaction_details": "Transactiegegevens",
    "payment_method": "Betaalmethode",
    "billing_period": "Facturatieperiode",
    "paid_by": "Betaald door",
    "download": "Download",
    "pay_now": "Betaal nu",
    "pull_down_refresh": "Trek omlaag om te vernieuwen",
    "release_refresh": "Vrijgeven om te vernieuwen",
    "billing_details_text": "Factureringsgegevens",
    "payment_methods": "Betaalmethoden",
    "invoice": "Factuur",
    "invoice_to": "Factuur aan:",
    "issue_date": "Uitgiftedatum",
    "due_date": "Deadline",
    "amount_due": "Te betalen bedrag",
    "charges": "Kosten",
    "sub_total_capitalize": "Subtotaal",
    "hst": "BTW-tarief",
    "grand_total": "Totaalbedrag",
    "invoice_generated_on": "Factuur gegenereerd op",
    "contact_us": "Neem contact met ons op",
    "invoice_issue": "Als u uw rekening heeft doorgenomen en nog vragen heeft",
    "call": "Telefoongesprek",
    "send_sms": "SMS versturen",
    "payment_success": "Betaling is succesvol",
    "edit_credit_card": "Creditcard bewerken",
    "add_credit_card": "Creditcard toevoegen",
    "modify_card_info": "Wijzig uw kaartgegevens",
    "enter_card_info": "Voer uw kaartgegevens in",
    "account_no_req": "Rekeningnummer is vereist",
    "card_name_req": "Naam op kaart is vereist",
    "expiry_date_req": "Vervaldatum is vereist",
    "valid_expiry_date": "Voer een geldige vervaldatum in",
    "valid_cvv": "Voer een geldig cvv-nummer in",
    "cvv_req": "CVV is vereist",
    "card_no": "Kaartnummer",
    "name_of_card": "Naam op kaart",
    "expiry": "Vervaldatum",
    "mm_text": "MM\/JJ",
    "cvv": "CVV",
    "set_as_default": "Instellen als standaard",
    "add_new_card": "Nieuwe kaart toevoegen",
    "all_credit_card": "Al uw creditcards",
    "fastest_checkout": "Snelste afrekening met een veilig en betrouwbaar platform, vertrouwd door een grote groep bedrijven",
    "coupon_button_req": "Tekst op couponknop is vereist",
    "max19_characters_allowed": "Maximaal 19 tekens zijn toegestaan",
    "fine_print_text_req": "Kleine lettertjes zijn vereist",
    "clients_req": "Selecteer minimaal één klant",
    "client_groups": "Clientgroepen",
    "day_text": "Dag",
    "valid_days": "Dagwaarde is te groot, voer geldige dagen in",
    "hours_text": "Uren",
    "enter_valid_hours": "De urenwaarde is te groot, voer geldige uren in",
    "enter_valid_min": "De waarde voor minuten is te groot. Voer geldige minuten in.",
    "clients_with_connection_required": "Clients met verbinding in de laatste is vereist",
    "connection_required": "Verbinding is vereist",
    "connection_value_too_large": "Verbindingswaarde is te groot, voer een geldige verbinding in",
    "minutes_required": "Vereiste minuten",
    "clients_with_at_least_connection_required": "Clients met minimaal een verbinding zijn vereist",
    "last_minutes_req": "Met de laatste minuten is vereist",
    "clients_not_returned_required": "Wie in de laatste minuten niet terugkwam, is vereist",
    "not_return_in_last_minutes_invalid": "Niet terug in laatste minuten moet minder zijn dan de laatste minuten.",
    "customerGroups": "Klantengroepen",
    "select_customer_group": "Selecteer de klantgroep",
    "location_required": "Locatie is vereist",
    "start_date_required": "Startdatum is vereist",
    "start_date_invalid": "Startdatum is ongeldig",
    "start_date_invalid_error": "De startdatum moet groter zijn dan of gelijk zijn aan vandaag",
    "recurring": "Terugkerend",
    "end_date_required": "Einddatum is vereist",
    "end_date_invalid": "Einddatum is ongeldig",
    "end_date_invalid_error": "Einddatum moet later zijn dan startdatum",
    "time_invalid": "Tijd is ongeldig",
    "monthly_text": "Maandelijks",
    "send_day_req": "Verzenden op dag is vereist",
    "loyal_clients": "Trouwe klanten",
    "recent_clients": "Recente klanten",
    "lapsed_clients": "Vervallen klanten",
    "clients_connection_date": "Klanten op verbindingsdatum",
    "scheduled_text": "Gepland",
    "weekly": "Wekelijks",
    "selected_clients_not_subscriber": "Geselecteerde klanten zijn geen abonnee.",
    "message_preview_title": "Berichtvoorbeeld",
    "coupon_preview_title": "Couponvoorbeeld",
    "form_text": "Formulier",
    "preview_text": "Voorvertoning",
    "next_text": "Volgende",
    "send_test_SMS": "Test-SMS verzenden",
    "save_draft": "Opslaan als concept",
    "back_to_edit": "Terug naar Bewerken",
    "select_payment_method": "Selecteer de betaalmethode",
    "schedule_SMS": "SMS plannen",
    "send_now": "Nu verzenden",
    "get_more_ratings": "Krijg meer beoordelingen",
    "overview": "Overzicht",
    "reset_campaign": "Campagne resetten",
    "permissions": "Machtigingen",
    "location_name": "Locatienaam",
    "phone_no": "Telefoonnummer",
    "location_email_address": "Locatie E-mailadres",
    "located_business": "Waar is uw bedrijf gevestigd?",
    "business_logo": "Bedrijfslogo",
    "congratulations": "Gefeliciteerd",
    "almost_done": "Je bent bijna klaar",
    "publish": "Publiceren",
    "about_your_business": "Over uw bedrijf",
    "add_your_location": "Voeg uw locatie toe",
    "publish_location": "Publicatielocatie",
    "location_name_req": "Locatienaam is vereist",
    "valid_location_name": "Voer een geldige locatienaam in",
    "business_logo_req": "Bedrijfslogo is vereist",
    "please_accept_terms": "Accepteer de algemene voorwaarden",
    "add_new_location": "Nieuwe locatie toevoegen",
    "edit_location": "Locatie bewerken",
    "add_location": "Locatie toevoegen",
    "existing_msg_subscriber_txt": "Bericht voor bestaande abonnee",
    "msg_capitalize_txt": "Bericht",
    "group_noti_sms": "Stuur mij een sms-bericht over elk nieuw contact",
    "group_noti_email": "Stuur mij een e-mail met de melding van elk nieuw contact",
    "group_member_msg": "Het lijkt erop dat je al bij onze groep hoort!",
    "group_mend_msg": "STOP om te eindigen. HELP voor hulp. SMS- en datatarieven kunnen van toepassing zijn",
    "Disclaimer_title": "Disclaimertekst: ",
    "group_def_msg": "We zijn nu online! Bekijk ons servicemenu en boek vandaag nog uw volgende afspraak.",
    "required_msg_txt": "Bericht is vereist",
    "required_Key_txt": "Trefwoorden zijn vereist",
    "required_mem_msg": "Abonnementsbericht is vereist",
    "client_list_title": "Klantenlijst",
    "add_contact_txt": "Contactpersoon toevoegen",
    "delete_all_clients_txt": "Verwijder alle klanten",
    "delete_all_clients_msg": "Weet u zeker dat u alle clients wilt verwijderen? Ze kunnen niet worden teruggehaald.",
    "delete_all_txt": "Alles verwijderen",
    "timeline_title": "Tijdlijn",
    "unsubscribe_title": "Uitschrijven",
    "subscribe_title": "Abonneren",
    "unsubscribe_confirm_msg": "Wilt u deze klant markeren als uitschrijver?",
    "subscribe_confirm_msg": "Wilt u deze klant markeren als abonnee?",
    "no_title": "Nee",
    "yes_title": "Ja",
    "client_name_title": "Klantnaam",
    "source_title": "Bron",
    "contact_file_Req": "Contactbestand is vereist",
    "req_title": "Vereist",
    "opt_in_req": "Geïmporteerde contacten moeten 100% opt-in zijn",
    "image_invalid_error": "Bestand is ongeldig.",
    "import_contacts_msg": "Contacten importeren",
    "csv_upload_title": "CSV-contactbestand om te uploaden *",
    "csv_file_desc": "Upload alleen een csv-bestand - kolommen in het csv-bestand - Voornaam, Achternaam, E-mailadres, Mobiel nummer, Geslacht, Geboortedatum (MM\/DD\/JJJJ) - geen spaties, streepjes of haakjes in Mobiel nummer",
    "to_download_title": "Klik hier om het demobestand te downloaden",
    "contains_header_title": "Ja, dit bestand bevat headers",
    "opt_in_100_txt": "Ja, deze geïmporteerde contacten zijn 100% opt-in",
    "DisT": "Disclaimertekst: ",
    "gMendMsg": "STOP om te eindigen. HELP voor hulp. SMS- en datatarieven kunnen van toepassing zijn",
    "reqKeyT": "Trefwoorden zijn vereist",
    "reqMemMsg": "Abonnementsbericht is vereist",
    "reqMsgT": "Bericht is vereist",
    "gMemberMsg": "Het lijkt erop dat je al bij onze groep hoort!",
    "gdefMsg": "We zijn nu online! Bekijk ons servicemenu en boek vandaag nog uw volgende afspraak.",
    "next_title": "Volgende",
    "male_title": "Mannelijk",
    "male_val": "mannelijk",
    "female_title": "Vrouwelijk",
    "female_val": "vrouwelijk",
    "others_txt": "Anderen",
    "others_val": "anderen",
    "validBirthdate": "Voer een geldige geboortedatum in",
    "valid_phone_no_title": "Voer een geldig telefoonnummer in",
    "required_phone_no_title": "Telefoonnummer is verplicht",
    "add_new_client_txt": "Voeg een nieuwe klant toe",
    "update_client_txt": "Client bijwerken",
    "phone_num_text": "Telefoonnummer",
    "dob_title": "Geboortedatum",
    "select_gender_title": "Geslacht selecteren",
    "timelineTitle": "Tijdlijn",
    "confirm_location": "Bevestig locatie",
    "feedback_title": "Feedback",
    "rep_question_1": "Was het bedrijf schoon?",
    "rep_que_2": "Was het eten lekker?",
    "rep_que_3": "Was onze service vriendelijk?",
    "rep_que_4": "Was onze service snel?",
    "business_location_req_title": "Bedrijfslocatie is vereist",
    "valid_location_err_txt": "Selecteer een geldig adres",
    "rep_management_title": "Krijg meer beoordelingen Beheer",
    "rep_about_desc": "Reputatie helpt u de Google-beoordeling te verhogen door uw klanten om feedback te vragen",
    "preview_title": "Voorvertoning",
    "rep_preview_txt": "Reputatie voorbeeld",
    "back_title": "Rug",
    "fine_print_txt": "Graag inwisselen bij de kassa",
    "redeem_me_title": "Verlos mij",
    "rep_nxt_visit_txt": "Bij uw volgende bezoek",
    "device_type": "Type gebruikt apparaat",
    "connection_method": "Verbindingsmethode",
    "peak_days": "Piekdagen",
    "peak_hours": "Spitsuren",
    "guest_by_day": "Gast overdag",
    "guest_visit": "Gastbezoek",
    "connection": "Verbinding",
    "connection_duration": "Verbindingsduur",
    "guest_visit_1": "1 keer",
    "guest_visit_2": "2 keer",
    "guest_visit_3_5": "3-5 keer",
    "guest_visit_6_10": "6-10 keer",
    "guest_visit_11_25": "11-25 keer",
    "guest_visit_26_100": "26-100 keer",
    "guest_visit_100_plus": "100+ keer",
    "device_android_total": "Totaal Android",
    "device_android": "Androïde",
    "device_desktop": "Bureaublad",
    "device_ios": "iOS",
    "device_ios_total": "Totaal Ios",
    "device_desktop_total": "Totaal bureaublad",
    "connection_duration_10": "<=10 minuten",
    "connection_duration_20": "11-20 minuten",
    "connection_duration_30": "21-30 minuten",
    "connection_duration_40": "31-45 minuten",
    "connection_duration_60": "46-60 minuten",
    "connection_method_email": "E-mail",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Alle gasten",
    "new_Guest_txt": "Nieuwe gast",
    "connections_txt": "Verbindingen",
    "hotspot": "Hotspot",
    "hotspot_list": "Hotspotlijst",
    "add_new_hotspot": "Nieuwe hotspot toevoegen",
    "hotspot_information": "Hotspot-informatie",
    "edit_details_button": "Bewerk details",
    "wifi_info_message": "Maak verbinding en geniet van gratis WiFi",
    "connection_message": "Geweldig, je bent verbonden met ",
    "connection_message_suffix": " Wi-Fi",
    "wifi": "Wi-Fi",
    "login_to_access": "Meld u aan om toegang te krijgen",
    "verification_code": "Verificatiecode",
    "verification_code_message": "Typ de verificatiecode die naar u is verzonden ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Hoe heet je trouwens?",
    "your_birthdate": "Kunt u ons vertellen wat uw geboortedatum is?",
    "request_guest_wifi_name": "Voer de gast-wifi-naam in",
    "request_device_key": "Voer de apparaatsleutel in",
    "request_maximum_internet_access_length": "Selecteer de maximale lengte van uw internettoegang",
    "mac_address": "MAC-adres",
    "device_port": "Apparaatpoort",
    "hardware": "Hardware",
    "current_uptime": "Huidige uptime",
    "nearby_devices": "Apparaten in de buurt",
    "firmware": "Bedrijfsprogramma",
    "who_are_you": "Wie ben je?",
    "where_to_contact_you": "Waar kunnen wij contact met u opnemen?",
    "your_area_code": "Wat is uw netnummer?",
    "connected": "Aangesloten",
    "delete_hotspot_message": "Deze hotspot wordt permanent verwijderd.",
    "delete_multiple_hotspots_message": "Deze hotspots worden permanent verwijderd.",
    "speed_error": "Snelheid moet minimaal 0,01 zijn",
    "speed_max_error": "Voer een waarde tot 1024 in voor onbeperkte snelheid, of selecteer een lagere waarde binnen het ondersteunde bereik",
    "device_ssid": "Apparaat-SSID",
    "device_ssid_two": "Apparaat-SSID Twee",
    "device_ssid_two_wpa": "Apparaat SSID Twee WPA",
    "device_key": "Apparaatsleutel",
    "select_location": "Selecteer locatie",
    "select_maximum_internet_access_length": "Selecteer maximale internettoegangsduur",
    "download_speed": "Downloadsnelheid",
    "upload_speed": "Uploadsnelheid",
    "network_length_1": "15 minuten",
    "network_length_2": "30 minuten",
    "network_length_3": "45 minuten",
    "network_length_4": "1 uur",
    "network_length_5": "2 uur",
    "network_length_6": "4 uur",
    "network_length_7": "6 uur",
    "network_length_8": "8 uur",
    "network_length_9": "10 uur",
    "network_length_10": "12 uur",
    "employee_wifi_name": "Wifi-naam van werknemer",
    "employee_wifi_password": "Wifi-wachtwoord voor werknemers",
    "guest_wifi_name": "Gast Wifi Naam",
    "menu_other_products_txt": "Overige producten | Menu Online",
    "menu_home_text": "Home | Menu Online",
    "whatsapp_title": "WhatsApp",
    "select_campaign_type": "Kies een campagnetype",
    "select_readymade_templates": "Kies uit kant-en-klare sjablonen of maak uw eigen",
    "campaign_title_required": "Campagnetitel is vereist",
    "type_here": "Typ hier...",
    "location_permission_req": "Locatietoestemming is vereist",
    "platform_permission_req": "Platformtoestemming is vereist",
    "profile_picture": "Profielfoto",
    "click_to_upload": "Klik om te uploaden",
    "location_permission": "Locatietoestemming",
    "pin": "Pin",
    "platform_permission": "Platformtoestemming",
    "set_pin": "Pincode instellen",
    "list_of_users": "Lijst met gebruikers",
    "create_user": "Gebruiker aanmaken",
    "terms_and_condition_req": "Algemene voorwaarden zijn vereist",
    "terms_and_conditions": "Algemene voorwaarden",
    "recommended": "AANBEVOLEN",
    "mo": "\/maand",
    "unsubscribe": "Uitschrijven",
    "cancel_subscription": "Weet u zeker dat u uw abonnement wilt opzeggen?",
    "upgrade": "Upgrade",
    "plan_upgraded_successfully": "Het plan is succesvol geüpgraded!",
    "menu_online": "Menuonline",
    "support": "Steun",
    "wifiC": "WIFI",
    "billing": "Facturering",
    "please_upgrade": "Upgrade om van alle functies te kunnen genieten",
    "you_have_subscribed": "U heeft zich geabonneerd op de ",
    "plan": "plan",
    "of_text": "van",
    "days": "Dagen",
    "remaining_days_until_plan": " Dagen resterend totdat uw plan een update nodig heeft",
    "manage_your_screens": "Beheer uw schermen",
    "screens_you_save": "Schermen die je hebt",
    "add_more_screens": "Voeg meer schermen toe",
    "addition_screen": "Toevoegingsscherm",
    "per_screen": "$ per scherm",
    "per_box": "$ per doos",
    "shipping_changes": "Verzendkosten",
    "upto4_boxes": "tot 4 dozen ",
    "charge_now": "Je krijgt nu de rekening",
    "updated_reoccuring": "Uw bijgewerkte terugkerende zal zijn",
    "current_reoccuring": "Huidig terugkerend: ",
    "no_text": "Nee",
    "android_box": "Android-doos",
    "old_password_req": "Oud wachtwoord is vereist",
    "new_password_req": "Nieuw wachtwoord vereist",
    "confirm_password_req": "Bevestig wachtwoord is vereist",
    "password_do_not_match": "Wachtwoorden komen niet overeen",
    "old_password": "Oud wachtwoord",
    "new_password": "Nieuw wachtwoord",
    "confirm_password": "Bevestig wachtwoord",
    "copy_working_hours": "Deze werkuren naar alle dagen kopiëren?",
    "yes_copy": "Ja, kopiëren",
    "closed": "Gesloten",
    "opening_time": "Openingstijd",
    "closing_time": "Sluitingstijd",
    "description": "Beschrijving",
    "file_exist": "bestand bestaat al, selecteer een ander bestand",
    "bussiness_images": "Bedrijfsafbeeldingen",
    "display_info_on_market_place": "Wilt u onderstaande informatie op uw marktplaats weergeven?",
    "profile_ready": "Jouw profiel is klaar!",
    "client_book_with_online": "Klanten kunnen nu online bij u boeken. Deel de onderstaande link om te beginnen.",
    "copy_link": "Link kopiëren",
    "see_your_profile": "Bekijk je profiel",
    "ok_got_it": "Oké, begrepen",
    "preview_profile": "Bekijk een voorbeeld van uw profiel en ontdek hoe het eruitziet.",
    "opening_hours": "Openingstijden",
    "display_data": "Gegevens weergeven",
    "manage_profile": "Profiel beheren",
    "phone_req": "Telefoon is vereist",
    "market_place_img_req": "Marktplaatsafbeeldingen zijn vereist",
    "add_new_market_place": "Nieuwe marktplaats toevoegen",
    "edit_market_place": "Bewerken Marktplaats",
    "no_reviews_yet": "Nog geen beoordelingen",
    "good": "Goed",
    "average": "Gemiddeld",
    "add_marketPlace": "Marktplaats toevoegen",
    "all_title": "Alle",
    "included_title": "Inbegrepen",
    "excluded_title": "Uitgesloten",
    "clients_subscribed": "Klanten die zich de afgelopen",
    "clients_groups": "Klantengroepen",
    "customer_groups": "Klantengroepen",
    "audience_title": "Publiek",
    "client_gt5": "die de laatste keer niet terugkwam",
    "select_all": "Selecteer alles",
    "modify": "Bewerken",
    "campaign_title": "Campagnetitel",
    "msg_type": "Berichttype",
    "enter_discount": "Korting invoeren",
    "discount_type": "Kortingstype",
    "coupon_text": "Coupontekst",
    "enter_coupon_text": "Voer coupontekst in",
    "coupon_button_text": "Tekst op couponknop",
    "enter_coupon_button_text": "Voer couponknoptekst in",
    "fine_prin": "Kleine lettertjes",
    "enter_fine_prin": "Voer kleine lettertjes in",
    "campaign_dec": "U kunt de campagne 30 minuten voor de starttijd wijzigen. Kosten voor SMS-campagne worden 30 minuten voor de starttijd van de campagne in rekening gebracht.",
    "blast_text_message_dec": "Je blast-sms is klaar. Selecteer je betaalmethode en verstuur je berichten.",
    "payment_completed": " De betaling wordt afgerond op ",
    "total_cost": "Totale kosten",
    "close_title": "Dichtbij",
    "friday": "Vrijdag",
    "saturday": "Zaterdag",
    "sunday": "Zondag",
    "thursday": "Donderdag",
    "tuesday": "Dinsdag",
    "wednesday": "Woensdag",
    "port_txt": "Haven",
    "today_title": "Vandaag",
    "yesterday_title": "Gisteren",
    "last_30_days_txt": "Laatste 30 dagen",
    "this_month_txt": "Deze maand",
    "last_month_txt": "Vorige maand",
    "valid_date_title": "Selecteer een geldige datum",
    "valid_business_name_txt": "Voer een geldige bedrijfsnaam in",
    "req_bus_add_txt": "Bedrijfsadres is vereist",
    "req_domain_name_txt": "Domeinnaam is vereist",
    "basic_info_txt": "Basisgegevens van uw bedrijf",
    "loyalty_qr_def_txt": "Scan de QR-code, registreer u voor onze loyaliteitskaart en geniet van voordelen.",
    "last_stamp_txt": "Laatste stempel",
    "collected_on_txt": "Verzameld op",
    "stamp_details_txt": "Postzegeldetails",
    "add_stamp_txt": "Stempel toevoegen",
    "choose_brand_color_txt": "Kies uw merkkleur en taal 🎨",
    "change_anytime_txt": "U kunt dit op elk moment wijzigen",
    "like_to_get_txt": "Wil je krijgen",
    "your_next_visit_txt": "bij uw volgende bezoek?",
    "time_to_time_get_offers": "Wilt u van tijd tot tijd geweldige aanbiedingen en promoties ontvangen?",
    "respond_back_to_you": "Wilt u dat iemand op u reageert?",
    "input_serve_better": "Met uw input kunnen wij u nog beter van dienst zijn!",
    "do_it_txt": "Laten we het doen",
    "take_15_s": "Duurt 15 seconden",
    "rate_on_google_desc": "Zou u zo vriendelijk willen zijn om ons op Google te beoordelen?",
    "may_be_next_time": "Misschien de volgende keer",
    "thank_you_txt": "Bedankt",
    "look_next_txt": "Wij kijken uit naar uw volgende bezoek!",
    "deleteUserTitle": "Deze gebruiker wordt definitief verwijderd.",
    "deleteMUserTitle": "Deze gebruikers worden permanent verwijderd.",
    "change_pin": "Pin wijzigen",
    "area_code": "Wat is uw netnummer? ",
    "add_menu": "Menu toevoegen",
    "menu_name": "Menunaam",
    "add_menu_placeholder": "Pizza, Burgers, Bavarois, etc.",
    "enable_data_Collection": "Gegevensverzameling inschakelen",
    "add_new_menu": "Nieuw menu toevoegen",
    "rename_menu": "Menu hernoemen",
    "preview": "Voorvertoning",
    "generate_qr": "Gegenereerde QR",
    "edit_menu": "Bewerken Menu",
    "remove_menu": "Menu verwijderen",
    "menu_delete_msg": "Dit menu wordt permanent verwijderd",
    "menus_delete_msg": "Deze menu's worden permanent verwijderd",
    "view_menu_dialoage_msg": "Wil je een cadeau krijgen voor je verjaardag?",
    "skip": "Overslaan",
    "cliam_your_gift": "Claim uw geschenk",
    "collect_form": "Verzamelformulier",
    "enter_first_name": "Voer uw voornaam in",
    "enter_last_name": "Voer achternaam in",
    "enter_email": "E-mailadres invoeren",
    "enter_dob": "Voer geboortedatum in",
    "enter_number": "Voer nummer in",
    "select_gender": "Geslacht selecteren",
    "congratulations_desc": "🎉 Gefeliciteerd! Uw gift claim verzoek is succesvol ingediend. Ons team zal binnenkort contact met u opnemen. 🎉",
    "male_heading": "Mannelijk",
    "male_text": "mannelijk",
    "female_heading": "Vrouwelijk",
    "female_text": "vrouwelijk",
    "others_heading": "Anderen",
    "other_text": "anderen",
    "BirthD": "Geboortedatum",
    "GenderT": "Geslacht",
    "EmailT": "E-mail",
    "dobT": "Geboortedatum",
    "capitalize_menu": "Menu",
    "select_menu": "Selecteer menu",
    "manage_variant": "Variant beheren",
    "add_products": "Producten toevoegen",
    "add_category": "Categorie toevoegen",
    "category_delete": "Deze categorie wordt definitief verwijderd",
    "variation_delete": "Deze variant wordt uit het Product verwijderd.",
    "product_delete": "Dit product wordt definitief verwijderd.",
    "categories_delete": "Deze categorieën worden definitief verwijderd.",
    "products_delete": "Deze producten worden permanent verwijderd.",
    "category": "CATEGORIE",
    "price": "PRIJS",
    "food_product_placeholder": "Pizza, Burgers, Bavarois, etc.",
    "active_title": "Actief",
    "inActive_title": "Inactief",
    "status_capital": "STATUS",
    "cat_status_require": "Categorie Status is vereist",
    "cat_name_require": "Categorienaam is vereist",
    "category_name": "Categorie Naam",
    "status": "Staat",
    "lgDay1": "Maandag",
    "lgDay2": "Dinsdag",
    "lgDay3": "Woensdag",
    "lgDay4": "Donderdag",
    "lgDay5": "Vrijdag",
    "lgDay6": "Zaterdag",
    "lgDay7": "Zondag",
    "is_closed_title": "Gesloten",
    "book_table_title": "Reserveer een tafel",
    "emailErrSub": "E-mailonderwerp is verplicht",
    "email_subject": "E-mailonderwerp",
    "contactUsfrmTitleEmail": "Reageer op e-mail",
    "companyInfo": "Lorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraets.Lorem Ipsum is lorem hrig, lorem ipsum is fraets.",
    "footerSTitle1": "Over Menuonline",
    "footerSTitle1Lnk2": "Functies",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Juridisch",
    "footerSTitle2Lnk1": "Algemene voorwaarden",
    "footerSTitle2Lnk2": "Privacybeleid",
    "footerSTitle3": "Voor bedrijven",
    "footerSTitle3Lnk1": "Voor partners",
    "footerSTitle3Lnk2": "Prijzen",
    "footerSTitle3Lnk3": "Ondersteuning voor partners",
    "footerCopyRithgTxt": "  Menuonline of haar filialen",
    "homeTitle": "Thuis",
    "contactTitle": "Neem contact met ons op",
    "aboutTitle": "Over ons",
    "homeMenuTitle": "Menu",
    "bookTableTitle": "Reserveer een tafel",
    "vDateT": "Selecteer een geldige datum",
    "reqDateT": "Datum is vereist",
    "dateGtErr": "Datum moet later zijn dan of gelijk zijn aan de datum van vandaag",
    "timeInvalid": "Tijd is ongeldig",
    "reqTimeT": "Er is tijd nodig",
    "timeInvalidBfr": "Selecteer een tijd die minimaal 5 minuten later is dan de huidige tijd ",
    "PerReq": "Aantal personen is vereist",
    "validcNoT": "Voer een geldig contactnummer in",
    "reqCrT": "Valuta is vereist",
    "reqPrT": "Prijs is vereist",
    "reCateT": "Categorie is verplicht",
    "reqVrT": "Variant is vereist",
    "reqVrVldT": "Voer een geldige variant in ",
    "validLNameT": "Voer een geldige achternaam in",
    "sDateInValid": "Van datum is ongeldig",
    "minmReq": "Minimaal vereist",
    "resLblDate": "Selecteer datum",
    "resLblTime": "Selecteer tijd",
    "perT": "Voor hoeveel personen?",
    "resLblEmail": "Geef uw e-mailadres op.",
    "resLblNote": "Reserveringsnotitie toevoegen",
    "imageError1MB": "Voeg een afbeelding in die kleiner is dan 1 MB",
    "imageError": "Voeg een afbeelding in die kleiner is dan 2,5 MB",
    "imageError3Mb": "Voeg een afbeelding in die kleiner is dan 3 MB",
    "imageInVldError": "Bestand is ongeldig.",
    "addProMT": "Productmenu",
    "proT": "Productnaam",
    "reqProT": "Productnaam is vereist",
    "proPT": "Productprijs",
    "reservationSuccTitle": "Geweldig, uw reservering is voltooid",
    "book": "Boek",
    "custzName": "Aanpassingsnaam",
    "always": "Altijd",
    "proImgReq": "Productafbeelding is vereist",
    "selCustmT": "Selecteer Aanpassing of maak een nieuwe",
    "visStR": "Zichtbaarheidsstatus is vereist",
    "avlblScR": "Selecteer beschikbaarheidsschema",
    "addonPR": "Selecteer aanvullende producten",
    "upsellPR": "Selecteer upsell-producten",
    "markItemR": "Selecteer markeer item producten",
    "caloriesR": "Calorieën zijn vereist",
    "allergensR": "Selecteer allergenenproducten",
    "prepInstR": "Bereidingsinstructies zijn vereist",
    "staffNR": "Personeelsnotities zijn vereist",
    "discountR": "Korting is vereist",
    "validDiscE": "Voer een geldige korting in",
    "disday": "Overdag",
    "plSelDayT": "Selecteer een dag",
    "sTimeReq": "Starttijd is vereist",
    "sTimeInValid": "Starttijd is ongeldig",
    "eTimeReq": "Eindtijd is vereist",
    "eTimeInValid": "Eindtijd is ongeldig",
    "sDateReq": "Vanaf datum is vereist",
    "eDateReq": "Tot op heden is vereist",
    "eDateInValid": "Tot op heden is ongeldig",
    "disdate": "Vanaf de datum",
    "disdate1": "Tot nu toe",
    "disdate2": "Vanaf datum",
    "currT": "Munteenheid",
    "iconR": "Selecteer het pictogram 'Markeer item'",
    "minT": "Minimum",
    "maxT": "Maximaal",
    "itemNT": "Artikelnaam",
    "itemPT": "Artikelprijs",
    "descProT": "Beschrijf uw product",
    "cateT": "Categorie",
    "selProCateT": "Selecteer uw productcategorie",
    "reqMT": "Menu Naam is verplicht",
    "ViewMenu": "Bekijk menu",
    "CopyMenu": "Menu kopiëren",
    "EditMenu": "Bewerkingsmenu",
    "RemoveMenu": "Menu verwijderen",
    "welcomeMnuTitle": "Welkom bij",
    "reviewT": "Beoordelingen",
    "userErrorMsg": "Sorry, er is een fout opgetreden!!!",
    "reqCateT": "Categorienaam is vereist",
    "mngCustT": "Beheer maatwerk",
    "custNReq": "Aanpassingsnaam is vereist",
    "incReq": "Inbegrepen is vereist",
    "minmValid": "Voer een geldige minimumwaarde in",
    "maxmReq": "Maximaal is vereist",
    "cust": "Aanpassing",
    "crCust": "Nieuwe aanpassing maken",
    "custList": "Aanpassingslijst",
    "delCustmzT": "Deze aanpassing wordt permanent verwijderd.",
    "mkNameR": "Markeer itemnaam is vereist",
    "mkDelT": "Dit markeringsitem wordt definitief verwijderd.",
    "hideText": "Verbergen",
    "showText": "Show",
    "device_mac_txt": "Apparaat Mac",
    "delete_hotspot_txt": "Deze hotspot wordt permanent verwijderd.",
    "delete_hotspots_txt": "Deze hotspots worden permanent verwijderd.",
    "emp_wifi_name": "Wifi-naam van werknemer",
    "max_limit_txt": "Voer '1024' in voor snelheid om het aan te duiden als ONBEPERKT. Deze waarde verwijdert alle caps, waardoor maximale doorvoer mogelijk is",
    "device_port_txt": "Apparaatpoort",
    "firmware_txt": "Bedrijfsprogramma",
    "hotspot_info_txt": "Hotspot-informatie",
    "editDBtn": "Bewerk details",
    "birth_date": "Geboortedatum",
    "di_theme_clr": "Thema Kleur exact 7 cijfers",
    "color_Invalid_txt": "Selecteer een geldige kleurcode",
    "Req_theme_clr": "Thema Kleur is vereist",
    "select_valid_color_txt": "Selecteer een geldige thema kleur",
    "req_redir_link": "Omleidingslink is vereist",
    "val_redir_link": "Voer een geldige omleidingslink in",
    "req_business_name_txt": "Bedrijfsnaam is vereist",
    "splash_preview": "Splash-voorbeeld",
    "create_new_splash": "Nieuwe Splash maken",
    "splash_page": "Splash-pagina",
    "splash_page_builder": "Splash-pagina-bouwer",
    "redirect_link": "Link omleiden",
    "theme_color": "Thema Kleur",
    "enable_social_login": "Schakel sociale media-opties in voor de login van gebruikers",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Is het verplicht?",
    "field": "Veld",
    "name": "Naam",
    "first_name": "Voornaam",
    "last_name": "Achternaam",
    "birthdate": "Geboortedatum",
    "gender": "Geslacht",
    "email": "E-mail",
    "dob": "Geboortedatum",
    "zip_code": "Postcode",
    "required_redirect_link": "Omleidingslink is vereist",
    "valid_redirect_link": "Voer een geldige omleidingslink in",
    "required_theme_color": "Thema Kleur is vereist",
    "theme_color_length": "Thema Kleur exact 7 cijfers",
    "required_name": "Naam is verplicht",
    "delete_splash_message": "Wil je deze splash verwijderen?",
    "delete_multiple_splashes_message": "Wil je deze spatten verwijderen?",
    "user_login_required": "Gebruikerslogin is vereist",
    "set_theme_color": "Themakleur instellen",
    "splash_colllect_additional_info_txt": "Verzamel aanvullende informatie",
    "verify_mobile_otp_message": "Wilt u een OTP verzenden om uw mobiele nummer te verifiëren?",
    "add_company": "Bedrijf toevoegen",
    "edit_company": "Bedrijf bewerken",
    "ds_device": "Digitaal Signage-apparaat",
    "ds_device_activation_fee": "Activeringskosten voor Digital Signage-apparaten",
    "wifi_hardware": "Wifi-hardware",
    "menu_design": "Menu-ontwerp",
    "ds_hardware": "Hardware voor digitale bewegwijzering",
    "company_name_req": "Bedrijfsnaam is vereist",
    "known_name_req": "Bekende naam is vereist",
    "no_location_req": "Aantal locaties is vereist",
    "minimum_location": "Voer minimaal 1 locatie in",
    "terms_req": "Voorwaarden zijn vereist",
    "notes_req": "Notities zijn vereist",
    "sms_charge_req": "SMS-kosten zijn vereist",
    "menuonline_quantity_req": "Menu Online Hoeveelheid is vereist",
    "menuonline_cost_req": "Menu Online Kosten zijn vereist",
    "ds_quantity_req": "Digitale Signage Hoeveelheid is vereist",
    "ds_monthly_cost_req": "Maandelijkse kosten voor digitale bewegwijzering zijn vereist",
    "reputation_quantity_req": "Reputatie Hoeveelheid is vereist",
    "reputation_cost_req": "Reputatiekosten zijn vereist",
    "marketing_quantity_req": "Marketinghoeveelheid is vereist",
    "marketing_cost_req": "Marketingkosten zijn vereist",
    "tax_percentage_req": "Belastingpercentage is vereist",
    "router_hardware_quantity_req": "Routerhardwarehoeveelheid is vereist",
    "wifi_hardware_cost_req": "WIFI Hardwarekosten zijn vereist",
    "activation_cost_req": "Activeringskosten zijn vereist",
    "wifi_quantity_req": "WIFI Hoeveelheid is vereist",
    "wifi_router_req": "Wifi-router is vereist",
    "menu_design_quantity_req": "Menu-ontwerp Hoeveelheid is vereist",
    "menu_design_cost_req": "Menu-ontwerpkosten zijn vereist",
    "quantity_req": "Hoeveelheid is vereist",
    "cost_req": "Kosten zijn vereist",
    "sheduledown_quantity_req": "Schedule Down-hoeveelheid is vereist",
    "sheduledown_cost_req": "Schedule Down Cost is vereist",
    "loyalty_program_quantity_req": "Hoeveelheid loyaliteitsprogramma is vereist",
    "loyalty_program_cost_req": "Loyaliteitsprogrammakosten zijn vereist",
    "loyalty_program_sms_quantity_req": "Loyaliteitsprogramma SMS Hoeveelheid is vereist",
    "loyalty_program_sms_cost_req": "Loyaliteitsprogramma SMS-kosten zijn vereist",
    "comapny_created": "Bedrijf succesvol opgericht",
    "comapny_updated": "Bedrijf succesvol bijgewerkt",
    "billing_info": "FACTURERINGSINFORMATIE",
    "business_name_acc_holder": "Bedrijfsnaam (rekeninghouder)",
    "client_first_name": "Voornaam klant",
    "client_last_name": "Achternaam van de klant",
    "dba_known_as": "DBA bekend als",
    "business_address": "Zakelijk adres",
    "no_of_locations": "Aantal locaties",
    "package_details": "PAKKETGEGEVENS",
    "terms": "Voorwaarden",
    "one_month": "1 maand",
    "per_sms_charge": "Kosten per sms",
    "plan_test": "PLAN: TESTEN",
    "desc_capital": "BESCHRIJVING",
    "qty_capital": "Hoeveelheid",
    "cost_capital": "KOSTEN",
    "total_price_capital": "TOTAALPRIJS",
    "quantity": "Hoeveelheid",
    "can_not_access": "U kunt niet meer dan",
    "licenses": "licenties",
    "monthly_cost": "Maandelijkse kosten",
    "plan_cost": "Plankosten",
    "tax": "Belasting",
    "tax_percentage": "Belastingpercentage",
    "monthly_cost_after_tax": "Maandelijkse kosten na belasting",
    "activation_one_time_charge": "Activering en eenmalige kosten",
    "sub_total": "SUBTOTAAL",
    "action": "Actie",
    "cost": "Kosten",
    "shipping_charge": "Verzendkosten",
    "other": "Ander",
    "additional_cost": "Extra kosten (Overige kosten)",
    "other_tax": "Overige belasting",
    "total": "Totaal",
    "license_statistics": "Licentiestatistieken",
    "total_licenses": "Totaal aantal licenties",
    "available_licenses": "Beschikbare licenties",
    "stock": "Voorraad",
    "has_permission": "Heeft toestemming",
    "avg_stock_price": "AVG-aandelenkoers",
    "average_price": "Gemiddelde prijs",
    "allocated": "Toegewezen",
    "reward_regulars": "Beloon vaste klanten",
    "not_add_coupon_url": "Kan coupon-URL niet toevoegen. De maximale lengte van het bericht is 160",
    "like_attach_coupon": "Wilt u een kortingsbon bijvoegen?",
    "advance_scheduling": "Vooraf plannen",
    "choose_day": "Kies dag(en) om bericht te versturen",
    "select_essage_window": "Selecteer Berichtvenster",
    "subscription_outside_delivery_window": "Als een gebruiker zich buiten de bezorgperiode abonneert, ontvangt hij of zij uw autoresponder op het eerstvolgende beschikbare tijdstip.",
    "remaining": "Overig",
    "processing_request": "Er is een fout opgetreden tijdens het verwerken van uw verzoek.",
    "list_companies": "Lijst van bedrijven",
    "are_you_sure": "Weet je het zeker?",
    "signin_agreement": "Door dit te bevestigen, creëert het bedrijf een overeenkomst zonder dat er een overeenkomst wordt ondertekend.",
    "status_updated": "Statusupdate succesvol",
    "status_failed": "Status kan niet worden bijgewerkt",
    "new_editor_status_updated": "De DS Nieuwe Editor-status is succesvol bijgewerkt.",
    "user_name": "Gebruikersnaam",
    "known_as": "bekend als",
    "agreement_signed": "Overeenkomst getekend",
    "deactivate": "Deactiveren",
    "activate": "Activeren",
    "login_to_user_acc": "Inloggen op gebruikersaccount",
    "disable": "Uitzetten",
    "enable": "Inschakelen",
    "ds_new_editor": "DS Nieuwe redacteur",
    "sign_agreement": "Overeenkomst ondertekenen",
    "agreement_sign": "Overeenkomst zonder handtekening",
    "view_agreement": "Bekijk overeenkomst",
    "download_agreement": "Downloadovereenkomst",
    "add_deleted_user": "Verwijderde gebruiker toevoegen",
    "deleted_user": "Gebruiker verwijderen",
    "favourite": "Favoriet",
    "refresh": "Vernieuwen",
    "delete_chat": "Chat verwijderen",
    "responsive_drawer": "Responsieve lade",
    "delete_contact": "Contactpersoon verwijderen",
    "clear_chat_history": "Chatgeschiedenis wissen",
    "clear_chat_history_dec": "Weet u zeker dat u deze chat wilt verwijderen?",
    "clear_contact_dec": "Weet u zeker dat u dit contact wilt verwijderen?",
    "select_contact": "Selecteer Contact",
    "new_conversation": "Begin een nieuw gesprek",
    "type_msg": "Typ hier uw bericht",
    "select_delete_client": "Selecteer de klant die u wilt verwijderen",
    "select_delete_client_chat": "Selecteer de client om de chat te verwijderen",
    "select_delete_client_chat_err": "Je hebt geen chat met deze klant",
    "acquire_customers_business": "Werf nieuwe klanten van andere bedrijven.",
    "customers_from_events": "Haal klanten van evenementen en haal ze naar u toe!",
    "customers_from_ads": "Krijg klanten via advertenties in de krant.",
    "smart_pamphlets": "Maak slimme brochures om klantgegevens te verzamelen.",
    "smart_qr_group": "Een slimme QR-code voor klanten om zich bij een groep aan te sluiten.",
    "opt_in_number": "Geef klanten de mogelijkheid om een nummer in te voeren.",
    "qr_menu": "Maak een QR-code voor het weergeven van uw menu.",
    "collect_birthdays": "Verzamel de verjaardagen en trouwdagen van klanten.",
    "join_loyalty_program": "Zorg dat nieuwe klanten zich bij mijn loyaliteitsprogramma aansluiten.",
    "create_feedback_forms": "Maak formulieren om feedback van klanten te verzamelen.",
    "exclusive_discounts_promotions": "Bied exclusieve kortingen en promoties voor kruispromoties.",
    "group_smart_qr_opt_in": "Nadat u een groep hebt aangemaakt, kunt u een slimme QR-code genereren en selecteren, zodat klanten zich direct kunnen aanmelden voor een groep",
    "group_smart_qr_opt_in_dec": "Nadat u een groep hebt aangemaakt, kunt u met de widget Afbeelding klanten de mogelijkheid bieden om zich aan te melden met behulp van tekst en promoties te ontvangen, bijvoorbeeld door 'pizza' te sms'en naar 555-555-5555",
    "in_store_discount_next_visit": "Ontvang korting in de winkel tijdens uw volgende bezoek aan onze vestiging",
    "delivery_redirection": "Maak een bezorgomleiding",
    "additional_info": "Aanvullende informatie",
    "add_url": "URL toevoegen",
    "custom_fields": "Aangepaste velden",
    "meta_tag": "Meta-tag",
    "max_chars": "Maximaal aantal tekens",
    "add": "Toevoegen",
    "update_campaign": "Campagne bijwerken",
    "last_week_required": "vorige week is vereist",
    "large_week_value": "Weekwaarde is te groot voer een geldige week in",
    "less_than_last_week": "Niet-terugkeer in vorige week moet lager zijn dan vorige week.",
    "last_week_req": "Niet terug in vorige week is vereist",
    "birthday_special": "Verjaardagsspecial",
    "birthday_message": "Geautomatiseerd bericht verzonden op hun verjaardagen met persoonlijke groeten en verleidelijke aanbiedingen",
    "birthday_sms": "Maak gepersonaliseerde verjaardagsgroeten voor SMS\/MMS",
    "target_audience": "Kies doelgroep",
    "client_by_birthday": "Klant op verjaardag",
    "clients_once_year": "Deze campagne wordt automatisch eenmaal per jaar naar klanten verzonden ",
    "auto_responder_default_msg_1": "U bent een van onze beste klanten aan wie we graag korting geven! Moge u altijd rijk en gezond zijn. Fijne verjaardag! %% voornaam %%",
    "campaign_send_to_client_birthday": "Deze automatische campagne wordt verzonden naar klanten met een verjaardag. U kunt de targeting hieronder aanpassen.",
    "get_more_engagement": "Krijg meer betrokkenheid door verjaardags-sms'jes te sturen 🎂",
    "welcome_new_clients": "Welkom nieuwe klanten",
    "make_lasting_impression": "Maak een blijvende indruk door gasten die voor het eerst bij ons komen, hartelijk te begroeten en te verwelkomen",
    "personalized_greetings": "Maak gepersonaliseerde begroetingen voor nieuwe klanten",
    "greetings_to_new_customers": "Met deze campagne worden nieuwe klanten op de dag na hun eerste interactie met uw bedrijf welkom geheten.",
    "audience_predefined": "Het publiek van deze autoresponder is vooraf gedefinieerd, wij regelen het!",
    "send_clients_subscribed": "Deze autoresponder is ingesteld om klanten te sturen die zich in de laatste ",
    "first_interaction": "  na hun eerste interactie.",
    "default_msg_2": "We hopen dat u genoten heeft van uw eerste bezoek, we zien u graag snel weer, dus bieden we een korting speciaal voor u! Klik op de link om uw volgende afspraak bij ons te boeken en uw speciale online aanbieding nu te verzilveren.",
    "new_clients_update": "Welkom nieuwe klanten update campagnetekst",
    "new_clients_warm_greeting": "Verwelkom nieuwe klanten met een warme groet 💌",
    "win_back_clients": "Win klanten terug",
    "re_engage_past_customers": "Trek opnieuw de aandacht van bestaande klanten met onweerstaanbare aanbiedingen om ze aan te moedigen terug te komen",
    "target_disengaged_clients": "Probeer ongeïnteresseerde klanten terug te laten komen met een speciale aanbieding. Dit is een bewezen tactiek om klanten terug te lokken.",
    "campaign_sends_to_clients": "Deze campagne wordt verzonden naar klanten die na een bepaalde tijd niet zijn teruggekeerd.",
    "customize_targeting": "Pas hieronder de targeting aan om SMS\/MMS te versturen naar klanten die uw e-mailadres hebben verloren.",
    "clients_with_at_least": "Cliënten met minimaal ",
    "connection_last": " verbinding in de laatste ",
    "return_in_last_week": " week, maar kwam niet terug in de laatste ",
    "arr_menu_44": " week",
    "df_msg_4": "We willen je ZO graag terug dat we je 10% korting geven als je terugkomt! Wil je ons vandaag nog eens proberen?",
    "update_campaign_text": "Win klanten terug, update campagnetekst",
    "re_invite_customers": "Nodig klanten die al een tijdje niet meer zijn geweest opnieuw uit",
    "loyal_patrons": "Toon uw waardering voor trouwe klanten met speciale kortingen en loyaliteitsbeloningen",
    "surprize_top_spenders": "Verras topuitgevers met gepersonaliseerde en speciale aanbiedingen.",
    "campaign_automatically_send": "Deze campagne verstuurt automatisch beloningen om het aantal bezoekers van uw bedrijf te vergroten.",
    "sms_loyal_clients": "Met deze campagne sturen we SMS\/MMS naar trouwe klanten. U kunt de targeting hieronder wijzigen.",
    "or_more_connection": " of meer verbinding, binnen de laatste ",
    "week_period": " weekperiode.",
    "default_msg_5": "U bent al lang klant! Wauw, de tijd vliegt! Wij waarderen u en willen u bedanken dat u bij ons bent.",
    "reward_regulars_update": "Beloon vaste klanten update campagnetekst",
    "special_discounts": "Waardeer en geef speciale kortingen💎",
    "leave_positive_online": "Moedig klanten aan om positieve online beoordelingen achter te laten om de reputatie van het restaurant te verbeteren",
    "clients_service_online": "De campagne moedigt uw klanten aan om uw dienstverlening online te beoordelen.",
    "increase_your_company": "Deze autoresponder wordt verzonden om de online beoordeling van uw bedrijf te verhogen.",
    "send_message_after": "Bericht verzenden na ",
    "min_connection": " min van de verbinding",
    "default_msg_6": "We hopen dat je genoten hebt van je eerste bezoek, we zien je graag snel weer! [URL] Klik op de link om ons te beoordelen op Google.",
    "ratings_update": "Ontvang meer beoordelingsupdatecampagnetekst",
    "friendly_nudge": "Krijg meer beoordelingen met een vriendelijke duwtje ⭐",
    "thanks_for_visiting": "Bedankt voor uw bezoek",
    "express_gratitude": "Toon uw dankbaarheid aan klanten die voor uw bedrijf hebben gekozen met oprechte bedankberichten",
    "thank_you_message": "Maak een bedankbericht voor uw klanten.",
    "clients_for_visiting": "Stuur uw klanten een hartelijk bedankje voor hun bezoek.",
    "campaign_audience": "Deze automatische campagnedoelgroep is vooraf gedefinieerd, wij regelen dit!",
    "campaign_automatically_sends": "Deze automatische campagne wordt automatisch verzonden na ",
    "minutes_customer_visit": " minuten van klantbezoek",
    "default_msg_7": "Bedankt dat u onze gewaardeerde klant bent. We zijn zo dankbaar voor het genoegen om u te mogen bedienen en hopen dat we aan uw verwachtingen hebben voldaan.",
    "visiting_update_campaign": "Bedankt voor uw bezoek update campagnetekst",
    "guests_thank_you": "Bedank uw gasten voor hun bezoek 😊",
    "download_title": "Download",
    "qr_gen_title": "QR gegenereerd",
    "qr_download_s_title": "QR-code succesvol gedownload",
    "sel_dark_brand_clr_msg": "Selecteer een geldige schuurkleur",
    "manage_customer_stamp_rewards": "Beheer klantenstempelbeloningen 🎁",
    "sel_loc_menu_title": "Selecteer locaties voor uw menu",
    "ans_req": "Antwoord is vereist",
    "valid_reputation_name": "Voer een geldige reputatienaam in",
    "reviews_name_req": "Ontvang meer beoordelingen naam is vereist",
    "birthdate_required": "Geboortedatum is verplicht",
    "gender_required": "Geslacht is vereist",
    "valid_birthdate_required": "Voer een geldige geboortedatum in",
    "custom_delivery_redirection": "Maak een aangepast bezorgingsherleidingsformulier per groep",
    "customer_fields_incentive_settings": "Klantvelden en incentive-instellingen",
    "delivery_redirection_text": "Bezorging omleiden",
    "delivery_redirection_success": "Gefeliciteerd! Je bezorgomleidingswidget is aangemaakt! 🎉",
    "swipe_to_preview_redirection": "Veeg om te zien hoe uw bezorgomleidingsformulier eruit zal zien",
    "enter_item_name": "Voer de naam van het item in",
    "home_page_text": "Tekst op de startpagina",
    "settings": "Instellingen",
    "anniversary_required": "Jubileumdatum is vereist",
    "valid_anniversary": "Voer een geldige jubileumdatum in",
    "form_submited": "formulier succesvol verzonden",
    "notifications": "Meldingen",
    "discount_message": "Ontvang nu 20% korting op pizza!",
    "is_required": " is vereist",
    "section_title_required": "Sectietitel is vereist",
    "section_dec_required": "Sectiebeschrijving is vereist",
    "client_details_required": "Het veld Klantgegevens is verplicht",
    "compliance": "Naleving",
    "SMS_campaign1": "SMS-campagne 1",
    "mobile_number_mandatory": "Mobiel nummer is verplicht",
    "new_answer": "Nieuw antwoord",
    "new_question": "Nieuwe vraag",
    "add_new_question": "Nieuw antwoord toevoegen",
    "select": "Selecteer",
    "group_customers_question": "Waar wilt u uw klanten groeperen?",
    "contacts_added_to_group": "Alle contactpersonen die het formulier indienen, worden toegevoegd aan de geselecteerde groep",
    "edit_client_details_section": "Sectie Clientgegevens bewerken",
    "client_details_fields": "Velden met klantgegevens",
    "enter_client_details_section_title_desc": "Voer de titel en beschrijving van het gedeelte met de klantgegevens in",
    "choose_fields_displayed_client_side": "Kies de velden die aan de clientzijde moeten worden weergegeven",
    "section_title": "Sectietitel",
    "add_title": "Titel toevoegen",
    "section_description": "Sectie Beschrijving",
    "add_description": "Beschrijving toevoegen",
    "enter_program_name_location": "Voer de programmanaam en locatie in 📍",
    "brand_theme_clr_txt": "Merk Thema Kleur",
    "set_loyalty_punch_card_theme": "Laten we een thema bepalen voor uw loyaliteitskaart 🎨",
    "upload_logo_txt": "Upload uw logo",
    "recommended_image_specs": "Wij raden aan om een afbeelding van 512 x 512px te gebruiken voor de beste weergave. JPG, SVG of PNG. Maximale grootte van 10MB.",
    "valid_rep_name_txt": "Voer een geldige reputatienaam in",
    "rep_name_req_txt": "Ontvang meer beoordelingen naam is vereist",
    "que_req": "Vraag is verplicht",
    "day_of_birthday": "op de dag van de verjaardag",
    "day_before_birthday": "3 dagen voor de verjaardag",
    "week_before_birthday": "een week voor de verjaardag",
    "two_week_before_birthday": "twee weken voor de verjaardag",
    "de_active": "De-actief",
    "campaign_details": "Campagnedetails",
    "link_clicked": "Link geklikt",
    "history": "Geschiedenis",
    "auto_responder_summary": "Samenvatting van de automatische beantwoorder",
    "vsop_1": "15 minuten",
    "vsop_2": "30 minuten",
    "vsop_3": "45 minuten",
    "vsop_4": "60 minuten",
    "vsop_5": "90 minuten",
    "vsop_6": "120 minuten",
    "in_the_last": " in de laatste ",
    "return_in_last": " maar kwam niet terug in de laatste ",
    "of_the_connection": "van de verbinding",
    "your_campaign": "Uw campagne",
    "you_have_successfully": "Je hebt succesvol",
    "published_successfully": "succesvol gepubliceerd",
    "updated_smart_campaign": "de slimme campagne bijgewerkt",
    "almost_done_text": "Bijna klaar!",
    "update_campaign_desc": "Uw autoresponder is compleet, zodra deze is ingeschakeld, stuurt deze doorlopend berichten naar klanten. Deze campagne kan op elk gewenst moment eenvoudig worden aangepast of gepauzeerd.",
    "update_and_publish": "Bijwerken en publiceren",
    "reset_campaign_title": "Hiermee worden de aangepaste tekst, afbeeldingen, kortingsaanbiedingen en doelgroep van uw campagne definitief verwijderd!",
    "space_wifi": " Wi-Fi",
    "custom_forms": "Aangepaste formulieren",
    "web_e_signup": "Web E-aanmelding",
    "import": "Importeren",
    "permissions_req": "Toestemming is vereist",
    "redeemed": "Ingewisseld",
    "coupon_already_redeemed": "Coupon al ingewisseld op",
    "autoresponder_campaigns": "Autoresponder-campagnes",
    "autoresponder_campaign_desc": "Schakel de autoresponder in en start de gepersonaliseerde campagne",
    "mon": "MAANDAG",
    "tue": "DI",
    "wed": "WOENSDAG",
    "thu": "DO",
    "fri": "VRIJ",
    "sat": "ZAT",
    "sun": "ZON",
    "duplicate": "Duplicaat",
    "visibility": "Zichtbaarheid",
    "availability": "Beschikbaarheid",
    "out_stok": "Niet op voorraad",
    "edit_product": "Product bewerken",
    "create_product": "Maak een product",
    "basic_info": "Basis Informatie",
    "basic_sub_info": "Lorem Ipsum is slechts een proeftekst van de druk...",
    "enter_product_price": "Voer productprijs in",
    "upload_image": "Afbeelding uploaden",
    "allowed_file_formats": "Alleen JPG- en PNG-bestanden met een maximale bestandsgrootte van 3 MB zijn toegestaan.",
    "pre_select": "Vooraf selecteren",
    "promotions": "Promoties",
    "discount_availability": "Beschikbaarheid van korting",
    "start_time": "Starttijd",
    "end_time": "Eindtijd",
    "select_day": "Selecteer dag",
    "menu_required": "Menu is vereist",
    "generated": "gegenereerd",
    "link": "Link",
    "variations": "Variaties",
    "select_variant_to_add": "Selecteer de variant die u wilt toevoegen",
    "price_title": "Prijs",
    "choose_image": "Kies afbeelding",
    "select_visiblity": "Selecteer zichtbaarheid",
    "availability_schedule": "Beschikbaarheidsschema",
    "add_on_upsell": "Toevoegen \/ Upsellen",
    "add_on_select_product": "Voeg toe Selecteer product",
    "upsell_product": "Upsell Selecteer product",
    "variant_deletion_warning": "Deze variant wordt definitief verwijderd.",
    "search_variations": "Zoek variaties",
    "add_variation": "Variatie toevoegen",
    "variation_text": "Variant",
    "select_customization": "Selecteer maatwerk",
    "add_new": "Nieuw toevoegen",
    "delete_customization_warning": "Wilt u deze aanpassing uit het product verwijderen?",
    "nutritional_allergen_info": "Voedings- en allergeneninformatie",
    "mark_item": "Item markeren",
    "calories_text": "Calorieën",
    "allergens": "Allergenen",
    "select_allergens": "Selecteer allergenen",
    "special_instructions": "Speciale instructies",
    "preparation_instructions": "Bereidingsinstructies",
    "staff_notes": "Personeelsnotities",
    "checkbox1": "Een selectievakje waarmee u speciale instructies van klanten voor dit menu-item kunt toestaan of weigeren.",
    "menu_c": "MENU",
    "past_design": "Verleden ontwerp",
    "file_name": "Bestandsnaam",
    "variation_name": "Variatie Naam",
    "grid_items_title1": "Tafeltent",
    "grid_items_title2": "Digitaal Menu",
    "grid_items_title3": "Afhaalmenu",
    "grid_items_title4": "Halve pagina menu",
    "grid_items_title5": "Digitaal Menu 5",
    "grid_items_title6": "Digitaal Menu 6",
    "grid_items_title7": "Digitaal Menu 7",
    "grid_items_title8": "Digitaal Menu 8",
    "grid_items_title9": "Digitaal Menu 9",
    "enter_valid_form_name": "Voer een geldige formuliernaam in",
    "form_name_required": "Formuliernaam is vereist",
    "enter_valid_section": "Voer een geldige sectietitel in",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Formulier Naam",
    "contact_list": "Contactlijst",
    "label_notify_email": "Wilt u per e-mail op de hoogte worden gehouden?",
    "add_client_details_section": "Sectie Klantgegevens toevoegen",
    "demo_purpose_only": "Het is alleen bedoeld als demodoeleinden",
    "form_updated_success": "formulier succesvol bijgewerkt",
    "form_creat_success": "formulier succesvol aangemaakt",
    "add_custom_section": "Voeg een aangepaste sectie toevoegen",
    "edit_custom_section": "Een aangepaste sectie bewerken",
    "forms_list": "Lijst met formulieren",
    "filled_custom_form_details": "Gegevens van ingevuld aangepast formulieren",
    "custom_form_deletion_warning": "Dit aangepaste formulier wordt permanent verwijderd"
};

export default nl