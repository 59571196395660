const cs = {
    "rated_google_reviews": "Hodnocení 5,0 v Google Reviews",
    "get_your_digital_menu": "Získejte digitální menu –",
    "instant_popup_menu": "Okamžité vyskakovací menu bez skenování QR",
    "free_forever": "Navždy zdarma",
    "the_future_is_here": "Budoucnost je tady!",
    "upgrade_your_restaurant": "Vylepšete svou restauraci úžasným digitálním menu za nulové náklady. Přilákejte více zákazníků již dnes.",
    "get_my_free_menu_now": "Získejte mé bezplatné menu",
    "no_credit_card_required": "* Není vyžadována žádná kreditní karta",
    "join_largest_marketplace": "Připojte se k největšímu trhu restaurací!",
    "what_will_you_get": "Co získáte",
    "free": "UVOLNIT",
    "free_digital_menu": "Zdarma digitální menu 2D nebo 3Digitální",
    "free_digital_menu_desc": "Rozlučte se s papírovými menu a drahými dotisky",
    "quick_and_easy_signup": "Rychlá a snadná registrace",
    "communicate_with_customers_title": "Komunikujte se zákazníky ZDARMA!",
    "features1": [
        "Ušetřete náklady na tisk a užívejte si okamžité aktualizace nabídek, kdykoli je potřebujete.",
        "Získejte přístup k digitální nabídce na jakémkoli zařízení",
        "Ekologické a bezproblémové"
    ],
    "features2": [
        "Umožněte zákazníkům virtuálně prozkoumat vaše pokrmy pomocí ohromujících 3D vizuálů.",
        "Vylepšete zapojení tím, že dáte hostům realistický náhled na jejich jídlo.",
        "Vynikněte se zážitkem z menu, které je stejně lákavé jako vaše jídlo."
    ],
    "features3": [
        "Okamžitě přivítejte hosty svým menu, jakmile vstoupí do restaurace.",
        "Automaticky posílat aktualizace o speciálních akcích a akcích na jejich telefony.",
        "Vylepšete zážitek z jídla rychlým, bezkontaktním přístupem k vašemu menu."
    ],
    "feature4": [
        "Zvýrazněte sezónní nabídky, denní speciály a exkluzivní akce.",
        "Upoutejte pozornost zákazníků na své nejoblíbenější položky.",
        "Vytvořte naléhavost a vzrušení pomocí poutavých vizuálů a popisů."
    ],
    "feature5": [
        "Staňte se součástí online komunity s tisíci denních návštěvníků.",
        "Zvyšte viditelnost a oslovte nové zákazníky, kteří aktivně hledají možnosti stravování.",
        "Zajistěte si první místo včas, abyste maximalizovali svou expozici a přilákali více hostů."
    ],
    "section3_heading": "Proč přejít na digitální vysílání? Protože je to zdarma a snadné!",
    "section3_description": "Odemkněte výkonné funkce navržené ke zvýšení přitažlivosti vaší restaurace.",
    "instant_menu": "Okamžité menu",
    "instant_menu_notification": "Pop-up a oznámení",
    "instant_menu_description": "Přivítejte hosty s okamžitým přístupem k menu, jakmile vstoupí. Automaticky posílat nejnovější menu a speciality přímo na jejich mobilní telefony",
    "digital_menu": "Digitální menu",
    "at_zero_cost": "za nulové náklady",
    "digital_menu_description1": "Rozlučte se s papírovými menu a drahými dotisky. Přejděte na digitální menu, které je vždy aktuální – a je navždy zdarma!",
    "digital_menu_description2": "S naší digitální nabídkou jsou vaše nabídky vždy aktuální a dostupné na jakémkoli zařízení. Ušetřete peníze a zjednodušte aktualizace menu s bezproblémovým řešením s nulovými náklady.",
    "try_me_button": "Zkuste mě",
    "try_3d_menu_title": "3D menu",
    "try_3d_menu_title2": "Zdarma — WOW!",
    "try_3d_menu_description1": "Nechte vaše nádobí vyskočit z obrazovky. Nahrajte 3D obrázky a nechte zákazníky virtuálně ochutnat vaše jídlo, než vejdou.",
    "try_3d_menu_description2": "Vysoce kvalitní 3D obrázky vytvářejí strhující náhled, zvyšující přitažlivost a povzbuzující objednávky.",
    "try_now": "Zkuste nyní",
    "showcase_title": "Předveďte svůj",
    "showcase_specials": "Speciality",
    "showcase_specials_description1": "Máte skvělou nabídku nebo bezkonkurenční propagaci? Zvýrazněte to ve svém digitálním menu a přitáhněte více hladových lidí.",
    "showcase_specials_description2": "Zajistěte, aby propagační akce nemohly uniknout, upoutejte pozornost odvážnými vizuálními prvky a lákavými popisy.",
    "marketplace": "Tržiště",
    "marketplace_description": "Rozvíjíme tržiště s více než 2000 denními návštěvníky. Naskočte na palubu hned a budete první v řadě, až odstartujeme. Můžete oslovit nové zákazníky, kteří aktivně hledají možnosti stravování. Zajistěte si své místo včas, abyste maximalizovali expozici!",
    "see_other_products": "Viz Další produkty",
    "about_us": "O nás",
    "why_serving_free": "Proč to servírujeme",
    "serving_free_highlight": "Uvolnit",
    "about_desc": "Jsme místní lidé přímo tady v Dallasu, kteří se zavázali pomáhat restauracím, jako je ta vaše, aby prosperovaly.",
    "about_tool_free": "Tento mocný nástroj nabízíme zcela zdarma, protože věříme v podporu naší komunity.",
    "no_catch": "Žádný úlovek, žádná sranda. Víme, že když uspějete, naše komunita bude silnější. 😊",
    "understanding_challenges": "Rozumíme výzvám podnikání, a proto jsme odhodláni poskytovat nástroje, díky nimž budou vaše operace plynulejší a váš dosah širší.",
    "community_growth": "Jak rostete, naše komunita roste s vámi a vytváří dominový efekt úspěchu a podpory. Pojďme společně pozvednout zážitek ze stolování pro všechny.",
    "how_it_works": "Jak to funguje",
    "three_simple_steps": "Tři jednoduché kroky k tomu",
    "get_started_highlight": "Začněte",
    "no_tech_skills_needed": "Nejsou potřeba žádné technické dovednosti. Pokud umíte otočit burger, můžete to udělat!",
    "step1_title": "Zobrazte svou promo akci ZDARMA!",
    "step1_desc": "Začněte rychleji, než stačíte říct \"Objednejte!\" Stačí vyplnit jednoduchý formulář.",
    "step2_title": "Přizpůsobte si své menu",
    "step2_desc": "Přidejte svá jídla, nahrajte fotografie i ve 3D a zvýrazněte své speciality.",
    "step3_title": "Sdílejte a rostete",
    "step3_desc": "Sdílejte své digitální menu prostřednictvím QR, sociálních médií nebo svého webu.",
    "testimonials": "Posudky",
    "testimonials_section_title": "Neberte jen naše slovo",
    "testimonials_section_desc": "Podívejte se, co říkají další majitelé restaurací v Dallasu.",
    "faq_section_title": "Máte otázky? Máme odpovědi",
    "faq_section_desc": "Vše, co potřebujete vědět, než začnete.",
    "faqs": "Nejčastější dotazy",
    "faq_answers": [
        {
            "que": "Je to opravdu navždy zdarma?",
            "ans": "Absolutně! Žádné skryté poplatky, žádné kreditní karty, žádné podmínky."
        },
        {
            "que": "Potřebuji nějaké technické know-how?",
            "ans": "Pokud můžete poslat e-mail, můžete to udělat. Je to tak jednoduché."
        },
        {
            "que": "Proč to nabízíte zdarma?",
            "ans": "Věříme v dávání jako první. Po linii můžeme mít další služby, které se vám budou líbit – ale to je zcela na vás."
        },
        {
            "que": "Jaká je dohoda s tržištěm?",
            "ans": "Vaříme to! Jakmile budeme mít na palubě dostatek restaurací, spustíme vaše tržiště, abychom vás ještě více zviditelnili."
        }
    ],
    "testimonials_content1": "Přechod na digitální menu byl nejlepší krok, který jsme udělali! Naši zákazníci milují pohodlí a aktualizovaný vzhled!",
    "testimonials_name1": "Morris George",
    "testimonials_restaurant_name1": "Restaurace Mansion",
    "testimonials_content2": "Naše nové digitální menu hostům usnadňuje procházení a je to opravdu větší zapojení!",
    "testimonials_name2": "Amélie Johnsonové",
    "testimonials_restaurant_name2": "Urban Eats",
    "testimonials_content3": "Hosté jsou ohromeni 3D vizuálními prvky – to nám pomohlo vyniknout a zlepšilo jejich kulinářský zážitek!",
    "testimonials_name3": "Davis Green",
    "testimonials_restaurant_name3": "Městský krb",
    "ready_to_boost_your_restaurant": "Jste připraveni oživit svou restauraci?",
    "ready_to_boost_desc": "Nezůstávejte pozadu. Připojte se k digitální revoluci ještě dnes!",
    "yes_i_want_my_free_digital_menu": "Ano, chci své bezplatné digitální menu",
    "480_plus": "480+",
    "people_already_joined": "lidé se již připojili",
    "menu_online_home": "Menu Online Domů",
    "welcome": "Vítejte",
    "description_other": "Pojďme zvýšit spokojenost vašich zákazníků!",
    "benefits": "Výhody",
    "greeting_text": "Vítejte 👋",
    "all_in_one_features": "🚀 Funkce „vše v jednom“ pro povýšení vaší restaurace",
    "menu_online_menu_name": "Menu online",
    "menu_online_description": "Digitalizujte své menu pro snadný online přístup pro zákazníky.",
    "menu_online_benefits": "Zvyšte pohodlí zákazníků a zvyšte zapojení tím, že jim umožníte zobrazit si vaši nabídku kdykoli a kdekoli.",
    "ds_name": "Digitální značení",
    "ds_description": "Předvádějte nabídky a propagační akce na dynamických obrazovkách obchodu.",
    "ds_benefits": "Upoutejte pozornost zákazníků pomocí poutavých vizuálů, propagace upsells a nabídek v reálném čase bez námahy.",
    "loyalty_punch_name": "Věrnostní děrná karta",
    "loyalty_punch_description": "Odměňte opakované zákazníky digitálním věrnostním programem.",
    "loyalty_punch_benefits": "Podpořte udržení zákazníků a zvyšte prodeje tím, že budete motivovat loajalitu pomocí směnitelných odměn za časté návštěvy.",
    "marketing_menu_name": "Marketing",
    "marketing_description": "Oslovte více zákazníků pomocí cílených digitálních marketingových kampaní.",
    "marketing_benefits": "Rozšiřte dosah své restaurace a zvyšte návštěvnost pomocí vlastních propagačních akcí přizpůsobených tak, aby přilákaly nové a opakované zákazníky.",
    "get_more_reviews_menu_name": "Získejte více recenzí",
    "get_more_reviews_description": "Povzbuďte spokojené zákazníky, aby zanechali pozitivní online recenze.",
    "get_more_reviews_benefits": "Zvyšte důvěryhodnost své restaurace a přilákejte nové hosty tím, že zlepšíte svou online reputaci pomocí více pozitivních recenzí.",
    "guest_smart_wifi_menu_name": "Inteligentní Wifi pro hosty",
    "guest_smart_wifi_description": "Nabídněte bezplatnou Wi-Fi k zachycení dat hostů pro marketing.",
    "guest_smart_wifi_benefits": "Zvyšte spokojenost zákazníků při shromažďování cenných zákaznických dat pro personalizaci budoucích akcí.",
    "reservation_menu_name": "Rezervace",
    "reservation_description": "Umožněte zákazníkům snadno si rezervovat stoly online.",
    "reservation_benefits": "Zlepšete zážitek ze stolování tím, že minimalizujete čekací doby a zefektivněte rezervace, abyste mohli lépe spravovat sezení.",
    "pos_integration_menu_name": "Integrace POS",
    "pos_integration_description": "Integrujte náš systém s vaším POS systémem.",
    "pos_integration_benefits": "Zjednodušte zpracování objednávek a snižte počet chyb tím, že budete udržovat své online systémy a systémy v obchodě synchronizované.",
    "online_ordering_menu_name": "Online objednávka",
    "online_ordering_description": "Umožněte zákazníkům zadávat objednávky přímo z vašeho webu nebo aplikace.",
    "online_ordering_benefits": "Zvyšte prodeje a snižte závislost na platformách třetích stran tím, že nabídnete možnost přímé objednávky, která šetří poplatky.",
    "pro_label": "Pro",
    "coming_soon": "Již brzy",
    "locked_label": "Zamčeno",
    "forever_label": "Navždy",
    "new_label": "Nový",
    "other_products_page_title": "Ostatní produkty | Menu online",
    "other_products_description": "Nabídka Zásady ochrany osobních údajů online",
    "other_products_header_title": "Ostatní produkty",
    "privacy_policy": {
        "title": "Zásady ochrany osobních údajů",
        "lastUpdated": "Poslední aktualizace: 1. července 2024",
        "introduction": {
            "header": "Zásady ochrany osobních údajů pro nové a stávající zákazníky",
            "paragraph1": "NÁSLEDUJÍCÍ KLÍČOVÉ BODY (VČETNĚ REFERENCÍ NA ČÁSTI) JSOU POSKYTOVÁNY POUZE PRO VAŠE POHODLÍ A NENAHRAZUJÍ ÚPLNÉ SMLUVNÍ PODMÍNKY. JE VAŠÍ ODPOVĚDNOSTÍ PŘEČÍST SI TYTO PODMÍNKY PŘED POUŽITÍM SLUŽBY MENUONLINE INC.",
            "paragraph2": "Používání Menuonline znamená, že souhlasíte s Podmínkami a prohlašujete a zaručujete, že k tomu máte oprávnění vaší organizací nebo společností. (Viz část 3 „Jak to funguje“)",
            "paragraph3": "Menuonline Inc je marketingová služba založená na povoleních. Záměrné rozesílání „spamu“ nebo porušení Podmínek bude mít za následek uzavření vašeho účtu. (Viz část 16 „Přijatelné používání služby“)",
            "paragraph4": "Data, která hostujete v Menuonline Inc, jako jsou záznamy kontaktů, obsah e-mailů a informace, které shromažďujete prostřednictvím Menuonline Inc, patří vám. (Viz s.14 „Co vlastníte?“).",
            "paragraph5": "Software Menuonline Inc a obsah našich webových stránek patří nám. (Viz s.13 „Co vlastníme?“) Kromě vašich vlastních uživatelů mohou data nebo nepublikovaný obsah, který hostujete u Menuonline Inc., prohlížet pouze zaměstnanci a partneři Menuonline Inc, kteří podepsali smlouvy o mlčenlivosti. (Viz s.14 „Co vlastníte?“)",
            "paragraph6": "Veškeré osobní údaje (PII) jsou uchovávány pouze na serverech umístěných v Kanadě. (Viz s.14 „Co vlastníte?“).",
            "paragraph7": "Služba Menuonline Inc podléhá zákonům Kanady a provincie Ontario. (Viz § 24 „Rozhodné právo“)",
            "paragraph8": "Jakékoli žádosti o vaše data nebo obsah od jednotlivců, policie nebo jiných regulačních orgánů z Kanady nebo mimo ni budou vždy předány vám. Jedinou výjimkou by bylo, pokud by to znamenalo porušení kanadského nebo ontarijského práva; ve všech případech se však budeme důrazně bránit jakémukoli uvolnění vašich dat kýmkoli jiným než vámi. (Viz s.14 „Co vlastníte?“).",
            "paragraph9": "Zabezpečení Menuonline Inc je jen tak dobré, jak dobrá je vaše správa uživatelských jmen a hesel. Za uživatelské účty Menuonline Inc neúčtujeme poplatky, proto vytvořte pro každého uživatele samostatný a hesla udržujte v tajnosti. Jste zodpovědní za svůj účet. (Viz část 3 „Jak to funguje“)",
            "paragraph10": "Svůj účet můžete kdykoli zrušit. V případě zrušení neposkytujeme náhradu za nevyužitý kredit. (Viz část 3 „Jak to funguje“)"
        },
        "termsAndConditions": {
            "title": "OBCHODNÍ PODMÍNKY MENUONLINE",
            "lastUpdate": "POSLEDNÍ AKTUALIZACE: 14. ledna 2020",
            "paragraph1": "Před použitím této webové stránky (dále jen „stránky“) si prosím pozorně přečtěte tyto podmínky služby (dále jen „Podmínky“). Tyto podmínky osvobozují společnost Menuonline Inc. („Menuonline Inc“ „my“ nebo „nás“) a další z odpovědnosti a\/nebo omezují naši a jejich odpovědnost a obsahují další důležitá ustanovení, která se vztahují na vaše používání těchto stránek. Návštěvou nebo používáním tohoto webu nebo služby souhlasíte svým jménem a jménem jakékoli organizace, jejímž jménem můžete jednat (dále společně jen „vy“), že přijmete a budete se řídit těmito podmínkami pro každé použití a každou návštěvu tohoto webu."
        },
        "services": {
            "title": "Naše služby.",
            "paragraph": "Služby, které nabízíme prostřednictvím Stránek službu, která vám umožňuje vytvářet, odesílat a spravovat e-mailové zprávy (dále jen „E-maily“) příjemcům dle vašeho výběru (dále jen „Služba“)."
        },
        "license": {
            "title": "Licence.",
            "paragraph": "V souladu s těmito Podmínkami vám společnost Menuonline Inc tímto uděluje nevýhradní, omezenou a nepřenosnou licenci během Období k prohlížení webu a používání služby tak, jak to umožňují funkce služby. Vyhrazujeme si všechna práva, která zde nejsou výslovně udělena, ve Službě a Obsahu Menuonline Inc (jak je definováno níže)."
        },
        "emailingContent": {
            "title": "Obsah e-mailu.",
            "paragraph": "Při interakci s Menuonline Inc prostřednictvím tohoto webu nebo služby souhlasíte s poskytováním pravdivých a přesných informací. Dále chápete, že poskytování důvěrných informací prostřednictvím e-mailu je nebezpečný postup. Chápete, že jste výhradně odpovědní za vše, co pošlete komukoli, kdo používá naši službu."
        },
        "support": {
            "title": "Podpora.",
            "paragraph": "Poskytujeme vám podporu, která vám pomůže stát se soběstačnými se Službou. Poskytujeme vám podporu ve formě dokumentace a videí na Stránce, v systému lístků podpory Menuonline Inc a po telefonu. Můžete nás kontaktovat kliknutím na záložku „Podpora“ v horní části webu. Za podporu produktu se neplatí; vyhrazujeme si však právo ukončit podporu pro konkrétní klientské účty podle našeho uvážení."
        }
    },
    "terms_condition": {
        "title": "Podmínky služby | Menu online",
        "header": "Smluvní podmínky pro nové a stávající zákazníky"
    },
    "privacy_policy_title": "SOUKROMÁ POLITIKA",
    "privacy_policy_effective_date": "Účinné od 16. ledna 2020",
    "privacy_policy_general_title": "OBECNÉ ZÁSADY SOUKROMÍ",
    "privacy_policy_intro": "menuonline.com (Menuonline a „my“) chápe důležitost ochrany soukromí osobních údajů jednotlivců a důležitost bezpečnosti důvěrných informací všech typů. Vytvořili jsme tyto soukromé zásady (dále jen „zásady“), abychom demonstrovali náš závazek chránit vaše „soukromí“. Tyto soukromé zásady vysvětlují:",
    "privacy_policy_points": [
        "Jaké osobní údaje shromažďujeme a proč je shromažďujeme;",
        "Jak používáme vaše osobní údaje;",
        "S kým sdílíme vaše osobní údaje; a",
        "Možnosti, které máte k přístupu, aktualizaci a odstranění vašich osobních údajů ze služby."
    ],
    "privacy_policy_pipeda": "Menuonline řídí shromažďování všech informací v souladu s kanadským zákonem o ochraně osobních údajů a elektronických dokumentech (PIPEDA).",
    "privacy_policy_update_notice": "Upozorňujeme, že Menuonline si vyhrazuje právo kdykoli aktualizovat nebo změnit tyto soukromé zásady. O jakýchkoli změnách vás budeme informovat zveřejněním aktualizovaných Zásad na této webové stránce nebo tak, jak jsou dostupné prostřednictvím služeb. Nejaktuálnější verze těchto zásad se bude řídit tím, jak mycircle používá osobní údaje uživatelů. Pokračováním v přístupu na webovou stránku mycircle nebo pokračováním v používání jejích služeb po provedení změn v těchto zásadách soukromí souhlasíte s tím, že budete vázáni upravenými podmínkami.",
    "privacy_policy_consent_title": "SOUHLAS PRO OSOBNÍ INFORMACE",
    "privacy_policy_consent_text": "Poskytnutím svých osobních údajů společnosti Menuonline prostřednictvím našich webových stránek nebo některého z našich formulářů souhlasíte se shromažďováním, používáním a zveřejňováním těchto informací v souladu s těmito zásadami nebo pro účely, které jste uvedli v době, kdy jste osobní údaje poskytli. Jakékoli sekundární použití osobních údajů bude zpracováno pouze s vaším výslovným souhlasem, nebo vám bude poskytnuta možnost se odhlásit.",
    "privacy_policy_opt_out_text": "Máte možnost svůj souhlas kdykoli odvolat nebo se odhlásit. Pokud si přejete odvolat svůj souhlas nebo se odhlásit, můžete kliknout na odkaz předvoleb komunikace ve spodní části jakéhokoli e-mailu, který od nás obdržíte.",
    "privacy_policy_info_collection_title": "INFORMACE, KTERÉ SBÍRÁME",
    "privacy_policy_info_collection_text": "Menuonline shromažďuje a zpracovává osobní údaje v souladu s těmito Zásadami. Tyto informace nebudeme prodávat, sdílet ani pronajímat jiným osobám, s výjimkou případů uvedených v těchto zásadách. Menuonline shromažďuje osobní údaje pro účely poskytování našich služeb nebo produktů vám.",
    "privacy_policy_info_types_text": "Mezi typy informací, které Menuonline shromažďuje, patří:",
    "privacy_policy_account_holders_title": "Majitelé účtů Menuonline",
    "privacy_policy_registration_info": "Registrační informace: Pokud se zaregistrujete k používání služeb, potřebujete účet Menuonline, abyste se stali držitelem účtu (\"držitel účtu\"). Při registraci účtu budete požádáni o poskytnutí určitých základních informací, jako je vaše jméno, e-mailová adresa, uživatelské jméno, heslo, název společnosti, povolání, umístění a telefonní číslo. Hesla jsou šifrována – zaměstnanci Menuonline nemohou hesla zobrazit ani načíst.",
    "privacy_policy_billing_info": "Fakturační údaje: Pokud si předplatíte naše služby, budete nám muset poskytnout platební a fakturační údaje.",
    "privacy_policy_cookies_info": "Soubory cookie: Soubory cookie používáme ke sledování údajů o návštěvnících, jako je zdroj návštěv webových stránek a počet návštěv webových stránek na našich webových stránkách.",
    "privacy_policy_ip_info": "IP adresy: IP adresy jsou o vás shromažďovány v rámci vašeho používání služeb (např. za účelem zaznamenávání polohy na úrovni země a IP adres používaných při používání našich služeb).",
    "privacy_policy_your_contacts_title": "Vaše kontakty",
    "privacy_policy_distribution_list": "Distribuční seznam: V rámci vašeho používání našich služeb nám můžete poskytnout nebo můžeme v rámci služeb shromažďovat osobní údaje o vašich kontaktech („Kontakty“). Tyto informace zahrnují e-mailové adresy a mohou zahrnovat další identifikovatelné informace, jako je jméno, název společnosti nebo jiné informace, které poskytujete společnosti Menuonline při používání služeb. Musíte se ujistit, že od svých Kontaktů získáte příslušný souhlas, který nám umožní zpracovávat jejich informace.",
    "privacy_policy_billing_info_contacts": "Fakturační údaje: Pokud si předplatíte naše služby, budete nám muset poskytnout platební a fakturační údaje.",
    "privacy_policy_cookies_contacts": "Soubory cookie: Společnost Menuonline bude používat soubory cookie a podobné technologie sledování ke shromažďování a používání osobních údajů o vašich Kontaktech pouze v případě, že jste na své webové stránky přidali sledovací kód Menuonline. Všechny vstupní stránky hostované službou Menuonline budou mít automaticky sledovací kód.",
    "privacy_policy_ip_contacts": "IP adresy: IP adresy jsou shromažďovány o vašich Kontaktech jako součást služeb (např. za účelem zaznamenání polohy na úrovni země a IP adres použitých při odeslání formuláře a poskytnutí souhlasu).",
    "privacy_policy_info_about_contacts_title": "INFORMACE, KTERÉ SBÍRÁME O VAŠICH KONTAKTECH",
    "privacy_policy_info_about_contacts_notice": "*Tato část se vztahuje pouze na informace shromážděné, použité nebo zveřejněné společností Menuonline o Kontaktech majitele účtu pro účely poskytování služeb*",
    "privacy_policy_info_about_contacts_desc": "Společnost Menuonline bude shromažďovat, používat nebo zveřejňovat osobní údaje o Kontaktech pouze v případě, že se Majitel účtu zaregistroval pro určité služby, kde nám poskytuje distribuční seznam. Držitelé účtů jsou výhradně odpovědní za zajištění toho, že při poskytování osobních údajů o svých Kontaktech společnosti Menuonline prostřednictvím služeb dodrželi všechny platné zákony.",
    "privacy_policy_info_auto_collect_title": "Informace Menuonline automaticky shromažďuje",
    "privacy_policy_info_auto_collect_desc1": "Když se kontakty držitele účtu zapojí do e-mailové kampaně přijaté od držitele účtu nebo interagují s odkazem na připojenou webovou stránku držitele účtu, může společnost Menuonline shromažďovat informace o zařízení takového kontaktu a interakci s e-mailem pomocí souborů cookie. Tyto informace jsou shromažďovány pouze za účelem poskytování služeb a poskytování přehledu majiteli účtu.",
    "privacy_policy_info_auto_collect_desc2": "Informace, které shromažďujeme o zařízení Kontaktu a aplikacích, které Kontakt používá pro přístup k e-mailům odeslaným od Majitele účtu prostřednictvím našich služeb, mohou zahrnovat IP adresu Kontaktu, operační systém, ID prohlížeče a další související informace o systému a připojení Kontaktu.",
    "privacy_policy_info_auto_collect_desc3": "Informace, které shromažďujeme o interakci Kontaktu s e-maily zasílanými prostřednictvím služeb, mohou zahrnovat data a časy, kdy Kontakt přistupuje k e-mailu a procházení, a informace týkající se výkonu služeb, jako je doručování e-mailů a jiné komunikace zaslané Kontaktu Majitelem účtu prostřednictvím služeb.",
    "privacy_policy_info_auto_collect_desc4": "Upozorňujeme, že společnost Menuonline nebude kontaktovat žádný z vašich kontaktů, pokud to nevyžaduje platný zákon.",
    "privacy_policy_why_we_collect_title": "PROČ SHROMAŽĎUJEME VAŠE INFORMACE",
    "privacy_policy_why_we_collect_desc": "Menuonline shromažďuje informace pro následující účely:",
    "privacy_policy_why_collect_1": "poskytovat Držitelům účtu Menuonline služby, které si předplatí, včetně používání informací o držiteli účtu pro služby;",
    "privacy_policy_why_collect_2": "pro administraci a správu účtu, stejně jako pro přístup na webovou stránku mycircle;",
    "privacy_policy_why_collect_3": "pokud jste souhlasili se zasíláním marketingových a propagačních materiálů, abychom vás informovali o dalších produktech a službách nabízených Menuonline; a",
    "privacy_policy_why_collect_4": "reagovat na sdělení majitele účtu nebo zasílat vámi požadované informace. To může zahrnovat, ale není omezeno na:",
    "privacy_policy_why_collect_5": "speciální nabídky a aktualizace;",
    "privacy_policy_why_collect_6": "bulletin; a",
    "privacy_policy_why_collect_7": "servisní oznámení.",
    "privacy_policy_opt_out_desc": "Kdykoli se můžete rozhodnout odhlásit se z přijímání zpráv kliknutím na tlačítko pro odhlášení ve spodní části každého e-mailu zaslaného z Menuonline.",
    "privacy_policy_use_disclosure_title": "POUŽÍVÁNÍ A POSKYTOVÁNÍ OSOBNÍCH INFORMACÍ",
    "privacy_policy_use_disclosure_desc1": "Osobní údaje zaslané společnosti Menuonline, a to i prostřednictvím webových stránek společnosti Menuonline nebo předepsaného formuláře, jsou přísně důvěrné, pokud nejsou zveřejněny v souladu s výslovným souhlasem Majitele účtu nebo zákonnými požadavky. V každém případě jsou jakékoli osobní údaje poskytnuté na jakékoli platformě „Menuonline“ držiteli účtů dobrovolné: jakékoli osobní údaje poskytnuté držitelem účtu jsou poskytnuty na základě uvážení držitele účtu. Vezměte prosím na vědomí, že odvolání vašeho souhlasu může vést k tomu, že nebudete moci přistupovat k některým službám Menuonline nebo je využívat.",
    "privacy_policy_use_disclosure_desc2": "Společnost Menuonline využívá pro své webové stránky a služby třetí strany a externí poskytovatele služeb. Osobní údaje Majitele účtu mohou být sdíleny s těmito třetími stranami a poskytovateli. Můžeme také poskytovat agregované statistiky o majitelích účtů, prodejích, vzorcích provozu a související informace renomovaným třetím stranám. Menuonline vyžaduje, aby jakýkoli poskytovatel třetí strany, který má přístup k osobním údajům, byl vázán povinnostmi v souladu s těmito zásadami ochrany soukromí a aby tito poskytovatelé shromažďovali, používali nebo sdělovali osobní údaje Majitele účtu pouze pro účely poskytování služeb Menuonline.",
    "privacy_policy_use_disclosure_desc3": "Osobní údaje poskytnuté držiteli účtů nebudou prodávány, sdíleny ani pronajímány externím organizacím, pokud to není v souladu s vaším předpokládaným nebo výslovným souhlasem. Menuonline si vyhrazuje právo kontaktovat kteréhokoli Držitele účtu za účelem předání informací, odpovědi na otázky nebo oznámení o jakýchkoli změnách našeho programu nebo našich zásad. Upozorňujeme, že Menuonline nebude kontaktovat žádný z vašich Kontaktů, pokud to nevyžaduje platný zákon.",
    "privacy_policy_use_disclosure_desc4": "Menuonline zpřístupní osobní údaje pouze v případě, že k tomu udělí souhlas příslušný jednotlivec nebo:",
    "privacy_policy_disclosure_list_1": "pokud to povoluje nebo vyžaduje zákon, například v reakci na předvolání k soudu nebo jiný právní proces;",
    "privacy_policy_disclosure_list_2": "nabyvateli celé nebo části Menuonline;",
    "privacy_policy_disclosure_list_3": "poskytovatelům služeb společnosti Menuonline v souladu s těmito zásadami; nebo",
    "privacy_policy_disclosure_list_4": "ke splnění zákonných povinností, včetně, ale nejen, regulačních oznamovacích povinností.",
    "privacy_policy_service_providers_title": "Menuonline využívá následující třetí strany a poskytovatele služeb (osobní údaje vašich Kontaktů nejsou „takovým“ třetím stranám poskytovány):",
    "privacy_policy_service_provider_1": "ManyChat používáme k poskytování internetových chatovacích služeb pro prodej a podporu, stejně jako následných e-mailů o těchto konverzacích.",
    "privacy_policy_service_provider_2": "AskNicely používáme k průzkumu našich zákazníků za účelem dotazu na kvalitu služeb a podpory, kterou dostávají.",
    "privacy_policy_service_provider_3": "join.me používáme k plánování schůzek se zákazníky a potenciálními zákazníky, hovorů podpory a ukázek softwaru.",
    "privacy_policy_service_provider_4": "Používáme Google Analytics ke sledování anonymních webových aktivit a měření vzorců používání na našich webových stránkách.",
    "privacy_policy_breach_notification_title": "OZNÁMENÍ O PORUŠENÍ",
    "privacy_policy_breach_notification_desc": "Společnost Menuonline se bude řídit platnými zákony a poskytne Majitelům účtů přiměřenou pomoc v souladu s platnými zákony s ohledem na jakékoli neoprávněné použití, přístup nebo zveřejnění osobních údajů.",
    "privacy_policy_rights_access_title": "VAŠE PRÁVA A PŘÍSTUP",
    "privacy_policy_rights_access_desc": "Máte právo na přístup k osobním údajům, které o vás Menuonline zpracovává. Můžete si od nás vyžádat informace o:",
    "privacy_policy_rights_access_list_1": "Osobní údaje, které o vás uchováváme",
    "privacy_policy_rights_access_list_2": "Kategorie dotčených osobních údajů",
    "privacy_policy_rights_access_list_3": "Účely zpracování",
    "privacy_policy_rights_access_list_4": "Podrobnosti o tom, komu jsou\/budou vaše osobní údaje zpřístupněny",
    "privacy_policy_rights_access_list_5": "Jak dlouho uchováváme vaše osobní údaje",
    "privacy_policy_rights_access_list_6": "Pokud jsme informace nezískali přímo od vás, informace o zdroji",
    "privacy_policy_rights_access_list_7": "Jak podat stížnost u správného dozorového úřadu",
    "privacy_policy_rights_access_list_8": "Můžete od nás také požadovat následující:",
    "privacy_policy_rights_access_list_9": "Že aktualizujeme jakékoli neúplné nebo nepřesné informace o vás",
    "privacy_policy_rights_access_list_10": "Požádejte, abychom vymazali vaše osobní údaje v souladu s platnými zákony",
    "privacy_policy_rights_access_contact": "Můžete požádat, abychom provedli vaše práva tak, že nás kontaktujete na adrese 2851 Dufferin Street, Toronto, ON, Kanada, nebo nám pošlete e-mail na adresu {link}.",
    "privacy_policy_accountability_title": "ODPOVĚDNOST",
    "privacy_policy_accountability_desc": "Společnost Menuonline se zavazuje nést odpovědnost za osobní a důvěrné informace, které nám poskytnete. Kontaktní informace naleznete na konci těchto zásad.",
    "privacy_policy_links_other_sites_title": "ODKAZY NA JINÉ STRÁNKY",
    "privacy_policy_links_other_sites_desc1": "Webové stránky Menuonline mohou obsahovat odkazy na jiné stránky. Mějte prosím na paměti, že Menuonline nenese odpovědnost za postupy ochrany osobních údajů jiných stránek. Doporučujeme našim majitelům účtů, aby si byli vědomi toho, že když opustí naše stránky, měli by si důkladně přečíst prohlášení o soukromých zásadách každé webové stránky, která shromažďuje osobní údaje.",
    "privacy_policy_links_other_sites_desc2": "Tyto zásady ochrany osobních údajů se vztahují výhradně na námi shromážděné informace.",
    "privacy_policy_links_other_sites_desc3": "Pokud použijete odkaz pro přechod z webu Menuonline k nákupu produktů nebo služeb, dokončujete nákupní transakci podle pravidel a předpisů společnosti prodejce, nikoli společnosti Menuonline.",
    "privacy_policy_cookies_title": "COOKIES SOUKROMÉ ZÁSADY",
    "privacy_policy_cookies_desc1": "Webové stránky a služby Menuonline používají soubory cookie ke shromažďování a ukládání určitých informací v souladu s těmito zásadami. „Cookie“ je malý textový soubor odeslaný z webové stránky a uložený na počítači uživatele jeho webovým prohlížečem. Při návštěvě webu, který používá soubory cookie, se do vašeho počítače nebo mobilního zařízení stáhne soubor cookie. Při příští návštěvě tohoto webu si vaše zařízení zapamatuje užitečné informace, jako jsou preference, navštívené stránky nebo možnosti přihlášení.",
    "privacy_policy_cookies_desc2": "Soubory cookie se široce používají k přidávání funkcí na webové stránky nebo k zajištění jejich efektivnějšího fungování. Naše stránky se spoléhají na soubory cookie k optimalizaci uživatelské zkušenosti a zajištění správného fungování služeb stránek.",
    "privacy_policy_cookies_desc3": "Většina webových prohlížečů umožňuje určitou kontrolu k omezení nebo blokování souborů cookie prostřednictvím nastavení prohlížeče, pokud však soubory cookie zakážete, může to ovlivnit vaši schopnost používat určité části našich webových stránek nebo služeb.",
    "privacy_policy_cookies_analytical_purpose": "<strong> Analytické účely: <\/strong> Soubory cookie používáme k analýze aktivity držitele účtu za účelem zlepšení našich webových stránek. Soubory cookie můžeme například použít k prohlížení souhrnných vzorců, jako jsou funkce, které používají majitelé účtů. Tuto analýzu můžeme použít k získání informací o tom, jak zlepšit funkčnost a uživatelskou zkušenost našich webových stránek.",
    "privacy_policy_cookies_preferences_user_experience": "<strong> Vaše předvolby a uživatelská zkušenost: <\/strong> Soubory cookie používáme ke shromažďování určitých informací o návštěvnících a majitelích účtů, jako je typ prohlížeče, server, jazykové preference a nastavení země, abychom na našich webových stránkách uložili preference držitele účtu, aby byl zážitek držitele účtu konzistentnější a pohodlnější. Soubory cookie používáme k udržení stavu vašeho přihlášení, když opakovaně navštěvujete naše webové stránky.",
    "privacy_policy_cookies_targeted_ads": "<strong> Poskytujte služby měření a lépe cílené reklamy a marketing: <\/strong> Používáme soubory cookie, web beacons a další technologie úložiště od partnerů třetích stran, jako je Google a Facebook, pro služby měření, lepší cílení reklam a pro marketingové účely. To se děje, když navštívíte naše webové stránky. Tyto soubory cookie, webové majáky a další technologie ukládání nám umožňují zobrazovat vám propagační materiál Menuonline na jiných stránkách, které navštívíte na internetu.",
    "privacy_policy_security_title": "ZABEZPEČENÍ",
    "privacy_policy_security_desc1": "Společnost Menuonline se snaží chránit důvěrné informace všech typů, včetně osobních údajů, pomocí organizačních, fyzických, mechanických a elektronických zabezpečení odpovídajících citlivosti informací. Naše servery se nacházejí v Kanadě a dodržují průmyslové standardy týkající se zabezpečení.",
    "privacy_policy_security_desc2": "Upozorňujeme však, že nemůžeme zaručit, že opatření, která udržujeme, zaručí bezpečnost informací.",
    "privacy_policy_retention_title": "ZACHOVÁNÍ",
    "privacy_policy_retention_desc": "Osobní údaje o majitelích účtů a kontaktech uchováváme tak dlouho, jak je to nutné pro účely uvedené v těchto zásadách ochrany soukromí a v souladu s ustanoveními o uchovávání v Podmínkách použití.",
    "privacy_policy_access_questions_title": "PŘÍSTUP, OTÁZKY A OBAVY",
    "privacy_policy_access_questions_desc": "Jednotlivec, který zaslal své osobní údaje společnosti Menuonline, může podat písemnou žádost o přístup nebo opravu svých osobních údajů uchovávaných společností Menuonline.",
    "terms_obj": {
        "terms_conditions_title": "OBCHODNÍ PODMÍNKY",
        "terms_conditions_subtitle": "Smluvní podmínky pro nové a stávající zákazníky",
        "terms_conditions_desc1": "NÁSLEDUJÍCÍ KLÍČOVÉ BODY (VČETNĚ REFERENCÍ NA ČÁSTI) JSOU POSKYTOVÁNY POUZE PRO VAŠE POHODLÍ A NENAHRAZUJÍ ÚPLNÉ SMLUVNÍ PODMÍNKY. JE VAŠÍ ODPOVĚDNOSTÍ PŘEČÍST SI TYTO PODMÍNKY PŘED POUŽITÍM SLUŽBY Menuonline.",
        "terms_conditions_desc2": "Používání Menuonline znamená, že souhlasíte s Podmínkami a prohlašujete a zaručujete, že k tomu máte oprávnění vaší organizací nebo společností. (Viz část 3 „Jak to funguje“)",
        "terms_conditions_desc3": "Menuonline je marketingová služba založená na povoleních. Záměrné rozesílání „spamu“ nebo porušení Podmínek bude mít za následek uzavření vašeho účtu. (Viz část 16 „Přijatelné používání služby“)",
        "terms_conditions_desc4": "Data, která hostujete v Menuonline, jako jsou záznamy kontaktů, obsah e-mailů a informace, které shromažďujete prostřednictvím Menuonline, patří vám. (Viz s.14 „Co vlastníte?“).",
        "terms_conditions_desc5": "Software Menuonline a obsah našich webových stránek patří nám. (Viz s.13 „Co vlastníme?“)",
        "terms_conditions_desc6": "Kromě vašich vlastních uživatelů mohou data nebo nepublikovaný obsah, který hostujete prostřednictvím Menuonline, prohlížet pouze zaměstnanci a partneři Menuonline, kteří podepsali dohody o mlčenlivosti. (Viz s.14 „Co vlastníte?“).",
        "terms_conditions_desc7": "Veškeré osobní údaje (PII) jsou uchovávány pouze na serverech umístěných v Kanadě. (Viz s.14 „Co vlastníte?“).",
        "terms_conditions_desc8": "Služba Menuonline podléhá zákonům Kanady a provincie Ontario. (Viz § 24 „Rozhodné právo“)",
        "terms_conditions_desc9": "Jakékoli žádosti o vaše data nebo obsah od jednotlivců, policie nebo jiných regulačních orgánů z Kanady nebo mimo ni budou vždy předány vám. Jedinou výjimkou by bylo, pokud by to znamenalo porušení kanadského nebo ontarijského práva; ve všech případech se však budeme tvrdě bránit jakémukoli uvolnění vašich dat kýmkoli jiným než vámi. (Viz s.14 „Co vlastníte?“).",
        "terms_conditions_desc10": "Zabezpečení menuonline je jen tak dobré, jak dobrá je vaše správa uživatelských jmen a hesel. Uživatelské účty Menuonline neúčtujeme, proto vytvořte pro každého uživatele samostatný a hesla udržujte v tajnosti. Jste zodpovědní za svůj účet. (Viz část 3 „Jak to funguje“)",
        "terms_conditions_desc11": "Svůj účet můžete kdykoli zrušit. V případě zrušení neposkytujeme náhradu za nevyužitý kredit. (Viz část 3 „Jak to funguje“)",
        "terms_conditions_desc12": "Váš účet můžeme kdykoli zrušit. Pokud zrušíme váš účet, poskytujeme vrácení peněz za e-mailové kredity a nevyužité služby. (Viz část 3 „Jak to funguje“)",
        "terms_conditions_desc13": "Vaše data budou po zrušení vymazána a mohou být také smazána, pokud se uživatel účtu nepřihlásí po dobu 12 měsíců nebo déle. Budeme udržovat agregovaná data z vašeho účtu, která neobsahují osobně identifikovatelné informace pro statistické účely. (Viz část 3 „Jak to funguje“)",
        "terms_conditions_desc14": "E-mailové kredity se nakupují na základě průběžných plateb a jejich platnost nikdy nevyprší. Jedinou výjimkou je situace, kdy jste se ke svému účtu nepřihlásili po dobu 12 měsíců. V tomto případě si vyhrazujeme právo smazat váš účet, vaše data a související kredity. (Viz bod 10 „Co platím za Službu?“)",
        "terms_conditions_desc15": "Usilovně pracujeme na tom, abychom zajistili, že Menuonline bude vždy funkční, a abychom vás předem informovali, pokud potřebujeme dočasně pozastavit službu z důvodu údržby. Nezaručujeme však, že služba bude vždy funkční a neneseme odpovědnost za jakékoli ztráty, které utrpíte používáním Menuonline, včetně ztrát na vaší straně, pokud systém neběží v době, kdy jej potřebujete. (Viz s.17 „Odmítnutí odpovědnosti“).",
        "terms_conditions_desc16": "Nesledujeme váš obsah ani to, komu posíláte zprávy. Stížnosti a další problémy vzniklé vaší činností však sledujeme. Příliš mnoho stížností nebo problémů může mít za následek zrušení vaší služby podle našeho uvážení. (Viz část 7 „Monitorování“)",
        "terms_conditions_desc17": "Jsme odhodláni poskytovat vynikající podporu produktů, abychom vám pomohli stát se soběstačnými s Menuonline. Poskytujeme podporu ve formě dokumentace a videa na našich webových stránkách, v systému lístků podpory Menuonline a telefonicky. Za podporu produktu se neplatí; vyhrazujeme si však právo ukončit podporu pro konkrétní klientské účty podle našeho uvážení. (Viz část 5 „Podpora“)",
        "terms_conditions_desc18": "Produktové služby jsou pro ty chvíle, kdy chcete, abychom nějakou práci související s Menuonline dělali my, a ne sami. Naše sazba za produktové služby je 135 USD za hodinu. Poskytujeme také poradenské služby za vyšší ceny. Vyhrazujeme si právo naše sazby kdykoli změnit. (Viz část 6 „Produkty a poradenské služby“)",
        "terms_conditions_desc19": "Budeme vás informovat, pokud se budeme domnívat, že došlo k narušení vašich údajů, a vynaložíme úsilí poskytnout související podrobnosti a informace. (Viz s.14 „Co vlastníte?“)",
        "terms_conditions_desc20": "Vyhrazujeme si právo tuto smlouvu kdykoli změnit. Pokusíme se vás informovat o změnách podmínek, ale používání služby znamená, že souhlasíte se změnami podmínek. (Viz část 9 „Změny“).",
        "terms_conditions_desc21": "Podmínky a naše zásady ochrany osobních údajů byly navrženy pouze v angličtině a souhlasíte s tím, že anglická jazyková verze podmínek a zásad ochrany osobních údajů bude mít prioritu v případě jakéhokoli rozporu s jakoukoli přeloženou verzí.",
        "terms_conditions_mycircle": "SMLUVNÍ PODMÍNKY MYCIRCLE",
        "terms_conditions_last_update": "POSLEDNÍ AKTUALIZACE: 14. ledna 2020",
        "terms_conditions_usage_agreement": "Před použitím této webové stránky (dále jen „stránka“) si pozorně přečtěte tyto podmínky služby (dále jen „Podmínky“). Tyto podmínky osvobozují společnost Menuonline („Menuonline“, „my“ nebo „nás“) a další z odpovědnosti a\/nebo omezují naši a jejich odpovědnost a obsahují další důležitá ustanovení, která se vztahují na vaše používání těchto stránek.",
        "terms_conditions_acceptance": "Návštěvou nebo používáním tohoto webu nebo služby souhlasíte svým jménem a jménem jakékoli organizace, jejímž jménem můžete jednat (dále společně jen „vy“), přijmout a dodržovat tyto podmínky pro každé použití a každou návštěvu tohoto webu.",
        "terms_conditions_services": "1. Naše služby.",
        "terms_conditions_services_desc": "Služby, které nabízíme prostřednictvím Stránek, jsou službou, která vám umožňuje vytvářet, odesílat a spravovat e-mailové zprávy (dále jen „E-maily“) příjemcům dle vašeho výběru (dále jen „Služba“).",
        "terms_conditions_license": "2. Licence.",
        "terms_conditions_license_desc": "V souladu s těmito Podmínkami vám Menuonline tímto uděluje nevýhradní, omezenou a nepřenosnou licenci během Období k prohlížení Stránky a používání Služby tak, jak to umožňují funkce Služby. Vyhrazujeme si všechna práva, která zde nejsou výslovně udělena, ve Službě a Obsahu Menuonline (jak je definováno níže).",
        "terms_conditions_account": "3. Jak to funguje.",
        "terms_conditions_account_desc": "Chcete-li používat službu, musíte si zaregistrovat účet. Váš účet je zdarma. Váš účet vám umožňuje přístup ke Službě a „funkcím“, které můžeme čas od času zřídit a udržovat. Abyste měli nárok na účet, musíte:",
        "terms_conditions_account_requirements": [
            "být starší osmnácti (18) let a být schopen uzavírat smlouvy;",
            "dokončit registrační proces, který jsme stanovili;",
            "souhlasit s těmito Podmínkami;",
            "uveďte pravdivé, úplné a aktuální kontaktní a fakturační\/platební údaje."
        ],
        "responsibility": "Odpovědnost.",
        "responsibilityText": "Jste výhradně odpovědní za aktivitu, ke které dochází na vašem účtu, a musíte udržovat své heslo k účtu důvěrné a bezpečné. Účty jsou zdarma, proto vám doporučujeme, abyste si pro každého uživatele zřídili jiný účet. Jste také odpovědní za jakýkoli účet, ke kterému máte přístup, bez ohledu na to, zda jste použití tohoto účtu povolili či nikoli. Souhlasíte, že nesete výhradní odpovědnost za své interakce s těmi, kterým posíláte e-maily, a že e-maily budete posílat pouze těm lidem, od kterých k tomu máte souhlas. Musíte nás okamžitě informovat o jakémkoli neoprávněném použití vašich účtů.",
        "compliance_with_CASL": "Soulad s kanadskou legislativou proti spamu (CASL).",
        "compliance_with_CASL_text": "Role Menuonline je usnadňovat správu nastavení souhlasu, nikoli vynucovat legislativu. Je vaší odpovědností dodržovat CASL. Pomůžeme vám nastavením výchozí funkčnosti a usnadněním získání a aktualizace zdokumentovaného výslovného souhlasu a správou pohyblivých dat vypršení platnosti předpokládaného souhlasu. Máte plnou kontrolu nad správou souhlasu pro vaši databázi; stav souhlasu pro všechny své kontakty můžete kdykoli hromadně aktualizovat, přepsat výchozí nastavení a deaktivovat automatické vypršení stavu předpokládaného souhlasu.",
        "closing_account": "Uzavření vašeho účtu.",
        "closing_account_text": "Vy nebo Menuonline můžete tuto smlouvu kdykoli a z jakéhokoli důvodu ukončit zasláním oznámení druhé straně. Pokud poskytnete jakékoli informace, které jsou nepravdivé, nepřesné, neaktuální nebo neúplné, nebo použijete Službu způsobem, který porušuje tyto Podmínky a ustanovení, máme právo okamžitě pozastavit váš účet a ukončit váš přístup ke Službě kromě jakýchkoli jiných opravných prostředků, které máme k dispozici. Pokud nebudete svůj účet používat po dobu dvanácti (12) měsíců, můžeme váš účet zrušit. Pokud z jakéhokoli důvodu zrušíme váš účet, vrátíme vám všechny zbývající kredity. Po ukončení trvale smažeme váš účet a všechna data s ním spojená, včetně e-mailů, které jste odeslali. Můžeme uchovávat agregovaná data z vašeho účtu, která neobsahují osobně identifikovatelné informace pro statistické účely.",
        "emailing_content": "Zasílání obsahu e-mailem.",
        "emailing_content_text": "Při interakci s Menuonline prostřednictvím tohoto webu nebo služby souhlasíte s poskytováním pravdivých a přesných informací. Dále chápete, že poskytování důvěrných informací prostřednictvím e-mailu je nebezpečný postup. Chápete, že jste výhradně odpovědní za vše, co pošlete komukoli, kdo používá naši službu. účely.",
        "support": "Podpora.",
        "support_text": "Poskytujeme vám podporu, která vám pomůže stát se soběstačnými se Službou. Poskytujeme vám podporu ve formě dokumentace a videí na Stránkách, v systému lístků podpory Menuonline a po telefonu. Můžete nás kontaktovat kliknutím na záložku „Podpora“ v horní části webu. Za podporu produktu se neplatí; vyhrazujeme si však právo ukončit podporu pro konkrétní klientské účty podle našeho uvážení.",
        "product_and_consulting_services": "Produktové a poradenské služby.",
        "product_and_consulting_services_text": "Produktové služby jsou pro ty chvíle, kdy chcete, abychom nějakou práci související s Menuonline dělali my, a ne sami. Naše sazba za produktové služby je 125 USD za hodinu. Poskytujeme také poradenské služby za vyšší ceny. Vyhrazujeme si právo naše sazby kdykoli změnit.",
        "monitoring": "Sledování.",
        "monitoring_text": "Nebudeme monitorovat ani posuzovat obsah informací přenášených prostřednictvím Služby, ale prošetříme, zda obdržíme stížnosti na možné nevhodné použití. Společnost Menuonline může kdykoli a na základě vlastního uvážení rozhodnout, zda konkrétní použití jsou nebo nejsou vhodná, a to s upozorněním nebo bez něj v souladu s následujícími pokyny.",
        "term": "Období.",
        "term_text": "Období začíná okamžikem, kdy si u nás zaregistrujete účet, a pokračuje, dokud Službu používáte. Prohlašujete a zaručujete, že máte oprávnění a schopnost zaregistrovat si pro nás účet (včetně případů, kdy se zaregistrujete jménem organizace).",
        "changes": "Změny.",
        "changes_text": "Období začíná okamžikem, kdy si u nás zaregistrujete účet, a pokračuje, dokud Službu používáte. Prohlašujete a zaručujete, že máte oprávnění a schopnost zaregistrovat si pro nás účet (včetně případů, kdy se zaregistrujete jménem organizace).",
        "payment": "PLATBA",
        "what_do_i_pay_for_service": "Co platím za Službu?.",
        "what_do_i_pay_for_service_text": "Můžete si zakoupit předplacené kredity pro zasílání e-mailu („Kredity“) prostřednictvím naší Služby, jak podrobně popisujeme na stránce „Ceny“ na našem webu. Platnost kreditů nikdy nevyprší a za tyto kredity nejsou účtovány žádné opakované poplatky. Jedinou výjimkou je, že pokud nebudete svůj účet používat po dobu dvanácti (12) měsíců, můžeme váš účet a nevyužité kredity zrušit. Naše ceny můžeme kdykoli změnit aktualizací cenových stránek na našem webu, což se bude vztahovat na váš příští nákup kreditů.",
        "how_do_i_pay_for_service": "Jak za Službu zaplatím?.",
        "how_do_i_pay_for_service_text": "Za kredity můžete platit jakoukoli hlavní kreditní kartou. Způsoby platby, které přijímáme, můžeme kdykoli změnit.",
        "can_i_get_refund": "Mohu získat náhradu?.",
        "can_i_get_refund_text": "Částky, které jste zaplatili pomocí Služby, jsou nevratné, pokud svůj účet zrušíte. Pokud zrušíme váš účet, vrátíme vám všechny zbývající kredity.",
        "rights": "PRÁVA",
        "what_do_we_own": "Co vlastníme?.",
        "what_do_we_own_text": "S výjimkou obsahu, který nám poskytnete (včetně, bez omezení, záznamů kontaktů, obsahu e-mailů a informací, které shromažďujete prostřednictvím Služby) („Váš obsah“), všechny materiály obsažené na našem webu, službě a všech materiálech na nich obsažených nebo jimi přenesených, včetně, bez omezení, softwaru, obrázků, textu, grafiky, ilustrací, log, patentů, ochranných známek, servisních značek, fotografií, zvuku, videa, hudby a veškerého obsahu souvisejícího s duševním vlastnictvím („obsah související s duševním vlastnictvím“) k nim) jsou vlastněny námi nebo našimi poskytovateli licencí.",
        "what_do_you_own": "Co vlastníte?",
        "what_do_you_own_text": "Nenárokujeme si žádná vlastnická práva na váš obsah. Váš obsah je váš a vy si ponecháváte vlastnictví svého obsahu, který nahrajete do služby. Sdílením svého obsahu prostřednictvím služby souhlasíte s tím, že umožníte ostatním prohlížet, upravovat a\/nebo sdílet váš obsah v souladu s vaším nastavením a těmito smluvními podmínkami. Prohlašujete a zaručujete, že buď vlastníte, nebo máte oprávnění k použití veškerého materiálu, který používáte ve svých e-mailech. Vaše materiály můžeme používat nebo zveřejňovat pouze tak, jak je popsáno v těchto Podmínkách a v našich Zásadách ochrany osobních údajů.",
        "privacy_statement": "Menuonline respektuje soukromí svých uživatelů. Jakékoli osobní údaje, které Menuonline shromažďuje prostřednictvím tohoto webu nebo služby, podléhají Prohlášení o ochraně osobních údajů Menuonline, které je odkazem začleněno do těchto podmínek. Vaše údaje můžeme používat a zveřejňovat v souladu s naším prohlášením o ochraně osobních údajů.",
        "access_to_your_content": "Společnost Menuonline neposkytne přístup k vašemu obsahu nikomu s výjimkou zaměstnanců a dodavatelů společnosti Menuonline, kteří s námi uzavřeli příslušné dohody o mlčenlivosti nebo máme váš souhlas.",
        "governmentRequest": "Pokud od vládního nebo regulačního úřadu obdržíme žádost o přístup k vašemu obsahu, upozorníme vás na tuto žádost a požádáme vás o souhlas dříve, než jakýkoli váš obsah zveřejníme. Budeme odolávat jakýmkoli žádostem o váš obsah a nezveřejníme váš obsah v reakci na tento typ žádosti bez vašeho svolení nebo požadavku soudu příslušné jurisdikce nebo příslušného regulačního orgánu.",
        "breachNotification": "V případě, že se dozvíme, že váš obsah byl vystaven neoprávněnému přístupu, použití, kopírování, krádeži, ztrátě nebo použití nebo zveřejnění v rozporu s těmito podmínkami (dále jen „porušení“), neprodleně vás upozorníme a budeme vás informovat o tom, k čemu došlo. Souhlasíte s tím, že Porušení bude řešeno v souladu s platnými zákony, a pokud budeme potřebovat, abyste informovali členy vašich e-mailových distribučních seznamů, budete souhlasit, že tak učiníte neprodleně a podle našich pokynů.",
        "contentRetention": "Váš obsah budeme uchovávat a používat a přenášet v Kanadě s výjimkou vašeho předchozího písemného souhlasu nebo pokynů (například pokud se rozhodnete poslat svůj obsah jednotlivcům mimo Kanadu). Servery používané k poskytování Služby se nacházejí v Kanadě.",
        "accessTrouble": "Pokud máte potíže s přístupem ke svému obsahu, Menuonline vám pomůže získat váš obsah na základě času a materiálů.",
        "reportingAbuse": "Pokud se domníváte, že někdo porušuje kteroukoli z těchto podmínek, neprodleně nás o tom informujte. Pokud jste obdrželi spamy, o kterých si myslíte, že pocházejí od jiného uživatele služby, pokud si myslíte, že jiný uživatel služby zveřejnil materiál, který porušuje jakákoli autorská práva, nebo pokud si myslíte, že jiný uživatel služby porušuje tyto podmínky, kontaktujte nás na adrese Support@menuonline.com.",
        "acceptableUse": "Prohlašujete a zaručujete, že vaše používání služby bude v souladu se všemi platnými zákony a předpisy. Souhlasíte s tím, že jste odpovědní za rozhodnutí, zda bude služba v souladu s vaší jurisdikcí a zda je pro vás vhodná k použití. Pro větší jistotu souhlasíte s tím, že budete (a souhlasíte s tím, že zajistíte, aby vaši zaměstnanci, zástupci a oprávnění uživatelé) ...",
        "exportLaws": "Neprohlašujeme, že stránky nebo služba jsou vhodné nebo dostupné pro použití mimo Kanadu a přístup k nim z území, kde je jejich obsah nezákonný, je zakázán. Nesmíte používat nebo exportovat nebo reexportovat obsah Menuonline nebo jiné materiály na této stránce nebo jakoukoli kopii nebo úpravu v rozporu s jakýmikoli platnými zákony nebo předpisy, včetně, bez omezení, kanadských a amerických exportních zákonů a předpisů.",
        "disclaimers": "Služba a tato stránka jsou poskytovány „tak, jak jsou“ bez jakýchkoli prohlášení, záruk nebo podmínek jakéhokoli druhu a společnost Menuonline výslovně odmítá, v plném rozsahu povoleném příslušnými zákony, všechna prohlášení, záruky a podmínky, vyjádřené nebo předpokládané, na základě zákona nebo jinak, včetně, bez omezení, jakýchkoli předpokládaných záruk a podmínek obchodovatelnosti, vhodnosti nebo práv třetích stran k použití, které není v rozporu s...",
        "limitLiability": "Souhrnná odpovědnost Menuonline podle této Smlouvy, ať už ve smlouvě nebo v deliktu (včetně nedbalosti), v důsledku porušení záruky, objektivní odpovědnosti nebo jakékoli jiné teorie odpovědnosti, bude omezena na přímé škody ve výši nepřesahující celkové poplatky zaplacené společnosti Menuonline podle těchto Smluvních podmínek za dvanáct (12) měsíců bezprostředně před měsícem, ve kterém došlo k poslední události vedoucí k odpovědnosti.",
        "liabilityExclusions": "Žádný z Menuonline, jeho přidružených společností nebo jejich příslušných ředitelů, úředníků, zaměstnanců, agentů nebo jiných zástupců (souhrnně „Zástupci Menuonline“) ani provozovatelů jakýchkoli připojených sítí nebude mít žádnou odpovědnost nebo odpovědnost v souvislosti s touto stránkou nebo službou za: (i) jakékoli nepřímé, následné, náhodné, exemplární, represivní nebo zvláštní škody; nebo (ii) za jakékoli škody, ať už přímé, nepřímé, následné, náhodné, exemplární, trestní nebo zvláštní, charakterizované jako ušlý příjem, ušlé úspory nebo výnosy nebo ušlý zisk...",
        "indemnity": "Odškodníte a ochráníte Menuonline a\/nebo zástupce Menuonline (dále jen „odškodněné strany Menuonline“) před a proti jakýmkoli nárokům vzneseným třetími stranami a veškerým pokutám nebo sankcím uděleným proti nám, které vyplývají z vašeho používání informací přístupných z tohoto webu nebo vašeho používání služby a jakéhokoli porušení těchto podmínek...",
        "ipInfringement": "Společnost Menuonline vás bude bránit, odškodní vás a ochrání vás před všemi nároky, požadavky, žalobami, žalobami nebo řízeními vznesenými nebo vznesenými proti vám třetí stranou, která tvrdí, že váš přístup ke Službě nebo její používání v souladu s touto Smlouvou porušuje práva duševního vlastnictví podle kanadského práva třetí strany (\"Nárok na porušení IP\")...",
        "equitableRelief": "Pokud porušíte tyto Podmínky, můžeme požádat o soudní zákaz (což znamená, že můžeme požádat o soudní příkaz, abychom vás zastavili) nebo jinou spravedlivou nápravu. Souhlasíte s tím, že to bude dodatkem a bez újmy jakýchkoliv dalších práv, která můžeme mít ze zákona nebo na základě spravedlnosti.",
        "linksToOtherSites": "Pokud poskytujeme jakékoli odkazy na webové stránky třetích stran, jsou poskytovány pouze pro pohodlí. Pokud použijete tyto odkazy, opustíte naše stránky. Tyto stránky třetích stran nekontrolujeme ani je nepodporujeme. Souhlasíte s tím, že neneseme odpovědnost za žádné z těchto stránek třetích stran ani jejich obsah...",
        "thirdPartyRequests": "Pokud musíme poskytnout informace v reakci na právní nebo vládní žádost týkající se vašeho účtu nebo vašeho používání služby, souhlasíte s tím, že vám můžeme účtovat naše náklady. Tyto poplatky mohou zahrnovat naše právní poplatky a náklady na čas našeho zaměstnance strávený přípravou odpovědi na žádost.",
        "assignment": "Tuto smlouvu a jakákoli práva a licence udělené podle ní nesmíte převést ani postoupit vy, ale společnost Menuonline je může bez omezení postoupit na základě přiměřeného předchozího upozornění.",
        "governingLaw": "Tyto podmínky se budou řídit a vykládat v souladu se všemi platnými federálními zákony a zákony Ontaria bez ohledu na jejich ustanovení o konfliktu zákonů. Souhlasíte, že se podřídíte výhradní jurisdikci soudů Ontaria pro rozhodování o jakémkoli sporu týkajícím se těchto Podmínek.",
        "severability": "Pokud z jakéhokoli důvodu soud příslušné jurisdikce shledá jakékoli ustanovení těchto podmínek nebo jejich část nezákonné nebo z jakéhokoli důvodu nevymahatelné, bude toto ustanovení vymáháno v maximální možné míře, aby bylo možné uvést v platnost záměr těchto podmínek, a zbytek těchto podmínek bude nadále plně platný a účinný a zůstane vymahatelný.",
        "waiver": "Jakékoli zřeknutí se nebo souhlas s odchýlením se od požadavků těchto Podmínek bude účinný pouze v případě, že bude písemný a podepsaný námi, a pouze v konkrétním případě a pro konkrétní účel, pro který byl udělen. Naše neuplatnění nebo naše zpoždění při výkonu jakéhokoli práva podle těchto podmínek nebude fungovat jako vzdání se našich práv.",
        "furtherAssurances": "Každá strana souhlasí s tím, že podepíše a poskytne všechny dokumenty a podnikne jakékoli kroky (nebo způsobí provedení těchto kroků) nezbytné ke splnění svých závazků podle těchto podmínek.",
        "notices": "Všechna oznámení pro Menuonline musí být písemná a musí nám být zaslána e-mailem. Oznámení nám musí být zaslána na vědomí našim zástupcům zákaznických služeb na support@menuonline.com. Oznámení vám mohou být zaslána na adresu nebo e-mailovou adresu, kterou jste uvedli v rámci své registrace. Kromě toho můžeme prostřednictvím této stránky vysílat oznámení nebo zprávy, abychom vás informovali o změnách na této stránce nebo jiných důležitých záležitostech, a tato vysílání budou představovat oznámení pro vás v době, kdy je zveřejníme.",
        "entireAgreement": "Tyto podmínky a naše prohlášení o ochraně osobních údajů tvoří úplnou dohodu mezi vámi a námi, pokud jde o vaše používání stránek a služby, a nahrazují všechny předchozí dohody, prohlášení a ujednání mezi společností Menuonline a vámi s ohledem na tento předmět. V případě jakéhokoli rozporu mezi Prohlášením o ochraně osobních údajů a těmito Podmínkami budou platit tyto Podmínky.",
        "language": "Strany výslovně požádaly a požadovaly, aby tyto podmínky, naše zásady ochrany osobních údajů a veškeré související dokumenty byly vypracovány v angličtině. Les parties conviennent et exigent expressément que ce Contrat et tous les documents qui s'y rapportent soient rédigés en anglais. Souhlasíte s tím, že anglická jazyková verze těchto podmínek a zásad ochrany osobních údajů bude mít přednost v případě jakéhokoli rozporu s jakoukoli přeloženou verzí.",
        "survival": "Všechna ustanovení těchto Podmínek, která mají svou povahou nebo importem přežít i po vypršení nebo ukončení těchto Podmínek, zůstávají v platnosti, včetně, bez omezení, oddílů 17-19."
    },
    "collect_additional_info": "Chcete shromáždit další informace?",
    "additionalInfoChoice": "Vyberte další informace, které chcete shromažďovat.",
    "additional_info_yes": "Ano",
    "additional_info_noSkip": "Ne, Skipe",
    "additional_info_label": "Další informace",
    "confirm_business_location": "Nejprve potvrďte umístění vaší firmy",
    "custom_get_more_reviews_form": "Vlastní formulář pro získání více recenzí",
    "set_up_custom_form": "Nastavte si svůj vlastní Získejte více recenzí Formulář podle skupiny",
    "reputation_name_label": "Získejte další recenze Jméno",
    "reputation_name_placeholder": "Zadejte jméno reputace",
    "group_label": "Skupina",
    "group_desc_title": "Název popisu skupiny",
    "group_desc": "Popis skupiny",
    "select_group_placeholder": "Vyberte možnost Skupina",
    "required_group_name": "Název skupiny je povinný",
    "before_asking_review": "Než požádáme zákazníky o recenzi, položme jim 1 až 4 otázky.",
    "feel_free_edit": "Neváhejte a upravte podle potřeby.",
    "incentive_settings": "Nastavení pobídek",
    "incentivize_customer": "Chcete svého zákazníka motivovat k napsání recenze?",
    "choose_incentive": "Vyberte pobídku, kterou chcete doporučit.",
    "scan_qr_feedback": "Naskenujte QR kód a podělte se s námi o vaši cennou zpětnou vazbu týkající se vašich zkušeností.",
    "qr_code": "QR kód",
    "scan_me": "SKENOVAT MĚ!",
    "or_title": "NEBO",
    "send_otp_title": "Odeslat OTP",
    "sign_in_title": "Přihlaste se",
    "email_addresss_title": "E-mailová adresa",
    "reset_password_title": "Obnovit heslo",
    "remember_password_title": "Zapamatované heslo?",
    "digit_code_title": "Zadejte 4místný kód, který byl odeslán ",
    "not_received_title": "Neobdržel jsem ověřovací kód!",
    "resend_title": "Odešlete prosím znovu",
    "sign_up_form": "Vytvořte si svůj účet",
    "sign_up_sub_title": "Přihlaste se nyní a získejte přístup k nejnovějším statistikám",
    "already_registered_title": "Máte již účet v Menuonline?",
    "reserved_title": "Autorská práva ©2023 Menuonline. Všechna práva vyhrazena.",
    "terms_title": "Podmínky služby",
    "policy_title": "Zásady ochrany osobních údajů",
    "sign_up_title": "Zaregistrujte se",
    "terms_conditions_title": "Smluvní podmínky",
    "business_type_title": "Typ podnikání",
    "business_type_sub_title": "Laskavě vyberte jeden z typů podnikání, který lépe popisuje vaši firmu!",
    "salon_title": "Salon",
    "restaurant_title": "Restaurace",
    "pizza_store_title": "Prodejna pizzy",
    "food_truck_title": "Food Truck",
    "check_domain_title": "Zkontrolujte název domény",
    "start_exploring_title": "Začněte objevovat",
    "sign_in_form": "Přihlaste se ke svému účtu",
    "sign_in_sub_title": "Přihlaste se nyní a získejte přístup k nejnovějším statistikám",
    "remember_title": "Pamatuj si mě",
    "otp_title": "OTP",
    "password_title": "Heslo",
    "forgot_title": "Zapomněli jste heslo?",
    "not_registered_title": "Nemáte účet?",
    "create_account_title": "Zaregistrujte se nyní",
    "create_form_title": "Vytvořit formulář",
    "first_name_title": "Křestní jméno",
    "last_name_title": "Příjmení",
    "email_title": "E-mail",
    "mobile_number_title": "Mobilní číslo",
    "birthday_title": "Narozeniny",
    "gender_title": "Pohlaví",
    "address_title": "Adresa",
    "short_answer_title": "Krátká odpověď",
    "long_answer_title": "Dlouhá odpověď",
    "single_answer_title": "Jediná odpověď",
    "single_checkbox_title": "Jedno zaškrtávací políčko",
    "multiple_choice_title": "Vícenásobný výběr",
    "drop_down_title": "Rozbalovací seznam",
    "yes_no_title": "ano nebo ne",
    "description_text_title": "Popisný text",
    "file_upload": "Nahrání souboru",
    "reset_password_form": "Obnovte své heslo",
    "reset_password_sub_title": "Rychle obnovte své heslo a získejte přístup ke svému účtu.",
    "required_full_name_title": "Je vyžadováno celé jméno",
    "required_first_name_title": "Jméno je povinné",
    "valid_first_name_title": "Zadejte platné křestní jméno",
    "valid_full_name_title": "Zadejte platné celé jméno",
    "required_block_time": "Je vyžadován blokovací čas",
    "required_last_name_title": "Příjmení je povinné",
    "valid_last_name_title": "Zadejte platné příjmení",
    "valid_mobile_number_title": "Zadejte platné mobilní číslo",
    "required_mobile_number_title": "Je vyžadováno mobilní číslo",
    "required_already_mobile_number_title": "Mobilní číslo je již vyžadováno",
    "valid_otp_title": "Zadejte platné otp",
    "valid_email_title": "Zadejte platný e-mail",
    "required_email_title": "E-mail je povinný",
    "required_description_title": "Popis je povinný",
    "required_description_500_characters": "Zadejte prosím alespoň 500 znaků",
    "required_description_600_characters": "Zadejte prosím maximálně 600 znaků",
    "domain_title": "Název domény",
    "required_password_title": "Je vyžadováno heslo",
    "required_password_regex_title": "Musí obsahovat minimálně 8 znaků, alespoň jedno velké písmeno, jedno malé písmeno, jedno číslo a jeden speciální znak",
    "required_terms_title": "Souhlaste s podmínkami",
    "required_note_title": "Poznámka je povinná",
    "required_birthday_title": "Datum narození je povinné",
    "no_access_title": "Momentálně nemáte přístup. Kontaktujte prosím svého správce",
    "menu_online_open_txt": "OTEVŘENO",
    "loyalty_program_description": "😊 Najděte si svůj ideální věrnostní program a přizpůsobte si ho tak, aby odpovídal vaší značce!",
    "lp_list_title": "Seznam věrnostních děrných štítků",
    "lp_n_title": "Název věrnostního děrného štítku",
    "enter_details_title": "Zadejte podrobnosti",
    "stamp_allowed_title": "Razítko povoleno",
    "stamp_items_title": "Razítko položky",
    "lpn_req_title": "Jméno děrného štítku je povinné",
    "valid_lpn_title": "Zadejte platný název věrnostního děrného štítku",
    "brand_color_error": "Barva značky musí mít přesně 7 znaků",
    "select_dark_brand_color_title": "Vyberte platnou barvu značky",
    "your_brand_color_title": "Barva vaší značky",
    "upload_logo_title": "Nahrát logo",
    "locations_title": "Místa",
    "add_lp_title": "Vytvořit program",
    "location_error": "Vyberte umístění",
    "pvs_title": "Kolik razítek může zákazník získat za návštěvu?",
    "max_apd_title": "Jaký je maximální počet razítek, které může zákazník získat za den?",
    "logo_error": "Logo je povinné",
    "expiry_title": "Nastavte vypršení platnosti odměn",
    "add_more_rewards_title": "+ Přidat další odměny",
    "prev_step_1_title": "Ukažte QR kód, abyste získali body a užili si své odměny.",
    "delete_lp_title": "Tento věrnostní děrný štítek bude trvale smazán.",
    "delete_user_record_title": "Tento uživatelský záznam bude trvale smazán.",
    "redeem_title": "Vykoupit",
    "lp_button_title": "Připojte se k věrnostní děrovací kartě",
    "lp_download_button_title": "Stáhnout Card",
    "step_0_title": "Připojte se nyní, na věrné gurmány, jako jste vy, čekají lahodné odměny.",
    "step_1_title": "Připojili jste se",
    "congrats_step_title": "Nyní máte nárok na pizzu zdarma",
    "reward_title": "Získejte odměny za každou návštěvu",
    "visit_title": "Navštivte a odemkněte",
    "rewards_title": "Odměny",
    "dummy_address": "3730 Frankfort Ave, Louisville Kentucky, Spojené státy americké",
    "loyalty_qr_default_text": "Naskenujte QR kód, připojte se k naší věrnostní kartě a dopřejte si odměny",
    "lp_earn_stamp_txt": "Zákazníci získávají razítka při každé návštěvě.",
    "lp_get_rewards_txt": "Získejte odměny při každé návštěvě",
    "lp_1_time_visit_txt": "1 časová návštěva, kterou dostanete",
    "lp_uppercase_stamp_text": "Razítko",
    "lp_lowercase_stamp_text": "razítko",
    "lp_txt": "Věrnost",
    "lp_per_visit_stamp_validation_msg": "Razítko za návštěvu musí být menší než maximální povolené razítko za den",
    "lp_per_max_stamp_validation_msg": "Maximální povolené razítko za den musí být větší než razítko za návštěvu",
    "lp_valid_value_msg": "Zadejte platnou hodnotu",
    "lp_required_field_msg": "Toto pole je povinné",
    "lp_stamp_required_msg": "Je vyžadováno razítko",
    "lp_valid_stamp_msg": "Zadejte platnou hodnotu razítka",
    "lp_reward_name_required_msg": "Název odměny je povinný",
    "lp_unique_stamp_msg": "Hodnota razítka by měla být jedinečná",
    "lp_benefits": "Věrnostní program zvyšuje opakované návštěvy a příjmy a zároveň poskytuje informace o zákaznících pro personalizovaný marketing",
    "lp_how_customer_txt": "Zde je návod, jak vaši zákazníci",
    "lp_punch_card_txt": "věrnostní děrný štítek",
    "lp_look_txt": "bude vypadat! 😍",
    "lp_desc": "Zákazníci za každou návštěvu získají razítka, a jakmile jich nasbírají dostatek, mohou využít speciální odměny.",
    "lp_edit": "Upravit",
    "lp_looks_good_txt": "Vypadá dobře",
    "lp_customer_view_instruction": "Toto uvidí váš zákazník. Nahrajte své logo a nastavte barevný motiv",
    "lp_customer_earn_stamps": "Umožněte zákazníkům získávat razítka při každé návštěvě a spravovat své odměny",
    "lp_stamp_rewards_setup": "Nastavte odměny za získávání známek, umožněte zákazníkům získat odměny za známky",
    "lp_loyalty_program_setup": "Zadejte název svého věrnostního programu a nastavte místo, kde chcete mít tento program",
    "lp_prev_step1": "Ukažte QR kód, abyste získali body a užili si své odměny.",
    "lp_visit_1_time_txt": "Navštivte 1 Time a získejte",
    "lp_get_free_txt": "Získejte zdarma",
    "lp_visit_allow_txt": "Vist & Unlock",
    "lp_rewards": "Odměny",
    "repsT": "Získejte více recenzí",
    "offSup": "Kancelářské potřeby",
    "repsNxtV": "Při další návštěvě",
    "addRepT": "Přidat Získat další recenze",
    "repEntrCpnTxt": "Zadejte text kupónu",
    "repEntrCpnTxtBtn": "Zadejte text tlačítka kupónu",
    "repMngNxtV": "při příští návštěvě?",
    "repMngWldL": "Chtěli byste získat",
    "discount": "Sleva",
    "repDisTyp": "Typ slevy",
    "repNameT": "Pojmenujte svou pověst",
    "crtRepT": "Vytvořte si pověst",
    "feedBackT": "Zpětná vazba",
    "confLocT": "Potvrďte polohu",
    "businLocT": "Místo podnikání",
    "addInfoT": "Další informace",
    "repNReqT": "Jméno Získat další recenze je povinné",
    "validRepNT": "Zadejte platný název reputace",
    "groupT": "Skupina",
    "groupLDescTitleT": "Automaticky vložit kontakty do skupiny",
    "groupLDescT": "Všichni zákazníci, kteří vyplní formulář, budou přidáni do vybrané skupiny.",
    "que2": "Bylo vaše jídlo dobré?",
    "que3": "Byla naše služba přátelská?",
    "que4": "Byla naše služba rychlá?",
    "addAdditionalT": "Chcete přidat nějaké další informace?",
    "phNoMandT": "Telefonní číslo je povinné!",
    "qrGenT": "Vygenerováno QR",
    "repFDT": "Váš příspěvek pomůže našemu týmu lépe sloužit!",
    "letdiT": "Pojďme na to",
    "tak15secT": "Trvá 15 sekund",
    "defQue": "Chtěli byste čas od času dostávat skvělé nabídky a propagační akce?",
    "defNQue": "Chcete, aby vám někdo odpověděl?",
    "rateOnGoogleDesc": "Byli byste tak laskav a ohodnotili nás na Googlu?",
    "mayBeNT": "Možná příště",
    "yesSureT": "Ano, jistě",
    "valid_name": "Zadejte platný název",
    "nameReq": "Jméno je povinné",
    "backToMainT": "Zpět na hlavní stránku",
    "attachCopT": "Chtěli byste motivovat svého zákazníka?",
    "thankYouT": "Děkuju",
    "lftyT": "Těšíme se na Vaši další návštěvu!",
    "repPreT": "Náhled reputace",
    "offer_exclusive_discounts": "Nabídněte exkluzivní slevy a propagační akce, abyste nalákali své zákazníky.",
    "couponT": "Kupón",
    "locStepDesc": "Potvrďte umístění své firmy",
    "createRepStepDesc": "Vytvořte vlastní formulář reputace podle skupin",
    "feedbackStepDesc": "Sada otázek zpětné vazby od zákazníků",
    "addInfoStepDesc": "Zákaznická pole a nastavení pobídek",
    "FedbkkWDT": "Zpětná vazba s daty",
    "repAnlytT": "Analýza reputace",
    "todayT": "Dnes",
    "rep_last_7_days_t": "Posledních 7 dní",
    "last30DaysT": "Posledních 30 dní",
    "lastMT": "Minulý měsíc",
    "custRangeT": "Vlastní rozsah",
    "queWiseAnltT": "Otázka Wise Analytics",
    "atL1QueErrT": "Vyberte prosím alespoň jednu otázku",
    "deleteRepTitle": "Tato reputace bude trvale smazána.",
    "incvCustT": "Ano, motivujte mého zákazníka",
    "collAddInfoT": "Ano, rád bych shromáždil další informace",
    "totRewT": "Celková recenze",
    "totPosRewT": "Celkem pozitivní recenze",
    "negFeedT": "Negativní zpětná vazba",
    "posFeedT": "Pozitivní zpětná vazba",
    "fineT": "Fine Print",
    "enterFineT": "Zadejte drobně tištěný text",
    "setThemeT": "Nastavit barvu motivu",
    "que1T": "Otázka 1",
    "que2T": "Otázka 2",
    "que3T": "Otázka 3",
    "que4T": "Otázka 4",
    "entMailT": "Zadejte svůj email",
    "reputationQRDefTxt": "Naskenujte QR kód a podělte se s námi o vaši cennou zpětnou vazbu týkající se vašich zkušeností.",
    "selUser": "Vyberte Uživatel",
    "userT": "Uživatel",
    "subUReq": "Vyberte uživatele",
    "updateLoc": "Aktualizovat umístění",
    "leadGenT": "Generování olova",
    "displayT": "Zobrazit",
    "ratingT": "Hodnocení",
    "rep_dashboard_negative_feedback": "Negativní zpětná vazba 0 %",
    "rep_dashboard_position_feedback": "Pozitivní zpětná vazba 0 %",
    "rep_dashboard_total_Feedback_txt": "Celková zpětná vazba",
    "rep_dashboard_anony_Feedback": "Anonymní zpětná vazba",
    "rep_dashboard_Feeedback_Data_txt": "Zpětná vazba s daty",
    "rep_dashboard_review_txt": "Získejte více recenzí",
    "rep_dashboard_total_Review_txt": "Celková recenze",
    "rep_dashboard_total_positive_Review_txt": "Celkem pozitivní recenze",
    "rep_dashboard_negative_feedback_txt": "Negativní zpětná vazba",
    "rep_dashboard_connection_txt": "Spojení",
    "rep_dashboard_question_wise_analytics_txt": "Otázka Wise Analytics",
    "rep_dashboard_date_label": "Vyberte Datum",
    "rep_dashboard_custom_range_txt": "Vlastní rozsah",
    "rep_tlt_min": "REP",
    "emojis": "Emojis",
    "media_text": "Přidat média",
    "custom_field_text": "Vložit vlastní pole",
    "shortlink_text": "Přidat krátký odkaz",
    "use_template_text": "Použijte šablonu",
    "banner_must_have": "Musí mít",
    "res_seamless": "Bezešvé",
    "res_table_reservation": "Rezervace stolu",
    "res_system": "Systém",
    "res_text": "Rezervace",
    "md_easily": "Snadno",
    "md_manage_customers": "Správa zákazníků",
    "md_visits_interactions": "návštěvy a interakce",
    "md_desc": "Usnadněte svým zákazníkům stolování díky rezervaci stolů v reálném čase. Spravujte rezervace bez námahy, zkraťte čekací doby a zajistěte hladký zážitek pro zaměstnance i hosty. Udržujte svou restauraci plně rezervovanou při zachování špičkového servisu a spokojenosti.",
    "engage_convert": "Zapojit a převést SMS",
    "coupon_campaign": "a kupónová kampaň",
    "sms_read_rate": "SMS zprávy jsou čteny 98 % času",
    "real_time_promo": "Poskytujte propagační akce v reálném čase pro okamžitou akci",
    "affordable_reach": "Cenově dostupný způsob, jak oslovit zákazníky se solidní návratností investic",
    "stay_in_touch": "Zůstaňte v kontaktu se svým zákazníkem po celou dobu",
    "bc_engage_customers": "Zapojte zákazníky Převeďte SMS",
    "bc_coupon_campaign": "a kupónová kampaň",
    "bc_sms_read_rate": "SMS zprávy jsou čteny 98 % času",
    "bc_real_time_promo": "Poskytujte propagační akce v reálném čase pro okamžitou akci",
    "bc_affordable_reach": "Cenově dostupný způsob, jak oslovit zákazníky se solidní návratností investic",
    "bc_stay_in_touch": "Zůstaňte v kontaktu se svým zákazníkem po celou dobu",
    "ar_automate_engagement": "Automatizujte zapojení svých klientů",
    "ar_with": "s",
    "ar_with_auto_responders": "Automatické odpovídače",
    "ar_personalized_messages": "Automaticky posílejte personalizované zprávy k narozeninám, přivítejte nové klienty a znovu se spojte s minulými zákazníky. Ušetřete čas a zároveň udržujte každou interakci smysluplnou a včasnou.",
    "sq_custom_forms_tools": "Vlastní formuláře a nástroje – zjednodušit",
    "sq_custom_forms_ci": "Klientské interakce",
    "sq_smart_qr_desc": "Vytvářejte vlastní formuláře, sbírejte elektronické podpisy a využijte chytré QR kódy k zefektivnění vašich obchodních procesů. Přizpůsobte každý nástroj potřebám svých klientů a vylepšete uživatelský zážitek.",
    "gnc_get_new_cus": "Získejte nové zákazníky",
    "gnc_get_customers": "ze sociálních sítí – rychle a snadno!",
    "gnc_social_media_power": "Využijte sílu sociálních platforem k přilákání nových zákazníků a zvýšení prodeje přímo do vaší firmy. Přesměrujte objednávky doručení třetích stran přímo do vašeho obchodu nebo online objednávkové platformy pro maximální kontrolu a výnosy.",
    "wifi_connect_guests": "Spojte hosty s",
    "wifi_smart": "Inteligentní Wi-Fi",
    "wifi_connect_grow": "& Rozšiřte své poznatky",
    "wifi_smart_wifi_desc": "Poskytněte svým zákazníkům bezproblémové připojení Wi-Fi prostřednictvím značkové úvodní obrazovky a zároveň shromažďujte cenná data o zákaznících, abyste zlepšili své marketingové úsilí a zapojení.",
    "wifi_create_splash_page": "Vytvořte úvodní stránku Wifi pro hosty",
    "instant_menu_create": "Vytvořte si svůj",
    "instant_menu_text": "Okamžité menu",
    "instant_menu_wm": "během několika minut",
    "instant_menu_desc": "Vylepšuje zákaznickou zkušenost tím, že umožňuje aktualizace v reálném čase, snadné přizpůsobení a lepší zapojení, což usnadňuje předvádění vašich nabídek a udržuje váš obsah aktuální.",
    "instant_menu_create_digital_menu": "Vytvořte digitální menu",
    "loyalty_program_rewards": "Získejte odměny",
    "loyalty_program_on_visits": "při každé návštěvě",
    "loyalty_program_create": "Vytvořte věrnostní program",
    "reputation_create": "Vytvořte si pověst",
    "reputation_boost": "Zvyšte své",
    "reputation_text": "Pověst",
    "reputation_reviews": "s více recenzemi!",
    "reputation_desc": "Sbírejte cennou zpětnou vazbu od svých zákazníků, abyste zlepšili své služby a pověst. Podělte se o své zkušenosti a pomozte nám růst – na vaší recenzi záleží!",
    "dashboard": "Dashboard",
    "blast_campaign": "Odstřelová kampaň",
    "blast_campaigns": "Odstřelové kampaně",
    "blast_campaign_sub_title": "Spusťte skvělé kampaně a oslovte všechny své klienty několika kliknutími",
    "auto_responders": "Automatické odpovídače",
    "auto_responder": "Automatická odpověď",
    "auto_responder_sub_title": "Snadno použitelné akce založené na akcích pro vaše klienty",
    "smart_qr_form": "Chytrý QR \/ formulář",
    "get_new_customer": "Získejte nového zákazníka",
    "marketing": "Marketing",
    "clients": "klienti",
    "group_data": "Data skupin",
    "clients_data": "Údaje o klientech",
    "account": "Účet",
    "user_acc_info": "Informace o uživatelském účtu",
    "current_plan": "Aktuální plán",
    "payments": "Platby",
    "location": "Umístění",
    "location_list": "Seznam míst",
    "users": "Uživatelé",
    "users_sub_title": "Seznam všech uživatelů a jejich podrobnosti",
    "online_profile": "Online profil",
    "change_password": "Změnit heslo",
    "menu": "Menu",
    "reseller_dashboard": "Panel prodejce",
    "companies": "Společnosti",
    "plans_bought": "Plány koupeny",
    "agreement_t_c": "Smluvní podmínky",
    "wifi_dashboard": "Wifi Dashboard",
    "hot_spot": "Hotspot",
    "splash_pages": "Úvodní stránky",
    "get_more_reviews": "Získejte více recenzí",
    "office_supplies": "Kancelářské potřeby",
    "reservation": "Rezervace",
    "floor_table": "Podlaha a stůl",
    "guest_smart_wifi": "Inteligentní WiFi pro hosty",
    "digital_signage": "Digitální značení",
    "schedule_down": "Rozvrh",
    "business_settings": "Obchodní nastavení",
    "sub_user_login": "Přihlášení poduživatele",
    "help_center": "Centrum nápovědy",
    "switch_to_hub": "Přepněte na Hub",
    "meeting_records": "Záznamy schůzek",
    "logout": "Odhlášení",
    "no_access_text": "Právě teď nemáte přístup. kontaktujte prosím svého správce",
    "menu_item": "POLOŽKA MENU",
    "menu_capital": "MENU",
    "good_day": "Dobrý den",
    "no_permission_text": "K provedení této akce nemáte oprávnění!!",
    "enter_valid_pin": "Zadejte platný PIN",
    "add_reservation": "Přidat rezervaci",
    "edit_reservation": "Upravit rezervaci",
    "per_req": "Je vyžadován počet osob",
    "req_block_time": "Je vyžadován blokovací čas",
    "pending_status": "Čeká na vyřízení",
    "booked_status": "Zarezervováno",
    "completed_status": "Dokončeno",
    "cancelled_status": "Zrušeno",
    "reservation_slot": "Rezervační slot",
    "block_time": "Blokovat čas",
    "slot_time": "Čas rezervačního slotu:  ",
    "template_name_req": "Název šablony je povinný",
    "template_type_req": "Typ šablony je povinný",
    "sms_template_req": "Šablona SMS je povinná",
    "group_req": "Skupina je povinná",
    "valid_date_time": "Zadejte platné datum a čas",
    "valid_group_name": "Zadejte platný název skupiny",
    "reservation_mark_completed": "Tato rezervace bude označena jako dokončená.",
    "customer_name": "Jméno zákazníka",
    "date_time": "Datum Čas",
    "table_no": "Tabulka č",
    "floor": "Patro",
    "customer_name_placeholder": "customer_name",
    "date_time_placeholder": "datum_čas",
    "table_no_placeholder": "tabulka_č",
    "floor_placeholder": "patro",
    "select_floor": "Vyberte Podlaha",
    "select_table": "Vyberte Tabulka",
    "customer_name_macro": "%% customer_name %%",
    "date_time_macro": "%% datum_čas %%",
    "table_no_macro": "%% table_no %%",
    "floor_macro": "%% podlaha %%",
    "template_name": "Název šablony",
    "login_with_4_digit_pin": "Přihlaste se svým 4místným PINem",
    "login_now_to_access_latest_insights": "Přihlaste se nyní a získejte přístup k nejnovějším odznakům",
    "mkt_BS_Title": "Blast SMS",
    "step_1": "Přizpůsobit zprávu",
    "step_2": "Vyberte možnost Publikum",
    "step_3": "Naplánovat zprávu",
    "step_4": "Odeslat zprávu",
    "step_5": "Spravovat zprávu",
    "bs_tab_title": "Souhrnné shrnutí kampaně",
    "default_message": "Ahoj %% jméno %%, Speciální sleva na brunch! Povečeřte s námi ještě dnes a získejte exkluzivní 10% slevu na svůj účet!",
    "bs_Title": "Vyberte typ kampaně",
    "sms_title": "SMS\/MMS",
    "reach_out_to_customer": "Oslovte zákazníky prostřednictvím textových a multimediálních zpráv.",
    "wp_text": "WhatsApp",
    "connect_with_wp": "Spojte se se svými klienty pomocí WhatsApp 💬",
    "send_discount_offers": "Nabídky slev zasílejte SMS 📲",
    "send_coupons_via_sms": "Posílejte kupóny prostřednictvím SMS svým zákazníkům 🎟️",
    "env_Cap": "Nabídněte exkluzivní slevy a propagační akce, abyste nalákali své zákazníky",
    "cmt_Caption": "Oslovte zákazníky prostřednictvím textových a multimediálních zpráv",
    "wp_caption": "Spojte se se zákazníky pomocí WhatsApp pro bezproblémovou a přímou komunikaci",
    "msg_Prev_Title": "Náhled zprávy",
    "cou_Prev_Title": "Náhled kupónu",
    "blast_SMS": "SMS\/MMS kampaň",
    "bs_P_Msg": "Ve frontě",
    "bs_S_Msg_C": "Nepodařilo se",
    "bs_F_Msg_Count": "Doručeno",
    "previous": "Předchozí",
    "next_ST": "Další",
    "next_S": "Další krok",
    "req_Temp_Name_Title": "Název šablony je povinný",
    "req_Template_Tx_Title": "Text šablony je povinný",
    "add_Temp_Title": "Přidat šablonu",
    "link_Desc": "Přidejte odkaz podle svého výběru, aby si u vás klienti mohli rezervovat přes Facebook, Google nebo váš vlastní web.",
    "audience_Title": "Publikum",
    "client_G1_Title": "Noví klienti",
    "client_G2_Title": "Nedávní klienti",
    "client_G3_Title": "Loajální klienti",
    "client_G4_Title": "Zaniklí klienti",
    "client_G5_Title": "Klienti podle data připojení",
    "on_Which_Day": "Který den",
    "on_Day": "V den",
    "immediate_Desc": "Vaše zpráva bude odeslána okamžitě, začněte kliknutím na Další.",
    "never_Desc": "Tato kampaň je naplánována tak, aby běžela navždy, dokud ji ručně neukončíte.",
    "end_Desc": "Kampaň skončí v den, který si zde vyberete.",
    "success_Message": "Kampaň byla úspěšně uložena!",
    "send_Success_Message": "Kampaň byla úspěšně odeslána!",
    "msg_Name_Field_Title": "Název kampaně",
    "valid_campaign_title": "Zadejte platný název kampaně",
    "msg_Type_Title": "Typ zprávy",
    "sms_Desc": "1 kredit na SMS",
    "mms_Desc": "3 kredity za MMS",
    "client_GTO_1": "Den",
    "client_GTO_2": "hodiny",
    "minutes": "Zápis",
    "client_GTO_4": "Týden",
    "time_Option_1": "Den",
    "time_Option_2": "hodiny",
    "c_Grp_Day_1": "90 dní",
    "c_Grp_Day_2": "60 dní",
    "c_Grp_Day_3": "30 dní",
    "first": "První",
    "second_T": "Druhý",
    "third_T": "Třetí",
    "fourth_T": "Čtvrtý",
    "fifth_T": "Pátý",
    "delete_BS_T": "Tato kampaň bude trvale smazána.",
    "delete_MBS_T": "Tyto kampaně budou trvale smazány.",
    "cFT_1": "Telefon",
    "cFT_2": "Kontaktní křestní jméno",
    "cFT_3": "Kontaktní příjmení",
    "cFT_4": "Kontaktní e-mail",
    "cFT_5": "Poznámka",
    "cFT_6": "Narozeniny",
    "cFT_7": "Název společnosti",
    "cF_Meta_Tag_1": "%% telefon %%",
    "cF_Meta_Tag_2": "%% křestní jméno %%",
    "cF_Meta_Tag_3": "%% příjmení %%",
    "cF_Meta_Tag_4": "%% email %%",
    "cF_Meta_Tag_5": "%% poznámka %%",
    "cF_Meta_Tag_6": "%% narozeniny %%",
    "cF_Meta_Tag_7": "%% název_společnosti %%",
    "cF_Name_1": "telefon",
    "cF_Name_2": "křestní jméno",
    "cF_Name_3": "příjmení",
    "cF_Name_4": "e-mail",
    "cF_Name_5": "poznámka",
    "cF_Name_6": "narozeniny",
    "cF_Name_7": "název_společnosti",
    "all_clients": "AllClients",
    "aud_Type_2": "Klientské skupiny",
    "aud_Type_3": "Zákaznické skupiny",
    "aud_Type_T1": "Všichni klienti",
    "aud_Type_T2": "Skupiny klientů",
    "aud_Type_T3": "Skupiny zákazníků",
    "new_clients": "Noví klienti",
    "client_G2": "Nedávní klienti",
    "client_G3": "Loajální klienti",
    "client_G4": "Zaniklí klienti",
    "client_G5": "Klienti podle data připojení",
    "never_text": "Nikdy",
    "ending_on": "Končí na",
    "send_T2": "Naplánováno",
    "send_Type_3": "Opakující se",
    "daily": "Denní",
    "weekly_T": "Týdně",
    "monthly_T": "Měsíční",
    "yearly_T": "Roční",
    "each": "Každý",
    "on_the": "Na",
    "monday": "pondělí",
    "exclusive_offer": "Exkluzivní nabídka pro vás",
    "redeem_now": "Uplatnit nyní",
    "redeem_with_cashier": "Proveďte prosím u pokladny",
    "lg_Day_2": "úterý",
    "lg_Day_3": "středa",
    "lg_Day_4": "čtvrtek",
    "lg_Day_5": "pátek",
    "lg_Day_6": "sobota",
    "lg_Day_7": "neděle",
    "msg_Pre_T": "Dominos",
    "cA_Edit_T1": "Vše",
    "cA_Edit_T2": "V ceně",
    "cA_Edit_T3": "Vyloučeno",
    "SMS": "SMS",
    "MMS": "MMS",
    "usd_T": "USD",
    "cad_T": "CAD",
    "msg": "Zpráva",
    "which_Day": "Které dny",
    "end_Date_Tx": "Datum ukončení",
    "sDate_Err": "Datum zahájení musí být větší nebo rovné dnešnímu dni",
    "eDate_Err": "Datum ukončení musí být pozdější než datum zahájení",
    "start_Date_Req": "Datum zahájení je povinné",
    "end_Date_Req": "Datum ukončení je povinné",
    "time_req": "Čas je vyžadován",
    "client_GT1": "Klienti se přihlásili v posledním",
    "client_GT2": "Klienti s připojením v posledním",
    "client_GT3": "Klienti s min",
    "client_GT4": "s posledním",
    "client_GT5": "který se nevrátil v posledním",
    "dummy_Phone_No": "+91987-654-3210",
    "test_T": "Test",
    "dummy_Birth_Date": "01-01-2001",
    "image_Req": "Je vyžadováno médium nebo adresa URL",
    "time_Title": "Čas",
    "start_date": "Datum zahájení",
    "end_date": "Datum ukončení",
    "auto_Send_T": "Automaticky odesílat klientům",
    "add_Media": "Přidat média",
    "in_Title": "V",
    "c_Temp_T": "Šablona kampaně",
    "temp_NT": "Název šablony",
    "type_T": "Typ",
    "select_template_type": "Vyberte typ šablony",
    "sel_Temp_T": "Vyberte Typ šablony",
    "sms_temp_t": "Šablona SMS",
    "temp_T": "Text šablony",
    "default_Msg": "Ahoj %% jméno %%, Speciální sleva na brunch! Povečeřte s námi ještě dnes a získejte exkluzivní 10% slevu na svůj účet!",
    "refill_T": "Doplňte",
    "avl_Credit": "Dostupné kredity",
    "req_Credit": "Celkový počet požadovaných kreditů",
    "rem_Credit": "Zbývající kredit",
    "refill_Credit": "Kredity k doplnění",
    "clients_Req": "Vyberte alespoň jednoho klienta",
    "subclient_Req": "Vybraní klienti nejsou předplatiteli.",
    "refill_Desc": "Doplňte prosím požadované kredity",
    "url_Regex": "^((http|https):\/\/).....+$",
    "sd_Invalid": "Datum zahájení je neplatné",
    "ed_Invalid": "Datum ukončení je neplatné",
    "img_Url_Invalid": "Neplatná adresa URL obrázku",
    "time_Invalid": "Čas je neplatný",
    "time_And_Date_Invalid": "Zadejte platné datum a čas",
    "time_Invalid_Bfr": "Vyberte čas alespoň o 5 minut později od aktuálního času",
    "sod_Req": "Je vyžadováno odeslání dne",
    "add_Card": "Přidat novou kartu",
    "sm_D_Rep_Title": "Zpráva o doručení SMS\/MMS",
    "send_Now_T": "Odeslat hned",
    "schd_Now_T": "Naplánovat SMS",
    "test_SMS_T": "Odeslat testovací SMS",
    "save_AD_T": "Uložit jako koncept",
    "back_TE_T": "Zpět na Upravit",
    "reset_tex": "Resetovat",
    "update_tex": "Aktualizovat",
    "dum_msg1": "Chcete-li zobrazit jméno zákazníka, použijte v šabloně %% customer_name %%",
    "dum_msg2": "Chcete-li zobrazit čas rezervace, použijte v šabloně %% date_time %%",
    "dum_msg3": "Chcete-li zobrazit číslo tabulky, použijte v šabloně %% table_no %%",
    "embedded_link": "Vložený odkaz",
    "img_title": "Obraz",
    "img_input_text": "Text:",
    "img_join_text": " připojit se k našemu seznamu",
    "img_copy_text": "Kopie",
    "img_not_found": "Vygenerovaný obrázek nebyl nalezen!!",
    "or_text": "NEBO",
    "web_signup_image_content": "Odesláním tohoto formuláře a registrací prostřednictvím SMS souhlasíte s přijímáním marketingových textových zpráv (jako jsou propagační kódy a upomínky). Mohou být účtovány poplatky za zprávy a data. Frekvence zpráv se liší. Z odběru se můžete kdykoli odhlásit odpovědí STOP",
    "img_download_success": "Obrázek byl úspěšně stažen",
    "embedded_link_copied": "Vložený odkaz byl úspěšně zkopírován",
    "media_url_required": "Je vyžadováno médium nebo adresa URL",
    "invalid_image_url": "Neplatná adresa URL obrázku",
    "invalid_file": "Soubor je neplatný",
    "image_error_1mb": "Vložte prosím obrázek, který je menší než 1 MB",
    "image_error_2_5mb": "Vložte prosím obrázek, který je menší než 2,5 MB",
    "image_error_3mb": "Vložte prosím obrázek, který je menší než 3 MB",
    "change_title": "Přeměna",
    "drop_file_text": "Přetáhněte soubor sem nebo jej nahrajte kliknutím",
    "apply_text": "Použít",
    "search_here": "Hledat zde",
    "update_status": "Stav aktualizace",
    "reservation_deleted_permanently": "Tato rezervace bude trvale smazána.",
    "table_text": "Tabulky",
    "add_table_button": "Přidat novou tabulku",
    "add_table_text": "Přidat tabulku",
    "edit_table_text": "Upravit tabulku",
    "table_delete_text": "Tato tabulka bude trvale smazána.",
    "multiple_table_delete_text": "Tyto tabulky budou trvale smazány.",
    "table_error_name": "Zadejte název tabulky",
    "table_error_invalid_name": "Zadejte platný název tabulky",
    "table_error_floor": "Vyberte podlahu",
    "table_error_select": "Vyberte prosím tabulku",
    "table_capacity_text": "Kapacita stolu",
    "capacity_text": "Kapacita",
    "table_occasion_text": "Najděte si svůj stůl pro každou příležitost",
    "table_name_text": "Název tabulky",
    "table_capacity_error": "Je vyžadována kapacita stolu",
    "cancel_text": "Zrušit",
    "submit_text": "Předložit",
    "select_valid_date": "Vyberte platné datum",
    "select_valid_time": "Vyberte platný čas",
    "valid_contact_number": "Zadejte platné kontaktní číslo",
    "date_req": "Datum je povinné",
    "date_error_later_than_today": "Datum musí být pozdější nebo rovné dnešnímu datu",
    "time_error_later_than_5_minutes": "Vyberte čas alespoň o 5 minut později od aktuálního času",
    "number_person_req": "Vyžaduje se počet osob",
    "contact_no_req": "Kontaktní číslo je povinné",
    "contact_no": "Kontaktní číslo",
    "select_time": "Vyberte Čas",
    "for_how_many_person": "Pro kolik osob?",
    "f_name": "Křestní jméno",
    "l_name": "Příjmení",
    "business_name": "Obchodní název",
    "business_no": "Obchodní číslo",
    "no_for_sign_in": "Toto číslo se používá pro přihlášení",
    "business_email": "Obchodní e-mail",
    "notes_tex": "Poznámky",
    "floor_deleted_permanently": "Toto patro bude trvale smazáno.",
    "add_floor": "Přidat podlahu",
    "edit_floor": "Upravit podlahu",
    "name_text": "Jméno",
    "mobile_no": "Mobil č.",
    "person_text": "Osoba",
    "date_and_time": "Datum a čas",
    "actions_text": "Akce",
    "extra_text": "Další",
    "floor_name_req": "Název podlaží je povinný",
    "floor_name": "Název podlaží",
    "status_text": "Postavení",
    "category_status_req": "Status kategorie je povinný",
    "table_deleted_permanently": "Tato tabulka bude trvale smazána.",
    "tables_deleted_permanently": "Tyto tabulky budou trvale smazány.",
    "back_to_home": "Zpět na domov",
    "link_copied_text": "Odkaz zkopírován do schránky",
    "cust_dash_head_to_title": "na",
    "cust_dash_head_location_title": "Umístění",
    "select_location_title": "Vyberte umístění",
    "all_locations_label": "all_locations",
    "rep_customer_feedback_analytics": "😊 Sledujte zpětnou vazbu od zákazníků pomocí analýzy v reálném čase",
    "rep_customer_txt": "Zákazníci",
    "rep_delete_title": "Tato reputace bude trvale smazána.",
    "rep_qr_def_txt": "Naskenujte QR kód a podělte se s námi o vaši cennou zpětnou vazbu týkající se vašich zkušeností.",
    "delete_title": "Vymazat",
    "user_list_title": "Seznam klientů",
    "os_nfc_txt": "NFC karty",
    "os_sign_holder_txt": "Stojan držáku znamení",
    "os_store_Sign_holder_txt": "Držáky obchodních značek",
    "StaT": "Statistika",
    "AllgT": "Všechny skupiny",
    "FavT": "Oblíbený",
    "MostActT": "Nejaktivnější",
    "grT": "Název skupiny",
    "keywT": "Klíčová slova",
    "exSubT": "Zpráva pro stávajícího předplatitele",
    "ArchT": "Archivováno",
    "gNotiSms": "Informujte mě o každém novém kontaktu prostřednictvím SMS",
    "gNotiEmail": "Informujte mě o každém novém kontaktu prostřednictvím e-mailu",
    "reqGName": "Název skupiny je povinný",
    "validGN": "Zadejte platný název skupiny",
    "valid_phone_no": "Zadejte platné telefonní číslo",
    "phone_no_req": "Telefonní číslo je povinné",
    "required_message_text": "Zpráva je povinná",
    "required_discount_text": "Je vyžadována sleva",
    "less_than_discount": "Částka slevy musí být nižší než 5000",
    "discount_percentage_invalid": "Procento slevy musí být menší nebo rovné 100",
    "discount_type_req": "Je vyžadován typ slevy",
    "discount_text_req": "Text kupónu je povinný",
    "reqContactNo": "Kontaktní číslo je povinné",
    "reqMsgNT": "Název kampaně je povinný",
    "reqLinkT": "Odkaz je povinný",
    "delMGT": "Tyto skupiny budou trvale smazány.",
    "defMemMsg": "Sem napište svou zprávu",
    "add_cust_to_grp_title": "Přidat klienta do skupiny",
    "group_title": "Skupiny",
    "group_create_title": "Vytvořit skupinu",
    "group_name_txt": "Název skupiny",
    "group_table_keyword_title": "Klíčové slovo",
    "actions_title": "Akce",
    "clients_title": "klienti",
    "send_title": "Poslat",
    "qr_title": "QR",
    "delete_group_txt": "Tato skupina bude trvale smazána.",
    "delete_groups_txt": "Tyto skupiny budou trvale smazány.",
    "delete_client_title": "Tento klient bude trvale smazán.",
    "delete_clients_title": "Tito klienti budou trvale smazáni.",
    "delete_multiple_title": "Vícenásobné smazání",
    "wel_sms_mms": "Odeslat uvítací SMS\/MMS?",
    "key_words_title": "Klíčová slova",
    "srch_plch_txt": "Hledat zde",
    "req_location_id_title": "Poloha je povinná",
    "create_text": "Vytvořit",
    "view_text": "Pohled",
    "immediately": "Ihned",
    "business_name_req": "Název firmy je povinný",
    "business_no_req": "Je vyžadováno obchodní číslo",
    "valid_business_name": "Zadejte platný obchodní název",
    "valid_business_no": "Zadejte platné obchodní číslo",
    "address_req": "Adresa je povinná",
    "valid_address": "Vyberte prosím platnou adresu",
    "time_zone_req": "Je vyžadována sleva",
    "image_req": "Vložte prosím obrázek, který je menší než 2,5 MB",
    "valid_file": "Soubor je neplatný.",
    "logo": "Logo",
    "time_zone": "Časové pásmo",
    "save": "Uložit",
    "account_type_req": "Je vyžadován typ účtu",
    "gst_no_req": "Číslo GST je povinné",
    "gst_no_valid": "Zadejte platné číslo GST",
    "set_up_payments": "Nastavte platby",
    "billing_details": "Zadejte své fakturační údaje",
    "billing_details_desc": "Vaše fakturační údaje se zobrazí na vaší měsíční faktuře z menuonline.",
    "account_type": "Typ účtu",
    "select_account_type": "Vyberte typ účtu",
    "gst_no": "Číslo GST",
    "transaction_details": "Podrobnosti transakce",
    "payment_method": "Způsob platby",
    "billing_period": "Zúčtovací období",
    "paid_by": "Zaplaceno",
    "download": "Stáhnout",
    "pay_now": "Zaplaťte hned",
    "pull_down_refresh": "Zatažením dolů obnovte",
    "release_refresh": "Uvolněním se obnoví",
    "billing_details_text": "Fakturační údaje",
    "payment_methods": "Platební metody",
    "invoice": "Faktura",
    "invoice_to": "Faktura Komu:",
    "issue_date": "Datum vydání",
    "due_date": "Datum splatnosti",
    "amount_due": "Dlužná částka",
    "charges": "Poplatky",
    "sub_total_capitalize": "Mezisoučet",
    "hst": "HST",
    "grand_total": "Celkový součet",
    "invoice_generated_on": "Faktura vygenerována dne",
    "contact_us": "Kontaktujte nás",
    "invoice_issue": "Pokud jste si prošli svůj účet a stále máte otázku",
    "call": "Volání",
    "send_sms": "Poslat SMS",
    "payment_success": "Platba proběhla úspěšně",
    "edit_credit_card": "Upravit kreditní kartu",
    "add_credit_card": "Přidat kreditní kartu",
    "modify_card_info": "Upravte informace o své kartě",
    "enter_card_info": "Zadejte informace o své kartě",
    "account_no_req": "Číslo účtu je povinné",
    "card_name_req": "Jméno na kartě je povinné",
    "expiry_date_req": "Datum vypršení platnosti je povinné",
    "valid_expiry_date": "Zadejte platné datum vypršení platnosti",
    "valid_cvv": "Zadejte platný cvv",
    "cvv_req": "CVV je vyžadováno",
    "card_no": "Číslo karty",
    "name_of_card": "Jméno na kartě",
    "expiry": "Uplynutí",
    "mm_text": "MM\/RR",
    "cvv": "CVV",
    "set_as_default": "Nastavit jako výchozí",
    "add_new_card": "Přidat novou kartu",
    "all_credit_card": "Všechny vaše kreditní karty",
    "fastest_checkout": "Nejrychlejší platba s bezpečnou a spolehlivou platformou, důvěryhodná velkou skupinou podniků",
    "coupon_button_req": "Text tlačítka kupónu je povinný",
    "max19_characters_allowed": "Je povoleno maximálně 19 znaků",
    "fine_print_text_req": "Je vyžadován drobný text",
    "clients_req": "Vyberte alespoň jednoho klienta",
    "client_groups": "Klientské skupiny",
    "day_text": "Den",
    "valid_days": "Hodnota dne je příliš velká, zadejte platné dny",
    "hours_text": "hodiny",
    "enter_valid_hours": "Hodnota hodin je příliš velká, zadejte platné hodiny",
    "enter_valid_min": "Hodnota minut je příliš velká, zadejte platné minuty",
    "clients_with_connection_required": "Klienti s připojením v posledním jsou vyžadováni",
    "connection_required": "Je vyžadováno připojení",
    "connection_value_too_large": "Hodnota připojení je příliš velká, zadejte platné připojení",
    "minutes_required": "Jsou vyžadovány minuty",
    "clients_with_at_least_connection_required": "Požadují se klienti s alespoň připojením",
    "last_minutes_req": "S posledními minutami je vyžadováno",
    "clients_not_returned_required": "Kdo se v posledních minutách nevrátil, je povinný",
    "not_return_in_last_minutes_invalid": "Nevrácení v posledních minutách musí být menší než v posledních minutách.",
    "customerGroups": "Zákaznické skupiny",
    "select_customer_group": "Vyberte skupinu zákazníků",
    "location_required": "Místo je povinné",
    "start_date_required": "Datum zahájení je povinné",
    "start_date_invalid": "Datum zahájení je neplatné",
    "start_date_invalid_error": "Datum zahájení musí být větší než dnešní nebo stejné",
    "recurring": "Opakující se",
    "end_date_required": "Datum ukončení je povinné",
    "end_date_invalid": "Datum ukončení je neplatné",
    "end_date_invalid_error": "Datum ukončení musí být pozdější než datum zahájení",
    "time_invalid": "Čas je neplatný",
    "monthly_text": "Měsíční",
    "send_day_req": "Je vyžadováno odeslání dne",
    "loyal_clients": "Loajální klienti",
    "recent_clients": "Nedávní klienti",
    "lapsed_clients": "Zaniklí klienti",
    "clients_connection_date": "Klienti podle data připojení",
    "scheduled_text": "Naplánováno",
    "weekly": "Týdně",
    "selected_clients_not_subscriber": "Vybraní klienti nejsou předplatiteli.",
    "message_preview_title": "Náhled zprávy",
    "coupon_preview_title": "Náhled kupónu",
    "form_text": "Formulář",
    "preview_text": "Náhled",
    "next_text": "Další",
    "send_test_SMS": "Odeslat testovací SMS",
    "save_draft": "Uložit jako koncept",
    "back_to_edit": "Zpět na Upravit",
    "select_payment_method": "Vyberte prosím způsob platby",
    "schedule_SMS": "Naplánovat SMS",
    "send_now": "Poslat nyní",
    "get_more_ratings": "Získejte další hodnocení",
    "overview": "Přehled",
    "reset_campaign": "Resetovat kampaň",
    "permissions": "Oprávnění",
    "location_name": "Název místa",
    "phone_no": "Telefonní číslo",
    "location_email_address": "Umístění E-mailová adresa",
    "located_business": "Kde se nachází vaše firma?",
    "business_logo": "Obchodní logo",
    "congratulations": "Gratuluji",
    "almost_done": "Už jste skoro hotovi",
    "publish": "Publikovat",
    "about_your_business": "O vašem podnikání",
    "add_your_location": "Přidejte svou polohu",
    "publish_location": "Umístění publikování",
    "location_name_req": "Název místa je povinný",
    "valid_location_name": "Zadejte platný název místa",
    "business_logo_req": "Logo firmy je povinné",
    "please_accept_terms": "Přijměte prosím smluvní podmínky",
    "add_new_location": "Přidat nové umístění",
    "edit_location": "Upravit místo",
    "add_location": "Přidat umístění",
    "existing_msg_subscriber_txt": "Zpráva pro stávajícího předplatitele",
    "msg_capitalize_txt": "Zpráva",
    "group_noti_sms": "Informujte mě o každém novém kontaktu prostřednictvím SMS",
    "group_noti_email": "Informujte mě o každém novém kontaktu prostřednictvím e-mailu",
    "group_member_msg": "Zdá se, že už jste součástí naší skupiny!!",
    "group_mend_msg": "STOP pro ukončení. HELP pro pomoc. Mohou být účtovány poplatky za SMS a data",
    "Disclaimer_title": "Text vyloučení odpovědnosti: ",
    "group_def_msg": "Nyní jsme online! Podívejte se na naši nabídku služeb a rezervujte si další termín ještě dnes.",
    "required_msg_txt": "Zpráva je povinná",
    "required_Key_txt": "Klíčová slova jsou povinná",
    "required_mem_msg": "Je vyžadována zpráva odběratele",
    "client_list_title": "Seznam klientů",
    "add_contact_txt": "Přidat kontakt",
    "delete_all_clients_txt": "Smazat všechny klienty",
    "delete_all_clients_msg": "Opravdu chcete smazat všechny klienty? Nelze je získat zpět.",
    "delete_all_txt": "Smazat vše",
    "timeline_title": "Časová osa",
    "unsubscribe_title": "Odhlásit odběr",
    "subscribe_title": "Upsat",
    "unsubscribe_confirm_msg": "Chcete tohoto zákazníka označit jako odhlašujícího?",
    "subscribe_confirm_msg": "Chcete označit tohoto zákazníka jako odběratele?",
    "no_title": "Žádný",
    "yes_title": "Ano",
    "client_name_title": "Jméno klienta",
    "source_title": "Zdroj",
    "contact_file_Req": "Je vyžadován soubor kontaktů",
    "req_title": "Požadovaný",
    "opt_in_req": "Importované kontakty musí být 100% přihlášeny",
    "image_invalid_error": "Soubor je neplatný.",
    "import_contacts_msg": "Importovat kontakty",
    "csv_upload_title": "Soubor CSV s kontakty k nahrání *",
    "csv_file_desc": "Nahrajte prosím pouze soubor csv – sloupce souboru csv – Jméno, Příjmení, E-mailová adresa, Mobilní číslo, Pohlaví, DOB (MM\/DD\/RRRR) – žádné mezery, pomlčky nebo závorky v Mobilním čísle",
    "to_download_title": "Pro stažení demo souboru klikněte zde",
    "contains_header_title": "Ano, tento soubor obsahuje záhlaví",
    "opt_in_100_txt": "Ano, tyto importované kontakty jsou 100% přihlášeny",
    "DisT": "Text vyloučení odpovědnosti: ",
    "gMendMsg": "STOP pro ukončení. HELP pro pomoc. Mohou být účtovány poplatky za SMS a data",
    "reqKeyT": "Klíčová slova jsou povinná",
    "reqMemMsg": "Je vyžadována zpráva odběratele",
    "reqMsgT": "Zpráva je povinná",
    "gMemberMsg": "Zdá se, že už jste součástí naší skupiny!!",
    "gdefMsg": "Nyní jsme online! Podívejte se na naši nabídku služeb a zarezervujte si další termín ještě dnes.",
    "next_title": "Další",
    "male_title": "Samec",
    "male_val": "samec",
    "female_title": "Žena",
    "female_val": "žena",
    "others_txt": "Ostatní",
    "others_val": "ostatní",
    "validBirthdate": "Zadejte platné datum narození",
    "valid_phone_no_title": "Zadejte platné telefonní číslo",
    "required_phone_no_title": "Telefonní číslo je povinné",
    "add_new_client_txt": "Přidat nového klienta",
    "update_client_txt": "Aktualizovat klienta",
    "phone_num_text": "Telefonní číslo",
    "dob_title": "nar",
    "select_gender_title": "Vyberte Pohlaví",
    "timelineTitle": "Časová osa",
    "confirm_location": "Potvrďte polohu",
    "feedback_title": "Zpětná vazba",
    "rep_question_1": "Byl podnik čistý?",
    "rep_que_2": "Bylo vaše jídlo dobré?",
    "rep_que_3": "Byla naše služba přátelská?",
    "rep_que_4": "Byla naše služba rychlá?",
    "business_location_req_title": "Sídlo firmy je povinné",
    "valid_location_err_txt": "Vyberte prosím platnou adresu",
    "rep_management_title": "Získejte více recenzí Management",
    "rep_about_desc": "Reputace vám pomůže zvýšit hodnocení Google tím, že požádáte své zákazníky o zpětnou vazbu",
    "preview_title": "Náhled",
    "rep_preview_txt": "Náhled reputace",
    "back_title": "Zadní",
    "fine_print_txt": "Proveďte prosím u pokladny",
    "redeem_me_title": "Vykupte mě",
    "rep_nxt_visit_txt": "Při další návštěvě",
    "device_type": "Typ použitého zařízení",
    "connection_method": "Způsob připojení",
    "peak_days": "Špičkové dny",
    "peak_hours": "Špičkové hodiny",
    "guest_by_day": "Denní host",
    "guest_visit": "Návštěva hosta",
    "connection": "Spojení",
    "connection_duration": "Délka připojení",
    "guest_visit_1": "1krát",
    "guest_visit_2": "2krát",
    "guest_visit_3_5": "3-5krát",
    "guest_visit_6_10": "6-10krát",
    "guest_visit_11_25": "11-25krát",
    "guest_visit_26_100": "26-100krát",
    "guest_visit_100_plus": "100+krát",
    "device_android_total": "Celkový Android",
    "device_android": "Android",
    "device_desktop": "Desktop",
    "device_ios": "Ios",
    "device_ios_total": "Celkový Ios",
    "device_desktop_total": "Celková plocha",
    "connection_duration_10": "<=10 min",
    "connection_duration_20": "11-20 min",
    "connection_duration_30": "21-30 min",
    "connection_duration_40": "31-45 min",
    "connection_duration_60": "46-60 min",
    "connection_method_email": "E-mail",
    "connection_method_sms": "SMS",
    "connection_method_google": "Google",
    "connection_method_facebook": "Facebook",
    "age_category_1": "<18",
    "age_category_2": "18-24",
    "age_category_3": "25-34",
    "age_category_4": "35-44",
    "age_category_5": "45-54",
    "age_category_6": "55+",
    "all_guest_txt": "Všichni hosté",
    "new_Guest_txt": "Nový host",
    "connections_txt": "Spojení",
    "hotspot": "Hotspot",
    "hotspot_list": "Seznam hotspotů",
    "add_new_hotspot": "Přidat nový hotspot",
    "hotspot_information": "Informace o hotspotu",
    "edit_details_button": "Upravit podrobnosti",
    "wifi_info_message": "Připojte se a užívejte si bezplatné Wi-Fi",
    "connection_message": "Skvělé, byli jste připojeni k ",
    "connection_message_suffix": " WiFi",
    "wifi": "WiFi",
    "login_to_access": "Chcete-li získat přístup, přihlaste se",
    "verification_code": "Ověřovací kód",
    "verification_code_message": "Zadejte ověřovací kód odeslaný na adresu ",
    "wifi_user_email": "adamo@gmail.com",
    "wifi_label": "Wifi",
    "your_name": "Mimochodem, jak se jmenuješ?",
    "your_birthdate": "Můžete nám říct, jaké je vaše datum narození?",
    "request_guest_wifi_name": "Zadejte název Wifi pro hosta",
    "request_device_key": "Zadejte klíč zařízení",
    "request_maximum_internet_access_length": "Vyberte maximální délku přístupu k internetu",
    "mac_address": "MAC adresa",
    "device_port": "Port zařízení",
    "hardware": "Železářské zboží",
    "current_uptime": "Aktuální doba provozu",
    "nearby_devices": "Zařízení v okolí",
    "firmware": "Firmware",
    "who_are_you": "kdo jsi?",
    "where_to_contact_you": "Kde vás můžeme kontaktovat?",
    "your_area_code": "Jaké je vaše předčíslí?",
    "connected": "Připojeno",
    "delete_hotspot_message": "Tento hotspot bude trvale smazán.",
    "delete_multiple_hotspots_message": "Tyto aktivní body budou trvale smazány.",
    "speed_error": "Rychlost by měla být alespoň 0,01",
    "speed_max_error": "Zadejte hodnotu až 1024 pro neomezenou rychlost nebo vyberte nižší hodnotu v podporovaném rozsahu",
    "device_ssid": "SSID zařízení",
    "device_ssid_two": "SSID zařízení dvě",
    "device_ssid_two_wpa": "SSID zařízení dvě WPA",
    "device_key": "Klíč zařízení",
    "select_location": "Vyberte umístění",
    "select_maximum_internet_access_length": "Vyberte Maximální délka přístupu k internetu",
    "download_speed": "Rychlost stahování",
    "upload_speed": "Rychlost nahrávání",
    "network_length_1": "15 minut",
    "network_length_2": "30 minut",
    "network_length_3": "45 minut",
    "network_length_4": "1 hodina",
    "network_length_5": "2 hodiny",
    "network_length_6": "4 hodiny",
    "network_length_7": "6 hodin",
    "network_length_8": "8 hodin",
    "network_length_9": "10 hodin",
    "network_length_10": "12 hodin",
    "employee_wifi_name": "Jméno Wifi zaměstnance",
    "employee_wifi_password": "Heslo zaměstnance Wifi",
    "guest_wifi_name": "Název Wifi pro hosty",
    "menu_other_products_txt": "Ostatní produkty | Menu online",
    "menu_home_text": "Domů | Menu online",
    "whatsapp_title": "Whatsapp",
    "select_campaign_type": "Vyberte typ kampaně",
    "select_readymade_templates": "Vyberte si z hotových šablon nebo si vytvořte vlastní",
    "campaign_title_required": "Název kampaně je povinný",
    "type_here": "Sem napište...",
    "location_permission_req": "Je vyžadováno oprávnění k poloze",
    "platform_permission_req": "Vyžaduje se oprávnění platformy",
    "profile_picture": "Profilový obrázek",
    "click_to_upload": "Kliknutím nahrajete",
    "location_permission": "Povolení k umístění",
    "pin": "Kolík",
    "platform_permission": "Oprávnění k platformě",
    "set_pin": "Nastavte PIN",
    "list_of_users": "Seznam uživatelů",
    "create_user": "Vytvořit uživatele",
    "terms_and_condition_req": "Smluvní podmínky jsou povinné",
    "terms_and_conditions": "Smluvní podmínky",
    "recommended": "DOPORUČUJEME",
    "mo": "\/měs",
    "unsubscribe": "Odhlásit odběr",
    "cancel_subscription": "Opravdu chcete zrušit předplatné?",
    "upgrade": "Upgrade",
    "plan_upgraded_successfully": "Plán byl úspěšně aktualizován!",
    "menu_online": "Menu online",
    "support": "Podpora",
    "wifiC": "WIFI",
    "billing": "Fakturace",
    "please_upgrade": "Upgradujte, abyste mohli využívat všechny funkce",
    "you_have_subscribed": "Přihlásili jste se k odběru ",
    "plan": "plán",
    "of_text": "z",
    "days": "Dny",
    "remaining_days_until_plan": " Počet zbývajících dní, kdy váš plán vyžaduje aktualizaci",
    "manage_your_screens": "Spravujte své obrazovky",
    "screens_you_save": "Obrazovky, které máte",
    "add_more_screens": "Přidat další obrazovky",
    "addition_screen": "Přídavná obrazovka",
    "per_screen": "$ za obrazovku",
    "per_box": "$ za krabici",
    "shipping_changes": "Poplatky za dopravu",
    "upto4_boxes": "až 4 krabice ",
    "charge_now": "Nyní dostanete poplatek",
    "updated_reoccuring": "Vaše aktualizované opakování bude",
    "current_reoccuring": "Aktuální opakování: ",
    "no_text": "Žádný",
    "android_box": "Android Box",
    "old_password_req": "Je vyžadováno staré heslo",
    "new_password_req": "Je vyžadováno nové heslo",
    "confirm_password_req": "Je vyžadováno potvrzení hesla",
    "password_do_not_match": "Hesla se neshodují",
    "old_password": "Staré heslo",
    "new_password": "Nové heslo",
    "confirm_password": "Potvrďte heslo",
    "copy_working_hours": "Zkopírovat tuto pracovní dobu na všechny dny?",
    "yes_copy": "Ano, kopírovat",
    "closed": "ZAVŘENO",
    "opening_time": "Otevírací doba",
    "closing_time": "Zavírací čas",
    "description": "Popis",
    "file_exist": "soubor již existuje, vyberte prosím jiný soubor",
    "bussiness_images": "Obchodní obrázky",
    "display_info_on_market_place": "Chcete zobrazit níže uvedené informace na vašem Marketplace?",
    "profile_ready": "Váš profil je připraven!",
    "client_book_with_online": "Klienti si nyní mohou u vás rezervovat online. Chcete-li začít, sdílejte níže uvedený odkaz.",
    "copy_link": "Kopírovat odkaz",
    "see_your_profile": "Podívejte se na svůj profil",
    "ok_got_it": "Dobře, rozumím",
    "preview_profile": "Prohlédněte si svůj profil a podívejte se, jak by vypadal.",
    "opening_hours": "Otevírací doba",
    "display_data": "Zobrazit data",
    "manage_profile": "Spravovat profil",
    "phone_req": "Telefon je povinný",
    "market_place_img_req": "Obrázky tržiště jsou povinné",
    "add_new_market_place": "Přidat nové tržiště",
    "edit_market_place": "Upravit MarketPlace",
    "no_reviews_yet": "Zatím žádné recenze",
    "good": "Dobrý",
    "average": "Průměrný",
    "add_marketPlace": "Přidat MarketPlace",
    "all_title": "Vše",
    "included_title": "V ceně",
    "excluded_title": "Vyloučeno",
    "clients_subscribed": "Klienti se přihlásili v posledním",
    "clients_groups": "Skupiny klientů",
    "customer_groups": "Skupiny zákazníků",
    "audience_title": "Publikum",
    "client_gt5": "který se nevrátil v posledním",
    "select_all": "Vyberte Vše",
    "modify": "Upravit",
    "campaign_title": "Název kampaně",
    "msg_type": "Typ zprávy",
    "enter_discount": "Zadejte slevu",
    "discount_type": "Typ slevy",
    "coupon_text": "Text kupónu",
    "enter_coupon_text": "Zadejte text kupónu",
    "coupon_button_text": "Text tlačítka kupónu",
    "enter_coupon_button_text": "Zadejte text tlačítka kupónu",
    "fine_prin": "Fine Print",
    "enter_fine_prin": "Zadejte drobně tištěný text",
    "campaign_dec": "Kampaň můžete upravit 30 minut před jejím začátkem. Poplatky za SMS kampaň budou účtovány 30 minut před začátkem kampaně.",
    "blast_text_message_dec": "Vaše textová zpráva je připravena. Vyberte způsob platby a odešlete zprávy.",
    "payment_completed": " Platba bude dokončena v ",
    "total_cost": "Celkové náklady",
    "close_title": "Blízko",
    "friday": "pátek",
    "saturday": "sobota",
    "sunday": "neděle",
    "thursday": "čtvrtek",
    "tuesday": "úterý",
    "wednesday": "středa",
    "port_txt": "Přístav",
    "today_title": "Dnes",
    "yesterday_title": "Včera",
    "last_30_days_txt": "Posledních 30 dní",
    "this_month_txt": "Tento měsíc",
    "last_month_txt": "Minulý měsíc",
    "valid_date_title": "Vyberte platné datum",
    "valid_business_name_txt": "Zadejte platný obchodní název",
    "req_bus_add_txt": "Adresa firmy je povinná",
    "req_domain_name_txt": "Název domény je povinný",
    "basic_info_txt": "Základní informace o vaší firmě",
    "loyalty_qr_def_txt": "Naskenujte QR kód, připojte se k naší věrnostní kartě a dopřejte si odměny.",
    "last_stamp_txt": "Poslední razítko",
    "collected_on_txt": "Sbíráno dne",
    "stamp_details_txt": "Podrobnosti o razítku",
    "add_stamp_txt": "Přidat razítko",
    "choose_brand_color_txt": "Vyberte si barvu a jazyk značky 🎨",
    "change_anytime_txt": "Toto můžete kdykoli změnit",
    "like_to_get_txt": "Chtěli byste získat",
    "your_next_visit_txt": "při příští návštěvě?",
    "time_to_time_get_offers": "Chtěli byste čas od času dostávat skvělé nabídky a propagační akce?",
    "respond_back_to_you": "Chcete, aby vám někdo odpověděl?",
    "input_serve_better": "Váš příspěvek pomůže našemu týmu lépe sloužit!",
    "do_it_txt": "Pojďme na to",
    "take_15_s": "Trvá 15 sekund",
    "rate_on_google_desc": "Byli byste tak laskav a ohodnotili nás na Googlu?",
    "may_be_next_time": "Možná příště",
    "thank_you_txt": "Děkuju",
    "look_next_txt": "Těšíme se na Vaši další návštěvu!",
    "deleteUserTitle": "Tento uživatel bude trvale smazán.",
    "deleteMUserTitle": "Tito uživatelé budou trvale smazáni.",
    "change_pin": "Změnit PIN",
    "area_code": "Jaké je vaše předčíslí? ",
    "add_menu": "Přidat nabídku",
    "menu_name": "Název nabídky",
    "add_menu_placeholder": "Pizza, Burger, Baverages atd",
    "enable_data_Collection": "Povolit sběr dat",
    "add_new_menu": "Přidat nové menu",
    "rename_menu": "Menu Přejmenovat",
    "preview": "Náhled",
    "generate_qr": "Generované QR",
    "edit_menu": "Nabídka Upravit",
    "remove_menu": "Odebrat nabídku",
    "menu_delete_msg": "Tato nabídka bude trvale smazána",
    "menus_delete_msg": "Tato menu budou trvale smazána",
    "view_menu_dialoage_msg": "Chtěli byste dostat dárek k narozeninám?",
    "skip": "Přeskočit",
    "cliam_your_gift": "Získejte svůj dárek",
    "collect_form": "Sbírejte formulář",
    "enter_first_name": "Zadejte křestní jméno",
    "enter_last_name": "Zadejte příjmení",
    "enter_email": "Zadejte e-mail",
    "enter_dob": "Zadejte Datum narození",
    "enter_number": "Zadejte číslo",
    "select_gender": "Vyberte Pohlaví",
    "congratulations_desc": "🎉 Gratulujeme! Vaše žádost o nárok na dárek byla úspěšně odeslána. Náš tým vás bude brzy kontaktovat. 🎉",
    "male_heading": "Samec",
    "male_text": "samec",
    "female_heading": "Žena",
    "female_text": "žena",
    "others_heading": "Ostatní",
    "other_text": "ostatní",
    "BirthD": "datum narození",
    "GenderT": "Pohlaví",
    "EmailT": "E-mail",
    "dobT": "nar",
    "capitalize_menu": "Menu",
    "select_menu": "Vyberte nabídku",
    "manage_variant": "Spravovat variantu",
    "add_products": "Přidat produkty",
    "add_category": "Přidat kategorii",
    "category_delete": "Tato kategorie bude trvale smazána",
    "variation_delete": "Tato varianta bude z produktu odstraněna.",
    "product_delete": "Tento produkt bude trvale smazán.",
    "categories_delete": "Tyto kategorie budou trvale smazány.",
    "products_delete": "Tyto produkty budou trvale smazány.",
    "category": "KATEGORIE",
    "price": "CENA",
    "food_product_placeholder": "Pizza, Burger, Baverages atd",
    "active_title": "Aktivní",
    "inActive_title": "Neaktivní",
    "status_capital": "POSTAVENÍ",
    "cat_status_require": "Status kategorie je povinný",
    "cat_name_require": "Název kategorie je povinný",
    "category_name": "Název kategorie",
    "status": "Postavení",
    "lgDay1": "pondělí",
    "lgDay2": "úterý",
    "lgDay3": "středa",
    "lgDay4": "čtvrtek",
    "lgDay5": "pátek",
    "lgDay6": "sobota",
    "lgDay7": "neděle",
    "is_closed_title": "ZAVŘENO",
    "book_table_title": "Kniha A Stůl",
    "emailErrSub": "Předmět e-mailu je povinný",
    "email_subject": "Předmět e-mailu",
    "contactUsfrmTitleEmail": "Odpověď na email",
    "companyInfo": "Lorem Ipsum je lorem hrig, lorem ipsum je fraets. Lorem Ipsum je lorem hrig, lorem ipsum je fraets. Lorem Ipsum je lorem hrig, lorem ipsum je fraets.",
    "footerSTitle1": "O Menuonline",
    "footerSTitle1Lnk2": "Vlastnosti",
    "footerSTitle1Lnk3": "Blog",
    "footerSTitle2": "Právní",
    "footerSTitle2Lnk1": "Smluvní podmínky",
    "footerSTitle2Lnk2": "Zásady ochrany osobních údajů",
    "footerSTitle3": "Pro podnikání",
    "footerSTitle3Lnk1": "Pro partnery",
    "footerSTitle3Lnk2": "Ceny",
    "footerSTitle3Lnk3": "Podpora pro partnery",
    "footerCopyRithgTxt": "  Menuonline nebo jeho přidružených společností",
    "homeTitle": "Domov",
    "contactTitle": "Kontaktujte nás",
    "aboutTitle": "O nás",
    "homeMenuTitle": "Menu",
    "bookTableTitle": "Kniha A Stůl",
    "vDateT": "Vyberte platné datum",
    "reqDateT": "Datum je povinné",
    "dateGtErr": "Datum musí být pozdější nebo rovné dnešnímu datu",
    "timeInvalid": "Čas je neplatný",
    "reqTimeT": "Čas je vyžadován",
    "timeInvalidBfr": "Vyberte čas alespoň o 5 minut později od aktuálního času ",
    "PerReq": "Vyžaduje se počet osob",
    "validcNoT": "Zadejte platné kontaktní číslo",
    "reqCrT": "Je vyžadována měna",
    "reqPrT": "Cena je požadována",
    "reCateT": "Kategorie je povinná",
    "reqVrT": "Je vyžadována varianta",
    "reqVrVldT": "Zadejte platnou variantu ",
    "validLNameT": "Zadejte platné příjmení",
    "sDateInValid": "Datum od je neplatné",
    "minmReq": "Je vyžadováno minimum",
    "resLblDate": "Vyberte Datum",
    "resLblTime": "Vyberte Čas",
    "perT": "Pro kolik osob?",
    "resLblEmail": "Uveďte prosím svůj email?",
    "resLblNote": "Přidejte poznámku k rezervaci",
    "imageError1MB": "Vložte prosím obrázek, který je menší než 1 MB",
    "imageError": "Vložte prosím obrázek, který je menší než 2,5 MB",
    "imageError3Mb": "Vložte prosím obrázek, který je menší než 3 MB",
    "imageInVldError": "Soubor je neplatný.",
    "addProMT": "Nabídka produktů",
    "proT": "Název produktu",
    "reqProT": "Název produktu je povinný",
    "proPT": "Cena produktu",
    "reservationSuccTitle": "Skvělé, vaše rezervace je hotová",
    "book": "Rezervovat",
    "custzName": "Název přizpůsobení",
    "always": "Vždy",
    "proImgReq": "Obrázek produktu je povinný",
    "selCustmT": "Vyberte Přizpůsobení nebo vytvořte nový",
    "visStR": "Je vyžadován stav viditelnosti",
    "avlblScR": "Vyberte plán dostupnosti",
    "addonPR": "Vyberte prosím doplňkové produkty",
    "upsellPR": "Vyberte prosím upsell produkty",
    "markItemR": "Vyberte prosím označit produkty",
    "caloriesR": "Kalorie jsou vyžadovány",
    "allergensR": "Vyberte prosím alergenové produkty",
    "prepInstR": "Pokyny k přípravě jsou vyžadovány",
    "staffNR": "Poznámky personálu jsou povinné",
    "discountR": "Je vyžadována sleva",
    "validDiscE": "Zadejte platnou slevu",
    "disday": "Ve dne",
    "plSelDayT": "Vyberte prosím den",
    "sTimeReq": "Je vyžadován čas zahájení",
    "sTimeInValid": "Čas zahájení je neplatný",
    "eTimeReq": "Je vyžadován čas ukončení",
    "eTimeInValid": "Čas ukončení je neplatný",
    "sDateReq": "Datum od je povinné",
    "eDateReq": "Je vyžadováno datum",
    "eDateInValid": "Dosud je neplatné",
    "disdate": "Od Datum",
    "disdate1": "K dnešnímu dni",
    "disdate2": "Od data",
    "currT": "Měna",
    "iconR": "Vyberte ikonu označení položky",
    "minT": "Minimální",
    "maxT": "Maximum",
    "itemNT": "Název položky",
    "itemPT": "Cena položky",
    "descProT": "Popište svůj produkt",
    "cateT": "Kategorie",
    "selProCateT": "Vyberte kategorii produktu",
    "reqMT": "Název nabídky je povinný",
    "ViewMenu": "Zobrazit menu",
    "CopyMenu": "Kopírovat nabídku",
    "EditMenu": "Nabídka Upravit",
    "RemoveMenu": "Odebrat nabídku",
    "welcomeMnuTitle": "Vítejte na",
    "reviewT": "Recenze",
    "userErrorMsg": "Omlouváme se, došlo k chybě!!!",
    "reqCateT": "Název kategorie je povinný",
    "mngCustT": "Správa přizpůsobení",
    "custNReq": "Je vyžadován název přizpůsobení",
    "incReq": "Je vyžadováno zahrnuto",
    "minmValid": "Zadejte platnou minimální hodnotu",
    "maxmReq": "Je požadováno maximum",
    "cust": "Přizpůsobení",
    "crCust": "Vytvořit nové přizpůsobení",
    "custList": "Seznam přizpůsobení",
    "delCustmzT": "Toto přizpůsobení bude trvale smazáno.",
    "mkNameR": "Označit název položky je povinné",
    "mkDelT": "Tato položka označení bude trvale smazána.",
    "hideText": "Skrýt",
    "showText": "Show",
    "device_mac_txt": "Zařízení Mac",
    "delete_hotspot_txt": "Tento hotspot bude trvale smazán.",
    "delete_hotspots_txt": "Tyto aktivní body budou trvale smazány.",
    "emp_wifi_name": "Jméno Wifi zaměstnance",
    "max_limit_txt": "Zadejte '1024' pro rychlost, abyste ji označili jako NEOMEZENO. Tato hodnota odstraňuje všechna omezení a umožňuje maximální propustnost",
    "device_port_txt": "Port zařízení",
    "firmware_txt": "Firmware",
    "hotspot_info_txt": "Informace o hotspotu",
    "editDBtn": "Upravit podrobnosti",
    "birth_date": "datum narození",
    "di_theme_clr": "Barva motivu přesně 7 číslic",
    "color_Invalid_txt": "Vyberte platný kód barvy",
    "Req_theme_clr": "Barva motivu je povinná",
    "select_valid_color_txt": "Vyberte prosím platnou barvu motivu",
    "req_redir_link": "Je vyžadován odkaz přesměrování",
    "val_redir_link": "Zadejte platný odkaz pro přesměrování",
    "req_business_name_txt": "Název firmy je povinný",
    "splash_preview": "Úvodní náhled",
    "create_new_splash": "Vytvořit nový splash",
    "splash_page": "Úvodní stránka",
    "splash_page_builder": "Tvůrce úvodní stránky",
    "redirect_link": "Přesměrování odkazu",
    "theme_color": "Barva motivu",
    "enable_social_login": "Povolit možnosti sociálních médií pro přihlášení uživatelů",
    "google": "Google",
    "facebook": "Facebook",
    "is_mandatory": "Je to povinné?",
    "field": "Pole",
    "name": "Jméno",
    "first_name": "Křestní jméno",
    "last_name": "Příjmení",
    "birthdate": "datum narození",
    "gender": "Pohlaví",
    "email": "E-mail",
    "dob": "nar",
    "zip_code": "PSČ",
    "required_redirect_link": "Je vyžadován odkaz přesměrování",
    "valid_redirect_link": "Zadejte platný odkaz pro přesměrování",
    "required_theme_color": "Barva motivu je povinná",
    "theme_color_length": "Barva motivu přesně 7 číslic",
    "required_name": "Jméno je povinné",
    "delete_splash_message": "Chcete tento splash smazat?",
    "delete_multiple_splashes_message": "Chcete tyto cákance smazat?",
    "user_login_required": "Je vyžadováno přihlášení uživatele",
    "set_theme_color": "Nastavit barvu motivu",
    "splash_colllect_additional_info_txt": "Sbírejte další informace",
    "verify_mobile_otp_message": "Chcete odeslat jednorázové heslo pro ověření mobilního čísla?",
    "add_company": "Přidat společnost",
    "edit_company": "Upravit společnost",
    "ds_device": "Digital Signage zařízení",
    "ds_device_activation_fee": "Poplatek za aktivaci zařízení Digital Signage",
    "wifi_hardware": "Wifi hardware",
    "menu_design": "Návrh menu",
    "ds_hardware": "Hardware digitálního značení",
    "company_name_req": "Název společnosti je povinný",
    "known_name_req": "Je vyžadováno známé jméno",
    "no_location_req": "Číslo umístění je povinné",
    "minimum_location": "Zadejte prosím minimálně 1 místo",
    "terms_req": "Podmínky jsou povinné",
    "notes_req": "Poznámky jsou povinné",
    "sms_charge_req": "Je vyžadován poplatek za SMS",
    "menuonline_quantity_req": "Nabídka Online Množství je povinné",
    "menuonline_cost_req": "Nabídka Online Cena je vyžadována",
    "ds_quantity_req": "Je vyžadováno množství digitálních nápisů",
    "ds_monthly_cost_req": "Je vyžadována měsíční cena digitálního podpisu",
    "reputation_quantity_req": "Reputační množství je povinné",
    "reputation_cost_req": "Reputační náklady jsou vyžadovány",
    "marketing_quantity_req": "Je vyžadováno marketingové množství",
    "marketing_cost_req": "Požadují se marketingové náklady",
    "tax_percentage_req": "Je vyžadováno procento daně",
    "router_hardware_quantity_req": "Je vyžadováno množství hardwaru routeru",
    "wifi_hardware_cost_req": "Je vyžadována cena hardwaru WIFI",
    "activation_cost_req": "Náklady na aktivaci jsou povinné",
    "wifi_quantity_req": "WIFI Množství je povinné",
    "wifi_router_req": "Je vyžadován Wifi router",
    "menu_design_quantity_req": "Menu Design Množství je povinné",
    "menu_design_cost_req": "Je požadována cena za návrh menu",
    "quantity_req": "Je požadováno množství",
    "cost_req": "Cena je vyžadována",
    "sheduledown_quantity_req": "Je vyžadováno množství v časovém rozvrhu",
    "sheduledown_cost_req": "Je požadováno snížení nákladů na rozvrh",
    "loyalty_program_quantity_req": "Věrnostní program Množství je povinné",
    "loyalty_program_cost_req": "Cena věrnostního programu je povinná",
    "loyalty_program_sms_quantity_req": "Věrnostní program SMS Množství je povinné",
    "loyalty_program_sms_cost_req": "Je vyžadována cena SMS věrnostního programu",
    "comapny_created": "Společnost byla úspěšně vytvořena",
    "comapny_updated": "Společnost byla úspěšně aktualizována",
    "billing_info": "FAKTURAČNÍ ÚDAJE",
    "business_name_acc_holder": "Obchodní název (majitel účtu)",
    "client_first_name": "Křestní jméno klienta",
    "client_last_name": "Příjmení klienta",
    "dba_known_as": "DBA známý jako",
    "business_address": "Obchodní adresa",
    "no_of_locations": "Počet míst",
    "package_details": "PODROBNOSTI O BALÍKU",
    "terms": "Podmínky",
    "one_month": "1 měsíc",
    "per_sms_charge": "Poplatek za SMS",
    "plan_test": "PLÁN: TEST",
    "desc_capital": "POPIS",
    "qty_capital": "MNOŽSTVÍ",
    "cost_capital": "NÁKLADY",
    "total_price_capital": "CENA CELKEM",
    "quantity": "Množství",
    "can_not_access": "Nelze vybrat více než",
    "licenses": "licencí",
    "monthly_cost": "Měsíční náklady",
    "plan_cost": "Náklady na plán",
    "tax": "Daň",
    "tax_percentage": "Procento daně",
    "monthly_cost_after_tax": "Měsíční náklady po zdanění",
    "activation_one_time_charge": "Aktivace a jednorázové nabití",
    "sub_total": "MÍSTO SOUČET",
    "action": "Akce",
    "cost": "Náklady",
    "shipping_charge": "Poplatek za dopravu",
    "other": "Ostatní",
    "additional_cost": "Dodatečné poplatky (další poplatky)",
    "other_tax": "Jiná daň",
    "total": "Celkový",
    "license_statistics": "Statistiky licencí",
    "total_licenses": "Celkový počet licencí",
    "available_licenses": "Dostupné licence",
    "stock": "Sklad",
    "has_permission": "Má povolení",
    "avg_stock_price": "AVG skladová cena",
    "average_price": "Průměrná cena",
    "allocated": "Přiděleno",
    "reward_regulars": "Odměňujte štamgasty",
    "not_add_coupon_url": "Nelze přidat adresu URL kupónu. Maximální délka zprávy je 160",
    "like_attach_coupon": "Chcete přiložit kupón?",
    "advance_scheduling": "Pokročilé plánování",
    "choose_day": "Vyberte dny pro odeslání zprávy",
    "select_essage_window": "Vyberte Okno zpráv",
    "subscription_outside_delivery_window": "Pokud se uživatel přihlásí k odběru mimo dodací lhůtu, obdrží vaši automatickou odpověď v nejbližším dostupném čase.",
    "remaining": "Zbývající",
    "processing_request": "Při zpracování vašeho požadavku došlo k chybě.",
    "list_companies": "Seznam společností",
    "are_you_sure": "jsi si jistý..?",
    "signin_agreement": "Potvrzením tohoto společnost vytvoří bez podpisu smlouvy.",
    "status_updated": "Aktualizace stavu proběhla úspěšně",
    "status_failed": "Aktualizace stavu se nezdařila",
    "new_editor_status_updated": "Stav nového editoru DS byl úspěšně aktualizován.",
    "user_name": "Uživatelské jméno",
    "known_as": "známý jako",
    "agreement_signed": "Smlouva podepsána",
    "deactivate": "Deaktivovat",
    "activate": "Aktivovat",
    "login_to_user_acc": "Přihlášení k uživatelskému účtu",
    "disable": "Zakázat",
    "enable": "Umožnit",
    "ds_new_editor": "Nový editor DS",
    "sign_agreement": "Podepsat dohodu",
    "agreement_sign": "Smlouva bez podpisu",
    "view_agreement": "Zobrazit smlouvu",
    "download_agreement": "Stáhněte si smlouvu",
    "add_deleted_user": "Přidat smazaného uživatele",
    "deleted_user": "Smazat uživatele",
    "favourite": "Oblíbený",
    "refresh": "Obnovit",
    "delete_chat": "Smazat chat",
    "responsive_drawer": "Responzivní zásuvka",
    "delete_contact": "Smazat kontakt",
    "clear_chat_history": "Vymazat historii chatu",
    "clear_chat_history_dec": "Opravdu chcete tento chat smazat?",
    "clear_contact_dec": "Opravdu chcete tento kontakt smazat?",
    "select_contact": "Vyberte možnost Kontakt",
    "new_conversation": "Začněte novou konverzaci",
    "type_msg": "Sem napište svou zprávu",
    "select_delete_client": "Vyberte klienta, kterého chcete smazat",
    "select_delete_client_chat": "Vyberte klienta pro smazání chatu",
    "select_delete_client_chat_err": "S tímto klientem nemáte chat",
    "acquire_customers_business": "Získejte nové zákazníky z jiných firem.",
    "customers_from_events": "Získejte zákazníky z akcí a přiveďte je k vám!",
    "customers_from_ads": "Získejte zákazníky z novinových inzerátů.",
    "smart_pamphlets": "Vytvářejte chytré brožury ke shromažďování údajů o zákaznících.",
    "smart_qr_group": "Chytrý QR pro připojení ke skupině pro zákazníky.",
    "opt_in_number": "Umožněte zákazníkům zvolit číslo.",
    "qr_menu": "Vytvořte QR kód pro zobrazení vašeho menu.",
    "collect_birthdays": "Sbírejte narozeniny a výročí zákazníka.",
    "join_loyalty_program": "Získejte nové zákazníky do mého věrnostního programu.",
    "create_feedback_forms": "Vytvářejte formuláře pro sběr zpětné vazby od klientů.",
    "exclusive_discounts_promotions": "Nabídněte exkluzivní slevy a akce pro křížové propagace.",
    "group_smart_qr_opt_in": "Po vytvoření skupiny můžete vygenerovat a vybrat chytrý QR kód, který zákazníkům umožní okamžitě se přihlásit do skupiny",
    "group_smart_qr_opt_in_dec": "Po vytvoření skupiny widget Obrázek, který zákazníkům umožňuje přihlásit se pomocí textu a přijímat propagační akce, například text „pizza“ na číslo 555-555-5555",
    "in_store_discount_next_visit": "Získejte slevu v obchodě při příští návštěvě naší pobočky",
    "delivery_redirection": "Vytvořit přesměrování doručení",
    "additional_info": "Další informace",
    "add_url": "Přidat URL",
    "custom_fields": "Vlastní pole",
    "meta_tag": "Meta tag",
    "max_chars": "Maximální počet znaků",
    "add": "Přidat",
    "update_campaign": "Aktualizovat kampaň",
    "last_week_required": "minulý týden je povinný",
    "large_week_value": "Hodnota týdne je příliš velká, zadejte platný týden",
    "less_than_last_week": "Nevrácení za minulý týden musí být menší než minulý týden.",
    "last_week_req": "Nevyžaduje se návrat v minulém týdnu",
    "birthday_special": "Narozeninový speciál",
    "birthday_message": "Automatická zpráva odeslaná v den jejich narozenin s personalizovanými pozdravy a lákavými nabídkami",
    "birthday_sms": "Vytvořte si personalizovaná přání k narozeninám pro SMS\/MMS",
    "target_audience": "Vyberte cílové publikum",
    "client_by_birthday": "Klient do narozenin",
    "clients_once_year": "Tato kampaň je automaticky zasílána klientům jednou ročně ",
    "auto_responder_default_msg_1": "Jste naším jedním z nejlepších zákazníků, kterým rádi poskytujeme slevy! Ať jste vždy bohatí a zdraví. Všechno nejlepší k narozeninám! %% křestní jméno %%",
    "campaign_send_to_client_birthday": "Tato automatická kampaň se odesílá klientům s narozeninami, níže si můžete přizpůsobit cílení.",
    "get_more_engagement": "Získejte více zapojení zasláním b'day SMS 🎂",
    "welcome_new_clients": "Vítáme nové klienty",
    "make_lasting_impression": "Udělejte trvalý dojem tím, že vřele pozdravíte a přivítáte první hosty",
    "personalized_greetings": "Vytvořte osobní pozdravy pro nové klienty",
    "greetings_to_new_customers": "Tato kampaň rozešle uvítací pozdravy novým zákazníkům následující den po jejich první interakci s vaší firmou",
    "audience_predefined": "Publikum této automatické odpovědi je předdefinované, máme to!",
    "send_clients_subscribed": "Tato automatická odpověď je nastavena tak, aby odesílala klienty přihlášené jako poslední ",
    "first_interaction": "  po jejich první interakci.",
    "default_msg_2": "Doufáme, že se vám první návštěva líbila, rádi vás zase brzy uvidíme, takže právě pro vás nabízíme slevu! Klikněte na odkaz, zarezervujte si u nás další schůzku a uplatněte svou speciální online nabídku hned teď.",
    "new_clients_update": "Vítejte nové klienty s aktualizací textu kampaně",
    "new_clients_warm_greeting": "Přivítejte nové klienty vřelým pozdravem 💌",
    "win_back_clients": "Získejte zpět klienty",
    "re_engage_past_customers": "Znovu oslovte minulé zákazníky neodolatelnými nabídkami, které je povzbudí k návratu",
    "target_disengaged_clients": "Zacilte na nezainteresované klienty, aby se vrátili se speciální nabídkou, což je osvědčená taktika, jak přilákat klienty zpět.",
    "campaign_sends_to_clients": "Tato kampaň je zasílána klientům, kteří se po určité době nevrátili.",
    "customize_targeting": "Přizpůsobte cílení níže, aby bylo možné odesílat SMS\/MMS klientům, kteří přestali fungovat.",
    "clients_with_at_least": "Klienti s min ",
    "connection_last": " spojení v posl ",
    "return_in_last_week": " týden, ale nevrátil se v posledním ",
    "arr_menu_44": " týden",
    "df_msg_4": "TAK MOC vás chceme zpět, že vám dáme 10% slevu jen za to, že se vrátíte! Chcete nám to ještě dnes zkusit?",
    "update_campaign_text": "Získejte zpět klienty aktualizujte text kampaně",
    "re_invite_customers": "Znovu pozvěte zákazníky, kteří se nějakou dobu nevrátili",
    "loyal_patrons": "Ukažte uznání věrným patronům pomocí speciálních slev a věrnostních odměn",
    "surprize_top_spenders": "Překvapte zákazníky, kteří nejvíce utrácejí, personalizovanými a speciálními nabídkami.",
    "campaign_automatically_send": "Tato kampaň automaticky rozesílá odměny, abyste podpořili pád vašeho podnikání.",
    "sms_loyal_clients": "Tato kampaň posílá SMS\/MMS věrným klientům, níže můžete změnit cílení.",
    "or_more_connection": " nebo více spojení v rámci posledního ",
    "week_period": " týdenní období.",
    "default_msg_5": "Již dlouho jste zákazníkem! Páni, ten čas letí! Vážíme si vás a chceme vám poděkovat, že jste s námi.",
    "reward_regulars_update": "Pravidelné odměny aktualizují text kampaně",
    "special_discounts": "Oceňujte a dejte speciální slevy💎",
    "leave_positive_online": "Povzbuďte zákazníky, aby zanechávali pozitivní online recenze, abyste zvýšili reputaci restaurace",
    "clients_service_online": "Kampaň vybízí vaše klienty, aby ohodnotili vaši službu online.",
    "increase_your_company": "Tato automatická odpověď je odeslána za účelem zvýšení online hodnocení vaší společnosti.",
    "send_message_after": "Odeslat zprávu po ",
    "min_connection": " min připojení",
    "default_msg_6": "Doufáme, že se vám první návštěva líbila, rádi vás zase brzy uvidíme! [URL] Kliknutím na odkaz nás ohodnotíte na Googlu.",
    "ratings_update": "Získejte další text aktualizace hodnocení",
    "friendly_nudge": "Získejte více recenzí s přátelským šťouchnutím ⭐",
    "thanks_for_visiting": "děkuji za návštěvu",
    "express_gratitude": "Vyjádřete vděk zákazníkům za to, že si vybrali vaši firmu, pomocí srdečných děkovných zpráv",
    "thank_you_message": "Vytvořte poděkování pro své klienty.",
    "clients_for_visiting": "Pošlete vřelé poděkování svým klientům za návštěvu u vás.",
    "campaign_audience": "Toto publikum automatické kampaně je předdefinované, máme to!",
    "campaign_automatically_sends": "Tato automatická kampaň se automaticky odešle po ",
    "minutes_customer_visit": " minut návštěvy zákazníka",
    "default_msg_7": "Děkujeme, že jste naším váženým zákazníkem. Jsme velmi vděční za potěšení, že vám můžeme sloužit, a doufáme, že jsme splnili vaše očekávání.",
    "visiting_update_campaign": "Děkujeme, že jste navštívili text aktualizace kampaně",
    "guests_thank_you": "Děkujeme svým hostům za návštěvu 😊",
    "download_title": "Stáhnout",
    "qr_gen_title": "Vygenerováno QR",
    "qr_download_s_title": "QR kód byl úspěšně stažen",
    "sel_dark_brand_clr_msg": "Vyberte prosím platnou barvu pruhu",
    "manage_customer_stamp_rewards": "Spravujte odměny zákaznických razítek 🎁",
    "sel_loc_menu_title": "Vyberte umístění pro své menu",
    "ans_req": "Odpověď je vyžadována",
    "valid_reputation_name": "Zadejte platný název reputace",
    "reviews_name_req": "Jméno Získat další recenze je povinné",
    "birthdate_required": "Datum narození je povinné",
    "gender_required": "Pohlaví je povinné",
    "valid_birthdate_required": "Zadejte platné datum narození",
    "custom_delivery_redirection": "Vytvořte vlastní formulář pro přesměrování doručení podle skupin",
    "customer_fields_incentive_settings": "Zákaznická pole a nastavení pobídek",
    "delivery_redirection_text": "Přesměrování doručení",
    "delivery_redirection_success": "gratuluji! Váš widget pro přesměrování doručení byl vytvořen! 🎉",
    "swipe_to_preview_redirection": "Přejetím prstem zobrazíte, jak bude vypadat váš formulář pro přesměrování doručení",
    "enter_item_name": "Zadejte název položky",
    "home_page_text": "Text domovské stránky",
    "settings": "Nastavení",
    "anniversary_required": "Datum výročí je povinné",
    "valid_anniversary": "Zadejte platné výročí",
    "form_submited": "formulář úspěšně odeslán",
    "notifications": "Oznámení",
    "discount_message": "Získejte slevu 20 % na pizzu hned teď!",
    "is_required": " je vyžadováno",
    "section_title_required": "Název sekce je povinný",
    "section_dec_required": "Popis sekce je povinný",
    "client_details_required": "Pole s podrobnostmi o klientovi jsou povinná",
    "compliance": "Dodržování",
    "SMS_campaign1": "SMS kampaň 1",
    "mobile_number_mandatory": "Mobilní číslo je povinné",
    "new_answer": "Nová odpověď",
    "new_question": "Nová otázka",
    "add_new_question": "Přidat novou odpověď",
    "select": "Vybrat",
    "group_customers_question": "Kam chcete seskupit své zákazníky?",
    "contacts_added_to_group": "Všechny kontakty, které odešlou formulář, budou přidány do vybrané skupiny",
    "edit_client_details_section": "Upravit sekci Podrobnosti klienta",
    "client_details_fields": "Pole s podrobnostmi o klientovi",
    "enter_client_details_section_title_desc": "Zadejte název a popis sekce s podrobnostmi o klientovi",
    "choose_fields_displayed_client_side": "Vyberte pole, která se mají zobrazit na straně klienta",
    "section_title": "Název sekce",
    "add_title": "Přidat název",
    "section_description": "Popis sekce",
    "add_description": "Přidat popis",
    "enter_program_name_location": "Zadejte název a umístění programu 📍",
    "brand_theme_clr_txt": "Barva motivu značky",
    "set_loyalty_punch_card_theme": "Pojďme nastavit téma pro vaši věrnostní děrnou kartu 🎨",
    "upload_logo_txt": "Nahrajte své logo",
    "recommended_image_specs": "Pro nejlepší zobrazení doporučujeme použít obrázek o velikosti 512 x 512 pixelů. JPG, SVG nebo PNG. Maximální velikost 10 MB.",
    "valid_rep_name_txt": "Zadejte platný název reputace",
    "rep_name_req_txt": "Jméno Získat další recenze je povinné",
    "que_req": "Otázka je povinná",
    "day_of_birthday": "v den narozenin",
    "day_before_birthday": "3 dny před narozeninami",
    "week_before_birthday": "týden před narozeninami",
    "two_week_before_birthday": "dva týdny před narozeninami",
    "de_active": "De-Active",
    "campaign_details": "Podrobnosti o kampani",
    "link_clicked": "Odkaz klikl",
    "history": "Dějiny",
    "auto_responder_summary": "Souhrn automatické odpovědi",
    "vsop_1": "15 min",
    "vsop_2": "30 min",
    "vsop_3": "45 min",
    "vsop_4": "60 min",
    "vsop_5": "90 min",
    "vsop_6": "120 min",
    "in_the_last": " v posledním ",
    "return_in_last": " ale nevrátil se jako poslední ",
    "of_the_connection": "spojení",
    "your_campaign": "Vaše kampaň",
    "you_have_successfully": "Úspěšně jste",
    "published_successfully": "úspěšně zveřejněno",
    "updated_smart_campaign": "aktualizoval chytrou kampaň",
    "almost_done_text": "Téměř hotovo!",
    "update_campaign_desc": "Vaše automatická odpověď je dokončena, jakmile bude povolena, bude průběžně odesílat zprávy klientům. Tuto kampaň lze kdykoli snadno upravit nebo pozastavit.",
    "update_and_publish": "Aktualizovat a publikovat",
    "reset_campaign_title": "Předběžně smaže vlastní text, obrázek, slevové nabídky a cílové publikum vaší kampaně!",
    "space_wifi": " WiFi",
    "custom_forms": "Vlastní formuláře",
    "web_e_signup": "Webová elektronická registrace",
    "import": "Importovat",
    "permissions_req": "Vyžaduje se oprávnění",
    "redeemed": "Vykoupeno",
    "coupon_already_redeemed": "Kupon již byl uplatněn",
    "autoresponder_campaigns": "Kampaně s automatickou odpovědí",
    "autoresponder_campaign_desc": "Povolte automatickou odpověď a spusťte personalizovanou kampaň",
    "mon": "PO",
    "tue": "ÚT",
    "wed": "ST",
    "thu": "ČT",
    "fri": "PÁ",
    "sat": "SAT",
    "sun": "SLUNCE",
    "duplicate": "Duplikát",
    "visibility": "Viditelnost",
    "availability": "Dostupnost",
    "out_stok": "Mimo stok",
    "edit_product": "Upravit produkt",
    "create_product": "Vytvořte produkt",
    "basic_info": "Základní informace",
    "basic_sub_info": "Lorem Ipsum je prostě fiktivní text tisku...",
    "enter_product_price": "Zadejte cenu produktu",
    "upload_image": "Nahrát obrázek",
    "allowed_file_formats": "Povoleny jsou pouze soubory JPG a PNG s maximální velikostí souboru 3 MB.",
    "pre_select": "Předvýběr",
    "promotions": "Propagační akce",
    "discount_availability": "Dostupnost slev",
    "start_time": "Čas zahájení",
    "end_time": "Čas ukončení",
    "select_day": "Vyberte Den",
    "menu_required": "Menu je povinné",
    "generated": "Vygenerováno",
    "link": "Odkaz",
    "variations": "Variace",
    "select_variant_to_add": "Vyberte variantu, kterou chcete přidat",
    "price_title": "Cena",
    "choose_image": "Vyberte Obrázek",
    "select_visiblity": "Vyberte Viditelnost",
    "availability_schedule": "Plán dostupnosti",
    "add_on_upsell": "Add on \/ Upsell",
    "add_on_select_product": "Přidat k vybranému produktu",
    "upsell_product": "Upsell Select Product",
    "variant_deletion_warning": "Tato varianta bude trvale smazána.",
    "search_variations": "Varianty vyhledávání",
    "add_variation": "Přidat variaci",
    "variation_text": "Varianta",
    "select_customization": "Vyberte přizpůsobení",
    "add_new": "Přidat nový",
    "delete_customization_warning": "Chcete smazat toto přizpůsobení z produktu?",
    "nutritional_allergen_info": "Nutriční informace a informace o alergenech",
    "mark_item": "Označit položku",
    "calories_text": "Kalorie",
    "allergens": "Alergeny",
    "select_allergens": "Vyberte alergeny",
    "special_instructions": "Speciální pokyny",
    "preparation_instructions": "Pokyny pro přípravu",
    "staff_notes": "Poznámky zaměstnanců",
    "checkbox1": "Zaškrtávací políčko pro povolení nebo zakázání speciálních pokynů od zákazníků pro tuto položku nabídky.",
    "menu_c": "MENU",
    "past_design": "Minulý design",
    "file_name": "Název souboru",
    "variation_name": "Název varianty",
    "grid_items_title1": "Stolní stan",
    "grid_items_title2": "Digitální menu",
    "grid_items_title3": "Menu s sebou",
    "grid_items_title4": "Půlstránkové menu",
    "grid_items_title5": "Digitální menu 5",
    "grid_items_title6": "Digitální menu 6",
    "grid_items_title7": "Digitální menu 7",
    "grid_items_title8": "Digitální menu 8",
    "grid_items_title9": "Digitální menu 9",
    "enter_valid_form_name": "Zadejte platný název formuláře",
    "form_name_required": "Název formuláře je povinný",
    "enter_valid_section": "Zadejte platný název sekce",
    "fraction_half": "1\/2",
    "fraction_two_by_two": "2\/2",
    "form_name": "Název formuláře",
    "contact_list": "Seznam kontaktů",
    "label_notify_email": "Chcete být informováni e-mailem?",
    "add_client_details_section": "Přidat sekci Podrobnosti klienta",
    "demo_purpose_only": "Slouží pouze pro demo účely",
    "form_updated_success": "formulář byl úspěšně aktualizován",
    "form_creat_success": "formulář byl úspěšně vytvořen",
    "add_custom_section": "Přidejte vlastní sekci",
    "edit_custom_section": "Upravit vlastní sekci",
    "forms_list": "Seznam formulářů",
    "filled_custom_form_details": "Podrobnosti vyplněného vlastního formuláře",
    "custom_form_deletion_warning": "Tento vlastní formulář bude trvale smazán"
};

export default cs